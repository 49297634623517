import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import plus from '../../assets/images/plus.png';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import DropDown from '../../components/RDropDown';
import {
  UPDATE_STOCK_COLUMNS,
  BLOOD_GROUP_OPTIONS,
} from '../../utils/constants';
import { postInProcess } from '../../redux/actions/inventoryAction';

function UpdateStockView({
  sortedDataList,
  updaetStockList,
  page,
  search,
  setSearch,
  rowsPerPage,
  onHeaderClick,
  headerDropDownChange,
  handleChangePage,
  handleChangeRowsPerPage,
  setUpdateStockPopUp,
  setfailedPopup,
  setSuccessPopup,
}) {
  const dispatch = useDispatch();
  const location = useLocation(); // use for accepting the Data as props
  const history = useHistory();

  function onCancleBtnClick() {
    history.push('/inventory-in-process');
  }

  function onUpdateStockClick() {
    let obj = {
      //  inventoryIds comes from inProcessContainer.js using useLocation method
      inventoryIds: location.state.ids,
      inventories: updaetStockList.map((ele) => {
        ele.expiryDate = new Date(ele.expiryDate).toISOString();
        ele.processedDate = new Date(ele.processedDate).toISOString();
        return ele;
      }),
      type: 'processed',
    };

    if (obj.inventories.length > 0) {
      dispatch(postInProcess(obj));
      setSuccessPopup(true);
    } else {
      setfailedPopup(true);
    }
  }

  useEffect(() => {}, [location]);

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search'>
                {/* <div className='d-flex align-items-center'>
                  <div
                    className='d-flex align-items-center fw-600 cursor-pointer'
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    <img
                      src={back}
                      height='25px'
                      className='cursor-pointer'
                      style={{ marginLeft: '5px' }}
                    />
                  </div>
                </div> */}

                <span className='bd-dashboard__main'>Update Stock </span>
                <div className='dashboard_tiles_header'>
                  <div className='bd_error fw-600'>
                    <div className='bd-dorpdown'>
                      <div className='bd-dorpdown__selected detail-dd bg-dd'>
                        <DropDown
                          placeHolderText={'Blood Group'}
                          options={BLOOD_GROUP_OPTIONS}
                          fetchDropDownValue={headerDropDownChange}
                        />
                        <i className='bludbig-arrow' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bd-hader'>
                  <input
                    type='text'
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder='Search'
                    style={{ padding: 5, marginRight: 10 }}
                  />
                  <div
                    className='d-flex align-items-center fw-600 cursor-pointer'
                    onClick={() => {
                      setUpdateStockPopUp(true);
                    }}
                  >
                    <img
                      src={plus}
                      height='25px'
                      className='cursor-pointer'
                      style={{ marginLeft: '5px' }}
                      onClick={() => {
                        setUpdateStockPopUp(true);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='table-scroll'>
                <Tables
                  tab={'InventoryTab'}
                  tableType={'table-type-1'}
                  columns={UPDATE_STOCK_COLUMNS}
                  tableData={sortedDataList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  headerClick={onHeaderClick}
                />
              </div>
              <TablePagination
                component='div'
                count={sortedDataList.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </section>
      </article>

      <div className='bd-btn__wrap'>
        <div
          className='bd-btn bd-btn--outline '
          onClick={() => onCancleBtnClick()}
        >
          <span>Cancel</span>
        </div>
        <div
          className='bd-btn bd-btn--primary db-bg__green'
          onClick={() => onUpdateStockClick()}
        >
          <span>Update Stock</span>
        </div>
      </div>
    </>
  );
}

export default UpdateStockView;
