import React, { useState } from 'react';
import camp from '../../assets/images/camp.png';
import moment from 'moment';

function CampProfilePop({
  institutionType,
  setCampProfilePopup,
  campListObject,
  error,
  onDeclinedClick,
  setCommentValue,
  setShowRechedulCamp,
}) {
  const [Comment, setComment] = useState(false);
  const [Button, setButton] = useState(true);

  function CloseCampPopUp() {
    setCampProfilePopup(false);
  }
  function reschedualCamp() {
    setCampProfilePopup(false);
    setShowRechedulCamp(true);
  }

  const campListObjectData = [
    {
      label: 'Date:',
      value: moment(campListObject?.campDuration?.startTime).format(
        'DD MMM YYYY'
      ),
    },
    {
      label: 'Time:',
      value: `${moment(campListObject?.campDuration?.startTime).format(
        'hh:mm'
      )} - ${moment(campListObject?.campDuration?.endTime).format('hh:mm')}`,
    },
    { label: 'Camp:', value: campListObject.campName },
    { label: 'Venue:', value: campListObject.venue },
    {
      label: 'Address:',
      value: campListObject?.address?.description
        ? campListObject?.address?.description
        : '--',
    },
    { label: 'Sponser:', value: campListObject.organiser },
    {
      label: 'Coordinator:',
      value: campListObject?.campCoordinator?.name
        ? campListObject?.campCoordinator?.name
        : '--',
    },
    {
      label: 'Email:',
      value: campListObject.campCoordinator.email
        ? campListObject.campCoordinator.email
        : '--',
    },
    {
      label: 'Mobile:',
      value: campListObject.campCoordinator.mobileNumber
        ? campListObject.campCoordinator.mobileNumber
        : '--',
    },
    {
      label: 'Attention:',
      value: campListObject.bloodBankReference
        ? campListObject.bloodBankReference
        : '--',
    },
    {
      label: 'comments:',
      value: campListObject.comments ? campListObject.comments : '--',
    },
  ];

  return (
    <div className='bd-modal-wrap bd-profile'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>
            {institutionType !== 'NGO'
              ? 'Request to initate blood donation camp'
              : `${campListObject.campName}`}
          </span>
          <i className='bludclose' onClick={CloseCampPopUp}></i>
        </div>
        <div className='bd-popup__body'>
          <div className='bd-profile__tbl'>
            <div className='bd-profile__detail--tbl'>
              <div className='bd-profile__detail--lft'>
                <div className='bd-profile__detail'>
                  <div
                    className='bd-profile__img'
                    style={{
                      backgroundImage: campListObject.addedBy.photo
                        ? `url(${campListObject.addedBy.photo})`
                        : `url(${camp})`,
                      // : `url(${genderWiseProfilePic(campListObject.gender)})`,
                    }}
                  ></div>
                  <div className='bd-profile__group_1'>
                    <div>
                      <div style={{ marginBottom: '5px' }}>
                        {campListObject.addedBy?.firstName +
                          ' ' +
                          campListObject.addedBy?.lastName}
                      </div>
                      <div style={{ marginBottom: '5px' }}>
                        {campListObject.addedBy?.mobileNumber ||
                          campListObject.campCoordinator.mobileNumber}
                      </div>
                      {institutionType !== 'NGO' && (
                        <div>{campListObject.addedBy._id}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='bd-tbl bd-tbl__theme2'>
                  <div className='bd-tbl__body'>
                    {campListObjectData.map((cell) => {
                      return (
                        <div className='bd-tbl__row'>
                          <div className='bd-tbl__cell'>
                            <span>{cell.label}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{cell.value}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {institutionType !== 'NGO' && Button ? (
              <div className='bd-profile__otp'>
                <div className='bd-btn__wrap'>
                  <div
                    className='bd-btn bd-btn--primary'
                    onClick={() => {
                      setComment(true);
                      setButton(false);
                    }}
                  >
                    <span>Decline </span>
                  </div>

                  <div
                    className='bd-btn  bd-btn--accept active'
                    onClick={reschedualCamp}
                  >
                    <span>Schedule</span>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {Comment && (
              <div>
                <div className='bd-profile__otp'>
                  <div className='bd-testArea mb-10'>
                    <textarea
                      className='bd-textArea-style'
                      placeholder='Comments'
                      name='Comments'
                      onChange={(e) => setCommentValue(e.target.value)}
                    ></textarea>
                    {error && (
                      <span style={{ color: '#af0c0c' }}>
                        *Comment Is Required For Decline Camp Request
                      </span>
                    )}
                  </div>
                  <div className='bd-btn__wrap'>
                    <div
                      class='bd-btn bd-btn--outline'
                      onClick={() => {
                        setComment(false);
                        setButton(true);
                      }}
                    >
                      <span>Cancel </span>
                    </div>

                    <div
                      className='bd-btn  bd-btn--primary'
                      onClick={onDeclinedClick}
                    >
                      <span>Decline</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}

export default CampProfilePop;
