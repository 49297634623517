import { makeAPICall } from '../../utils';
import {
  API_BASE_PATH,
  DONOR_LIST_URL,
  BLOCK_DONOR_URL,
  UNBLOCK_DONOR_URL,
  REQUEST_BLOOD_URL,
  UNBLOCK_ADMIN_URL,
  BLOCK_ADMIN_URL,
} from '../../utils/config';
import {
  GET_DONOR_LIST,
  GET_DONOR_LIST_SUCCESS,
  GET_DONOR_LIST_FAILED,
  GET_RECEPIENT_LIST,
  GET_RECEPIENT_LIST_SUCCESS,
  GET_RECEPIENT_LIST_FAILED,
  BLOCK_AND_UNBLOCK,
  BLOCK_AND_UNBLOCK_SUCCESS,
  BLOCK_AND_UNBLOCK_FAILED,
  REQUEST_FOR_BLOOD,
  REQUEST_FOR_BLOOD_SUCCESS,
  REQUEST_FOR_BLOOD_FAILED,
  // super Admin BLOCK UNBLOCK ADMIN
  BLOCK_UNBLOCK_USER,
  BLOCK_UNBLOCK_USER_SUCCESS,
  BLOCK_UNBLOCK_USER_FAILED,
} from './../actions/actionTypes';

import { urlSearchParams } from '../../utils';

export function getPeopleDonorList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DONOR_LIST_URL}`,
      dispatch,
      defaultAction: GET_DONOR_LIST,
      successAction: GET_DONOR_LIST_SUCCESS,
      failedAction: GET_DONOR_LIST_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getPeopleRecepientList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DONOR_LIST_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_RECEPIENT_LIST,
      successAction: GET_RECEPIENT_LIST_SUCCESS,
      failedAction: GET_RECEPIENT_LIST_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}
export function blockDonor(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${
        obj.type == 'UnBlock' ? UNBLOCK_DONOR_URL : BLOCK_DONOR_URL
      }`,
      dispatch,
      defaultAction: BLOCK_AND_UNBLOCK,
      successAction: BLOCK_AND_UNBLOCK_SUCCESS,
      failedAction: BLOCK_AND_UNBLOCK_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    delete params.params.type;
    makeAPICall(params);
  };
}

// export function userChangeStatus(obj, callback) {
//   console.log(obj.type);
//   return (dispatch) => {
//     const params = {
//       url: `${API_BASE_PATH}${
//         obj.type == 'blockUser' ? BLOCK_ADMIN_URL : UNBLOCK_ADMIN_URL
//       }`,
//       dispatch,
//       defaultAction: BLOCK_UNBLOCK_USER,
//       successAction: BLOCK_UNBLOCK_USER_SUCCESS,
//       failedAction: BLOCK_UNBLOCK_USER_FAILED,
//       type: 'POST',
//       headers: {},
//       params: obj,
//       callback,
//     };
//     delete params.params.type;
//     makeAPICall(params);
//   };
// }

export function userChangeStatus(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${
        obj.type == 'blockUser' ? BLOCK_ADMIN_URL : UNBLOCK_ADMIN_URL
      }`,
      dispatch,
      defaultAction: BLOCK_UNBLOCK_USER,
      successAction: BLOCK_UNBLOCK_USER_SUCCESS,
      failedAction: BLOCK_UNBLOCK_USER_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function requestForBlood(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQUEST_BLOOD_URL}`,
      dispatch,
      defaultAction: REQUEST_FOR_BLOOD,
      successAction: REQUEST_FOR_BLOOD_SUCCESS,
      failedAction: REQUEST_FOR_BLOOD_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
