import React, { useState } from 'react';
import DropDown from '../../components/RDropDown';
import DatePicker from '../../components/DatePicker';
import download from '../../assets/icon/download.svg';
import { GENDER_LIST } from '../../utils/constants';

export default function AddPatientView({
  userDetails,
  onInputChange,
  setInstituteNameFocused,
  instituteNameFocused,
  instituteListObj,
  onInstituteSelect,
  requestClick,
  addFile,
  bloodComponents,
  setShowAddPatientPopup,
  currBloodGroup,
  bloodComponent,
}) {
  let list = [];
  const hiddenFileInput = React.useRef(null);
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  // const [bloodGroup, setBloodGroup] = useState('');
  // const [component, setBloodComponent] = useState('');
  const [requiredBy, setRequiredBy] = useState('');

  if (instituteListObj?.data?.institutionLocation) {
    list = [...instituteListObj.data.institutionLocation];
  }
  function onAddClick(e) {
    e.preventDefault();
    let {
      patientName,
      patientId,
      mobileNo,
      patientCondition,
      relationWithPatient,
      purpose,
      hospitalName,
      units,
      doctorName,
      comment,
    } = e.target;
    let patient = {
      component: bloodComponent,
      bloodGroup: currBloodGroup,
      units: units.value,
      hospitalName: hospitalName.value,
      hospitalLocation: {
        ...userDetails.address,
      },
      comments: comment.value,
      condition: patientCondition.value,
      requiredBy: requiredBy,
      patientName: patientName.value,
      mobileNumber: mobileNo.value,
      countryCode: '+91',
      doctorName: doctorName.value,
      reasons: purpose.value,
      patientId: patientId.value,
      gender: gender.value,
      dateOfBirth: dateOfBirth,
      relation: relationWithPatient.value,
      attachments: userDetails.attachments,
    };
    requestClick(patient);
  }
  return (
    <div className='bd-modal-wrap bd-request-blood add-patient'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Add Patient to Issue Blood</span>
          <div className='bd-popup__head--rgt'>
            <i
              className='bludclose'
              onClick={() => setShowAddPatientPopup(false)}
            ></i>
          </div>
        </div>
        <div className='bd-popup__body'>
          <form onSubmit={onAddClick}>
            <div className='bd-form'>
              <div className='bd-form__row'>
                <input
                  type='text'
                  placeholder='Patient Name'
                  name='patientName'
                />
              </div>
              <div className='bd-form__row bd-twocol'>
                <div className='inventory-dd dd-min-w'>
                  <label>Gender</label>
                  <div className='bd-dorpdown__selected'>
                    <DropDown
                      width={'200px'}
                      placeHolderText={'Select Gender'}
                      options={GENDER_LIST}
                      fetchDropDownValue={function (value) {
                        setGender(value);
                      }}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
                <div className='bd-form__row'>
                  <div className='bd-calender'>
                    <label>Date of Birth</label>
                    <DatePicker
                      getSelectedDate={function (value) {
                        setDateOfBirth(value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='bd-form__row bd-twocol'>
                <div className='bd-form__row'>
                  <input
                    type='text'
                    placeholder='Patient ID'
                    name='patientId'
                  />
                </div>
                <div className='bd-form__row'>
                  <input
                    type='number'
                    placeholder='Mobile Number'
                    name='mobileNo'
                  />
                </div>
              </div>
              <div className='bd-form__row bd-twocol'>
                <div className='bd-form__row'>
                  <input
                    type='text'
                    placeholder='Patient Condition'
                    name='patientCondition'
                  />
                </div>
                <div className='bd-form__row'>
                  <input
                    type='text'
                    placeholder='Relation with Patient'
                    name='relationWithPatient'
                  />
                </div>
              </div>
              <div className='bd-form__row'>
                <input type='text' placeholder='Purpose' name='purpose' />
              </div>
              <div className='bd-form__row'>
                <input
                  type='text'
                  placeholder='Hospital'
                  name='hospitalName'
                  value={userDetails.hospitalName}
                  onChange={onInputChange}
                  onChange={(e) => onInputChange(e)}
                  onFocus={(e) => setInstituteNameFocused(true)}
                />
              </div>
              {instituteNameFocused && list.length && (
                <div className='autoNameList'>
                  {list.map((data, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => onInstituteSelect(data)}
                        style={{
                          borderRadius: 1,
                          borderColor: 'grey',
                          borderWidth: 1,
                          padding: 4,
                        }}
                      >
                        <div>{data.institutionName},</div>
                        <div>
                          {data.location &&
                            data.location &&
                            data.location.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className='bd-form__row'>
                <input
                  type='text'
                  placeholder='Address'
                  value={userDetails.address.description}
                  name='address'
                  disabled
                />
              </div>

              <div className='bd-form__row bd-twocol'>
                <div className='bd-form__row'>
                  <div className=''>
                    <label>Blood Group</label>
                    <div className='bd-dorpdown__selected'>
                      <span>{currBloodGroup}</span>
                      {/* <DropDown
                        width={"200px"}
                        placeHolderText={"Select Bloodgroup"}
                        options={BLOOD_GROUP_OPTIONS}
                        fetchDropDownValue={function(value) {
                          setBloodGroup(value)
                        }}
                      />
                      <i className="bludbig-arrow" /> */}
                    </div>
                  </div>
                </div>
                <div className='bd-form__row'>
                  <div className=''>
                    <label>Component</label>
                    <div className='bd-dorpdown__selected'>
                      <span>{bloodComponent.split('_')[0]}</span>
                      {/* <DropDown
                        width={"200px"}
                        placeHolderText={"Select Component"}
                        options={bloodComponents.map((a) => {
                          return { label: a, value: a };
                        })}
                        fetchDropDownValue={function(value) {
                          setBloodComponent(value)
                        }}
                      />
                      <i className="bludbig-arrow" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='bd-form__row bd-twocol'>
                <div className='bd-form__row'>
                  <input type='number' placeholder='Units' name='units' />
                </div>
                <div className='bd-form__row'>
                  <div className='bd-calender'>
                    <label>Required By</label>
                    <DatePicker
                      getSelectedDate={function (value) {
                        setRequiredBy(value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='bd-form__row'>
                <input
                  type='text'
                  placeholder='Doctor Name'
                  name={'doctorName'}
                />
              </div>
              <div className='bd-form__row'>
                <input type='text' placeholder='Comments' name={'comment'} />
              </div>
              <input
                type='file'
                name='attachments'
                id='upload'
                hidden
                ref={hiddenFileInput}
                onChange={addFile}
              />
              <div
                className='bd-btn bd-btn--outline'
                for='upload'
                onClick={() => hiddenFileInput.current.click()}
              >
                <span for='upload'>
                  <i class='icon-clip-icon mr-10'></i>
                  Attach Files
                </span>
              </div>
              <div className='bd-attachment__file'>
                {userDetails.attachments &&
                  userDetails.attachments.map((attachment) => {
                    return (
                      <a
                        className='download-a'
                        download
                        href={attachment.location}
                      >
                        <img
                          className='download-img'
                          src={download}
                          alt='download'
                        />
                        <div className='attachment-name'>{attachment.name}</div>
                      </a>
                    );
                  })}
              </div>
              <div className='bd-request__share'>
                <div
                  className='bd-btn bd-btn--outline'
                  onClick={() => setShowAddPatientPopup(false)}
                >
                  <span>Cancel</span>
                </div>
                <button
                  type='submit'
                  className='bd-btn bd-btn--outline bd-btn--accept active'
                >
                  <span>Request</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
