import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CampDonorsForm_one_View from '../../views/CampView/CampDonorsForm_one_View';
import { ageInYears } from '../../utils';
import {
  postDonateForm,
  getCampsDetail,
} from '../../redux/actions/campsAction';

const FIELDS = [
  'height',
  'weight_1',
  'hb',
  'bp',
  'kitNumber',
  'bagId',
  'sampleId_1',
  'sampleId_2',
  'sampleId_3',
];

function CampDonorsForm_one_Container({ onCloseClick, campDonorsListObj }) {
  const dispatch = useDispatch();
  const [donorInfo, setDonorInfo] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gender: '',
    id: '',
  });
  const [selectedRadio, setCurrentRadioSelect] = useState({
    radio9: '',
  });
  const [formValues, setFormValues] = useState(null);
  const [errorFields, setErrorFields] = useState({ ...FIELDS });
  const [ErrorMsg, setErrorMsg] = useState('');

  function onFormChange(params) {
    setFormValues(params);
  }
  function onInputChange(e) {
    let updateDonorInfo = { ...donorInfo };
    let value = e.target.value;
    if (FIELDS.includes(e.target.getAttribute('name'))) {
      updateDonorInfo[e.target.getAttribute('name')] = value;
    }
    setDonorInfo(updateDonorInfo);
  }
  function onRadioChange(e) {
    let obj = { ...selectedRadio };
    obj[e.target.name] = e.target.value;
    setCurrentRadioSelect(obj);
  }

  function onDonateClick(e) {
    e.preventDefault();
    let temp = {};
    FIELDS.map((a) => (temp[a] = e?.target[a]?.value === ''));
    setErrorFields(temp);
    let obj = {
      donorId: campDonorsListObj?.donorId,
      status: 'DonationInProcess',
      campId: campDonorsListObj?.campId,
      sample: {
        ...formValues.donorInfo,
        bagType: formValues.radioValue['radio9'],
      },
    };

    if (Object.entries(temp).filter((a) => a[1]).length !== 0) {
      setErrorMsg('All fileds are Required');
    } else {
      dispatch(
        postDonateForm(obj),
        () => onCloseClick(),
        setTimeout(() => {
          dispatch(
            getCampsDetail({
              campId: campDonorsListObj?.campId,
              type: 'donors',
            })
          );
        }, 400)
      );
    }
  }

  useEffect(() => {
    onFormChange({
      donorInfo: donorInfo,
      radioValue: selectedRadio,
    });
  }, [selectedRadio, donorInfo]);

  useEffect(() => {
    // this use effcet for Data Binding in form_one
    if (campDonorsListObj && Object.keys(campDonorsListObj).length > 0) {
      setDonorInfo({
        firstName: campDonorsListObj?.firstName
          ? campDonorsListObj?.firstName
          : '',
        lastName: campDonorsListObj?.lastName
          ? campDonorsListObj?.lastName
          : '',
        id: campDonorsListObj?.donorId ? campDonorsListObj?.donorId : 'IN-0000',
        age: campDonorsListObj?.dateOfBirth
          ? ageInYears(campDonorsListObj?.dateOfBirth)
          : '00',
        gender: campDonorsListObj?.gender ? campDonorsListObj?.gender : 'Male',
      });
    }
  }, [campDonorsListObj]);

  const donationForm_OneProps = {
    donorInfo: donorInfo,
    onCloseClick: onCloseClick,
    campDonorsListObj: campDonorsListObj,
    errorFields,
    ErrorMsg: ErrorMsg,
    onInputChange: onInputChange,
    onRadioChange: onRadioChange,
    onDonateClick: onDonateClick,
  };

  return (
    <div>
      <CampDonorsForm_one_View {...donationForm_OneProps} />
    </div>
  );
}

export default CampDonorsForm_one_Container;
