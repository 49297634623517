import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import DatePicker from '../../components/DatePicker';
import DropDown from '../../components/RDropDown';
import { BLOOD_GROUP_OPTIONS } from '../../utils/constants';

export default function RequestBloodView({
  formValues,
  requestClick,
  hideRequest,
  close,
}) {
  const history = useHistory();
  const [dropdownValue, setDropdownValue] = useState({ bloodGroup: 'A+' });
  const [requiredDate, setRequiredDate] = useState('');
  const [comment, setComment] = useState('');
  const [units, setUnits] = useState('');

  function handleOnChange(e) {
    setDropdownValue({
      ['bloodGroup']: e.value,
    });
  }
  function onDateChange(date) {
    setRequiredDate(date);
  }
  useEffect(() => {
    formValues({
      bloodGroup: dropdownValue.bloodGroup,
      requiredBy: new Date(requiredDate),
      comments: comment,
      units: units,
    });
  }, [dropdownValue, requiredDate, comment, units]);
  return (
    <div className='bd-modal-wrap bd-modal-right bd-request-blood'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Request Blood</span>
          <div className='bd-popup__head--rgt'>
            <i
              className='bludclose'
              onClick={() => {
                close
                  ? hideRequest()
                  : history.push({
                      pathname: '/',
                      requestBlood: false,
                    });
              }}
            ></i>
          </div>
        </div>
        <div className='bd-popup__body bd-padding-Unset'>
          <div className='bd-form'>
            {/* <div className='bd-form__row bd-twocol'> */}
            <div className='bd-form__row'>
              <div className='bd-calender'>
                <label>Required Before : </label>
                <DatePicker getSelectedDate={onDateChange} />
              </div>
            </div>

            <div className='bd-form__row'>
              <label>Blood Group : </label>
              <div className='bd-dorpdown'>
                <div className='bd-dorpdown__selected'>
                  <DropDown
                    placeHolderText={'Select Blood Group'}
                    options={BLOOD_GROUP_OPTIONS}
                    fetchDropDownValue={handleOnChange}
                  />
                  <i className='bludbig-arrow' />
                </div>
              </div>
            </div>

            <div className='bd-form__row'>
              <label>Units : </label>
              <input
                type='number'
                // placeholder='Units'
                value={units}
                onChange={(e) => {
                  // let regex = /^[0-9]+$/;
                  // if(e.target.value)
                  setUnits(e.target.value);
                }}
              />
            </div>
            <div className='bd-form__row'>
              <label>Comments : </label>
              <input
                type='text'
                // placeholder='Comments'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <div className='bd-request__share'>
              <div
                className='bd-btn bd-btn--outline'
                onClick={() => {
                  close
                    ? hideRequest()
                    : history.push({
                        pathname: '/',
                        requestBlood: false,
                      });
                }}
              >
                <span>Cancel</span>
              </div>
              <div
                className='bd-btn bd-btn--outline bd-btn--accept active'
                onClick={requestClick}
              >
                <span>Request</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
