import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuperAdminTransactionsView from '../../views/SuperAdminTransactionsView/SuperAdminTransactionsView';
import { triggerGetsubBludList } from '../../redux/actions/superAdminCommonActions';
import { sorting } from '../../utils';
import moment from 'moment';

function SuperAdminTransactionsContainer() {
  const dispatch = useDispatch();
  const { getsubAndBludList } = useSelector(
    (state) => state.superAdminCommonReducers
  );
  // we usedStatic Data forubsvriptino Tab
  const [activeTab, setActiveTab] = useState('Subscription');

  const [BludUnitData, setBludUnitData] = useState([]);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);

  function onTabChange(e) {
    setActiveTab(e.target.id);
    setSearch('');
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...BludUnitData];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setBludUnitData(data);
    setCount(count + 1);
  }

  function setData(sortedData) {
    let data = [];
    var count = 1119;
    sortedData &&
      sortedData?.forEach((record, i) => {
        count += 3;
        let obj = {
          transactionId: record?.transactionId ? record?.transactionId : '--',
          amount: record?.price ? record?.price : `₹${count}`,
          regId: record?.recieverDetails?.registrationId
            ? record?.recieverDetails?.registrationId
            : '--',
          patientId: record?.request.length
            ? record?.request[0].patientId
            : '--',
          userId: record?.request.length
            ? record?.request[0].addedBy._id
            : '--',

          type: record?.recieverDetails?.institutionType
            ? record?.recieverDetails?.institutionType
            : '--',
          institution: record?.recieverDetails?.institutionName
            ? record?.recieverDetails?.institutionName
            : '--',
          date: moment(record?.addedAt).format('DD MMM YYYY'),
        };
        data.push(obj);
      });
    return data;
  }
  function searchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.transactionId.toLowerCase().includes(search.toLowerCase()) ||
          items.amount.toLowerCase().includes(search.toLowerCase()) ||
          String(items.patientId)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.userId).toLowerCase().includes(search.toLowerCase()) ||
          String(items.type).toLowerCase().includes(search.toLowerCase()) ||
          String(items.institution)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.date).toLowerCase().includes(search.toLowerCase())
      )
    );
  }
  function BludUnitsDataList() {
    return search === '' ? BludUnitData : searchData(BludUnitData);
  }

  useEffect(() => {
    if (getsubAndBludList?.data?.content?.data) {
      setBludUnitData(setData(getsubAndBludList?.data?.content?.data));
    }
  }, [getsubAndBludList?.data?.content?.data]);

  useEffect(() => {
    if (activeTab === 'BludUnit') dispatch(triggerGetsubBludList());
  }, [activeTab]);

  return (
    <>
      <SuperAdminTransactionsView
        // bludUnitSortedList={setData(getsubAndBludList?.data?.content?.data)}
        search={search}
        activeTab={activeTab}
        setSearch={setSearch}
        bludUnitSortedList={BludUnitsDataList()}
        onTabChange={onTabChange}
        onHeaderClick={onHeaderClick}
      />
    </>
  );
}

export default SuperAdminTransactionsContainer;
