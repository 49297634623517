import {
  //inventory Transfer & Ageing Data
  GET_INVENTORY_DATA,
  GET_INVENTORY_DATA_SUCCESS,
  GET_INVENTORY_DATA_FAILED,
  // inventory Receipt Data  GET API
  GET_INVENTORY_RECEIPT_DATA,
  GET_INVENTORY_RECEIPT_DATA_SUCCESS,
  GET_INVENTORY_RECEIPT_DATA_FAILED,
  // inventory Receipt Data  GET API
  POST_INVENTORY_RECEIPT_DATA,
  POST_INVENTORY_RECEIPT_DATA_SUCCESS,
  POST_INVENTORY_RECEIPT_DATA_FAILED,
  // dispose data POST API
  DISPOSE_INVENTORY_DATA,
  DISPOSE_INVENTORY_DATA_SUCCESS,
  DISPOSE_INVENTORY_DATA_FAILED,
  ADD_INVENTORY,
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_FAILED,
  ISSUE_TO_PATIENT,
  ISSUE_TO_PATIENT_SUCCESS,
  ISSUE_TO_PATIENT_FAILED,
  ISSUE_TO_PROCESS,
  ISSUE_TO_PROCESS_SUCCESS,
  ISSUE_TO_PROCESS_FAILED,
  DISPOSE_INVENTORY,
  DISPOSE_INVENTORY_SUCCESS,
  DISPOSE_INVENTORY_FAILED,
  REQUEST_USER_DATA,
  REQUEST_USER_DATA_SUCCESS,
  REQUEST_USER_DATA_FAILED,
  PATIENT_SPECIFIC_INVENTORY,
  PATIENT_SPECIFIC_INVENTORY_SUCCESS,
  PATIENT_SPECIFIC_INVENTORY_FAILED,
  INVENTORY_ADD_PATIENT,
  INVENTORY_ADD_PATIENT_SUCCESS,
  INVENTORY_ADD_PATIENT_FAILED,
  TRANSFER_INSTITUTE_LIST,
  TRANSFER_INSTITUTE_LIST_SUCCESS,
  TRANSFER_INSTITUTE_LIST_FAILED,
  INVENTORY_TRANSFER_PROCESS,
  INVENTORY_TRANSFER_PROCESS_SUCCESS,
  INVENTORY_TRANSFER_PROCESS_FAILED,
  GET_IN_PROCESS_DATA,
  GET_IN_PROCESS_DATA_SUCCESS,
  GET_IN_PROCESS_DATA_FAILED,
  POST_IN_PROCESS_DATA,
  POST_IN_PROCESS_DATA_SUCCESS,
  POST_IN_PROCESS_DATA_FAILED,
} from '../actions/actionTypes';

const initialState = {
  inventoryDataObj: {
    loading: false,
    data: [],
    error: null,
  },

  inventoryReceiptObj: {
    loading: false,
    data: [],
    error: null,
  },
  // inventoryReceiptObj: {
  //   loading: false,
  //   data: [],
  //   error: null,
  // },
  postInventoryReceiptObj: {
    loading: false,
    data: [],
    error: null,
  },

  inventoryDisposeObj: {
    loading: false,
    data: [],
    error: null,
  },

  ageingReportDataObj: {
    loading: false,
    data: [],
    error: null,
  },
  addInventoryObj: {
    loading: false,
    data: [],
    error: null,
  },
  issueToPatientObj: {
    loading: false,
    data: [],
    error: null,
  },
  issueToProcessObj: {
    loading: false,
    data: [],
    error: null,
  },
  transferInventoryObj: {
    loading: false,
    data: [],
    error: null,
  },
  disposeInventoryObj: {
    loading: false,
    data: [],
    error: null,
  },
  // inventory componet list
  registerPatientList: {
    loading: false,
    data: [],
    error: null,
  },
  patientSpecificData: {
    loading: false,
    data: [],
    error: null,
  },
  patientInfo: {
    loading: false,
    data: [],
    error: null,
  },
  transferInstitutionList: {
    loading: false,
    data: [],
    error: null,
  },
  transferProcessData: {
    loading: false,
    data: null,
    error: null,
  },
  getInProcessData: {
    loading: false,
    data: [],
    error: null,
  },
  postInProcessData: {
    loading: false,
    data: [],
    error: null,
  },
};

const inventoryReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_INVENTORY_DATA:
      return {
        ...state,
        inventoryDataObj: {
          loading: true,
          inventoryList: [],
          error: null,
        },
      };

    case GET_INVENTORY_DATA_SUCCESS:
      const { data: inventoryList } = action.payload.content;
      return {
        ...state,
        inventoryDataObj: {
          loading: false,
          inventoryList,
          error: null,
        },
      };

    case GET_INVENTORY_DATA_FAILED:
      return {
        ...state,
        inventoryDataObj: {
          loading: false,
          inventoryList: null,
          error: action.error,
        },
      };

    case GET_INVENTORY_RECEIPT_DATA:
      return {
        ...state,
        inventoryReceiptObj: {
          loading: true,
          receiptList: [],
          error: null,
        },
      };

    case GET_INVENTORY_RECEIPT_DATA_SUCCESS:
      const { data: receiptList } = action.payload.content;
      return {
        ...state,
        inventoryReceiptObj: {
          loading: false,
          receiptList,
          error: null,
        },
      };

    case GET_INVENTORY_RECEIPT_DATA_FAILED:
      return {
        ...state,
        inventoryReceiptObj: {
          loading: false,
          receiptList: null,
          error: action.error,
        },
      };

    case POST_INVENTORY_RECEIPT_DATA:
      return {
        ...state,
        postInventoryReceiptObj: {
          // loading:true,
          loading: action.loading === false ? action.loading : true,
          data: [],
          error: null,
        },
      };

    case POST_INVENTORY_RECEIPT_DATA_SUCCESS:
      return {
        ...state,
        postInventoryReceiptObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case POST_INVENTORY_RECEIPT_DATA_FAILED:
      return {
        ...state,
        postInventoryReceiptObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case DISPOSE_INVENTORY_DATA:
      return {
        ...state,
        inventoryDisposeObj: {
          loading: action.loading === false ? action.loading : true,
          data: [],
          error: null,
        },
      };

    case DISPOSE_INVENTORY_DATA_SUCCESS:
      return {
        ...state,
        inventoryDisposeObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case DISPOSE_INVENTORY_DATA_FAILED:
      return {
        ...state,
        inventoryDisposeObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case ADD_INVENTORY:
      return {
        ...state,
        addInventoryObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case ADD_INVENTORY_SUCCESS:
      return {
        ...state,
        addInventoryObj: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case ADD_INVENTORY_FAILED:
      return {
        ...state,
        addInventoryObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case ISSUE_TO_PATIENT:
      return {
        ...state,
        issueToPatientObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case ISSUE_TO_PATIENT_SUCCESS:
      return {
        ...state,
        issueToPatientObj: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case ISSUE_TO_PATIENT_FAILED:
      return {
        ...state,
        issueToPatientObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case ISSUE_TO_PROCESS:
      return {
        ...state,
        issueToProcessObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case ISSUE_TO_PROCESS_SUCCESS:
      return {
        ...state,
        issueToProcessObj: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case ISSUE_TO_PROCESS_FAILED:
      return {
        ...state,
        issueToProcessObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case DISPOSE_INVENTORY:
      return {
        ...state,
        disposeInventoryObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case DISPOSE_INVENTORY_SUCCESS:
      return {
        ...state,
        disposeInventoryObj: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case DISPOSE_INVENTORY_FAILED:
      return {
        ...state,
        disposeInventoryObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case REQUEST_USER_DATA:
      return {
        ...state,
        registerPatientList: {
          loading: action.loading === false ? action.loading : true,
          data: [],
          error: null,
        },
      };

    case REQUEST_USER_DATA_SUCCESS:
      return {
        ...state,
        registerPatientList: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case REQUEST_USER_DATA_FAILED:
      return {
        ...state,
        registerPatientList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case PATIENT_SPECIFIC_INVENTORY:
      return {
        ...state,
        patientSpecificData: {
          loading: action.loading === false ? action.loading : true,
          data: [],
          error: null,
        },
      };

    case PATIENT_SPECIFIC_INVENTORY_SUCCESS:
      return {
        ...state,
        patientSpecificData: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case PATIENT_SPECIFIC_INVENTORY_FAILED:
      return {
        ...state,
        patientSpecificData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case INVENTORY_ADD_PATIENT:
      return {
        ...state,
        patientInfo: {
          loading: action.loading === false ? action.loading : true,
          data: [],
          error: null,
        },
      };

    case INVENTORY_ADD_PATIENT_SUCCESS:
      return {
        ...state,
        patientInfo: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case INVENTORY_ADD_PATIENT_FAILED:
      return {
        ...state,
        patientInfo: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRANSFER_INSTITUTE_LIST:
      return {
        ...state,
        transferInstitutionList: {
          loading: action.loading === false ? action.loading : true,
          data: [],
          error: null,
        },
      };

    case TRANSFER_INSTITUTE_LIST_SUCCESS:
      return {
        ...state,
        transferInstitutionList: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case TRANSFER_INSTITUTE_LIST_FAILED:
      return {
        ...state,
        transferInstitutionList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case INVENTORY_TRANSFER_PROCESS:
      return {
        ...state,
        transferProcessData: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };

    case INVENTORY_TRANSFER_PROCESS_SUCCESS:
      return {
        ...state,
        transferProcessData: {
          loading: false,
          data: action.payload.content,
          error: null,
        },
      };

    case INVENTORY_TRANSFER_PROCESS_FAILED:
      return {
        ...state,
        transferProcessData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_IN_PROCESS_DATA:
      return {
        ...state,
        getInProcessData: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };

    case GET_IN_PROCESS_DATA_SUCCESS:
      return {
        ...state,
        getInProcessData: {
          loading: false,
          data: action.payload.content,
          error: null,
        },
      };

    case GET_IN_PROCESS_DATA_FAILED:
      return {
        ...state,
        getInProcessData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case POST_IN_PROCESS_DATA:
      return {
        ...state,
        postInProcessData: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };

    case POST_IN_PROCESS_DATA_SUCCESS:
      return {
        ...state,
        postInProcessData: {
          loading: false,
          data: action.payload.content,
          error: null,
        },
      };

    case POST_IN_PROCESS_DATA_FAILED:
      return {
        ...state,
        postInProcessData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default inventoryReducer;
