import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  // ResponsiveContainer,
  // Legend,
} from 'recharts';

export const InventoryChart = ({ inventoryChartData }) => {
  // const [activeIndex, setActiveIndex] = useState(0);

  const [data, setData] = useState([
    { name: 'Whole Blood', value: 0 },
    { name: 'Plasma', value: 0 },
    { name: 'Platelets', value: 0 },
    { name: 'Red Cells', value: 0 },
  ]);

  useEffect(() => {
    if (inventoryChartData) {
      setData([
        {
          name: 'Whole Blood',
          value: inventoryChartData?.['Whole Blood'] ?? 0,
        },
        {
          name: 'Plasma',
          value:
            (inventoryChartData?.['Plasma – Convalescent'] || 0) +
              (inventoryChartData?.['Plasma – Apheresis Single Donor'] || 0) +
              (inventoryChartData?.['Plasma – Cryo Poor'] || 0) +
              (inventoryChartData?.['Plasma – Fresh Frozen'] || 0) || 0,
        },
        {
          name: 'Platelets',
          value:
            (inventoryChartData?.['Platelets – Apheresis Single Donor'] || 0) +
              (inventoryChartData?.['Platelets – Irradiated'] || 0) +
              (inventoryChartData?.['Platelets – Leukoreduced'] || 0) +
              (inventoryChartData?.['Platelets – Random Donor'] || 0) || 0,
        },
        {
          name: 'Red Cells',
          value:
            (inventoryChartData?.['Red Cells – Apheresis Single Donor'] || 0) +
              (inventoryChartData?.['Red Cells – Leukoreduced'] || 0) +
              (inventoryChartData?.['Red Cells – Packed'] || 0) +
              (inventoryChartData?.['Red Cells – Packed Irradiated'] || 0) || 0,
        },
      ]);
      // calulateValues(inventoryChartData?.)
    }
  }, [inventoryChartData]);

  const COLORS = ['#FFB946', '#DF5047', '#66A3FF', '#73AF00'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    // index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor='middle'
        dominantBaseline='central'
      >
        {percent === 0 ? '' : `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // const calulateValues = (values) => {
  // for (const key in values) {
  //   let key1=key.split(' ')[0];
  //   values.filter((key2)=>{
  //     return word.indexOf(key1) !== -1;
  //   }
  //   }
  // }

  const renderActiveShape = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    // startAngle,
    // endAngle,
    fill,
    payload,
    percent,
    value,
  }) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor='middle'
          dominantBaseline='central'
          fill={fill}
          style={{ fontSize: '34px' }}
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={90}
          endAngle={-270}
          // startAngle={startAngle}
          // endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          // startAngle={startAngle}
          // endAngle={endAngle}
          startAngle={90}
          endAngle={-270}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor="middle" dominantBaseline="central" fill="#333">{`${payload.name} - ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor="middle" dominantBaseline="central" fill="#999"> */}
        <path
          d={`M${sx},${sy}L${mx + (cos >= 0 ? -10 : 10)},${my}`}
          stroke={fill}
          fill='none'
        />
        <circle
          cx={ex + (cos >= 0 ? -32 : 32)}
          cy={ey}
          r={2}
          fill={fill}
          stroke='none'
        />
        <text
          x={ex + (cos >= 0 ? -2 : 2) * 12}
          y={ey}
          textAnchor='middle'
          dominantBaseline='central'
          fill='#333'
        >{`${payload.name} - ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? -2 : 2) * 12}
          y={ey}
          dy={18}
          textAnchor='middle'
          dominantBaseline='central'
          fill='#999'
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  return (
    <>
      <PieChart width={450} height={400} className='inventory-chart'>
        <Pie
          activeShape={renderActiveShape}
          data={data}
          cx='260'
          cy='180'
          innerRadius={95}
          fill='#8884d8'
          dataKey='value'
          label={renderCustomizedLabel}
          labelLine={false}
          startAngle={90}
          endAngle={-270}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>

      <div className='d-flex legends-invetory'>
        <div className='divRow'>
          <div className='divCell'>
            <div className='bullet plasma' />
          </div>
          <div className='divCell'>Whole Blood</div>
        </div>
        <div className='divRow'>
          <div className='divCell'>
            <div className='bullet platelets' />
          </div>
          <div className='divCell'>Plasma</div>
        </div>

        <div className='divRow'>
          <div className='divCell'>
            <div className='bullet redcell' />
          </div>
          <div className='divCell'>Platelets</div>
        </div>
        <div className='divRow'>
          <div className='divCell'>
            <div className='bullet blood' />
          </div>
          <div className='divCell'>Red Cells</div>
        </div>
      </div>
      {/* <div className='d-flex legends-invetory'>
        <div className='divRow'>
          <div className='divCell'>
            <div className='bullet plasma' />
          </div>
          <div className='divCell'>Whole Blood</div>
        </div>
        <div className='divRow'>
          <div className='divCell'>
            <div className='bullet platelets' />
          </div>
          <div className='divCell'>Plasma</div>
        </div>
        <div className='divRow'>
          <div className='divCell'>
            <div className='bullet platelets' />
          </div>
          <div className='divCell'>Platelets</div>
        </div>

        <div className='divRow'>
          <div className='divCell'>
            <div className='bullet redcell' />
          </div>
          <div className='divCell'>Red Cells</div>
        </div>
      </div> */}
    </>
  );
};
