import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { MONTHS } from '../../utils';

const CustomTooltip = ({ active, payload }) => {
  if (payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <span>{payload[0].value}</span>
      </div>
    );
  } else {
    return null;
  }
};

const CustomXAxixTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={10}
        dy={18}
        textAnchor='end'
        fill='#666'
        fontSize={10}
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomYAxixTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5} textAnchor='end' fill='#666' fontSize={10}>
        {payload.value}
      </text>
    </g>
  );
};

export const DonationChart = ({ chartData }) => {
  let data =
    chartData &&
    chartData
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((a) => {
        return {
          date: `${MONTHS[new Date(a.date).getMonth()]}  ${new Date(
            a.date
          ).getDate()}`,
          value: a.donations || a.camp,
        };
      });
  return (
    <AreaChart
      className='donation-chart'
      width={500}
      height={298}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray='7' vertical={false} />
      <XAxis dataKey='date' tick={CustomXAxixTick} tickLine={false} />
      <YAxis
        domain={[0, (dataMax) => dataMax + 5]}
        tick={CustomYAxixTick}
        tickLine={false}
      />
      <Tooltip content={<CustomTooltip />} />
      <Area type='monotone' dataKey='value' stroke='#AF0C0C' fill='#E8B1B1' />
    </AreaChart>
  );
};
