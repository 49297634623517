import React from "react";
import {Spinner} from "../../components";
import {LandscapeImage} from "../../components/Auth";
import {AuthHeader} from "../../components/AuthHeader";

function ForgotPasswordView(props) {
  return (
    <div className="bd-main">
      <AuthHeader />
      <div className="bd-container-fluid">

        <div className="bd-registration_main_grid">
          <LandscapeImage />
          <div>
            <div className="bd-registration" style={{marginTop: '65px'}}>
              {/* <div className="bd-logo"></div> */}
              <div className="bd-registration__body">
                <div className="bd-signin bd-forgot-pass">
                  <div className="bd-registration__header" style={{textAlign: 'left'}}>
                    <span>Forgot password</span>
                  </div>
                  <div className="bd-form">
                    <ul className="bd-forgot-pass__cont">
                      <li>
                        To recover your password you need to enter your registered
                    Email address.{" "}
                      </li>
                      <li>Please check your Email for the reset password link.</li>
                    </ul>
                    <div className="bd-form__row bd-invalid__email">
                      <label>Email ID</label>
                      <input
                        type="text"
                        placeholder="Alex@yahoo.com"
                        name="loginId"
                        onChange={(e) => props.onChangeText(e)}
                      />
                    </div>

                    <div className="bd-form__row bd-form__row--forgotpass">
                      <span className="bd-incorrect__msg">
                        {props.errorMsg
                          ? props.errorMsg
                          : props.forgetPassword && props.forgetPassword.error
                            ? props.forgetPassword.error
                            : ""}
                      </span>
                    </div>
                    <div className="bd-form__row bd-form__row--signin">
                      <div
                        className="bd-btn bd-btn--primary"
                        onClick={props.onSubmit}
                        disabled={props?.forgetPassword?.loading}
                      >
                        <span>
                          {props.forgetPassword?.loading ? (
                            <Spinner />
                          ) : (
                            <span>Send Email</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  );
}

export default ForgotPasswordView;
