import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { VALIDATE_DONOR_OTP } from '../../redux/actions/actionTypes';
import { Tabs, TabPane, Charts } from '../index';
import UserDetail from './UserDetail';
import DeclineDonation from './DeclineDonation';
import UserDetailTable from './UserDetailTable';

export function ProfileDetailPopup({
  tabs,
  profileData,
  hidePopup,
  onTabChange,
  activeTab,
  displayApproveDeclineButton,
  onQuestionnarieClick,
  displaySelfQuestionnarie,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showButtons, setShowButton] = useState(displayApproveDeclineButton);
  const [showTable, setShowTable] = useState(true);
  const [showDeclineDetails, setShowDeclineDetails] = useState(false);

  function onDeclineButtonClick() {
    setShowDeclineDetails(true);
    setShowButton(false);
    setShowTable(false);
  }

  function onApproveBtnClick() {
    // setShowButton(false);
    // setShowTable(false);
    onQuestionnarieClick();
    // history.push("/donation-form");
    dispatch({
      type: VALIDATE_DONOR_OTP,
      loading: false,
    });
  }

  return (
    <div className='bd-modal-wrap bd-modal-right bd-profile'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Profile of Donor ID - {profileData.donorId}</span>
          <i className='bludclose' onClick={hidePopup}></i>
        </div>
        <div className='bd-popup__body'>
          <div className='bd-profile__tbl'>
            <div className='bd-profile__detail--tbl'>
              <UserDetail profileData={profileData} />
              {/* {displaySelfQuestionnarie && (
                <div class="bd-questionnaire">
                  <span>View Questionnaire</span>
                </div>
              )} */}
              <div className='bd-graph__wrap'>
                <Charts chartType='line-chart' chartData={tabs[1]} />
              </div>
            </div>
            {showTable && (
              <Tabs onChange={onTabChange} activeTab={activeTab}>
                {tabs.map((record, i) => {
                  return (
                    <TabPane name={record.title} key={i}>
                      <UserDetailTable data={record.tableData} key={i} />
                    </TabPane>
                  );
                })}
              </Tabs>
            )}

            {showDeclineDetails && (
              <DeclineDonation
                setShowDeclineDetails={setShowDeclineDetails}
                hidePopup={hidePopup}
              />
            )}

            {showButtons && (
              <div className='bd-btn__wrap'>
                <div
                  className='bd-btn bd-btn--outline'
                  onClick={onDeclineButtonClick}
                >
                  <span>Decline</span>
                </div>
                <div
                  className='bd-btn bd-btn--primary'
                  onClick={onApproveBtnClick}
                >
                  <span>View Questionnaire</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox' />
    </div>
  );
}
