import React from 'react';
import TableOne from './TableOne';
import TableTwo from './TableTwo';
import TableThree from './TableThree';
import TableFour from './TableFour';
import TableFive from './TableFive';

export const Tables = (props) => {
  const renderTables = (props) => {
    switch (props.tableType) {
      case 'table-type-1':
        return (
          <TableOne
            tab={props.tab}
            columns={props.columns}
            tableData={props.tableData}
            {...props}
          />
        );
      case 'table-type-2':
        return (
          <TableTwo
            columns={props.columns}
            tableData={props.tableData}
            {...props}
          />
        );
      case 'table-type-3':
        return (
          <TableThree
            columns={props.columns}
            tableData={props.tableData}
            {...props}
          />
        );
      case 'table-type-4':
        return (
          <TableFour
            columns={props.columns}
            tableData={props.tableData}
            {...props}
          />
        );
      default:
        return (
          <TableFive
            columns={props.columns}
            tableData={props.tableData}
            {...props}
          />
        );
    }
  };
  return renderTables(props);
};
