import {
  GET_ADMIN_LIST,
  GET_ADMIN_LIST_SUCCESS,
  GET_ADMIN_LIST_FAILED,
  GET_ADMIN_DATA,
  GET_ADMIN_DATA_SUCCESS,
  GET_ADMIN_DATA_FAILED,
  POST_ADD_ADMIN,
  POST_ADD_ADMIN_SUCCESS,
  POST_ADD_ADMIN_FAILED,
  POST_EDIT_ADMIN,
  POST_EDIT_ADMIN_SUCCESS,
  POST_EDIT_ADMIN_FAILED,
  ACTIVE_INACTIVE_DELETE_ADMIN,
  ACTIVE_INACTIVE_DELETE_ADMIN_SUCCESS,
  ACTIVE_INACTIVE_DELETE_ADMIN_FAILED,
} from '../actions/actionTypes';
// import { login } from '../../utils';

const initialState = {
  getAdminList: {
    loading: false,
    data: [],
    error: null,
  },
  getAdminData: {
    loading: false,
    data: [],
    error: null,
  },
  postAddAdmin: {
    loading: false,
    data: [],
    error: null,
  },
  postEditAdmin: {
    loading: false,
    data: [],
    error: null,
  },
  changeStatus: {
    loading: false,
    data: [],
    error: null,
  },
};

const superAdminAdministratoReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_LIST:
      return {
        ...state,
        getAdminList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        getAdminList: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_ADMIN_LIST_FAILED:
      return {
        ...state,
        getAdminLIst: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_ADMIN_DATA:
      return {
        ...state,
        getAdminData: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_ADMIN_DATA_SUCCESS:
      return {
        ...state,
        getAdminData: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_ADMIN_DATA_FAILED:
      return {
        ...state,
        getAdminData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case POST_ADD_ADMIN:
      return {
        ...state,
        postAddAdmin: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case POST_ADD_ADMIN_SUCCESS:
      return {
        ...state,
        postAddAdmin: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case POST_ADD_ADMIN_FAILED:
      return {
        ...state,
        postAddAdmin: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case POST_EDIT_ADMIN:
      return {
        ...state,
        postEditAdmin: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case POST_EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        postEditAdmin: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case POST_EDIT_ADMIN_FAILED:
      return {
        ...state,
        postEditAdmin: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case ACTIVE_INACTIVE_DELETE_ADMIN:
      return {
        ...state,
        changeStatus: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case ACTIVE_INACTIVE_DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        changeStatus: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case ACTIVE_INACTIVE_DELETE_ADMIN_FAILED:
      return {
        ...state,
        changeStatus: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default superAdminAdministratoReducer;
