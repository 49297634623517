import React from 'react';
import { useHistory } from 'react-router-dom';
import back from '../../assets/images/back.png';
import TablePagination from '@material-ui/core/TablePagination';
import {
  Loader,
  Tables,
  SuccessPopup,
  FailPopup,
  InventoryModal,
} from '../../components';
import DropDown from '../../components/RDropDown';
import {
  REQUEST_VIEW_ALL_TAB_COLUMNS,
  BLOOD_GROUP_OPTIONS,
  BLOOD_COMPONENTS,
} from '../../utils/constants';
import { NgoInventoryModal } from '../../components/Popups/NgoInventoryModal';

export default function RequestViewAll({
  institutionType,
  ngoData,
  onAssignClick,
  page,
  rowsPerPage,
  requestTableData,
  acceptList,
  inventoryChangeStatus,
  handleChangeRowsPerPage,
  onHeaderClick,
  onIssueClick,
  onCancelClick,
  handleChangePage,
  headerDropDownChange,
  showAcceptPopup,
  setShowSuccess,
  setFail,
  showSuccess,
  loading,
  showFail,
  selectedUser,
  units,
  userList,
  dropDownChange,
  reset,
}) {
  const history = useHistory();

  const inventoryProps = {
    title: 'Accept & Issue',
    buttonName: 'Issue',
    data: acceptList.data,
    selectedUser: selectedUser,
    userList: userList,
    showDetail: true,
    defaultUnits: units,
    defaultUser: userList.filter((a) => a.id === showAcceptPopup.id)[0],
    dropDownChange: dropDownChange,
    onButtonlick: onIssueClick,
    onCancelClick: onCancelClick,
  };
  const ngoProps = {
    title: 'Accept & Assign',
    buttonName: 'Assign/Depute',
    data: ngoData,
    selectedUser: selectedUser,
    userList: userList,
    showDetail: true,
    defaultUnits: units,
    defaultUser: userList.filter((a) => a.id === showAcceptPopup.id)[0],
    dropDownChange: dropDownChange,
    onButtonlick: onAssignClick,
    onCancelClick: onCancelClick,
  };
  function goBack() {
    history.push('/');
  }
  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header'>
                <div className='d-flex align-items-center'>
                  <div
                    className='d-flex align-items-center fw-600 cursor-pointer'
                    onClick={goBack}
                  >
                    <img
                      src={back}
                      height='25px'
                      className='cursor-pointer mr-10 '
                      style={{ marginLeft: '5px' }}
                      alt='back'
                    />
                  </div>
                </div>
                <span className='bd-dashboard__main'>Request</span>
                <div className='bd-dorpdown'>
                  <div className='bd-dorpdown__selected detail-dd bg-dd'>
                    <DropDown
                      placeHolderText={'Blood Group'}
                      options={BLOOD_GROUP_OPTIONS}
                      fetchDropDownValue={headerDropDownChange}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
              </div>
              <div className='table-scroll'>
                <Tables
                  tab={'RequestTab'}
                  tableType={'table-type-1'}
                  columns={REQUEST_VIEW_ALL_TAB_COLUMNS}
                  tableData={requestTableData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  headerClick={onHeaderClick}
                />
              </div>
              <TablePagination
                component='div'
                count={requestTableData.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </section>
      </article>
      {showAcceptPopup.show &&
        (institutionType === 'NGO' ? (
          <NgoInventoryModal {...ngoProps} />
        ) : (
          <InventoryModal {...inventoryProps} />
        ))}

      {showSuccess && (
        <SuccessPopup
          title={
            institutionType !== 'NGO'
              ? acceptList.data.bloodGroup +
                ' ' +
                `${
                  BLOOD_COMPONENTS[acceptList.data.component] ||
                  acceptList.data.component
                }` +
                ' issued to patient'
              : 'Assign/Depute Successfully'
          }
          onContinueClick={() => {
            reset();
            setShowSuccess(false);
          }}
        />
      )}
      {showFail && (
        <FailPopup
          title={inventoryChangeStatus.error}
          onContinueClick={() => {
            reset();
            setFail(false);
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}
