import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import { TRANSACTION_BLUDUNIT_TABLE } from '../../utils/constants';

function BludUnitView({ bludUnitSortedList, onHeaderClick }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  //   const [count, setCount] = useState(0);
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  return (
    <>
      <div className='bd-tbl__inventory table-scroll'>
        <Tables
          tab={'BludUnit'}
          tableType={'table-type-1'}
          columns={TRANSACTION_BLUDUNIT_TABLE}
          tableData={bludUnitSortedList.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          headerClick={onHeaderClick}
        />
      </div>
      <TablePagination
        component='div'
        count={bludUnitSortedList.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

export default BludUnitView;
