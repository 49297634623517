import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import back from '../../assets/images/back.png';
import TablePagination from '@material-ui/core/TablePagination';
import SelfQuestionnarie from './SelfQuestionnaire';
import {
  Tables,
  ProfilePopup,
  ProfileDetailPopup,
  SuccessPopup,
  FailPopup,
  Loader,
} from '../../components';
import DropDown from '../../components/RDropDown';
import {
  DONATION_VIEW_ALL_TAB_COLUMNS,
  BLOOD_GROUP_OPTIONS,
} from '../../utils/constants';

export default function DonationViewAll({
  donorProfile,
  showProfilePopup,
  showVerifyOTP,
  showProfileDetailPopup,
  tabs,
  otpInput,
  onRequestClick,
  setShowProfilePopup,
  onVerifyClick,
  setShowVerifyOTP,
  onTabChange,
  activeTab,
  hidePopup,
  loading,
  handleChangeRowsPerPage,
  handleChangePage,
  page,
  rowsPerPage,
  donorTableData,
  errors,
  headerDropDownChange,
  onHeaderClick,
  showSuccess,
  showFail,
  setShowSuccess,
  setFail,
}) {
  const history = useHistory();
  const [viewSelfQuestionnarie, setViewSelfQuestionnarie] = useState(false);

  function onQuestionnarieClick() {
    setViewSelfQuestionnarie(true);
  }
  function declineClick() {
    setViewSelfQuestionnarie(false);
  }
  function approveClick() {
    setViewSelfQuestionnarie(false);
    history.push('/donation-form');
  }
  function goBack() {
    history.push('/');
  }

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header'>
                <div className='d-flex align-items-center'>
                  <div
                    className='d-flex align-items-center fw-600 cursor-pointer'
                    onClick={goBack}
                  >
                    <img
                      src={back}
                      height='25px'
                      className='cursor-pointer mr-10 '
                      style={{ marginLeft: '5px' }}
                      alt='Back Btn'
                    />
                  </div>
                </div>
                <span className='bd-dashboard__main'>Donation</span>
                <div className='bd-dorpdown'>
                  <div className='bd-dorpdown__selected detail-dd bg-dd'>
                    <DropDown
                      placeHolderText={'Blood Group'}
                      options={BLOOD_GROUP_OPTIONS}
                      fetchDropDownValue={headerDropDownChange}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
              </div>
              <div className='table-scroll'>
                <Tables
                  tab={'DonationTab'}
                  tableType={'table-type-1'}
                  columns={DONATION_VIEW_ALL_TAB_COLUMNS}
                  tableData={donorTableData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  headerClick={onHeaderClick}
                />
              </div>
              <TablePagination
                component='div'
                count={donorTableData.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </section>
      </article>
      {showProfilePopup.show && (
        <ProfilePopup
          profileData={donorProfile}
          onRequestClick={onRequestClick}
          showVerifyOTP={showVerifyOTP}
          otpInput={otpInput}
          setShowProfilePopup={setShowProfilePopup}
          onVerifyClick={onVerifyClick}
          setShowVerifyOTP={setShowVerifyOTP}
        />
      )}
      {showProfileDetailPopup.show && (
        <ProfileDetailPopup
          profileData={donorProfile}
          tabs={tabs}
          onTabChange={onTabChange}
          activeTab={activeTab}
          hidePopup={hidePopup}
          displayApproveDeclineButton={true}
          displaySelfQuestionnarie={true}
          onQuestionnarieClick={onQuestionnarieClick}
        />
      )}
      {viewSelfQuestionnarie && (
        <SelfQuestionnarie
          profileData={donorProfile}
          declineClick={declineClick}
          approveClick={approveClick}
          test={'sdgj'}
        />
      )}
      {showSuccess && (
        <SuccessPopup
          title={`The Donor has been Blocked successfully!`}
          onContinueClick={() => {
            hidePopup();
            setShowSuccess(false);
          }}
        />
      )}
      {showFail && (
        <FailPopup
          title={errors}
          onContinueClick={() => {
            setFail(false);
            hidePopup();
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}
