import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SuperAdminAddView from '../../views/SuperAdminAddView/SuperAdminAddView';
import {
  triggerPostAddAdmin,
  triggerPostEditAdmin,
} from '../../redux/actions/superAdminAdministratorAction';
import { SuccessPopup } from '../../components/Popups/SuccessPopup';
import { FailPopup } from '../../components';
import { useLocation } from 'react-router-dom';
import { ageCalculation, getpermission } from '../../utils';

function SuperAdminAddContainer() {
  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();
  const [addAdmin, setAddAdmin] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    email: '',
    mobileNumber: '',
    address: '',
    state: '',
    district: '',
    city: '',
    zipCode: '',
    country: '',
  });
  const [sucessPopup, setShowSuccessPopup] = useState(false);
  const [failPopup, setShowFailPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [succesMsg, setSuccesMsg] = useState('');

  const [gender, setGenderSelected] = useState('');
  const [userManagement, setUserManagement] = useState({});
  const [instituteManagement, setInstitutionsManagement] = useState({});
  const [subscription, setSubcription] = useState({});
  const [billing, setBilling] = useState({});
  const [configurations, setConfigration] = useState({});
  const [admins, setAdmins] = useState({});

  // console.log(location.state.rawDataList);
  // console.log(location.state.editProfile ? 'EDIT ADMIN' : 'ADD ADMIN');

  useEffect(() => {
    // if is Comes from  SuperAdminAdministratorProfilepopup
    if (location.state.editProfile) {
      setAddAdmin({
        firstName: location.state.rawDataList?.personalInfo?.firstName,
        lastName: location.state.rawDataList?.personalInfo?.lastName,
        email: location.state.rawDataList?.personalInfo?.email,
        mobileNumber: location.state.rawDataList?.personalInfo?.mobileNumber,
        dateOfBirth: ageCalculation(
          location.state.rawDataList?.personalInfo?.dateOfBirth
        ),
        gender: location.state.rawDataList?.personalInfo?.gender,
        address:
          location.state.rawDataList?.personalInfo?.addressDetails?.address,
        zipCode:
          location.state.rawDataList?.personalInfo?.addressDetails?.zipCode,
        state: location.state.rawDataList?.personalInfo?.addressDetails?.state,
        city: location.state.rawDataList?.personalInfo?.addressDetails?.city,
        district:
          location.state.rawDataList?.personalInfo?.addressDetails?.district,
        country:
          location.state.rawDataList?.personalInfo?.addressDetails?.country,
      });
      setGenderSelected(location.state.rawDataList?.personalInfo?.gender);
      setUserManagement(
        location.state.rawDataList?.permissions?.userManagement
      );
      setInstitutionsManagement(
        location.state.rawDataList?.permissions?.instituteManagement
      );
      setSubcription(location.state.rawDataList?.permissions?.subscription);
      setBilling(location.state.rawDataList?.permissions?.billing);
      setConfigration(location.state.rawDataList?.permissions?.configurations);
      setAdmins(location.state.rawDataList?.permissions?.admins);
    }
  }, [location]);

  function onChangeText(e, name) {
    let updateAddAdminObj = { ...addAdmin };
    if (name) {
      updateAddAdminObj[name] = new Date(e);
    } else {
      updateAddAdminObj[e.target.name] = e.target.value;
    }
    setAddAdmin(updateAddAdminObj);
  }
  function onsubmit(e) {
    e.preventDefault();
    const {
      firstName,
      lastName,
      dateOfBirth,
      email,
      mobileNumber,
      address,
      state,
      district,
      city,
      zipCode,
      country,
    } = addAdmin;
    if (gender) addAdmin.gender = gender;
    let addParam = {
      type: 'SuperAdmin',
      personalInfo: {
        firstName,
        lastName,
        dateOfBirth,
        email,
        gender,
        mobileNumber,
        countryCode: '+91',
        addressDetails: {
          country,
          address,
          state,
          district,
          city,
          zipCode,
        },
      },
      permissions: {
        userManagement,
        instituteManagement,
        subscription,
        billing,
        configurations,
        admins,
      },
    };

    let editParam = {
      id: location.state.rawDataList?._id,
      personalInfo: {
        firstName,
        lastName,
        dateOfBirth,
        email,
        gender,
        mobileNumber,
        countryCode: '+91',
        addressDetails: {
          country,
          address,
          state,
          district,
          city,
          zipCode,
        },
      },
      details: {
        permissions: {
          userManagement,
          instituteManagement,
          subscription,
          billing,
          configurations,
          admins,
        },
      },
    };

    if (
      !firstName ||
      !lastName ||
      !dateOfBirth ||
      !email ||
      !mobileNumber ||
      !address ||
      !state ||
      !district ||
      !country
    ) {
      setShowFailPopup(true);
      setErrorMsg('All Personal Info. Is Required');
    } else if (
      !Object.keys(userManagement).length ||
      !Object.keys(instituteManagement).length ||
      !Object.keys(subscription).length ||
      !Object.keys(configurations).length ||
      !Object.keys(subscription).length ||
      !Object.keys(billing).length ||
      !Object.keys(admins).length
    ) {
      setShowFailPopup(true);
      setErrorMsg('All Permissions Is Required');
    } else {
      if (location.state.editProfile) {
        // post api call here EDIT  ADMIN
        dispatch(triggerPostEditAdmin(editParam), editCallBack());
      } else {
        // post api call here ADD ADMIN
        dispatch(triggerPostAddAdmin(addParam), addCallBack());
      }
    }
  }
  function addCallBack() {
    setShowSuccessPopup(true);
    setSuccesMsg('Admin Add Successfully');
  }
  function editCallBack() {
    setShowSuccessPopup(true);
    setSuccesMsg('Admin Edit Successfully');
  }

  function onContinueClick() {
    history.push('/Super-Admin/Administrators');
  }

  return (
    <>
      <SuperAdminAddView
        profileEdit={location.state.editProfile}
        set={location.state.rawDataList?.permissions?.userManagement}
        // addAdmin
        adminObj={addAdmin}
        sucessPopup={sucessPopup}
        onChangeText={onChangeText}
        onSubmit={onsubmit}
        gender={gender}
        setGenderSelected={setGenderSelected}
        userManagement={userManagement}
        setUserManagement={setUserManagement}
        instituteManagement={instituteManagement}
        setInstitutionsManagement={setInstitutionsManagement}
        subscription={subscription}
        setSubcription={setSubcription}
        billing={billing}
        setBilling={setBilling}
        configurations={configurations}
        setConfigration={setConfigration}
        admins={admins}
        setAdmins={setAdmins}
        onContinueClick={onContinueClick}
      />
      {sucessPopup && (
        <SuccessPopup title={succesMsg} onContinueClick={onContinueClick} />
      )}
      {failPopup && (
        <FailPopup
          title={errorMsg}
          onContinueClick={() => setShowFailPopup(false)}
        />
      )}
    </>
  );
}

export default SuperAdminAddContainer;
