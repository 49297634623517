import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SuccessPopup, Tables } from '../../components';
import TablePagination from '@material-ui/core/TablePagination';
import back from '../../assets/images/back.png';
import { CAMP_DONORS_COLUMNS } from '../../utils/constants';

import CampDonorsForm_one_Container from '../../containers/CampContainer/CampDonorsForm_one_Container';
import CampDonorsForm_two_Conatainer from '../../containers/CampContainer/CampDonorsForm_two_Conatainer';
import { POST_DONATE_FORM } from '../../redux/actions/actionTypes';
function CampDonorsView({
  campDetails,
  finalDonorsList,
  setDonorPage,
  page,
  donorSearch,
  setDonorSearch,
  rowsPerPage,
  onHeaderClick,
  handleChangePage,
  handleChangeRowsPerPage,
  // DONATE btn form
  donatFrom,
  onCloseClick,
  // PROCEED btn form
  proceedForm,
  ProceedDonorData,
  // donor obj pass to form
  campDonorsListObj,
  APICallAgain,
}) {
  const dispatch = useDispatch();
  // const { donorDonateForm } = useSelector((state) => state.campsReducer);
  // const [popUpMsg, setPopUpMsg] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  function onBack() {
    setDonorPage({
      id: '',
      show: false,
    });
  }

  function closePopup() {
    setShowSuccess(false);
    dispatch({
      type: POST_DONATE_FORM,
      loading: false,
    });
  }
  // useEffect(() => {
  //   if (
  //     donorDonateForm?.data?.code === 200 &&
  //     donorDonateForm?.content?.data?.status === 6
  //   ) {
  //     setPopUpMsg('Donation Process Phase 1 Done');
  //     setShowSuccess(true);
  //   } else if (donorDonateForm?.data?.code === 200) {
  //     setPopUpMsg('Donation Process Completed');
  //     setShowSuccess(true);
  //   }
  // }, [donorDonateForm]);

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search'>
                <div className='d-flex align-items-center'>
                  <div
                    className='d-flex align-items-center fw-600 cursor-pointer'
                    onClick={onBack}
                  >
                    <img
                      src={back}
                      height='25px'
                      className='cursor-pointer'
                      style={{ marginLeft: '5px' }}
                      alt='Back Btn'
                    />
                  </div>
                </div>
                <div>
                  <div className='bd-dashboard__main ml-20 mb-10'>
                    {campDetails?.data?.campName}
                  </div>
                  <div className=' ml-20'>
                    {campDetails?.data?.address?.description}
                  </div>
                </div>
                <div className='dashboard_tiles_header'>
                  <div className='bd_error fw-600'></div>
                </div>
                <div className='bd-hader'>
                  <input
                    type='text'
                    value={donorSearch}
                    onChange={(e) => {
                      setDonorSearch(e.target.value);
                    }}
                    placeholder='Search'
                    style={{ padding: 5, marginRight: 10 }}
                  />
                </div>
              </div>
              <div className='table-scroll'>
                <Tables
                  tab={'AvailableForDonation'}
                  tableType={'table-type-1'}
                  columns={CAMP_DONORS_COLUMNS}
                  tableData={finalDonorsList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  headerClick={onHeaderClick}
                />
              </div>
              <TablePagination
                component='div'
                count={finalDonorsList.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              {donatFrom && (
                <CampDonorsForm_one_Container
                  onCloseClick={onCloseClick}
                  campDonorsListObj={campDonorsListObj}
                  APICallAgain={APICallAgain}
                  onBack={onBack}
                />
              )}
              {proceedForm && (
                <CampDonorsForm_two_Conatainer
                  onCloseClick={onCloseClick}
                  campDonorsListObj={campDonorsListObj}
                  ProceedDonorData={ProceedDonorData}
                  APICallAgain={APICallAgain}
                  setShowSuccess={setShowSuccess}
                />
              )}
            </div>
            {showSuccess && (
              <SuccessPopup
                // title={'Donation successfully Completed'}
                // title={popUpMsg}
                onContinueClick={closePopup}
              />
            )}
          </div>
        </section>
      </article>
    </>
  );
}

export default CampDonorsView;
