import {
  GET_ADMIN_LIST,
  GET_ADMIN_LIST_FAILED,
  GET_ADMIN_LIST_SUCCESS,
  GET_ADMIN_DATA,
  GET_ADMIN_DATA_FAILED,
  GET_ADMIN_DATA_SUCCESS,
  POST_ADD_ADMIN,
  POST_ADD_ADMIN_FAILED,
  POST_ADD_ADMIN_SUCCESS,
  POST_EDIT_ADMIN,
  POST_EDIT_ADMIN_FAILED,
  POST_EDIT_ADMIN_SUCCESS,
  ACTIVE_INACTIVE_DELETE_ADMIN,
  ACTIVE_INACTIVE_DELETE_ADMIN_SUCCESS,
  ACTIVE_INACTIVE_DELETE_ADMIN_FAILED,
} from './actionTypes';
import { makeAPICall } from '../../utils';
import {
  API_BASE_PATH,
  GET_ADMINS_LIST_URL,
  GET_ADMINS_DATA_URL,
  ADD_ADMIN_URL,
  EDIT_ADMIN_URL,
  CHANGE_ADMIN_STATUS_URL,
} from '../../utils/config';
import { urlSearchParams } from '../../utils';

export function triggerGetAdminsList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_ADMINS_LIST_URL}`,
      dispatch,
      defaultAction: GET_ADMIN_LIST,
      successAction: GET_ADMIN_LIST_SUCCESS,
      failedAction: GET_ADMIN_LIST_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function triggerGetAdminData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_ADMINS_DATA_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_ADMIN_DATA,
      successAction: GET_ADMIN_DATA_SUCCESS,
      failedAction: GET_ADMIN_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerPostAddAdmin(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ADD_ADMIN_URL}`,
      dispatch,
      defaultAction: POST_ADD_ADMIN,
      successAction: POST_ADD_ADMIN_SUCCESS,
      failedAction: POST_ADD_ADMIN_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    delete params.params.type;
    makeAPICall(params);
  };
}
export function triggerPostEditAdmin(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${EDIT_ADMIN_URL}`,
      dispatch,
      defaultAction: POST_EDIT_ADMIN,
      successAction: POST_EDIT_ADMIN_SUCCESS,
      failedAction: POST_EDIT_ADMIN_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    delete params.params.type;
    makeAPICall(params);
  };
}

export function triggerChangeStaus(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${CHANGE_ADMIN_STATUS_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: ACTIVE_INACTIVE_DELETE_ADMIN,
      successAction: ACTIVE_INACTIVE_DELETE_ADMIN_SUCCESS,
      failedAction: ACTIVE_INACTIVE_DELETE_ADMIN_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    delete params.params.type;
    makeAPICall(params);
  };
}
