import React from 'react';
import question from '../../assets/images/question.png';
import { Spinner } from '../Spinner';

export function DeletePopup({ onCancelClick, title, onDeleteClick, loading }) {
  return (
    <div className='bd-modal-wrap'>
      <div className='bd-popup si-fade-in bd-addinventory'>
        <div className='bd-popup__body'>
          <div className='bd-addinvent_wrap'>
            <div className='bd-addinvent__top db-bg__red'>
              <img src={question} height='80px' />
            </div>
            <div className='bd-addinvent__cont'>
              <p className='fail-title'>
                {title ? title : 'Do you want to delete?'}
              </p>

              <div className='bd-add-cancel__labs--btn'>
                <span
                  className='bd-btn bd-btn--outline'
                  onClick={onCancelClick}
                >
                  Cancel
                </span>
                <span
                  class='bd-btn bd-btn--primary d-flex'
                  onClick={onDeleteClick}
                  disabled={loading}
                >
                  {loading && <Spinner />}
                  Delete
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
