/*container*/
import SuperAdminLoginContainer from '../../../containers/LoginContainer/SuperAdminLoginContainer';
import LoginContainer from '../../../containers/LoginContainer';
import RegisterContainer from '../../../containers/RegisterContainer';
import ChangePasswordContainer from '../../../containers/ChangePasswordContainer';
import ForgetPasswordContainer from '../../../containers/ForgetPasswordContainer';
import OTPContainer from '../../../containers/OTPContainer';
import SetPasswordContainer from '../../../containers/SetPasswordContainer';
import DashboardContainer from '../../../containers/DashboardContainer';
// import CampsContainer from '../../../containers/CampsContainer';
import CampContainer from '../../../containers/CampContainer';
import ChatContainer from '../../../containers/ChatContainer';
import InventoryContainer from '../../../containers/InventoryContainer';
import LabResultContainer from '../../../containers/LabResultContainer';
import PeopleContainer from '../../../containers/PeopleContainer';
import SettingsContainer from '../../../containers/SettingsContainer';
import AddDonarContainer from '../../../containers/AddDonarContainer';
import DonationFormContainer from '../../../containers/DashboardContainer/DonationFormContainer';
import AddPatientContainer from '../../../containers/AddPatientContainer/index';
import VerifyViewAllContainer from '../../../containers/DashboardContainer/VerifyViewAllContainer';
import LabSamplesContainer from '../../../containers/LabResultContainer/Samples';
import DonorsNearByContainer from '../../../containers/DashboardContainer/DonorsNearByContainer';
import AvailableForDonatinContainer from '../../../containers/DashboardContainer/AvailableForDonatinContainer';
/*views*/
import RequestViewAllContainer from '../../../containers/DashboardContainer/RequestViewAllContainer';
import DonationViewAllContainer from '../../../containers/DashboardContainer/DonationViewAllContainer';
import PatientContainer from '../../../containers/Patient';
import Profile from '../../../containers/Profile';
import TransferContainer from '../../../containers/InventoryContainer/TransferContainer';
import ReceiptContainer from '../../../containers/InventoryContainer/ReceiptContainer';
import AgeingContainer from '../../../containers/InventoryContainer/AgeingContainer';
import CampsDonorList from '../../../containers/CampsContainer/CampsDonorList';
import IssuedContainer from '../../../containers/InventoryContainer/IssuedContainer';
import ReceivedContainer from '../../../containers/InventoryContainer/ReceivedContainer';
import CampDonorsContainer from '../../../containers/CampContainer/CampDonorsContainer';
import InProcessContainer from '../../../containers/InventoryContainer/InProcessContainer';
import UpdateStockContainer from '../../../containers/InventoryContainer/UpdateStockContainer';

/*SUPER ADMIN container*/
import SuperAdminDashboardContainer from '../../../containers/SuperAdminDashboardContainer/SuperAdminDashboardContainer';
import SuperAdminTransactionsContainer from '../../../containers/SuperAdminTransactionsContainer/SuperAdminTransactionsContainer';
import SuperAdminUsersContainer from '../../../containers/SuperAdminUsersContainer/SuperAdminUsersContainer';
import SuperAdminInstituionsContainer from '../../../containers/SuperAdminInstituionsContainer/SuperAdminInstituionsContainer';
import SuperAdminPricingContainer from '../../../containers/SuperAdminPricingContainer/SuperAdminPricingContainer';
import SuperAdminConfigratonContainer from '../../../containers/SuperAdminConfigratonContainer/SuperAdminConfigratonContainer';
import SuperAdminSubscriptionContainer from '../../../containers/SuperAdminSubscriptionContainer/SuperAdminSubscriptionContainer';
import SuperAdminAdministratorContainer from '../../../containers/SuperAdminAdministratorContainer/SuperAdminAdministratorContainer';
import SuperAdminAddContainer from '../../../containers/SuperAdminAddContainer/SuperAdminAddContainer';
import InstitutionDetailPageContainer from '../../../containers/SuperAdminInstituionsContainer/InstitutionDetailPageContainer';

const publicRoutesList = [
  {
    path: '/Super-Admin/login',
    path_: '/Super-Admin/login',
    restricted: true,
    private: false,
    component: SuperAdminLoginContainer,
    name: 'superAdminlogin',
    group: 'app entry',
    readablename: 'superAdminLogin',
  },
  {
    path: '/login',
    path_: '/login',
    restricted: true,
    private: false,
    component: LoginContainer,
    name: 'login',
    group: 'app entry',
    readablename: 'Login',
  },
  {
    path: '/signup',
    path_: '/signup',
    restricted: true,
    private: false,
    component: RegisterContainer,
    name: 'register',
    group: 'app entry',
    readablename: 'Register',
  },
  {
    path: '/register',
    path_: '/register',
    restricted: true,
    private: false,
    component: RegisterContainer,
    name: 'register',
    group: 'app entry',
    readablename: 'Register',
  },
  {
    path: '/setPassword',
    path_: '/setPassword',
    restricted: false,
    private: false,
    component: SetPasswordContainer,
    name: 'SetPassword',
    group: 'app entry',
    readablename: 'Set Password',
  },
  {
    path: '/forgetPassword',
    path_: '/forgetPassword',
    restricted: false,
    private: false,
    component: ForgetPasswordContainer,
    name: 'forgetPassword',
    group: 'app entry',
    readablename: 'Forget Password',
  },
  {
    path: '/changePassword',
    path_: '/changePassword',
    restricted: false,
    private: false,
    component: ChangePasswordContainer,
    name: 'ChangePassword',
    group: 'app entry',
    readablename: 'Change Password',
  },
  {
    path: '/otp',
    path_: '/otp',
    restricted: false,
    private: false,
    component: OTPContainer,
    name: 'Otp',
    group: 'app entry',
    readablename: 'Otp',
  },
];

const privateRoutesList = [
  ////////  Super Admin Dashboard  START ////////
  {
    path: '/Super-Admin/Dashboard',
    path_: '/Super-Admin/Dashboard',
    restricted: false,
    private: false,
    component: SuperAdminDashboardContainer,
    name: 'home1',
    group: 'app entry',
    readablename: 'home1',
  },
  {
    path: '/Super-Admin/Instituions',
    path_: '/Super-Admin/Instituions',
    restricted: false,
    private: false,
    component: SuperAdminInstituionsContainer,
    name: 'SAinstitutions',
    group: 'app entry',
    readablename: 'SAinstitutions',
  },
  {
    path: '/Super-Admin/Instituions-Detail-Page',
    path_: '/Super-Admin/Instituions-Detail-Page',
    restricted: false,
    private: false,
    component: InstitutionDetailPageContainer,
    name: 'SAinstitutions-page',
    group: 'app entry',
    readablename: 'SAinstitutions-page',
  },
  {
    path: '/Super-Admin/Administrators',
    path_: '/Super-Admin/Administrators',
    restricted: false,
    private: false,
    component: SuperAdminAdministratorContainer,
    name: 'saAdministrators',
    group: 'app entry',
    readablename: 'saAdministrators',
  },
  {
    path: '/Super-Admin/Users',
    path_: '/Super-Admin/Users',
    restricted: false,
    private: false,
    component: SuperAdminUsersContainer,
    name: 'SAusers',
    group: 'app entry',
    readablename: 'SAusers',
  },

  {
    path: '/Super-Admin/Transaction',
    path_: '/Super-Admin/Transaction',
    restricted: false,
    private: false,
    component: SuperAdminTransactionsContainer,
    name: 'SAtransaction',
    group: 'app entry',
    readablename: 'SAtransaction',
  },
  {
    path: '/Super-Admin/Pricing',
    path_: '/Super-Admin/Pricing',
    restricted: false,
    private: false,
    component: SuperAdminPricingContainer,
    name: 'SApricing',
    group: 'app entry',
    readablename: 'SApricing',
  },
  {
    path: '/Super-Admin/Configration',
    path_: '/Super-Admin/Configration',
    restricted: false,
    private: false,
    component: SuperAdminConfigratonContainer,
    name: 'SAconfigration',
    group: 'app entry',
    readablename: 'SAconfigration',
  },
  {
    path: '/Super-Admin/Subcription-plan',
    path_: '/Super-Admin/Subcription-plan',
    restricted: false,
    private: false,
    component: SuperAdminSubscriptionContainer,
    name: 'plans',
    group: 'app entry',
    readablename: 'plans',
  },
  {
    path: '/Super-Admin/Add_Admin',
    path_: '/Super-Admin/Add_Admin',
    restricted: false,
    private: false,
    component: SuperAdminAddContainer,
    name: 'add_Admin',
    group: 'app entry',
    readablename: 'add_Admin',
  },

  ////////  Super Admin Dashboard END ////////

  {
    path: '/',
    path_: '/',
    restricted: false,
    private: false,
    component: DashboardContainer,
    name: 'home',
    group: 'app entry',
    readablename: 'home',
  },
  {
    path: '/nearby-donors',
    path_: '/nearby-donors',
    restricted: false,
    private: false,
    component: DonorsNearByContainer,
    name: 'nearbyContainer',
    group: 'app entry',
    readablename: 'nearbyContainer',
  },
  {
    path: '/available-for-donation',
    path_: '/available-for-donation',
    restricted: false,
    private: false,
    component: AvailableForDonatinContainer,
    name: 'available-for-donation',
    group: 'app entry',
    readablename: 'available-for-donation',
  },
  // {
  //   path: '/camps',
  //   path_: '/camps',
  //   restricted: false,
  //   private: false,
  //   component: CampsContainer,
  //   name: 'camps',
  //   group: 'app entry',
  //   readablename: 'camps',
  // },
  {
    path: '/camp',
    path_: '/camp',
    restricted: false,
    private: false,
    component: CampContainer,
    name: 'camp',
    group: 'app entry',
    readablename: 'camp',
  },
  {
    path: '/camp/donors_List',
    path_: '/camp/donors_List',
    restricted: false,
    private: false,
    component: CampDonorsContainer,
    name: 'camp_donors',
    group: 'app entry',
    readablename: 'camp_donors',
  },
  {
    path: '/chat',
    path_: '/chat',
    restricted: false,
    private: false,
    component: ChatContainer,
    name: 'chat',
    group: 'app entry',
    readablename: 'chat',
  },
  {
    path: '/inventory',
    path_: '/inventory',
    restricted: false,
    private: false,
    component: InventoryContainer,
    name: 'inventory',
    group: 'app entry',
    readablename: 'inventory',
  },
  {
    path: '/inventory-transfer',
    path_: '/inventory-transfer',
    restricted: false,
    private: false,
    component: TransferContainer,
    name: 'transferContainer',
    group: 'app entry',
    readablename: 'transferContainer',
  },
  {
    path: '/inventory-in-process',
    path_: '/inventory-in-process',
    restricted: false,
    private: false,
    component: InProcessContainer,
    name: 'in-processContainer',
    group: 'app entry',
    readablename: 'in-processContainer',
  },
  {
    path: '/inventory-in-process/updatestock',
    path_: '/inventory-in-process/updatestock',
    restricted: false,
    private: false,
    component: UpdateStockContainer,
    name: 'update-stockContainer',
    group: 'app entry',
    readablename: 'update-stockContainer',
  },
  {
    path: '/inventory-receipt',
    path_: '/inventory-receipt',
    restricted: false,
    private: false,
    component: ReceiptContainer,
    name: 'ReceiptContainer',
    group: 'app entry',
    readablename: 'ReceiptContainer',
  },
  {
    path: '/inventory-ageing-report',
    path_: '/inventory-ageing-report',
    restricted: false,
    private: false,
    component: AgeingContainer,
    name: 'AgeingContainer',
    group: 'app entry',
    readablename: 'AgeingContainer',
  },
  {
    path: '/inventory-issued',
    path_: '/inventory-issued',
    restricted: false,
    private: false,
    component: IssuedContainer,
    name: 'IssuedContainer',
    group: 'app entry',
    readablename: 'IssuedContainer',
  },
  {
    path: '/received',
    path_: '/received',
    restricted: false,
    private: false,
    component: ReceivedContainer,
    name: 'receivedContainer',
    group: 'app entry',
    readablename: 'receivedContainer',
  },
  {
    path: '/labresult',
    path_: '/labresult',
    restricted: false,
    private: false,
    component: LabResultContainer,
    name: 'labResult',
    group: 'app entry',
    readablename: 'Lab Result',
  },

  {
    path: '/labresult-samples',
    path_: '/labresult-samples',
    restricted: false,
    private: false,
    component: LabSamplesContainer,
    name: 'labResult',
    group: 'app entry',
    readablename: 'Lab Result',
  },

  {
    path: '/people',
    path_: '/people',
    restricted: false,
    private: false,
    component: PeopleContainer,
    name: 'people',
    group: 'app entry',
    readablename: 'People',
  },
  {
    path: '/settings',
    path_: '/settings',
    restricted: false,
    private: false,
    component: SettingsContainer,
    name: 'settings',
    group: 'app entry',
    readablename: 'settings',
  },
  {
    path: '/dashboard/request-viewall',
    path_: '/dashboard/request-viewall',
    restricted: false,
    private: false,
    component: RequestViewAllContainer,
    name: 'request viewall',
    group: 'app entry',
    readablename: 'request',
  },
  {
    path: '/dashboard/donation-viewall',
    path_: '/dashboard/donation-viewall',
    restricted: false,
    private: false,
    component: DonationViewAllContainer,
    name: 'donation viewall',
    group: 'app entry',
    readablename: 'donation',
  },

  {
    path: '/add-donor',
    path_: '/add-donor',
    restricted: false,
    private: false,
    component: AddDonarContainer,
    name: 'add donor',
    group: 'app entry',
    readablename: 'donor',
  },
  {
    path: '/donation-form',
    path_: '/donation-form',
    restricted: false,
    private: false,
    component: DonationFormContainer,
    name: 'donation by donor',
    group: 'donation by donor',
    readablename: 'donor',
  },

  {
    path: '/add-patient',
    path_: '/add-patient',
    restricted: false,
    private: false,
    component: AddPatientContainer,
    name: 'add donor',
    group: 'app entry',
    readablename: 'donor',
  },
  {
    path: '/dashboard/verify-request-viewall',
    path_: '/dashboard/verify-request-viewall',
    restricted: false,
    private: false,
    component: VerifyViewAllContainer,
    name: 'verify request viewall',
    group: 'verify request viewall',
    readablename: 'request',
  },
  {
    path: '/patient',
    path_: '/patient',
    restricted: false,
    private: false,
    component: PatientContainer,
    name: 'patient',
    group: 'patient',
    readablename: 'request',
  },
  {
    path: '/profile',
    path_: '/profile',
    restricted: false,
    private: false,
    component: Profile,
    name: 'profile',
    group: 'profile',
    readablename: 'profile',
  },
  {
    path: '/chat',
    path_: '/chat',
    restricted: false,
    private: false,
    component: ChatContainer,
    name: 'chat',
    group: 'chat',
    readablename: 'chat',
  },

  {
    path: '/camp-details/:id',
    path_: '/camp-details/:id',
    restricted: false,
    private: false,
    component: CampsDonorList,
    name: 'camp',
    group: 'camp',
    readablename: 'camp',
  },
];

export { publicRoutesList, privateRoutesList };
