import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { persistor } from '../../redux/store';

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  function onLogout() {
    persistor.purge();
    localStorage.removeItem('TOKEN_KEY');
    window.location.reload();
  }

  function renderMenu() {
    return (
      <div
        className={[
          'bd-acimg__ac',
          showMenu ? 'visibility-visible' : 'visibility-hidden',
        ].join(' ')}
      >
        <div className='bd-acimg__ac--profile'>
          <i className='bluduser'></i>
          <Link to={'/profile'}>
            <div className='bd-acimg'>Profile</div>
          </Link>
        </div>
        <div className='bd-acimg__ac--logout'>
          <i className='bludpower-off'></i>
          <span onClick={() => onLogout()} style={{ marginLeft: '11px' }}>
            Logout
          </span>
        </div>
      </div>
    );
  }

  return (
    <header className='bd-hader'>
      {/* <div className="bd-search active">
        <input type="text" name="" />
        <i className="bludsearch" />
      </div>
      <div className="bd-notification">
        <i className="bludnotification" />
      </div> */}
      <div className='bd-acimg'>
        <div
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <div>
            <i class='bluddonar'></i>
          </div>
        </div>
        {renderMenu()}
      </div>
    </header>
  );
}

export { Header };
