import React from 'react';

export default function SelfQuestionnarie({
  profileData,
  declineClick,
  approveClick,
  acceptTermsClick,
  acceptTermsQuestionnarie,
}) {
  let { donorId, name, bloodGroup, gender } = profileData;

  return (
    <div className='bd-modal-wrap bd-modal-right bd-self-assessment'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__body'>
          <div className='bd-assessment__top'>
            <div className='bd-assessment__top--lft'>
              <div>
                <span>Donor ID -</span>
                <span>{donorId || ''}</span>
              </div>
              <div>
                <span>Donor Name -</span>
                <span>{name || ''}</span>
              </div>
            </div>
            <div className='bd-assessment__top--mid'>
              <span>
                <b>{bloodGroup || ''}</b>
              </span>
            </div>
            <div className='bd-assessment__top--rgt'>
              <span>Self Assessment Questionnaire</span>
            </div>
          </div>
          <div className='bd-assessment__mid'>
            <ul className='bd-assessment__list'>
              <li>
                <span>
                  Have you or any of your close contacts (family members)
                  travelled abroad in the last 28 days?{' '}
                </span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>
                  Did you had any close contact with individuals who have
                  travelled from abroad within 28 days?
                </span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>
                  Do you or any of your close contacts (family members) have the
                  following syptoms?
                </span>
                <span className='bd-answer'>Cough, Fever</span>
              </li>
              <li>
                <span>Have you eaten anything in the last 4 hours?</span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>Have you slept well last night?</span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>
                  Have you taken aspirin / antibiotics in the last 3 days?
                </span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>Are you taking any medicine at present?</span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>-</span>
                <ul className='bd-assessment__list--sub'>
                  <li>
                    <span>Have you donated blood in the last 3 months?</span>
                    <span className='bd-answer'>Yes</span>
                  </li>
                  <li>
                    <span>
                      Did you have any discomfort during prior blood donation?
                    </span>
                    <span className='bd-answer'>Yes</span>
                  </li>
                </ul>
              </li>
              <li>
                <span>-</span>
                <ul className='bd-assessment__list--sub'>
                  <li>
                    <span>Have you had jaundice in the last one year?</span>
                    <span className='bd-answer'>Yes</span>
                  </li>
                  <li>
                    <span>Has your blood ever tested positive for HBsAg?</span>
                    <span className='bd-answer'>Yes</span>
                  </li>
                  <li>
                    <span>
                      Has any of your family members suffered from jaundice in
                      the last 1 year?
                    </span>
                    <span className='bd-answer'>Yes</span>
                  </li>
                </ul>
              </li>
              <li>
                <span>
                  Have you had any reason to believe you have been infected by
                  the virus that causes AIDS?
                </span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>
                  In the last 6 months have you had any of these? select the
                  ones you had.
                </span>
                <span className='bd-answer'>
                  Unexplained weight loss, Swollen glands
                </span>
              </li>
              <li>
                <span>
                  Have you had any of following? select the ones you had.
                </span>
                <span className='bd-answer'>Dengue</span>
              </li>
              <li>
                <span>
                  Have you had any accident, operation or dental procedure in
                  the last 12 months?
                </span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>
                  Have you had any tattoo, acuouncture or ear piercing in the
                  last 6 months?
                </span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>
                  Have you received ay blood or blood components in the last 6
                  months?
                </span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>
                  Have you had any of the following? if yes please discuss with
                  doctor present, select the one’s you had.
                </span>
                <span className='bd-answer'>
                  Fanting attacks, Heart disease, Polycythemia vera{' '}
                </span>
              </li>
              <li>
                <span>
                  Have you had cold / cough / fever in the last 1 week?
                </span>
                <span className='bd-answer'>Yes</span>
              </li>
              <li>
                <span>Have you consumed alcohol in the past 24 hours?</span>
                <span className='bd-answer'>Yes</span>
              </li>
              {gender === 'Female' && (
                <li>
                  <span>Reproductive system</span>
                  <ul className='bd-assessment__list--sub'>
                    <li>
                      <span>
                        Are you menstruating / pregnant / breast feeding?
                      </span>
                      <span className='bd-answer'>Yes</span>
                    </li>
                    <li>
                      <span>Have you a child less than one year of age?</span>
                      <span className='bd-answer'>Yes</span>
                    </li>
                    <li>
                      <span>
                        Have you had an abortion in the last 6 months?
                      </span>
                      <span className='bd-answer'>Yes</span>
                    </li>
                  </ul>
                </li>
              )}

              <li>
                <span>
                  I would like to be informed about my blood test results in
                  person.
                </span>
                <span className='bd-answer'>Yes</span>
              </li>
            </ul>
          </div>
          <div className='bd-assessment__bot'>
            <div className='bd-confirm__input'>
              <span className='bd-checkbox'>
                <input
                  type='checkbox'
                  checked={acceptTermsQuestionnarie}
                  onChange={() => acceptTermsClick(acceptTermsQuestionnarie)}
                />
                <b></b>
              </span>
              <span>
                I have checked and confirm the self assessment is complete and
                the Donor is Fit to Donate
              </span>
            </div>
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline' onClick={declineClick}>
                <span>Back</span>
              </div>
              <div
                className='bd-btn bd-btn--primary'
                onClick={approveClick}
                disabled={!acceptTermsQuestionnarie}
              >
                <span>Approve</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
