import React from 'react';
import { RadioButton, CheckBox } from '../../components';
import { ageCalculation } from '../../utils';
export default function DonationForm({
  donarProfile,
  donorInfo,
  onApproveClick,
  errorFields,
  onCancelClick,
  onCheckboxChange,
  onRadioChange,
  onInputChange,
  selectedCheckbox,
  // disableApprove,
  // ErrrorMsg,
}) {
  let userAge = donorInfo?.age ? ageCalculation(donorInfo.age) : '';
  return (
    <article className='bd-article'>
      <section className='bd-dashboard'>
        <form onSubmit={onApproveClick}>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft bd-equalcolumn'>
              <div className='bd-dashboard__header'>
                <span className='bd-dashboard__main'>
                  Donation by Donor ID: {donarProfile?.donorId ?? ''}
                </span>
              </div>
              <div className='bd-dbd__form'>
                <section className='bd-sec__1'>
                  <div className='bd-sec__1--1'>
                    <span>
                      {donorInfo?.firstName} {donorInfo?.lastName}
                    </span>
                    <span>{donorInfo?.gender}</span>
                    <span>{userAge}</span>
                    <span>
                      BMI<b>{donorInfo?.bmi}</b>
                    </span>
                  </div>
                </section>
                <section className='bd-sec__2'>
                  <div className='bd-sec__2--3'>
                    <div>
                      <RadioButton
                        label='Blood'
                        name='radio1'
                        value='wholeBlood'
                        onRadioChange={onRadioChange}
                      />
                    </div>
                    <div>
                      <RadioButton
                        label='Platelets'
                        name='radio1'
                        value='platelets'
                        onRadioChange={onRadioChange}
                      />
                    </div>
                    <div>
                      <RadioButton
                        label='Plasma'
                        name='radio1'
                        value='plasma'
                        onRadioChange={onRadioChange}
                      />
                    </div>
                  </div>
                  <div className='bd-sec__2--2'>
                    <div>
                      <span>Height</span>
                      <input
                        className={errorFields['height'] ? 'form-error' : ''}
                        type='number'
                        placeholder=''
                        value={donorInfo.height}
                        name='height'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>cm</span>
                    </div>
                    <div>
                      <span>Weight</span>
                      <input
                        className={errorFields['weight_1'] ? 'form-error' : ''}
                        type='number'
                        placeholder=''
                        value={donorInfo.weight_1}
                        name='weight_1'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>kg</span>
                    </div>
                  </div>
                  <div className='bd-sec__2--2'>
                    <div>
                      <span>Hb</span>
                      <input
                        className={errorFields['hb'] ? 'form-error' : ''}
                        type='number'
                        placeholder=''
                        value={donorInfo.hb}
                        name='hb'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>g/dL</span>
                    </div>
                    <div>
                      <span>Blood Pressure</span>
                      <input
                        className={errorFields['bp'] ? 'form-error' : ''}
                        type='number'
                        placeholder=''
                        value={donorInfo.bp}
                        name='bp'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>mmHg</span>
                    </div>
                  </div>
                </section>
                <section className='bd-sec__3'>
                  <div className='bd-sec__3--1'>
                    <span>
                      Is the donor (veins) fit to donate Plasma/Platelets?
                    </span>
                  </div>
                  <div className='bd-sec__3--2'>
                    <div>
                      <RadioButton
                        label='Yes'
                        name='radio2'
                        value='yes'
                        onRadioChange={onRadioChange}
                      />
                    </div>
                    <div>
                      <RadioButton
                        label='No'
                        name='radio2'
                        value='no'
                        onRadioChange={onRadioChange}
                      />
                    </div>
                  </div>
                </section>
                <section className='bd-sec__4'>
                  <div className='bd-sec__4--1'>
                    <div>
                      <span>Kit Number</span>
                      <input
                        className={errorFields['kitNumber'] ? 'form-error' : ''}
                        type='text'
                        placeholder=''
                        value={donorInfo.kitNumber}
                        name='kitNumber'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div>
                      <span>Bag / Unit ID</span>
                      <input
                        className={errorFields['unitId'] ? 'form-error' : ''}
                        type='text'
                        placeholder=''
                        value={donorInfo.unitId}
                        name='unitId'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className='bd-sec__4--2'>
                    <div className='bd-sec__4--head'>
                      <span>Samples</span>
                    </div>
                    <div>
                      <span># 1 ID</span>
                      <input
                        className={
                          errorFields['sampleId_1'] ? 'form-error' : ''
                        }
                        type='text'
                        placeholder=''
                        value={donorInfo.sampleId_1}
                        name='sampleId_1'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div>
                      <span># 2 ID</span>
                      <input
                        className={
                          errorFields['sampleId_2'] ? 'form-error' : ''
                        }
                        type='text'
                        placeholder=''
                        value={donorInfo.sampleId_2}
                        name='sampleId_2'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div>
                      <span># 3 ID</span>
                      <input
                        className={
                          errorFields['sampleId_3'] ? 'form-error' : ''
                        }
                        type='text'
                        placeholder=''
                        value={donorInfo.sampleId_3}
                        name='sampleId_3'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                </section>
                <section className='bd-sec__3'>
                  <div className='bd-sec__3--1'>
                    <span>Bag Type</span>
                  </div>
                  <div className='bd-sec__3--2'>
                    <div>
                      <RadioButton
                        label='Single'
                        name='radio9'
                        value='single'
                        onRadioChange={onRadioChange}
                      />
                    </div>
                    <div>
                      <RadioButton
                        label='Double'
                        name='radio9'
                        value='double'
                        onRadioChange={onRadioChange}
                      />
                    </div>
                    <div>
                      <RadioButton
                        label='SAGM'
                        name='radio9'
                        value='SAGM'
                        onRadioChange={onRadioChange}
                      />
                    </div>
                    <div>
                      <RadioButton
                        label='Quardruple'
                        name='radio9'
                        value='quardruple'
                        onRadioChange={onRadioChange}
                      />
                    </div>
                  </div>
                </section>
                <section className='bd-sec__6'>
                  <div className='bd-sec__6--head'>
                    <span>Blood / Platelet Donation</span>
                  </div>
                  <div className='bd-sec__6--1'>
                    <div>
                      <span>Doctor</span>
                      <input
                        className={errorFields['doctor'] ? 'form-error' : ''}
                        type='text'
                        placeholder=''
                        value={donorInfo.doctor}
                        name='doctor'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div>
                      <span>Phlebotomist</span>
                      <input
                        className={
                          errorFields['phlebotomist'] ? 'form-error' : ''
                        }
                        type='text'
                        placeholder=''
                        value={donorInfo.phlebotomist}
                        name='phlebotomist'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className='bd-sec__6--2'>
                    <div>
                      <span>Start Time</span>
                      <input
                        className={errorFields['startTime'] ? 'form-error' : ''}
                        type='number'
                        placeholder=''
                        value={donorInfo.startTime}
                        name='startTime'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>Hrs</span>
                    </div>
                    <div>
                      <span>End Time</span>
                      <input
                        className={errorFields['endTime'] ? 'form-error' : ''}
                        type='number'
                        placeholder=''
                        value={donorInfo.endTime}
                        name='endTime'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>Hrs</span>
                    </div>
                  </div>
                  <div className='bd-sec__6--2'>
                    <div>
                      <span>Volume</span>
                      <input
                        className={errorFields['volume'] ? 'form-error' : ''}
                        type='number'
                        placeholder=''
                        value={donorInfo.volume}
                        name='volume'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>ml</span>
                    </div>
                    <div>
                      <span>Weight</span>
                      <input
                        className={errorFields['weight_2'] ? 'form-error' : ''}
                        type='number'
                        placeholder=''
                        value={donorInfo.weight_2}
                        name='weight_2'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>g</span>
                    </div>
                  </div>
                </section>
                <section className='bd-sec__7'>
                  <div className='bd-sec__7--1'>
                    <span>Sample to be tested by</span>
                    <input
                      className={
                        errorFields['sampleToBeTested'] ? 'form-error' : ''
                      }
                      type='text'
                      placeholder=''
                      // value={donorInfo.sampleToBeTested || 'Self'}
                      value={donorInfo.sampleToBeTested}
                      name='sampleToBeTested'
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                  <div className='bd-sec__7--2'>
                    <div className='bd-sec__7--2-1'>
                      <span>Blood Bag Stored at</span>
                      <input
                        className={
                          errorFields['bloodBagStoredAt'] ? 'form-error' : ''
                        }
                        type='text'
                        placeholder=''
                        value={donorInfo.bloodBagStoredAt}
                        name='bloodBagStoredAt'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div className='bd-at'>
                      <span>at</span>
                    </div>
                    <div className='bd-sec__7--2-2'>
                      <input
                        className={
                          errorFields['temperature'] ? 'form-error' : ''
                        }
                        type='number'
                        placeholder=''
                        value={donorInfo.temperature}
                        name='temperature'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>&#8451;</span>
                    </div>
                  </div>
                  <div className='bd-sec__7--3'>
                    <span>Data posted by</span>
                    <input
                      className={errorFields['dataPostBy'] ? 'form-error' : ''}
                      type='text'
                      placeholder='Your Name'
                      value={donorInfo.dataPostBy}
                      name='dataPostBy'
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </section>
              </div>
            </div>
            <div className='bd-dashboard__rgt bd-equalcolumn'>
              <div className='bd-dbd__form'>
                <section className='bd-sec__8'>
                  <div className='bd-sec__8--1'>
                    <div>
                      <input
                        className={errorFields['aadharNo'] ? 'form-error' : ''}
                        placeholder='Aadhaar'
                        type='text'
                        value={donorInfo.aadharNo}
                        name='aadharNo'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div>
                      <input
                        className={
                          errorFields['confirmAadharNo'] ? 'form-error' : ''
                        }
                        placeholder='Confirm Aadhaar'
                        type='text'
                        value={donorInfo.confirmAadharNo}
                        name='confirmAadharNo'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className='bd-sec__8--2'>
                    <div>
                      <input
                        className={errorFields['mobileNo'] ? 'form-error' : ''}
                        placeholder='Change in Mobile no.'
                        type='number'
                        value={donorInfo.mobileNo}
                        name='mobileNo'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div>
                      <input
                        className={errorFields['emailId'] ? 'form-error' : ''}
                        placeholder='Change of email ID'
                        type='text'
                        value={donorInfo.emailId}
                        name='emailId'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                </section>

                <section className='bd-sec__10'>
                  <ul>
                    <li>
                      <CheckBox
                        keyindex={'checkbox_1'}
                        id={'checkbox_1'}
                        checked={selectedCheckbox['checkbox_1']}
                        onChange={onCheckboxChange}
                      />
                      Willing to donate for free
                    </li>
                    <li>
                      <CheckBox
                        keyindex={'checkbox_2'}
                        id={'checkbox_2'}
                        checked={selectedCheckbox['checkbox_2']}
                        onChange={onCheckboxChange}
                      />
                      Have verified the Identity and contact information
                    </li>
                    <li>
                      <CheckBox
                        keyindex={'checkbox_3'}
                        id={'checkbox_3'}
                        checked={selectedCheckbox['checkbox_3']}
                        onChange={onCheckboxChange}
                      />
                      Have explained about the protocols & precautions for
                      donation
                    </li>
                    <li>
                      <CheckBox
                        keyindex={'checkbox_4'}
                        id={'checkbox_4'}
                        checked={selectedCheckbox['checkbox_4']}
                        onChange={onCheckboxChange}
                      />
                      Pre-donation health check questionnaire filled
                    </li>
                    <li>
                      <CheckBox
                        keyindex={'checkbox_5'}
                        id={'checkbox_5'}
                        checked={selectedCheckbox['checkbox_5']}
                        onChange={onCheckboxChange}
                      />
                      Self Assessment Questionnaire verified and found OK
                    </li>
                  </ul>
                </section>

                <section className='bd-sec__11'>
                  <ul>
                    <li>
                      <label>
                        Was the donor comfortable during the process?
                      </label>
                      <div className='bd-radio__wrap'>
                        <div>
                          <RadioButton
                            label='Yes'
                            name='radio3'
                            value='yes'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                        <div>
                          <RadioButton
                            label='No'
                            name='radio3'
                            value='no'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <label>
                        Was there any clotting issues during / after the
                        process?
                      </label>
                      <div className='bd-radio__wrap'>
                        <div>
                          <RadioButton
                            label='Yes'
                            name='radio4'
                            value='yes'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                        <div>
                          <RadioButton
                            label='No'
                            name='radio4'
                            value='no'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <label>
                        Did the donor face any issue post donation - recovery?
                      </label>
                      <div className='bd-radio__wrap'>
                        <div>
                          <RadioButton
                            label='Yes'
                            name='radio5'
                            value='yes'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                        <div>
                          <RadioButton
                            label='No'
                            name='radio5'
                            value='no'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <label>
                        Donor is willing to participate in Blood Donation Camps?
                      </label>
                      <div className='bd-radio__wrap'>
                        <div>
                          <RadioButton
                            label='Yes'
                            name='radio6'
                            value='yes'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                        <div>
                          <RadioButton
                            label='No'
                            name='radio6'
                            value='no'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <label>
                        Donor is able to read, write and communicate?
                      </label>
                      <div className='bd-radio__wrap'>
                        <div>
                          <RadioButton
                            label='Yes'
                            name='radio7'
                            value='yes'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                        <div>
                          <RadioButton
                            label='No'
                            name='radio7'
                            value='no'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <label>
                        Donor can send and receive messages on mobile phone?
                      </label>
                      <div className='bd-radio__wrap'>
                        <div>
                          <RadioButton
                            label='Yes'
                            name='radio8'
                            value='yes'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                        <div>
                          <RadioButton
                            label='No'
                            name='radio8'
                            value='no'
                            onRadioChange={onRadioChange}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className='bd-sec__11--1'>
                    <textarea
                      placeholder='Notes'
                      value={donorInfo.notes}
                      name='notes'
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                  </div>
                </section>
                <section className='bd-sec__12'>
                  <div className='bd-sec__12--1'>
                    <CheckBox
                      key={'checkbox_7'}
                      id={'checkbox_7'}
                      checked={selectedCheckbox['checkbox_7']}
                      onChange={onCheckboxChange}
                    />
                    Have explained about the protocols & precautions for
                    donation
                  </div>
                  <div className='form-inline-error text-center '>
                    {Object.entries(errorFields).filter((a) => a[1]).length !==
                      0 && 'Please fill on the Details and Questions'}
                  </div>
                  {/* <div className='form-inline-error'>{ErrrorMsg}</div> */}
                  <div className='bd-sec__12--2 '>
                    <div className='bd-btn__wrap'>
                      <div
                        className='bd-btn bd-btn--outline'
                        onClick={onCancelClick}
                      >
                        <span>Cancel</span>
                      </div>
                      <button
                        type='submit'
                        className='bd-btn bd-btn--primary'
                        // disabled={true}
                        // onClick={onApproveClick}
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </form>
      </section>
    </article>
  );
}
