import React from "react";
import { LandscapeImage } from "../../components/Auth";
import { AuthHeader } from "../../components/AuthHeader";

function SetPasswordView(props) {
  return (
    <div className="bd-main">
      <AuthHeader />
      <div className="bd-container-fluid">
        <div className="bd-registration_main_grid">
          <LandscapeImage />
          <div>
          <div
            className="bd-registration bd-setpassword"
            style={{ marginTop: "65px" }}
          >
            <div className="bd-registration__body">
              <div className="bd-setpass__lft">
                <div className="bd-registration__header">
                  <span>Set Password</span>
                </div>
                <div className="bd-form">
                  <div className="bd-form__row bd-form__row--pass">
                    <input
                      type={props.showPassword ? "text" : "Password"}
                      placeholder="Enter Password"
                      name="password"
                      onChange={props.onChangeText}
                    />
                    <i
                      onClick={props.showPasswordToggle}
                      className={
                        props.showPassword ? "bd-eyeIcon bd-show" : "bd-eyeIcon"
                      }
                    ></i>
                  </div>
                  <div className="bd-form__row bd-form__row--pass">
                    <input
                      type={props.showConfirmPassword ? "text" : "Password"}
                      placeholder="Re-enter Password"
                      name="confirmPassword"
                      onChange={props.onChangeText}
                    />
                    <i
                      onClick={props.showConfirmPasswordToggle}
                      className={
                        props.showConfirmPassword
                          ? "bd-eyeIcon bd-show"
                          : "bd-eyeIcon"
                      }
                    ></i>
                  </div>
                  <div className="bd-form__row bd-form__row--forgotpass">
                    <span className="bd-incorrect__msg">
                      {(props?.errorMsg || props?.setPassObj?.error) ?? ""}
                    </span>
                  </div>
                  <div className="bd-form__row bd-form__row--setpass">
                    <div
                      onClick={props.onSubmit}
                      disabled={props.setPassObj.loading}
                      className="bd-btn bd-btn--primary"
                    >
                      <span>Set Password</span>
                    </div>
                  </div>
                  <hr
                    style={{ borderColor: "rgba(151, 151, 151, 0.2)" }}
                  />
                </div>
              </div>
              <div className="bd-setpass__rgt">
                <div
                  className="bd-registration__header bd-changepass-header"
             
                >
                  <span>Password must have 8 characters</span>
                </div>
                <ul>
                  <li>You can have a combination of:</li>
                  <li>- Upper-case characters (A-Z)</li>
                  <li>- Lower-case characters (a-z)</li>
                  <li>- Numbers (0-9) </li>
                  <li>- Special characters (!,@,#,$,%,^,&,*,etc) </li>
                </ul>
              </div>
            </div>
          </div>
          </div>
     
        </div>
      </div>
    </div>
  );
}

export default SetPasswordView;
