import React from 'react';

function ResultHeader({ labCriteriaComponents }) {
  const arr = [
    { name: 'Sample Date', sub: '' },
    { name: 'Sample ID', sub: '' },
    { name: 'Group', sub: '' },
    { name: 'Laboratory', sub: '' },
  ];

  labCriteriaComponents.map((ele) => {
    arr.push({
      name: ele?.name,
      sub: `${ele.min}-${ele.max}`,
      id: ele.id,
    });
  });

  arr.push({ name: 'Result', sub: '' }, { name: 'Result Date', sub: '' });

  return (
    <div className='bd-tbl__head'>
      <div className='bd-tbl__row'>
        {arr.map((data) => {
          return (
            <div className={`bd-tbl__cell td${data.id}`}>
              {/* <div id={`td${data.id}`} className={`bd-tbl__cell`}> */}
              <span>{data.name}</span>
              {data.sub !== '' && (
                <span className='bd-tbl__head--sub'>{data.sub}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ResultHeader;
