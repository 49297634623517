import React, { useEffect, useState } from 'react';
import DropDown from '../../components/RDropDown';
import { CheckBox } from '../index';
import { formatDate } from '../../utils';

export const NgoInventoryModal = ({
  title,
  buttonName,

  data,
  userList,
  showDetail,
  defaultUnits,
  defaultUser,
  onCancelClick,
  onButtonlick,
  dropDownChange,
}) => {
  const [dropdownList, setDropdownList] = useState([]);
  const [unitValue, setUnitValue] = useState(defaultUnits || 0);
  const [modalData, setModalData] = useState(null);

  const checkboxClick = (e) => {
    let data = [...modalData?.donors];
    data &&
      data.map((a) => {
        if (a._id === e.target.id) {
          a['isSelected'] = e.target.checked;
        }
      });
    setUnitValue(modalData?.donors.filter((a) => a.isSelected).length);
    setModalData({
      ...modalData,
      data: data,
    });
  };

  useEffect(() => {
    if (data?.donors !== null || data?.donors !== undefined) {
      let obj = data?.donors;
      if (obj) {
        let list = [];
        obj.map((a, i) => {
          let o = {
            label: i + 1,
            value: i + 1,
          };
          list.push(o);
        });
        setDropdownList(list);
      }
      setModalData(obj);
      selectRecord(unitValue);
    }
  }, [data]);

  const selectRecord = (value) => {
    if (data?.donors && data.donors.length !== 0) {
      let _data = [...data.donors];
      let list = [];
      _data.forEach((a, i) => {
        a['isSelected'] = i < value * 1;
        let o = {
          label: i + 1,
          value: i + 1,
        };
        list.push(o);
      });

      setDropdownList(list);
      setModalData({
        ...modalData,
        donors: _data,
      });
    }
  };
  const handleOnChange = (e) => {
    setUnitValue(e.value);
    selectRecord(e.value);
  };
  const cells = ['', 'Name', 'Donor ID', 'Last Donated', 'Address'];

  let disabledBtn = true;
  if (modalData?.donors && modalData.donors.length) {
    disabledBtn = false;
  }
  // object payload variable

  return (
    <div className='bd-modal-wrap bd-accept-issue'>
      <div className='bd-popup si-fade-in dashboard-request-popup'>
        <div className='bd-popup__head'>
          <span>{title}</span>
          <div className='bd-popup__head--rgt'>
            <div className='bd-blood-type'>
              <span>
                <b>{data?.request?.bloodGroup}</b>
              </span>
              <span>{data?.request?.component}</span>
            </div>
          </div>
        </div>
        <div
          className='bd-form__row'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <label>Quantity</label>
          <div className='bd-dorpdown inventory-dd'>
            <div className='bd-dorpdown__selected'>
              <DropDown
                width={'200px'}
                options={dropdownList}
                defaultValue={{ label: unitValue, value: unitValue }}
                fetchDropDownValue={handleOnChange}
              />
              <i className='bludbig-arrow' />
            </div>
          </div>
        </div>
        <div className='bd-popup__body'>
          <div className='bd-accept-issue__tbl'>
            <div className='bd-tbl bd-tbl__theme1'>
              <div className='bd-tbl__body--scroll'>
                <div className='bd-tbl__body'>
                  {modalData?.donors && modalData?.donors.length > 0 && (
                    <div className='bd-tbl__row'>
                      {cells.map((cell) => {
                        return (
                          <div className='bd-tbl__cell'>
                            <span>{cell}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {modalData?.donors && modalData?.donors.length > 0 ? (
                    modalData?.donors.map((record, i) => {
                      return (
                        <div key={'row' + i} className='bd-tbl__row'>
                          <div className='bd-tbl__cell'>
                            <CheckBox
                              keyindex={`checkbox_${record._id}`}
                              id={record._id}
                              checked={record.isSelected}
                              onChange={checkboxClick}
                            />
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{`${record?.firstName}  ${record?.lastName}`}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{record?._id ?? '--'}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>
                              {/* {formatDate(record.lastDonationDate) || '--'} */}
                              {record.lastDonationDate
                                ? formatDate(record.lastDonationDate)
                                : '--'}
                            </span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>--</span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className='noData_center'>
                      <div className='bd-tbl__body'>
                        <div className='bd-nodata'>
                          <span>No Data Available</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='bd-accept-issue__details'>
            {showDetail && (
              <>
                <div className='bd-accept-issue__bot'>
                  <label>Details</label>
                  <div className='bd-dorpdown'>
                    <div className='bd-dorpdown__selected dd-zindex'>
                      <DropDown
                        width={'200px'}
                        options={userList.map((a) => {
                          return {
                            label: a.name,
                            value: a.id,
                          };
                        })}
                        defaultValue={{
                          label: defaultUser?.name,
                          value: defaultUser?.id,
                        }}
                        fetchDropDownValue={dropDownChange}
                      />
                      <i className='bludbig-arrow' />
                    </div>
                  </div>
                </div>

                <div className='bd-accept-issue__bot mb-20'>
                  <label>Comment</label>
                  <div className='bd-accept-issue__comment'>
                    <span>{data?.request?.comments}</span>
                  </div>
                </div>
              </>
            )}
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline' onClick={onCancelClick}>
                <span>Cancel</span>
              </div>
              <div
                className='bd-btn bd-btn--primary bd-btn--issue'
                disabled={disabledBtn}
                onClick={() =>
                  disabledBtn
                    ? null
                    : onButtonlick({
                        requestId: data?.request?._id || '',
                        donorsList: modalData?.donors.filter(
                          (e) => e.isSelected
                        ),
                        Date: data.request?.requiredBy,
                      })
                }
              >
                <span>{buttonName}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
};
// let requestId = type === 'camp' ? campID : data.request._id;
