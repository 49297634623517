import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PendingTabView from './PendingTabView';
import VerifyTabListView from './VerifyTabListView';
import { sorting } from '../../utils';
import DropDown from '../../components/RDropDown';
import { BLOOD_GROUP_OPTIONS } from '../../utils/constants';
import back from '../../assets/images/back.png';

export default function VerifyTabViewAll({
  activeTab,
  onTabChange,
  onViewClick,
}) {
  const history = useHistory();

  const { requestVerifyObj, requestVerifiedObj } = useSelector(
    (state) => state.dashboardReducer
  );

  const [count, setCount] = useState(0);

  const [pendingViewData, setPendingViewData] = useState([]);
  const [VerifiedViewData, setVerifiedViewData] = useState([]);

  function ViewButton(props) {
    return (
      <div class='bd-request-otp' id={props._id}>
        <div
          className='bd-request-accept-blur brder_Radius bd-request-otp-stars'
          onClick={props.onClick}
          id={props._id}
        >
          <span id={props._id} onClick={props.onClick}>
            <i id={props._id} className='bludaccept'></i>
          </span>
        </div>
        <div
          onClick={props.onClick}
          id={props._id}
          className=' bd-request-accept bd-black active'
        >
          <span id={props._id}>Accept </span>
        </div>
      </div>
    );
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    if (activeTab === 'Pending') {
      let data = [...pendingViewData];
      data.sort((a, b) => {
        if (count % 2 === 0) {
          return sorting(a, b, attribute);
        } else {
          return sorting(b, a, attribute);
        }
      });
      setPendingViewData(data);
      return setCount(count + 1);
    } else if (activeTab === 'Verified') {
      let data = [...VerifiedViewData];
      data.sort((a, b) => {
        if (count % 2 === 0) {
          return sorting(a, b, attribute);
        } else {
          return sorting(b, a, attribute);
        }
      });
      setVerifiedViewData(data);
      return setCount(count + 1);
    }
  }

  function headerDropDownChange(e) {
    if (activeTab === 'Pending') {
      let data = setPendingData(requestVerifyObj);
      if (e.value === 'All/Any') {
        data = [...data];
      } else {
        data = data.filter((a) => a.bloodGroup === e.value);
      }
      return setPendingViewData(data);
    } else if (activeTab === 'Verified') {
      let data = setVerifiedData(requestVerifiedObj);
      if (e.value === 'All/Any') {
        data = [...data];
      } else {
        data = data.filter((a) => a.bloodGroup === e.value);
      }
      return setVerifiedViewData(data);
    }
  }
  function setPendingData(tabData) {
    let data = [];
    tabData.data &&
      tabData.data.forEach((ele) => {
        let obj = {
          name: [
            ele.details.firstName ? ele.details.firstName : '--',
            ele.details.lastName ? ele.details.lastName : '--',
          ].join(' '),
          bloodGroup: ele.bloodGroup ? ele.bloodGroup : '--',
          units: ele.units ? ele.units : '--',
          component: ele.component ? ele.component : '--',
          condition: ele.condition ? ele.condition : '--',
          hospital: ele.hospitalName ? ele.hospitalName : '--',
          requiredBy: ele.requiredBy
            ? moment(new Date(ele.requiredBy)).format('DD MMM YYYY')
            : '--',
          view: (
            <ViewButton id={ele._id} onClick={(e) => onViewClick(e, ele._id)} />
          ),
        };
        data.push(obj);
      });
    return data;
  }
  function setVerifiedData(verifiedData) {
    let data = [];
    verifiedData.data &&
      verifiedData.data.forEach((ele) => {
        let obj = {
          name: [
            ele.details.firstName ? ele.details.firstName : '--',
            ele.details.lastName ? ele.details.lastName : '--',
          ].join(' '),
          bloodGroup: ele.bloodGroup ? ele.bloodGroup : '--',
          units: ele.units ? ele.units : '--',
          component: ele.component ? ele.component : '--',
          condition: ele.condition ? ele.condition : '--',
          hospital: ele.hospitalName ? ele.hospitalName : '--',
          requiredBy: ele.requiredBy
            ? moment(new Date(ele.requiredBy)).format('DD MMM YYYY')
            : '--',
        };
        data.push(obj);
      });
    return data;
  }
  useEffect(() => {
    setPendingViewData(setPendingData(requestVerifyObj));
    setVerifiedViewData(setVerifiedData(requestVerifiedObj));
  }, [requestVerifyObj, requestVerifiedObj]);

  return (
    <article className='bd-article'>
      <section className='bd-dashboard'>
        <div className='bd-dashboard__container '>
          <div className='bd-dashboard__lft'>
            <div className='bd-dashboard__header bd-header__search'>
              <div className='d-flex align-items-center mr-10'>
                <div
                  className='d-flex align-items-center fw-600 cursor-pointer'
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  <img
                    src={back}
                    height='25px'
                    className='cursor-pointer'
                    style={{ marginLeft: '5px' }}
                  />
                </div>
              </div>
              <span className='bd-dashboard__main'>Request</span>
              <div className='bd-tab bd-tab-new width'>
                <>
                  <div
                    id='Pending'
                    className={activeTab === 'Pending' && 'active'}
                    onClick={onTabChange}
                    activetab={activeTab}
                  >
                    <span id='Pending' style={{ padding: '0 10px' }}>
                      Pending
                    </span>
                  </div>
                  <div
                    id='Verified'
                    className={activeTab === 'Verified' && 'active'}
                    onClick={onTabChange}
                    activetab={activeTab}
                  >
                    <span id='Verified' style={{ padding: '0 10px' }}>
                      Verified
                    </span>
                  </div>
                </>
              </div>
              <div className='bd-dorpdown'>
                <div className='bd-dorpdown__selected detail-dd bg-dd'>
                  <DropDown
                    placeHolderText={'Blood Group'}
                    options={BLOOD_GROUP_OPTIONS}
                    fetchDropDownValue={headerDropDownChange}
                  />
                  <i className='bludbig-arrow' />
                </div>
              </div>
            </div>
            {activeTab === 'Pending' && (
              <PendingTabView
                pendingViewData={pendingViewData}
                onHeaderClick={onHeaderClick}
              />
            )}
            {activeTab === 'Verified' && (
              <VerifyTabListView
                VerifiedViewData={VerifiedViewData}
                onHeaderClick={onHeaderClick}
              />
            )}
          </div>
        </div>
      </section>
    </article>
  );
}
