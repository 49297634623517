import React from 'react';

function ResultBody(props) {
  let { item, onRowClick } = props;
  let date = new Date(item.labResults.addedAt).toDateString().split(' ');
  return (
    <div className='bd-tbl__row' onClick={() => onRowClick(item)}>
      <div className='bd-tbl__cell'>
        <span>
          {date[2]} {date[1]} {date[3]}
        </span>
      </div>
      <div className='bd-tbl__cell'>
        <span>{item.labResults.reportNo}</span>
      </div>
      <div className='bd-tbl__cell bd-red__color bd-tbl__cell--center'>
        <span>{item.bloodGroup}</span>
      </div>
      <div className='bd-tbl__cell'>
        <span>{item.labResults.laboratory}</span>
      </div>

      {item.labResults.results.map((ele) => {
        return (
          <div className={`bd-tbl__cell td${ele.id}`}>
            <span>{ele.observation}</span>
          </div>
        );
      })}

      <div className='bd-tbl__cell'>
        <span>
          <i
            className={
              item.labResults.finalResult == 'Fail'
                ? 'bd-result-icon bd-red__color'
                : 'bd-result-icon bd-red__green'
            }
          ></i>
        </span>
      </div>
      <div className='bd-tbl__cell'>
        <span>
          {date[2]} {date[1]} {date[3]}
        </span>
      </div>
    </div>
  );
}

export default ResultBody;
