import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { sorting } from '../../utils';
import DonorNearByView from '../../views/DashboardView/DonorNearByView';
import { getDashboardTilesData } from '../../redux/actions/dashboardAction';

function DonorsNearByContainer() {
  const dispatch = useDispatch();

  const { dashboarTilesCount } = useSelector((state) => state.dashboardReducer);
  const DonorNearbyData =
    dashboarTilesCount?.data?.content?.dashboardData?.nearByDonors;
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);

  const [DonorNearbyTableData, setDonorNearbyTableData] = useState([]);
  const [search, setSearch] = useState('');

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...DonorNearbyTableData];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setDonorNearbyTableData(data);
    setCount(count + 1);
  }

  function headerDropDownChange(e) {
    let data = setData(DonorNearbyData);
    if (e.value === 'All/Any') {
      data = [...setData(DonorNearbyData)];
    } else {
      data = data.filter((a) => a.bloodGroup === e.value);
    }
    setDonorNearbyTableData(data);
  }

  function setData(sortedData) {
    let data = [];
    sortedData &&
      sortedData.forEach((record, i) => {
        let obj = {
          donorId: record?._id,
          bloodGroup: record?.bloodGroup,
          name: record?.firstName + ' ' + record?.lastName,
          gender: record?.gender,
          status:
            record?.type === 1 ? (
              <div style={{ color: 'green', fontWeight: '700' }}>Active</div>
            ) : record?.type === 2 ? (
              <div style={{ color: '#2a6bf4', fontWeight: '700' }}>
                Inactive
              </div>
            ) : (
              <div style={{ color: '#AF0C0C', fontWeight: '700' }}>Blocked</div>
            ),
          date: moment(record?.addedAt).format('DD MMM YYYY'),
        };
        data.push(obj);
      });
    return data;
  }

  function searchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.name.toLowerCase().includes(search.toLowerCase()) ||
          items.gender.toLowerCase().includes(search.toLowerCase()) ||
          String(items.bloodGroup)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.date).toLowerCase().includes(search.toLowerCase()) ||
          String(items.donorId).toLowerCase().includes(search.toLowerCase()) ||
          String(items.status).toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  function DonorNearbyTableDatas() {
    return search === ''
      ? DonorNearbyTableData
      : searchData(DonorNearbyTableData);
  }

  useEffect(() => {
    if (DonorNearbyData) {
      setDonorNearbyTableData(setData(DonorNearbyData));
    }
  }, [DonorNearbyData]);

  useEffect(() => {
    dispatch(getDashboardTilesData());
  }, []);

  return (
    <DonorNearByView
      page={page}
      search={search}
      setSearch={setSearch}
      DonorNearbyTableData={DonorNearbyTableDatas()}
      // DonorNearbyTableData={DonorNearbyTableData}
      rowsPerPage={rowsPerPage}
      onHeaderClick={onHeaderClick}
      headerDropDownChange={headerDropDownChange}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
    />
  );
}

export default DonorsNearByContainer;
