import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

function DatePicker({
  showMonthDropdown,
  dateFormatType,
  getSelectedDate,
  disabled,
  selected,
}) {
  const [startDate, setStartDate] = useState(selected || new Date());

  function onDateChange(date) {
    setStartDate(date);

    // send the current selected date to parent
    getSelectedDate(date);
  }

  return (
    <ReactDatePicker
      disabled={disabled}
      selected={startDate}
      onChange={onDateChange}
      dateFormat={dateFormatType}
      showMonthDropdown={showMonthDropdown}
      closeOnScroll={true}
    />
  );
}

DatePicker.defaultProps = {
  dateFormatType: 'dd/MM/yyyy',
  // dateFormatType: 'dd-Mon-YYYY',
  showMonthDropdown: true,
};

DatePicker.propTypes = {
  showMonthDropdown: bool,
  dateFormatType: string,
  getSelectedDate: func,
};

export default DatePicker;
