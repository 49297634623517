import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// import profile from '../assets/images/profile.png';
import profile from '../../assets/images/profile.png';
import insti from '../../assets/images/insti.png';
import star from '../../assets/images/star.png';
import back from '../../assets/images/back.png';

import { triggeReCallLogin } from '../../redux/actions/superAdminCommonActions';

function InstitutionDetailPageView({
  institutionInfo,
  onTabChange,
  activeTab,
  setShowPopUp,
}) {
  const history = useHistory();

  const dispatch = useDispatch();
  const { reCallLogin } = useSelector(
    (state) => state.superAdminCommonReducers
  );
  useEffect(() => {
    dispatch(triggeReCallLogin());
  }, []);

  const cell2 = [
    {
      label: 'Registration Number:',
      value: institutionInfo?.address
        ? institutionInfo?.address?.description +
          '' +
          institutionInfo?.address?.state +
          '' +
          institutionInfo?.address?.city
        : '--',
    },

    {
      label: 'Email ID:',
      value: institutionInfo?.contactEmail ?? '--',
    },
    {
      label: 'Contact No:',
      value: `+91 ${institutionInfo?.contactNumber}` ?? '--',
    },
    {
      label: 'Open Time:',
      value: institutionInfo?.time ?? '09:00-13:00 , 14:00-18:00 ',
    },
  ];
  const cell3 = [
    {
      label: '',
      value: '',
    },
    {
      label: 'Authorised signature:',
      value: institutionInfo?.authign ?? '--',
    },
    {
      label: 'Registration Number:',
      value: institutionInfo?.registrationNumber ?? '--',
    },
    {
      label: 'Joined On:',
      value: moment(institutionInfo?.addedAt).format('DD MMM YYYY') ?? '--',
    },
    // {
    //   label: 'Rating:',
    //   value: institutionInfo?.avarageRating ?? '--',
    // },
  ];
  const cell4 = [
    {
      label: '',
      value: '',
    },
    {
      label: 'Registration ID:',
      value: institutionInfo?.registrationId ?? '--',
    },
    {
      label: 'Camps:',
      value: institutionInfo?.camps ?? '--',
    },
    {
      label: 'Subscription:',
      value: institutionInfo?.subscriptions ?? '108/1000 Remaining',
    },
    {
      label: 'Last subscribed:',
      value: moment(institutionInfo?.addedAt).format('DD MMM YYYY') ?? '--',
    },
  ];

  // console.log(institutionInfo?.state);
  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header d-flex justify-content-between '>
                <div className='d-flex-center'>
                  <div className='d-flex align-items-center mr-10'>
                    <div
                      className='d-flex align-items-center fw-600 cursor-pointer'
                      onClick={() => {
                        history.push('/Super-Admin/Instituions');
                      }}
                    >
                      <img
                        src={back}
                        height='25px'
                        className='cursor-pointer'
                        style={{ marginLeft: '5px' }}
                        alt='Back Btn'
                      />
                    </div>
                  </div>
                  <span className='bd-dashboard__main '>Institution</span>
                </div>
                {reCallLogin?.data?.content?.data?.instituteManagement
                  ?.update && (
                  <div
                    className='bd-btn bd-btn--primary'
                    onClick={() => {
                      setShowPopUp(true);
                    }}
                  >
                    <span>
                      {institutionInfo?.state &&
                      institutionInfo?.state == 'Blocked'
                        ? 'Unblock Institution'
                        : 'Block Institution'}
                    </span>
                  </div>
                )}
              </div>
              <div className='profile_detail'>
                <div
                  className='bd-profile__img imgs'
                  style={{
                    backgroundImage: `url(${insti})`,
                  }}
                ></div>
                <div className='col_2'>
                  <div className='col_22'>
                    <div className='instiName'>
                      {institutionInfo?.institutionName}
                    </div>
                    {cell2.map((cell) => {
                      return <div>{cell.value}</div>;
                    })}
                  </div>
                </div>
                <div className='col_3'>
                  <div className='bd-tbl bd-tbl__theme2'>
                    <div className='bd-tbl__body'>
                      {cell3.map((cell) => {
                        return (
                          <div className='bd-tbl__row'>
                            <div className='bd-tbl__cell'>
                              <span>{cell.label}</span>
                            </div>
                            <div className='bd-tbl__cell'>
                              <span>{cell.value}</span>
                            </div>
                          </div>
                        );
                      })}
                      <div className='bd-tbl__row'>
                        <div className='bd-tbl__cell'>
                          <span>Ratings</span>
                        </div>
                        <div className='bd-tbl__cell d-flex'>
                          <span>{institutionInfo?.avarageRating}</span>
                          <span>
                            <div
                              className='bd-profile__img img-star ml-5'
                              style={{
                                backgroundImage: `url(${star})`,
                              }}
                            ></div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col_4'>
                  <div className='bd-tbl bd-tbl__theme2'>
                    <div className='bd-tbl__body'>
                      {cell4.map((cell) => {
                        return (
                          <div className='bd-tbl__row'>
                            <div className='bd-tbl__cell'>
                              <span>{cell.label}</span>
                            </div>
                            <div className='bd-tbl__cell'>
                              <span>{cell.value}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='bd-dashboard__container mt-15'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header d-flex justify-content-between '>
                <span className='bd-dashboard__main'>Reviews</span>
              </div>
              <div className='bd-tab bd-tab-new width'>
                <div
                  id='Institution'
                  className={activeTab === 'Institution' && 'active'}
                  onClick={onTabChange}
                  activetab={activeTab}
                >
                  <span id='Institution' style={{ padding: '0 10px' }}>
                    Institution
                  </span>
                </div>
                <div
                  id='Delivery'
                  className={activeTab === 'Delivery' && 'active'}
                  onClick={onTabChange}
                  activetab={activeTab}
                >
                  <span id='Delivery' style={{ padding: '0 10px' }}>
                    Delivery
                  </span>
                </div>
              </div>
              {institutionInfo?.ratings && institutionInfo?.ratings.length ? (
                <div>
                  {institutionInfo?.ratings.map((item) => {
                    return (
                      <div className='rating_table mt-15'>
                        <div className='element bb'>
                          <div
                            className='profile_img'
                            style={{
                              backgroundImage: `url(${profile})`,
                            }}
                          ></div>
                          <div className=''>
                            <div className='d-flex justify-content-between mb-5'>
                              <div className='bold'>
                                {item?.addedBy?.firstName +
                                  ' ' +
                                  item?.addedBy?.lastName}
                              </div>
                              <div className='d-flex '>
                                {/* <div className='mr-10'>star</div> */}
                                <div className='d-flex'>
                                  <span>{item?.avarageRating}</span>
                                  <div
                                    className='bd-profile__img img-star ml-5'
                                    style={{
                                      backgroundImage: `url(${star})`,
                                    }}
                                  ></div>
                                </div>
                                <div className='mr-10'>
                                  {moment.utc(item?.addedAt).format('HH:mm')},
                                </div>
                                <div>
                                  {moment(item?.addedAt).format('DD MMM YYYY')}
                                </div>
                              </div>
                            </div>
                            <div className='mb-10'>{item?.comments}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className='align-center-no-data'
                  style={{ marginTop: '5vh' }}
                >
                  <div className='bd-tbl__body'>
                    <div className='bd-noDonordata'>
                      <span>No Ratings Available</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </article>
    </>
  );
}

export default InstitutionDetailPageView;
