import React, { useEffect, useState } from 'react';
import { INVENTORY_RECEIVED_COLUMNS } from '../../utils/constants';
import InventoryTableList from '../../components/InventoryTableList';
import { Loader } from '../../components';

function ReceivedView({ data, bloodComponentList, loading }) {
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    if (data.length) setRawData(data);
  }, [data]);

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          {loading ? (
            <Loader />
          ) : (
            <InventoryTableList
              title={'Received'}
              data={rawData}
              columns={INVENTORY_RECEIVED_COLUMNS}
              bloodComponentList={bloodComponentList}
            />
          )}
        </section>
      </article>
    </>
  );
}

export default ReceivedView;
