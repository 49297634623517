import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuperAdminUsersView from '../../views/SuperAdminUsersView/SuperAdminUsersView.js';
import moment from 'moment';
import { sorting, formatDate } from '../../utils';
import { getUsersList } from '../../redux/actions/superAdminUsersAction';
import { getDonarProfile } from '../../redux/actions/dashboardAction';
import { SuperAdminUserProfilePopup } from '../../components/Popups/SuperAdminUserProfilePopup';
import { Loader, SuccessPopup, FailPopup } from '../../components';
import { triggeReCallLogin } from '../../redux/actions/superAdminCommonActions';

function SuperAdminUsersContainer() {
  const dispatch = useDispatch();
  const { reCallLogin } = useSelector(
    (state) => state.superAdminCommonReducers
  );
  useEffect(() => {
    dispatch(triggeReCallLogin());
  }, []);

  const { superAdminUsersList } = useSelector((state) => state.peopleReducer);
  const { donarProfile } = useSelector((state) => state.dashboardReducer);

  const [sucessPopup, setShowSuccessPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [failPopup, setShowFailPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [UsersRawList, setUsersRawList] = useState([]);
  const [search, setSearch] = useState('');

  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [donorProfileData, setDonoProfileData] = useState({});

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...UsersRawList];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setUsersRawList(data);
    setCount(count + 1);
  }
  let type = 'superAdmin';
  const handleClick = (e) => {
    e.stopPropagation();

    setShowProfilePopup(true);
    dispatch(
      getDonarProfile(
        {
          id: e.target.getAttribute('id'),
        },
        {},
        type
      )
    );
  };

  function headerDropDownChange(e) {
    let data = setData(superAdminUsersList?.data);
    if (e.value === 'All/Any') {
      data = [...setData(superAdminUsersList?.data)];
    } else {
      data = data.filter((a) => a.bloodGroup === e.value);
    }
    setUsersRawList(data);
  }

  function setData(sortedData) {
    let data = [];
    sortedData &&
      sortedData.forEach((record, i) => {
        let obj = {
          userId: record?._id,
          bloodGroup: record?.bloodGroup ? record?.bloodGroup : '--',
          name: record?.firstName + ' ' + record?.lastName,
          email: record?.email ? record?.email : '--',
          address:
            record?.address && record?.address?.addressLine
              ? record?.address?.addressLine + ' ' + record?.address?.city
              : '--',
          contactNo: record?.mobileNumber ? record?.mobileNumber : '--',
          status: !record?.isBlocked ? (
            <div style={{ color: 'green', fontWeight: '700' }}>Active</div>
          ) : (
            <div style={{ color: '#AF0C0C', fontWeight: '700' }}>Blocked</div>
          ),
          date: moment(record?.addedAt).format('DD MMM YYYY'),
        };
        data.push(obj);
      });
    return data;
  }

  function searchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.name.toLowerCase().includes(search.toLowerCase()) ||
          String(items.bloodGroup)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.date).toLowerCase().includes(search.toLowerCase()) ||
          String(items.email).toLowerCase().includes(search.toLowerCase()) ||
          String(items.userId).toLowerCase().includes(search.toLowerCase()) ||
          String(items.address).toLowerCase().includes(search.toLowerCase()) ||
          String(items.contactNo)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.status).toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  function usersTableData() {
    return search === '' ? UsersRawList : searchData(UsersRawList);
  }

  useEffect(() => {
    if (donarProfile.data) {
      console.log(donarProfile.data);
      setDonoProfileData({
        name: donarProfile.data.firstName + ' ' + donarProfile.data.lastName,
        gender: donarProfile.data.gender ? donarProfile.data.gender : '--',
        userId: donarProfile.data._id,
        bloodGroup: donarProfile.data.bloodGroup,
        email: donarProfile.data.email,
        donations: donarProfile.data.donation,
        livesSaved: donarProfile.data.livesSaved,
        request: donarProfile.data.request,
        block: donarProfile.data.isBlocked,
        active: donarProfile.data.isActive,
        mobileNumber: donarProfile.data.mobileNumber
          ? donarProfile.data.mobileNumber
          : '--',
        date: moment(donarProfile.data.addedAt).format('DD MMM YYYY'),
        dob: donarProfile.data.dateOfBirth
          ? donarProfile.data.dateOfBirth
          : ' --',
        date: formatDate(donarProfile.data.addedAt),
        address: donarProfile.data.address
          ? donarProfile.data.address.fullAddress
          : '--',
      });
    }
  }, [donarProfile]);

  function hidePopup() {
    setShowSuccessPopup(false);
    setShowFailPopup(false);
    dispatch(getUsersList());
  }

  function escFunction(e) {
    if (e.keyCode === 27) {
      setShowProfilePopup(false);
      setShowSuccessPopup(false);
      setShowFailPopup(false);
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  });

  useEffect(() => {
    if (superAdminUsersList?.data) {
      setUsersRawList(setData(superAdminUsersList?.data));
    }
  }, [superAdminUsersList?.data]);

  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  return (
    <>
      {superAdminUsersList?.loading ? (
        <Loader />
      ) : (
        <SuperAdminUsersView
          page={page}
          search={search}
          setSearch={setSearch}
          UsersList={usersTableData()}
          rowsPerPage={rowsPerPage}
          onHeaderClick={onHeaderClick}
          headerDropDownChange={headerDropDownChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleClick={handleClick}
        />
      )}
      {showProfilePopup && (
        <SuperAdminUserProfilePopup
          profileData={donorProfileData}
          setShowProfilePopup={setShowProfilePopup}
          setShowSuccessPopup={setShowSuccessPopup}
          setSuccessMsg={setSuccessMsg}
          permisssions={reCallLogin?.data?.content?.data}
        />
      )}
      {sucessPopup && (
        <SuccessPopup title={successMsg} onContinueClick={() => hidePopup()} />
      )}
      {failPopup && (
        <FailPopup title={errorMsg} onContinueClick={() => hidePopup()} />
      )}
    </>
  );
}

export default SuperAdminUsersContainer;
