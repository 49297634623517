import React from 'react';
import ReactDatePicker from 'react-datepicker';

function AddPlansForm({
  addPlansObj,
  setAddPlans,
  addPlans,
  onChangeText,
  onSubmit,
}) {
  const {
    planName,
    description,
    quantity,
    price,
    fromDate,
    toDate,
    bonusQuantity,
    bonusPeriod,
    condition,
    // attachments,
  } = addPlansObj;
  return (
    <div className='bd-modal-wrap bd-modal-right '>
      <div className='bd-popup si-fade-in'>
        {/* <BedInfo /> */}
        <div className='bd-popup__head' onClick={() => setAddPlans(!addPlans)}>
          <span>Add Plan</span>
          <i className='bludclose' style={{ marginTop: '6px' }} />
        </div>
        <div className='bd-popup__body'>
          <div className='bd-form__row'>
            <label>Plan Name</label>
            <input
              type='text'
              name='planName'
              value={planName}
              onChange={(e) => onChangeText(e)}
            />
          </div>
          <div className='bd-form__row bd-twocol'>
            <div>
              <label>Price</label>
              <input
                type='text'
                name='price'
                value={price}
                onChange={(e) => onChangeText(e)}
              />
            </div>
            <div>
              <label>Quantity</label>
              <input
                type='text'
                name='quantity'
                value={quantity}
                onChange={(e) => onChangeText(e)}
              />
            </div>
          </div>
          <div className='bd-form__row'>
            <label>Discription</label>
            <input
              type='text'
              name='description'
              value={description}
              onChange={(e) => onChangeText(e)}
            />
          </div>
          <div className='bd-form__row'>
            <label>Bonus Quntity</label>
            <input
              type='text'
              name='bonusQuantity'
              value={bonusQuantity}
              onChange={(e) => onChangeText(e)}
            />
          </div>
          <div className='bd-form__row'>
            <label>Condition</label>
            <input
              type='text'
              name='condition'
              value={condition}
              onChange={(e) => onChangeText(e)}
            />
          </div>

          <div className='bd-adddonor-row bd-adddonor__1'>
            <div className='bd-adddonor-div bd-form__row '>
              <label>From Date</label>
              <div className='width-100'>
                <ReactDatePicker
                  selected={fromDate}
                  onChange={(date) => onChangeText(date, 'fromDate')}
                  dateFormat='dd/MM/yyyy'
                  showMonthDropdown=' true'
                  //   placeholderText='From Date'
                />
              </div>
            </div>
            <div className='bd-adddonor-div bd-form__row '>
              <label>To Date</label>
              <div className='width-100'>
                <ReactDatePicker
                  selected={toDate}
                  onChange={(date) => onChangeText(date, 'toDate')}
                  dateFormat='dd/MM/yyyy'
                  showMonthDropdown=' true'
                  //   placeholderText='To Date'
                />
              </div>
            </div>
          </div>

          <div className='bd-form__row'>
            <label>Bonus Period</label>
            <div className='width-100'>
              <ReactDatePicker
                selected={bonusPeriod}
                onChange={(date) => onChangeText(date, 'bonusPeriod')}
                dateFormat='dd/MM/yyyy'
                showMonthDropdown=' true'
                //   placeholderText='From Date'
              />
            </div>
          </div>
          <div className='bd-btn__wrap'>
            <div
              className='bd-btn bd-btn--outline'
              onClick={() => setAddPlans(!addPlans)}
            >
              <div className='ml-5'> Cancel</div>
            </div>
            <div className='bd-btn bd-btn--primary' onClick={onSubmit}>
              <div className='ml-5'> Add Plan</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPlansForm;
