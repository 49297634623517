import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../components';
import { getInventoryData } from '../../redux/actions/inventoryAction';
import { setDataList } from '../../utils';
import TransferView from '../../views/InventoryView/TransferView';
import { triggerBloodComponentList } from '../../redux/actions/commonAction';

function TransferContainer() {
  const { inventoryDataObj } = useSelector((state) => state.inventoryReducer);
  const [bloodComponentList, setBloodComponentsList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInventoryData());
    dispatch(triggerBloodComponentList(setBloodComponents)); //boolComponent
  }, []);

  const { loading, inventoryList } = inventoryDataObj;

  function fetchList() {
    return setDataList(inventoryList);
  }
  function setBloodComponents(response) {
    //boolComponent
    setBloodComponentsList(response.content.data);
  }
  return (
    <>
      {loading && inventoryList.length <= 0 ? (
        <Loader />
      ) : (
        <TransferView
          data={fetchList()}
          bloodComponentList={bloodComponentList} //boolComponent
        />
      )}
    </>
  );
}

export default TransferContainer;
