import React from "react";

export const RadioButton = ({ id, name, value, label, onRadioChange }) => {
  return (
    <div>
      <span className="bd-radio">
        <input
          id={id}
          type="radio"
          name={name}
          value={value}
          onChange={onRadioChange}
        />
        <b></b>
      </span>
      <label className="radioLabel" htmlFor={id}>{label}</label>
    </div>
  );
};
