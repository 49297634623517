import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../components';
import { setReceivedDataList } from '../../utils';

import ReceivedView from '../../views/InventoryView/ReceivedView';
import { getReceiptData } from '../../redux/actions/inventoryAction';
import { triggerBloodComponentList } from '../../redux/actions/commonAction';
import { getDashboardTilesData } from '../../redux/actions/dashboardAction';

function ReceivedContainer() {
  const { dashboarTilesCount } = useSelector((state) => state.dashboardReducer);

  const [bloodComponentList, setBloodComponentsList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReceiptData());
    dispatch(getDashboardTilesData()); // Dashboard Tiles Issued Data List
    dispatch(triggerBloodComponentList(setBloodComponents)); //boolComponent
  }, []);

  const { loading } = dashboarTilesCount;
  var issuedList = dashboarTilesCount?.data?.content?.dashboardData?.issuedBags;
  var receivedList =
    dashboarTilesCount?.data?.content?.dashboardData?.recievedBags;
  function setBloodComponents(response) {
    //boolComponent
    if (response.content.data) setBloodComponentsList(response.content.data);
  }

  function fetchList() {
    return setReceivedDataList(
      receivedList ? [...receivedList, ...issuedList] : []
    );
  }

  useEffect(() => {}, [bloodComponentList]);

  return (
    <>
      {loading &&
      issuedList?.length <= 0 &&
      bloodComponentList?.length <= 0 &&
      bloodComponentList ? (
        <Loader />
      ) : (
        <ReceivedView
          loading={loading}
          data={fetchList()}
          bloodComponentList={bloodComponentList}
        />
      )}
      {/* received dat */}
    </>
  );
}

export default ReceivedContainer;
