import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Loader,
  TabPane,
  SuccessPopup,
  FailPopup,
  InventoryModal,
} from '../../components';
import {
  ACCEPT_REQUEST,
  GET_DASHBOARD_INVENTORYLIST,
  INVENTORY_CHANGE_STATUS,
  GET_NGO_DASHBOARD_DATA,
} from '../../redux/actions/actionTypes';
import DonationTabContainer from './DonationTabContainer';
import DashboardView from '../../views/DashboardView';
import RequestTab from '../../views/DashboardView/RequestTab';
import VerifyTabContainer from '../../containers/DashboardContainer/VerifyTabContainer';
import InventoryTabContainer from '../../containers/DashboardContainer/InventoryTabContainer';
import RequestBloodContainer from '../../containers/DashboardContainer/RequestBloodContainer';
import {
  getRequestData,
  getChartsData,
  getDonationData,
  getDashboardInventoryList,
  getAcceptRequest,
  issueRequest,
  getDonationGraphData,
  getDashboardTilesData,
  getNGODashboardData,
  getNGODashboardReqTabData,
  assignBtnClick,
} from '../../redux/actions/dashboardAction';
import CampsTab from '../../views/DashboardView/CampsTab';
import { getCampsListData } from '../../redux/actions/campsAction';
import { NgoInventoryModal } from '../../components/Popups/NgoInventoryModal';

export default function DashboardContainer(props) {
  const dispatch = useDispatch();
  const {
    userRequestObj,
    donationObj,
    acceptList,
    chartsData,
    donationGraphData,
    inventoryList,
    inventoryChangeStatus,
    dashboarTilesCount, //Tiles_Count
    // NGO FLOW //
    ngoDashboardCount, // NGO All Dashboard Data
    ngoDashboardAcceptBtn, // NGO All Dashboard Request TAb Acceptance  Data
    ngoReqAssignBtn, //post Assign btn
  } = useSelector((state) => state.dashboardReducer);
  const { loginObj } = useSelector((state) => state.loginReducer);
  let institutionType = loginObj?.data?.content?.data?.institutionType ?? '';

  const { campsObject, camp } = useSelector((state) => state.campsReducer);

  const [showAcceptPopup, setShowAcceptPopup] = useState({
    id: '',
    show: false,
  });
  const [activeTab, setActiveTab] = useState('Request');
  const [selectedUser, setSelectedUser] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState('');
  const blood_bank_tab = [
    {
      title: 'Request',
      component: (
        <RequestTab
          onShareClick={onShareClick}
          onAcceptClick={onAcceptClick}
          userRequestData={userRequestObj}
        />
      ),
    },
    {
      title: 'Donations',
      component: <DonationTabContainer donationObj={donationObj} />,
    },
    {
      title: 'Camps',
      component: <CampsTab campsObject={campsObject.data} />,
    },
  ];
  const blood_bank_hospital_tab = [
    {
      title: 'Request',
      component: (
        <RequestTab
          onShareClick={onShareClick}
          onAcceptClick={onAcceptClick}
          userRequestData={userRequestObj}
        />
      ),
    },
    {
      title: 'Donations',
      component: <DonationTabContainer donationObj={donationObj} />,
    },
    {
      title: 'Verify',
      component: <VerifyTabContainer />,
    },
    {
      title: 'Inventory',
      component: <InventoryTabContainer />,
    },
    {
      title: 'Camps',
      component: <CampsTab campsObject={campsObject.data} />,
    },
  ];
  const NGO_tab = [
    {
      title: 'Request',
      component: (
        <RequestTab
          onShareClick={onShareClick}
          onAcceptClick={onAcceptClick}
          userRequestData={userRequestObj}
        />
      ),
    },

    {
      title: 'Camps',
      component: <CampsTab campsObject={campsObject.data} />,
    },
  ];

  function chartFilterOnChange(value) {
    dispatch(
      getDonationGraphData({
        type: 'donationGraph',
        show: value.toLowerCase(),
      })
    );
  }

  function onTabChange(tab) {
    setActiveTab(tab);
    if (tab === 'Request') {
      dispatch(getRequestData());
    } else if (tab === 'Donations') {
      dispatch(getDonationData());
    } else if (tab === 'Inventory') {
      dispatch(getDashboardInventoryList({ type: 'inventoryList' }));
    } else if (tab === 'Camps') {
      dispatch(getCampsListData());
    }
    dispatch({
      type: INVENTORY_CHANGE_STATUS,
      loading: false,
      successFlag: false,
    });
  }
  function onAcceptClick(e) {
    if (institutionType === 'NGO') {
      let params = {
        id: e.target.id,
      };
      dispatch(getNGODashboardReqTabData(params));
    } else {
      let params = {
        type: 'request',
        id: e.target.id,
      };
      dispatch(getAcceptRequest(params));
    }
    setShowAcceptPopup({
      show: true,
      id: e.target.id,
    });
  }

  function onIssueClick({ inventoryList, unit, id, deliveryType }) {
    let obj = {
      id: id,
      status: 3,
      issueType: 'patient',
      issuedUnits: unit,
      inventoryIds: inventoryList.filter((a) => a.isSelected).map((a) => a._id),
      deliveryType: deliveryType,
    };
    dispatch(
      issueRequest(obj, () => {
        dispatch(getRequestData());
      })
    );
    setShowAcceptPopup({ show: false });
  }
  function onAssignClick({ donorsList, Date, requestId }) {
    let obj = {
      id: requestId,
      donors: donorsList,
      donationDate: Date,
      type: 'request',
    };
    dispatch(
      assignBtnClick(obj, () => {
        dispatch(getRequestData());
        setShowSuccess(true);
      })
    );
    setShowAcceptPopup({ show: false });
  }

  function onCancelClick(e) {
    setShowAcceptPopup({ show: false });
  }

  function onContinueClick() {
    dispatch({
      type: INVENTORY_CHANGE_STATUS,
      loading: false,
      successFlag: false,
    });
  }

  function onShareClick() {}

  function setList() {
    let arr = [];

    userRequestObj?.data?.map((a) => {
      let fname = a?.details?.firstName ?? '';
      let lname = a?.details?.lastName ?? '';
      let bname = a?.bloodBankName ?? '';
      let obj = {
        id: a._id,
        patientId: a.patientId,
        name:
          a.requestType === 'Individual'
            ? `${fname} ${lname} [${a.patientId}] ${
                a?.hospitalLocation?.description ?? ''
              }`
            : `${bname}`,
      };
      // let ngoObj = {};
      // arr.push(institutionType === 'NGO' ? ngoObj : obj);

      arr.push(obj);
    });
    return arr;
  }

  function dropDownChange(e) {
    let id = e.value;
    setSelectedUser(e.value);
    let params = {
      type: 'request',
      id: userRequestObj.data.filter((a) => id === a._id)[0]._id,
    };
    dispatch(getAcceptRequest(params));
  }

  function tabComponent(institutionType) {
    if (institutionType === 'BloodBank') {
      return blood_bank_tab.map((tab, i) => {
        return (
          <TabPane name={tab.title} key={'bloodbabk' + i}>
            {tab.component}
          </TabPane>
        );
      });
    }

    if (institutionType === 'Both') {
      return blood_bank_hospital_tab.map((tab, i) => {
        return (
          <TabPane name={tab.title} key={'both' + i}>
            {tab.component}
          </TabPane>
        );
      });
    }
    if (institutionType === 'NGO') {
      return NGO_tab.map((tab, i) => {
        return (
          <TabPane name={tab.title} key={'NGO' + i}>
            {tab.component}
          </TabPane>
        );
      });
    }
  }
  function reset() {
    dispatch({ type: INVENTORY_CHANGE_STATUS, loading: false });
    dispatch({ type: ACCEPT_REQUEST, loading: false });
    dispatch({ type: GET_DASHBOARD_INVENTORYLIST, loading: false });
    // ngo APIS
    dispatch({ type: GET_NGO_DASHBOARD_DATA, loading: false });
    // dispatch({ type: GET_NGO_REQUEST_TAB_DATA, loading: false });
    // dispatch({ type: POST_NGO_REQUEST_TAB_DATA, loading: false });
  }

  function setLoadingState() {
    userRequestObj.loading ||
    chartsData.loading ||
    donationGraphData.loading ||
    donationObj.loading ||
    inventoryList.loading ||
    acceptList.loading ||
    inventoryChangeStatus.loading ||
    ngoDashboardAcceptBtn.loading ||
    ngoReqAssignBtn.loading
      ? setLoading(true)
      : setLoading(false);

    if (
      inventoryChangeStatus.data !== null ||
      (inventoryChangeStatus.data && inventoryChangeStatus.data.length > 0)
    ) {
      dispatch({
        type: INVENTORY_CHANGE_STATUS,
        loading: false,
      });
      setShowSuccess(true);
    } else if (
      inventoryChangeStatus.error !== null ||
      userRequestObj.error !== null ||
      chartsData.error !== null ||
      donationGraphData.error !== null ||
      donationObj.error !== null ||
      inventoryList.error !== null ||
      acceptList.error !== null ||
      ngoDashboardAcceptBtn.error !== null ||
      ngoReqAssignBtn.error !== null
    ) {
      setError(
        inventoryChangeStatus.error ||
          userRequestObj.error ||
          chartsData.error ||
          donationGraphData.error ||
          donationObj.error ||
          inventoryList.error ||
          acceptList.error ||
          ngoDashboardAcceptBtn.error ||
          ngoReqAssignBtn.error
      );
      setFail(true);
    } else {
      setFail(false);
    }
  }

  function escFunction(e) {
    if (e.keyCode === 27) {
      setShowAcceptPopup({ show: false });
    }
  }

  useEffect(() => {
    dispatch(getRequestData());
    dispatch(getDashboardTilesData());
    dispatch(getNGODashboardData());
    // dispatch(getNGODashboardReqTabData());

    dispatch(getChartsData({ type: 'dashboard' }));
    if (institutionType !== 'Hospital') {
      dispatch(getDonationData());
      dispatch(getDonationGraphData({ type: 'donationGraph' }));
      dispatch(getDashboardInventoryList({ type: 'inventoryList' }));
    }
    document.addEventListener('keydown', escFunction, false);
  }, []);

  useEffect(() => {
    setLoadingState();
  }, [
    userRequestObj,
    chartsData,
    donationGraphData,
    donationObj,
    inventoryList,
    acceptList,
    inventoryChangeStatus,
  ]);

  const inventoryProps = {
    title: 'Accept & Issue',
    buttonName: 'Issue',
    data: acceptList.data,
    selectedUser: selectedUser,
    userList: setList(),
    showDetail: true,
    defaultUnits: userRequestObj?.data?.filter(
      (a) => a._id === showAcceptPopup.id
    )[0]?.units,
    defaultUser: setList().filter((a) => a.id === showAcceptPopup.id)[0],
    onButtonlick: onIssueClick,
    onCancelClick: onCancelClick,
    dropDownChange: dropDownChange,
  };
  const ngoProps = {
    title: 'Accept & Assign',
    buttonName: 'Assign/Depute',
    data: ngoDashboardAcceptBtn?.data?.content?.data,
    selectedUser: selectedUser,
    userList: setList(),
    showDetail: true,
    defaultUnits: userRequestObj?.data?.filter(
      (a) => a._id === showAcceptPopup.id
    )[0]?.units,
    defaultUser: setList().filter((a) => a.id === showAcceptPopup.id)[0],
    onButtonlick: onAssignClick,
    onCancelClick: onCancelClick,
    dropDownChange: dropDownChange,
  };

  return (
    <>
      <DashboardView
        institutionType={institutionType}
        dashboarTilesCount={dashboarTilesCount} //Dashboard Data Counts
        ngoDashboardCount={ngoDashboardCount} // NGO Dashboard Data
        activeTab={activeTab}
        showAcceptPopup={showAcceptPopup}
        inventoryList={inventoryList}
        tabList={tabComponent(institutionType)}
        onTabChange={onTabChange}
        onAcceptClick={onAcceptClick}
        onContinueClick={onContinueClick}
        onShareClick={onShareClick}
        chartFilterOnChange={chartFilterOnChange}
      />

      {showAcceptPopup.show &&
        (institutionType === 'NGO' ? (
          <NgoInventoryModal {...ngoProps} />
        ) : (
          <InventoryModal {...inventoryProps} />
        ))}
      {props.location.requestBlood && <RequestBloodContainer />}
      {showSuccess && (
        <SuccessPopup
          title={
            institutionType !== 'NGO'
              ? acceptList.data.bloodGroup +
                ' ' +
                acceptList.data.component +
                ' issued to patient'
              : 'Assign/Depute Successfully'
          }
          onContinueClick={() => {
            setShowSuccess(false);
            reset();
          }}
        />
      )}
      {showFail && (
        <FailPopup
          title={errors}
          onContinueClick={() => {
            setFail(false);
            reset();
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}
