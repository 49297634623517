import React from 'react';

function SettingList(props) {
  let {
    SettingsJSON,
    currSelected,
    onRadioClick,
    onEditText,
    onChangeInstituteTime,
    onOptionClick,
  } = props;

  return (
    <>
      <div className='setting-div'>
        <ul className='bd-list__primary bd-list__decimal'>
          {SettingsJSON[Object.keys(SettingsJSON)[currSelected]]?.map(
            (data, index) => {
              return (
                data.question && (
                  <li key={index}>
                    <div
                      className={
                        'bd-list__content bd-new-list__content ' +
                        (currSelected === 0
                          ? 'setting-questions-grid-main '
                          : 'setting-question-general-grid')
                      }
                    >
                      <div className='bd-new-secondary-list__content'>
                        <span
                          className={
                            'bd-list__content--txt ' +
                            (currSelected !== 3
                              ? 'general-equipments'
                              : 'general-equipments-infra')
                          }
                        >
                          {data.question}
                        </span>
                        {data &&
                        data.capacity &&
                        currSelected !== 0 &&
                        currSelected !== 3 ? (
                          <div className='bd-timing-tbl__row margin-unset'>
                            <input
                              type='text'
                              placeholder={data.placeholder}
                              value={data.text}
                              onChange={(e) => {
                                onEditText({
                                  data: data,
                                  dataIndex: index,
                                  text: e.target.value,
                                });
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        {data && data?.radios && (
                          <div className='bd-list__radio bd-list__radio-settings'>
                            {data &&
                              data.radios &&
                              data?.radios?.map((radioData, rIndex) => {
                                return (
                                  <div key={rIndex}>
                                    <span className='bd-radio'>
                                      <input
                                        type='radio'
                                        value={radioData.label}
                                        checked={radioData.checked}
                                        onChange={() => {
                                          onRadioClick({
                                            data: data,
                                            radioData: radioData,
                                            dataIndex: index,
                                            radioDataIndex: rIndex,
                                          });
                                        }}
                                      />
                                      <b></b>
                                    </span>
                                    <label>{radioData.label}</label>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>

                      {data && data.capacity && (
                        <div
                          className={
                            'bd-list__rgt--row   ' +
                            (currSelected === 3 ? 'infra-input-width' : '')
                          }
                        >
                          {data.capacity.map((capacityData, inputIndex) => {
                            return (
                              <span key={inputIndex}>
                                {((data?.radios && data?.radios[0].checked) ||
                                  !data.radios) && (
                                  <>
                                    {capacityData.label && (
                                      <label>{capacityData.label}</label>
                                    )}

                                    <input
                                      type='text'
                                      placeholder={capacityData.placeholder}
                                      value={capacityData.text}
                                      onChange={(e) => {
                                        onEditText({
                                          data: data,
                                          dataIndex: index,
                                          text: e.target.value,
                                          capacityDataIndex: inputIndex,
                                        });
                                      }}
                                    />
                                    {capacityData.placeholder === 'Capacity' &&
                                      inputIndex == 0 && <span>KWH</span>}
                                    {capacityData.placeholder === 'Capacity' &&
                                      inputIndex == 1 && <span>Hrs.</span>}
                                    {capacityData.label === 'a) Capacity' &&
                                      currSelected == 0 && <span>Units.</span>}

                                    {capacityData.label ===
                                      'a) Distance covered:' && <span>Km</span>}
                                  </>
                                )}
                              </span>
                            );
                          })}
                        </div>
                      )}
                    </div>

                    {data &&
                      data.radios &&
                      data.radios[1].label === 'Regulated' &&
                      data.radios[1].checked && (
                        <div className='bd-timing-tbl'>
                          <div className='bd-timing-tbl__day'>
                            {data?.radios[1]?.weekDays?.map((weekData, wi) => {
                              return (
                                <div
                                  onClick={() => {
                                    onChangeInstituteTime({
                                      data: data,
                                      weekData: weekData,
                                      dataIndex: index,
                                      weekDataIndex: wi,
                                    });
                                  }}
                                  className={weekData.active ? 'active' : ''}
                                >
                                  <span>{weekData.name}</span>
                                </div>
                              );
                            })}
                          </div>
                          <div className='bd-timing-tbl__open'>
                            <div className='bd-timing-tbl__row'>
                              <label>Open</label>
                              <div>
                                <label>from:</label>
                                <input
                                  type='text'
                                  value={data.radios[1].open.from}
                                  onChange={(e) => {
                                    onEditText({
                                      data: data,
                                      dataIndex: index,
                                      text: e.target.value,
                                      option: 'open',
                                      subOption: 'from',
                                      from: 'Regulated',
                                    });
                                  }}
                                />
                              </div>
                              <div>
                                <label>to:</label>
                                <input
                                  type='text'
                                  value={data.radios[1].open.to}
                                  onChange={(e) => {
                                    onEditText({
                                      data: data,
                                      dataIndex: index,
                                      text: e.target.value,
                                      option: 'open',
                                      subOption: 'to',
                                      from: 'Regulated',
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='bd-timing-tbl__break'>
                            <div className='bd-timing-tbl__row'>
                              <label>Break</label>
                              <div>
                                <label>from:</label>
                                <input
                                  value={data.radios[1].break.from}
                                  type='text'
                                  onChange={(e) => {
                                    onEditText({
                                      data: data,
                                      dataIndex: index,
                                      text: e.target.value,
                                      option: 'break',
                                      subOption: 'from',
                                      from: 'Regulated',
                                    });
                                  }}
                                />
                              </div>
                              <div>
                                <label>to:</label>
                                <input
                                  type='text'
                                  value={data.radios[1].break.to}
                                  onChange={(e) => {
                                    onEditText({
                                      data: data,
                                      dataIndex: index,
                                      text: e.target.value,
                                      option: 'break',
                                      subOption: 'to',
                                      from: 'Regulated',
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="bd-timing-tbl__add">
                          <span><i className="bludplus"></i></span>
                        </div> */}
                          <div className='bd-timing-tbl__day mt-15'>
                            <label>Closed on</label>
                            {data?.radios[1]?.weekDays?.map(
                              (weekData, index) => {
                                return (
                                  <div
                                    className={weekData?.active ? '' : 'active'}
                                  >
                                    <span>{weekData?.name}</span>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}

                    {data &&
                      data.radios &&
                      data.radios[0]?.label === '24 x 7' &&
                      data.radios[0]?.checked && (
                        <div className='bd-timing-tbl'>
                          <div className='bd-timing-tbl__open'>
                            <div className='bd-timing-tbl__row'>
                              <label>Open</label>
                              <div>
                                <label>from:</label>
                                <input
                                  type='text'
                                  value={data?.radios[0]?.open?.from}
                                  onChange={(e) => {
                                    onEditText({
                                      data: data,
                                      dataIndex: index,
                                      text: e.target.value,
                                      option: 'open',
                                      subOption: 'from',
                                      from: '24 x 7',
                                    });
                                  }}
                                />
                              </div>
                              <div>
                                <label>to:</label>
                                <input
                                  type='text'
                                  value={data?.radios[0]?.open?.to}
                                  onChange={(e) => {
                                    onEditText({
                                      data: data,
                                      dataIndex: index,
                                      text: e.target.value,
                                      option: 'open',
                                      subOption: 'to',
                                      from: '24 x 7',
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='bd-timing-tbl__break'>
                            <div className='bd-timing-tbl__row'>
                              <label>Break</label>
                              <div>
                                <label>from:</label>
                                <input
                                  type='text'
                                  value={data?.radios[0]?.break?.from}
                                  onChange={(e) => {
                                    onEditText({
                                      data: data,
                                      dataIndex: index,
                                      text: e.target.value,
                                      option: 'break',
                                      subOption: 'from',
                                      from: '24 x 7',
                                    });
                                  }}
                                />
                              </div>
                              <div>
                                <label>to:</label>
                                <input
                                  type='text'
                                  value={data?.radios[0]?.break?.t0}
                                  onChange={(e) => {
                                    onEditText({
                                      data: data,
                                      dataIndex: index,
                                      text: e.target.value,
                                      option: 'break',
                                      subOption: 'to',
                                      from: '24 x 7',
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {/* {data && data.capacity && (
                    <div className="bd-list__rgt">
                      <div className="bd-list__rgt--row">
                        {data.capacity.map((capacityData, inputIndex) => {
                          return (
                            <span key={inputIndex}>
                              <label>{capacityData.label}</label>
                              <input
                                type="text"
                                name=""
                                onChange={(e) => {
                                  onEditText({
                                    data: data,
                                    capacityData: capacityData,
                                    dataIndex: index,
                                    capacityDataIndex: inputIndex,
                                    text: e.target.value,
                                  });
                                }}
                                value={capacityData.text}
                              />
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )} */}
                    {data && data.subQuestions ? (
                      <ul className='bd-list__secondary bd-list__lower-alpha'>
                        {data.subQuestions.map((subData, subIndex) => {
                          return (
                            <li key={subIndex}>
                              <div className='setting-questions-grid'>
                                <div
                                  className={
                                    'bd-list__content bd-new-secondary-list__content ' +
                                    (currSelected === 0
                                      ? 'bd-new-secondary-list__content-general'
                                      : '')
                                  }
                                >
                                  <div className='bd-list__content--txt general-equipments'>
                                    {subData.question}
                                  </div>
                                  <div className='bd-list__radio bd-list__radio-settings'>
                                    {subData &&
                                      subData.radios &&
                                      subData.radios.map((radioSubData, i) => {
                                        return (
                                          <div key={i}>
                                            <span className='bd-radio'>
                                              <input
                                                type='radio'
                                                checked={radioSubData.checked}
                                                value={radioSubData.label}
                                                onChange={() => {
                                                  onRadioClick({
                                                    data: data,
                                                    radioSubData: radioSubData,
                                                    index: index,
                                                    subDataIndex: subIndex,
                                                    radioSubDataIndex: i,
                                                    type: 'subQuestions',
                                                  });
                                                }}
                                              />
                                              <b></b>
                                            </span>
                                            <label>{radioSubData.label}</label>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                                <div className='bd-list-rgt-settings'>
                                  <div className='bd-list__rgt--row '>
                                    {subData &&
                                      subData.capacity &&
                                      subData?.radios[0]?.checked &&
                                      subData.capacity.map(
                                        (capacitySubData, ii) => {
                                          return (
                                            <span key={ii}>
                                              <label>
                                                {capacitySubData.label}
                                              </label>
                                              <input
                                                type='text'
                                                name=''
                                                placeholder={
                                                  capacitySubData.placeholder
                                                }
                                                value={capacitySubData.text}
                                                onChange={(e) => {
                                                  onEditText({
                                                    data: data,
                                                    capacitySubData:
                                                      capacitySubData,
                                                    index: index,
                                                    subDataIndex: subIndex,
                                                    capacitySubDataIndex: ii,
                                                    type: 'subQuestions',
                                                    text: e.target.value,
                                                  });
                                                }}
                                              />
                                              {(subData.question ===
                                                'For Untested Blood' ||
                                                subData.question ===
                                                  'For tested Blood') &&
                                                capacitySubData.label ===
                                                  'a) Capacity' && (
                                                  <span>Units.</span>
                                                )}
                                            </span>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </li>
                )
              );
            }
          )}
        </ul>
      </div>
      {currSelected !== 3 ? (
        <div className='component-setting-wrapper'>
          {SettingsJSON[Object.keys(SettingsJSON)[currSelected]]?.map(
            (data, index) => {
              return (
                <>
                  {data &&
                    data.question ==
                      'Do you have the capability to process Whole Blood into components?' &&
                    data.radios[0].checked && (
                      <>
                        <div class='bd-selectcomponents__hd'>
                          <span>{data.opQuestions}</span>
                        </div>
                        <div class='bd-selectcomponents__list'>
                          {data?.options?.map((compData, compIndex) => {
                            return (
                              <span
                                key={compIndex}
                                class={
                                  compData.checked
                                    ? 'bd-btn bd-btn--primary'
                                    : 'bd-btn bd-btn--outline'
                                }
                                onClick={() => {
                                  onOptionClick({
                                    data: data,
                                    compData: compData,
                                    dataIndex: index,
                                    compDataIndex: compIndex,
                                  });
                                }}
                              >
                                {compData.label}
                              </span>
                            );
                          })}
                        </div>
                      </>
                    )}
                </>
              );
            }
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default SettingList;
