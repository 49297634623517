import React, { useState } from 'react';
import SettingsHeader from '../../components/SettingComponents/Header';
import SettingList from '../../components/SettingComponents/SettingList';
import AssociateLabs from '../../components/SettingComponents/AssociateLabs';
import HolidaysList from '../../components/SettingComponents/HolidaysList';
import MonthlyInventoryList from '../../components/SettingComponents/MonthlyInventoryList';
import Buttons from '../../components/SettingComponents/Buttons';
import lab from '../../assets/images/lab.png';
import Set_Lab_Creiterai from '../../components/SettingComponents/Set_Lab_Creiterai';

function SettingsView(props) {
  let {
    onRadioClick,
    onOptionClick,
    SettingsJSON,
    currSelected,
    onEditText,
    setCurrSelected,
    getLabsSettingsObj,
    postLabsSettingsObj,
    addHoliday,
    onFinalSubmitSettings,
    onChangeInstituteTime,
  } = props;

  const [LabCriteria, setLabCriteria] = useState(false);

  let listOfHolidays =
    SettingsJSON &&
    SettingsJSON[Object.keys(SettingsJSON)[currSelected]].filter(
      (holidayObj, index) => {
        return holidayObj.listOfHolidays;
      }
    );

  let monthlyInventoryList =
    SettingsJSON &&
    SettingsJSON[Object.keys(SettingsJSON)[currSelected]].filter(
      (obj, index) => {
        return obj.monthlyInventory;
      }
    );

  return (
    <article className='bd-article'>
      {SettingsJSON && (
        <div className='bd-step__container'>
          <section className='bd-step__content'>
            <SettingsHeader
              SettingsJSON={SettingsJSON}
              currSelected={currSelected}
            />

            <div
              className={
                'bd-step-padding ' + (currSelected === 3 ? 'bd-step__body' : '')
              }
            >
              <SettingList
                SettingsJSON={SettingsJSON}
                currSelected={currSelected}
                onRadioClick={onRadioClick}
                onEditText={onEditText}
                onChangeInstituteTime={onChangeInstituteTime}
                onOptionClick={onOptionClick}
              />
              {listOfHolidays && listOfHolidays.length > 0 && false ? (
                <HolidaysList
                  listOfHolidays={listOfHolidays}
                  addHoliday={addHoliday}
                  currSelected={currSelected}
                />
              ) : (
                ''
              )}
            </div>

            {currSelected !== 0 && currSelected !== 2 ? (
              <div className='bd-step__body'>
                {currSelected === 1 && (
                  <AssociateLabs
                    getLabsSettingsObj={getLabsSettingsObj}
                    postLabsSettingsObj={postLabsSettingsObj}
                  />
                )}

                {currSelected === 1 ? (
                  <div className='bd-step__body--rgt lab-img'>
                    <img src={lab} width='100%' alt='lab' />
                    <span
                      className='bd-btn bd-btn--primary '
                      onClick={() => setLabCriteria(true)}
                    >
                      Set Lab Test Criteria
                    </span>
                  </div>
                ) : (
                  ''
                )}

                {LabCriteria && (
                  <Set_Lab_Creiterai
                    setLabCriteria={setLabCriteria}
                    SettingsJSON={SettingsJSON}
                  />
                )}

                {listOfHolidays && listOfHolidays.length > 0 && (
                  <>
                    {/* <div className="bd-step__body--rgt"> */}
                    {/* <HolidaysList
                    listOfHolidays={listOfHolidays}
                    addHoliday={addHoliday}
                    currSelected={currSelected}
                  /> */}
                    {/* </div> */}
                    <MonthlyInventoryList
                      monthlyInventoryList={monthlyInventoryList}
                      currSelected={currSelected}
                    />
                  </>
                )}
              </div>
            ) : (
              ''
            )}
          </section>
          <Buttons
            currSelected={currSelected}
            setCurrSelected={setCurrSelected}
            SettingsJSON={SettingsJSON}
            onFinalSubmitSettings={onFinalSubmitSettings}
          />
        </div>
      )}
    </article>
  );
}

export default SettingsView;
