import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../components';
import { setDataList } from '../../utils';

import { getReceiptData } from '../../redux/actions/inventoryAction';
import ReceiptView from '../../views/InventoryView/ReceiptView';
import { triggerBloodComponentList } from '../../redux/actions/commonAction';

function ReceiptContainer() {
  const { inventoryReceiptObj } = useSelector(
    (state) => state.inventoryReducer
  );
  const [bloodComponentList, setBloodComponentsList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReceiptData());
    dispatch(triggerBloodComponentList(setBloodComponents)); //boolComponent
  }, []);

  const { loading, receiptList } = inventoryReceiptObj;

  const data = receiptList;
  function fetchList() {
    return setDataList(receiptList);
  }

  function setBloodComponents(response) {
    //boolComponent
    setBloodComponentsList(response.content.data);
  }

  // useEffect(() => {
  //   const ListData = fetchList();
  // }, [receiptList]);

  return (
    <React.Fragment>
      {loading && receiptList.length <= 0 ? (
        <Loader />
      ) : (
        <ReceiptView
          data={fetchList()}
          bloodComponentList={bloodComponentList}
        />
      )}
    </React.Fragment>
  );
}

export default ReceiptContainer;
