import React from 'react';
// import moment from 'moment';
import { useSelector } from 'react-redux';
import { FailPopup, Spinner } from '../../components';
import close from '../../assets/images/close.png';
import ReactDatePicker from 'react-datepicker';

export default function AddCamp({
  onCancelClick,
  onSubmit,
  onChangeText,
  onChangeFile,
  failedPopup,
  inputFile,
  onContinueFailedClick,
  uploadingFile,
  addCampsObj,
  onAddressSearch,
  selectAddress,
  addressFocused,
  setaddressFocused,
  handleClick,
}) {
  const { addressList, camp } = useSelector((state) => state.campsReducer);

  var today = new Date();
  const {
    campName,
    noOfBeds,
    sponser,
    targetUnits,
    coordinator,
    attachments,
    place,
    address,
    date,
    id,
    fromTime,
    toTime,
  } = addCampsObj;

  return (
    <div className='bd-modal-wrap bd-modal-right bd-createcamp'>
      {failedPopup ? (
        <div className='bd-fail'>
          <FailPopup
            onContinueClick={() => onContinueFailedClick()}
            title='Please fill required details!'
          />
        </div>
      ) : (
        ''
      )}

      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Create Blood Donation Camp</span>
          <img
            src={close}
            height='25px'
            className='cursor-pointer'
            onClick={onCancelClick}
            alt='Close Btn'
          />
        </div>
        <div className='bd-popup__body'>
          <div className='bd-form'>
            <div className='bd-form__row'>
              <div className='d-flex align-items-center'>
                <span className='bd_error bd_star'>*</span>
                <input
                  type='text'
                  placeholder='Name of the Camp'
                  name='campName'
                  value={campName}
                  onChange={(e) => onChangeText(e)}
                />
              </div>
            </div>
            <div className='bd-form__row '>
              <div className='d-flex align-items-center'>
                <span className='bd_error bd_star'>*</span>
                <input
                  type='text'
                  placeholder='Sponsored by'
                  name='sponser'
                  value={sponser}
                  onChange={(e) => onChangeText(e)}
                />
              </div>
            </div>
            <div className='bd-form__row bd-addimages'>
              <input
                type='file'
                ref={inputFile}
                style={{ display: 'none' }}
                name='attachments'
                onChange={onChangeFile}
              />

              <div className='bd-form__row bd-addimages'>
                <div className='bd-add bd-add-first ' onClick={handleClick}>
                  <i className='bludplus'></i>
                  <div className='bd-add-images-title'>Add Images</div>
                </div>

                {attachments?.length
                  ? attachments.map((file, i) => {
                      return (
                        <div key={i} className='bd-add-box active bd-add-other'>
                          {i === attachments.length - 1 && uploadingFile ? (
                            <div className='center'>
                              <Spinner />
                            </div>
                          ) : (
                            <div
                              className='bd-add active bd-add-other'
                              style={{
                                backgroundImage: `url(${file.location})`,
                              }}
                            >
                              {' '}
                            </div>
                          )}
                        </div>
                      );
                    })
                  : ''}
              </div>
            </div>
            <div className='bd-form__row bd-twocol'>
              <div className='d-flex align-items-center'>
                <span className='bd_error bd_star'>*</span>
                <input
                  type='number'
                  placeholder='No. of Beds'
                  name='noOfBeds'
                  value={noOfBeds}
                  onChange={(e) => onChangeText(e)}
                />
              </div>

              <div>
                <div className='d-flex align-items-center '>
                  <span className='bd_error bd_star'>*</span>
                  <input
                    type='number'
                    placeholder='Target Units'
                    name='targetUnits'
                    value={targetUnits}
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
              </div>
            </div>

            <div className='bd-form__row bd-twocol'>
              <div>
                <div className='d-flex align-items-center'>
                  <span className='bd_error bd_star'>*</span>
                  <label>Date</label>
                </div>

                <div className='ml-10'>
                  <ReactDatePicker
                    selected={date}
                    onChange={(date) => onChangeText(date, 'date')}
                    dateFormat='dd/MM/yyyy'
                  />
                </div>
                {date && date <= today && (
                  <p className='bd_error'>Please select date after today!</p>
                )}
              </div>
              <div className='bd_createcamp_time'>
                <div className='d-flex '>
                  <span className='bd_error bd_star'>*</span>

                  <div className='bd-addcamp-from'>
                    <label>From</label>
                    <input
                      placeholder='Time'
                      onChange={(e) => onChangeText(e)}
                      value={fromTime}
                      type='time'
                      name='fromTime'
                    />
                  </div>

                  <div>
                    <label>To</label>

                    <input
                      placeholder='Time'
                      onChange={(e) => onChangeText(e)}
                      value={toTime}
                      type='time'
                      name='toTime'
                    />
                  </div>
                </div>
                {toTime.split(':')[0] < fromTime.split(':')[0] &&
                  toTime.split(':')[1] < fromTime.split(':')[1] && (
                    <p className='bd_error'>To should be greater than from</p>
                  )}
              </div>
            </div>
            <div className='bd-form__row align-items-center d-flex'>
              <span className='bd_error bd_star'>*</span>
              <input
                onChange={(e) => onChangeText(e)}
                type='text'
                value={coordinator}
                name='coordinator'
                placeholder='Coordinator'
              />
            </div>
            <div className='bd-form__row align-items-center d-flex'>
              <span className='bd_error bd_star'>*</span>
              <input
                type='text'
                placeholder='Place'
                name='place'
                value={place}
                onChange={(e) => onChangeText(e)}
              />
            </div>
            <div className='bd-form__row'>
              <div>
                <div className='d-flex align-items-center'>
                  <span className='bd_error bd_star'>*</span>
                  <input
                    type='text'
                    placeholder='Address'
                    name='address'
                    value={address.description}
                    onChange={(e) => onAddressSearch(e)}
                    onFocus={(e) => setaddressFocused(true)}
                  />
                </div>
                {addressFocused &&
                addressList?.data &&
                addressList?.data?.length ? (
                  <div className='bd_address'>
                    {addressList?.data.map((data, i) => {
                      return (
                        <div
                          className='cursor-pointer'
                          key={i}
                          onClick={() => {
                            selectAddress(data);
                          }}
                        >
                          <div>{data.location.description},</div>
                          <div
                            onClick={() => {
                              selectAddress(data);
                            }}
                          ></div>
                          <hr />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {targetUnits && targetUnits < 100 && (
            <p className='bd_error ml-10 mb-10'>
              Plan for & maximize this oppotunity
            </p>
          )}
          <div className='bd-btn__wrap'>
            <div
              className='bd-btn bd-btn--primary'
              onClick={() => onSubmit()}
              disabled={camp.loading}
            >
              <div className='d-flex align-items-center justify-content-center'>
                {camp.loading ? (
                  <div>
                    <Spinner />
                  </div>
                ) : (
                  ''
                )}
                <div className='ml-5'> {id ? 'Edit ' : 'Create '} Camp</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
