// export const API_BASE_PATH =
  // process.env.REACT_APP_ENV === 'dev'
  //   ? 'https://dev.blud.app/api/applications/'
  //   : 'https://prod.blud.app/api/applications/';

// export const API_BASE_PATH = 'http://192.168.1.130:1337/applications/';
export const API_BASE_PATH = 'https://blud-app.herokuapp.com/applications/';

export const ENDPOINT =
  process.env.REACT_APP_ENV === 'dev'
    ? 'https://dev.blud.app'
    : 'https://prod.blud.app';

export const LatLongURL = 'https://www.latlong.net/';
// LOGIN, REGISTRATION, PASSWORD (SET / FORGOT / RESET)

export const LOGIN_URL = 'institution/login';
export const SUPER_ADMIN_URL = 'super-admin/login';

export const REGISTER_URL = 'institution/register';

export const SET_PASSWORD_URL = 'institution/confirm';
export const SET_PASSWORD_PATIENT_URL = 'institution/user/confirm';
export const SUPER_ADMIN_SET_PASSWORD_URL = 'super-admin/confirm';

export const FORGOT_PASSWORD_URL = 'institution/forgot-password';

export const RESET_PASSWORD_URL = 'institution/reset-password';

export const VALIDATE_OTP_URL = 'auth/validate-otp';

export const POST_NEW_INSTITUTION_URL = 'institution/geolocation/add';

export const STATE_AND_STATE_URL = 'generic/state-city/list';

// SETTINGS URL
export const GET_LAB_RESULTS_SETTINGS_URL = 'institution/get-keymembers';

export const ADD_LAB_RESULTS_SETTINGS_URL = 'institution/add-keymembers';
export const REMOVE_LAB_SETTINGS_URL = 'institution/delete-keymembers';

export const GET_SETTINGS_URL = 'institution/get-questions';

export const POST_SETTINGS_URL = 'institution/edit';

export const UPLOAD_SETTINGS_INVENTORY_URL = 'institution/inventory/add';

export const UPLOAD_SETTINGS_DONOR_URL = 'institution/donors/add';

export const POST_LAB_CRITERIA_API = 'institution/lab-criteria/add';

// BloddBank / Hospital Dashboard API
export const GET_DASHBOARD_TILES_DATA = 'institution/dashboard-count';

//NGO Dashboard API
export const GET_NGO_DASHBOARD_DATA_API = 'institution/ngo/dashboard';
// following API for assign Donors Accept btn
export const GET_NGO_REQUEST_TAB_DATA_API = 'institution/get-known-donors';
// FOLLOWING API for REQ POP  Assign click
export const REQ_ASSIGN_BTN_CLICK_API = 'institution/donors/depute-assign';

// export const GET_HOSPITAL_LIST_URL = "institution/list?name=";
export const GET_HOSPITAL_LIST_URL = 'institution/location-list?name=';
export const GET_CAMP_ADDRESS_LIST_URL = 'institution/list?name=';

export const REQUEST_USER_DATA_URL = 'requests/list';

export const ADD_DONOR_URL = 'institution/donors/add';

export const UPLOAD_FILE_URL = 'generic/file';

export const CHART_DATA_URL = 'institution/inventory/list';

export const DONATION_DATA_URL = 'requests/donations';

export const INVENTORY_CHANGE_STATUS_URL =
  'institution/inventory/change-status';

export const GET_PROFILE_URL = 'auth/get-profile';

export const GET_DONOR_OTP_URL = 'institution/donor-otp/send';

export const VALIDATE_DONOR_OTP_URL = 'institution/donor-otp/validate';

export const REQUEST_DONOR_CHANGE_STATUS_URL = 'requests/donor/change-status';
// export const REQUEST_DONOR_CHANGE_STATUS_URL = "​/request​/donor​/change-status";
export const DONOR_LAB_RESULT_URL = 'institution/lab-results/list';
export const DONATION_HISTORY_URL = 'institution/donation-history/list';
export const MEDICAL_HISTORY_URL = 'institution/medicalhistory/list';
export const DONATION_REMARK_URL = 'institution/donor/remarks';
export const REQUEST_ADD_BLOOD_URL = 'requests/add';

// export const EDIT_URL = "institution/inventory/transfer/confirm";
export const EDIT_URL = 'requests/edit';
//////////////////////////////////////////////////////////

// PEOPLE URL
export const DONOR_LIST_URL = 'requests/peoples';
export const BLOCK_DONOR_URL = 'requests/blockuser';
export const UNBLOCK_DONOR_URL = 'requests/unblockuser';
export const REQUEST_BLOOD_URL = '/requests/blood-request/send';

// CAMPS URL
export const GET_CAMP_DETAILS_URL = 'donation-camp/details';

// export const GET_CAMPS_LIST_URL = 'donation-camp/list';
export const GET_CAMPS_LIST_URL = 'donation-camp/list';

export const GET_CAMPS_REQUEST_LIST_URL =
  'donation-camp/list?id=609237a5e529ad35d0b5c7c6';

export const POST_DONATION_FORM_URL = 'donation-camp/donor/change-status';

export const POST_CAMPS_DATA_URL = 'donation-camp/add';

export const GET_PROCEED_BTN_CLICK = 'donation-camp/inprocess/details';

export const POST_PENDING_CAMP_DATA = '';

export const UPDATE_CAMPS_DATA_URL = 'donation-camp/edit';

export const ACCEPT_CAMPS_REQUEST_URL = 'donation-camp/change-status';

export const DECLINE_CAMPS_REQUEST_URL = 'donation-camp/change-status';

export const DELETE_CAMPS_URL = 'donation-camp/change-status';

export const DEACTIVATE_CAMPS_URL = 'donation-camp/change-status';
export const GET_NGO_CAMPS_LIST_URL = 'institution/ngo/camps';

export const GET_BB_LIST_URL = 'institution/registered/list?name=';

// INVENTORY URLS
export const GET_INVENTORY_DATA_URL = 'institution/inventory/list';
export const GET_INVENTORY_RECEIPT_DATA_URL =
  'institution/inventory/transfer/list'; //sudarshan
export const POST_INVENTORY_RECEIPT_DATA_URL =
  'institution/inventory/transfer/confirm'; //sudarshan
export const DISPOSE_INVENTORYS_URL = 'institution/inventory/change-status'; // sudarshan

export const GET_AGEING_REPORT_DATA_URL = 'institution/inventory/list';

export const ADD_INVENTORY_URL = 'institution/inventory/add';

export const ISSUE_TO_PATIENT_URL = 'institution/inventory/change-status';

export const ISSUE_TO_REQUEST_URL = 'requests/change-status';
export const VERIFY_ISSUE_TO_PATIENT_URL = 'requests/change-status';

export const GET_IN_PROCESS_DATA_URL =
  'institution/inventory/in-processing/list';
export const POST_IN_PROCESS_DATA_URL =
  'institution/inventory/in-processing/change-status ';

export const ISSUE_TO_PROCESS_URL = '';
// sudarshan
export const INVENTORY_COMPONENT_LIST_URL =
  'institution/inventory/component/list';
// export const INVENTORY_DATA_LIST_URL = "institution/inventory/list";

export const DISPOSE_INVENTORY_URL = 'institution/inventory/change-status';

export const INVENTORY_ADD_PATIENT_URL = 'requests/patient';

export const TRANSFER_LIST_URL = 'auth/nearby';

//ADD PATIENT URL
export const POST_PATIENT_DATA_URL = 'institution/patient/add';
export const GET_PATIENT_LIST_URL = 'institution/patient/list';

// LAB RESULTS URL
export const GET_LAB_RESULTS_URL = 'institution/lab-results/list';

export const POST_LAB_URL = 'institution/lab-results/add';

export const GET_LAB_RESULTS_DETAILS_URL = 'institution/lab-results/details';

export const ACCEPT_LAB_RESULTS = 'institution/lab-results/change-status';

export const GET_LAB_CRITERIA_URL = 'institution/lab-criteria/list';

//Get Profile

export const GET_INSTITUTION_PROFILE = 'institution/get';

export const POST_INSTITUTION_PROFILE = '/';

// Chat

export const GET_CHAT_URL = 'requests/institution/chats';

//////////////// SUPER ADMINI ///////////////////////////

/* SUPER ADMIN ADMINITARTOR API STRATS */
export const GET_ADMINS_LIST_URL = 'super-admin/blud/admins';
export const GET_ADMINS_DATA_URL = 'super-admin/blud/admin/details';
export const ADD_ADMIN_URL = 'super-admin/add';
export const EDIT_ADMIN_URL = 'super-admin/blud/admin/update';
export const CHANGE_ADMIN_STATUS_URL = 'super-admin/blud/admin/change-status';
/*  END */

/* SUPER ADMIN USERS API STRATS */
export const USERS_LIST_URL = 'super-admin/blud/users';
export const SUPER_ADMIN_USERS_URL = 'super-admin/blud/user-details';
export const BLOCK_ADMIN_URL = 'super-admin/blud/blockuser';
export const UNBLOCK_ADMIN_URL = 'super-admin/blud/unblockuser';
/*  END */

/* SUPER ADMIN cOMMON MODULES API STRATS */

// DASHBOARD MPDULES**
export const DASHBOARD_TILES_DATA_URL = 'super-admin/tile-data';
export const DASHBOARD_STATE_CITY_DATA_URL = 'super-admin/dashboard-data';
/*  END */

// TRANSACTION MPDULES**
export const GET_SUBCRIPTION_LIST_URL = '';
export const GET_BLUDUNIT_LIST_URL = 'super-admin/blud/transactions';
/*  END */

// GET_SUBCRIPTION_PLANS MPDULES**
export const GET_SUBCRIPTION_PLANS_LIST_URL = 'super-admin/blud/plans';
export const POST_ADD_SUBACRIPTION_PLANS_URL = 'super-admin/blud/add/plan';

// GET INSTITUITON  MPDULES**
export const GET_REGISTER_LIST_URL = 'super-admin/blud/institutions';
export const GET_PENDING_LIST_URL = 'super-admin/blud/pending/institutions';

export const GET_REGISTER_DETAIL_URL = 'super-admin/blud/institution-detail';
export const GET_PENDING_DETAIL_URL = 'super-admin/blud/pending/institution';

export const APPROVE_DECLINE_REQUEST_URL = 'institution/verify';
export const BLOCK_UNBLOCK_INSTITUTION_URL = 'super-admin/action/institution';

// PRICING
export const GET_PRICING_LIST_URL = 'super-admin/blud/pricings';
export const RECALL_LOGIN_URL = 'super-admin/permissions';

/*  END */

// INVENTORY TAB COLUMNS
export const INVENTORY_TAB_COLUMNS = [
  { title: 'Components', dataIndex: 'components', key: 'components' },
  { title: 'A+', dataIndex: 'a+', key: 'a+' },
  { title: 'A-', dataIndex: 'a-', key: 'a-' },
  { title: 'B+', dataIndex: 'b+', key: 'b+' },
  { title: 'B-', dataIndex: 'b-', key: 'b-' },
  { title: 'AB+', dataIndex: 'ab+', key: 'ab+' },
  { title: 'AB-', dataIndex: 'ab-', key: 'ab-' },
  { title: 'O+', dataIndex: 'o+', key: 'o+' },
  { title: 'O-', dataIndex: 'o-', key: 'o-' },
];

// REQUEST VIEW ALL TAB COLUMNS
export const REQUEST_VIEW_ALL_TAB_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Units', dataIndex: 'units', key: 'units' },
  { title: 'Component', dataIndex: 'component', key: 'component' },
  { title: 'Condition', dataIndex: 'condition', key: 'condition' },
  { title: 'Hospital', dataIndex: 'hospital', key: 'hospital' },
  { title: 'Required by', dataIndex: 'requiredBy', key: 'requiredBy' },
  { title: '', dataIndex: 'share', key: 'share' },
  { title: '', dataIndex: 'accept', key: 'accept' },
];

// DONATION VIEW ALL TAB COLUMNS
export const DONATION_VIEW_ALL_TAB_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Donar ID', dataIndex: 'donorId', key: 'donorId' },
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Time', dataIndex: 'time', key: 'time' },
  { title: 'Checklist', dataIndex: 'checklist', key: 'checklist' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
  { title: 'Mobile no', dataIndex: 'mobileNo', key: 'mobileNo' },
  { title: '', dataIndex: 'requestOTP', key: 'requestOTP' },
];

export const BLOOD_GROUP_LIST = [
  'A+',
  'A-',
  'B-',
  'B+',
  'AB+',
  'AB-',
  'O+',
  'O-',
];

export const GENDER_LIST = ['Male', 'Female', 'Others'];

//inventory

export const INVENTORY_TRANSFER_COLUMNS = [
  {
    title: '',
    dataIndex: 'checkBox',
    key: 'checkBox',
  },
  {
    title: 'Bag ID/Unit no.',
    dataIndex: 'bagId',
    key: 'bagId',
  },
  {
    title: 'Donor ID',
    dataIndex: 'donorId',
    key: 'donorId',
  },
  {
    title: 'Collected on',
    dataIndex: 'collectionDate',
    key: 'collectionDate',
  },
  {
    title: 'Expiry',
    dataIndex: 'expiryDate',
    key: 'expiryDate',
  },
  {
    title: 'Location',
    dataIndex: 'storedIn',
    key: 'storedIn',
  },
  {
    title: 'Issued to',
    dataIndex: 'IssuedTo',
    key: 'IssuedTo',
  },
  {
    title: 'Issued on',
    dataIndex: 'issuedOn',
    key: 'issuedOn',
  },
  {
    title: '',
    dataIndex: 'transfer',
    key: 'transfer',
  },
];

export const INVENTORY_RECEIVED_COLUMNS = [
  {
    title: '',
    dataIndex: 'checkBox',
    key: 'checkBox',
  },
  {
    title: 'Bag ID/Unit no.',
    dataIndex: 'bagId',
    key: 'bagId',
  },
  {
    title: 'Donor ID',
    dataIndex: 'donorId',
    key: 'donorId',
  },
  {
    title: 'Collected on',
    dataIndex: 'collectionDate',
    key: 'collectionDate',
  },
  {
    title: 'Expiry',
    dataIndex: 'expiryDate',
    key: 'expiryDate',
  },
  {
    title: 'Location',
    dataIndex: 'storedIn',
    key: 'storedIn',
  },
  {
    title: 'Recieved on',
    dataIndex: 'recievedOn',
    key: 'recievedOn',
  },
  {
    title: 'Received from',
    dataIndex: 'receivedFrom',
    key: 'receivedFrom',
  },
  {
    title: '',
    dataIndex: 'accept',
    key: 'accept',
  },
];
export const INVENTORY_HOME_COLUMNS = [
  { title: 'Components', dataIndex: 'components', key: 'components' },
  { title: 'A+', dataIndex: 'a+', key: 'a+' },
  { title: 'A-', dataIndex: 'a-', key: 'a-' },
  { title: 'B+', dataIndex: 'b+', key: 'b+' },
  { title: 'B-', dataIndex: 'b-', key: 'b-' },
  { title: 'AB+', dataIndex: 'ab+', key: 'ab+' },
  { title: 'AB-', dataIndex: 'ab-', key: 'ab-' },
  { title: 'O+', dataIndex: 'o+', key: 'o+' },
  { title: 'O-', dataIndex: 'o-', key: 'o-' },
  { title: 'HH', dataIndex: 'hh', key: 'hh' },
];

export const AGEING_REPORT_COLUMNS = [
  {
    title: '',
    dataIndex: 'checkBox',
    key: 'checkBox',
  },
  {
    title: 'Bag ID/Unit no.',
    dataIndex: 'bagId',
    key: 'bagId',
  },
  {
    title: 'Donor ID',
    dataIndex: 'donorId',
    key: 'donorId',
  },
  {
    title: 'Collected on',
    dataIndex: 'collectionDate',
    key: 'collectionDate',
  },
  {
    title: 'Expiry',
    dataIndex: 'expiryDate',
    key: 'expiryDate',
  },
  {
    title: 'Location',
    dataIndex: 'storedIn',
    key: 'storedIn',
  },
  {
    title: '',
    dataIndex: 'dispose',
    key: 'dispose',
  },
];

export const INVENTORY_BLOOD_GROUP_TABS = [
  'Whole Blood',
  'Plasma',
  'Platelets',
  'Red cells',
  'AHF',
];

export const INVENTORY_BLOOD_GROUPS_SIDE_SELECT = [
  {
    bloodtype: 'A+',
    componentBlood: (
      <span>
        A<b>+</b>
      </span>
    ),
  },
  {
    bloodtype: 'A-',
    componentBlood: (
      <span>
        A<b>-</b>
      </span>
    ),
  },
  {
    bloodtype: 'B+',
    componentBlood: (
      <span>
        B<b>+</b>
      </span>
    ),
  },
  {
    bloodtype: 'B-',
    componentBlood: (
      <span>
        B<b>-</b>
      </span>
    ),
  },
  {
    bloodtype: 'AB+',
    componentBlood: (
      <span>
        AB<b>+</b>
      </span>
    ),
  },
  {
    bloodtype: 'AB-',
    componentBlood: (
      <span>
        AB<b>-</b>
      </span>
    ),
  },
  {
    bloodtype: 'O+',
    componentBlood: (
      <span>
        O<b>+</b>
      </span>
    ),
  },
  {
    bloodtype: 'O-',
    componentBlood: (
      <span>
        O<b>-</b>
      </span>
    ),
  },
  {
    bloodtype: 'HH',
    componentBlood: (
      <span>
        HH<b></b>
      </span>
    ),
  },
];

export const PEOPLE_DONORS_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Blood Group',
    dataIndex: 'BLoodGroup',
    key: 'blood_group',
  },
  {
    title: 'Donor ID',
    dataIndex: 'donorId',
    key: 'donor_id',
  },
  {
    title: 'Email ID',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Mobile No',
    dataIndex: 'MobileNo',
    key: 'MobileNo',
  },

  {
    title: 'Last Donation',
    dataIndex: 'lastDonationDate',
    key: 'lastDonationDate',
  },
  {
    title: 'Last Donation On',
    dataIndex: 'lastDonationComponent',
    key: 'lastDonationComponent',
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

// PEOPLE  -->  RECEPIENTS TAB COLUMNS
export const PEOPLE_RECEPIENT_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'BloodGroup',
    dataIndex: 'bloodGroup',
    key: 'bloodGroup',
  },
  {
    title: "Patient's Name",
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Component',
    dataIndex: 'components',
    key: 'components',
  },
  {
    title: 'Units',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: 'Age',
    dataIndex: 'dateOfBirth',
    key: 'dateOfBirth',
  },
  {
    title: 'Mobile No.',
    dataIndex: 'mobileNo',
    key: 'mobileNo',
  },
  {
    title: 'Patient ID',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Purpose',
    dataIndex: 'condition',
    key: 'condition',
  },
  {
    title: 'Doctor',
    dataIndex: 'doctorName',
    key: 'doctorName',
  },
  {
    title: 'Hospital',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
  },
];

export const CAMP_DONOR_DETAILS_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Blood Group',
    dataIndex: 'BLoodGroup',
    key: 'blood_group',
  },
  {
    title: 'Donor ID',
    dataIndex: 'donorId',
    key: 'donor_id',
  },
  {
    title: 'Email ID',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Mobile No',
    dataIndex: 'MobileNumber',
    key: 'MobileNumber',
  },

  {
    title: 'Checklist',
    dataIndex: 'checkList',
    key: 'checkList',
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];
// BLOOD COMPONENTS URL
export const BLOOD_COMPONENT_LIST_URL = 'institution/inventory/component/list';
