import React from 'react';
import { useHistory } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import DropDown from '../../components/RDropDown';
import back from '../../assets/images/back.png';

import {
  NEARBY_DONORS_COLUMNS,
  BLOOD_GROUP_OPTIONS,
} from '../../utils/constants';

function DonorNearByView({
  page,
  DonorNearbyTableData,
  search,
  setSearch,
  rowsPerPage,
  onHeaderClick,
  headerDropDownChange,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const history = useHistory();
  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search'>
                <div className='d-flex align-items-center'>
                  <div
                    className='d-flex align-items-center fw-600 cursor-pointer'
                    onClick={() => {
                      history.push('/');
                    }}
                  >
                    <img
                      src={back}
                      height='25px'
                      className='cursor-pointer'
                      style={{ marginLeft: '5px' }}
                      alt='Back Btn'
                    />
                  </div>
                </div>

                <span className='bd-dashboard__main ml-20'>Donors Nearby</span>
                <div className='dashboard_tiles_header'>
                  <div className='bd_error fw-600'>
                    <div className='bd-dorpdown'>
                      <div className='bd-dorpdown__selected detail-dd bg-dd'>
                        <DropDown
                          placeHolderText={'Blood Group'}
                          options={BLOOD_GROUP_OPTIONS}
                          fetchDropDownValue={headerDropDownChange}
                        />
                        <i className='bludbig-arrow' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bd-hader'>
                  <input
                    type='text'
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder='Search'
                    style={{ padding: 5, marginRight: 10 }}
                  />
                </div>
              </div>

              <div className='table-scroll'>
                <Tables
                  tab={'DonorNearby'}
                  tableType={'table-type-1'}
                  columns={NEARBY_DONORS_COLUMNS}
                  tableData={DonorNearbyTableData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  headerClick={onHeaderClick}
                />
              </div>
              <TablePagination
                component='div'
                count={DonorNearbyTableData.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </section>
      </article>
    </>
  );
}

export default DonorNearByView;
