import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import requestBlood from '../../assets/images/blooddrop-white.png';
import {
  bloodBankList,
  // hospitalMenuList,
  NgoMenuList,
  SuperAdminMenuList,
} from '../../utils/constants';
import { triggeReCallLogin } from '../../redux/actions/superAdminCommonActions';

function Drawer() {
  const dispatch = useDispatch();

  const { loginObj } = useSelector((state) => state.loginReducer);

  const { reCallLogin } = useSelector(
    (state) => state.superAdminCommonReducers
  );

  let institutionType = loginObj?.data?.content?.data?.institutionType;
  const location = useLocation();
  const history = useHistory();

  function checkActiveClass(path) {
    return location.pathname === path ? 'drawer-active' : '';
  }

  useEffect(() => {
    dispatch(triggeReCallLogin());
  }, []);

  // console.log(' ReCall', reCallLogin);
  let defineTabs = [];

  // Render Menu For SUPER ADMIN
  function renderSuperAdminMemu() {
    // console.log(
    //   'supuerAdmin login',
    //   loginObj?.data?.content?.data?.permissions
    // );

    SuperAdminMenuList.map((items) => {
      if (!items.key) {
        defineTabs.push(items);
      }
      if (reCallLogin?.data?.content?.data[items.key]) {
        for (let permissions in reCallLogin?.data?.content?.data[items.key]) {
          if (reCallLogin?.data?.content?.data[items.key][permissions]) {
            defineTabs.push(items);
            break;
          }
        }
      }
    });

    return defineTabs.map((item, i) => {
      const { name, path, iconClassName } = item;
      let menuActiveClass = checkActiveClass(path);
      return (
        <li key={`${name}_${i}`}>
          <i className={[iconClassName, menuActiveClass].join(' ')}></i>
          <span className={menuActiveClass} onClick={() => redirect(path)}>
            {name}
          </span>
        </li>
      );
    });
  }

  // render menu function for   BLLOD_BANK , HOSPITAL , NGO
  function renderMenu() {
    let list =
      institutionType == 'Hospital'
        ? NgoMenuList
        : institutionType == 'Both' || institutionType == 'BloodBank'
        ? bloodBankList
        : NgoMenuList;

    return list.map((item, i) => {
      const { name, path, iconClassName, subMenu } = item;
      let menuActiveClass = checkActiveClass(path);
      return (
        <li key={`${name}_${i}`}>
          <i className={[iconClassName, menuActiveClass].join(' ')}></i>

          <span className={menuActiveClass} onClick={() => redirect(path)}>
            {name}
          </span>

          {subMenu?.length > 0 && renderSubMenu(subMenu)}
        </li>
      );
    });
  }

  function redirect(path) {
    history.push(path);
  }

  function renderSubMenu(subMenu) {
    return (
      <ul className='bd-secondary__menu'>
        {subMenu.map((item, i) => {
          const { name, path, iconClassName } = item;
          let menuActiveClass = checkActiveClass(path);
          return (
            <li key={`${name}_${i}`}>
              <i className={[iconClassName, menuActiveClass].join(' ')}></i>
              {/* <Link 
                style={{ color: "inherit", textDecoration: "inherit" }}
                to={path}
              > */}
              <span className={menuActiveClass} onClick={() => redirect(path)}>
                {name}
              </span>
              {/* {
                name === 'Samples'  && <span class="red-circle">{getLabResultSamples?.data?.content?.data.length  }</span>
              }
                           {
                name === 'People'  && <span class="red-circle">{getLabResultSamples?.data?.content?.data.length  }</span>
              } */}
              {/* </Link> */}
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <>
      {!institutionType ? (
        <nav className='bd-menu'>
          <Link to={`/Super-Admin/Dashboard`}>
            <div className='bd-logo'></div>
          </Link>
          <ul className='bd-primary__menu'>{renderSuperAdminMemu()}</ul>
        </nav>
      ) : (
        <nav className='bd-menu'>
          <Link to={`/`}>
            <div className='bd-logo'></div>
          </Link>
          <ul className='bd-primary__menu'>{renderMenu()}</ul>

          <Link
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            to={`/add-donor`}
          >
            <div class='bd-request-otp mb20 nav-btn'>
              <div className='bd-request-decline-blur bd-request-otp-stars brder_Radius'>
                <span>
                  <i className='bluddonar'></i>
                </span>
              </div>
              <div className=' bd-decline-accept bd-black active'>
                <span>Add donor </span>
              </div>
            </div>
          </Link>

          <Link
            to={{
              pathname: institutionType === 'NGO' ? '/camp' : '/',
              requestBlood: true,
            }}
          >
            <div class='bd-request-otp nav-btn'>
              <div className='bd-request-decline-blur bd-request-otp-stars brder_Radius'>
                <span>
                  <img src={requestBlood} width={18} />
                </span>
              </div>
              <div className=' bd-decline-accept bd-black active'>
                <span>
                  {institutionType === 'NGO' ? ' Create Camp' : 'Request Blood'}
                </span>
              </div>
            </div>
          </Link>
        </nav>
      )}
    </>
  );
}

export { Drawer };
