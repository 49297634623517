import React from 'react';

function BlockUnblockInstituttionPopup({
  setShowPopUp,
  name,
  info,
  attachments,
  addFile,
  blockUnbockAction,
  setCommentValue,
  Comment,
}) {
  const hiddenFileInput = React.useRef(null);

  return (
    <>
      <div className='bd-modal-wrap bd-profile'>
        <div className='bd-popup si-fade-in'>
          <div className='bd-popup__head'>
            <span>
              {info?.state && info?.state == 'Blocked'
                ? 'Unblock Institution'
                : 'Block Institution'}
            </span>
            <i
              className='bludclose'
              onClick={() => {
                setShowPopUp(false);
              }}
            ></i>
          </div>
          <div className='bd-testArea mb-10 pd-unset'>
            <div className='mb-10' style={{ color: '#979797' }}>
              Instituotn Name :{' '}
              <span style={{ color: '#000' }}>{info?.institutionName}</span>
            </div>
            <div className='mb-10' style={{ color: '#979797' }}>
              Reason :
            </div>
            <textarea
              className='bd-textArea-style'
              placeholder='Enter your Reason here'
              name='Comments'
              onChange={(e) => setCommentValue(e.target.value)}
            ></textarea>
            {Comment && (
              <span style={{ color: '#af0c0c' }}>*Comment Is Required</span>
            )}
          </div>

          <input
            type='file'
            name='attachments'
            id='upload'
            hidden
            ref={hiddenFileInput}
            onChange={addFile}
          />
          <div
            className='bd-btn bd-btn--outline '
            for='upload'
            onClick={() => hiddenFileInput.current.click()}
          >
            <span for='upload'>
              <i class='icon-clip-icon mr-10'></i>
              Attach Files
            </span>
          </div>
          <div className='bd-attachment__file mb-10'>
            {attachments &&
              attachments.map((attachment, i) => {
                return (
                  <a className='download-a' download href={attachment.location}>
                    <div className='attachment-name'>{attachment.name}</div>
                    {/* <i
                      className='bluddelete paddingTop_3'
                      onClick={(e) => deleteAttachment(e, i)}
                    /> */}
                  </a>
                );
              })}
          </div>
          <div className='bd-btn__wrap justify-content-between'>
            <div
              class='bd-btn bd-btn--outline'
              onClick={() => {
                setShowPopUp(false);
              }}
            >
              <span>Cancel </span>
            </div>

            {info?.state && info?.state == 'Blocked' ? (
              <div
                className='bd-btn  bd-btn--primary'
                onClick={() => {
                  // blockUnbockAction('Unblocked');
                  blockUnbockAction('Unblocked');
                }}
              >
                <span>unblock</span>
              </div>
            ) : (
              <div
                className='bd-btn  bd-btn--primary'
                onClick={() => {
                  // blockUnbockAction('Unblocked');
                  blockUnbockAction('Blocked');
                }}
              >
                <span>Block</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockUnblockInstituttionPopup;
