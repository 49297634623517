import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_PATH, UPLOAD_FILE_URL } from '../../utils/config';
import { useDispatch, useSelector } from 'react-redux';
import RegisterView from '../../views/RegisterView';
import {
  triggerAuthRegister,
  triggerFetchInstituteList,
} from '../../redux/actions';
import { validateRegister } from '../../utils';
import { AuthHeader } from '../../components/AuthHeader';
import AddNewInstituteContainer from '../AddNewInstituteContainer';
import {
  checkBoxArrBloodBank,
  checkBoxArrHospital,
  checkBoxArrUndertakingBloodBank,
  checkBoxArrUndertakingHospital,
  checkBoxArrUndertakingNGO,
} from '../../utils/constants';

function RegisterContainer() {
  const [attachments, setAttachments] = useState([]);

  var regAPIObj = {
    institutionType: 'BloodBank',
    institutionName: '',
    contactNumber: '',
    contactEmail: '',
    attachments: [],
    registrationNumber: '',

    address: {
      description: '',
    },
    keyMembers: {
      firstName: '',
      lastName: '',
      email: '',
      designation: '',
    },
  };

  const NgoObj = {
    InstitutionName: '',
    registrationNumber: '',
    address: '',
    state: '',
    City: '',
    postalCode: '',
    Latitude: '',
    Langitude: '',
    // keyMembers: {
    firstname: '',
    lastName: '',
    emailId: '',
    designation: '',
    mobileNumber: '',
    // },
  };

  const dispatch = useDispatch();
  const { registerObj, instituteListObj } = useSelector(
    (state) => state.authReducer
  );
  const [currSelected, setCurrSelected] = useState('blood_bank');
  const [regObj, setRegisterObj] = useState(regAPIObj);
  const [errorMsg, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [checkBox, setCheckBox] = useState(
    currSelected == 'hospital' ? checkBoxArrHospital : checkBoxArrBloodBank
  );
  const [checkBoxUndertaking, setCheckBoxUndertaking] = useState(
    currSelected == 'hospital'
      ? checkBoxArrUndertakingHospital
      : currSelected == 'blood_bank'
      ? checkBoxArrUndertakingBloodBank
      : currSelected == 'NGO'
      ? checkBoxArrUndertakingNGO
      : ''
  ); //(checkBoxArrUndertaking);

  function curelected() {}

  const [disable, setDisable] = useState(true);
  const [createNewInstitute, setCreateNewInstitute] = useState(false);
  const [instituteNameFocused, setInstituteNameFocused] = useState(false);

  function getData() {
    const data = localStorage.getItem('ngoRegObj');
    return data ? JSON.parse(data) : NgoObj;
  }

  ////////  START NGO / Camp Org TAB Data............. //////////\

  const [ngoRegObj, setNgoRegObj] = useState(getData());
  const [typeOfNgo, setTypeOfNgo] = useState('');

  localStorage.setItem('ngoRegObj', JSON.stringify(ngoRegObj));

  function onNgoChangeText(e) {
    let { name, value } = e.target;

    let updatedngoRegObj = ngoRegObj;
    // if (option === 'keyMember') {
    //   updatedngoRegObj['keyMembers'][e.target.name] = e.target.value;
    // } else {
    updatedngoRegObj[name] = value;
    // }
    let completeObj = { ...ngoRegObj, ...updatedngoRegObj };
    setNgoRegObj(completeObj);
    setDisable(!NGORegEnablBtn());
  }

  function onSubmitNGO() {
    let obj = {
      institutionType: currSelected,
      ngoType: typeOfNgo.toLowerCase(),
      institutionName: ngoRegObj?.InstitutionName,
      registrationNumber: ngoRegObj?.registrationNumber,
      countryCode: '91',
      address: {
        type: 'Point',
        coordinates: [
          parseFloat(ngoRegObj?.Latitude),
          parseFloat(ngoRegObj?.Langitude),
        ],
        description: ngoRegObj?.address,
        city: ngoRegObj?.City,
        state: ngoRegObj?.state,
        zipcode: ngoRegObj?.postalCode,
      },
      attachments: [],
      contactEmail: '',
      contactNumber: ngoRegObj?.keyMembers?.mobileNumber,
      keyMembers: [
        {
          firstName: ngoRegObj?.firstname,
          lastName: ngoRegObj?.lastName,
          email: ngoRegObj?.emailId,
          designation: ngoRegObj?.designation,
        },
      ],
    };
    function callBack() {
      setTypeOfNgo('');
      setNgoRegObj(NgoObj);
      // setNgoRegObj(NgoObj.keyMembers);
      localStorage.removeItem('ngoRegObj');
      setSuccessMsg(
        'User Camp org. / NGO registered successfully, please check your email'
      );
    }
    return dispatch(triggerAuthRegister(obj, callBack()));
  }

  function NGORegEnablBtn() {
    return Object.values(ngoRegObj).every((item) => item !== '');
  }

  // ////////  END NGO / Camp Org TAB Data............. //////////
  function enableBtn() {
    let checkObj = { ...regObj };
    let declaration = checkBoxUndertaking.every((data) => {
      return data.checked;
    });
    return (
      Object.values(checkObj).every(
        (item) =>
          item != '' &&
          Object.values(checkObj.address).every((item2) => item2 != '') &&
          Object.values(checkObj.keyMembers).every((item3) => item3 != '')
      ) && declaration
    );
  }
  const onChangeText = (e, option) => {
    let updatedRegObj = regObj;
    if (option === 'keyMember') {
      updatedRegObj['keyMembers'][e.target.name] = e.target.value;
    } else {
      updatedRegObj[e.target.name] = e.target.value;
    }
    let completeObj = { ...regObj, ...updatedRegObj };
    setRegisterObj(completeObj);
    setDisable(!enableBtn());
  };

  const onCheckBoxClick = (index, type) => {
    let chArr;
    if (type === 'undertaking') {
      chArr = [...checkBoxUndertaking];
    } else {
      chArr = [...checkBox];
    }
    chArr[index].checked = !chArr[index].checked;
    if (type === 'undertaking') {
      setCheckBoxUndertaking(chArr);
    } else {
      setCheckBox(chArr);
    }
    setDisable(!enableBtn());
  };

  const onInstituteSearch = (e) => {
    onChangeText(e);
    return dispatch(triggerFetchInstituteList(e.target.value, currSelected));
  };

  const onInstituteSelect = (data) => {
    setInstituteNameFocused(false);
    let updatedRegObj = regObj;
    updatedRegObj['institutionName'] = data?.institutionName;
    updatedRegObj['address'] = data?.location;
    updatedRegObj['referenceId'] = data?._id;
    setRegisterObj(updatedRegObj);
    setDisable(!enableBtn());
  };

  function tabChange(type) {
    setSuccessMsg('');
    setTypeOfNgo('');
    // setNgoRegObj(NgoObj);
    setNgoRegObj(getData());

    setCurrSelected(type);
    setRegisterObj(regAPIObj);
    setInstituteNameFocused(false);
    setError('');
    setCheckBox(
      type === 'hospital' ? checkBoxArrHospital : checkBoxArrBloodBank
    );
    setCheckBoxUndertaking(
      // type === 'hospital'
      //   ? checkBoxArrUndertakingHospital
      //   : checkBoxArrUndertakingBloodBank

      type == 'hospital'
        ? checkBoxArrUndertakingHospital
        : type == 'blood_bank'
        ? checkBoxArrUndertakingBloodBank
        : type == 'NGO'
        ? checkBoxArrUndertakingNGO
        : ''
    );
  }

  const hiddenFileInput = React.useRef(null);

  async function addFile(e) {
    let selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    let response = await axios.post(API_BASE_PATH + UPLOAD_FILE_URL, formData, {
      headers: {
        token: `${localStorage.getItem('TOKEN_KEY')}`,
      },
    });
    let file = response.data.content.savedFiles[0];
    let arr = [...regObj?.attachments];
    if (file) arr.push(file);

    setRegisterObj({ ...regObj, attachments: arr });
  }

  function deleteAttachment(e, index) {
    e.preventDefault();
    let files = [...regObj?.attachments];
    files.splice(index, 1);
    setRegisterObj({ ...regObj, attachments: files });
  }
  var keyMembersPoint = [];

  const onSubmit = (e) => {
    setError('');

    if (currSelected === 'blood_bank') {
      regObj.institutionType = 'BloodBank';
    } else {
      regObj.institutionType = 'Hospital';
    }

    let undertakingChecked = checkBoxUndertaking.filter((item) => {
      return !item.checked;
    });
    if (undertakingChecked.length) {
      setError('Please check Declaration & Undertaking');
    }
    // jugad gor add more / validators **START**
    // const mailformat =
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // keyMembersPoint.push(regObj?.keyMembers);
    // if (
    //   keyMembersPoint[0]?.email !== '' &&
    //   keyMembersPoint[0]?.email?.match(mailformat)
    // ) {
    //   regObj['keyMembersssss'] = keyMembersPoint;
    //   regObj['keyMembers'] = regObj['keyMembersssss'];
    //   delete regObj['keyMembersssss'];
    //   console.log('🚀 ~ file: index.js ~ line 297 ~ onSubmit ~ regObj', regObj);
    // } else {
    //   keyMembersPoint = [];
    //   setError('Invalid Email');
    // }
    // jugad for add more / validators **END**

    keyMembersPoint.push(regObj?.keyMembers);
    console.log(keyMembersPoint);
    regObj.keyMembers = keyMembersPoint;
    console.log(regObj);

    console.log(
      '🚀 ~ file: index.js ~ line 313 ~ onSubmit ~  validateRegister(regObj, setError || setSuccessMsg)',
      validateRegister(regObj, setError || setSuccessMsg)
    );
    if (
      // regObj &&
      // validateRegister(regObj, setError || setSuccessMsg) &&
      !undertakingChecked.length
    ) {
      return dispatch(
        triggerAuthRegister(regObj, () => {
          setCheckBox(
            currSelected === 'hospital'
              ? checkBoxArrHospital
              : checkBoxArrBloodBank
          );
          setCheckBoxUndertaking(
            currSelected === 'hospital'
              ? checkBoxArrUndertakingHospital
              : checkBoxArrUndertakingBloodBank
          );
          setRegisterObj(regAPIObj);
          setSuccessMsg(
            'User registered successfully, please check your email'
          );
        })
      );
    }
  };

  function addNewInstition() {
    setCreateNewInstitute(true);
  }

  return (
    <>
      <AuthHeader />

      <RegisterView
        regObj={regObj}
        checkBoxUndertaking={checkBoxUndertaking}
        currSelected={currSelected}
        disable={disable}
        hiddenFileInput={hiddenFileInput}
        attachments={attachments}
        registerObj={registerObj}
        errorMsg={errorMsg}
        successMsg={successMsg}
        checkBoxArr={checkBox}
        instituteListObj={instituteListObj}
        instituteNameFocused={instituteNameFocused}
        setCurrSelected={setCurrSelected}
        addFile={addFile}
        deleteAttachment={deleteAttachment}
        tabChange={tabChange}
        onChangeText={onChangeText}
        onSubmit={onSubmit}
        onCheckBoxClick={onCheckBoxClick}
        setInstituteNameFocused={setInstituteNameFocused}
        onInstituteSearch={onInstituteSearch}
        onInstituteSelect={onInstituteSelect}
        addNewInstition={addNewInstition}
        // NGO BTN actons
        ngoRegObj={ngoRegObj} // API Object
        onNgoChangeText={onNgoChangeText}
        onSubmitNGO={onSubmitNGO}
        typeOfNgo={typeOfNgo}
        setTypeOfNgo={setTypeOfNgo}
      />
      {createNewInstitute && (
        <AddNewInstituteContainer
          instituteNameFocused={instituteNameFocused}
          regObj={regObj}
          setRegisterObj={setRegisterObj}
          setDisable={setDisable}
          enableBtn={enableBtn}
          setCreateNewInstitute={setCreateNewInstitute}
          setInstituteNameFocused={setInstituteNameFocused}
        />
      )}
    </>
  );
}

export default RegisterContainer;
