import React from 'react';
import DatePicker from '../DatePicker';
import { Spinner } from '../Spinner';

function PopupLabs(props) {
  let {
    showAddResultPopup,
    onChangeText,
    onSubmit,
    showResultOption,
    onResultSelect,
    setShowResultOption,
    result,
    resultsArray,
    // showLabOption,
    // onLaboratorySelect,
    // setShowLabOption,
    // laboratory,
    onResultArrEdit,
    postError,
    postLabResultObj,
    singleObj,
    getSelectedDate,
  } = props;
  let classNameResult = '';
  if (result === 'Fail') {
    classNameResult = 'bd-red__color';
  } else if (result === 'Pass') {
    classNameResult = 'bd-green__color';
  }
  let date = new Date(singleObj.collectionDate).toDateString().split(' ');
  return (
    <div class='bd-modal-wrap bd-modal-right bd-lbresult'>
      <div class='bd-popup si-fade-in'>
        <div class='bd-popup__head'>
          <span>Lab Results</span>

          <div class='bd-form__row'>
            <div className='d-flex-center'>
              <span>Report #</span>
              <input
                type='text'
                name='reportNo'
                onChange={(e) => onChangeText(e)}
              />
            </div>
          </div>
        </div>
        <div class='bd-popup__body'>
          <div class='bd-form'>
            <div class='bd-form__row bd-form__row--1'>
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}>Donor ID</span>
                <input
                  disabled
                  type='text'
                  name='donorId'
                  value={singleObj?.donorId}
                  onChange={(e) => onChangeText(e)}
                />
              </div>
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}>Blood Group</span>
                <input
                  type='text'
                  name='bloodGroup'
                  onChange={(e) => onChangeText(e)}
                />
              </div>

              <div class='bd-calender'>
                <input
                  disabled
                  type='text'
                  placeholder='Date'
                  name='collectedDate'
                  value={`${date[2]} ${date[1]} ${date[3]}`}
                  onChange={(e) => onChangeText(e)}
                />
              </div>
            </div>
            {/* <div class="bd-form__row bd-form__row--3"> */}
            <div class='bd-form__row bd-form__row--2 '>
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}>SampleID</span>
                <input
                  disabled
                  type='text'
                  value={singleObj?.sample?.sampleId_1}
                />
              </div>
              {/* <div className="d-flex-center">
                <span style={{ marginRight: "10px" }}>Sample ID_2</span>
                <input type="text" value={singleObj?.sample?.sampleId_2} />
              </div>
              <div className="d-flex-center">
                <span style={{ marginRight: "10px" }}>Sample ID_3</span>
                <input type="text" value={singleObj?.sample?.sampleId_3} />
              </div> */}
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}>Received from</span>
                <input
                  disabled
                  type='text'
                  value={singleObj?.sample?.location?.description}
                />
              </div>
              {/* <div class="bd-dorpdown active">
                <div
                  class="bd-dorpdown__selected"
                  onClick={() => setShowLabOption(!showLabOption)}
                > 
                  <span>{laboratory}</span>
                  <i class="bludbig-arrow"></i>
                </div>
                {showLabOption && (
                  <div class="bd-dorpdown__option ">
                    <div
                      class="bd-dorpdown__list d-flex-center"
                      onClick={() => {
                        onLaboratorySelect("inhouse");
                      }}
                    >
                      <span>inhouse</span>
                    </div>
                    <div
                      class="bd-dorpdown__list"
                      onClick={() => {
                        onLaboratorySelect("inhouse");
                      }}
                    >
                      <span>inhouse</span>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
            {/* <div class="bd-form__row bd-form__row--3">
              <div className="d-flex-center">
                <span style={{ marginRight: "10px" }}>Weight</span>
                <input
                  type="text"
                  name="weight"
                  onChange={(e) => onChangeText(e)}
                />
                <span>kgs</span>
              </div>
              <div className="d-flex-center">
                <span style={{ marginRight: "10px" }}>Blood Pressure</span>
                <input
                  type="text"
                  name="bloodPressure"
                  onChange={(e) => onChangeText(e)}
                />
              </div>
              <div className="d-flex-center">
                <span style={{ marginRight: "10px" }}>Hb</span>
                <input
                  type="text"
                  name="Hb"
                  onChange={(e) => onChangeText(e)}
                />
              </div>
            </div> */}
            <div class='bd-form__row bd-form__row--2'>
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}>Tested by</span>
                <input
                  type='text'
                  name='testedBy'
                  onChange={(e) => onChangeText(e)}
                />
              </div>
              <div className='bd-calender'>
                <label></label>
                <DatePicker
                  className='d-flex-center'
                  getSelectedDate={getSelectedDate}
                />
              </div>

              {/* <div className="bd-form__row">
                <div className="bd-calender">
                  <DatePicker getSelectedDate={getSelectedDate} />
                </div>
              </div> */}

              {/* <div class="bd-calender">
                <label></label>
                <DatePicker getSelectedDate={getSelectedDate} />
              </div> */}
            </div>
          </div>
          <div class='bd-tbl  bd-tbl__theme1'>
            <div class='bd-tbl__head'>
              <div class='bd-tbl__row'>
                <div class='bd-tbl__cell'>
                  <span>Parameters</span>
                </div>
                <div class='bd-tbl__cell'>
                  <span>Standard</span>
                </div>
                <div class='bd-tbl__cell'>
                  <span>Observation</span>
                </div>
                <div class='bd-tbl__cell'>
                  <span>Remarks</span>
                </div>
              </div>
            </div>
            <div class='bd-tbl__body'>
              {resultsArray.map((data, index) => {
                return (
                  <div class='bd-tbl__row'>
                    <div class='bd-tbl__cell'>
                      <span>{data.parameter}</span>
                    </div>
                    <div class='bd-tbl__cell'>
                      <span>{data.standard}</span>
                    </div>
                    <div class='bd-tbl__cell'>
                      <input
                        type='text'
                        name='observation'
                        placeholder='00.00'
                        onChange={(e) => onResultArrEdit(e, index)}
                      />
                    </div>
                    <div class='bd-tbl__cell'>
                      <input
                        type='text'
                        placeholder='remarks'
                        name='remarks'
                        onChange={(e) => onResultArrEdit(e, index)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div class='bd-print__result'>
            <div class='bd-print__result--btn'>
              <span>Result</span>
              <i class='bludprint'></i>
            </div>
            <div class='bd-dorpdown active'>
              <div
                class='bd-dorpdown__selected'
                onClick={() => setShowResultOption(!showResultOption)}
              >
                <span class={classNameResult}>{result}</span>
                <i class='bludbig-arrow'></i>
              </div>
              {showResultOption && (
                <div class='bd-dorpdown__option'>
                  <div
                    class='bd-dorpdown__list bd-red__color'
                    onClick={() => {
                      onResultSelect('Fail');
                    }}
                  >
                    <span>Fail</span>
                  </div>
                  <div
                    class='bd-dorpdown__list bd-green__color'
                    onClick={() => {
                      onResultSelect('Pass');
                    }}
                  >
                    <span>Pass</span>
                  </div>
                  <div
                    class='bd-dorpdown__list'
                    onClick={() => {
                      onResultSelect('Conditional Release');
                    }}
                  >
                    <span>Conditional Release</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div class='bd-comment'>
            <input
              type='text'
              placeholder='Comment'
              name='comments'
              onChange={(e) => onChangeText(e)}
            />
          </div>
          <div
            className='bd-form__row bd-form__row--forgotpass'
            style={{ marginTop: '10px', textAlign: 'center' }}
          >
            <span className='bd-incorrect__msg'>
              {postError
                ? postError
                : postLabResultObj && postLabResultObj.error
                ? postLabResultObj.error
                : ''}
            </span>
          </div>

          <div class='bd-btn__wrap'>
            <div class='bd-btn bd-btn--outline' onClick={showAddResultPopup}>
              <span>Cancel</span>
            </div>
            <div
              class='bd-btn bd-btn--primary db-bg__green d-flex justify-content-center text-center'
              onClick={onSubmit}
              disabled={postLabResultObj.loading}
            >
              {postLabResultObj.loading && <Spinner />}
              <span>Submit</span>
            </div>
          </div>
        </div>
      </div>
      <div class='bd-popup__lightbox'></div>
    </div>
  );
}

export default PopupLabs;
