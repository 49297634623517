import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Drawer } from '../../../components/Drawer';
import { Header } from '../../../components/Header';
import { isLogin } from '../../index';

const PrivateRoute = ({ component: Component, subRoutes, ...rest }) => {
  let redirectRoute =
    localStorage.getItem('identifier') == 'admin'
      ? '/Super-Admin/login'
      : '/login';
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <div className='bd-main'>
            <div className='bd-container-fluid'>
              <Drawer />
              <div className='bd-main__container'>
                <Header />
                <Component {...props} subRoutes={subRoutes} />
              </div>
            </div>
          </div>
        ) : (
          <Redirect to={redirectRoute} />
        )
      }
    />
  );
};

export default PrivateRoute;
