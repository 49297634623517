import React, { useEffect, useState } from 'react';

export default function TableOne({
  tab,
  onClick,
  columns,
  headerClick,
  tableData,
  rowClick,
  headerDropDown,
}) {
  // const [selected, setSelected] = useState(false);
  const [bloodGroupDropDown, setBloodGroupDropDown] = useState('');

  const handleClick = (e) => {
    // setSelected(e.isTrusted);
    onClick(e);
  };
  const createDropdownList = (data) => {
    return (
      data.data &&
      data.data.list.map((item, key) => {
        return (
          <option key={key} value={item === 'default' ? '' : item}>
            {data.data.list[key]}
          </option>
        );
      })
    );
  };
  const HeaderDropDown = (data) => {
    return (
      <select
        className='table-dropdown'
        value={bloodGroupDropDown ? bloodGroupDropDown : ''}
        id='dropdown'
        onChange={(e) => {
          setBloodGroupDropDown(e.target.value);
          data.data.headerDropDownChange(e);
        }}
      >
        <option key={''} value={'Select Blood Group'}></option>
        {createDropdownList(data)}
      </select>
    );
  };
  function renderHeader() {
    return (
      <div className='bd-tbl__row '>
        {columns &&
          columns.length > 0 &&
          columns.map((col, i) => {
            return (
              <div
                key={`${col}_${i}`}
                className='bd-tbl__cell'
                dataIndex={col.dataIndex}
              >
                <span
                  className='table-l-cell'
                  style={{ fontWeight: '700' }}
                  dataindex={col.dataIndex}
                  onClick={headerClick ? headerClick : null}
                >
                  {col.title}
                </span>
                {headerDropDown && headerDropDown[col.dataIndex] ? (
                  <HeaderDropDown data={headerDropDown[col.dataIndex]} />
                ) : null}
              </div>
            );
          })}
      </div>
    );
  }
  function noDataImg() {
    return (
      <div className='align-center-no-data'>
        <div className='bd-tbl__body'>{RenderImages(tab)}</div>
      </div>
    );
  }
  function RenderImages(tab) {
    switch (tab) {
      case 'RequestTab':
        return (
          <div className='bd-noRequestData'>
            <span> No New Request </span>
          </div>
        );
      case 'DonationTab':
        return (
          <div className='bd-noDonationData'>
            <span>No Donors scheduled </span>
            <span>for donation </span>
          </div>
        );
      case 'CampTab':
        return (
          <div className='bd-noCampData'>
            <span>Looks like no donation</span>
            <span>drives planned</span>
          </div>
        );
      case 'PendingCamp':
        return (
          <div className='bd-noCampData'>
            <span>No Pending Camp List</span>
          </div>
        );
      case 'AcceptedCamp':
        return (
          <div className='bd-noCampData'>
            <span>No Camp List</span>
          </div>
        );
      case 'InventoryTab':
        return (
          <div className='bd-noInventoryData'>
            <span>Looks like you don’t </span>
            <span>have any unit</span>
          </div>
        );
      case 'VerifyPendingTab':
        return (
          <div className='bd-noVerifyData'>
            <span>None pending</span>
            <span>Approval</span>
          </div>
        );
      case 'VerifyAllTab':
        return (
          <div className='bd-noVerifyData'>
            <span>None awaiting</span>
            <span>Approval</span>
          </div>
        );
      case 'DonorNearby':
        return (
          <div className='bd-noDonationData'>
            <span>No Donors Available</span>
            <span>nearby</span>
          </div>
        );
      case 'AvailableForDonation':
        return (
          <div className='bd-noDonordata'>
            <span>No Donors Available</span>
            <span>for Donation</span>
          </div>
        );
      case 'Users':
        return (
          <div className='bd-noDonordata'>
            <span>No Users Available</span>
            {/* <span>for Donation</span> */}
          </div>
        );
      case 'RecepientTab':
        return (
          <div className='bd-noDonordata'>
            <span>No Recepient Available</span>
          </div>
        );
      case 'Requested-for':
        return (
          <div className='bd-noDonordata'>
            <span>No Request</span>
          </div>
        );
      default:
        return (
          <div className='bd-nodata'>
            <span>No Data Available </span>
          </div>
        );
    }
  }

  return (
    <div className='bd-request__tbl'>
      <div className='bd-tbl bd-tbl__theme1'>
        <div class='bd-tbl__head'>
          {tableData.length === 0 && renderHeader()}
        </div>
        {tableData.length === 0 ? (
          noDataImg()
        ) : (
          <div className='bd-tbl__body'>
            {renderHeader()}
            {tableData.map((data, i) => {
              return (
                <div
                  key={`${data}_${i}`}
                  id={data._id ? data._id : data.userId}
                  onClick={rowClick ? handleClick : null}
                  className={'bd-tbl__row'}
                  style={{ cursor: 'pointer' }}
                >
                  {columns.map((col, j) => {
                    return (
                      <div
                        key={`${col}_${j}`}
                        id={data._id ? data._id : data.userId}
                        style={{ cursor: 'pointer' }}
                        className='bd-tbl__cell'
                      >
                        <span id={data._id ? data._id : data.userId}>
                          {data[col.dataIndex]}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
