import React from 'react';
import logo from '../../assets/images/logo.png';

function AuthHeader() {
  return (
    <header className='bd-hader bd-auth-header'>
      <div className='bd-search active'>
        <a href='http://blud.app'>
          <img src={logo} height='40' alt='logo' />
        </a>
      </div>
      {/* <div className="bd-notification">
                <div
                    className="bd-btn bd-btn--primary bd_header_div" 

                >
                    Get notified
                  </div>
            </div> */}
    </header>
  );
}

export { AuthHeader };
