import React, { useState } from 'react';
import { ageCalculation, genderWiseProfilePic } from '../../utils';

export function ProfilePopup({
  profileData,
  otpInput,
  setShowVerifyOTP,
  setShowProfilePopup,
  showVerifyOTP,
  onVerifyClick,
  onRequestClick,
}) {
  const refObj = {
    input_0: React.createRef(),
    input_1: React.createRef(),
    input_2: React.createRef(),
    input_3: React.createRef(),
    input_4: React.createRef(),
    input_5: React.createRef(),
  };

  const [activeEle, setActiveEle] = useState('input_0');

  function onInputFocus(e) {
    setActiveEle(e.target.id);
  }
  function onInputChange(e) {
    let ele = e.target.id;
    if (e.target.value !== '') {
      const length = refObj[ele].value.length;
      const count = ele.split('_')[1] * 1 + 1;
      if (refObj['input_' + count]) {
        refObj['input_' + count].focus();
        refObj['input_' + count].setSelectionRange(length, length);
      }
    }
    otpInput(refObj);
  }
  const cells = [
    { label: 'Name:', value: profileData.name },
    { label: 'Gender:', value: profileData.gender },
    {
      label: 'Age:',
      value:
        profileData && profileData.age ? ageCalculation(profileData.age) : '',
    },
    { label: 'BMI:', value: profileData.BMI ?? '--' },
    { label: 'Email:', value: profileData.email ?? '--' },
    { label: 'Phone:', value: profileData.phone ?? '--' },
    { label: 'Address:', value: profileData.address ?? '--' },
    { label: 'Available since:', value: profileData.availableDate ?? '--' },
  ];

  return (
    <div className='bd-modal-wrap bd-profile'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Profile of Donor ID - {profileData.donorId}</span>
          <i
            className='bludclose'
            onClick={() => {
              setShowVerifyOTP(false);
              setShowProfilePopup({ show: false });
            }}
          ></i>
        </div>
        <div className='bd-popup__body'>
          <div className='bd-profile__tbl'>
            <div className='bd-profile__detail--tbl'>
              <div className='bd-profile__detail--lft'>
                <div className='bd-profile__detail'>
                  <div
                    className='bd-profile__img'
                    style={{
                      backgroundImage: profileData.photo
                        ? `url(${profileData.photo})`
                        : `url(${genderWiseProfilePic(profileData.gender)})`,
                    }}
                  ></div>
                  <div className='bd-profile__group'>
                    <span>{profileData.bloodGroup}</span>
                  </div>
                </div>
                <div className='bd-tbl bd-tbl__theme2'>
                  <div className='bd-tbl__body'>
                    {cells.map((cell) => {
                      return (
                        <div className='bd-tbl__row'>
                          <div className='bd-tbl__cell'>
                            <span>{cell.label}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{cell.value}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {showVerifyOTP ? (
              <div className='bd-profile__otp'>
                <div className='bd-popup__head'>
                  <span>Verify OTP</span>
                </div>
                <div className='bd-form__row pd-lr'>
                  <label>OTP</label>
                  <div className='bd-otp__box'>
                    {[1, 2, 3, 4, 5, 6].map((ele, i) => {
                      return (
                        <input
                          // autoFocus
                          key={i}
                          ref={(input) => (refObj['input_' + i] = input)}
                          type='text'
                          id={'input_' + i}
                          maxLength='1'
                          className={'input_' + i === activeEle && 'active'}
                          onFocus={onInputFocus}
                          onChange={onInputChange}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className='bd-btn__wrap'>
                  <div
                    className='bd-btn bd-btn--outline'
                    onClick={() => {
                      setShowVerifyOTP(false);
                      setShowProfilePopup({ show: false });
                    }}
                  >
                    <span>Cancel</span>
                  </div>

                  <div
                    className='bd-btn bd-btn--primary'
                    onClick={onVerifyClick}
                  >
                    <span>Verify</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className='bd-btn__wrap' onClick={onRequestClick}>
                <div className='bd-btn bd-btn--primary'>
                  <span>Request OTP from Donor</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}

// <i className='bludclose' onClick={CloseCampPopUp}></i>;
{
  /* <div className='bd-profile__otp'>
  <div className='bd-btn__wrap'>
    <div className='bd-btn bd-btn--primary'>
      <span>Decline </span>
    </div>

    <div className='bd-btn  bd-btn--accept active'>
      <span>Schedule</span>
    </div>
  </div>
</div>; */
}
