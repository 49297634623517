import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProfilePopup,
  ProfileDetailPopup,
  SuccessPopup,
  FailPopup,
  Loader,
  // Dropdown,
} from '../../components';
// import { getOTP } from "../../utils";
import moment from 'moment';
import {
  getDonarProfile,
  getDonarOTP,
  validateDonorOTP,
  getDonorLabResultData,
  getDonationHistoryData,
  getMedicalHistoryData,
} from '../../redux/actions/dashboardAction';
import TablePagination from '@material-ui/core/TablePagination';
import { CheckBox } from '../../components';
import { formatDate, getOTP, sorting } from '../../utils';
import { PEOPLE_DONORS_COLUMNS } from '../../utils/constants';
import DetailPopupView from './DetailPopupView';
import RequestBloodContainer from '../../containers/DashboardContainer/RequestBloodContainer';
import {
  VALIDATE_DONOR_OTP,
  GET_DONOR_OTP,
  BLOCK_AND_UNBLOCK,
} from '../../redux/actions/actionTypes';

function DonorView({
  headerDropDown,
  peopleDonorObj,
  setDataList,
  search,
  serachData,
}) {
  const dispatch = useDispatch();
  const { donarProfile, donarOTPData, historyList, labResultList, medicaList } =
    useSelector((state) => state.dashboardReducer);
  const { blockDonorObj } = useSelector((state) => state.peopleReducer);
  const { loginObj } = useSelector((state) => state.loginReducer);
  let institutionType = loginObj?.data?.content?.data?.institutionType ?? '';

  const [table, setTable] = useState([]);
  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
  const [OTP, setOTP] = useState('');
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState({
    id: '',
    show: false,
  });
  const [showProfileDetailPopup, setShowProfileDetailPopup] = useState({
    id: '',
    show: false,
  });
  const [requetBlood, setRequetBlood] = useState({
    id: '',
    show: false,
  });
  const [blockDonor, setBlockDonor] = useState({
    id: '',
    show: false,
  });
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(null);
  const [tabsData, setTabsData] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [blockBtns, setBlockBtns] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [donorProfileData, setDonoProfileData] = useState({
    donorId: '',
    bloodGroup: '',
    name: '',
    gender: '',
    age: '',
    BMI: '',
    email: '',
    phone: '',
    address: '',
    availableDate: '',
  });
  const [activeTab, setActiveTab] = useState('History');
  function onTabChanges(tab) {
    setActiveTab(tab);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleCilck(e) {
    console.log('hahahahahahahahahahahah', e.target.getAttribute('donorId'));

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    let user = tableData.filter(
      (t) => t.donorId == e.target.getAttribute('donorId')
    )[0];

    if (e.target.id.split('_')[0] !== 'checkbox') {
      setShowProfilePopup({
        show: true,
        id: e.target.donorId,
      });
      dispatch(
        getDonarProfile({
          donorId: e.target.getAttribute('donorId'),
          userType: user?.userType,
        })
      );
    }
  }
  function onRequestClick(e) {
    setShowVerifyOTP(true);
    dispatch(getDonarOTP({ donorId: showProfilePopup.id }));
  }

  function handleBlockUnblock(params) {
    setType(params);
    let user = tableData.filter((t) => t.isSelected)[0];
    setBlockDonor({
      id: user.donorId,
      show: true,
    });

    dispatch(
      getDonarProfile({ donorId: user.donorId, userType: user?.userType })
    );
  }

  function otpInput(refObj) {
    let otp = getOTP(refObj);
    setOTP(otp);
  }
  function onVerifyClick() {
    dispatch(
      validateDonorOTP(
        {
          otp: OTP,
          id: donarOTPData.data.id,
        },
        () => {
          dispatch(
            getDonationHistoryData({ donorId: donarProfile.data.donorId })
          );
          dispatch(
            getDonorLabResultData({ donorId: donarProfile.data.donorId })
          );
          dispatch(
            getMedicalHistoryData({ donorId: donarProfile.data.donorId })
          );
        }
      )
    );
    setShowProfilePopup({ show: false });
    setShowVerifyOTP(false);
    // setShowProfileDetailPopup({ show: true });
  }

  function hidePopup() {
    setShowProfileDetailPopup({ show: false });
    setBlockDonor({ show: false });
    dispatch({
      type: VALIDATE_DONOR_OTP,
      loading: false,
    });
    dispatch({
      type: GET_DONOR_OTP,
      loading: false,
    });
  }

  function hideRequest() {
    setRequetBlood({ id: '', show: false });
  }

  function selectAllCheck(e) {
    let obj = [...table];

    obj.forEach((a) => {
      a.isSelected = e.target.checked;
    });
    let index = obj.findIndex((t) => t.isSelected == true);
    index != -1 && setShowButtons(true);
    index == -1 && setShowButtons(false);
    setSelectAllCheckBox(e.target.checked);
    setTable(obj);
  }

  function onCheckboxChange(e) {
    let donorId = e.target.id.split('_')[1];
    let obj = [...table];
    obj.forEach((a) => {
      if (a.donorId === donorId) {
        a.isSelected = e.target.checked;
      }
    });
    let index = obj.findIndex((t) => t.isSelected == true);
    index != -1 && setShowButtons(true);
    index == -1 && setShowButtons(false);
    setSelectAllCheckBox(false);
    setTable(obj);
  }

  function hideBtn() {
    let obj = [...table];
    obj.forEach((a) => {
      if (a.donorId) {
        a.isSelected = false;
      }
    });
    setShowButtons(false);
    setSelectAllCheckBox(false);
    setTable(obj);
  }
  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...table];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setTableData(data);

    setCount(count + 1);
  }
  var DonorData = [...peopleDonorObj];

  function headerDropDownChange(list) {
    // bloodGroup DropDown
    let data = [...list];
    if (headerDropDown === 'All/Any') {
      data = [...list];
    } else {
      data = data.filter((a) => a.bloodGroup === headerDropDown);
    }
    setTableData(data);
  }

  useEffect(() => {
    let filterList =
      search === '' ? peopleDonorObj : serachData(peopleDonorObj, search);
    headerDropDownChange(filterList);
  }, [peopleDonorObj, headerDropDown, search]);

  useEffect(() => {
    setTableData(DonorData);

    setTable(peopleDonorObj);
  }, [peopleDonorObj]);

  // const createDropdownList = (data) => {
  //   return (
  //     data.data &&
  //     data.data.list.map((item, key) => {
  //       return (
  //         <option key={key} value={item === "default" ? "" : item}>
  //           {data.data.list[key]}
  //         </option>
  //       );
  //     })
  //   );
  // };
  // function onHeaderDropDownChange(e) {
  //   onSelectChange(e);
  // }
  // const HeaderDropDown = (data) => {
  //   return (
  //     <select
  //       className="table-dropdown"
  //       value={bloodGroupDropDown ? bloodGroupDropDown : ""}
  //       id="dropdown"
  //       onChange={onHeaderDropDownChange}
  //     >
  //       <option key={""} value={""}></option>
  //       {createDropdownList(data)}
  //     </select>
  //   );
  // };

  const Tabs = [
    {
      title: 'History',
      tableData: {
        tableType: 'table-type-2',
        column: [
          { title: '#', dataIndex: 'hash', key: 'hash' },
          { title: 'Date', dataIndex: 'date', key: 'date' },
          { title: 'Component', dataIndex: 'component', key: 'component' },
          { title: 'Institute', dataIndex: 'institute', key: 'institute' },
          { title: 'Unit', dataIndex: 'unit', key: 'unit' },
          { title: 'Bag ID / Unit no', dataIndex: 'unitId', key: 'unitId' },
          { title: 'Comments', dataIndex: 'comments', key: 'comments' },
        ],
        data: [],
      },
    },
    {
      title: 'Lab Result',
      tableData: {
        tableType: 'table-type-2',
        column: [
          { title: '#', dataIndex: 'hash', key: 'hash' },
          { title: 'Date', dataIndex: 'date', key: 'date' },
          {
            title: (
              <div className='lab_table_header'>
                <b>BP</b>
                <div className='component-lab'>130/80 mmHg</div>
              </div>
            ),
            dataIndex: 'bp',
            key: 'bp',
          },
          {
            title: (
              <div className='lab_table_header'>
                <b>Weight</b>
                <div className='component-lab'>kg</div>
              </div>
            ),
            dataIndex: 'weight',
            key: 'weight',
          },
          {
            title: (
              <div className='lab_table_header'>
                <b>Hb</b>
                <div className='component-lab'>12/-17 mg/dl</div>
              </div>
            ),
            dataIndex: 'hb',
            key: 'hb',
          },
          {
            title: (
              <div className='lab_table_header'>
                <b>RBC</b>
                <div className='component-lab'>
                  4.5-5.5<sup>*</sup>10<sup>12</sup>
                </div>
              </div>
            ),
            dataIndex: 'rbc',
            key: 'rbc',
          },
          {
            title: (
              <div className='lab_table_header'>
                <b>WBC</b>
                <div className='component-lab'>
                  4.5<sup>*</sup>10<sup>9</sup>
                </div>
              </div>
            ),
            dataIndex: 'wbc',
            key: 'wbc',
          },
          {
            title: (
              <div className='lab_table_header'>
                <b>Platelet</b>
                <div className='component-lab'>
                  150-400<sup>*</sup>10<sup>9</sup>
                </div>
              </div>
            ),
            dataIndex: 'platelet',
            key: 'platelet',
          },
          {
            title: (
              <div className='lab_table_header'>
                <b>ESR</b>
                <div className='component-lab'>0-20 mm/Hr</div>
              </div>
            ),
            dataIndex: 'esr',
            key: 'esr',
          },
          {
            title: (
              <div className='lab_table_header'>
                <b>TLC</b>
                <div className='component-lab'>
                  4-11 k/mm<sup>3</sup>
                </div>
              </div>
            ),
            dataIndex: 'tlc',
            key: 'tlc',
          },
          { title: 'Result', dataIndex: 'result', key: 'result' },
        ],
        data: [],
      },
    },
    {
      title: 'Medical',
      tableData: {
        tableType: 'table-type-2',
        column: [
          { title: '#', dataIndex: 'hash', key: 'hash' },
          {
            title: 'Health Issue',
            dataIndex: 'health_issue',
            key: 'health_issue',
          },
          {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
          },
          { title: 'Condition', dataIndex: 'condition', key: 'condition' },
          { title: 'Medication', dataIndex: 'medication', key: 'medication' },
          { title: 'Comments', dataIndex: 'comments', key: 'comments' },
        ],
        data: [
          {
            hash: '25',
            health_issue: 'Not feeling well',
            description: 'Having cough',
            condition: 'Good',
            medication: 'cough syrup',
            comments: <span>Lorem Ipsum Lorem Lorem Ipsum Lorem </span>,
          },
        ],
      },
    },
    {
      title: 'Remarks',
      tableData: {
        tableType: 'table-type-2',
        column: [
          { title: '#', dataIndex: 'hash', key: 'hash' },
          { title: 'Date', dataIndex: 'date', key: 'date' },
          { title: 'Doctor', dataIndex: 'doctor', key: 'doctor' },
          { title: 'Event', dataIndex: 'event', key: 'event' },
          { title: 'Comments', dataIndex: 'comments', key: 'comments' },
        ],
        data: [
          {
            hash: '25',
            date: '23 Feb 20210',
            doctor: 'Mr. Junagade',
            event: 'Blood donation',
            comments: <span>Lorem Ipsum Lorem Lorem Ipsum Lorem </span>,
          },
        ],
      },
    },
  ];

  function escFunction(e) {
    if (e.keyCode === 27) {
      setShowVerifyOTP(false);
      setBlockDonor({ show: false });
      setRequetBlood({ show: false });
      setShowProfilePopup({ show: false });
      setShowProfileDetailPopup({ show: false });
    }
  }

  useEffect(() => {
    if (donarProfile.data) {
      setDonoProfileData({
        donorId: donarProfile.data.donorId,
        bloodGroup: donarProfile.data.bloodGroup,
        name: donarProfile.data.firstName + ' ' + donarProfile.data.lastName,
        gender: donarProfile.data.gender ? donarProfile.data.gender : '--',
        age: donarProfile.data.dateOfBirth
          ? donarProfile.data.dateOfBirth
          : '--',
        BMI: donarProfile.data.bmi ? donarProfile.data.bmi : '--',
        email: donarProfile.data.email,
        phone:
          (donarProfile.data.countryCode ? donarProfile.data.countryCode : '') +
          ' ' +
          (donarProfile.data.mobileNumber
            ? donarProfile.data.mobileNumber
            : ''),
        address: donarProfile.data.address
          ? donarProfile.data.address.description
          : '--',
        availableDate: formatDate(donarProfile.data.addedAt),
        photo: donarProfile.data.photo,
      });
    }
  }, [donarProfile]);

  useEffect(() => {
    if (blockDonorObj.error !== null) {
      setFail(true);
    } else if (blockDonorObj.data) {
      setShowSuccess(true);
    }
  }, [blockDonorObj]);

  useEffect(() => {
    if (donarOTPData.successFlag) {
      setShowProfileDetailPopup({ show: true });
    } else if (donarOTPData.error !== null) {
      setFail(true);
      // setError(donarOTPData.error);
    }
  }, [donarOTPData]);

  useEffect(() => {
    let obj = table.filter((a) => a.isSelected);
    if (obj.length === table.length) {
      setSelectAllCheckBox(true);
    } else {
      setSelectAllCheckBox(false);
    }

    obj.length == 1
      ? setBlockBtns(true)
      : obj.length > 1 && setBlockBtns(false);
  }, [table]);

  useEffect(() => {
    setDataList(tableData);
  }, [tableData]);

  useEffect(() => {
    if (!Tabs || !Tabs.length) return;
    if (historyList.data) {
      let temp = [...Tabs];
      historyList.data.map((history, index) => {
        let obj = {
          hash: index + 1,
          date: moment(new Date(history.date)).format('DD MMM YYYY'),
          component: history.component,
          institute: history.institutionName,
          unit: history.units,
          unitId: '',
          comments: history.comments,
        };
        temp[0].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }, [historyList.Tabs]);

  useEffect(() => {
    if (!Tabs || !Tabs.length) return;
    if (labResultList.data) {
      let temp = [...Tabs];
      labResultList.data.map((labResult, index) => {
        let obj = {
          hash: index + 1,
          date: moment(new Date(labResult.labResults.addedAt)).format(
            'DD MMM YYYY'
          ),
          bp: '',
          weight: '',
          hb: '',
          rbc: '',
          wbc: '',
          platelet: '',
          esr: '',
          tlc: '',
          result: (
            <i
              className={[
                'bd-result-icon',
                labResult.labResults.finalResult === 'Pass'
                  ? ''
                  : 'bd-red__color',
              ].join(' ')}
            ></i>
          ),
        };
        labResult.labResults.results.forEach((element) => {
          obj[element.parameter.toLowerCase()] = element.observation;
        });
        temp[1].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }, [labResultList]);

  useEffect(() => {
    if (!Tabs || !Tabs.length) return;
    if (medicaList.data) {
      let temp = [...Tabs];
      medicaList.data.map((history) => {
        let obj = {
          hash: '',
          date: '',
          component: '',
          institute: '',
          unit: '',
          unitId: '',
          comments: <span></span>,
        };
        temp[2].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }, [medicaList]);
  useEffect(() => {
    if (
      labResultList.loading ||
      medicaList.loading ||
      historyList.loading ||
      donarProfile.loading ||
      donarOTPData.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [donarProfile, donarOTPData, historyList, labResultList, medicaList]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  }, []);
  return (
    <>
      <article className='bd-article'>
        <section className=''>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              {/* <div className="bd-tbl__inventory bd-scroll__horz"> */}
              <div className='table-scroll'>
                {/* <div className="bd-people"> */}
                <div className='bd-tbl bd-tbl__theme1'>
                  <div className='bd-tbl__head'>
                    <div className='bd-tbl__row'>
                      <div className='bd-tbl__cell_people'>
                        {table.length > 0 ? (
                          <CheckBox
                            keyindex={'checkbox_1'}
                            id={'checkbox'}
                            checked={selectAllCheckBox}
                            onChange={selectAllCheck}
                          />
                        ) : (
                          ''
                        )}
                      </div>

                      {PEOPLE_DONORS_COLUMNS.map((data, i) => {
                        return (
                          <div
                            className='bd-tbl__cell_people bd-pointer'
                            key={i}
                          >
                            <span
                              onClick={onHeaderClick}
                              dataindex={data.dataIndex}
                            >
                              {data.title}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {!tableData.length ? (
                    <div className='align-center-no-data'>
                      <div className='bd-tbl__body'>
                        {/* <div className="bd-nodata"> */}
                        <div className='bd-noDonordata'>
                          <span>No Data Available</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    tableData.length > 0 &&
                    tableData
                      .map((data, i) => {
                        return (
                          <div className='bd-tbl__body' key={i}>
                            <div
                              className='bd-tbl__row'
                              onClick={handleCilck}
                              donorId={data.donorId}
                              key={i}
                            >
                              <div
                                className='bd-tbl__cell_people'
                                key={i}
                                donorId={data.donorId}
                              >
                                <CheckBox
                                  keyindex={data.donorId}
                                  id={`checkbox_${data.donorId}`}
                                  checked={data.isSelected}
                                  onChange={onCheckboxChange}
                                />
                              </div>
                              <div
                                donorId={data.donorId}
                                // className = "bd-tbl__cell_people bd-pointer"
                                className={
                                  data.status == 'Blocked'
                                    ? 'bd-tbl__cell_people bd-pointer width-15 bd-clr-red'
                                    : 'bd-tbl__cell_people bd-pointer width-15'
                                }
                              >
                                <span donorId={data.donorId}>{data.name}</span>
                              </div>
                              <div
                                // className='bd-tbl__cell_people bd-tbl__cell_people--center'
                                className={
                                  data.status == 'Blocked'
                                    ? 'bd-tbl__cell_people  bd-pointer  bd-tbl__cell_people--center bd-clr-red'
                                    : 'bd-tbl__cell_people cell-bold bd-tbl__cell_people--center bd-pointer'
                                }
                                donorId={data.donorId}
                              >
                                <span donorId={data.donorId}>
                                  {data.bloodGroup}
                                </span>
                              </div>
                              <div
                                donorId={data.donorId}
                                // className="bd-tbl__cell_people bd-pointer"
                                className={
                                  data.status == 'Blocked'
                                    ? 'bd-tbl__cell_people bd-pointer bd-clr-red'
                                    : 'bd-tbl__cell_people bd-pointer'
                                }
                              >
                                <span donorId={data.donorId}>
                                  {data.donorId}
                                </span>
                              </div>
                              <div
                                donorId={data.donorId}
                                // className='bd-tbl__cell_people bd-pointer'
                                className={
                                  data.status == 'Blocked'
                                    ? 'bd-tbl__cell_people bd-pointer width-20 bd-clr-red'
                                    : 'bd-tbl__cell_people bd-pointer width-20'
                                }
                              >
                                <span donorId={data.donorId}>{data.email}</span>
                              </div>
                              <div
                                donorId={data.donorId}
                                // className='bd-tbl__cell_people bd-pointer'
                                className={
                                  data.status == 'Blocked'
                                    ? 'bd-tbl__cell_people bd-pointer bd-clr-red'
                                    : 'bd-tbl__cell_people bd-pointer'
                                }
                              >
                                <span donorId={data.donorId}>
                                  {data.mobileNo}
                                </span>
                              </div>
                              <div
                                donorId={data.donorId}
                                // className="bd-tbl__cell_people bd-pointer"
                                className={
                                  data.status == 'Blocked'
                                    ? 'bd-tbl__cell_people bd-pointer bd-clr-red'
                                    : 'bd-tbl__cell_people bd-pointer'
                                }
                              >
                                <span donorId={data.donorId}>
                                  {data.lastDonationComponent
                                    ? data.lastDonationComponent
                                    : '-'}
                                </span>
                              </div>
                              <div
                                donorId={data.donorId}
                                // className="bd-tbl__cell_people bd-pointer"
                                className={
                                  data.status == 'Blocked'
                                    ? 'bd-tbl__cell_people bd-pointer bd-clr-red'
                                    : 'bd-tbl__cell_people bd-pointer'
                                }
                              >
                                <span donorId={data.donorId}>
                                  {data.lastDonationDate
                                    ? data.lastDonationDate
                                    : '-'}
                                </span>
                              </div>
                              <div
                                donorId={data.donorId}
                                className='bd-tbl__cell_people bd-pointer width-10'
                              >
                                <span
                                  donorId={data.donorId}
                                  className={
                                    data.status == 'Blocked'
                                      ? 'bd-tbl__cell_people_span bd-pointer padding-0 bd-clr-red'
                                      : 'bd-tbl__cell_people_span bd-pointer  padding-0 bd-clr-green'
                                  }
                                >
                                  {data.status}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                  )}
                </div>
              </div>
              <TablePagination
                component='div'
                count={tableData.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
          {showButtons ? (
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline' onClick={hideBtn}>
                <span>Cancel</span>
              </div>
              {blockBtns &&
                tableData.filter((t) => t.isSelected && t.status === 'Active')
                  .length === 1 && (
                  <div
                    className='bd-btn bd-btn--primary'
                    onClick={() => {
                      handleBlockUnblock('Block');
                    }}
                  >
                    <span>Block</span>
                  </div>
                )}
              {blockBtns &&
                tableData.filter((t) => t.isSelected && t.status === 'Blocked')
                  .length === 1 && (
                  <div
                    className='bd-btn bd-btn--share'
                    onClick={() => {
                      handleBlockUnblock('UnBlock');
                    }}
                  >
                    <span>UnBlock</span>
                  </div>
                )}

              {institutionType !== 'NGO' && (
                <div
                  className='bd-btn bd-btn--primary db-bg__green'
                  onClick={() => setRequetBlood({ show: true })}
                >
                  <span>Request</span>
                </div>
              )}
            </div>
          ) : (
            ''
          )}
        </section>
      </article>

      {requetBlood.show && (
        <RequestBloodContainer
          donorIds={table.filter((t) => t.isSelected).map((m) => m.donorId)}
          hideRequest={hideRequest}
        />
      )}
      {blockDonor.show && (
        <DetailPopupView
          profileData={donorProfileData}
          hidePopup={hidePopup}
          type={type}
          tabs={Tabs}
        />
      )}
      {showProfilePopup.show && (
        <ProfilePopup
          profileData={donorProfileData}
          onRequestClick={onRequestClick}
          showVerifyOTP={showVerifyOTP}
          otpInput={otpInput}
          setShowProfilePopup={setShowProfilePopup}
          onVerifyClick={onVerifyClick}
          setShowVerifyOTP={setShowVerifyOTP}
        />
      )}
      {showProfileDetailPopup.show && (
        <ProfileDetailPopup
          profileData={donorProfileData}
          tabs={tabsData}
          onTabChange={onTabChanges}
          hidePopup={hidePopup}
          showHideProfileDetailPopup={setShowProfileDetailPopup}
          displayButtons={false}
          activeTab={activeTab}
        />
      )}
      {showSuccess && (
        <SuccessPopup
          title={
            type == 'UnBlock'
              ? 'Donor has been UnBlock Successfully '
              : 'Donor has been Block Successfully '
          }
          onContinueClick={() => {
            hidePopup();
            setShowSuccess(false);
            dispatch({ type: BLOCK_AND_UNBLOCK, loading: false });
          }}
        />
      )}
      {showFail && (
        <FailPopup
          title={blockDonorObj.error || donarOTPData.error}
          onContinueClick={() => {
            setFail(false);
            dispatch({ type: BLOCK_AND_UNBLOCK, loading: false });
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default DonorView;
