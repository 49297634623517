import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { BLOOD_GROUP_LIST } from '../../utils/constants';
import { API_BASE_PATH, UPLOAD_FILE_URL } from '../../utils/config';
import EditPopup from '../../views/DashboardView/EditPopup';
import {
  triggerFetchInstituteList,
  getRequestVerificationData,
  EditVerifyUsers,
} from '../../redux/actions/dashboardAction';
import { FailPopup, SuccessPopup } from '../../components';
import { POST_EDIT_VERIFY_USER } from '../../redux/actions/actionTypes';

// import { EditVerifyUsers } from "../../redux/actions/dashboardAction";

export default function EditPopupContainer({
  data,
  setEditVerify,
  setVerify,
  setUserData,
}) {
  const dispatch = useDispatch();
  const { editVerifyUser } = useSelector((state) => state.dashboardReducer);
  const { instituteListObj } = useSelector((state) => state.dashboardReducer);
  const [dropdownValue, setDropdownValue] = useState({ bloodGroup: '' });
  const [requiredDate, setRequiredDate] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);
  const [userDetails, setUserDetails] = useState({
    address: {
      type: '',
      coordinates: [],
      description: '',
    },
  });
  const [instituteNameFocused, setInstituteNameFocused] = useState(false);
  function createDropdownList() {
    return BLOOD_GROUP_LIST.map((item, key) => {
      return (
        <option key={key} value={item === 'default' ? '' : item}>
          {BLOOD_GROUP_LIST[key]}
        </option>
      );
    });
  }
  function handleOnChange(e) {
    setDropdownValue({
      [e.target.id]: e.target.value,
    });
    setUserDetails({
      ...userDetails,
      bloodGroup: e.target.value,
    });
  }
  // function onDateChange(date) {
  //   setRequiredDate(date);
  //   setUserDetails({
  //     ...userDetails,
  //     requiredBy: new Date(date).toISOString(),
  //   });
  // }
  function onInputChange(e) {
    if (e.target.name === 'hospitalName') {
      dispatch(triggerFetchInstituteList(e.target.value));
    }
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  }
  function requestClick(obj, bloodGroup, selectedComponent) {
    dispatch(
      EditVerifyUsers(
        {
          id: obj.id,
          units: obj.units,
          bloodGroup: bloodGroup,
          component: selectedComponent,
        },
        (response) => {
          setShowSuccess(true);
          setVerifyPopUpData(response);
          dispatch(getRequestVerificationData({ requestType: 'verify' }));
        }
      )
    );
  }

  function setVerifyPopUpData(response) {
    setUserData(response.content.data);
  }

  function onInstituteSelect(data) {
    setInstituteNameFocused(false);
    let updatedRegObj = { ...userDetails.address };
    updatedRegObj['type'] = data?.location?.type;
    updatedRegObj['coordinates'] = data?.location?.coordinates;
    updatedRegObj['description'] = data?.location?.description;
    setUserDetails({
      ...userDetails,
      hospitalName: data?.institutionName,
      address: updatedRegObj,
    });
  }

  const addFile = async (e) => {
    let selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    let response = await axios.post(API_BASE_PATH + UPLOAD_FILE_URL, formData, {
      headers: {
        token: `${localStorage.getItem('TOKEN_KEY')}`,
      },
    });
    let file = response.data.content.savedFiles[0];
    let arr = [...userDetails.attachments];
    arr.push(file);
    setUserDetails({
      ...userDetails,
      attachments: arr,
    });
  };

  function getData() {
    let {
      details,
      bloodGroup,
      component,
      patientId,
      hospitalName,
      hospitalLocation,
      donationLocation,
      units,
      requiredBy,
      comments,
      attachments,
      _id,
    } = data;
    let obj = {
      patientName: [
        details.firstName ? details.firstName : '',
        details.lastName ? details.lastName : '',
      ].join(' '),
      patientId: patientId,
      mobileNo: details.mobileNumber ? details.mobileNumber : '',
      hospitalName: hospitalName,
      address: {
        type: hospitalLocation.type,
        coordinates: hospitalLocation.coordinates,
        description: hospitalLocation.description,
      },
      donationLocation: {
        type: donationLocation.type,
        coordinates: donationLocation.coordinates,
        description: donationLocation.description,
      },
      units: units,
      component: component,
      comment: comments,
      requiredBy: moment(requiredBy).format('DD MMM YYYY'),
      attachments: attachments,
      bloodGroup: bloodGroup,
      id: _id,
    };
    setDropdownValue({
      bloodGroup: bloodGroup,
    });
    setRequiredDate(requiredBy);
    setUserDetails(obj);
  }

  function deleteAttachment(e, index) {
    e.preventDefault();
    let files = [...userDetails.attachments];
    files.splice(index, 1);
    setUserDetails({
      ...userDetails,
      attachments: files,
    });
  }

  useEffect(() => {
    getData();
  }, [data]);

  useEffect(() => {
    if (editVerifyUser.error !== null) {
      setFail(true);
    } else {
      setFail(false);
    }
  }, [editVerifyUser]);

  return (
    <>
      <EditPopup
        userDetails={userDetails}
        setEditVerify={setEditVerify}
        // dropdownValue={dropdownValue}
        instituteListObj={instituteListObj}
        instituteNameFocused={instituteNameFocused}
        setInstituteNameFocused={setInstituteNameFocused}
        onInstituteSelect={onInstituteSelect}
        requestClick={requestClick}
        // onDateChange={onDateChange}
        onInputChange={onInputChange}
        // handleOnChange={handleOnChange}
        // createDropdownList={createDropdownList}
        deleteAttachment={deleteAttachment}
        addFile={addFile}
      />
      {showSuccess && (
        <SuccessPopup
          title={`Edit successfully!`}
          onContinueClick={() => {
            setShowSuccess(false);
            setEditVerify(false);
            setVerify(true);
            dispatch({ type: POST_EDIT_VERIFY_USER, loading: false });
          }}
        />
      )}
      {showFail && (
        <FailPopup
          title={editVerifyUser.error}
          onContinueClick={() => setFail(false)}
        />
      )}
    </>
  );
}
