import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postLabCriterias } from '../../redux/actions/settings';
import { CheckBox } from '..';

function Set_Lab_Creiterai({ setLabCriteria, SettingsJSON }) {
  const dispatch = useDispatch();
  const { postLabCriteria } = useSelector((state) => state.settingReducer);

  const Data = SettingsJSON.labCriteria;
  const [LabList, setLabList] = useState([]);

  let tempLabList = [];
  function onCheckboxChange(index, e) {
    const objUpdate = LabList.findIndex((obj) => obj.id === index);
    LabList[objUpdate].checked = e.target.checked;
    setLabList(tempLabList);
  }

  function onApproveCriteria() {
    SettingsJSON['labCriteria'] = LabList;
    dispatch(
      postLabCriterias({ settings: SettingsJSON }, () => {
        setLabCriteria(false);
      })
    );
  }

  useEffect(() => {
    if (Data) setLabList(Data);
  }, [Data, LabList, tempLabList, SettingsJSON]);

  return (
    // <div className='bd-modal-wrap bd-lab-criterai .bd-modal-right '>
    <div className='bd-modal-wrap .bd-modal-right  .bd-lab-criteria'>
      <div className='bd-popup .si-fade-in bd-popup_Lab_Criteria'>
        <div className='bd-popup__head style-case'>
          <span>Set Lab Creiteria</span>
          <span>Whole Blood</span>
        </div>
        <div>
          <div className='Lab-criteria-inside style-case'>
            <div></div>
            <div>Parameter</div>
            <div>Min</div>
            <div>Max</div>
            <div>Units</div>
          </div>
          <div
            style={{ borderBottom: '1px solid hsl(0deg 64% 44% / 20%)' }}
          ></div>
        </div>
        {LabList.map((cell, index) => {
          if (!cell.disable) {
            return (
              <div>
                <div className='Lab-criteria-inside'>
                  <div>
                    <CheckBox
                      checked={cell.checked}
                      onChange={onCheckboxChange.bind(this, index)}
                    />
                  </div>
                  <div>{cell.name}</div>
                  <div>{cell.min}</div>
                  <div>{cell.max}</div>
                  <div>{cell.unit}</div>
                </div>
                <div
                  style={{ borderBottom: '1px solid hsl(0deg 64% 44% / 20%)' }}
                ></div>
              </div>
            );
          }
        })}
        <div className='bd-form__row'>
          <label></label>
          <input placeholder='comment' type='text' name='organiser' />
        </div>

        <div className='bd-btn__wrap'>
          <div
            class='bd-btn bd-btn--outline'
            onClick={() => setLabCriteria(false)}
          >
            <span>Cancel </span>
          </div>

          <div className='bd-btn  bd-btn--primary' onClick={onApproveCriteria}>
            <span>Aprrove</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Set_Lab_Creiterai;
