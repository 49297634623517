import React from 'react';

export function CheckBox(props) {
  function onCheckBoxClick(e) {
    // trigger parent function
    props.onChange(e);
  }

  return (
    <span className='bd-checkbox'>
      <input
        {...props}
        key={props.keyindex}
        type='checkbox'
        checked={props.checked}
        onChange={onCheckBoxClick}
      />
      <b></b>
    </span>
  );
}
