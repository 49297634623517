import {
  GET_LAB_RESULTS,
  GET_LAB_RESULTS_SUCCESS,
  GET_LAB_RESULTS_FAILED,
  POST_LAB_RESULTS,
  POST_LAB_RESULTS_SUCCESS,
  POST_LAB_RESULTS_FAILED,
  CURR_SELECTED_LABS,
  GET_LAB_RESULT_SAMPLES,
  GET_LAB_RESULT_SAMPLES_SUCCESS,
  GET_LAB_RESULT_SAMPLES_FAILED,
  ACCEPT_LABS,
  ACCEPT_LABS_SUCCESS,
  ACCEPT_LABS_FAILED,
  GET_LABS_CRITERIA,
  GET_LABS_CRITERIA_SUCCESS,
  GET_LABS_CRITERIA_FAILED,
} from './../actions/actionTypes';
const initialState = {
  getLabResultObj: {
    loading: false,
    data: null,
    error: null,
  },
  postLabResultObj: {
    loading: false,
    data: null,
    error: null,
  },

  getLabResultSamples: {
    loading: false,
    data: null,
    error: null,
  },

  labStatusChange: {
    loading: false,
    data: null,
    error: null,
  },

  labCriteriaChange: {
    loading: false,
    data: [],
    error: null,
  },

  selectedLabResult: null,
};

const labResultReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_LAB_RESULTS:
      return {
        ...state,
        getLabResultObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case GET_LAB_RESULTS_SUCCESS:
      return {
        ...state,
        getLabResultObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_LAB_RESULTS_FAILED:
      return {
        ...state,
        getLabResultObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case POST_LAB_RESULTS:
      return {
        ...state,
        postLabResultObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case POST_LAB_RESULTS_SUCCESS:
      return {
        ...state,
        postLabResultObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case POST_LAB_RESULTS_FAILED:
      return {
        ...state,
        postLabResultObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case CURR_SELECTED_LABS:
      return {
        ...state,
        selectedLabResult: action.payload,
      };

    case GET_LAB_RESULT_SAMPLES:
      return {
        ...state,
        getLabResultSamples: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case GET_LAB_RESULT_SAMPLES_SUCCESS:
      return {
        ...state,
        getLabResultSamples: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_LAB_RESULT_SAMPLES_FAILED:
      return {
        ...state,
        getLabResultSamples: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case ACCEPT_LABS:
      return {
        ...state,
        labStatusChange: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case ACCEPT_LABS_SUCCESS:
      return {
        ...state,
        labStatusChange: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case ACCEPT_LABS_FAILED:
      return {
        ...state,
        labStatusChange: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_LABS_CRITERIA:
      return {
        ...state,
        labCriteriaChange: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case GET_LABS_CRITERIA_SUCCESS:
      return {
        ...state,
        labCriteriaChange: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_LABS_CRITERIA_FAILED:
      return {
        ...state,
        labCriteriaChange: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default labResultReducer;
