import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TabPane } from './TabPane';

export const Tabs = (props) => {
  const { children } = props;

  const [tabHeader, setTabHeader] = useState([]);

  const [childContent, setChildConent] = useState({});

  const [active, setActive] = useState('');
  const history = useHistory();
  useEffect(() => {
    const headers = [];
    const childCnt = {};
    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      const { name } = element.props;
      headers.push(name);
      childCnt[name] = element.props.children;
    });

    setTabHeader(headers);

    setActive(props.activeTab);

    setChildConent({ ...childCnt });
  }, [props, children]);

  const changeTab = (tab) => {
    setActive(tab);
    props.onChange(tab);
  };

  const viewAll = (type) => {
    if (type === 'Request') {
      history.push('/dashboard/request-viewall');
    }
    if (type === 'Donations') {
      history.push('/dashboard/donation-viewall');
    }
    if (type === 'Verify') {
      history.push('/dashboard/verify-request-viewall');
    }

    if (type === 'Camps') {
      history.push('/camp');
    }
  };

  return (
    <div className='bd-tab__main'>
      <div className='bd-tab bd-tab-new'>
        {tabHeader.map((item) => (
          <div
            onClick={() => changeTab(item)}
            key={item}
            className={item === active ? 'active' : ''}
          >
            <span>{item}</span>
          </div>
        ))}
      </div>

      {/* {active !== 'Inventory' &&
        active !== 'Camps' &&
        active !== 'BloodBank' &&
        active !== 'Hospital' &&
        active !== 'History' &&
        active !== 'Lab Result' &&
        active !== ' Medical' &&
        active !== 'Remarks' && (
          <div className='view-all' onClick={() => viewAll(active)}>
            View All
          </div>
        )} */}
      {active === 'Request' || active === 'Verify' || active === 'Donations' ? (
        <div className='view-all' onClick={() => viewAll(active)}>
          View All
        </div>
      ) : (
        ''
      )}

      <div className='bd-tab__content'>
        {Object.keys(childContent).map((key) => {
          if (key === active) {
            return <div>{childContent[key]}</div>;
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: function (props, propName, componentName) {
    const prop = props[propName];

    let error = null;
    React.Children.forEach(prop, function (child) {
      if (child.type !== TabPane) {
        error = new Error(
          '`' + componentName + '` children should be of type `TabPane`.'
        );
      }
    });
    return error;
  },
};
