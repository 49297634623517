import React, { useState, useEffect } from 'react';
import SettingsView from '../../views/SettingsView';
import SettingInventoryView from '../../views/SettingsInventoryView';
import SettingsJSON from '../../utils/jsons/setting.json';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatedSettings,
  getLabResultsSettings,
  // addHolidays,
  getSettings,
  submitSettings,
} from '../../redux/actions/settings';
function SettingsContainer() {
  // let SettingJSON = SettingsJSON;
  const [currSelected, setCurrSelected] = useState(0);
  const [showInventory, setShowInventory] = useState(false);
  // useEffect()

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLabResultsSettings());
    dispatch(getSettings());
  }, []);

  const { settingsObj, getLabsSettingsObj, postLabsSettingsObj } = useSelector(
    (state) => state.settingReducer
  );

  const onRadioClick = (data) => {
    dispatch(
      updatedSettings({ data: data, currSelected: currSelected, type: 'radio' })
    );
  };

  const onOptionClick = (data) => {
    dispatch(
      updatedSettings({
        data: data,
        currSelected: currSelected,
        type: 'options',
      })
    );
  };

  const onEditText = (data) => {
    dispatch(
      updatedSettings({ data: data, currSelected: currSelected, type: 'input' })
    );
  };

  const onChangeInstituteTime = (data) => {
    dispatch(
      updatedSettings({
        data: data,
        currSelected: currSelected,
        type: 'institueTiming',
      })
    );
  };

  const onFinalSubmitSettings = () => {
    dispatch(
      submitSettings({ settings: settingsObj.data }, (data, params) => {
        dispatch(getSettings());
        setShowInventory(true);
      })
    );
  };

  // const addInventory = (data) => {
  //   dispatch(addMonthlyInventory(data));
  // };
  return (
    <>
      {showInventory ? (
        <SettingInventoryView />
      ) : (
        <SettingsView
          currSelected={currSelected}
          setCurrSelected={setCurrSelected}
          onRadioClick={onRadioClick}
          onOptionClick={onOptionClick}
          SettingsJSON={settingsObj.data}
          onEditText={onEditText}
          getLabsSettingsObj={getLabsSettingsObj}
          postLabsSettingsObj={postLabsSettingsObj}
          onFinalSubmitSettings={onFinalSubmitSettings}
          onChangeInstituteTime={onChangeInstituteTime}
        />
      )}
    </>
  );
}

export default SettingsContainer;
