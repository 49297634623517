import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { GENDER_LIST, PERMISSIONS_ARR } from '../../utils/constants';
import DropDown from '../../components/RDropDown';
import { getpermission } from '../../utils';

function SuperAdminAddView({
  profileEdit,
  adminObj,
  onChangeText,
  onSubmit,
  onContinueClick,

  gender,
  setGenderSelected,

  userManagement,
  setUserManagement,

  instituteManagement,
  setInstitutionsManagement,

  subscription,
  setSubcription,

  billing,
  setBilling,

  configurations,
  setConfigration,

  admins,
  setAdmins,
}) {
  const {
    firstName,
    lastName,
    dateOfBirth,
    email,
    mobileNumber,
    address,
    state,
    district,
    city,
    zipCode,
    country,
  } = adminObj;

  function fetchGender({ value }) {
    setGenderSelected(value);
  }
  function fetchUserManagement({ value }) {
    setUserManagement(getpermission(value));
  }
  function featchInstituteManagement({ value }) {
    setInstitutionsManagement(getpermission(value));
  }
  function fetchSubscripton({ value }) {
    setSubcription(getpermission(value));
  }
  function fetchBilling({ value }) {
    setBilling(getpermission(value));
  }
  function fetchConfigration({ value }) {
    setConfigration(getpermission(value));
  }
  function fetchAdmins({ value }) {
    setAdmins(getpermission(value));
  }
  // let disableFields = profileEdit ? disabled : false;
  return (
    <article className='bd-article '>
      <section className='bd-dashboard bd-bg-color'>
        <div className='bd-dashboard__container'>
          <div className='bd-dashboard__lft bd-equalcolumn'>
            <div className='bd-dashboard__header'>
              <span className='bd-dashboard__main'>
                {profileEdit ? 'Edit Profile' : 'Add Admin'}
              </span>
            </div>

            <div className='bd-dbd__form'>
              <section className='bd-adddonor'>
                <div className='bd-dashboard__header'>
                  <span className=''>Personal Information</span>
                </div>
                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>First Name</label>
                    <input
                      type='text'
                      placeholder='First Name'
                      value={firstName}
                      name='firstName'
                      onChange={(e) => onChangeText(e)}
                      disabled={profileEdit ? true : false}
                    />
                  </div>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>Last Name</label>
                    <input
                      type='text'
                      placeholder='Last Name'
                      value={lastName}
                      name='lastName'
                      onChange={(e) => onChangeText(e)}
                      disabled={profileEdit ? true : false}
                    />
                  </div>
                </div>

                {profileEdit ? (
                  <div className='bd-adddonor-row bd-adddonor__1'>
                    <div className='bd-adddonor-div bd-form__row '>
                      <label>Gender</label>
                      <input
                        type='text'
                        placeholder='First Name'
                        value={gender}
                        name='gender'
                        onChange={(e) => onChangeText(e)}
                        disabled={profileEdit ? true : false}
                      />
                    </div>
                    <div className='bd-adddonor-div bd-form__row '>
                      <label>Age</label>
                      <input
                        type='text'
                        placeholder='Last Name'
                        value={dateOfBirth}
                        name='dateOfBirth'
                        onChange={(e) => onChangeText(e)}
                        disabled={profileEdit ? true : false}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='bd-adddonor-row bd-adddonor__1'>
                    <div className='bd-adddonor-div bd-form__row '>
                      <label>Gender</label>
                      <div className='bd-dorpdown width-100 '>
                        <div className='bd-dorpdown__selected pd-unset'>
                          <DropDown
                            options={GENDER_LIST}
                            fetchDropDownValue={fetchGender}
                            placeHolderText={'Gender'}
                          />
                          <i className='bludbig-arrow' />
                        </div>
                      </div>
                    </div>
                    <div className='bd-adddonor-div bd-form__row '>
                      <label>Date Of Birth</label>
                      <div className='width-100'>
                        <ReactDatePicker
                          selected={dateOfBirth}
                          onChange={(date) => onChangeText(date, 'dateOfBirth')}
                          dateFormat='dd/MM/yyyy'
                          showMonthDropdown=' true'
                          placeholderText='Date of Birth'
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>Email ID</label>
                    <input
                      type='text'
                      placeholder='Email ID'
                      name='email'
                      value={email}
                      onChange={(e) => onChangeText(e)}
                      disabled={profileEdit ? true : false}
                    />
                  </div>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>Contact No.</label>
                    <input
                      type='text'
                      placeholder='Contact Number'
                      name='mobileNumber'
                      value={mobileNumber}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>

                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>Address</label>
                    <input
                      type='text'
                      placeholder='Address'
                      name='address'
                      value={address}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>Postal Code/Zip COde</label>
                    <input
                      type='text'
                      placeholder='Zip Code'
                      name='zipCode'
                      value={zipCode}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>
                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>State/Province</label>
                    <input
                      type='text'
                      placeholder='State'
                      name='state'
                      value={state}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>City</label>
                    <input
                      type='text'
                      placeholder='City'
                      name='city'
                      value={city}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>
                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>District</label>
                    <input
                      type='text'
                      placeholder='district'
                      name='district'
                      value={district}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                  <div className='bd-adddonor-div bd-form__row '>
                    <label>Country</label>
                    <input
                      type='text'
                      placeholder='Country'
                      name='country'
                      value={country}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className='bd-dashboard__rgt bd-equalcolumn mt-30'>
            <div className='bd-dashboard__header'>
              <span className='bd-dashboard__main'>
                Permissions To Addministor
              </span>
            </div>
            <div className='bd-dbd__form'>
              <section className='bd-adddonor'>
                <div className='bd-adddonor-div bd-form__row '>
                  <label>User (Donor / Patient) Management</label>
                  <div className='bd-dorpdown width-100 '>
                    <div className='bd-dorpdown__selected pd-unset'>
                      <DropDown
                        options={PERMISSIONS_ARR}
                        fetchDropDownValue={fetchUserManagement}
                        placeHolderText={
                          getpermission(userManagement) || 'None'
                        }
                      />
                      <i className='bludbig-arrow' />
                    </div>
                  </div>
                </div>{' '}
                <div className='bd-adddonor-div bd-form__row '>
                  <label>
                    Institution (Hospital / Blood Bank / Facilitator) Management
                  </label>
                  <div className='bd-dorpdown width-100 '>
                    <div className='bd-dorpdown__selected pd-unset'>
                      <DropDown
                        options={PERMISSIONS_ARR}
                        fetchDropDownValue={featchInstituteManagement}
                        placeHolderText={
                          getpermission(instituteManagement) || 'None'
                        }
                      />
                      <i className='bludbig-arrow' />
                    </div>
                  </div>
                </div>{' '}
                <div className='bd-adddonor-div bd-form__row '>
                  <label>Admins</label>
                  <div className='bd-dorpdown width-100 '>
                    <div className='bd-dorpdown__selected pd-unset'>
                      <DropDown
                        options={PERMISSIONS_ARR}
                        fetchDropDownValue={fetchAdmins}
                        placeHolderText={getpermission(admins) || 'None'}
                      />
                      <i className='bludbig-arrow' />
                    </div>
                  </div>
                </div>
                <div className='bd-adddonor-div bd-form__row '>
                  <label>Billing</label>
                  <div className='bd-dorpdown width-100 '>
                    <div className='bd-dorpdown__selected pd-unset'>
                      <DropDown
                        options={PERMISSIONS_ARR}
                        fetchDropDownValue={fetchBilling}
                        placeHolderText={getpermission(billing) || 'None'}
                      />
                      <i className='bludbig-arrow' />
                    </div>
                  </div>
                </div>
                <div className='bd-adddonor-div bd-form__row '>
                  <label>Configration</label>
                  <div className='bd-dorpdown width-100 '>
                    <div className='bd-dorpdown__selected pd-unset'>
                      <DropDown
                        options={PERMISSIONS_ARR}
                        fetchDropDownValue={fetchConfigration}
                        placeHolderText={
                          getpermission(configurations) || 'None'
                        }
                      />
                      <i className='bludbig-arrow' />
                    </div>
                  </div>
                </div>
                <div className='bd-adddonor-div bd-form__row '>
                  <label>Subscriptions</label>
                  <div className='bd-dorpdown width-100 '>
                    <div className='bd-dorpdown__selected pd-unset'>
                      <DropDown
                        options={PERMISSIONS_ARR}
                        fetchDropDownValue={fetchSubscripton}
                        placeHolderText={getpermission(subscription) || 'None'}
                      />
                      <i className='bludbig-arrow' />
                    </div>
                  </div>
                </div>{' '}
              </section>
            </div>
          </div>

          <div className='bd-adddonor__save'>
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline' onClick={onContinueClick}>
                <span>{profileEdit ? 'Cancel' : 'Back'}</span>
              </div>
              <div
                className='bd-btn bd-btn--primary db-bg__green'
                onClick={onSubmit}
              >
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='ml-5'> {profileEdit ? 'Save' : 'Add'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
}

export default SuperAdminAddView;
