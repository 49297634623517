import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { donorStatusChange } from "../../redux/actions/dashboardAction";
import { RadioButton } from "..";

export default function DeclineDonatio ({
  hidePopup,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { donationObj, donarProfile, donorDecline } = useSelector(
    (state) => state.dashboardReducer
  );
  const [donorRequestId, setDonorRequestId] = useState(null);
  const [currentRadioSelect, setCurrentRadioSelect] = useState("");
  const [detail, setDetail] = useState({});

  function onInputChange(e) {
    let obj = { ...detail };
    obj[e.target.name] = e.target.value;
    setDetail(obj);
  };
  function onDeclineClick() {
    let obj = {
      donorId: donarProfile.data.donorId,
      requestId: donorRequestId.requestId,
      comment: detail.comment,
      reason: currentRadioSelect,
      status: "Decline",
      units: donorRequestId.issuedUnits,
    };
    dispatch(donorStatusChange(obj));
  };

  function onRadioChange(e) {
    setCurrentRadioSelect(e.target.value);
  };

  useEffect(() => {
    if (donarProfile.data) {
      let request = donationObj.data.filter(
        (a) => a.donorId === donarProfile.data.donorId
      )[0];
      setDonorRequestId(request);
    }
  }, []);
  useEffect(() => {
    if (donorDecline.data) {
      hidePopup();
    }
  }, [donorDecline]);
  return (
    <>
      <div className="bd-donation__form">
        <div className="bd-popup__head">
          <span>Decline Request</span>
        </div>
        <div className="bd-form">
          <div className="bd-form__row">
            <input
              type="text"
              placeholder="Doctor Name"
              name="doctorName"
              value={detail.doctorName}
              onChange={onInputChange}
            />
          </div>
          <div className="bd-form__row">
            <input
              type="text"
              placeholder="Comment"
              name="comment"
              value={detail.comment}
              onChange={onInputChange}
            />
          </div>

          <div className="bd-select-reason">
            <div className="bd-popup__head">
              <span>Select reason for declining</span>
            </div>
            <div className="bd-select-reason__row">
              <RadioButton
                label="Request fulfilled"
                name="radio1"
                value="Request fulfilled"
                onRadioChange={onRadioChange}
              />
            </div>
            <div className="bd-select-reason__row">
              <RadioButton
                label="Request Cancelled"
                name="radio1"
                value="Request Cancelled"
                onRadioChange={onRadioChange}
              />
            </div>
            <div className="bd-select-reason__row">
              <RadioButton
                label="Due to donor health"
                name="radio1"
                value="Due to donor health"
                onRadioChange={onRadioChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bd-btn__wrap">
        <div className="bd-btn bd-btn--outline" onClick={hidePopup}>
          <span>Cancel</span>
        </div>
        <div className="bd-btn bd-btn--primary" onClick={onDeclineClick}>
          <span>Decline</span>
        </div>
      </div>
    </>
  );
};

