export const checkBoxArrBloodBank = [
  {
    name: 'Collecting Platelets from Donors',
    checked: false,
  },
  {
    name: 'Processing Blood into components',
    checked: false,
  },
  {
    name: 'Testing inhouse with own Laboratory',
    checked: false,
  },
  {
    name: 'Organising Regular & Emergency Camps',
    checked: false,
  },
];
export const checkBoxArrHospital = [
  {
    name: 'Collecting Blood from Donors',
    checked: false,
  },
  {
    name: 'Collecting Platelets from Donors',
    checked: false,
  },
  {
    name: 'Processing Blood into components',
    checked: false,
  },
  {
    name: 'Storing Blood & Components for long',
    checked: false,
  },
];

export const checkBoxArrUndertakingBloodBank = [
  {
    name: 'I understand that this App can only connect with voluntary donors and can’t guarantee their fitness.',
    checked: false,
  },
  {
    name: 'I hereby declare that all data have been verified and found correct.',
    checked: false,
  },
  {
    name: ' I also undertake to keep this data up to date.',
    checked: false,
  },
];

export const checkBoxArrUndertakingHospital = [
  {
    name: 'I hereby declare all data have been verified and found correct to this point in time.',
    checked: false,
  },
  {
    name: 'I also undertake to keep this data up to date.',
    checked: false,
  },
];
export const checkBoxArrUndertakingNGO = [
  {
    name: 'I undertand that this App provides a platform to manage donors and organise camps to fill the demand supply gap of safe blood.',
    checked: false,
  },
  {
    name: ' I hereby declare that this platform will not be used to facilitate Professional Donors or in any way contravening the law',
    checked: false,
  },
  {
    name: 'I hereby declare that all data have been verified and found correct.',
    checked: false,
  },
  {
    name: 'I also undertake to keep this data up to date.',
    checked: false,
  },
];

export const BLOOD_GROUP_LIST = [
  'A+',
  'A-',
  'B+',
  'B-',
  'AB+',
  'AB-',
  'O+',
  'O-',
  'HH',
];

export const INSTI_TYPE = [
  {
    label: 'institution',
    value: 'institution',
  },
  { label: 'Individual', value: 'Individual' },
];

export const INVENTORY_TABLE_HEADERS = ['Components', ...BLOOD_GROUP_LIST];

export const BLOOD_STATIC_COMPONET_LIST = [
  { label: 'Cryoprecipitate', value: 'Cryoprecipitate' },
  {
    label: 'Granulocyte Concentrates – Irradiated',
    value: 'Granulocyte Concentrates – Irradiated',
  },
  { label: 'Haematopoetic Stem Cells', value: 'Haematopoetic Stem Cells' },
  {
    label: 'Plasma – Apheresis Single Donor',
    value: 'Plasma – Apheresis Single Donor',
  },
  { label: 'Plasma – Convalescent', value: 'Plasma – Convalescent' },
  { label: 'Plasma – Cryo Poor', value: 'Plasma – Cryo Poor' },
  { label: 'Plasma – Fresh Frozen', value: 'Plasma – Fresh Frozen' },
  {
    label: 'Platelets – Apheresis Single Donor',
    value: 'Platelets – Apheresis Single Donor',
  },
  { label: 'Platelets – Irradiated', value: 'Platelets – Irradiated' },
  { label: 'Platelets – Leukoreduced', value: 'Platelets – Leukoreduced' },
  { label: 'Platelets – Random Donor', value: 'Platelets – Random Donor' },
  {
    label: 'Red Cells – Apheresis Single Donor',
    value: 'Red Cells – Apheresis Single Donor',
  },
  { label: 'Red Cells – Leukoreduced', value: 'Red Cells – Leukoreduced' },
  { label: 'Red Cells – Packed', value: 'Red Cells – Packed' },
  {
    label: 'Red Cells – Packed Irradiated',
    value: 'Red Cells – Packed Irradiated',
  },
  { label: 'Whole Blood', value: 'Whole Blood' },
];

export const BLOOD_GROUP_OPTIONS_1 = [
  {
    label: 'A+',
    value: 'A+',
  },
  {
    label: 'A-',
    value: 'A-',
  },
  {
    label: 'B+',
    value: 'B+',
  },
  {
    label: 'B-',
    value: 'B-',
  },
  {
    label: 'AB+',
    value: 'AB+',
  },
  {
    label: 'AB-',
    value: 'AB-',
  },
  {
    label: 'O+',
    value: 'O+',
  },
  {
    label: 'O-',
    value: 'O-',
  },
  {
    label: 'HH',
    value: 'HH',
  },
  {
    label: 'Bulk',
    value: 'Bulk',
  },
];

export const BLOOD_GROUP_OPTIONS = [
  {
    label: 'All/Any',
    value: 'All/Any',
  },
  ...BLOOD_GROUP_OPTIONS_1,
];

export const INVENTORY_SUB_CELL_LIST = [
  {
    label: 'Issue',
    id: 'issue',
    superSubList: [
      {
        label: 'To Patient',
        id: 'patient',
        superSubList: [
          {
            label: 'Registered User',
            id: 'registered',
            showInventory: true,
          },
          {
            label: 'Unregistered User',
            id: 'unregistered',
            showInventory: true,
          },
        ],
      },
      {
        label: 'To Process',
        id: 'process',
      },
    ],
  },
  {
    label: 'Transfer',
    id: 'transfer',
  },
  {
    label: 'Dispose',
    id: 'dispose',
  },
];

export const PROFILE_TABS = [
  {
    title: 'History',
    tableData: {
      tableType: 'table-type-2',
      column: [
        { title: '#', dataIndex: 'hash', key: 'hash' },
        { title: 'Date', dataIndex: 'date', key: 'date' },
        { title: 'Component', dataIndex: 'component', key: 'component' },
        { title: 'Institute', dataIndex: 'institute', key: 'institute' },
        { title: 'Unit', dataIndex: 'unit', key: 'unit' },
        { title: 'Bag ID / Unit no', dataIndex: 'unitId', key: 'unitId' },
        { title: 'Comments', dataIndex: 'comments', key: 'comments' },
      ],
      data: [],
    },
  },
  {
    title: 'Lab Result',
    tableData: {
      alignCellCenter: ['text-center'],
      tableType: 'table-type-2',
      column: [
        { title: '#', dataIndex: 'hash', key: 'hash' },
        { title: 'Date', dataIndex: 'date', key: 'date' },
        {
          title: (
            <div className='lab_table_header'>
              <b>BP</b>
              <div className='component-lab'>130/80 mmHg</div>
            </div>
          ),
          dataIndex: 'bp',
          key: 'bp',
        },
        {
          title: (
            <div className='lab_table_header'>
              <b>Weight</b>
              <div className='component-lab'>kg</div>
            </div>
          ),
          dataIndex: 'weight',
          key: 'weight',
        },
        {
          title: (
            <div className='lab_table_header'>
              <b>Hb</b>
              <div className='component-lab'>12/-17 mg/dl</div>
            </div>
          ),
          dataIndex: 'hb',
          key: 'hb',
        },
        {
          title: (
            <div className='lab_table_header'>
              <b>RBC</b>
              <div className='component-lab'>
                4.5-5.5<sup>*</sup>10<sup>12</sup>
              </div>
            </div>
          ),
          dataIndex: 'rbc',
          key: 'rbc',
        },
        {
          title: (
            <div className='lab_table_header'>
              <b>WBC</b>
              <div className='component-lab'>
                4.5<sup>*</sup>10<sup>9</sup>
              </div>
            </div>
          ),
          dataIndex: 'wbc',
          key: 'wbc',
        },
        {
          title: (
            <div className='lab_table_header'>
              <b>Platelet</b>
              <div className='component-lab'>
                150-400<sup>*</sup>10<sup>9</sup>
              </div>
            </div>
          ),
          dataIndex: 'platelet',
          key: 'platelet',
        },
        {
          title: (
            <div className='lab_table_header'>
              <b>ESR</b>
              <div className='component-lab'>0-20 mm/Hr</div>
            </div>
          ),
          dataIndex: 'esr',
          key: 'esr',
        },
        {
          title: (
            <div className='lab_table_header'>
              <b>TLC</b>
              <div className='component-lab'>
                4-11 k/mm<sup>3</sup>
              </div>
            </div>
          ),
          dataIndex: 'tlc',
          key: 'tlc',
        },
        { title: 'Result', dataIndex: 'result', key: 'result' },
      ],
      data: [],
    },
  },
  {
    title: 'Medical',
    tableData: {
      tableType: 'table-type-2',
      column: [
        { title: '#', dataIndex: 'hash', key: 'hash' },
        {
          title: 'Health Issue',
          dataIndex: 'health_issue',
          key: 'health_issue',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        },
        { title: 'Condition', dataIndex: 'condition', key: 'condition' },
        { title: 'Medication', dataIndex: 'medication', key: 'medication' },
        { title: 'Comments', dataIndex: 'comments', key: 'comments' },
      ],
      data: [],
    },
  },
  {
    title: 'Remarks',
    tableData: {
      tableType: 'table-type-2',
      column: [
        { title: '#', dataIndex: 'hash', key: 'hash' },
        { title: 'Date', dataIndex: 'date', key: 'date' },
        { title: 'Doctor', dataIndex: 'doctor', key: 'doctor' },
        { title: 'Event', dataIndex: 'event', key: 'event' },
        { title: 'Comments', dataIndex: 'comments', key: 'comments' },
      ],
      data: [],
    },
  },
];

// INVENTORY TAB COLUMNS
export const INVENTORY_TAB_COLUMNS = [
  { title: 'Components', dataIndex: 'components', key: 'components' },
  { title: 'A+', dataIndex: 'a+', key: 'a+' },
  { title: 'A-', dataIndex: 'a-', key: 'a-' },
  { title: 'B+', dataIndex: 'b+', key: 'b+' },
  { title: 'B-', dataIndex: 'b-', key: 'b-' },
  { title: 'AB+', dataIndex: 'ab+', key: 'ab+' },
  { title: 'AB-', dataIndex: 'ab-', key: 'ab-' },
  { title: 'O+', dataIndex: 'o+', key: 'o+' },
  { title: 'O-', dataIndex: 'o-', key: 'o-' },
  { title: 'HH', dataIndex: 'hh', key: 'hh' },
];

// REQUEST VIEW ALL TAB COLUMNS
export const REQUEST_VIEW_ALL_TAB_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Units', dataIndex: 'units', key: 'units' },
  { title: 'Component', dataIndex: 'component', key: 'component' },
  { title: 'Condition', dataIndex: 'condition', key: 'condition' },
  { title: 'Hospital', dataIndex: 'hospital', key: 'hospital' },
  { title: 'Required by', dataIndex: 'requiredBy', key: 'requiredBy' },
  { title: '', dataIndex: 'share', key: 'share' },
  { title: '', dataIndex: 'accept', key: 'accept' },
];

// DONATION VIEW ALL TAB COLUMNS
export const DONATION_VIEW_ALL_TAB_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Donor ID', dataIndex: 'donorId', key: 'donorId' },
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Time', dataIndex: 'time', key: 'time' },
  { title: 'Checklist', dataIndex: 'checklist', key: 'checklist' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
  { title: 'Mobile no', dataIndex: 'mobileNo', key: 'mobileNo' },
  { title: '', dataIndex: 'requestOTP', key: 'requestOTP' },
];

export const NEARBY_DONORS_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Donor ID', dataIndex: 'donorId', key: 'donorId' },
  { title: 'Gender', dataIndex: 'gender', key: 'gender' },
  { title: 'Available Since', dataIndex: 'date', key: 'date' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
];
export const AVAILABLE_DONORS_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Donor ID', dataIndex: 'donorId', key: 'donorId' },
  { title: 'Gender', dataIndex: 'gender', key: 'gender' },
  { title: 'Available Since', dataIndex: 'date', key: 'date' },
  { title: 'Contact No.', dataIndex: 'contactNo', key: 'contactNo' },
  { title: 'Landmark', dataIndex: 'LandMark', key: 'LandMark' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
];
export const USERS_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'User ID', dataIndex: 'userId', key: 'userId' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Eff. from', dataIndex: 'date', key: 'date' },
  { title: 'Email', dataIndex: 'email', key: 'email' },
  { title: 'Address', dataIndex: 'address', key: 'address' },
  { title: 'Contact No.', dataIndex: 'contactNo', key: 'contactNo' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
];
export const PRICING_COLUMNS = [
  { title: 'Component', dataIndex: 'Component', key: 'Component' },
  { title: 'Best Price', dataIndex: 'BestPrice', key: 'BasePrice' },
  { title: 'North East', dataIndex: 'NorthEast', key: 'NorthEast' },
  { title: 'UT', dataIndex: 'UT', key: 'UT' },
  { title: 'Platelets', dataIndex: 'Platelets', key: 'Platelets' },
  { title: 'Whole Blood', dataIndex: 'WholeBlood', key: 'WholeBlood' },
  { title: 'TLC', dataIndex: 'TLC', key: 'TLC' },
];
export const ADMIN_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Email', dataIndex: 'email', key: 'email' },
  { title: 'Contact No.', dataIndex: 'contactNo', key: 'contactNo' },
  { title: 'Address', dataIndex: 'address', key: 'address' },
  { title: 'Eff. from', dataIndex: 'date', key: 'date' },
  { title: 'User ID', dataIndex: 'userId', key: 'userId' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
];

export const SUBACRIPTION_PLANS_COLUMNS = [
  { title: 'Plan Name', dataIndex: 'planName', key: 'planName' },
  { title: 'Price', dataIndex: 'price', key: 'price' },
  { title: 'Description', dataIndex: 'description', key: 'description' },
  { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
  { title: 'Bonus Quantity', dataIndex: 'bonusQuantity', key: 'bonusQuantity' },
  { title: 'Bonus Pteriod', dataIndex: 'bonusPeriod', key: 'bonusPeriod' },
  { title: 'Condition', dataIndex: 'condition', key: 'condition' },
  { title: 'From Date', dataIndex: 'fromDate', key: 'fromDate' },
  { title: 'To Date', dataIndex: 'toDate', key: 'toDate' },
  // { title: 'Attachments', dataIndex: 'toDate', key: 'toDate' },
];

export const GENDER_LIST = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Other', value: 'Other' },
];
export const PERMISSIONS_ARR = [
  { label: 'Full Permission', value: 'Full Permission' },
  { label: 'View Only', value: 'View Only' },
  {
    label: 'Create , Update but Not Delete',
    value: 'Create , Update but Not Delete',
  },
  { label: 'None', value: 'None' },
];

//inventory sudarshan

export const COMMON_COLUMNS = [
  {
    title: '',
    dataIndex: 'checkBox',
    key: 'checkBox',
  },
  {
    title: 'Bag ID/Unit no.',
    dataIndex: 'bagId',
    key: 'bagId',
  },
  {
    title: 'Donor ID',
    dataIndex: 'donorId',
    key: 'donorId',
  },
  {
    title: 'Collected on',
    dataIndex: 'collectionDate',
    key: 'collectionDate',
  },
  {
    title: 'Expiry',
    dataIndex: 'expiryDate',
    key: 'expiryDate',
  },
];

export const INVENTORY_TRANSFER_COLUMNS = [
  ...COMMON_COLUMNS,
  {
    title: 'Location',
    dataIndex: 'storedIn',
    key: 'storedIn',
  },
  {
    title: 'Transfer',
    dataIndex: 'transfer',
    key: 'transfer',
  },
];
export const INVENTORY_AGEING_COLUMNS = [
  ...COMMON_COLUMNS,
  {
    title: 'Location',
    dataIndex: 'storedIn',
    key: 'storedIn',
  },
];

export const INVENTORY_RECEIPT_COLUMNS = [
  ...COMMON_COLUMNS,
  {
    title: 'Result',
    dataIndex: 'result',
    key: 'result',
  },
  {
    title: 'Recieved on',
    dataIndex: 'receivedOn',
    key: 'receivedOn',
  },
  {
    title: 'Received from',
    dataIndex: 'receivedFrom',
    key: 'receivedFrom',
  },
  // {
  //   title: 'Comes From',
  //   dataIndex: 'DonationType',
  //   key: 'DonationType',
  // },
];

export const INVENTORY_ISSUED_COLUMNS = [
  {
    title: 'Bag ID/Unit no.',
    dataIndex: 'bagId',
    key: 'bagId',
  },

  {
    title: 'Patient Id',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Collected on',
    dataIndex: 'collectionDate',
    key: 'collectionDate',
  },

  {
    title: 'Expiry',
    dataIndex: 'expiryDate',
    key: 'expiryDate',
  },
  {
    title: 'Result',
    dataIndex: 'Result',
    key: 'Result',
  },
  {
    title: 'Delivered on ',
    dataIndex: 'issuedDate',
    key: 'issuedDate',
  },
  {
    title: 'Delivered To',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
  },
];
export const INVENTORY_RECEIVED_COLUMNS = [
  {
    title: 'Bag ID/Unit no.',
    dataIndex: 'bagId',
    key: 'bagId',
  },
  {
    title: 'Donor Id',
    dataIndex: 'donorId',
    key: 'donorId',
  },
  {
    title: 'Collected on',
    dataIndex: 'collectionDate',
    key: 'collectionDate',
  },

  {
    title: 'Expiry',
    dataIndex: 'expiryDate',
    key: 'expiryDate',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
  // {
  //   title: 'Result',
  //   dataIndex: 'Result',
  //   key: 'Result',
  // },
  {
    title: 'Issued On',
    dataIndex: 'issuedDate',
    key: 'issuedDate',
  },
  {
    title: 'Issued To',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
  },
  {
    title: 'Remark',
    dataIndex: 'status',
    key: 'status',
  },
];
export const IN_PROCESS_COLUMNS = [
  { title: '', dataIndex: 'checkbox', key: 'checkbox' },
  { title: 'Bag ID/Unit No.', dataIndex: 'bagid', key: 'bagid' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Donor ID', dataIndex: 'donorId', key: 'donorId' },
  { title: 'Collected On', dataIndex: 'collectionDate', key: 'collectionDate' },
  { title: 'Expiry', dataIndex: 'expirtDate', key: 'expirtDate' },
  { title: 'Volume', dataIndex: 'volume', key: 'volume' },
];
export const UPDATE_STOCK_COLUMNS = [
  { title: 'Bag ID/Unit No.', dataIndex: 'bagId', key: 'bagId' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  {
    title: 'Blood Component',
    dataIndex: 'component',
    key: 'component',
  },
  { title: 'Process On', dataIndex: 'processOn', key: 'processOn' },
  { title: 'Expiry', dataIndex: 'expiryDate', key: 'expiryDate' },
  { title: 'Volume', dataIndex: 'volume', key: 'volume' },
  { title: 'Location', dataIndex: 'storedIn', key: 'storedIn' },
];
export const INVENTORY_BLOOD_GROUP_TABS = [
  'Whole Blood',
  'Plasma',
  'Platelets',
  'Red cells',
  'AHF',
];

export const PEOPLE_DONORS_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Blood Group',
    dataIndex: 'bloodGroup',
    key: 'bloodGroup',
  },
  {
    title: 'Donor ID',
    dataIndex: 'donorId',
    key: 'donorId',
  },
  {
    title: 'Email ID',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Mobile No',
    dataIndex: 'mobileNo',
    key: 'mobileNo',
  },

  {
    title: 'Last Donation',
    dataIndex: 'lastDonationDate',
    key: 'lastDonationDate',
  },
  {
    title: 'Last donated on',
    dataIndex: 'lastDonationComponent',
    key: 'lastDonationComponent',
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

// PEOPLE  -->  RECEPIENTS TAB COLUMNS / Requested for tab
export const PEOPLE_RECEPIENT_COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Blood Group',
    dataIndex: 'bloodGroup',
    key: 'bloodGroup',
  },
  {
    title: 'Bag ID',
    dataIndex: 'bagId',
    key: 'bagId',
  },
  {
    title: "Patient's Name",
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Component',
    dataIndex: 'components',
    key: 'components',
  },
  {
    title: 'Expiry Date',
    dataIndex: 'expiryDate',
    key: 'expiryDate',
  },

  {
    title: 'Patient Id',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Purpose',
    dataIndex: 'purpose',
    key: 'purpose',
  },
  {
    title: 'Hospital/Blood Bank',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
  },
];

export const VERIFY_PENDING_VIEW_ALL_TAB_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Units', dataIndex: 'units', key: 'units' },
  { title: 'Component', dataIndex: 'component', key: 'component' },
  { title: 'Condition', dataIndex: 'condition', key: 'condition' },
  { title: 'Hospital', dataIndex: 'hospital', key: 'hospital' },
  { title: 'Required By', dataIndex: 'requiredBy', key: 'requiredBy' },
  { title: '', dataIndex: 'view', key: 'view' },
];

export const VERIFY_VERIFIED_VIEW_ALL_TAB_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Units', dataIndex: 'units', key: 'units' },
  { title: 'Component', dataIndex: 'component', key: 'component' },
  { title: 'Condition', dataIndex: 'condition', key: 'condition' },
  { title: 'Required By', dataIndex: 'requiredBy', key: 'requiredBy' },
];

export const PATIENT_VIEW_TAB_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Blood Group', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'Age', dataIndex: 'age', key: 'age' },
  { title: 'Mobile No', dataIndex: 'mobileNo', key: 'mobileNo' },
  { title: 'Patient ID', dataIndex: 'patientId', key: 'patientId' },
  { title: 'Purpose', dataIndex: 'purpose', key: 'purpose' },
  { title: 'Doctor', dataIndex: 'doctor', key: 'doctor' },
];

export const DD_VALUES = [
  { label: 6, value: 6 },
  { label: 12, value: 12 },
  { label: 18, value: 18 },
  { label: 24, value: 24 },
  { label: 30, value: 30 },
  { label: 36, value: 36 },
  { label: 42, value: 42 },
  { label: 48, value: 48 },
  { label: 54, Value: 54 },
];

export const questions = [
  { label: 'Willing to donate for free' },
  { label: 'Has donated blood / platelets before at this Blood Bank' },
  {
    label: 'Has donated blood / platelets before, but elsewhere',
  },
  {
    label:
      'Have explained about the protocols & precautions for donation (for new donor)',
  },
  { label: 'Have verified the Identity and contact information' },
];

export const questionsSet2 = [
  { label: 'Donor is willing to participate in Blood Donation Camps' },
  { label: 'Donor is able to read, write and communicate' },
  { label: 'Donor can send and receive messages on mobile phone' },
];

export const addPatientQuestions = [
  { label: 'This patient requires Blood' },
  { label: 'This patient requires Blood Components' },
  { label: 'Has donated blood / platelets before' },
  {
    label:
      'This patient is willing to participate in Blood Donation Camps in future',
  },
  { label: 'Have verified the patient’s Identity and Contact information' },
  { label: 'This patient is able to read, write and communicate' },
  { label: 'This patient can send and receive messages on mobile phone' },
];
export const bloodBankList = [
  {
    name: 'Dashboard',
    path: '/',
    iconClassName: 'bluddashboard',
  },
  {
    name: 'Lab',
    path: '/labresult',
    iconClassName: 'bludlabs-icon',
    subMenu: [
      {
        name: 'Results',
        path: '/labresult',
        iconClassName: 'bludlab-results',
      },
      {
        name: 'Samples',
        path: '/labresult-samples',
        iconClassName: 'bludlab-samples',
      },
    ],
  },
  {
    name: 'People',
    path: '/people',
    iconClassName: 'bludpeople-icon',
  },
  {
    name: 'Inventory',
    path: '/inventory',
    iconClassName: 'bludblood-bag',
    subMenu: [
      {
        name: 'In Process',
        path: '/inventory-in-process',
        iconClassName: 'bludsetting ',
      },
      {
        name: 'Transfer',
        path: '/inventory-transfer',
        iconClassName: 'bludtransfer',
      },
      {
        name: 'Receipt',
        path: '/inventory-receipt',
        iconClassName: 'icon-bludReceipt',
      },
      {
        name: 'Ageing',
        path: '/inventory-ageing-report',
        iconClassName: 'bludageing',
      },
      {
        name: 'Issued',
        path: '/inventory-issued',
        iconClassName: 'bludpeople-icon',
      },
    ],
  },
  // {
  //   name: 'Camps',
  //   path: '/camps',
  //   iconClassName: 'bludcamps',
  // },
  {
    name: ' Camp',
    path: '/camp',
    iconClassName: 'bludcamps',
  },
  {
    name: 'Chat',
    path: '/chat',
    iconClassName: 'bludchat',
  },
  {
    name: 'Settings',
    path: '/settings',
    iconClassName: 'bludsetting',
  },
];

export const hospitalMenuList = [
  {
    name: 'Dashboard',
    path: '/',
    iconClassName: 'bluddashboard',
  },
  {
    name: 'Patients',
    path: '/patient',
    iconClassName: 'bludcamps',
  },
  {
    name: 'Settings',
    path: '/settings',
    iconClassName: 'bludsetting',
  },
];
export const NgoMenuList = [
  {
    name: 'Dashboard',
    path: '/',
    iconClassName: 'bluddashboard',
  },
  {
    name: 'People',
    path: '/people',
    iconClassName: 'bludpeople-icon',
  },

  {
    name: ' Camp',
    path: '/camp',
    iconClassName: 'bludcamps',
  },
  // {
  //   name: 'Settings',
  //   path: '/settings',
  //   iconClassName: 'bludsetting',
  // },
];

export const SETTING_HEADER = {
  GeneralEquipments: 'General Equipments',
  LaboratoryEquipments: 'Laboratory Equipments',
  ComponentPreparation: 'Component Preparation',
  Infrastructure: 'Infrastructure',
};

export const BLOOD_COMPONENTS = {
  RedCells: 'Red Cells',
  WholeBlood: 'Whole Blood',
};

export const titleCase = (str) => {
  if (str) {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
};

export const NGO_ONGOING_TABLE = [
  { title: 'Date', dataIndex: 'Date', key: 'Date' },
  { title: 'Camp Name', dataIndex: 'CampName', key: 'CampName' },
  { title: 'Venue', dataIndex: 'Venue', key: 'Venue' },
  { title: 'Organiser', dataIndex: 'Organiser', key: 'Organiser' },
  { title: 'Mobile no.', dataIndex: 'MobileNo', key: 'MobileNo' },
  { title: '', dataIndex: 'Button', key: 'Button' },
];
export const CAMP_ACCEPTED_TABLE = [
  { title: 'Date', dataIndex: 'Date', key: 'Date' },
  { title: 'Camp Name', dataIndex: 'CampName', key: 'CampName' },
  { title: 'Venue', dataIndex: 'Venue', key: 'Venue' },
  { title: 'Organiser', dataIndex: 'Organiser', key: 'Organiser' },
  { title: 'Mobile no.', dataIndex: 'MobileNo', key: 'MobileNo' },
  { title: 'Donors', dataIndex: 'DonorsCount', key: 'DonorsCount' },
  { title: '', dataIndex: 'Button', key: 'Button' },
];
export const CAMP_PENDING_TABLE = [
  { title: 'Date', dataIndex: 'Date', key: 'Date' },
  { title: 'Camp Name', dataIndex: 'CampName', key: 'CampName' },
  { title: 'Venue', dataIndex: 'Venue', key: 'Venue' },
  { title: 'Organiser', dataIndex: 'Organiser', key: 'Organiser' },
  { title: 'Mobile no.', dataIndex: 'MobileNo', key: 'MobileNo' },
  { title: '', dataIndex: 'Button', key: 'Button' },
];
export const CAMP_DONORS_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'BloodGroup', dataIndex: 'bloodGroup', key: 'bloodGroup' },
  { title: 'DonorID', dataIndex: 'donorId', key: 'donorId' },
  { title: 'Mobile No.', dataIndex: 'mobileNo', key: 'mobileNo' },
  { title: 'Email Id', dataIndex: 'email', key: 'email' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
  { title: '', dataIndex: 'Button', key: 'Button' },
];
export const ab_CAMPS_TABLE = [
  { title: 'Date', dataIndex: 'from', key: 'from' },
  { title: 'Camp Name', dataIndex: 'campName', key: 'campName' },
  { title: 'Location', dataIndex: 'location', key: 'location' },
  { title: 'Organiser', dataIndex: 'sponser', key: 'sponser' },
  { title: 'Mobile no.', dataIndex: 'mobileNumber', key: 'mobileNumber' },
  { title: 'Donors', dataIndex: 'donors', key: 'donors' },
];
export const TRANSACTION_SUB_TABLE = [
  { title: 'Regitration ID', dataIndex: 'regId', key: 'regId' },
  { title: 'Institution', dataIndex: 'institution', key: 'institution' },
  { title: 'Type', dataIndex: 'type', key: 'type' },
  { title: 'Email ID', dataIndex: 'emailId', key: 'emailId' },
  { title: 'Phone No.', dataIndex: 'mobileNo', key: 'mobileNo' },
  { title: 'Subscription', dataIndex: 'subscription', key: 'subscription' },
  {
    title: 'Last subscribed on',
    dataIndex: 'lastSubcribe',
    key: 'lastSubcribe',
  },
  { title: 'Transaction Id', dataIndex: 'transactionId', key: 'transactionId' },
];
export const TRANSACTION_BLUDUNIT_TABLE = [
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Regitration ID', dataIndex: 'regId', key: 'regId' },
  { title: 'Institution', dataIndex: 'institution', key: 'institution' },
  { title: 'Type', dataIndex: 'type', key: 'type' },
  { title: 'Patient ID', dataIndex: 'patientId', key: 'patientId' },
  { title: 'User ID', dataIndex: 'userId', key: 'userId' },
  { title: 'Amount(Rs.)', dataIndex: 'amount', key: 'amount' },
  { title: 'Transaction ID', dataIndex: 'transactionId', key: 'transactionId' },
];

export const REGISTER_TABEL = [
  {
    title: 'Institution Name',
    dataIndex: 'institutionName',
    key: 'institutionName',
  },
  { title: 'Type', dataIndex: 'institutionType', key: 'institutionType' },
  {
    title: 'Registration NO.',
    dataIndex: 'registrationNumber',
    key: 'registrationNumber',
  },
  { title: 'Email ID', dataIndex: 'contactEmail', key: 'contactEmail' },
  { title: 'Phone NO.', dataIndex: 'contactNumber', key: 'contactNumber' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
  { title: 'State', dataIndex: 'state', key: 'state' },
  { title: 'ID', dataIndex: '_id', key: '_id' },
];

export const PENDING_TABEL = [
  { title: 'Appled on', dataIndex: 'date', key: 'date' },
  {
    title: 'Institution Name',
    dataIndex: 'institutionName',
    key: 'institutionName',
  },
  { title: 'Type', dataIndex: 'institutionType', key: 'institutionType' },
  {
    title: 'Registration NO.',
    dataIndex: 'registrationNumber',
    key: 'registrationNumber',
  },
  { title: 'Email ID', dataIndex: 'contactEmail', key: 'contactEmail' },
  { title: 'Phone NO.', dataIndex: 'contactNumber', key: 'contactNumber' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
  { title: 'ID', dataIndex: '_id', key: '_id' },
];

export const SuperAdminMenuList = [
  {
    name: 'Dashboard',
    path: '/Super-Admin/Dashboard',
    iconClassName: 'bluddashboard',
  },
  {
    name: 'Instituions',
    path: '/Super-Admin/Instituions',
    iconClassName: 'bd-admin',
    key: 'instituteManagement',
  },
  {
    name: 'Administrators',
    path: '/Super-Admin/Administrators',
    iconClassName: 'blud-user',
    key: 'admins',
  },
  {
    name: 'Users',
    path: '/Super-Admin/Users',
    iconClassName: 'bludpeople-icon',
    key: 'userManagement',
  },
  {
    name: 'Transaction',
    path: '/Super-Admin/Transaction',
    iconClassName: 'bd-trans',
  },
  {
    name: 'Pricing',
    path: '/Super-Admin/Pricing',
    iconClassName: 'bd-price',
    key: 'billing',
  },
  {
    name: 'Subcription-plan',
    path: '/Super-Admin/Subcription-plan',
    iconClassName: 'bd-sub',
    key: 'subscription',
  },
  // {
  //   name: 'Configration',
  //   path: '/Super-Admin/Configration',
  //   iconClassName: 'bludsetting',
  // },
];
