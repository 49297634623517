import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import plus from '../../assets/images/plus.png';
import { ADMIN_COLUMNS } from '../../utils/constants';
import { useHistory } from 'react-router';

function SuperAdminAdministratorView({
  page,
  search,
  setSearch,
  adminsList,
  rowsPerPage,
  onHeaderClick,
  handleClick,
  handleChangePage,
  handleChangeRowsPerPage,
  permissionOfAddForm,
}) {
  const history = useHistory();

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search'>
                <span className='bd-dashboard__main'>Administrators</span>
                <div className='bd-hader'>
                  <input
                    type='text'
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder='Search'
                    style={{ padding: 5, marginRight: 10 }}
                  />
                </div>
                {permissionOfAddForm && (
                  <div className='d-flex align-items-center fw-600 cursor-pointer'>
                    <span className='bd_error'>Add Admin</span>
                    <img
                      src={plus}
                      height='25px'
                      className='cursor-pointer'
                      style={{ marginLeft: '5px' }}
                      alt='Add Btn'
                      onClick={() =>
                        history.push({
                          pathname: '/Super-Admin/Add_Admin',
                          state: {
                            editProfile: false,
                            profileData: [],
                          },
                        })
                      }
                    />
                  </div>
                )}
              </div>
              <div className='table-scroll'>
                <Tables
                  tab={'Users'}
                  tableType={'table-type-1'}
                  columns={ADMIN_COLUMNS}
                  tableData={adminsList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  headerClick={onHeaderClick}
                  onClick={handleClick}
                  rowClick={true}
                />
              </div>
              <TablePagination
                component='div'
                count={adminsList.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </section>
      </article>
    </>
  );
}

export default SuperAdminAdministratorView;
