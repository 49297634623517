import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getOTP, formatDate } from '../../utils';
import { PROFILE_TABS } from '../../utils/constants';
import {
  getDonarProfile,
  getDonarOTP,
  validateDonorOTP,
  getDonorLabResultData,
  getDonationHistoryData,
  getMedicalHistoryData,
  getRemarkyData,
} from '../../redux/actions/dashboardAction';
import DonationTab from '../../views/DashboardView/DonationTab';
import {
  VALIDATE_DONOR_OTP,
  GET_DONOR_OTP,
  BLOCK_DONOR,
  REQUEST_DONOR_CHANGE_STATUS,
  DONATION_REMARK,
  DONOR_LAB_RESULT,
  MEDICAL_HISTORY,
  DONATION_HISTORY,
} from '../../redux/actions/actionTypes';

export default function DonationTabContainer() {
  const dispatch = useDispatch();
  const {
    donarProfile,
    donarOTPData,
    historyList,
    labResultList,
    medicaList,
    donorDecline,
    donorBlock,
    donationObj,
    remarkList,
  } = useSelector((state) => state.dashboardReducer);
  const [loading, setLoading] = useState(false);
  const [OTP, setOTP] = useState('');
  const [showProfilePopup, setShowProfilePopup] = useState({
    id: '',
    show: false,
  });
  const [showProfileDetailPopup, setShowProfileDetailPopup] = useState({
    id: '',
    show: false,
  });
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [activeTab, setActiveTab] = useState('History');
  const [tabsData, setTabsData] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);
  const [errors, setError] = useState('');
  const [donorProfile, setDonoProfile] = useState({
    donorId: '',
    bloodGroup: '',
    name: '',
    gender: '',
    age: '',
    BMI: '',
    email: '',
    phone: '',
    address: '',
    availableDate: '',
    DonorType: '',
  });

  let type = 'institute';
  function handleCilck(Type, e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setShowProfilePopup({
      show: true,
      id: e.target.getAttribute('donorid'),
    });

    var donorType = Type === true ? 'knownDonor' : 'user';

    dispatch(
      getDonarProfile({
        donorId: e.target.getAttribute('donorid'),
        userType: donorType,
      })
    );
  }

  function onRequestClick(e) {
    setShowVerifyOTP(true);
    dispatch(getDonarOTP({ donorId: showProfilePopup.id }));
  }

  function otpInput(refObj) {
    let otp = getOTP(refObj);
    setOTP(otp);
  }

  function onVerifyClick() {
    dispatch(
      validateDonorOTP(
        {
          otp: OTP,
          id: donarOTPData.data.id,
        },
        () => {
          dispatch(
            getDonationHistoryData({ donorId: donarProfile.data.donorId })
          );
          dispatch(
            getDonorLabResultData({ donorId: donarProfile.data.donorId })
          );
          dispatch(
            getMedicalHistoryData({ donorId: donarProfile.data.donorId })
          );
          dispatch(getRemarkyData({ donorId: donarProfile.data.donorId }));
        }
      )
    );
    setShowProfilePopup({ show: false });
    setShowVerifyOTP(false);
  }

  function onTabChange(tab) {
    setActiveTab(tab);
  }

  function hidePopup() {
    setShowProfileDetailPopup({ show: false });
    dispatch({ type: VALIDATE_DONOR_OTP, loading: false });
    dispatch({ type: GET_DONOR_OTP, loading: false });
    dispatch({ type: BLOCK_DONOR, loading: false });
    dispatch({ type: REQUEST_DONOR_CHANGE_STATUS, loading: false });
    dispatch({ type: DONATION_HISTORY, loading: false });
    dispatch({ type: MEDICAL_HISTORY, loading: false });
    dispatch({ type: DONOR_LAB_RESULT, loading: false });
    dispatch({ type: DONATION_REMARK, loading: false });
  }

  function setDonoarPopupStatus() {
    if (donorBlock.error !== null) {
      setFail(true);
    } else if (donorBlock.data) {
      setShowSuccess(true);
    }
  }

  function setHistoryTabData() {
    if (historyList?.data) {
      let temp = [...PROFILE_TABS];
      temp[0].tableData.data = [];
      historyList?.data?.forEach((history, index) => {
        let obj = {
          hash: index + 1,
          date: history.date
            ? moment(new Date(history.date)).format('DD MMM YYYY')
            : '',
          component: history.component,
          institute: history.institutionName,
          unit: history.units,
          unitId: '',
          comments: history.comments,
        };
        temp[0].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }

  function setLabResultTabData() {
    if (labResultList?.data) {
      let temp = [...PROFILE_TABS];
      temp[1].tableData.data = [];
      labResultList?.data?.forEach((labResult, index) => {
        let obj = {
          hash: index + 1,
          date: labResult.labResults.addedAt
            ? moment(new Date(labResult.labResults.addedAt)).format(
                'DD MMM YYYY'
              )
            : '',
          bp: labResult.labResults.bloodPressure,
          weight: labResult.labResults.weight,
          hb: labResult.labResults.hb,
          rbc: '',
          wbc: '',
          platelet: '',
          esr: '',
          tlc: '',
          result: (
            <i
              className={[
                'bd-result-icon',
                labResult.labResults.finalResult === 'Pass'
                  ? ''
                  : 'bd-red__color',
              ].join(' ')}
            ></i>
          ),
        };
        labResult.labResults.results.forEach((element) => {
          obj[element.parameter.toLowerCase()] = element.observation;
        });
        temp[1].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }

  function setMedicalTabData() {
    if (medicaList?.data) {
      let temp = [...PROFILE_TABS];
      temp[2].tableData.data = [];
      medicaList?.data?.forEach((history, i) => {
        let obj = {
          hash: i + 1,
          health_issue: history?.healthIssue ?? '',
          description: history?.description ?? '',
          condition: history?.condition ?? '',
          medication: history?.medication ?? '',
          comments: history?.comments ?? '',
        };
        temp[2].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }

  function setRemarkTabData() {
    if (remarkList?.data) {
      let temp = [...PROFILE_TABS];
      temp[3].tableData.data = [];
      remarkList?.data?.forEach((remark, i) => {
        let obj = {
          hash: i + 1,
          date: remark.addedAt
            ? moment(new Date(remark.addedAt)).format('DD MMM YYYY')
            : '',
          doctor: remark.doctorName,
          event: remark.event,
          comments: remark.comment,
        };
        temp[3].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }

  function setUIStatus() {
    if (
      donarProfile.error !== null ||
      donarOTPData.error !== null ||
      donorDecline.error !== null ||
      donorBlock.error !== null
    ) {
      setFail(true);
      setError(
        donarProfile.error ||
          donarOTPData.error ||
          donorDecline.error ||
          donorBlock.error
      );
    } else {
      setFail(false);
      setError('');
    }

    labResultList.loading ||
    medicaList.loading ||
    historyList.loading ||
    donarProfile.loading ||
    donarOTPData.loading ||
    donorDecline.loading ||
    donorBlock.loading ||
    remarkList.loading
      ? setLoading(true)
      : setLoading(false);
  }

  function donarProfileDetail() {
    if (donarProfile?.data) {
      setDonoProfile({
        donorId: donarProfile?.data?.donorId,
        bloodGroup: donarProfile?.data?.bloodGroup,
        name:
          donarProfile?.data?.firstName + ' ' + donarProfile?.data?.lastName,
        gender: donarProfile?.data?.gender ? donarProfile?.data?.gender : '',
        age: donarProfile?.data?.dateOfBirth
          ? donarProfile?.data?.dateOfBirth
          : '',
        BMI: donarProfile?.data?.bmi ? donarProfile?.data?.bmi : '',
        email: donarProfile?.data?.email,
        phone:
          donarProfile?.data?.countryCode +
          ' ' +
          donarProfile?.data?.mobileNumber,
        address: donarProfile?.data?.address ? donarProfile?.data?.address : '',
        availableDate: formatDate(donarProfile?.data?.addedAt),
        photo: donarProfile?.data?.photo,
        DonorType: donarProfile?.data?.knownDonor ? 'true' : 'false',
      });
    }
  }

  function escFunction(e) {
    if (e.keyCode === 27) {
      setShowProfilePopup({ show: false });
      setShowProfileDetailPopup({ show: false });
      setShowVerifyOTP(false);
    }
  }

  useEffect(() => {
    if (donarOTPData.successFlag) {
      setShowProfileDetailPopup({ show: true });
    }
  }, [donarOTPData]);

  useEffect(() => {
    setHistoryTabData();
  }, [historyList]);

  useEffect(() => {
    setLabResultTabData();
  }, [labResultList]);

  useEffect(() => {
    setMedicalTabData();
  }, [medicaList]);

  useEffect(() => {
    setRemarkTabData();
  }, [remarkList]);

  useEffect(() => {
    donarProfileDetail();
  }, [donarProfile]);

  useEffect(() => {
    setDonoarPopupStatus();
  }, [donorBlock]);

  useEffect(() => {
    setUIStatus();
  }, [
    donarProfile,
    donarOTPData,
    historyList,
    labResultList,
    medicaList,
    donorBlock,
    donorDecline,
    remarkList,
  ]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  }, []);

  const donationProps = {
    donationData: donationObj.data,
    tabs: tabsData,
    showVerifyOTP: showVerifyOTP,
    showProfilePopup: showProfilePopup,
    showProfileDetailPopup: showProfileDetailPopup,
    activeTab: activeTab,
    showSuccess: showSuccess,
    donorProfile: donorProfile,
    showFail: showFail,
    loading: loading,
    errors: errors,
    handleCilck: handleCilck,
    onRequestClick: onRequestClick,
    otpInput: otpInput,
    setShowProfilePopup: setShowProfilePopup,
    onVerifyClick: onVerifyClick,
    setShowVerifyOTP: setShowVerifyOTP,
    onTabChange: onTabChange,
    hidePopup: hidePopup,
    setShowSuccess: setShowSuccess,
    setFail: setFail,
  };

  return (
    <>
      <DonationTab {...donationProps} />
    </>
  );
}
