import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RadioButton } from '../index';
import { API_BASE_PATH, UPLOAD_FILE_URL } from '../../utils/config';
import download from '../../assets/icon/download.svg';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { userChangeStatus } from '../../redux/actions/peopleAction';

function SuperAdminBlockUnBlock({
  ID,
  type,
  setShowComp,
  setShowProfilePopup,
  setShowSuccessPopup,
  setSuccessMsg,
  //   setShowFailPopup,
  //   setErrorMsg,
}) {
  const dispatch = useDispatch();

  const hiddenFileInput = React.useRef(null);
  const [detail, setDetail] = useState({});
  const [errMsg, setErrMsg] = useState('');

  const [attachments, setAttachments] = useState([]);
  const [currentRadioSelect, setCurrentRadioSelect] = useState('');
  const onRadioChange = (e) => {
    setCurrentRadioSelect(e.target.value);
  };
  const addFile = async (e) => {
    let selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    let response = await axios.post(API_BASE_PATH + UPLOAD_FILE_URL, formData, {
      headers: {
        token: `${localStorage.getItem('TOKEN_KEY')}`,
      },
    });
    let file = response.data.content.savedFiles[0];
    let arr = [...attachments];
    arr.push(file);
    setAttachments(arr);
  };
  const onInputChange = (e) => {
    let obj = { ...detail };
    let value = e.target.value;
    let regex = /^[0-9]+$/;
    if (e.target.name === 'days') {
      if (value.match(regex) !== null) {
        if (value * 1 < 182.5) {
          obj[e.target.name] = value;
        }
      }
    } else {
      obj[e.target.name] = value;
    }
    setDetail(obj);
  };

  function OnBlockUnblock(btnType) {
    let obj = {
      type: btnType,
      //   blockedForDays: new Date().toISOString(),
      donorId: ID,
      comment: detail.comment,
      reason: detail.reason,
      blockPeriod: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 3
      ).toISOString(),
      blockType: currentRadioSelect || 'temporarily',
      file: attachments,
    };
    if (!detail.reason) {
      setErrMsg('*Reason Is Required');
    } else {
      dispatch(userChangeStatus(obj), callBacks());
    }
  }
  function callBacks() {
    setShowProfilePopup(false);
    setShowSuccessPopup(true);
    setSuccessMsg('User Blocked Successfully');
  }

  return (
    <>
      <div className='bd-profile__otp'>
        <div className='bd-popup__head'>
          <span>{!type ? 'Block' : 'Unblock'} User</span>
        </div>
        {!type && (
          <div className='bd-donation'>
            <div className='mb-10'>Block Type :</div>
            <div className='blockPopupRadioWrapper'>
              <RadioButton
                label='Block Permanently'
                name='radio1'
                value='permanently'
                onRadioChange={onRadioChange}
              />
              <RadioButton
                label='Block Temporarily'
                name='radio1'
                value='temporarily'
                onRadioChange={onRadioChange}
              />
              <RadioButton
                label='Block till recovery'
                name='radio1'
                value='recovery'
                onRadioChange={onRadioChange}
              />
            </div>
          </div>
        )}
        {currentRadioSelect === 'temporarily' && (
          <div className='bd-form__row mt-10'>
            <lable>Block Period :</lable>
            <input
              type='text'
              placeholder='Block Period (in days) not more than 6 month'
              name='days'
              value={detail.days}
              onChange={onInputChange}
            />
          </div>
        )}
        <div className='bd-form__row mt-15'>
          <lable>Reason :</lable>
          <input
            type='text'
            placeholder='reason'
            name='reason'
            value={detail.reason}
            onChange={onInputChange}
          />
          <span style={{ color: '#af0c0c' }}>{errMsg}</span>
        </div>
        <div className='bd-form__row'>
          <lable>Comment :</lable>
          <input
            type='text'
            placeholder='Comment'
            name='comment'
            value={detail.comment}
            onChange={onInputChange}
          />
        </div>
        <input
          type='file'
          name='attachments'
          id='upload'
          hidden
          ref={hiddenFileInput}
          onChange={addFile}
        />
        <div
          className='bd-btn bd-btn--outline'
          for='upload'
          onClick={() => hiddenFileInput.current.click()}
        >
          <span for='upload'>Attach Files</span>
        </div>
        <div className='bd-attachment__file'>
          {attachments &&
            attachments.map((attachment) => {
              return (
                <a className='download-a' download href={attachment.location}>
                  <img className='download-img' src={download} alt='download' />
                  <div className='attachment-name'>{attachment.name}</div>
                </a>
              );
            })}
        </div>

        <div className='bd-btn__wrap mt-10 space-btw'>
          <div
            className='bd-btn bd-btn--outline'
            onClick={() => {
              setShowComp(false);
            }}
          >
            <span>Cancel</span>
          </div>

          {!type ? (
            <div
              className='bd-btn bd-btn--primary'
              onClick={() => {
                OnBlockUnblock('blockUser');
              }}
            >
              <span>Block User</span>
            </div>
          ) : (
            <div
              className='bd-btn bd-btn--primary'
              onClick={() => {
                OnBlockUnblock('unblockUser');
              }}
            >
              <span>Unblock User</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SuperAdminBlockUnBlock;
