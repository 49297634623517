import React from "react";

function TableFive(props) {
  return (
    <>
      Table five {props.headers} and {props.data}
    </>
  );
}

export default TableFive;
