import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CampDonorsForm_two_View from '../../views/CampView/CampDonorsForm_two_View';
import {
  postDonateForm,
  getCampsDetail,
} from '../../redux/actions/campsAction';

const FIELDS = [
  'startTime',
  'endTime',
  'volume',
  'weight_2',
  'storedIn',
  'temperature',
  'dataPostBy',
];
function CampDonorsForm_two_Conatainer({
  ProceedDonorData, //data for patch
  onCloseClick,
  campDonorsListObj,
}) {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState(null);

  const [donorInfo, setDonorInfo] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gender: '',
    id: '',
    height: '',
    weight_1: '',
    hb: '',
    bp: '',
    kitNumber: '',
    bagId: '',
    sampleId_1: '',
    sampleId_2: '',
    sampleId_3: '',
  });
  const [selectedRadio, setCurrentRadioSelect] = useState({
    radio1: '',
    radio2: '',
    radio3: '',
  });
  const [selectedCheckbox, setSelectedCheckbox] = useState({
    checkbox_1: true,
    checkbox_2: true,
  });
  const [errorFields, setErrorFields] = useState({ ...FIELDS });
  const [ErrorMsg, setErrorMsg] = useState('');

  function onInputChange(e) {
    let updateDonorInfo = { ...donorInfo };
    let value = e.target.value;
    if (FIELDS.includes(e.target.getAttribute('name'))) {
      updateDonorInfo[e.target.getAttribute('name')] = value;
    }
    setDonorInfo(updateDonorInfo);
  }

  function onRadioChange(e) {
    let obj = { ...selectedRadio };
    obj[e.target.name] = e.target.value;
    setCurrentRadioSelect(obj);
  }

  function onCheckboxChange(e) {
    let obj = { ...selectedCheckbox };
    obj[e.target.id] = e.target.checked;
    setSelectedCheckbox(obj);
  }
  function onFormChange(params) {
    setFormValues(params);
  }
  function onApproveClick(e) {
    e.preventDefault();
    let temp = {};
    FIELDS.map((a) => {
      temp[a] = e?.target[a]?.value === '';
    });
    setErrorFields(temp);

    let obj = {
      donorId: ProceedDonorData?.donorId,
      campId: ProceedDonorData?.camp,
      status: 'Approve',
      sample: {
        ...formValues.donorInfo,
        comfortableDuringProcess: formValues.radioValue['radio1'],
        clottingIssuesDuringAfterProcess: formValues.radioValue['radio2'],
        issuePostDonationRecovery: formValues.radioValue['radio3'],
        haveExplainedProtocolsPrecautionsForDonation:
          formValues.checkBoxValue['checkbox_1'],
        preDonationHealthCheckFilled: formValues.checkBoxValue['checkbox_2'],
      },
    };

    if (Object.entries(temp).filter((a) => a[1]).length !== 0) {
      setErrorMsg('All fileds are Required');
    } else {
      dispatch(
        postDonateForm(obj),
        () => onCloseClick(),
        setTimeout(() => {
          dispatch(
            getCampsDetail({ campId: ProceedDonorData?.camp, type: 'donors' })
          );
        }, 700)
      );
    }
  }

  useEffect(() => {
    onFormChange({
      donorInfo: donorInfo,
      radioValue: selectedRadio,
      checkBoxValue: selectedCheckbox,
    });
  }, [selectedRadio, donorInfo, selectedCheckbox]);

  useEffect(() => {
    if (ProceedDonorData && ProceedDonorData?.sample) {
      setDonorInfo({
        firstName: ProceedDonorData?.sample?.firstName
          ? ProceedDonorData?.sample?.firstName
          : '',
        lastName: ProceedDonorData?.sample?.lastName
          ? ProceedDonorData?.sample?.lastName
          : '',
        age: ProceedDonorData?.sample?.age ? ProceedDonorData?.sample?.age : '',
        gender: ProceedDonorData?.sample?.gender
          ? ProceedDonorData?.sample?.gender
          : '',
        id: ProceedDonorData?.donorId ? ProceedDonorData?.donorId : '',
        height: ProceedDonorData?.sample?.height
          ? ProceedDonorData?.sample?.height
          : '',
        weight: ProceedDonorData?.sample?.weight_1
          ? ProceedDonorData?.sample?.weight_1
          : '',
        hb: ProceedDonorData?.sample?.hb ? ProceedDonorData?.sample?.hb : '',
        bp: ProceedDonorData?.sample?.bp ? ProceedDonorData?.sample?.bp : '',
        kitNumber: ProceedDonorData?.sample?.kitNumber
          ? ProceedDonorData?.sample?.kitNumber
          : '',
        bagId: ProceedDonorData?.sample?.bagId
          ? ProceedDonorData?.sample?.bagId
          : '',
        sampleId_1: ProceedDonorData?.sample?.sampleId_1
          ? ProceedDonorData?.sample?.sampleId_1
          : '',
        sampleId_2: ProceedDonorData?.sample?.sampleId_2
          ? ProceedDonorData?.sample?.sampleId_2
          : '',
        sampleId_3: ProceedDonorData?.sample?.sampleId_3
          ? ProceedDonorData?.sample?.sampleId_3
          : '',
        bagType: ProceedDonorData?.sample?.bagType
          ? ProceedDonorData?.sample?.bagType
          : '',
      });
    }
  }, [ProceedDonorData]);
  const proceedForm_TwoProps = {
    donorInfo: donorInfo,
    onCloseClick: onCloseClick,
    campDonorsListObj: campDonorsListObj,
    selectedCheckbox: selectedCheckbox,

    errorFields,
    ErrorMsg: ErrorMsg,
    onInputChange: onInputChange,
    onRadioChange: onRadioChange,
    onCheckboxChange: onCheckboxChange,
    onApproveClick: onApproveClick,
  };
  return (
    <div>
      <CampDonorsForm_two_View {...proceedForm_TwoProps} />
    </div>
  );
}

export default CampDonorsForm_two_Conatainer;
