import {
  POST_DONOR_DATA,
  POST_DONOR_DATA_SUCCESS,
  POST_DONOR_DATA_FAILED,
} from "../actions/actionTypes";

const initialState = {
  donorObject: {
    loading: false,
    data: [],
    error: null,
  },
};
const addDonorReducer = function (state = initialState, action) {
  switch (action.type) {
    case POST_DONOR_DATA:
      return {
        ...state,
        donorObject: {
          loading: true,
          data: {},
          error: null,
        },
      };

    case POST_DONOR_DATA_SUCCESS:
      return {
        ...state,
        donorObject: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case POST_DONOR_DATA_FAILED:
      return {
        ...state,
        donorObject: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default addDonorReducer;
