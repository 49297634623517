import React, { useEffect, useState } from 'react';
import LabResultView from '../../views/LabResultView';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLabResults,
  triggerPostLabResult,
  triggerSetCurrSelected,
  getLabCriteriaResult,
} from '../../redux/actions/labResultAction';
import { validateLabResult } from '../../utils';

function LabResultContainer() {
  // const headerArray = [
  //   'Sample Date',
  //   'Sample ID',
  //   'Group',
  //   'Laboratory',
  //   'Hb 12-17 mg/dl',
  //   'RBC 4.5-5.5*1012',
  //   'WBC 4.5-5.5*1012',
  //   'PT 4.5-5.5*1012',
  //   'APTT 4.5-5.5*1012',
  //   'BT 4.5-5.5*1012',
  //   'CT 4.5-5.5*1012',
  //   'ESR 4.5-5.5*1012',
  //   'ESR 4.5-5.5*1012',
  //   'TLC 4.5-5.5*1012',
  //   'Result',
  //   'Result Date',
  // ];

  const resultsArr = [
    {
      parameter: 'RBC',
      observation: '',
      remarks: '',
      standard: '4.5-5.5*10^12',
    },
    {
      parameter: 'WBC',
      observation: '',
      remarks: '',
      standard: '4-5*10^9',
    },
    {
      parameter: 'Platelet',
      observation: '',
      remarks: '',
      standard: '150-400*10^9',
    },
    {
      parameter: 'ESR',
      observation: '',
      remarks: '',
      standard: '0-20 mm/Hr',
    },
    {
      parameter: 'TLC',
      observation: '',
      remarks: '',
      standard: '4000-11000/mm^3',
    },
  ];

  const dispatch = useDispatch();
  const { getLabResultObj, postLabResultObj } = useSelector(
    (state) => state.labResultReducer
  );

  useEffect(() => {
    dispatch(getLabCriteriaResult());
  }, []);

  const [showGroupFilter, setShowGroupFilter] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [labPostObj, setLabPostObj] = useState({});
  const [resultsArray, setResultsArray] = useState(resultsArr);
  const [showResultOption, setShowResultOption] = useState(false);
  const [result, SetResult] = useState('Fail');
  const [showLabOption, setShowLabOption] = useState(false);
  const [laboratory, setLaboratory] = useState('Laboratory');
  const [postError, setPostError] = useState('');

  const [currBludGroup, setCurrBludGroup] = useState('');

  useEffect(() => {
    dispatch(getLabResults());
    document.addEventListener('keydown', escFunction, false);
  }, []);

  const onRowClick = (item) => {
    setShowDetailsPopup(!showDetailsPopup);
    dispatch(triggerSetCurrSelected(item));
    // dispatch(triggerGetLabDetails({ id: item._id }));
  };

  const showAddResultPopup = () => {
    setPostError('');
    setShowAddPopup(!showAddPopup);
  };

  const onLaboratorySelect = (data) => {
    setLaboratory(data);
    setShowLabOption(false);
  };

  const onResultSelect = (data) => {
    SetResult(data);
    setShowResultOption(false);
  };

  const onChangeText = (e) => {
    let updatedLabObj = labPostObj;
    updatedLabObj[e.target.name] = e.target.value;
    setLabPostObj(updatedLabObj);
  };

  const onResultArrEdit = (e, i) => {
    let resultArray = resultsArray;
    resultArray[i][e.target.name] = e.target.value;
    setResultsArray(resultArray);
  };
  const onSubmit = () => {
    let obj = {
      inventoryId: '60ad29d07ad5c628c4715ef5',
      donorId: labPostObj.donorId,
      bloodGroup: labPostObj.bloodGroup,
      collectedDate: labPostObj.collectedDate,
      labResults: {
        reportNo: labPostObj.reportNo,
        weight: labPostObj.weight,
        bloodPressure: labPostObj.bloodPressure,
        Hb: labPostObj.Hb,
        laboratory: laboratory,
        testedBy: labPostObj.testedBy,
        addedAt: labPostObj.addedAt,
        results: resultsArray,
        finalResult: result,
        comments: labPostObj.comments,
      },
    };

    if (validateLabResult(obj, setPostError)) {
      setPostError('');
      return dispatch(
        triggerPostLabResult({ labs: [obj] }, () => {
          setShowAddPopup(!showAddPopup);
          dispatch(getLabResults());
        })
      );
    }
  };

  const filterByBloodGroup = (bg) => {
    setCurrBludGroup(bg);
  };

  function escFunction(e) {
    if (e.keyCode === 27) {
      setShowAddPopup(false);
      setShowDetailsPopup(false);
    }
  }
  return (
    <LabResultView
      getLabResultObj={getLabResultObj}
      // headerArray={headerArray}
      showGroupFilter={showGroupFilter}
      setShowGroupFilter={setShowGroupFilter}
      showAddResultPopup={showAddResultPopup}
      showAddPopup={showAddPopup}
      onChangeText={onChangeText}
      onSubmit={onSubmit}
      showResultOption={showResultOption}
      onResultSelect={onResultSelect}
      setShowResultOption={setShowResultOption}
      result={result}
      onLaboratorySelect={onLaboratorySelect}
      setShowLabOption={setShowLabOption}
      laboratory={laboratory}
      showLabOption={showLabOption}
      resultsArr={resultsArr}
      resultsArray={resultsArray}
      onResultArrEdit={onResultArrEdit}
      postError={postError}
      postLabResultObj={postLabResultObj}
      // showDetailsPopup={showDetailsPopup}
      // detailsPopup={detailsPopup}
      onRowClick={onRowClick}
      showDetailsPopup={showDetailsPopup}
      setShowDetailsPopup={setShowDetailsPopup}
      filterByBloodGroup={filterByBloodGroup}
      currBludGroup={currBludGroup}
    />
  );
}

export default LabResultContainer;
