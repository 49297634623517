import React from "react";
import background from "../../assets/images/camps/camps.jpg";

function ProfileView(props) {
  let { profileObj } = props;
  let profileData = profileObj?.data?.content?.data ?? null;
  const imageArr = [{}, {}, {}, {}, {}];
  return (
    <article class="bd-article">
      <section class="bd-dashboard bd-profile">
        <div class="bd-dashboard__container">
          <div class="bd-dashboard__lft">
            <div class="bd-dashboard__header">
              <span class="bd-dashboard__main">Profile</span>
              <i class="bludedit"></i>
            </div>

            <div class="bd-profile__cont">
              <div class="bd-addimages">
                {imageArr.map((data, index) => {
                  return (
                    <div
                      class="bd-add active"
                      style={{
                        backgroundImage: `url(${background})`,
                        width: 160,
                        height: 160,
                        margin: 20,
                      }}
                    >
                      <i class="bludplus"></i>
                    </div>
                  );
                })}
              </div>
              <div class="bd-profile__address">
                <div class="bd-profile__add">
                  <div class="bd-profile__add--hd">
                    <span>{profileData?.institutionName}</span>
                  </div>
                  <div class="bd-profile__add--main">
                    {profileData?.address?.description}
                  </div>
                  <div class="bd-profile__add--id">
                    <span>{profileData?.contactEmail}</span>
                  </div>
                  <div class="bd-profile__add--mob">
                    <span>{profileData?.contactNumber}</span>
                  </div>
                  {/* <div class="bd-profile__add--tm">
                    <span>09:00-13:00 , 14:00-18:00 </span>
                  </div> */}
                  <div class="bd-profile__add--beds">
                    <label>{profileData?.contactNumber}</label>
                  </div>
                </div>
                {/* <div class="bd-profile__sign">
                  <div class="bd-profile__sign--hd">
                    <span>Authorised signature</span>
                  </div>
                  <div class="bd-profile__sign--txt">
                    <span>Jay Jadhav</span>
                  </div>
                </div>
                <div class="bd-profile__review">
                  <span>View Reviews made by donors</span>
                </div> */}
              </div>
            </div>
          </div>

          {/* <div class="bd-dashboard__header"> */}
          {/* <i class="bludedit"></i> */}
        </div>
        {/* </div> */}
      </section>
    </article>
  );
}

export default ProfileView;
