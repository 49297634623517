import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuperAdminPricingView from '../../views/SuperAdminPricingView/SuperAdminPricingView';
import { triggerGetPricingList } from '../../redux/actions/superAdminCommonActions';
function SuperAdminPricingContainer() {
  const dispatch = useDispatch();
  const { getPricingList } = useSelector(
    (state) => state.superAdminCommonReducers
  );
  // console.log(getPricingList);
  useEffect(() => {
    dispatch(triggerGetPricingList());
  }, []);
  return <SuperAdminPricingView />;
}

export default SuperAdminPricingContainer;
