import React, { useEffect } from 'react';
import ProfileView from '../../views/ProfileView';
import { useDispatch, useSelector } from 'react-redux';
import { triggerGetProfile } from '../../redux/actions';

function Profile() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(triggerGetProfile());
  }, []);

  const { profileObj } = useSelector((state) => state.profileReducer);

  return <ProfileView profileObj={profileObj} />;
}

export default Profile;
