import { makeAPICall, urlSearchParams } from '../../utils';
import { API_BASE_PATH, USERS_LIST_URL } from '../../utils/config';
import {
  GET_USERS_DATA,
  GET_USERS_DATA_SUCCESS,
  GET_USERS_DATA_FAILED,
} from './../actions/actionTypes';

export function getUsersList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${USERS_LIST_URL}`,
      dispatch,
      defaultAction: GET_USERS_DATA,
      successAction: GET_USERS_DATA_SUCCESS,
      failedAction: GET_USERS_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
