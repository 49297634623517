import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCampsListData,
  getCampsRequestData,
  getCampsDetail,
  // getNgoCampsListData,
} from '../../redux/actions/campsAction';
import CampView from '../../views/CampView';
import { Loader } from '../../components';
import { sorting } from '../../utils';
import moment from 'moment';
import CampDonorsContainer from './CampDonorsContainer';
import { getNGODashboardReqTabData } from '../../redux/actions/dashboardAction';

function CampContainer() {
  const dispatch = useDispatch();

  const [acceptedCampList, setAcceptedCampList] = useState([]);
  const [acceptedSearch, setAcceptedSearch] = useState('');

  const [pendingCampList, setPendingCampList] = useState([]);
  const [pendingSearch, setPendingSearch] = useState('');
  const [campProfilePopup, setCampProfilePopup] = useState(false);
  const [campListObject, setCampListObject] = useState('');

  const [campDonorsDetailes, setCampDonorsDetailes] = useState([]);
  const [donorPage, setDonorPage] = useState({
    id: '',
    show: false,
  });
  const [showAcceptPopup, setShowAcceptPopup] = useState({
    id: '',
    show: false,
  });

  const [count, setCount] = useState(0);

  const { loginObj } = useSelector((state) => state.loginReducer);
  let institutionType = loginObj?.data?.content?.data?.institutionType ?? '';

  const { campsObject, campsRequestObject, campDetails, getNgoCamp } =
    useSelector((state) => state.campsReducer);

  useEffect(() => {
    dispatch(getCampsListData());
  }, []);

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...acceptedCampList];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setAcceptedCampList(data);
    setCount(count + 1);
  }
  function onPendingHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...pendingCampList];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setPendingCampList(data);
    setCount(count + 1);
  }

  // Accepted Camp DATA MODIFICATION
  // accepted BTN / OnGoing BTN
  function SetAcceptedList(listData) {
    let Data = [];
    listData &&
      listData.forEach((record) => {
        let obj = {
          Date: record?.campDuration?.startTime
            ? moment(record?.campDuration?.startTime).format('DD MMM YYYY')
            : '--',
          CampName: record?.campName ? record?.campName : '--',
          Venue: record?.venue ? record?.venue : '--',
          Organiser: record?.organiser ? record?.organiser : '--',
          MobileNo: record?.campCoordinator?.mobileNumber
            ? record?.campCoordinator?.mobileNumber
            : '--',
          id: record?._id,

          Button: (
            <OnGoingBtn
              id={record._id}
              onAssignDonorsClick={onAssignDonorsClick}
            />
          ),
        };
        if (institutionType !== 'NGO') {
          obj['DonorsCount'] = record?.noDonors ? record?.noDonors : '0';
          obj['Button'] = (
            <ButtonDonors
              id={record._id}
              onDonorsClick={onDonorsClick}
              APICallAgain={APICallAgain}
            />
          );
        }
        Data.push(obj);
      });
    return Data;
  }

  // Accepted Camp DONORS LIST BTN
  function ButtonDonors(props) {
    return (
      <div class='bd-request-otp' id={props.id}>
        <div
          className='bd-request-accept-blur bd-request-otp-stars brder_Radius'
          onClick={props.onDonorsClick}
          id={props.id}
        >
          <span onClick={props.onDonorsClick} id={props.id}>
            <i id={props.id} className='bludpeople-icon'></i>
          </span>
        </div>
        <div
          onClick={props.onDonorsClick}
          id={props.id}
          className=' bd-request-accept bd-black active'
        >
          <span id={props.id}>Donors</span>
        </div>
      </div>
    );
  }
  function onDonorsClick(e) {
    setDonorPage({
      id: e.target.id,
      show: true,
    });
    dispatch(getCampsDetail({ campId: e.target.id, type: 'donors' }));
  }

  // OnGoing Camp DONORS LIST BTN
  function OnGoingBtn(props) {
    return (
      <div class='bd-request-otp' id={props.id}>
        <div
          className='bd-request-accept-blur bd-request-otp-stars brder_Radius'
          onClick={props.onAssignDonorsClick}
          id={props.id}
        >
          <span onClick={props.onAssignDonorsClick} id={props.id}>
            <i id={props.id} className='bludpeople-icon'></i>
          </span>
        </div>
        <div
          onClick={props.onAssignDonorsClick}
          id={props.id}
          className=' bd-request-accept bd-black active'
        >
          <span id={props.id}>Assign Donors</span>
        </div>
      </div>
    );
  }
  function onAssignDonorsClick(e) {
    e.preventDefault();
    setShowAcceptPopup({
      id: e.target.id,
      show: true,
    });
    dispatch(getNGODashboardReqTabData());
  }

  // APICallAgain this is for Call api again in donors list
  function APICallAgain(CampIdd) {
    dispatch(getCampsDetail({ campId: CampIdd, type: 'donors' }));
  }

  // Pending Camp DATA MODIFICATION
  function SetPendingList(listData) {
    let Data = [];
    listData &&
      listData.forEach((record) => {
        let obj = {
          Date: record?.campDuration?.startTime
            ? moment(record?.campDuration?.startTime).format('DD MMM YYYY')
            : '--',
          CampName: record?.campName ? record?.campName : '--',
          Venue: record?.venue ? record?.venue : '--',
          Organiser: record?.organiser ? record?.organiser : '--',
          MobileNo: record?.campCoordinator?.mobileNumber
            ? record?.campCoordinator?.mobileNumber
            : '--',
          Button: (
            <ButtonPending
              id={record._id}
              // onPendingClick={onPendingClick}
              onRecordClick={onRecordClick}
            />
          ),
        };
        Data.push(obj);
      });
    return Data;
  }

  // Pending Camp List APPROVE  BTN
  function ButtonPending(props) {
    return (
      <div class='bd-request-otp' id={props.id}>
        <div
          className='bd-request-accept-blur bd-request-otp-stars brder_Radius'
          onClick={props.onRecordClick}
          id={props.id}
        >
          <span
            onClick={props.onRecordClick}
            id={props.id}
            records={props.records}
          >
            <i id={props._id} className='bludaccept'></i>
          </span>
        </div>
        <div
          onClick={props.onRecordClick}
          records={props.records}
          id={props.id}
          className=' bd-request-accept bd-black active'
        >
          <span id={props.id}>
            {institutionType !== 'NGO' ? 'Pending' : 'Details'}
          </span>
        </div>
      </div>
    );
  }

  function onRecordClick(e) {
    if (institutionType !== 'NGO') {
      setCampListObject(
        campsRequestObject?.data.filter((ele) => ele._id == e.target.id)[0]
      );
    } else {
      setCampListObject(
        getNgoCamp?.data.filter((ele) => ele._id == e.target.id)[0]
      );
    }
    setCampProfilePopup(true);
  }

  // Accepted Camp SEARCH
  function finalAcceptedCampList() {
    return acceptedSearch === ''
      ? acceptedCampList
      : acceptedSearchData(acceptedCampList);
  }

  //Pending Camp SEARCH
  function finalPendingCampList() {
    return pendingSearch === ''
      ? pendingCampList
      : pendingSearchData(pendingCampList);
  }

  // Accepted  list SEARCH
  function acceptedSearchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.Organiser.toLowerCase().includes(
            acceptedSearch.toLowerCase()
          ) ||
          String(items.Date)
            .toLowerCase()
            .includes(acceptedSearch.toLowerCase()) ||
          String(items.MobileNo)
            .toLowerCase()
            .includes(acceptedSearch.toLowerCase()) ||
          String(items.CampName)
            .toLowerCase()
            .includes(acceptedSearch.toLowerCase()) ||
          String(items.Venue)
            .toLowerCase()
            .includes(acceptedSearch.toLowerCase()) ||
          String(items.DonorsCount)
            .toLowerCase()
            .includes(acceptedSearch.toLowerCase())
      )
    );
  }
  // Pending list SEARCH
  function pendingSearchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.Organiser.toLowerCase().includes(pendingSearch.toLowerCase()) ||
          String(items.Date)
            .toLowerCase()
            .includes(pendingSearch.toLowerCase()) ||
          String(items.MobileNo)
            .toLowerCase()
            .includes(pendingSearch.toLowerCase()) ||
          String(items.CampName)
            .toLowerCase()
            .includes(pendingSearch.toLowerCase()) ||
          String(items.Venue)
            .toLowerCase()
            .includes(pendingSearch.toLowerCase())
      )
    );
  }

  useEffect(() => {
    if (campsObject) setAcceptedCampList(SetAcceptedList(campsObject?.data));

    if (campDetails) setCampDonorsDetailes(campDetails);

    if (institutionType !== 'NGO') {
      if (campsRequestObject) {
        setPendingCampList(SetPendingList(campsRequestObject?.data));
      }
    } else {
      if (getNgoCamp?.data?.length) {
        setPendingCampList(SetPendingList(getNgoCamp?.data));
      }
    }
  }, [
    campsObject?.data,
    campsRequestObject?.data,
    campDetails,
    getNgoCamp?.data,
  ]);

  return (
    <>
      {campsObject?.loading &&
      campsObject?.data.length <= 0 &&
      campsRequestObject?.loading &&
      campsRequestObject?.data.length <= 0 ? (
        <Loader />
      ) : donorPage.show ? (
        campDetails?.loading || campDetails?.data.length <= 0 ? (
          <Loader />
        ) : (
          <CampDonorsContainer
            campDonorsDetailes={campDonorsDetailes}
            campDetails={campDetails}
            setDonorPage={setDonorPage}
            APICallAgain={APICallAgain}
          />
        )
      ) : (
        <CampView
          institutionType={institutionType}
          // NGO Assign BTN
          showAcceptPopup={showAcceptPopup}
          setShowAcceptPopup={setShowAcceptPopup}
          // AcceptedView
          acceptedCampList={finalAcceptedCampList()}
          setAcceptedSearch={setAcceptedSearch}
          onHeaderClick={onHeaderClick}
          // PendingView
          pendingCampList={finalPendingCampList()}
          setPendingSearch={setPendingSearch}
          onPendingHeaderClick={onPendingHeaderClick}
          campProfilePopup={campProfilePopup}
          setCampProfilePopup={setCampProfilePopup}
          campListObject={campListObject}
        />
      )}
    </>
  );
}

export default CampContainer;
{
  /* {campsObject?.loading || campsRequestObject?.loading ? ( */
}
