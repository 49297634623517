import React from 'react';
import PendingVerificationsTab from './PendingVerificationsTab';
import RegisterTab from './RegisterTab';
function SuperAdminInstituionsView({
  registerSearch,
  pendingSearch,

  setRegisterSearch,
  setPendingSearch,

  activeTab,
  onTabChange,

  pendingList,
  onPendingHeaderClick,

  registeredList,
  onRegisterHeaderClick,

  handleClick,
}) {
  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search bd-inventory-header-custom'>
                <span className='bd-dashboard__main'>Institution</span>
                <div className='bd-tab bd-tab-new width'>
                  <div
                    id='Register'
                    className={activeTab === 'Register' && 'active'}
                    onClick={onTabChange}
                    activetab={activeTab}
                  >
                    <span id='Register' style={{ padding: '0 10px' }}>
                      Register
                    </span>
                  </div>
                  <div
                    id='Pending'
                    className={activeTab === 'Pending' && 'active'}
                    onClick={onTabChange}
                    activetab={activeTab}
                  >
                    <span id='Pending' style={{ padding: '0 10px' }}>
                      Pending For Verification
                    </span>
                  </div>
                </div>
                <div className='bd-hader bd-hader-custom'>
                  <div className='bd-hader'>
                    {activeTab === 'Register' ? (
                      <input
                        type='text'
                        value={registerSearch}
                        onChange={(e) => {
                          setRegisterSearch(e.target.value);
                        }}
                        placeholder='Search'
                        style={{ padding: 5, marginRight: 10 }}
                      />
                    ) : (
                      <input
                        type='text'
                        value={pendingSearch}
                        onChange={(e) => {
                          setPendingSearch(e.target.value);
                        }}
                        placeholder='Search'
                        style={{ padding: 5, marginRight: 10 }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='table-scroll'>
            {activeTab === 'Register' ? (
              <RegisterTab
                registeredList={registeredList}
                onRegisterHeaderClick={onRegisterHeaderClick}
                handleClick={handleClick}
              />
            ) : (
              <PendingVerificationsTab
                pendingList={pendingList}
                onPendingHeaderClick={onPendingHeaderClick}
                handleClick={handleClick}
              />
            )}
          </div>
        </section>
      </article>
    </>
  );
}

export default SuperAdminInstituionsView;
