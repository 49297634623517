import { BLOOD_COMPONENT_LIST_URL, API_BASE_PATH } from "../../utils/config";
import {
  BLOOD_COMPONENT_LIST,
  BLOOD_COMPONENT_LIST_SUCCESS,
  BLOOD_COMPONENT_LIST_FAILED
} from "../actions/actionTypes";
import { makeAPICall } from "../../utils";


export function triggerBloodComponentList(callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${BLOOD_COMPONENT_LIST_URL}`,
      dispatch,
      callback,
      defaultAction: BLOOD_COMPONENT_LIST,
      successAction: BLOOD_COMPONENT_LIST_SUCCESS,
      failedAction: BLOOD_COMPONENT_LIST_FAILED,
    };

    makeAPICall(params);
  };
}
