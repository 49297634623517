import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessPopup, FailPopup } from '../../components';
import plus from '../../assets/images/plus.png';
import AcceptedCampView from './AcceptedCampView';
import PendingCampView from './PendingCampView';
import CreateCampConatiner from '../../containers/CampContainer/CreateCampConatiner';
import NgoCreateCampContainer from '../../containers/CampContainer/NgoCreateCampContainer';
import { NgoInventoryModal } from '../../components/Popups/NgoInventoryModal';
import { GET_NGO_REQUEST_TAB_DATA } from '../../redux/actions/actionTypes';
import {
  getRequestData,
  getAcceptRequest,
  assignBtnClick,
} from '../../redux/actions/dashboardAction';
function CampView({
  // NGO assign popup

  institutionType,
  showAcceptPopup,
  setShowAcceptPopup,
  //Accepted Camps
  acceptedCampList,
  acceptedSearch,
  setAcceptedSearch,
  onHeaderClick,
  //Pending Camps
  pendingCampList,
  onPendingHeaderClick,
  pendingSearch,
  setPendingSearch,
  campProfilePopup,
  setCampProfilePopup,
  campListObject,
}) {
  const dispatch = useDispatch();

  const {
    userRequestObj,
    ngoDashboardAcceptBtn, // NGO All Dashboard Request TAb Acceptance  Data
  } = useSelector((state) => state.dashboardReducer);

  const [selectedUser, setSelectedUser] = useState('');

  const [addCamp, setAddCamp] = useState(false);

  const [activeTab, setActiveTab] = useState('Accepted');

  const [showSuccess, setShowSuccess] = useState(false);

  const [showFail, setShowFail] = useState(false);

  const [popUpMsg, setPopUpMsg] = useState('');

  function dropDownChange(e) {
    let id = e.value;
    setSelectedUser(e.value);
    let params = {
      type: 'request',
      id: userRequestObj.data.filter((a) => id === a._id)[0]._id,
    };
    dispatch(getAcceptRequest(params));
  }
  function setList() {
    let arr = [];
    userRequestObj?.data?.map((a) => {
      let fname = a?.details?.firstName ?? '';
      let lname = a?.details?.lastName ?? '';
      let bname = a?.bloodBankName ?? '';
      let obj = {
        id: a._id,
        patientId: a.patientId,
        name:
          a.requestType === 'Individual'
            ? `${fname} ${lname} [${a.patientId}] ${
                a?.hospitalLocation?.description ?? ''
              }`
            : `${bname}`,
      };
      arr.push(obj);
    });
    return arr;
  }

  var title = acceptedCampList.filter((c) => c.id === showAcceptPopup.id);

  function onAssignClick({ donorsList }) {
    let obj = {
      id: title[0]?.id,
      donationDate: new Date(title[0]?.Date).toISOString(),
      donors: donorsList,
      type: 'camp',
    };
    dispatch(
      assignBtnClick(obj, () => {
        dispatch(getRequestData());
        setShowSuccess(true);
        setPopUpMsg('Assign/Depute successfully');
      })
    );
    setShowAcceptPopup({ show: false });
  }

  function onCancelClick(e) {
    setShowAcceptPopup({ show: false });
    dispatch({ type: GET_NGO_REQUEST_TAB_DATA, loading: false });
  }

  // function getInventoryList() {
  //   return true;
  // }
  function CloseAddCampPopup() {
    setAddCamp(!addCamp);
    // institutionType !== 'NGO' ? setAddCamp(!addCamp) : 'ngo pop here';
    // addCamp && getInventoryList();
  }

  function onTabChange(e) {
    setActiveTab(e.target.id);
    setPendingSearch('');
    setAcceptedSearch('');
  }
  let addCampsObj = {
    campName: '',
    SponsoredBy: '',
    noOfBeds: '',
    targetUnits: '',
    date: '',
    fromTime: '',
    toTime: '',
    SponsorCoordinator: '',
    SponsoredMobileNo: '',
    SponsoredEmailId: '',
    bloodBankCoordinator: '',
    bloodBankedMobileNo: '',
    bloodBankedEmailId: '',
    venue: '',
    address: '',
    id: '',
    attachments: [],
  };

  const ngoProps = {
    title: title[0]?.CampName,
    campID: title[0]?.id,
    buttonName: 'Assign/Depute',
    type: 'camp',
    data: ngoDashboardAcceptBtn?.data?.content?.data,
    selectedUser: selectedUser,
    userList: setList(),
    showDetail: false,
    defaultUnits: userRequestObj?.data?.filter(
      (a) => a._id === showAcceptPopup.id
    )[0]?.units,
    defaultUser: setList().filter((a) => a.id === showAcceptPopup.id)[0],
    onButtonlick: onAssignClick,
    onCancelClick: onCancelClick,
    dropDownChange: dropDownChange,
  };

  function OrganiseORCreateForm() {
    if (addCamp) {
      if (institutionType === 'Both') {
        return (
          <CreateCampConatiner
            addCamps={addCampsObj}
            toggleForm={CloseAddCampPopup}
            setShowSuccess={setShowSuccess}
            setShowFail={setShowFail}
          />
        );
      } else if (institutionType === 'NGO') {
        return (
          <NgoCreateCampContainer
            addCamps={addCampsObj}
            toggleForm={CloseAddCampPopup}
            setPopUpMsg={setPopUpMsg}
            setShowSuccess={setShowSuccess}
            setShowFail={setShowFail}
          />
        );
      }
    }
  }

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search bd-inventory-header-custom'>
                <span className='bd-dashboard__main'>List of Camps</span>
                <div className='bd-tab bd-tab-new width'>
                  <div
                    id='Accepted'
                    className={activeTab === 'Accepted' && 'active'}
                    onClick={onTabChange}
                    activetab={activeTab}
                  >
                    <span id='Accepted' style={{ padding: '0 10px' }}>
                      {institutionType !== 'NGO' ? 'Accepted' : 'On Going'}
                    </span>
                  </div>
                  {institutionType !== 'NGO' ? (
                    <div
                      id='Pending'
                      className={activeTab === 'Pending' && 'active'}
                      onClick={onTabChange}
                      activetab={activeTab}
                    >
                      <span id='Pending' style={{ padding: '0 10px' }}>
                        Pending
                      </span>
                    </div>
                  ) : (
                    <div
                      id='Pending'
                      className={activeTab === 'Pending' && 'active'}
                      onClick={onTabChange}
                      activetab={activeTab}
                    >
                      <span id='Pending' style={{ padding: '0 10px' }}>
                        Created
                      </span>
                    </div>
                  )}
                </div>
                <div className='bd-hader bd-hader-custom'>
                  <div className='bd-hader'>
                    {activeTab === 'Pending' ? (
                      <input
                        type='text'
                        value={pendingSearch}
                        onChange={(e) => {
                          setPendingSearch(e.target.value);
                        }}
                        placeholder='Search'
                        style={{ padding: 5, marginRight: 10 }}
                      />
                    ) : (
                      <input
                        type='text'
                        value={acceptedSearch}
                        onChange={(e) => {
                          setAcceptedSearch(e.target.value);
                        }}
                        placeholder='Search'
                        style={{ padding: 5, marginRight: 10 }}
                      />
                    )}
                  </div>

                  <div className='d-flex align-items-center fw-600 cursor-pointer'>
                    <span className='bd_error' onClick={CloseAddCampPopup}>
                      {institutionType === 'NGO'
                        ? 'Create Camp'
                        : 'Organise Camp'}
                    </span>
                    <img
                      onClick={CloseAddCampPopup}
                      src={plus}
                      height='25px'
                      className='cursor-pointer'
                      style={{ marginLeft: '5px' }}
                      alt='Add Btn'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {OrganiseORCreateForm()}

          <div className='table-scroll'>
            {activeTab === 'Pending' ? (
              <PendingCampView
                institutionType={institutionType}
                pendingCampList={pendingCampList}
                campProfilePopup={campProfilePopup}
                setCampProfilePopup={setCampProfilePopup}
                campListObject={campListObject}
                onHeaderClick={onPendingHeaderClick}
              />
            ) : (
              <AcceptedCampView
                institutionType={institutionType}
                acceptedCampList={acceptedCampList}
                onHeaderClick={onHeaderClick}
              />
            )}
          </div>
          {/* ngo inventory modal for NGo assign Donor BTn */}
          {showAcceptPopup.show && <NgoInventoryModal {...ngoProps} />}
          {showSuccess && (
            <SuccessPopup
              title={
                institutionType !== 'NGO'
                  ? 'The Camp Has Been Added Successfully'
                  : popUpMsg
              }
              onContinueClick={() => setShowSuccess(false)}
            />
          )}
          {showFail && (
            <div className='bd-fail'>
              <FailPopup
                title='All Fileds Is Required!'
                onContinueClick={() => setShowFail(false)}
              />
            </div>
          )}
        </section>
      </article>
    </>
  );
}

export default CampView;
