import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InventoryTableList from '../../components/InventoryTableList';
import { INVENTORY_RECEIPT_COLUMNS } from '../../utils/constants';
import { postReceiptData } from '../../redux/actions/inventoryAction';

function ReceiptView({ data, bloodComponentList }) {
  const [selectedListIds, setSelectedIds] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [btnType, setBtnType] = useState('');
  const [loading, setLoading] = useState(false);
  const [showFail, setShowFail] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const { postInventoryReceiptObj } = useSelector(
    (state) => state.inventoryReducer
  );

  function onActionClick(type) {
    switch (type) {
      case 'Accept':
        dispatch(
          postReceiptData(
            {
              state: 'Accepted',
              ids: selectedListIds,
            },
            () => {
              setShowSuccess(true);
            }
          )
        );
        break;
      case 'Decline':
        dispatch(
          postReceiptData(
            {
              state: 'Declined',
              ids: selectedListIds,
            },
            () => {
              setShowSuccess(true);
            }
          )
        );
        break;
      default:
      // code block
    }
  }

  function onDeclinClick() {
    onActionClick('Decline');
    setBtnType('btnDecline');
  }
  function onAcceptClick() {
    onActionClick('Accept');
    setBtnType('btnAccept');
  }

  function showErrorPopUp() {
    postInventoryReceiptObj.loading ? setLoading(true) : setLoading(false);

    if (postInventoryReceiptObj.error !== null) {
      setError(postInventoryReceiptObj.error);
      setShowFail(true);
    }
  }
  function hideErrorPopUp() {
    setShowFail(false);
  }

  useEffect(() => {
    showErrorPopUp();
  }, [postInventoryReceiptObj, data]);

  return (
    <React.Fragment>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <InventoryTableList
            data={data}
            title={'Receipt'}
            columns={INVENTORY_RECEIPT_COLUMNS}
            selectedListIds={selectedListIds}
            setSelectedIds={setSelectedIds}
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
            showFail={showFail}
            // setShowFail={setShowFail}
            error={error}
            // setError={setError}
            loading={loading}
            hideErrorPopUp={hideErrorPopUp}
            btnType={btnType}
            bloodComponentList={bloodComponentList}
          />
          {selectedListIds.length ? (
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline' onClick={onDeclinClick}>
                <span>Decline</span>
              </div>
              <div
                className='bd-btn bd-btn--primary db-bg__green'
                onClick={onAcceptClick}
              >
                <span>Accept</span>
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
      </article>
    </React.Fragment>
  );
}

export default ReceiptView;
