import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tile, Charts, Tabs } from '../../components/index';
import VerifyTabContainer from '../../containers/DashboardContainer/VerifyTabContainer';
import DropDown from '../../components/RDropDown';
import donors from '../../assets/images/donors.png';
import available from '../../assets/images/available.png';
import issuedImg from '../../assets/images/issued.png';
import inventoryImg from '../../assets/images/inventory.png';
import receivedImg from '../../assets/images/received.png';
import moment from 'moment';
export default function DashboardView({
  tabList,
  onTabChange,
  activeTab,
  chartFilterOnChange,
  dashboarTilesCount,
  ngoDashboardCount,
  inventoryList,
  // institutionType,
}) {
  const chartFilter = [
    {
      label: 'Monthly',
      value: 'Monthly',
    },
    {
      label: 'Weekly',
      value: 'Weekly',
    },
  ];
  const { loginObj } = useSelector((state) => state.loginReducer);
  let { institutionType } = loginObj?.data?.content?.data ?? '';

  const [filterValue, setFilterValue] = useState(chartFilter[0]);
  const [totalCountData, setTotalCountData] = useState([
    {
      count: 0,
      title: 'Donors Nearby',
    },
    {
      count: 0,
      title: 'Available for Donation',
    },
    {
      count: 0,
      title: 'Received',
    },
    {
      count: 0,
      title: 'Issued',
    },
    {
      count: 0,
      title: 'Inventory',
    },
  ]);
  const [ngoTileData, setNgoTileDataa] = useState([
    {
      count: 0,
      title: 'My Donors',
    },
    {
      count: 0,
      title: 'Available for Donation',
    },
    {
      count: 0,
      title: 'Donated so far',
    },
    {
      count: 0,
      title: 'Repeat Donations',
    },
    {
      count: 0,
      title: 'Camps Organised',
    },
  ]);

  function setTileData() {
    const dashboardCountsData = dashboarTilesCount?.data?.content;
    const ngoDashboardCountsData = ngoDashboardCount?.data?.content?.data;

    if (ngoDashboardCountsData) {
      // This is for NGO Dashboard  data
      let {
        activeDonorsCount,
        campOrgnisedCount,
        donetedSoFarCount,
        nearByDonorsCount,
        repeatDonationsCount,
      } = ngoDashboardCountsData;
      setNgoTileDataa([
        {
          count: nearByDonorsCount,
          title: 'My Donors',
          img: donors,
          btn: 'noClick',
        },
        {
          count: activeDonorsCount,
          title: 'Available for Donation',
          img: available,
          btn: 'noClick',
        },
        {
          count: donetedSoFarCount,
          title: 'Donated so far',
          img: receivedImg,
          btn: 'noClick',
        },
        {
          count: repeatDonationsCount,
          title: 'Repeat Donations',
          img: issuedImg,
          btn: 'noClick',
        },
        {
          count: campOrgnisedCount,
          title: 'Camps Organised',
          img: inventoryImg,
          btn: 'noClick',
        },
      ]);
    }

    if (chartsData?.data && dashboardCountsData?.dashboardData) {
      let { inventory } = chartsData?.data?.inventoryCount;
      let { recievedCount, nearByDonorsCount, issuedCount, activeDonorsCount } =
        dashboardCountsData?.dashboardData;

      setTotalCountData([
        {
          count: nearByDonorsCount,
          title: 'Donors Nearby',
          img: donors,
          btn: 'Donors Nearby',
        },
        {
          count: activeDonorsCount,
          title: 'Available for Donation',
          img: available,
          btn: 'Available for Donation',
        },
        {
          count: recievedCount,
          title: 'Received',
          img: receivedImg,
          btn: 'Received',
        },
        {
          count: issuedCount,
          title: 'Issued',
          img: issuedImg,
          btn: 'Issued',
        },
        {
          count: inventory,
          title: 'Inventory',
          img: inventoryImg,
          btn: 'Inventory',
        },
      ]);
    }
  }

  const { chartsData, donationGraphData } = useSelector(
    (state) => state.dashboardReducer
  );

  function onFilterChange(value) {
    setFilterValue(value);
    chartFilterOnChange(value.value);
  }

  useEffect(() => {
    setTileData();
  }, [chartsData, dashboarTilesCount, ngoDashboardCount]);

  var ngoDashboardGraphData = ngoDashboardCount?.data?.content?.data;

  // reduce is use for findin higest and lowest camp
  function calculateMinMax(data) {
    var max = {};
    var min = {};
    if (data && data.length) {
      max = data.reduce((a, b) => (a.camp > b.camp ? a : b));
      min = data.reduce((a, b) => (a.camp < b.camp ? a : b));
    }
    return {
      max,
      min,
    };
  }

  var data = calculateMinMax(
    institutionType === 'NGO'
      ? ngoDashboardGraphData?.campGrapgh
      : donationGraphData?.data
  );

  function DonationChartView() {
    return (
      <div className='bd-mod bd-mod__donation'>
        {institutionType !== 'NGO' ? (
          <div className='bd-mod__head'>
            <span>Donations Receieved</span>
            <div className='bd-mod__sort--wrap'>
              <label>Show:</label>
              <div className='bd-dorpdown donation-filter'>
                <div className='bd-dorpdown__selected detail-dd bg-dd'>
                  <DropDown
                    options={chartFilter}
                    defaultValue={filterValue}
                    fetchDropDownValue={onFilterChange}
                  />
                  <i className='bludbig-arrow' />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='bd-mod__head'>
            <span>Camp Organised</span>
          </div>
        )}

        <div className='divTable donationIndexSpacing'>
          <div className='divRow'>
            <div className='divCell'>
              <div className='bullet' />
            </div>
            {institutionType === 'NGO' ? (
              <div className='divCell'>Blood Donation Received</div>
            ) : (
              <div className='divCell'>Donations Receieved</div>
            )}
          </div>
        </div>
        <div className='bd-mod__container'>
          <Charts
            chartType='donation-chart'
            chartData={
              institutionType === 'NGO'
                ? ngoDashboardGraphData?.campGrapgh
                : donationGraphData?.data
            }
          />
        </div>
        <div className=' donationIndexSpacing'>
          <div className='d-flex space-around pb-10'>
            <div>
              <div className='mb-5'>Heighest</div>
              <div>
                <span className='fw-600'>
                  {data?.max?.donations || data?.max?.camp}
                </span>
                {data?.max?.date
                  ? ` On ${moment(data?.max?.date).format('DD MMM YYYY')}`
                  : '--'}
              </div>
            </div>
            <div>
              <div className='mb-5'>Lowest</div>
              <div>
                <span className='fw-600'>
                  {data?.min?.donations || data?.max?.camp}
                </span>
                {data?.min?.date
                  ? ` On ${moment(data?.min?.date).format('DD MMM YYYY')}`
                  : '--'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function InventoryChartView() {
    return (
      <div className='bd-mod bd-mod__inventory'>
        <div className='bd-mod__head'>
          {institutionType === 'NGO' ? (
            <span>Donations</span>
          ) : (
            <span>Inventory</span>
          )}
        </div>
        <div className='bd-mod__container'>
          <Charts
            chartType='inventory-chart'
            inventoryChartData={
              institutionType === 'NGO'
                ? ngoDashboardGraphData?.componenetWiseData
                : chartsData?.data
            }
          />
        </div>
      </div>
    );
  }

  function BloodGroupChartView() {
    return (
      <div className='bd-mod bd-mod__inventory'>
        <div className='bd-mod__head'>
          <span>Blood Group</span>
        </div>
        <div className='bd-mod__container'>
          <Charts
            chartType='blood-group-chart'
            inventoryChartData={
              institutionType === 'NGO'
                ? ngoDashboardGraphData?.bloodGroupWiseData
                : inventoryList?.data
            }
          />
        </div>
      </div>
    );
  }

  return (
    <article className='bd-article'>
      <section className='bd-dashboard'>
        <div className=''>
          <Tile
            dataArray={institutionType == 'NGO' ? ngoTileData : totalCountData}
          />
          <div>
            {institutionType === 'Hospital' ? (
              <div className='verifyWrapper mt-20'>
                <VerifyTabContainer />
              </div>
            ) : (
              <Tabs
                style={{ backgroundColor: 'red' }}
                onChange={onTabChange}
                activeTab={activeTab}
              >
                {tabList}
              </Tabs>
            )}
            {/* <div className='bd-tab__content'></div> */}
          </div>
          {institutionType !== 'Hospital' && (
            <div className='bd-dashboard-bottom-grid'>
              <div>
                <InventoryChartView />
              </div>
              <div>
                <DonationChartView />
              </div>
              <div>
                <BloodGroupChartView />
              </div>
            </div>
          )}
          {/* <div className="bd-dashboard__lft">
            <div className="bd-dashboard__header">
              <span className="bd-dashboard__main">{institutionName}</span>
            </div>
            {institutionType === "Hospital" ? (
              <div className="verifyWrapper">
                <VerifyTabContainer />
              </div>
            ) : (
              <Tabs onChange={onTabChange} activeTab={activeTab}>
                {tabList}
              </Tabs>
            )}
          </div> */}
          {/* <div className="bd-dashboard__rgt">
            {institutionType !== "Hospital" && (
              <>
                <Tile dataArray={totalCountData} />
                <DonationChartView />
                <InventoryChartView />
              </>
            )}
          </div> */}
        </div>
      </section>
      {/* {<DonorsNearByContainer/>} */}
    </article>
  );
}
