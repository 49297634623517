import React, { useState } from 'react';
import { CheckBox } from '../../components';
import DropDown from '../../components/RDropDown';

export default function BloodComponentView({
  currBloodGroup,
  bloodComponent,
  bloodComponentList,
  setShowIssueToProcess,
  checkboxClick,
  onProcessClick,
  dropdownList,
  unitValue,
  handleOnChange,
}) {
  const [enteredVolume, setEnteredVolume] = useState(0);
  const [externalAttributes, setExternalAttributes] = useState({
    additives: 0,
    preservatives: 0,
    approxVol: '',
  });
  const [btnDisable, setBtnDisable] = useState(true);

  const totalVolume = unitValue * externalAttributes.approxVol;

  function onSubmitClick(e) {
    e.preventDefault();
    let arr = bloodComponentList.filter((a) => a.isSelected);
    let vol = 0;
    arr.map((a) => {
      if (
        a.component ===
        e.target[a.component.split('').join('')].getAttribute('name')
      ) {
        a.volume = e.target[a.component.split('').join('')].value * 1;
      }
      vol += e.target[a.component.split('').join('')].value * 1;
    });

    setExternalAttributes({
      ...externalAttributes,
      additives: e.target.additives.value * 1,
      preservatives: e.target.preservatives.value * 1,
      approxVol: e.target.approxVol.value * 1,
    });
    setEnteredVolume(vol);
    if (externalAttributes.approxVol === '') {
      setBtnDisable(true);
    } else if (arr.filter((a) => a.isSelected).length == 0 || unitValue === 0) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }

    if (
      (e.target.approxVol.value !== '' &&
        !(arr.filter((a) => a.isSelected).length == 0)) ||
      unitValue === 0
    ) {
      if (vol < totalVolume || vol > totalVolume * 0.9) {
        onProcessClick(vol, totalVolume, externalAttributes);
      }
    }
  }

  function QuantityError() {
    if (enteredVolume > totalVolume && enteredVolume < totalVolume * 0.9) {
      return (
        <div className='volume-error'>
          You cannot exceeds more than {totalVolume} ml.
        </div>
      );
    }
  }

  return (
    <div className='bd-modal-wrap bd-modal-right bd-accept-issue'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Issue to process</span>
          <div className='bd-popup__head--rgt'>
            <div className='bd-blood-type'>
              <span>
                <b>{currBloodGroup}</b>
              </span>
              <span>{bloodComponent}</span>
            </div>
          </div>
        </div>
        <div className=''>
          <label>Quantity</label>
          <div className='bd-dorpdown inventory-dd'>
            <div className='bd-dorpdown__selected'>
              <DropDown
                width={'200px'}
                placeHolderText={'Select Quantity'}
                options={dropdownList}
                fetchDropDownValue={handleOnChange}
              />
              <i className='bludbig-arrow' />
            </div>
          </div>
        </div>

        <form onSubmit={onSubmitClick}>
          <div className='bd-popup__body'>
            <div className='bd-accept-issue__tbl'>
              <div className='bd-tbl bd-tbl__theme1'>
                <div className='bd-tbl__body--scroll bd-issue-process'>
                  <div className='bd-tbl__body'>
                    <div className='bd-form'>
                      <div className='bd-tbl__row'>
                        <div className='bd-tbl__cell'>
                          <span></span>
                        </div>
                        <div className='bd-tbl__cell bd-components'>
                          <span>Components</span>
                        </div>
                        <div className='bd-tbl__cell bd-volume-input'>
                          <span>Volum ml</span>
                        </div>
                      </div>
                      {bloodComponentList.map((record, i) => {
                        return (
                          <div className='bd-tbl__row'>
                            <div className='bd-tbl__cell'>
                              <CheckBox
                                key={'checkbox_' + record.id}
                                id={record.id}
                                checked={record.isSelected}
                                onChange={checkboxClick}
                              />
                            </div>
                            <div className='bd-tbl__cell bd-components'>
                              <span>{record.component}</span>
                            </div>
                            <div className='bd-tbl__cell'>
                              <input
                                className='bd-volume-input'
                                type='number'
                                name={record.component.split('').join('')}
                                placeholder={'Volume'}
                                required={record.isSelected}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className='bd-form'>
                  <div className='bd-form__row'>
                    <div>
                      <span>Additives</span>
                      <input
                        className='bd-volume-input additives'
                        type='number'
                        name='additives'
                        placeholder={'Volume in ml'}
                      />
                    </div>
                  </div>
                  <div className='bd-form__row'>
                    <div>
                      <span>Preservatives</span>
                      <input
                        className='bd-volume-input preservatives'
                        type='number'
                        name='preservatives'
                        placeholder={'Volume in ml'}
                      />
                    </div>
                  </div>
                  <div className='bd-form__row'>
                    <div>
                      <span>
                        <span className='volume-error'>*</span>Approx Vol/Bag
                      </span>
                      <input
                        className='bd-volume-input approxVol'
                        type='number'
                        name='approxVol'
                        placeholder={'Volume in ml'}
                      />
                    </div>
                  </div>
                </div>
                {QuantityError()}
                {btnDisable && (
                  <div className='volume-error'>
                    Please fill all information.
                  </div>
                )}
                <div className='bd-btn__wrap'>
                  <div
                    className='bd-btn bd-btn--outline'
                    onClick={() => setShowIssueToProcess(false)}
                  >
                    <span>Cancel</span>
                  </div>
                  <button
                    type='submit'
                    className='bd-btn bd-btn--primary db-bg__green'
                    // disabled={buttonDisabled}
                  >
                    <span>Process</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
