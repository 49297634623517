import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import {
  BLOOD_GROUP_OPTIONS,
  PATIENT_VIEW_TAB_COLUMNS,
} from '../../utils/constants';
import DropDown from '../../components/RDropDown';

export default function PatientView({ patientsList, headerDropDownChange }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search'>
                <span className='bd-dashboard__main'>Patient</span>
                <div className='bd-dorpdown'>
                  <div className='bd-dorpdown__selected detail-dd bg-dd'>
                    <DropDown
                      placeHolderText={'Blood Group'}
                      options={BLOOD_GROUP_OPTIONS}
                      fetchDropDownValue={headerDropDownChange}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
                <div class='bd-hader'>
                  <Link to={`/add-patient`}>
                    <div class='bd-add'>
                      <i class='bludplus'></i>
                    </div>
                  </Link>
                </div>
              </div>
              <div className='bd-dashboard__lft'>
                <>
                  <div className='table-scroll'>
                    <Tables
                      tableType={'table-type-1'}
                      columns={PATIENT_VIEW_TAB_COLUMNS}
                      tableData={
                        patientsList?.length
                          ? patientsList.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : [].slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                      }
                    />
                  </div>
                  <TablePagination
                    component='div'
                    count={patientsList.length}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              </div>
            </div>
          </div>
        </section>
      </article>
    </>
  );
}
