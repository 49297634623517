import React from 'react';
import { SETTING_HEADER } from '../../utils/constants';

function SettingsHeader(props) {
  let { SettingsJSON, currSelected } = props;
  return (
    <div className='bd-step__nav'>
      {Object.keys(SettingsJSON).map((data, index) => {
        return (
          <div key={index} className={currSelected >= index ? 'active' : ''}>
            <i className='bluddot'></i>
            <span>{SETTING_HEADER[data] || data}</span>
          </div>
        );
      })}
    </div>
  );
}

export default SettingsHeader;
