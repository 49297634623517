import React from 'react';
import { useState } from 'react';
import { validateLabResultArr } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  triggerPostLabResult,
  getLabResults,
} from '../../redux/actions/labResultAction';
import DatePicker from '../DatePicker';

function PopupMultiplePost(props) {
  const body = [
    {
      header: 'Blood Group',
      placeholder: 'A',
      name: 'bloodGroup',
    },
    { header: 'Report no.', placeholder: '12345678', name: 'reportNo' },
    { header: 'BP (mmHg)', placeholder: '00.00', name: 'bloodPressure' },
    { header: 'Weight', placeholder: '00.00', name: 'weight' },
    { header: 'Hb', placeholder: '00.00', name: 'Hb' },
    { header: 'RBC', placeholder: '00.00', name: 'RBC' },
    { header: 'WBC', placeholder: '00.00', name: 'WBC' },
    { header: 'PT', placeholder: '00.00', name: 'PT' },
    { header: 'APTT', placeholder: '00.00', name: 'APTT' },
    { header: 'Platelet', placeholder: '00.00', name: 'Platelet' },
    { header: 'BT', placeholder: '00.00', name: 'BT' },
    { header: 'CT', placeholder: '00.00', name: 'CT' },
    { header: 'ESR', placeholder: '00.00', name: 'ESR' },
    { header: 'TLC', placeholder: '00.00', name: 'TLC' },
    { header: 'Result', placeholder: '', name: 'Result' },
    { header: 'Remark', placeholder: 'Enter', name: 'Remark' },
  ];
  let { acceptedData, onCancelPopup } = props;

  const reqObjArr = [];
  for (let i = 0; i < acceptedData.length; i++) {
    reqObjArr.push({
      bloodGroup: '',
      reportNo: '',
      bloodPressure: '',
      weight: '',
      Hb: '',
      RBC: '',
      WBC: '',
      PT: '',
      APTT: '',
      Platelet: '',
      BT: '',
      CT: '',
      ESR: '',
      TLC: '',
      Result: false,
      Remark: '',
    });
  }
  const { postLabResultObj } = useSelector((state) => state.labResultReducer);
  const dispatch = useDispatch();
  const [resultStatus, setResultStatus] = useState(true);
  const [testedBy, setTestedBy] = useState('');
  const [date, setDate] = useState('');
  const [error, setError] = useState('');
  const [labsObjByIndex, setLabsObjByIndex] = useState(reqObjArr);
  const changeStatus = (index) => {
    let obj = labsObjByIndex;
    obj[index]['Result'] = !obj[index]['Result'];
    setLabsObjByIndex((prvObj) => [...prvObj, ...obj]);
  };

  const onChangeText = (e, index) => {
    let obj = labsObjByIndex;
    obj[index][e.target.name] = e.target.value;
    setLabsObjByIndex(obj);
  };

  const onSubmit = () => {
    let finalArr = [];
    for (let i = 0; i < labsObjByIndex.length; i++) {
      finalArr.push({
        inventoryId: acceptedData[i]?.inventoryId,
        donorId: acceptedData[i]?.donorId,
        bloodGroup: labsObjByIndex[i]?.bloodGroup,
        collectedDate: date,
        labResults: {
          reportNo: labsObjByIndex[i]?.reportNo,
          weight: labsObjByIndex[i]?.weight,
          bloodPressure: labsObjByIndex[i]?.bloodPressure,
          Hb: labsObjByIndex[i]?.Hb,
          laboratory: '',
          testedBy: testedBy,
          addedAt: acceptedData[i]?.sample?.addedAt,
          results: [
            {
              parameter: 'RBC',
              observation: labsObjByIndex[i]?.RBC,
              remarks: '',
            },
            {
              parameter: 'WBC',
              observation: labsObjByIndex[i]?.WBC,
              remarks: '',
            },
            {
              parameter: 'PT',
              observation: labsObjByIndex[i]?.PT,
              remarks: '',
            },
            {
              parameter: 'TLC',
              observation: labsObjByIndex[i]?.TLC,
              remarks: '',
            },
            {
              parameter: 'APTT',
              observation: labsObjByIndex[i]?.APTT,
              remarks: '',
            },
            {
              parameter: 'ESR',
              observation: labsObjByIndex[i]?.ESR,
              remarks: '',
            },
            {
              parameter: 'Platelet',
              observation: labsObjByIndex[i]?.Platelet,
              remarks: '',
            },
          ],
          finalResult: labsObjByIndex[i].Result ? 'Pass' : 'Fail',
          comments: labsObjByIndex[i].Remark,
        },
      });
    }

    if (validateLabResultArr(finalArr, setError)) {
      // setPostError("");
      return dispatch(
        triggerPostLabResult({ labs: finalArr }, () => {
          onCancelPopup();
          dispatch(getLabResults());
        })
      );
    }
  };
  return (
    <div class='bd-modal-wrap bd-modal-right bd-labresults'>
      <div class='bd-popup si-fade-in'>
        <div class='bd-popup__head'>
          <span>Lab Results</span>
          <i class='bludclose' onClick={() => onCancelPopup()}></i>
        </div>
        <div class='bd-popup__body'>
          <div class='bd-form__row bd-txtby__wrp'>
            <div class='bd-txtby'>
              <span>Tested by</span>
              <input
                type='text'
                name='testedBy'
                onChange={(e) => {
                  setTestedBy(e.target.value);
                }}
              />
            </div>
            <div class='bd-calender'>
              <label></label>
              <DatePicker
                getSelectedDate={(date) => {
                  setDate(date);
                }}
              />
              {/* <input
                type="text"
                placeholder="Report Date"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              /> */}
            </div>
          </div>
          <div class='bd-labresults__tbl'>
            <div class='bd-tab__content'>
              <div class='bd-tbl'>
                <div class='bd-tbl__head'>
                  <div class='bd-tbl__row'>
                    {body.map((data) => {
                      return (
                        <div class='bd-tbl__cell'>
                          <span>{data.header}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div class='bd-tbl__body'>
                  {acceptedData?.map((d, i) => {
                    return (
                      <div class='bd-tbl__row'>
                        {body.map((data, index) => {
                          let setValue = '';
                          // if (index === 0) {
                          //   setValue = d._id;
                          //   return (
                          //     <div class="bd-tbl__cell">
                          //       <input
                          //         value={d?.sample?.sampleId}
                          //         type="text"
                          //         placeholder={data.placeholder}
                          //         name={data.name}
                          //       />
                          //     </div>
                          //   );
                          // }
                          return (
                            <div class='bd-tbl__cell'>
                              {data.name == 'Result' ? (
                                <span>
                                  <i
                                    onClick={() => changeStatus(i)}
                                    class={
                                      labsObjByIndex[i]?.Result
                                        ? 'bd-result-icon'
                                        : 'bd-result-icon bd-red__color'
                                    }
                                  ></i>
                                </span>
                              ) : (
                                <input
                                  type='text'
                                  placeholder={data.placeholder}
                                  name={data.name}
                                  onChange={(e) => onChangeText(e, i)}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div class='bd-btn__wrap'>
              <div class='bd-btn bd-btn--outline'>
                <span onClick={() => onCancelPopup()}>Decline</span>
              </div>
              <div class='bd-btn bd-btn--primary db-bg__green'>
                <span onClick={() => onSubmit()}>Approve</span>
              </div>
            </div>
            <div className='bd-form__row bd-form__row--forgotpass'>
              <span className='bd-incorrect__msg'>
                {error
                  ? error
                  : postLabResultObj && postLabResultObj.error
                  ? postLabResultObj.error
                  : ''}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class='bd-popup__lightbox'></div>
    </div>
  );
}

export default PopupMultiplePost;
