import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { titleCase } from '../../utils/constants';

export default function VerifyTabView({ onViewClick }) {
  const { requestVerifyObj } = useSelector((state) => state.dashboardReducer);
  return (
    <React.Fragment>
      <div className='bd-tbl bd-tbl__theme1 bd-tbl__theme1-new'>
        {requestVerifyObj && requestVerifyObj?.data?.length > 0 && (
          <div className='bd-tbl__head'>
            <div className='bd-tbl__row'>
              <div className='bd-tbl__cell'>Name</div>
              <div className='bd-tbl__cell  '>Blood Group</div>
              <div className='bd-tbl__cell text-center'>Component</div>
              <div className='bd-tbl__cell '>Request Date</div>
              <div className='bd-tbl__cell'>Condition</div>
              <div className='bd-tbl__cell'>Required By</div>
            </div>
          </div>
        )}
        {requestVerifyObj && requestVerifyObj?.data?.length > 0 ? (
          requestVerifyObj?.data
            .map((obj, i) => {
              return (
                <div className='bd-tbl__row'>
                  <div className='bd-tbl__cell name-cell' id={obj._id}>
                    {titleCase(obj.details.firstName) +
                      ' ' +
                      titleCase(obj.details.lastName)}
                  </div>
                  <div className='bd-tbl__cell  text-center' id={obj._id}>
                    {obj.bloodGroup}
                  </div>
                  <div className='bd-tbl__cell text-center' id={obj._id}>
                    {obj.component}
                  </div>

                  <div className='bd-tbl__cell' id={obj._id}>
                    {moment(obj?.requestDate).format('DD MMM YYYY')}
                  </div>
                  <div className='bd-tbl__cell' id={obj._id}>
                    {obj.condition}
                  </div>
                  <div className='bd-tbl__cell' id={obj._id}>
                    {moment(obj?.requiredBy).format('DD MMM YYYY')}
                  </div>

                  <div className='bd-tbl__cell '>
                    <div class='bd-request-otp' id={obj._id}>
                      <div
                        className='bd-request-accept-blur bd-request-otp-stars  brder_Radius'
                        onClick={(e) => onViewClick(e, obj._id)}
                        id={obj._id}
                      >
                        <span
                          id={obj._id}
                          onClick={(e) => onViewClick(e, obj._id)}
                        >
                          <i id={obj._id} className='bludaccept'></i>
                        </span>
                      </div>
                      <div
                        onClick={(e) => onViewClick(e, obj._id)}
                        id={obj._id}
                        className=' bd-request-accept bd-black active'
                      >
                        <span id={obj._id}>View</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            .filter((a, i) => i < 3)
        ) : (
          <div className='bd-tbl__body--scroll noData_center'>
            <div className='bd-tbl__body'>
              <div className='bd-noDonationData'>
                <span>No Donors scheduled </span>
                <span>for donation </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
