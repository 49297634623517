import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from '../../components';
import ReactDatePicker from 'react-datepicker';
import BedInfo from '../../components/Popups/BedInfo';
import moment from 'moment';

export default function CreateCampView({
  onCancelClick,
  onSubmit,
  onChangeText,
  addCampsObj,
  onAddressSearch,
  selectAddress,
  addressFocused,
  setaddressFocused,
  hiddenFileInput,
  addFile,
  deleteAttachment,
}) {
  const { addressList, camp } = useSelector((state) => state.campsReducer);

  var today = new Date();
  const {
    campName,
    SponsoredBy,
    organiser,
    noOfBeds,
    targetUnits,
    date,
    fromTime,
    toTime,
    SponsorCoordinator,
    SponsoredMobileNo,
    SponsoredEmailId,
    bloodBankCoordinator,
    bloodBankedMobileNo,
    bloodBankedEmailId,
    venue,
    address,
    // id,
    attachments,
  } = addCampsObj;

  return (
    <div className='bd-modal-wrap bd-modal-right bd-createcamp'>
      <div className='bd-popup si-fade-in'>
        <div className='camp-popup-inside'>
          <BedInfo />
          <div style={{ paddingLeft: '10px' }}>
            <div className='bd-popup__head'>
              <span>Create Blood Donation Camp</span>
              <i
                onClick={onCancelClick}
                className='bludclose'
                style={{ marginTop: '6px' }}
              />
            </div>
            <div className='bd-popup__body'>
              <div
                className='bd-form'
                style={{ height: '60vh', overflow: 'auto' }}
              >
                <div className='bd-form__row'>
                  <label>Name of the Camp</label>
                  <input
                    type='text'
                    name='campName'
                    value={campName}
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
                <div className='bd-form__row'>
                  <label>Sponsored by</label>
                  <input
                    type='text'
                    name='SponsoredBy'
                    value={SponsoredBy}
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
                <div className='bd-form__row'>
                  <label>Organiser</label>
                  <input
                    type='text'
                    name='organiser'
                    value={organiser}
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
                <div className='bd-form__row bd-twocol'>
                  <div>
                    <label>No. of beds</label>
                    <input
                      type='text'
                      name='noOfBeds'
                      value={noOfBeds}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                  <div>
                    <label>Target Units</label>
                    <input
                      type='text'
                      name='targetUnits'
                      value={targetUnits}
                      onChange={(e) => onChangeText(e)}
                    />
                    {targetUnits && targetUnits < 100 && (
                      <p className='bd_error ml-10 mb-10'>
                        Plan minimum 100 units
                      </p>
                    )}
                  </div>
                </div>

                <div className='bd-form__row bd-twocol'>
                  <div>
                    <label>Date</label>
                    <div style={{ marginTop: '10px' }}>
                      <ReactDatePicker
                        selected={date}
                        onChange={(date) => onChangeText(date, 'date')}
                        dateFormat='dd/MM/yyyy'
                      />
                    </div>
                    {date &&
                      moment(date).format('L') < moment(today).format('L') && (
                        <p className='bd_error'>
                          select date today/after today!
                        </p>
                      )}
                  </div>
                  <div className='bd_createcamp_time'>
                    <div className='d-flex '>
                      <div>
                        <label>From</label>
                        <input
                          placeholder='Time'
                          onChange={(e) => onChangeText(e)}
                          value={fromTime}
                          type='time'
                          name='fromTime'
                        />
                      </div>

                      <div style={{ marginLeft: '10px' }}>
                        <label>To</label>
                        <input
                          placeholder='Time'
                          onChange={(e) => onChangeText(e)}
                          value={toTime}
                          type='time'
                          name='toTime'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bd-form__row'>
                  <label>Sponsor’s Coordinator</label>
                  <input
                    type='text'
                    name='SponsorCoordinator'
                    value={SponsorCoordinator}
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
                <div className='bd-form__row bd-twocol'>
                  <div>
                    <label>Sponsor’s Mobile Number</label>
                    <input
                      type='number'
                      name='SponsoredMobileNo'
                      value={SponsoredMobileNo}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                  <div>
                    <label>Sponsor’s Email ID</label>
                    <input
                      type='text'
                      name='SponsoredEmailId'
                      value={SponsoredEmailId}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>
                <div className='bd-form__row'>
                  <label>Venue</label>
                  <input
                    type='text'
                    name='venue'
                    value={venue}
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
                <div className='bd-form__row'>
                  <label>Address</label>
                  <input
                    type='text'
                    name='address'
                    value={address?.description}
                    onChange={(e) => onAddressSearch(e)}
                    onFocus={() => setaddressFocused(true)}
                  />
                  {addressFocused &&
                  // addressList?.data &&
                  addressList?.data?.length ? (
                    <div
                      style={{
                        width: '100%',
                        height: 200,
                        // backgroundColor: "transparent",
                        border: '1px solid #eee',
                        zIndex: 100,
                        overflow: 'auto',
                      }}
                    >
                      {addressList?.data.map((data, i) => {
                        return (
                          <div
                            className='cursor-pointer'
                            style={{ padding: '10px' }}
                            key={i}
                            onClick={() => {
                              selectAddress(data);
                            }}
                          >
                            <div>{data?.address?.description},</div>
                            <div
                              onClick={() => {
                                selectAddress(data);
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className='bd-form__row'>
                  <label>Internal Coordinator</label>
                  <input
                    type='text'
                    name='bloodBankCoordinator'
                    value={bloodBankCoordinator}
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
                <div className='bd-form__row bd-twocol'>
                  <div>
                    <label> Mobile Number</label>
                    <input
                      type='number'
                      name='bloodBankedMobileNo'
                      value={bloodBankedMobileNo}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                  <div>
                    <label>Email ID</label>
                    <input
                      type='text'
                      name='bloodBankedEmailId'
                      value={bloodBankedEmailId}
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>
              </div>

              <input
                type='file'
                name='attachments'
                id='upload'
                hidden
                ref={hiddenFileInput}
                onChange={addFile}
              />
              <div
                className='bd-btn bd-btn--outline'
                for='upload'
                onClick={() => hiddenFileInput.current.click()}
              >
                <span for='upload'>
                  <i class='icon-clip-icon mr-10'></i>
                  Attach Files
                </span>
              </div>
              <div className='bd-attachment__file'>
                {attachments &&
                  attachments.map((attachment, i) => {
                    return (
                      <a
                        className='download-a'
                        download
                        href={attachment.location}
                      >
                        <div className='attachment-name'>{attachment.name}</div>
                        <i
                          className='bluddelete paddingTop_3'
                          onClick={(e) => deleteAttachment(e, i)}
                        />
                      </a>
                    );
                  })}
              </div>
              {/* {targetUnits && targetUnits < 100 ? (
                <p className='bd_error ml-10 mb-10'>
                  Plan for minimum 100 units & maximize this oppotunity
                </p>
              ) : (
                <p></p>
              )} */}
              <div className='bd-btn__wrap'>
                <div
                  className='bd-btn bd-btn--primary'
                  onClick={() => onSubmit()}
                  // disabled={camp.loading}
                >
                  <div className='d-flex align-items-center justify-content-center'>
                    {camp.loading ?? (
                      <div>
                        <Spinner />
                      </div>
                    )}
                    <div className='ml-5'> Create Camp</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
