import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getRequestData,
  getAcceptRequest,
  issueRequest,
  assignBtnClick,
} from '../../redux/actions/dashboardAction';
import { sorting } from '../../utils';
import {
  ACCEPT_REQUEST,
  GET_DASHBOARD_INVENTORYLIST,
  INVENTORY_CHANGE_STATUS,
} from '../../redux/actions/actionTypes';
import RequestViewAll from '../../views/DashboardView/RequestViewAll';
import { BLOOD_COMPONENTS } from '../../utils/constants';

export default function RequestViewAllContainer() {
  const dispatch = useDispatch();
  const {
    userRequestObj,
    acceptList,
    inventoryList,
    inventoryChangeStatus,
    ngoDashboardAcceptBtn, // NGO All Dashboard Request TAb Acceptance  Data
  } = useSelector((state) => state.dashboardReducer);

  const { loginObj } = useSelector((state) => state.loginReducer);
  let institutionType = loginObj?.data?.content?.data?.institutionType ?? '';

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [requestTableData, setRequestTableData] = useState([]);
  const [showAcceptPopup, setShowAcceptPopup] = useState({
    show: false,
    id: '',
  });
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onAcceptClick(e) {
    let params = {
      type: 'request',
      id: e.target.id,
    };
    dispatch(getAcceptRequest(params));
    setShowAcceptPopup({
      show: true,
      id: e.target.id,
    });
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...requestTableData];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setRequestTableData(data);
    setCount(count + 1);
  }

  function ButtonAccept(props) {
    return (
      <div class='bd-request-otp' id={props.id}>
        <div
          className='bd-request-accept-blur brder_Radius bd-request-otp-stars'
          onClick={props.onClick}
          id={props.id}
        >
          <span onClick={props.onClick} id={props.id}>
            <i id={props._id} className='bludaccept'></i>
          </span>
        </div>
        <div
          onClick={props.onClick}
          id={props.id}
          className=' bd-request-accept bd-black active'
        >
          <span id={props.id}>Accept </span>
        </div>
      </div>
    );
  }
  function ButtonDisable() {
    return (
      <div class='bd-request-otp cursor_notAllow '>
        <div className='bd-request-dul pt-10 brder_Radius bd-request-otp-stars'>
          <span>
            <i className='bludaccept'></i>
          </span>
        </div>
        <div className='bd-request-dul bd-black active'>
          <span>Accept </span>
        </div>
      </div>
    );
  }

  function onIssueClick({ inventoryList, unit, id, deliveryType }) {
    let obj = {
      id: id,
      status: 3,
      issueType: 'patient',
      issuedUnits: unit,
      inventoryIds: inventoryList.filter((a) => a.isSelected).map((a) => a._id),
      deliveryType: deliveryType,
    };
    dispatch(
      issueRequest(obj, () => {
        dispatch(getRequestData());
      })
    );
    setShowAcceptPopup({ show: false });
  }

  function onCancelClick(e) {
    setShowAcceptPopup({ show: false });
  }

  function headerDropDownChange(e) {
    let data = setData(userRequestObj);
    if (e.value === 'All/Any') {
      data = [...setData(userRequestObj)];
    } else {
      data = data.filter((a) => a.bloodGroup === e.value);
    }
    setRequestTableData(data);
  }

  function setData(userRequestObj) {
    let data = [];
    userRequestObj.data &&
      userRequestObj.data.forEach((record, i) => {
        record.calUnits = 0;
        if (record.bulkRequest) {
          for (let bulk in record.bulkRequest) {
            record.bulkRequest[bulk].map((ele) => {
              record.calUnits += ele.units - ele.recieved;
            });
          }
        } else {
          record.calUnits += record.units - record.unitsReceived;
        }
        // if (record.details === undefined) return 0;
        // if (record.details )
        // let instituionName = record?.details?.instituionName ?? "";
        let fname = record?.details?.firstName
          ? record?.details?.firstName
          : record?.bloodBankName || record?.hospitalName;
        let lname = record?.details?.lastName ?? '';
        let obj = {
          name: fname + ' ' + lname,
          bloodGroup: record.bloodGroup || 'Bulk',
          units: record.calUnits,
          component:
            BLOOD_COMPONENTS[record.component] ||
            record.component ||
            'Bulk requirment',
          condition: record.condition ? record.condition : '-',
          hospital: record?.hospitalName ? record?.hospitalName : '-',
          requiredBy: moment(record.requiredBy).format('DD MMM YYYY'),
          accept:
            record?.subType === 'Bulk' && institutionType === 'NGO' ? (
              <ButtonDisable />
            ) : (
              <ButtonAccept id={record._id} onClick={onAcceptClick} />
            ),
        };
        data.push(obj);
      });
    return data;
  }

  function setList() {
    let arr = [];
    userRequestObj?.data
      .map((a) => {
        // if(a.details) {
        let fname = a?.details?.firstName ?? '';
        let lname = a?.details?.lastName ?? '';
        let bname = a?.bloodBankName ?? '';
        let obj = {
          id: a._id,
          patientId: a.patientId,
          name:
            a.requestType === 'Individual'
              ? `${fname} ${lname} [${a.patientId}] ${a?.hospitalLocation?.description}`
              : `${bname}`,
        };
        arr.push(obj);
        // }
      })
      .filter((a, i) => i < 15);
    return arr;
  }

  function dropDownChange(e) {
    let id = e.value;
    setSelectedUser(e.value);
    let params = {
      type: 'request',
      id: userRequestObj.data.filter((a) => id === a._id)[0]._id,
    };
    dispatch(getAcceptRequest(params));
  }

  function reset() {
    dispatch({ type: INVENTORY_CHANGE_STATUS, loading: false });
    dispatch({ type: ACCEPT_REQUEST, loading: false });
    dispatch({ type: GET_DASHBOARD_INVENTORYLIST, loading: false });
  }

  function escFunction(e) {
    if (e.keyCode === 27) {
      setShowAcceptPopup({ show: false });
    }
  }

  function onAssignClick({ donorsList, Date, requestId }) {
    let obj = {
      id: requestId,
      donors: donorsList,
      donationDate: Date,
      type: 'request',
    };
    dispatch(
      assignBtnClick(obj, () => {
        dispatch(getRequestData());
        setShowSuccess(true);
      })
    );
    setShowAcceptPopup({ show: false });
  }

  // const ngoProps = {
  //   // title: 'Accept & Assign',
  //   // buttonName: 'Assign/Depute',
  //   // data: ngoDashboardAcceptBtn?.data?.content?.data,
  //   selectedUser: selectedUser,
  //   userList: setList(),
  //   showDetail: true,
  //   defaultUnits: userRequestObj?.data?.filter(
  //     (a) => a._id === showAcceptPopup.id
  //   )[0]?.units,
  //   defaultUser: setList().filter((a) => a.id === showAcceptPopup.id)[0],
  //   onButtonClick: onAssignClick,
  //   onCancelClick: onCancelClick,
  //   dropDownChange: dropDownChange,
  // };

  useEffect(() => {
    dispatch(getRequestData());
    document.addEventListener('keydown', escFunction, false);
  }, []);

  useEffect(() => {
    if (userRequestObj) {
      setRequestTableData(setData(userRequestObj));
    }
  }, [userRequestObj]);

  useEffect(() => {
    if (userRequestObj.loading || acceptList.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userRequestObj, acceptList]);

  useEffect(() => {
    if (
      userRequestObj.loading ||
      inventoryList.loading ||
      acceptList.loading ||
      inventoryChangeStatus.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userRequestObj, inventoryList, acceptList, inventoryChangeStatus]);
  useEffect(() => {
    if (
      inventoryChangeStatus.data !== null ||
      (inventoryChangeStatus?.data && inventoryChangeStatus?.data?.length > 0)
    ) {
      dispatch({
        type: INVENTORY_CHANGE_STATUS,
        loading: false,
      });
      setShowSuccess(true);
    } else if (inventoryChangeStatus.error !== null) {
      setFail(true);
    } else {
      setFail(false);
    }
  }, [inventoryChangeStatus]);

  let units = userRequestObj?.data?.filter(
    (a) => a._id === showAcceptPopup.id
  )[0]?.units;

  return (
    <RequestViewAll
      institutionType={institutionType}
      // ngo Dashboard Data for starts
      ngoData={ngoDashboardAcceptBtn?.data?.content?.data}
      onAssignClick={onAssignClick}
      // finish
      page={page}
      rowsPerPage={rowsPerPage}
      requestTableData={requestTableData}
      acceptList={acceptList}
      showAcceptPopup={showAcceptPopup}
      showSuccess={showSuccess}
      showFail={showFail}
      loading={loading}
      inventoryChangeStatus={inventoryChangeStatus}
      selectedUser={selectedUser}
      units={units}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      onHeaderClick={onHeaderClick}
      onIssueClick={onIssueClick}
      onCancelClick={onCancelClick}
      headerDropDownChange={headerDropDownChange}
      setShowSuccess={setShowSuccess}
      setFail={setFail}
      userList={setList()}
      dropDownChange={dropDownChange}
      reset={reset}
    />
  );
}
