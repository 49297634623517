import React from "react";
import no_data_found from "../../assets/icon/no_data_found.svg";
function NoDataFound() {
  return (
    <div className="bd-nodata">
        <span>
          <img src={no_data_found} className="no-data-found-img" />
        </span>
    </div>
  );
}

export { NoDataFound };
