import React, { useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';

import PopupLabs from '../../components/LabResults/Popup';
import PopupMultiplePost from '../../components/LabResults/PopupMultiplePost';

function LabSamplesView(props) {
  let {
    labCriteriaChangeObj,
    getLabResultSamples,
    headerArray,
    onSingleAccept,
    onCheckClick,
    chheckboxIds,
    showAddPopup,
    onAcceptAll,
    showMultipleAddPopup,
    acceptedData,
    onCancelPopup,

    singleObj,
    showAddResultPopup,
    onChangeText,
    onSubmit,
    setShowResultOption,
    showResultOption,
    onResultSelect,
    result,
    onLaboratorySelect,
    setShowLabOption,
    laboratory,
    showLabOption,
    resultsArray,
    onResultArrEdit,
    postError,
    postLabResultObj,
    getSelectedDate,
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft' style={{ paddingTop: '10px' }}>
              <div className='bd-dashboard__header'>
                <span className='bd-dashboard__main'>Samples received</span>
                {/* <div className="bd-hader">
                <div className="bd-search">
                  <input type="text" name="" />
                  <i className="bludsearch"></i>
                </div>
                <div className="bd-add">
                  <i className="bludplus"></i>
                </div>
              </div> */}
              </div>
              <div className='bd-samp__recived'>
                <div className='table-scroll'>
                  <div className='bd-tbl bd-tbl__theme1'>
                    <div className='bd-tbl__head'>
                      <div className='bd-tbl__row'>
                        {headerArray.map((data, i) => {
                          return (
                            <div className='bd-tbl__cell' key={i}>
                              <span>{data}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {getLabResultSamples?.data?.content?.data.length === 0 ? (
                      <div className='align-center-no-data'>
                        <div className='bd-tbl__body'>
                          <div className='bd-noSampledata'>
                            <span>No samples received</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='bd-tbl__body'>
                        {getLabResultSamples?.data?.content?.data
                          ?.map((data, index) => {
                            let date = new Date(data.collectionDate)
                              .toDateString()
                              .split(' ');

                            let date2 = new Date(data?.sample?.addedAt)
                              .toDateString()
                              .split(' ');
                            return (
                              <>
                                <div className='bd-tbl__row'>
                                  <div className='bd-tbl__cell'>
                                    <span className='bd-checkbox'>
                                      <input
                                        type='checkbox'
                                        onChange={() => onCheckClick(data?._id)}
                                      />
                                      <b></b>
                                    </span>
                                  </div>
                                  <div className='bd-tbl__cell'>
                                    <span>{data?.sample?.sampleId_1}</span>
                                  </div>
                                  <div className='bd-tbl__cell'>
                                    <span>{data?.donorId}</span>
                                  </div>
                                  <div className='bd-tbl__cell'>
                                    <span>
                                      {date[2]} {date[1]} {date[3]}
                                    </span>
                                  </div>
                                  <div className='bd-tbl__cell'>
                                    <span>{data?.bagId}</span>
                                  </div>
                                  <div className='bd-tbl__cell'>
                                    <span>
                                      {date2[2]} {date2[1]} {date2[3]}
                                    </span>
                                  </div>
                                  <div className='bd-tbl__cell'>
                                    <span>
                                      {data?.sample?.location?.description}
                                    </span>
                                  </div>
                                  <div className='bd-tbl__cell'>
                                    <div
                                      className={
                                        data?.status === 3
                                          ? 'bd-btn bd-btn--primary'
                                          : 'bd-btn bd-btn--primary db-bg__blue'
                                      }
                                      onClick={() => onSingleAccept(data)}
                                    >
                                      <span>
                                        {data?.status === 3
                                          ? 'Accept'
                                          : 'Enter Result'}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )}
                      </div>
                    )}
                  </div>
                </div>
                <TablePagination
                  component='div'
                  count={getLabResultSamples?.data?.content?.data.length ?? 0}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                {chheckboxIds && chheckboxIds.length ? (
                  <div className='bd-btn__wrap'>
                    <div className='bd-btn bd-btn--outline'>
                      <span>Decline</span>
                    </div>
                    <div
                      className='bd-btn bd-btn--primary db-bg__green'
                      onClick={() => onAcceptAll()}
                    >
                      <span>Accept</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </article>
      {showAddPopup && (
        <PopupLabs
          showAddResultPopup={showAddResultPopup}
          onChangeText={onChangeText}
          onSubmit={onSubmit}
          setShowResultOption={setShowResultOption}
          showResultOption={showResultOption}
          onResultSelect={onResultSelect}
          result={result}
          onLaboratorySelect={onLaboratorySelect}
          setShowLabOption={setShowLabOption}
          laboratory={laboratory}
          showLabOption={showLabOption}
          resultsArray={resultsArray}
          onResultArrEdit={onResultArrEdit}
          postError={postError}
          postLabResultObj={postLabResultObj}
          singleObj={singleObj}
          getSelectedDate={getSelectedDate}
        />
      )}
      {showMultipleAddPopup && (
        <PopupMultiplePost
          acceptedData={acceptedData}
          onCancelPopup={onCancelPopup}
        />
      )}
    </>
  );
}

export default LabSamplesView;
