import React from 'react';
import BludUnitView from './BludUnitView';
import SubscriptionView from './SubscriptionView';

function SuperAdminTransactionsView({
  search,
  setSearch,
  activeTab,
  onTabChange,
  bludUnitSortedList,
  onHeaderClick,
}) {
  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search bd-inventory-header-custom'>
                <span className='bd-dashboard__main'>Transactions</span>
                <div className='bd-tab bd-tab-new width'>
                  <div
                    id='Subscription'
                    className={activeTab === 'Subscription' && 'active'}
                    onClick={onTabChange}
                    activetab={activeTab}
                  >
                    <span id='Subscription' style={{ padding: '0 10px' }}>
                      Subscription
                    </span>
                  </div>
                  <div
                    id='BludUnit'
                    className={activeTab === 'BludUnit' && 'active'}
                    onClick={onTabChange}
                    activetab={activeTab}
                  >
                    <span id='BludUnit' style={{ padding: '0 10px' }}>
                      Blood Unit
                    </span>
                  </div>
                </div>
                <div className='bd-hader bd-hader-custom'>
                  <div className='bd-hader'>
                    {activeTab === 'Subscription' ? (
                      <input
                        type='text'
                        // value={SubscriptionSearch}
                        // onChange={(e) => {
                        //   setPendingSearch(e.target.value);
                        // }}
                        placeholder='Search'
                        style={{ padding: 5, marginRight: 10 }}
                      />
                    ) : (
                      <input
                        type='text'
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        placeholder='Search'
                        style={{ padding: 5, marginRight: 10 }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='table-scroll'>
            {activeTab === 'Subscription' ? (
              <SubscriptionView />
            ) : (
              <BludUnitView
                bludUnitSortedList={bludUnitSortedList}
                onHeaderClick={onHeaderClick}
              />
            )}
          </div>
        </section>
      </article>
    </>
  );
}

export default SuperAdminTransactionsView;
