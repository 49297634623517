import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import { TRANSACTION_SUB_TABLE } from '../../utils/constants';

function SubscriptionView() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  function handleChangePage(newPage) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  let SaticArr = [
    {
      regId: 'IN-AB011',
      institution: 'Demo Shivaji Hospital',
      type: 'Both',
      emailId: 'mail@mial.com',
      mobileNo: '5006984522',
      subscription: '108/1000',
      lastSubcribe: ' 17 Jan 2021',
      transactionId: '1643099599415',
    },
    {
      regId: 'IN-AB012',
      institution: 'Apollo  Hospital',
      type: 'Both',
      emailId: 'tabijop830@yks247.com',
      mobileNo: '5006984522',
      subscription: '156/1000',
      lastSubcribe: ' 20 Dec 2021',
      transactionId: '9768099599414',
    },
    {
      regId: 'IN-AB013',
      institution: 'Apple Hospital',
      type: 'Both',
      emailId: 'coydenemli@vusra.com',
      mobileNo: '5006984522',
      subscription: '198/1000',
      lastSubcribe: ' 18 feb 2021',
      transactionId: '1685299599481',
    },
  ];
  return (
    <>
      <div className='bd-tbl__inventory table-scroll'>
        <Tables
          tab={''}
          tableType={'table-type-1'}
          columns={TRANSACTION_SUB_TABLE}
          tableData={SaticArr.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          //   headerClick={onHeaderClick}
        />
      </div>
      <TablePagination
        component='div'
        count={SaticArr.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

export default SubscriptionView;
