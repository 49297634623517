import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import plus from '../../assets/images/plus.png';
import { SUBACRIPTION_PLANS_COLUMNS } from '../../utils/constants';
import { useHistory } from 'react-router';

function SuperAdminSubscriptionView({
  search,
  setSearch,
  SubcriptionDataList,
  onHeaderClick,
  setAddPlans,
  addPlans,
  permissions,
}) {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search'>
                <span className='bd-dashboard__main'>
                  Subscription Plans (INDIA)
                </span>
                <div className='bd-hader'>
                  <input
                    type='text'
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder='Search'
                    style={{ padding: 5, marginRight: 10 }}
                  />
                </div>
                {permissions?.write && (
                  <div className='d-flex align-items-center fw-600 cursor-pointer'>
                    <span className='bd_error'>Add Plan</span>
                    <img
                      src={plus}
                      height='25px'
                      className='cursor-pointer'
                      style={{ marginLeft: '5px' }}
                      alt='Add Btn'
                      // onClick={() => history.push('/Super-Admin/Add_Admin')}
                      onClick={() => setAddPlans(!addPlans)}
                    />
                  </div>
                )}
              </div>
              <div className='table-scroll'>
                <Tables
                  tab={'Users'}
                  tableType={'table-type-1'}
                  columns={SUBACRIPTION_PLANS_COLUMNS}
                  tableData={SubcriptionDataList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  headerClick={onHeaderClick}
                />
              </div>
              <TablePagination
                component='div'
                count={SubcriptionDataList.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </section>
      </article>
      {/* {addPlans && } */}
    </>
  );
}

export default SuperAdminSubscriptionView;
