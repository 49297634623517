import { combineReducers } from 'redux';
import addDonorReducer from './addDonorReducer';
import authReducer from './authReducer';
import campsReducer from './campsReducer';
import dashboardReducer from './dashboardReducer';
import peopleReducer from './peopleReducer';
import inventoryReducer from './inventoryReducer';
import addPatientReducer from './addPatientReducer';
import settingReducer from './settingReducer';
import labResultReducer from './labResultReducer';
import profileReducer from './profileReducer';
import loginReducer from './loginReducer';
import commonReducer from './commonReducer';
import chatReducer from './chatReducer';
import superAdminAdministratoReducer from './superAdminAdministratoReducer';
import superAdminCommonReducers from './superAdminCommonReducers';

const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  campsReducer,
  addDonorReducer,
  inventoryReducer,
  peopleReducer,
  addPatientReducer,
  settingReducer,
  labResultReducer,
  profileReducer,
  loginReducer,
  commonReducer,
  chatReducer,
  superAdminAdministratoReducer,
  superAdminCommonReducers,
});

export default rootReducer;
