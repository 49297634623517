import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessPopup, FailPopup } from '../../components';
import {
  GET_PROFILE,
  DONATION_BY_DONORID,
  DONATION_HISTORY,
  DONOR_LAB_RESULT,
  MEDICAL_HISTORY,
  GET_DONOR_OTP,
} from '../../redux/actions/actionTypes';
import DonationForm from '../../views/DashboardView/DonationForm';
import { donationByDonorId } from '../../redux/actions/dashboardAction';
// import { emailPattern } from '../../utils';
const FIELDS = [
  'mobileNo',
  'height',
  'weight_1',
  'hb',
  'bp',
  'startTime',
  'endTime',
  'volume',
  // 'weight_1',
  'weight_2',
  'temperature',
  'kitNumber',
  'unitId',
  'sampleId_1',
  'sampleId_2',
  'sampleId_3',
  'doctor',
  'phlebotomist',
  'sampleToBeTested',
  'bloodBagStoredAt',
  'dataPostBy',
  'aadharNo',
  'confirmAadharNo',
  'emailId',
];

export default function DonationTabContainer(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const donorType = props?.location?.donorType ?? '';
  const { donarProfile, donationObj, donationByDonor } = useSelector(
    (state) => state.dashboardReducer
  );
  const { donorObject } = useSelector((state) => state.addDonorReducer);

  const [donorInfo, setDonorInfo] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gender: '',
    bmi: '',
  });

  const [disableApprove, setDisableApprove] = useState(true);
  const [selectedRadio, setCurrentRadioSelect] = useState({
    radio1: 'yes',
    radio2: '',
    radio3: '',
    radio4: '',
    radio5: '',
    radio6: '',
    radio7: '',
    radio8: 'yes',
    radio9: '',
  });
  const [selectedCheckbox, setSelectedCheckbox] = useState({
    checkbox_1: true,
    checkbox_2: true,
    checkbox_3: true,
    checkbox_4: true,
    checkbox_5: true,
    checkbox_6: true,
  });
  const [formValues, setFormValues] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);
  // const [ErrrorMsg, setErrrorMsg] = useState(false);
  const [errorFields, setErrorFields] = useState({ ...FIELDS });
  function onFormChange(params) {
    setFormValues(params);
  }
  function onCancelClick() {
    dispatch({ type: DONATION_BY_DONORID, loading: false });
    dispatch({ type: DONOR_LAB_RESULT, loading: false });
    dispatch({ type: DONATION_HISTORY, loading: false });
    dispatch({ type: MEDICAL_HISTORY, loading: false });
    dispatch({ type: GET_PROFILE, loading: false });
    dispatch({ type: GET_DONOR_OTP, loading: false });
    history.push('/');
  }
  function onApproveClick(e) {
    e.preventDefault();
    let temp = {};
    FIELDS.map((a) => {
      temp[a] = e.target[a].value === '';
    });
    setErrorFields(temp);

    if (disableApprove) {
      return 0;
    }
    let requestId =
      donorType === ''
        ? donationObj?.data?.filter(
            (a) => a.donorId === donarProfile.data.donorId
          )[0]?.requestId
        : '';
    let obj = {
      donorId:
        donorType === ''
          ? donarProfile.data.donorId
          : donorObject?.data?.content?.donors[0]._id,
      status: 'Approve',
      requestId: requestId,
      donorType: donorType,
      sample: {
        ...formValues.donorInfo,
        addedAt: new Date(),
        // component: 'Whole Blood',
        component:
          formValues.radioValue['radio1'] === 'blood'
            ? 'Whole Blood'
            : 'Platelets',
        fitToDonatePlatelet: formValues.radioValue['radio2'],
        comfortableDuringProcess: formValues.radioValue['radio3'],
        clottingIssuesDuringAfterProcess: formValues.radioValue['radio4'],
        issuePostDonationRecovery: formValues.radioValue['radio5'],
        participateBloodDonationCamps: formValues.radioValue['radio6'],
        ableToReadWriteCommunicate: formValues.radioValue['radio7'],
        sendReceiveMessagesOnMobile: formValues.radioValue['radio8'],
        bagType: formValues.radioValue['radio9'],
        willDonateFree: formValues.checkBoxValue['checkbox_1'],
        haveVerifiedIdentityAndContactInformation:
          formValues.checkBoxValue['checkbox_2'],
        haveExplainedProtocolsPrecautionsForDonation:
          formValues.checkBoxValue['checkbox_3'],
        preDonationHealthCheckFilled: formValues.checkBoxValue['checkbox_4'],
        selfAssessmentVerified: formValues.checkBoxValue['checkbox_5'],
      },
    };
    dispatch(donationByDonorId(obj));
  }

  function onContinueClick() {
    setShowSuccess(false);
    dispatch({ type: DONATION_BY_DONORID, loading: false });
    dispatch({ type: DONOR_LAB_RESULT, loading: false });
    dispatch({ type: DONATION_HISTORY, loading: false });
    dispatch({ type: MEDICAL_HISTORY, loading: false });
    dispatch({ type: GET_PROFILE, loading: false });
    dispatch({ type: GET_DONOR_OTP, loading: false });
    history.push('/');
  }
  function onInputChange(e) {
    let updateDonorInfo = { ...donorInfo };
    let value = e.target.value;
    if (FIELDS.includes(e.target.getAttribute('name'))) {
      updateDonorInfo[e.target.getAttribute('name')] = value;
    }
    setDonorInfo(updateDonorInfo);
  }

  function onRadioChange(e) {
    let obj = { ...selectedRadio };
    obj[e.target.name] = e.target.value;

    setCurrentRadioSelect(obj);
  }

  function onCheckboxChange(e) {
    let obj = { ...selectedCheckbox };
    obj[e.target.id] = e.target.checked;
    setSelectedCheckbox(obj);
  }

  useEffect(() => {
    let inputValueFlag = false;
    onFormChange({
      donorInfo: donorInfo,
      radioValue: selectedRadio,
      checkBoxValue: selectedCheckbox,
    });
    if (Object.entries(errorFields).filter((a) => a[1]).length !== 0) {
      inputValueFlag = true;
    } else {
      for (let i in donorInfo) {
        if (donorInfo[i] === '') {
          if (donorType === 'walkIn') {
            if (i !== 'bmi' && i !== 'gender') {
              inputValueFlag = true;
            }
          } else {
            inputValueFlag = true;
          }
        } else if (i === 'confirmAadharNo') {
          if (donorInfo['aadharNo'] !== donorInfo[i]) {
            inputValueFlag = true;
          }
        } /*else if (i === 'emailId') {
          if (donorInfo['emailId'].match(emailPattern) === null) {
            inputValueFlag = true;
          }
        }*/
      }
    }
    setDisableApprove(inputValueFlag);
  }, [selectedRadio, donorInfo, selectedCheckbox]);

  useEffect(() => {
    setDisableApprove(true);
  }, []);

  useEffect(() => {
    if (donationByDonor?.data) {
      dispatch({
        type: GET_PROFILE,
        loading: false,
      });
      setShowSuccess(true);
    } else if (donationByDonor.error !== null) {
      setFail(true);
    } else {
      setFail(false);
    }
  }, [donationByDonor]);

  useEffect(() => {
    if (donorType === 'walkIn') {
      if (donorObject?.data?.content?.donors.length > 0) {
        let {
          firstName,
          lastName,
          dateOfBirth,
          gender,
          bmi,
          height,
          weight,
          aadharNumber,
          confirmAadhar,
          // confirmAadharNo,
        } = donorObject?.data?.content?.donors[0] ?? null;
        setDonorInfo({
          firstName: firstName ? firstName : '',
          lastName: lastName ? lastName : '',
          age: dateOfBirth ? dateOfBirth : '',
          gender: gender ? gender : '',
          bmi: bmi ? bmi : '',
          height: height ? height : '',
          weight_1: weight ? weight : '',
          aadharNo: aadharNumber ? aadharNumber : '',
          confirmAadharNo: confirmAadhar ? confirmAadhar : '',
          // confirmAadharNo: mobileNumber ? mobileNumber : '',
        });
      }
    } else {
      setDonorInfo({
        firstName: donarProfile?.data?.firstName ?? '',
        lastName: donarProfile?.data?.lastName ?? '',
        age: donarProfile?.data?.dateOfBirth ?? '',
        gender: donarProfile?.data?.gender ?? '',
        bmi: donarProfile?.data?.bmi ?? '',
      });
    }
  }, [donarProfile, donorObject]);

  const donationFormProps = {
    donarProfile: donarProfile.data,
    donorInfo: donorInfo,
    disableApprove: disableApprove,
    selectedCheckbox: selectedCheckbox,
    // ErrrorMsg: ErrrorMsg,
    errorFields,
    onFormChange: onFormChange,
    onApproveClick: onApproveClick,
    onCancelClick: onCancelClick,
    onCheckboxChange: onCheckboxChange,
    onRadioChange: onRadioChange,
    onInputChange: onInputChange,
  };

  return (
    <>
      <DonationForm {...donationFormProps} />
      {showSuccess && (
        <SuccessPopup
          title={`The Donor ${
            donarProfile && donarProfile?.data
              ? donarProfile?.data?.donorId
              : ''
          } has been successfully donated Blood!`}
          onContinueClick={onContinueClick}
        />
      )}
      {showFail && (
        <FailPopup
          title={donationByDonor.error}
          onContinueClick={() => setFail(false)}
        />
      )}
    </>
  );
}
