import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { PROFILE_TABS } from '../../utils/constants';
import {
  VALIDATE_DONOR_OTP,
  GET_DONOR_OTP,
  BLOCK_DONOR,
  REQUEST_DONOR_CHANGE_STATUS,
  DONATION_REMARK,
  DONOR_LAB_RESULT,
  MEDICAL_HISTORY,
  DONATION_HISTORY,
} from '../../redux/actions/actionTypes';
import {
  getDonarProfile,
  getDonationData,
  getDonarOTP,
  validateDonorOTP,
  getDonorLabResultData,
  getDonationHistoryData,
  getMedicalHistoryData,
  getRemarkyData,
} from '../../redux/actions/dashboardAction';
import { formatDate, getOTP, sorting } from '../../utils';
import DonationViewAll from '../../views/DashboardView/DonationViewAll';
import stars from '../../assets/images/stars.png';

export default function DonationViewAllContainer() {
  const dispatch = useDispatch();
  const {
    donationObj,
    donarProfile,
    donarOTPData,
    historyList,
    labResultList,
    medicaList,
    donorDecline,
    donorBlock,
    remarkList,
  } = useSelector((state) => state.dashboardReducer);
  const [OTP, setOTP] = useState('');
  // const [showRequestOTPPopup, setShowRequestOTPPopup] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showProfilePopup, setShowProfilePopup] = useState({
    id: '',
    show: false,
  });
  const [showProfileDetailPopup, setShowProfileDetailPopup] = useState({
    id: '',
    show: false,
  });
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [tabsData, setTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState('History');
  const [errors, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [donorTableData, setDonorTableData] = useState([]);
  const [donorProfile, setDonoProfile] = useState({
    donorId: '',
    bloodGroup: '',
    name: '',
    gender: '',
    age: '',
    BMI: '',
    email: '',
    phone: '',
    address: '',
    availableDate: '',
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);

  function onRequestClick() {}

  function otpInput(refObj) {
    let otp = getOTP(refObj);
    setOTP(otp);
  }

  function onVerifyClick() {
    dispatch(
      validateDonorOTP(
        {
          otp: OTP,
          id: donarOTPData.data.id,
        },
        () => {
          dispatch(
            getDonationHistoryData({ donorId: donarProfile.data.donorId })
          );
          dispatch(
            getDonorLabResultData({ donorId: donarProfile.data.donorId })
          );
          dispatch(
            getMedicalHistoryData({ donorId: donarProfile.data.donorId })
          );
          dispatch(getRemarkyData({ donorId: donarProfile.data.donorId }));
        }
      )
    );
    setShowProfilePopup({ show: false });
    setShowVerifyOTP(false);
  }

  function onRequestOTPClick(e) {
    setShowVerifyOTP(true);
    setShowProfilePopup({
      show: true,
      id: e.target.id,
    });
    dispatch(getDonarProfile({ donorId: e.target.id }));
    dispatch(getDonarOTP({ donorId: e.target.id }));
  }

  function onTabChange(tab) {
    setActiveTab(tab);
  }

  function hidePopup() {
    setShowProfileDetailPopup({ show: false });
    dispatch({ type: VALIDATE_DONOR_OTP, loading: false });
    dispatch({ type: GET_DONOR_OTP, loading: false });
    dispatch({ type: BLOCK_DONOR, loading: false });
    dispatch({ type: REQUEST_DONOR_CHANGE_STATUS, loading: false });
    dispatch({ type: DONATION_HISTORY, loading: false });
    dispatch({ type: MEDICAL_HISTORY, loading: false });
    dispatch({ type: DONOR_LAB_RESULT, loading: false });
    dispatch({ type: DONATION_REMARK, loading: false });
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function ButtonRequestOTP({ id, onClick, status }) {
    // return (
    //   <div
    //     id={id}
    //     className={[
    //       'bd-btn bd-btn--primary',
    //       status === 'Active' ? '' : 'bd-btn--deactive',
    //     ].join(' ')}
    //     onClick={status === 'Active' ? onClick : null}
    //   >
    //     <span id={id}>Request OTP</span>
    //   </div>
    // );
    return (
      <div class='bd-request-otp'>
        <div className='bd-request-otp-stars brder_Radius' id={id}>
          <span id={id} onClick={onClick}>
            <img id={id} src={stars} onClick={onClick} height='15' />
          </span>
        </div>
        <div id={id} className='bd-request-otp-stars bd-black active'>
          <span id={id} onClick={status === 'Active' ? onClick : null}>
            Request OTP
          </span>
        </div>
      </div>
    );
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...donorTableData];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setDonorTableData(data);
    setCount(count + 1);
  }

  function headerDropDownChange(e) {
    let data = setData(donationObj.data);
    if (e.value === 'All/Any') {
      data = [...setData(donationObj.data)];
    } else {
      data = data.filter((a) => a.bloodGroup === e.value);
    }
    setDonorTableData(data);
  }

  function setData(_tableData) {
    let data = [];
    _tableData &&
      _tableData.forEach((record, i) => {
        let obj = {
          name: record.firstName + ' ' + record.lastName,
          bloodGroup: record?.bloodGroup ?? '--',
          donorId: record?.donorId,
          date: formatDate(record.appointment),
          time:
            new Date(record?.appointment).getHours() +
            ':' +
            new Date(record?.appointment).getMinutes(),
          checklist:
            record?.status === 'Active' ? (
              <span className='bd-green-clr'>Completed</span>
            ) : (
              <span className='bd-red-clr'>Incomplete</span>
            ),
          status:
            record?.status === 'Active' ? (
              <span className='bd-green-clr'>Fit To Donate</span>
            ) : (
              <span className='bd-red-clr'>Assess Donor</span>
            ),
          mobileNo:
            (record?.countryCode ? record?.countryCode : '') +
            ' ' +
            (record?.mobileNumber ? record?.mobileNumber : '--'),
          requestOTP: (
            <ButtonRequestOTP
              id={record?.donorId}
              onClick={onRequestOTPClick}
              status={record?.status}
            />
          ),
        };
        data.push(obj);
      });
    return data;
  }

  function getHistoryList() {
    if (historyList?.data) {
      let temp = [...PROFILE_TABS];
      temp[0].tableData.data = [];
      historyList?.data?.forEach((history, index) => {
        let date = history.date
          ? moment(new Date(history.date)).format('DD MMM YYYY')
          : '';
        let obj = {
          hash: index + 1,
          date: date,
          component: history.component,
          institute: history.institutionName,
          unit: history.units,
          unitId: '',
          comments: history.comments,
        };
        temp[0].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }

  function getLabResultList() {
    if (labResultList?.data) {
      let temp = [...PROFILE_TABS];
      temp[1].tableData.data = [];
      labResultList?.data?.forEach((labResult, index) => {
        let date = labResult.labResults.addedAt
          ? moment(new Date(labResult.labResults.addedAt)).format('DD MMM YYYY')
          : '';
        let obj = {
          hash: index + 1,
          date: date,
          bp: labResult.labResults.bloodPressure,
          weight: labResult.labResults.weight,
          hb: labResult.labResults.hb,
          rbc: '',
          wbc: '',
          platelet: '',
          esr: '',
          tlc: '',
          result: (
            <i
              className={[
                'bd-result-icon',
                labResult.labResults.finalResult === 'Pass'
                  ? ''
                  : 'bd-red__color',
              ].join(' ')}
            ></i>
          ),
        };
        labResult.labResults.results.forEach((element) => {
          obj[element.parameter.toLowerCase()] = element.observation;
        });
        temp[1].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }

  function getMedicalList() {
    if (medicaList?.data) {
      let temp = [...PROFILE_TABS];
      temp[2].tableData.data = [];
      medicaList?.data?.forEach((history, i) => {
        let obj = {
          hash: i + 1,
          health_issue: history?.healthIssue ?? '',
          description: history?.description ?? '',
          condition: history?.condition ?? '',
          medication: history?.medication ?? '',
          comments: history?.comments ?? '',
        };
        temp[2].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }
  function getRemarkList() {
    if (remarkList?.data) {
      let temp = [...PROFILE_TABS];
      temp[3].tableData.data = [];
      remarkList?.data?.forEach((remark, i) => {
        let obj = {
          hash: i + 1,
          date: remark.addedAt
            ? moment(new Date(remark.addedAt)).format('DD MMM YYYY')
            : '',
          doctor: remark.doctorName,
          event: remark.event,
          comments: remark.comment,
        };
        temp[3].tableData.data.push(obj);
      });
      setTabsData(temp);
    }
  }

  function getLoadingState() {
    if (
      donarProfile.error !== null ||
      donarOTPData.error !== null ||
      donorDecline.error !== null ||
      donorBlock.error !== null
    ) {
      setFail(true);
      setError(
        donarProfile.error ||
          donarOTPData.error ||
          donorDecline.error ||
          donorBlock.error
      );
    } else {
      setFail(false);
      setError('');
    }
    if (
      labResultList.loading ||
      medicaList.loading ||
      historyList.loading ||
      donarProfile.loading ||
      donarOTPData.loading ||
      donationObj.loading ||
      donorDecline.loading ||
      donorBlock.loading ||
      remarkList.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }

  function getDonarsProfile() {
    if (donarProfile.data) {
      setDonoProfile({
        donorId: donarProfile?.data?.donorId,
        bloodGroup: donarProfile?.data?.bloodGroup,
        name:
          donarProfile?.data?.firstName + ' ' + donarProfile?.data?.lastName,
        gender: donarProfile?.data?.gender ? donarProfile?.data?.gender : '',
        age: donarProfile?.data?.dateOfBirth
          ? donarProfile?.data?.dateOfBirth
          : '',
        BMI: donarProfile?.data?.bmi ? donarProfile?.data?.bmi : '',
        email: donarProfile?.data?.email,
        phone:
          (donarProfile?.data?.countryCode
            ? donarProfile?.data?.countryCode
            : '') +
          ' ' +
          (donarProfile?.data?.mobileNumber
            ? donarProfile?.data?.mobileNumber
            : ''),
        address: donarProfile?.data?.address
          ? donarProfile?.data?.address.description
          : '',
        availableDate: formatDate(donarProfile?.data?.addedAt),
        photo: donarProfile?.data?.photo,
      });
    }
  }

  function escFunction(e) {
    if (e.keyCode === 27) {
      setShowProfilePopup({ show: false });
      setShowProfileDetailPopup({ show: false });
      setShowVerifyOTP(false);
    }
  }

  useEffect(() => {
    dispatch(getDonationData());
    document.addEventListener('keydown', escFunction, false);
  }, []);

  useEffect(() => {
    if (donarOTPData.successFlag) {
      setShowProfileDetailPopup({ show: true });
    }
  }, [donarOTPData]);

  useEffect(() => {
    getHistoryList();
  }, [historyList]);

  useEffect(() => {
    getLabResultList();
  }, [labResultList]);

  useEffect(() => {
    getLoadingState();
  }, [
    donarProfile,
    donarOTPData,
    historyList,
    labResultList,
    medicaList,
    donationObj,
    donorBlock,
    donorDecline,
    remarkList,
  ]);
  useEffect(() => {
    setDonorTableData(setData(donationObj.data));
  }, [donationObj.data]);

  useEffect(() => {
    getMedicalList();
  }, [medicaList]);

  useEffect(() => {
    getRemarkList();
  }, [remarkList]);

  useEffect(() => {
    getDonarsProfile();
  }, [donarProfile]);

  useEffect(() => {
    if (donorBlock.error !== null) {
      setFail(true);
    } else if (donorBlock.data) {
      setShowSuccess(true);
    }
  }, [donorBlock]);

  const donationProps = {
    tableData: donationObj.data,
    showProfilePopup: showProfilePopup,
    donorProfile: donorProfile,
    showVerifyOTP: showVerifyOTP,
    showProfileDetailPopup: showProfileDetailPopup,
    tabs: tabsData,
    loading: loading,
    activeTab: activeTab,
    page: page,
    rowsPerPage: rowsPerPage,
    donorTableData: donorTableData,
    errors: errors,
    showSuccess: showSuccess,
    showFail: showFail,
    setShowSuccess: setShowSuccess,
    setFail: setFail,
    otpInput: otpInput,
    onRequestClick: onRequestClick,
    setShowProfilePopup: setShowProfilePopup,
    onVerifyClick: onVerifyClick,
    setShowVerifyOTP: setShowVerifyOTP,
    onTabChange: onTabChange,
    setShowProfileDetailPopup: setShowProfileDetailPopup,
    onRequestOTPClick: onRequestOTPClick,
    hidePopup: hidePopup,
    handleChangePage: handleChangePage,
    handleChangeRowsPerPage: handleChangeRowsPerPage,
    headerDropDownChange: headerDropDownChange,
    onHeaderClick: onHeaderClick,
  };
  return (
    <>
      <DonationViewAll {...donationProps} />
    </>
  );
}
