import React from 'react';
import { Tabs, TabPane } from '../../components';
import { LatLongURL } from '../../utils/config';
import longLatIcon from '../../assets/icon/longLatIcon.svg';
// import download from '../../assets/icon/download.svg';

export default function AddNewInstituteView({
  createNewObj,
  onChangeText,
  onContinue,
  closeCreatNewPopup,
  onStateSearch,
  onCitySearch,
  StateFocused, // focus
  setStateFocused, // focus
  CityFocused,
  setCityFocused,
  onStateSelect, // state select
  onCitySelect, // city select
  apIStateList,
  // getCity,
  apICityList,
  // setCreateNewInstitute,
  activeTab,
  tabs,
  onTabChange,
}) {
  let { institutionName, address, city, state, zipcode, latitude, longitude } =
    createNewObj;
  console.log(apIStateList);
  function renderFields() {
    return (
      <div className='field-wrapper'>
        <div className='bd-form__row'>
          <input
            type='text'
            name='institutionName'
            value={institutionName}
            placeholder='Institution Name'
            onChange={(e) => onChangeText(e)}
          />
        </div>
        <div className='bd-form__row margin-Top-10 '>
          <input
            type='text'
            name='address'
            value={address}
            onChange={(e) => onChangeText(e)}
            placeholder='Address'
          />
        </div>
        <div className='d-flex justify-content-between margin-Top-10'>
          <div className='bd-form__row'>
            <input
              type='text'
              name='state'
              value={state}
              placeholder='State'
              onChange={(e) => onStateSearch(e)}
              onFocus={() => setStateFocused(true)}
            />
            {StateFocused && apIStateList.length ? (
              <div className='bd-api-dropdown'>
                {apIStateList.map((data, i) => {
                  return (
                    <div
                      className='cursor-pointer onHoveeerr bd-pd-10'
                      key={i}
                      onClick={() => {
                        onStateSelect(data);
                      }}
                    >
                      <div>{data}</div>
                      <div
                        className='cursor-pointer'
                        onClick={() => {
                          onStateSelect(data);
                        }}
                      ></div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='bd-form__row '>
            <input
              type='text'
              name='city'
              value={city}
              placeholder='city'
              onChange={(e) => onCitySearch(e)}
              onFocus={() => setCityFocused(true)}
            />
            {CityFocused && apICityList.length ? (
              <div className='bd-api-dropdown'>
                {apICityList.map((data, i) => {
                  return (
                    <div
                      className='cursor-pointer onHoveeerr bd-pd-10'
                      key={i}
                      onClick={() => {
                        onCitySelect(data);
                      }}
                    >
                      <div>{data}</div>
                      <div
                        onClick={() => {
                          onCitySelect(data);
                        }}
                      ></div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='bd-form__row'>
          <input
            type='number'
            name='zipcode'
            value={zipcode}
            onChange={(e) => onChangeText(e)}
            placeholder='Pin/Zip Code'
          />
        </div>
        <div className='d-flex align-items-center margin-Top-10'>
          <div className='bd-form__row'>
            <input
              type='number'
              name='latitude'
              value={latitude}
              onChange={(e) => onChangeText(e)}
              step='any'
              placeholder='Latitude'
            />
          </div>
          <div className='bd-form__row'>
            <input
              type='number'
              name='longitude'
              value={longitude}
              onChange={(e) => onChangeText(e)}
              step='any'
              placeholder='Longitude'
            />
          </div>
          <div className='bd-form__row'>
            <a target='_blank' href={LatLongURL}>
              <img className='lat-long' src={longLatIcon} />
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='bd-modal-wrap'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Create New</span>
          <div className='bd-popup__head--rgt'>
            <i className='bludclose' onClick={closeCreatNewPopup}></i>
          </div>
        </div>
        <div className='bd-popup__body'>
          {/* <form onSubmit={onContinueClick}> */}
          <form>
            <div className='bd-form'>
              <Tabs onChange={onTabChange} activeTab={activeTab}>
                {tabs.map((record, i) => {
                  return (
                    <TabPane name={record} key={i}>
                      {renderFields(i)}
                    </TabPane>
                  );
                })}
              </Tabs>

              <div
                className='bd-form__row bd-form__row--register'
                style={{ marginTop: '10px' }}
              >
                {/* {fieldsEmpty && (
                  <span className='form-inline-error'>
                    Please fill all the fields
                  </span>
                )} */}
                <button className='bd-btn bd-btn--primary' onClick={onContinue}>
                  <span>Continue</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
