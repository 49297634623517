import React from 'react';
import { ageCalculation, genderWiseProfilePic } from '../../utils';

export default function UserDetail({ profileData }) {
  const cells = [
    { label: 'Name:', value: profileData.name },
    { label: 'Gender:', value: profileData.gender },
    {
      label: 'Age:',
      value:
        profileData && profileData.age ? ageCalculation(profileData.age) : '',
    },
    { label: 'BMI:', value: profileData.BMI ?? '--' },
    { label: 'Address:', value: profileData.address ?? '--' },
  ];
  return (
    <div className='bd-profile__detail--lft'>
      <div className='bd-profile__detail'>
        <div
          className='bd-profile__img'
          style={{
            backgroundImage: profileData.photo
              ? `url(${profileData.photo})`
              : `url(${genderWiseProfilePic(profileData.gender)})`,
          }}
        ></div>
        <div className='bd-profile__group'>
          <span>{profileData.bloodGroup}</span>
        </div>
      </div>
      <div className='bd-tbl bd-tbl__theme2'>
        <div className='bd-tbl__body'>
          {cells.map((cell) => {
            return (
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>{cell.label}</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>{cell.value}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
