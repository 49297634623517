import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tables } from '..';
import DropDown from '../RDropDown';
import { CheckBox } from '..';
import {
  SuccessPopup,
  Confirmation,
  FailPopup,
  Loader,
} from '../../components';
import { BLOOD_COMPONENTS, BLOOD_GROUP_LIST } from '../../utils/constants';
import { sorting } from '../../utils';
import { triggerBloodComponentList } from '../../redux/actions/commonAction';
import TablePagination from '@material-ui/core/TablePagination';

function InventoryTableList({
  title,
  data,
  columns,
  bloodComponentList,

  selectedListIds,
  setSelectedIds,
  showSuccess,
  setShowSuccess,
  error,
  showFail,
  loading,
  hideErrorPopUp, //close error pops function from
  showConfirmation,
  setShowConfirmation,
  getComponent,
  disposeAgeing,
  btnType,
}) {
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);
  const [table, setTable] = useState([]);
  // const [ids, setIds] = useState([]);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const [bloodComponentList, setBloodComponentsList] = useState([]);

  const [activeBloodComponent, setActiveBloodComponent] = useState(
    bloodComponentList[0]
  );
  useEffect(() => {
    setActiveBloodComponent(bloodComponentList[0]);
  }, [bloodComponentList]);

  const [activeBloodGroup, setActiveBloodGroup] = useState(BLOOD_GROUP_LIST[0]);
  const [sort, setSort] = useState(false);
  // useEffect(() => {
  //   // setBloodComponents function as a callback once the API is success
  //   dispatch(triggerBloodComponentList(setBloodComponents));
  // }, []);

  // set the current the blood component list from the api
  // function setBloodComponents(response) {
  //   setBloodComponentsList(response.content.data);

  //   setActiveBloodComponent(response.content.data[0]);
  // }

  // fetch the data from dropdown component

  const [search, setSearch] = useState('');

  function fetchBloodComponentValue({ value }) {
    setSort(false);

    setActiveBloodComponent(value);
    // empty the selected array when we change the blood component
    setSelectedIds && setSelectedIds([]);
    if (title === 'Transfer') getComponent(activeBloodGroup, value);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const onHeaderClick = (e) => {
    setSort(true);
    let attribute = e.target.getAttribute('dataindex');
    let data = renderTableData();
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });

    setTable(data);
    setCount(count + 1);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  function onCheckBoxChange(e) {
    const { selected } = e.currentTarget.dataset;

    let selectedId = data.findIndex((d) => d._id === selected);
    data[selectedId].isSelected
      ? (data[selectedId].isSelected = false)
      : (data[selectedId].isSelected = true);

    if (e.target.checked) {
      // push the id's once we select the checkbox
      setSelectedIds([...selectedListIds, selected]);
    } else {
      // remove the id's from once we deselect
      setSelectedIds(selectedListIds.filter((item) => item !== selected));
    }
  }

  function renderCheckBox(arr) {
    if (arr.length > 0) {
      return arr.map((item, index) => {
        return {
          ...item,

          checkBox: (
            <CheckBox
              data-selected={item._id}
              keyindex={`checkbox_${index}`}
              checked={item.isSelected}
              onChange={onCheckBoxChange}
            />
          ),
        };
      });
    }

    return arr;
  }

  function filterData(arr) {
    if (search) {
      arr = arr.filter(
        (item) =>
          item.bloodGroup === activeBloodGroup &&
          item.component === activeBloodComponent &&
          (item?.bagId?.toLowerCase().includes(search.toLowerCase()) ||
            item?.donorId?.toLowerCase().includes(search.toLowerCase()) ||
            item?.storedIn?.toLowerCase().includes(search.toLowerCase()) ||
            item?.hospitalName?.toLowerCase().includes(search.toLowerCase()) ||
            String(item?.patientId)
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            String(item?.collectionDate)
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            String(item?.issuedDate)
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            String(item?.expiryDate)
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            String(item?.receivedOn)
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            String(item?.receivedFrom)
              ?.toLowerCase()
              .includes(search.toLowerCase()))
      );
    } else {
      arr = arr.filter(
        (item) =>
          item.bloodGroup === activeBloodGroup &&
          item.component === activeBloodComponent
      );
    }

    if (arr.length > 0) {
      arr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return arr;
  }

  function renderTableData() {
    let list = [];
    if (data.length > 0) {
      if (activeBloodGroup && activeBloodComponent) {
        title === 'Issued'
          ? (list = filterData(data))
          : (list = renderCheckBox(filterData(data)));
      }
    }
    return list;
  }

  function onBloodGroupChange(e) {
    setSort(false);

    const { id } = e.currentTarget.dataset;
    data.forEach((item) => (item.isSelected = false));
    setActiveBloodGroup(id);
    // empty the selected array when we change the blood group
    setSelectedIds && setSelectedIds([]);
    if (title === 'Transfer') getComponent(id, activeBloodComponent);
  }

  function renderDropDown() {
    return (
      <DropDown
        options={bloodComponentList.map((item) => ({
          label: BLOOD_COMPONENTS[item] || item,
          value: item,
        }))}
        defaultValue={{
          label: activeBloodComponent,
          value: activeBloodComponent,
        }}
        fetchDropDownValue={fetchBloodComponentValue}
      />
    );
  }

  function renderBloodGroups() {
    return BLOOD_GROUP_LIST.map((bloodGroup, i) => {
      let bloodGroupClass = bloodGroup === activeBloodGroup ? 'active' : '';
      return (
        <div
          onClick={onBloodGroupChange}
          key={`${bloodGroup}_${i}`}
          data-id={bloodGroup}
          className={`bd-blood__group--col ${bloodGroupClass}`}
        >
          {bloodGroup}
        </div>
      );
    });
  }

  function sucessPopupMessage() {
    let msg;
    switch (title) {
      case 'Transfer':
        btnType === 'btnDispose' &&
          (msg = `${selectedListIds.length} units of  ${activeBloodGroup} ${activeBloodComponent}  disposed as waste`);
        btnType === 'btnTransfer' &&
          (msg = `${selectedListIds.length} units of  ${activeBloodGroup} ${activeBloodComponent}  Transfer Successfully`);
        btnType === 'btnIssue' &&
          (msg = `${selectedListIds.length} units of  ${activeBloodGroup} ${activeBloodComponent}  Issue Successfully`);
        break;
      case 'Receipt':
        btnType === 'btnDecline' &&
          (msg = `${selectedListIds.length} units of  ${activeBloodGroup} ${activeBloodComponent}  Declined successfully`);
        btnType === 'btnAccept' &&
          (msg = `${selectedListIds.length} units of  ${activeBloodGroup} ${activeBloodComponent}  Added  successfully`);
        break;
      case 'Ageing-Report':
        msg = `${selectedListIds.length} units of  ${activeBloodGroup} ${activeBloodComponent}  disposed as waste`;
        break;
      default:
      // code block
    }
    return msg;
  }

  return (
    <>
      <div className='bd-dashboard__container'>
        <div className='bd-dashboard__lft'>
          <div className='bd-dashboard__header bd-header__search'>
            <span className='bd-dashboard__main'>{title}</span>
            <div className='inventory-transferView '>
              <label>Component</label>
              <div className='bd-dorpdown inventory-comp'>
                <div className='bd-dorpdown__selected pd-left'>
                  {bloodComponentList.length > 0 && renderDropDown()}
                  <i className='bludbig-arrow' />
                </div>
              </div>
            </div>
            <div className='bd-hader'>
              {/* <div className="bd-search">
                <input type="text" name="" />
                <i className="bludsearch" />
              </div> */}
              <input
                type='text'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder='Search'
                style={{ padding: 5, marginRight: 10 }}
              />
            </div>
          </div>

          <div className='bd-samp__recived bd-inventory__scr'>
            {/* <div class="bd-tbl__inventory bd-scroll__horz"> */}
            {<div className='bd-blood__group--list'>{renderBloodGroups()}</div>}
            {
              <div className='bd-tbl__inventory bd-scroll__horizontal'>
                <div className='table-scroll'>
                  <Tables
                    tab={'InventoryTab'}
                    tableType={'table-type-1'}
                    columns={columns}
                    tableData={
                      sort
                        ? table
                        : renderTableData().slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                    }
                    headerClick={onHeaderClick}
                    rowClick={false}
                  />
                </div>
                <TablePagination
                  component='div'
                  count={renderTableData().length}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            }
          </div>
        </div>
      </div>
      {showSuccess && (
        <SuccessPopup
          title={sucessPopupMessage()}
          onContinueClick={() => {
            setShowSuccess(false);
            refreshPage();
          }}
        />
      )}
      {showFail && (
        <FailPopup title={error} onContinueClick={hideErrorPopUp()} />
      )}
      {showConfirmation && (
        <Confirmation
          title={'Are you sure you want to Dispose the selected units?'}
          btnName={'Dispose'}
          onCancleClick={() => setShowConfirmation(false)}
          onDisposeClick={() => disposeAgeing()}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default InventoryTableList;
