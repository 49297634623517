import React from "react";
import people from "../../assets/images/people.png";
import bank from "../../assets/images/bank.png";
import BloodDonation from "../../assets/images/blood-donation.png";
import Blood from "../../assets/images/Blood.png";
import Register from "../../assets/images/Register.png";
import Bell from "../../assets/images/notification.png";
import Replenishment from "../../assets/images/Replenishment.png";
import BloodBag from "../../assets/images/BloodBag.png";
import hospital from "../../assets/images/hospital.png";

const countData = [
  {
    img: bank,
    label: "Blood Banks",
    count: "3023",
  },

  {
    img: Blood,
    label: "Lakh donors donate Blood",
    count: "15.35",
  },

  {
    img: BloodDonation,
    label: "Blood Collected from Voluntary Blood Donors",
    count: "78%",
  },
];

const countDataHospital = [
  {
    img: bank,
    label: "Blood Banks",
    count: "3023",
  },

  {
    img: Blood,
    label: "Donors",
    count: "57,298",
  },

  {
    img: hospital,
    label: "Hospitals",
    count: "12,978",
  },

  {
    img: BloodDonation,
    label: "Patients Served",
    count: "78,635",
  },
];

const stepsList = [
  {
    img: Register,
    title: "Register yourself",
    list: ["Donor", "Patient", "Social Worker", "Blood Bank", "Hospital"],
  },
  {
    img: Bell,
    title: "Get notified of Blood requirement",
    list: [
      "Closer to you",
      "Patient condition",
      "Emergency Relief",
      "Blood Donation Camp",
      "Share & Communicate",
    ],
  },
  {
    img: Replenishment,
    title: "Donors donate blood",
    list: [
      "Donor Health Check",
      "Donor Medical History",
      "Donation History",
      "Replenish stock",
      "Refers new donors",
    ],
  },

  {
    img: BloodBag,
    title: "Save Life",
    list: ["Every life matters!"],
  },
];

export function LandscapeImage() {
  return (
    <div className="bd-auth-img text-center">
      <img src={people} className="bd_main_img" />

      <div className="d-flex space-around">
        {countData.map((item, i) => {
          return (
            <div key={i}>
              <div className="d-flex align-items-center">
                <img
                  className="bd_count_images"
                  src={item.img}
                  alt=""
                  height="45"
                />
                <div className="counts">{item.count}</div>
              </div>

              {i !== 2 ? (
                <div className="label">{item.label}</div>
              ) : (
                <div className="label">
                  <div>Blood Collected from</div>
                  <div>Voluntary Blood Donors</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function Steps() {
  return (
    <div className="position-relative">
      <div className="bd_steps">
        {stepsList.map((step, index) => {
          return (
            <div className="donation-process" key={index}>
              <div className="img-div">
                <img src={step.img} height="50" alt={step.title} />
                {index !== stepsList.length - 1 ? (
                  <div className="vertical-bar"></div>
                ) : (
                  ""
                )}
              </div>

              <div>
                <div className="title"> {step.title}</div>
                <ul className="remove-list-style pl-2">
                  {step.list.map((data, i) => {
                    return (
                      <li
                        className={
                          i <= 2 && index == 0 ? "bd_color_gray" : "fw-600"
                        }
                        key={i}
                      >
                        - {data}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function HospitalCounts() {
  return (
    <div className="bd_count_hospital">
      <div className="bd-hospital d-flex align-items-center justify-content-between">
        {countDataHospital.map((item, i) => {
          return (
            <div key={i}>
              <div className="d-flex align-items-center">
                <img
                  className="bd_hospital_counts"
                  src={item.img}
                  alt=""
                  height="45"
                />
                <div>
                  <div className="counts">{item.count}</div>
                  <div className="label">{item.label}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <hr className="bd_counts_hr" />
    </div>
  );
}
