import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DropDown from '../../components/RDropDown';
import { RadioButton, CheckBox, NoDataFound } from '../../components';
import { formatDate } from '../../utils';
import {
  // getPatientRequest,
  // getPatientRequestData,
  getTransferInstituteList,
} from '../../redux/actions/inventoryAction';
import {
  INVENTORY_ADD_PATIENT,
  TRANSFER_INSTITUTE_LIST,
} from '../../redux/actions/actionTypes';
import { BLOOD_COMPONENTS } from '../../utils/constants';

export default function Modal({
  title,
  button,
  currBloodGroup,
  bloodComponent,
  data = null,
  showDetail = true,
  type,
  processValue,
  setShowInventory,
  componentList,
  addPatient,
  onBtnClick,
}) {
  const cells = [
    '',
    'Bag / Unit ID',
    'Donor ID',
    'Receipt',
    'Expiry',
    'Location',
  ];
  // let totalVolume =
  //   type === 'process'
  //     ? processValue.enteredVolume +
  //       processValue.additives +
  //       processValue.preservatives
  //     : 0;
  const dispatch = useDispatch();
  const { registerPatientList, patientInfo, transferInstitutionList } =
    useSelector((state) => state.inventoryReducer);
  const formRef = React.createRef();
  const [currentRadioSelect, setCurrentRadioSelect] = useState('');
  const [userList, setUserList] = useState([]);
  const [dropdownList, setDropdownList] = useState([]);
  const [unitValue, setUnitValue] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [patientComment, setComment] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [transferComment, setTransferComment] = useState();
  const [errors, setError] = useState(false);
  function onCancelClick() {
    setShowInventory(false);
    dispatch({ type: TRANSFER_INSTITUTE_LIST, loading: false });
    dispatch({ type: INVENTORY_ADD_PATIENT, loading: false });
  }
  function onRadioChange(e) {
    setCurrentRadioSelect(e.target.value);
  }

  function onButtonClick() {
    let deliveryType =
      currentRadioSelect === 'deliver' ? 'toBeDeliver' : 'toBeCollected';
    if (type === 'registered' || type === 'unregistered') {
      onBtnClick({
        type: type,
        inventoryList: modalData.inventory
          .filter((a) => a.isSelected && !a.isTransferInProcess)
          .map((a) => a._id),
        unit: unitValue,
        id: selectedUser.id,
        deliveryType: deliveryType,
      });
    } else if (type === 'transfer') {
      onBtnClick({
        type: type,
        selectedUser: selectedUser,
        inventoryList: modalData.inventory
          .filter((a) => a.isSelected && !a.isTransferInProcess)
          .map((a) => a._id),
        comment: transferComment,
        details: selectedUser,
        deliveryType: deliveryType,
      });
    } else if (type === 'process') {
      let inventoryList = modalData.inventory
        // .filter((a, i) => i < processValue.unitValue && !a.isTransferInProcess)
        .filter((a) => a.isSelected && !a.isTransferInProcess);
      // .map((a) => a._id);
      onBtnClick({
        type: type,
        inventoryList: inventoryList,
      });
      // let { inventories, flag } = getBagIds();
      // if (flag === false) {
      //   onBtnClick({
      //     type: type,
      //     inventoryList: inventoryList,
      //     // comment: transferComment,
      //     inventories: inventories,
      //   });
      // }
    }
  }

  function getBagIds() {
    let arr = [];

    let newObj = {};
    let flag = false;
    componentList.forEach((a, i) => {
      for (let j in formRef.current) {
        if (formRef.current[j]?.name === `${a.component}_${i}_volume`) {
          if (formRef.current[j].value === '') {
            setError(true);
            flag = true;
          }
          newObj[a.component] = formRef.current[j].value;
        }
        if (formRef.current[j]?.name === `${a.component}_${i}`) {
          if (formRef.current[j].value === '') {
            setError(true);
            flag = true;
          }
          let volume = a.volume / processValue.unitValue;
          let obj = {
            component: formRef.current[j].name.split('_')[0],
            bagId: formRef.current[j].value,
            volume: '',
            mass: (volume * 1.07).toString(),
          };
          arr.push(obj);
        }
      }
    });
    arr.map((a) => {
      let volume = newObj[a.component] / processValue.unitValue;
      a.volume = (volume * 1).toString();
    });

    return { inventories: arr, flag };
  }

  function checkboxClick(e) {
    let data = [...modalData.inventory];
    data &&
      data.forEach((a) => {
        if (a.bagId === e.target.id) {
          a['isSelected'] = e.target.checked;
        }
      });
    setUnitValue(
      data.filter((a) => a.isSelected && !a.isTransferInProcess).length
    );
    setModalData({
      ...modalData,
      inventory: data,
    });
  }

  function selectRecord(value) {
    if (data?.inventory && data.inventory.length !== 0) {
      let _data = [...data.inventory];
      let list = [];
      let arr = _data.filter(
        (b) =>
          b.component.toLowerCase() ===
          bloodComponent?.split('_')[0].toLowerCase()
      );
      arr
        .filter((b) => !b?.isTransferInProcess)
        .map((a, i) => {
          a['isSelected'] = i < value * 1;
          if (type !== 'process') {
            let o = {
              label: i + 1,
              value: i + 1,
            };
            list.push(o);
          }
        });
      if (type === 'process') {
        let ab = arr.length / 6;

        for (let j = 1; j < ab; j++) {
          let o = {
            label: j * 6,
            value: j * 6,
          };
          list.push(o);
        }
      }
      setDropdownList(list);
      setModalData({
        ...modalData,
        inventory: arr,
      });
    }
  }

  function handleOnChange(e) {
    setUnitValue(e.value);
    selectRecord(e.value);
  }

  function handleDropDownChange(e) {
    let id = e.value;
    setComment(userList.filter((a) => id === a.id)[0].comments);
    setSelectedUser(userList.filter((a) => id === a.id)[0]);
  }

  function getData(data) {
    if (data !== null || data !== undefined) {
      let obj = { ...data };
      if (obj?.inventory) {
        let list = [];
        let arr = obj.inventory.filter(
          (b) =>
            b.component.toLowerCase() ===
            bloodComponent?.split('_')[0].toLowerCase()
        );
        arr
          .filter((b) => !b?.isTransferInProcess)
          .map((a, i) => {
            if (type != 'process') {
              let o = {
                label: i + 1,
                value: i + 1,
              };
              list.push(o);
            }
          });
        if (type === 'process') {
          let ab = arr.length / 6;
          for (let j = 1; j < ab; j++) {
            let o = {
              label: j * 6,
              value: j * 6,
            };
            list.push(o);
          }
        }
        setDropdownList(list);
        setModalData(arr);
        selectRecord(unitValue);
      }
    }
  }

  function getUserList() {
    let arr = [];
    if (registerPatientList?.data) {
      registerPatientList?.data.forEach((a) => {
        // if (a.details) {
        let name = '';
        let fname = a?.details?.firstName ?? '';
        let lname = a?.details?.lastName ?? '';
        if (a.requestType === 'Individual') {
          name = `${fname} ${lname} [${a?.patientId}] ${a?.hospitalLocation?.description}`;
        } else if (a.requestType === 'BloodBank') {
          name = `${a.bloodBankName} ${a.donationLocation.description}`;
        } else if (a.requestType === 'Hospital') {
          name = `${a.hospitalName} ${a.hospitalLocation.description}`;
        }
        let obj = {
          id: a._id,
          patientId: a.patientId,
          comments: a.comments,
          name: name,
        };
        arr.push(obj);
        // }
      });
    }
    setUserList(arr);
  }

  function setTransferInstitutionList() {
    let arr = [];
    if (transferInstitutionList?.data) {
      transferInstitutionList?.data.forEach((a) => {
        let obj = {
          id: a._id,
          registrationNumber: a.registrationNumber,
          name: a.institutionName,
        };
        arr.push(obj);
      });
    }
    setUserList(arr);
  }

  useEffect(() => {
    getData(data);
  }, [data]);

  useEffect(() => {
    if (type === 'registered') {
      getUserList();
    }
  }, [registerPatientList]);

  useEffect(() => {
    if (type === 'transfer') {
      setTransferInstitutionList();
    }
  }, [transferInstitutionList]);

  useEffect(() => {
    if (type === 'transfer') {
      dispatch(getTransferInstituteList({ type: 'bloodbank' }));
    }
  }, []);

  useEffect(() => {
    if (type === 'unregistered') {
      if (patientInfo?.data) {
        if (patientInfo.data.length !== 0) {
          let patient = registerPatientList.data.filter(
            (a) => a._id === patientInfo?.data?.requestId
          )[0];
          let { details, _id, patientId, comments } = patient;
          let arr = [];
          let fname = details?.firstName ?? '';
          let lname = details?.lastName ?? '';
          let obj = {
            id: _id,
            patientId: patientId,
            comments: comments,
            name:
              details === undefined
                ? ''
                : `${fname} ${lname} [${patientId}] ${patient?.hospitalLocation?.description}`,
          };
          arr.push(obj);
          setUserList(arr);
        }
      }
    }
  }, [patientInfo]);

  function createRow() {
    let rows = [];
    for (let i = 0; i < processValue.unitValue; i++) {
      rows.push(
        <div className='bd-tbl__row'>
          <div className='bd-tbl__cell'>
            <span>
              <b>Bag ID</b>
            </span>
          </div>
          {componentList.map((a, i) => {
            return (
              <div className='bd-tbl__cell '>
                <input
                  className='bd-process'
                  type='text'
                  name={`${a.component}_${i}`}
                  placeholder='Bag ID'
                />
              </div>
            );
          })}
        </div>
      );
    }
    return rows;
  }
  function BagList() {
    return (
      <div className='bd-tbl bd-tbl__theme1'>
        {/* <div className="process-popup-scroll"> */}
        {errors && (
          <div className='volume-error'>
            Please fill all Bag Ids and total volume.
          </div>
        )}
        <form ref={formRef}>
          <div className='bd-tbl__body'>
            <div className='bd-tbl__row'>
              <div className='bd-tbl__cell'>
                <span></span>
              </div>
              {componentList.map((a) => {
                return (
                  <div className='bd-tbl__cell'>
                    <span>
                      <b>{a.component}</b>
                    </span>
                  </div>
                );
              })}
            </div>
            {createRow()}
            <div className='bd-tbl__row'>
              <div className='bd-tbl__cell volume-process'>
                <span>
                  <b>Volume ml</b>
                </span>
              </div>
              {componentList.map((a, i) => {
                return (
                  <div className='bd-tbl__cell '>
                    <input
                      className='bd-process'
                      type='number'
                      disabled
                      name={`${a.component}_${i}_volume`}
                      value={a.volume}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </form>
        {/* </div> */}
      </div>
    );
  }

  let disableButton = false;
  if (type === 'registered' || type === 'unregistered' || type === 'transfer') {
    disableButton =
      currentRadioSelect === '' ||
      selectedUser === null ||
      modalData?.inventory.filter((a) => a.isSelected).length === 0;
  } else if (type === 'process') {
    disableButton =
      modalData?.inventory.filter((a) => a.isSelected).length === 0;
  }

  const defUnitValue = unitValue;

  return (
    <div className='bd-modal-wrap bd-accept-issue'>
      <div className='bd-popup si-fade-in dashboard-request-popup'>
        <div className='bd-popup__head'>
          <span>{title}</span>
          <div className='bd-popup__head--rgt'>
            <div className='bd-blood-type'>
              <span>
                <b>{currBloodGroup}</b>
              </span>
              {/* <span>{BLOOD_COMPONENTS[bloodComponent?.split("_")[0]] || ""}</span> */}
              <span>
                {BLOOD_COMPONENTS[bloodComponent?.split('_')[0]] ||
                  bloodComponent?.split('_')[0] ||
                  ''}
              </span>
            </div>
          </div>
        </div>

        {/* <div className="bd-form__row"> */}
        {/* {type === 'process' ? (
          <div class='bd-volume'>
            <div>
              <label>Volume ml: </label>
              <span>{totalVolume}</span>
            </div>
            <div>
              <label>Quantity: </label>
              <span>{processValue.unitValue}</span>
            </div>
          </div>
        ) : ( */}
        <>
          <div
            className='bd-form__row '
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label>Quantity</label>
            <div className='bd-dorpdown inventory-dd'>
              <div className='bd-dorpdown__selected'>
                <DropDown
                  width={'200px'}
                  options={dropdownList}
                  placeHolderText={'Select Quantity'}
                  defaultValue={{ label: defUnitValue, value: defUnitValue }}
                  fetchDropDownValue={handleOnChange}
                />
                <i className='bludbig-arrow' />
              </div>
            </div>
          </div>
        </>

        {/* </div> */}
        <div className='bd-tbl__body--scroll modal_scroll'>
          <div className='bd-popup__body'>
            <div className='bd-accept-issue__tbl'>
              <div className='bd-tbl bd-tbl__theme1'>
                <div className='bd-tbl__body'>
                  {modalData?.inventory && modalData.inventory.length > 0 ? (
                    <div className='bd-tbl__row'>
                      {cells.map((cell, i) => {
                        // if (i === 0 && type === 'process') return null;
                        return (
                          <div className='bd-tbl__cell'>
                            <span>{cell}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
                  {modalData?.inventory &&
                    modalData.inventory.length > 0 &&
                    modalData.inventory
                      // .filter((a, i) =>
                      //   type === 'process' ? i < processValue.unitValue : true
                      // )
                      .map((record, i) => {
                        return (
                          <div
                            key={'row' + i}
                            className={[
                              'bd-tbl__row',
                              record?.isTransferInProcess ? 'disabledRow' : '',
                            ].join(' ')}
                          >
                            {/* {type === 'process' ? null : ( */}
                            <div className='bd-tbl__cell'>
                              <CheckBox
                                key={'checkbox_' + record.bagId}
                                id={record.bagId}
                                disabled={record?.isTransferInProcess}
                                checked={
                                  !record?.isTransferInProcess &&
                                  record.isSelected
                                }
                                onChange={checkboxClick}
                              />
                            </div>
                            {/* )} */}
                            <div className='bd-tbl__cell bd-issue-bagId'>
                              <span>{record.bagId ?? '--'}</span>
                            </div>
                            <div className='bd-tbl__cell bd-issue-bagId'>
                              <span>{record.donorId ?? '--'}</span>
                            </div>
                            <div className='bd-tbl__cell bd-issue-bagId'>
                              <span>{formatDate(record.collectionDate)}</span>
                            </div>
                            <div className='bd-tbl__cell bd-issue-bagId'>
                              <span>{formatDate(record.expiryDate)}</span>
                            </div>
                            <div className='bd-tbl__cell '>
                              <span>
                                {record.storedIn ? record.storedIn : '-'}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
            <div className='bd-accept-issue__details dd-zindex'>
              {showDetail && type != 'process' && (
                <div className='bd-accept-issue__bot'>
                  <label>Details</label>
                  {type === 'unregistered' ? (
                    userList?.[0]?.name || 'Unregistered Patient'
                  ) : (
                    <div className='bd-dorpdown'>
                      <div className='bd-dorpdown__selected detail-dd modal-inventory dd-zindex'>
                        <DropDown
                          width={'200px'}
                          placeHolderText={'Select...'}
                          options={userList.map((a) => {
                            return {
                              label: a.name,
                              value: a.id,
                            };
                          })}
                          fetchDropDownValue={handleDropDownChange}
                        />
                        <i className='bludbig-arrow' />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {type != 'process' && (
                <div className='bd-accept-issue__bot'>
                  <label>Comment</label>
                  <div className='bd-accept-issue__comment'>
                    {type === 'transfer' || type === 'process' ? (
                      <div className='bd-form__row'>
                        <input
                          type='text'
                          name='comment'
                          placeholder='Add your comment'
                          value={transferComment}
                          onChange={(e) => setTransferComment(e.target.value)}
                        />
                      </div>
                    ) : (
                      <span>{patientComment}</span>
                    )}
                  </div>
                </div>
              )}
              {/* {type === 'process'? (
                <BagList />
              ) : ( */}
              {type != 'process' && (
                <div className='bd-accept-issue__radio'>
                  <RadioButton
                    label='Will be delivered'
                    name='radio1'
                    value='deliver'
                    onRadioChange={onRadioChange}
                  />
                  <RadioButton
                    label='To be collected'
                    name='radio1'
                    value='collected'
                    onRadioChange={onRadioChange}
                  />
                </div>
              )}
              {/* )} */}

              <div className='bd-btn__wrap'>
                <div className='bd-btn bd-btn--outline' onClick={onCancelClick}>
                  <span>Cancel</span>
                </div>
                <div
                  className='bd-btn bd-btn--primary bd-btn--issue'
                  disabled={type === 'unregistered' ? false : disableButton}
                  onClick={
                    type === 'unregistered'
                      ? addPatient
                      : disableButton
                      ? null
                      : onButtonClick
                  }
                >
                  <span>
                    {type === 'unregistered' && userList.length !== 0
                      ? 'Issue'
                      : button}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
