import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  INVENTORY_TABLE_HEADERS,
  INVENTORY_SUB_CELL_LIST,
  BLOOD_COMPONENTS,
} from '../../utils/constants';
import { getPatientRequestData } from '../../redux/actions/inventoryAction';
import AddInventory from './AddInventory';
import { Loader, SuccessPopup, FailPopup } from '../../components';
import Modal from './Modal';
import AddPatientContainer from '../../containers/InventoryContainer/AddPatientContainer';
import SettingInventoryView from '../SettingsInventoryView/index';
// import SettingInventoryView from "../../views/SettingsInventoryView";
import BloodComponentContainer from '../../containers/InventoryContainer/BloodComponentContainer';
import {
  INVENTORY_CHANGE_STATUS,
  INVENTORY_TRANSFER_PROCESS,
} from '../../redux/actions/actionTypes';

function InventoryView({
  inventoryList,
  bloodComponents,
  inventoryStatus,
  onCellClick,
  inventoryProps,
  onBtnClick,
  getInventoryList,
  resetInventoryProps,
}) {
  const dispatch = useDispatch();

  const { inventoryChangeStatus } = useSelector(
    (state) => state.dashboardReducer
  );
  const { transferProcessData } = useSelector(
    (state) => state.inventoryReducer
  );

  const [showSubCellPopup, setSubCellPopup] = useState(false);

  const [subCellId, setSubCellId] = useState('');

  const [currBloodGroup, setCurrBloodGroup] = useState(null);

  const [onAddInventory, setOnAddInventory] = useState(false);

  const [showInventory, setShowInventory] = useState(false);

  const [showIssueToProcess, setShowIssueToProcess] = useState(false);

  const [showAddPatientPopup, setShowAddPatientPopup] = useState(false);

  const [componentList, setComponentList] = useState([]);

  const [unitValue, setUnitValue] = useState(null);

  const [showSuccess, setShowSuccess] = useState(false);

  const [showFail, setFail] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errors, setError] = useState('');

  const [successMsg, setSuccessMsg] = useState('');

  const [showXlUplodings, setShowXlUplodings] = useState(false);

  useEffect(() => {
    // if (inventoryProps.type === 'process') {
    //   setShowIssueToProcess(inventoryProps.showIssueToProcess);
    // } else {
    setShowInventory(inventoryProps.showInventoryPopUp);
    // }
  }, [inventoryProps]);

  useEffect(() => {
    setLoadingState();
  }, [inventoryChangeStatus, transferProcessData, inventoryStatus]);

  useEffect(() => {
    dispatch(getPatientRequestData({ bloodGroup: currBloodGroup }));
  }, [currBloodGroup]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  }, []);

  function escFunction(e) {
    if (e.keyCode === 27) {
      setOnAddInventory(false);
      setShowInventory(false);
      setShowIssueToProcess(false);
    }
  }

  function showHideInventoryPopup() {
    setOnAddInventory(!onAddInventory);
    onAddInventory && getInventoryList();
    resetInventoryProps();
  }

  function handleCellClick(e) {
    e.preventDefault();

    const { id, bg } = e.currentTarget.dataset;
    // set's cell popup value to true when it's false and vice versa
    setSubCellPopup(!showSubCellPopup);

    // set's current cell id in combination - comp_name + blood_group + count
    setSubCellId(id);

    // set's current blood group
    setCurrBloodGroup(bg);
  }

  function handleOnBlur() {
    // hide the popup open
    setSubCellPopup(false);
  }

  function addPatient() {
    setShowAddPatientPopup(true);
  }

  function getSelectedComponent(list) {
    setComponentList(list);
  }

  function getUnitValue(obj) {
    setUnitValue(obj);
  }

  function renderTable() {
    return (
      <div className='bd-tbl'>
        <div className='bd-tbl__head'>
          <div className='bd-tbl__row'>{renderTableHeads()}</div>
        </div>
        <div className='bd-tbl__body'>{renderTableRows()}</div>
      </div>
    );
  }

  function renderTableHeads() {
    return INVENTORY_TABLE_HEADERS.map((header, id) => {
      let tabCellClass =
        id === 0 ? 'bd-tbl__cell3' : 'bd-tbl__cell3 bd-tbl__cell--center';
      return (
        <div className={tabCellClass}>
          <span>{header}</span>
        </div>
      );
    });
  }

  function renderTableRows() {
    return bloodComponents.map((row, id) => {
      let cls = '';
      if (id > bloodComponents.length - 4) {
        cls = 'bd-10plus';
      }
      return (
        <div key={`${row}_${id}`} className={['bd-tbl__row', cls].join(' ')}>
          <div className='bd-tbl__cell3'>
            {BLOOD_COMPONENTS[row] ? BLOOD_COMPONENTS[row] : row}
          </div>
          {renderTableCells(row)}
        </div>
      );
    });
  }

  function renderSubCells(cellValue) {
    return (
      <div className={`bd-tbl__cell--filterpp`}>
        {renderList(INVENTORY_SUB_CELL_LIST, cellValue)}
      </div>
    );
  }

  function renderList(list, cellValue) {
    return (
      <React.Fragment>
        {list.map((ssList, id) => {
          let subListActiveClass = ssList?.superSubList ? 'active' : '';
          const onTriggerCellClick =
            ssList.id === 'process' && cellValue >= 6
              ? onCellClick
              : ssList.id !== 'process'
              ? onCellClick
              : null;

          return (
            <div
              key={id}
              data-id={ssList.id}
              className={`bd-filterpp__list ${subListActiveClass}`}
              onClick={onTriggerCellClick}
            >
              <span className=''>{ssList.label}</span>
              {subListActiveClass ? (
                <div
                  className={[
                    'bd-filterpp__sub',
                    ssList.superSubList[0].showInventory && 'sub_sub_list',
                  ].join(' ')}
                >
                  {' '}
                  {renderList(ssList.superSubList, cellValue)}{' '}
                </div>
              ) : null}
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  function renderTableCells(row) {
    // cloned header details
    let bloodGroups = [...INVENTORY_TABLE_HEADERS];

    // removes first element from the array
    bloodGroups.splice(0, 1);

    return bloodGroups.map((bloodGroup, id) => {
      const cell = inventoryList.find((elem) => elem._id === bloodGroup);
      let cellValue = cell?.[row] ?? 0;

      let elementId = `${row}_${bloodGroup}_${id}`;
      let subCellClass =
        showSubCellPopup && subCellId === elementId ? 'active' : '';
      return (
        <div
          data-id={`${elementId}`}
          key={`${elementId}`}
          className={`bd-tbl__cell3 bd-tbl__cell--center bd-tbl__cell--filter ${subCellClass}`}
          data-bg={bloodGroup}
          tabIndex={'0'}
          onClick={cellValue > 0 && handleCellClick}
          onBlur={handleOnBlur}
        >
          {cellValue}
          {cellValue ? <i className='bludbig-arrow' /> : null}
          {showSubCellPopup && subCellClass ? renderSubCells(cellValue) : null}
        </div>
      );
    });
  }

  function setLoadingState() {
    inventoryChangeStatus.loading || transferProcessData.loading
      ? setLoading(true)
      : setLoading(false);

    if (
      inventoryChangeStatus.data !== null ||
      transferProcessData.data !== null
    ) {
      if (inventoryChangeStatus.data) {
        setSuccessMsg(inventoryChangeStatus.data.message);
      } else if (transferProcessData.data) {
        setSuccessMsg(transferProcessData.data.message);
      }
      setShowSuccess(true);
    } else if (
      inventoryChangeStatus.error !== null ||
      transferProcessData.error !== null
    ) {
      setError(inventoryChangeStatus.error || transferProcessData.error);
      setFail(true);
    } else {
      setFail(false);
      setShowSuccess(false);
    }
  }

  function onStatusPopupClick() {
    setFail(false);
    setShowSuccess(false);
    setShowIssueToProcess(false);
    dispatch({ type: INVENTORY_CHANGE_STATUS, loading: false });
    dispatch({ type: INVENTORY_TRANSFER_PROCESS, loading: false });
  }

  function renderPopUp() {
    if (onAddInventory) {
      return (
        <AddInventory
          showHideInventoryPopup={showHideInventoryPopup}
          bloodComponents={bloodComponents}
        />
      );
    }
    if (showInventory) {
      return (
        <Modal
          {...inventoryProps}
          data={inventoryList.filter((a) => a._id === currBloodGroup)[0]}
          currBloodGroup={currBloodGroup}
          bloodComponent={subCellId}
          componentList={componentList}
          processValue={unitValue}
          onBtnClick={onBtnClick}
          setShowInventory={setShowInventory}
          addPatient={addPatient}
        />
      );
    }
    if (showIssueToProcess) {
      return (
        <BloodComponentContainer
          {...inventoryProps}
          currBloodGroup={currBloodGroup}
          bloodComponent={subCellId}
          setShowInventory={setShowInventory}
          setShowIssueToProcess={setShowIssueToProcess}
          getSelectedComponent={getSelectedComponent}
          getUnitValue={getUnitValue}
        />
      );
    }
  }
  var inventoryBtn = true;
  return (
    <>
      {showXlUplodings ? (
        <SettingInventoryView
          inventoryBtn={inventoryBtn}
          setShowXlUplodings={setShowXlUplodings}
        />
      ) : (
        <article className='bd-article'>
          <section className='bd-dashboard'>
            <div className='bd-dashboard__container'>
              <div className='bd-dashboard__lft'>
                <div className='bd-dashboard__header bd-header__search bd-inventory-header-custom'>
                  <span className='bd-dashboard__main'>Inventory</span>
                  <div className='bd-hader bd-hader-custom'>
                    {/* <Link to="/SettingInventoryView">
                  <button className="si-btn bd-btn bd-btn--primary">
                    Upload File
                  </button>
                </Link> */}
                    <div
                      className='bd-file-upload'
                      onClick={() => setShowXlUplodings(true)}
                    >
                      <button className='si-btn bd-btn bd-btn--primary'>
                        Upload Inventory
                      </button>
                    </div>
                    <div className='bd-add' style={{ marginLeft: '10px' }}>
                      <i
                        onClick={showHideInventoryPopup}
                        className='bludplus'
                      />
                    </div>
                  </div>
                </div>
                <div className='bd-people'>{renderTable()}</div>
              </div>
            </div>
          </section>
          {renderPopUp()}
          {showAddPatientPopup && (
            <AddPatientContainer
              currBloodGroup={currBloodGroup}
              bloodComponent={subCellId}
              bloodComponents={bloodComponents}
              setShowAddPatientPopup={setShowAddPatientPopup}
            />
          )}
          {showSuccess && (
            <SuccessPopup
              title={successMsg}
              onContinueClick={onStatusPopupClick}
            />
          )}
          {showFail && (
            <FailPopup title={errors} onContinueClick={onStatusPopupClick} />
          )}
          {loading && <Loader />}
        </article>
      )}
    </>
  );
}

export default InventoryView;
