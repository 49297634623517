import React from 'react';
// import recevied from '../../assets/images/recevied.png';
// import used from '../../assets/images/used.png';
// import inventory from '../../assets/images/inventory.png';
// import { ClickAwayListener } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const Tile = ({ dataArray, institutionType }) => {
  const history = useHistory();

  function clickOnTiles(btn) {
    switch (btn) {
      case 'Donors Nearby':
        return history.push('/nearby-donors');
      case 'Available for Donation':
        return history.push('/available-for-donation');
      case 'Received':
        return history.push('/received');
      case 'Issued':
        return history.push('/inventory-issued');
      case 'Inventory':
        return history.push('/inventory');
      case 'noClick':
      default:
    }
  }

  return (
    <div className='bd-dashboard-counts'>
      {dataArray.map((data, i) => {
        return (
          <div
            className='d-flex justify-content-between align-items-center  tileHover' //bd-pointer
            key={i}
            onClick={() => clickOnTiles(data?.btn)}
          >
            <div>
              <div className='bd-count-label'>{data.title}</div>
              <div className='bd-count-value'>{data.count}</div>
            </div>
            <div className='bd-count-circle'>
              <img className='bd-count-img' src={data.img} alt='img' />
            </div>
          </div>
        );
      })}
    </div>
    // <div className="bd-mod bd-mod__total">
    //   <div className="bd-mod__container">
    //     {dataArray.map((obj, i) => {
    //       let img = "";
    //       if (obj?.title.toLowerCase() === "received") {
    //         img = "bluddonation opacitynull";
    //       } else if (obj?.title.toLowerCase() === "used") {
    //         img = "bludissued opacitynull";
    //       } else if (obj?.title.toLowerCase() === "inventory") {
    //         img = "bludblood-bag opacitynull";
    //       }
    //       return (
    //         <div key={i} className="bd-mod__total--wrap">
    //           <div className="bd-mod__total--num">
    //             <i className={img}></i>
    //             <span>{obj.count}</span>
    //           </div>
    //           <label key={"label" + i}>{obj.title}</label>
    //         </div>
    //       );
    //     })}
    //   </div>
    // </div>
  );
};
