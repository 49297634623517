import React, { useState } from 'react';
import { array } from 'prop-types';
import DatePicker from '../../components/DatePicker';
import DropDown from '../../components/RDropDown';
import {
  BLOOD_GROUP_OPTIONS_1,
  BLOOD_STATIC_COMPONET_LIST,
} from '../../utils/constants';

function AddUpdateStock({ setUpdateStockPopUp, setUpdaetStockList }) {
  const [error, setError] = useState('');
  const [bloodGroup, setBloodGroupSelected] = useState('A+');
  const [bloodComponent, setBloodCompSelected] = useState('Whole Blood');
  const [expiryDate, setExpiryDate] = useState(undefined);
  const [processedDate, setProcessedDate] = useState(new Date());

  function getSelectedDate(date) {
    setExpiryDate(date);
  }

  function getProcessdDate(date) {
    setProcessedDate(date);
  }

  function onAddClick(e) {
    e.preventDefault();

    const { bag_id, volume, location } = e.target;
    const payload = {
      bagId: bag_id.value,
      bloodGroup: bloodGroup,
      component: bloodComponent,
      processedDate,
      expiryDate,
      volume: volume.value,
      storedIn: location.value,
    };
    if (
      bag_id.value == '' ||
      volume.value == '' ||
      location.value == '' ||
      bloodGroup == '' ||
      bloodComponent == '' ||
      processedDate == undefined ||
      expiryDate == undefined
    ) {
      setError('All Fileds Is Required');
    } else {
      setUpdaetStockList((list) => {
        return [...list, payload];
      });
      setUpdateStockPopUp(false);
    }
  }

  function fetchBloodGroup({ value }) {
    setBloodGroupSelected(value);
  }

  function fetchBloodComponent({ value }) {
    setBloodCompSelected(value);
  }
  return (
    <div className='bd-modal-wrap bd-modal-right bd-addinvent2'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Update inventory</span>
          <i onClick={() => setUpdateStockPopUp(false)} className='bludclose' />
        </div>
        <div className='bd-popup__body'>
          <form onSubmit={onAddClick}>
            <div className='bd-form'>
              <div className='bd-form__row'>
                <label>Bag ID/Unit No</label>
                <input type='text' name='bag_id' />
              </div>
              <div className='bd-form__row'>
                <label>Blood group</label>
                <div className='bd-dorpdown'>
                  <div className='bd-dorpdown__selected'>
                    <DropDown
                      options={BLOOD_GROUP_OPTIONS_1}
                      fetchDropDownValue={fetchBloodGroup}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
              </div>
              <div className='bd-form__row'>
                <label>Components</label>
                <div className='bd-dorpdown'>
                  <div className='bd-dorpdown__selected'>
                    <DropDown
                      options={BLOOD_STATIC_COMPONET_LIST}
                      fetchDropDownValue={fetchBloodComponent}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
              </div>

              <div className='bd-form__row'>
                <div className='bd-calender'>
                  <label>Processed On </label>
                  <DatePicker getSelectedDate={getProcessdDate} />
                </div>
              </div>

              <div className='bd-form__row'>
                <div className='bd-calender'>
                  <label>Expiry On</label>
                  <DatePicker getSelectedDate={getSelectedDate} />
                </div>
              </div>
              <div className='bd-form__row'>
                <label>Volume</label>
                <input type='text' name='volume' />
              </div>
              <div className='bd-form__row'>
                <label>Location</label>
                <input type='text' name='location' />
              </div>
            </div>
            <div className='text-center bd_error mb-10'>{error}</div>
            <div className='bd-btn__wrap'>
              <button type='submit' className='bd-btn bd-btn--primary'>
                <span>Add</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className='bd-popup__lightbox' />
    </div>
  );
}

AddUpdateStock.propTypes = {
  bloodComponents: array,
};

export default AddUpdateStock;
