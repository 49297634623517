import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPasswordView from '../../views/ForgetPasswordView';
import {
  triggerForgotPassword,
  triggerSetEmailForOTP,
} from '../../redux/actions/authAction';

function ForgetPasswordContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { forgetPassword } = useSelector((state) => state.authReducer);
  const [errorMsg, setError] = useState('');
  const forgPassObj = {
    loginId: '',
  };

  const [forgPassCred, setForgPassCred] = useState(forgPassObj);
  const onChangeText = (e) => {
    let updatedForgPassObj = forgPassCred;
    updatedForgPassObj[e.target.name] = e.target.value;
    setForgPassCred(updatedForgPassObj);
  };

  const onSubmit = () => {
    if (forgPassCred.loginId !== '') {
      setError('');
      return dispatch(
        triggerForgotPassword(forgPassCred, () => {
          history.push('/otp');
          return dispatch(triggerSetEmailForOTP(forgPassCred));
        })
      );
    } else {
      setError('Enter valid id');
    }
  };

  return (
    <ForgotPasswordView
      forgetPassword={forgetPassword}
      errorMsg={errorMsg}
      onChangeText={onChangeText}
      onSubmit={onSubmit}
    />
  );
}

export default ForgetPasswordContainer;
