import { makeAPICall } from '../../utils';
import {
  API_BASE_PATH,
  GET_LAB_RESULTS_URL,
  POST_LAB_URL,
  GET_LAB_RESULTS_DETAILS_URL,
  ACCEPT_LAB_RESULTS,
  GET_LAB_CRITERIA_URL,
} from '../../utils/config';
import {
  GET_LAB_RESULTS,
  GET_LAB_RESULTS_SUCCESS,
  GET_LAB_RESULTS_FAILED,
  POST_LAB_RESULTS,
  POST_LAB_RESULTS_SUCCESS,
  POST_LAB_RESULTS_FAILED,
  GET_LAB_RESULTS_DETAILS,
  GET_LAB_RESULTS_DETAILS_SUCCESS,
  GET_LAB_RESULTS_DETAILS_FAILED,
  CURR_SELECTED_LABS,
  GET_LAB_RESULT_SAMPLES,
  GET_LAB_RESULT_SAMPLES_SUCCESS,
  GET_LAB_RESULT_SAMPLES_FAILED,
  ACCEPT_LABS,
  ACCEPT_LABS_SUCCESS,
  ACCEPT_LABS_FAILED,
  GET_LABS_CRITERIA,
  GET_LABS_CRITERIA_SUCCESS,
  GET_LABS_CRITERIA_FAILED,
} from './../actions/actionTypes';

export function getLabResults(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_LAB_RESULTS_URL}`,
      dispatch,
      defaultAction: GET_LAB_RESULTS,
      successAction: GET_LAB_RESULTS_SUCCESS,
      failedAction: GET_LAB_RESULTS_FAILED,
      headers: {},
      type: 'GET',
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getLabCriteriaResult(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_LAB_CRITERIA_URL}`,
      dispatch,
      defaultAction: GET_LABS_CRITERIA,
      successAction: GET_LABS_CRITERIA_SUCCESS,
      failedAction: GET_LABS_CRITERIA_FAILED,
      headers: {},
      type: 'GET',
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerSetCurrSelected(obj) {
  return {
    type: CURR_SELECTED_LABS,
    payload: obj,
  };
}

export function triggerPostLabResult(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_LAB_URL}`,
      dispatch,
      defaultAction: POST_LAB_RESULTS,
      successAction: POST_LAB_RESULTS_SUCCESS,
      failedAction: POST_LAB_RESULTS_FAILED,
      headers: {},
      params: obj,
      callback,
      type: 'POST',
    };
    makeAPICall(params);
  };
}

export function triggerGetLabDetails(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_LAB_RESULTS_DETAILS_URL}`,
      dispatch,
      defaultAction: GET_LAB_RESULTS_DETAILS,
      successAction: GET_LAB_RESULTS_DETAILS_SUCCESS,
      failedAction: GET_LAB_RESULTS_DETAILS_FAILED,
      headers: {},
      type: 'GET',
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getLabSamples(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_LAB_RESULTS_URL}?type=samples`,
      dispatch,
      defaultAction: GET_LAB_RESULT_SAMPLES,
      successAction: GET_LAB_RESULT_SAMPLES_SUCCESS,
      failedAction: GET_LAB_RESULT_SAMPLES_FAILED,
      headers: {},
      type: 'GET',
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function acceptLabSamples(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ACCEPT_LAB_RESULTS}`,
      dispatch,
      defaultAction: ACCEPT_LABS,
      successAction: ACCEPT_LABS_SUCCESS,
      failedAction: ACCEPT_LABS_FAILED,
      headers: {},
      params: obj,
      callback,
      type: 'POST',
    };
    makeAPICall(params);
  };
}
