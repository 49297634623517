// import React from 'react';
import React, { useState } from 'react';
import { ageCalculation, genderWiseProfilePic } from '../../utils';
import SuperAdminBlockUnBlock from './SuperAdminBlockUnBlock';

export function SuperAdminUserProfilePopup({
  profileData,
  setShowProfilePopup,
  setShowSuccessPopup,
  setSuccessMsg,
  permisssions,
}) {
  console.log(
    '🚀 ~ file: SuperAdminUserProfilePopup.js ~ line 13 ~ permisssions',
    permisssions
  );

  const cells = [
    { label: 'Name:', value: profileData.name },
    { label: 'Gender:', value: profileData.gender },
    {
      label: 'Age:',
      value:
        profileData && profileData.dob ? ageCalculation(profileData.dob) : '',
    },
    { label: 'User ID:', value: profileData.userId ?? '--' },
    { label: 'Since:', value: profileData.date ?? '--' },
    { label: 'Email:', value: profileData.email ?? '--' },
    { label: 'Phone:', value: profileData.mobileNumber ?? '--' },
    { label: 'Location:', value: profileData.address ?? '--' },
    { label: 'Donations:', value: profileData.donations ?? '--' },
    { label: 'Lives Saved:', value: profileData.livesSaved ?? '--' },
    { label: 'Request:', value: profileData.request ?? '--' },
    // { label: 'Available since:', value: profileData.availableDate ?? '--' },
  ];

  const [showComp, setShowComp] = useState(false);

  return (
    <div className='bd-modal-wrap bd-profile'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Profile of User ID - {profileData.userId}</span>
          <i
            className='bludclose'
            onClick={() => {
              setShowProfilePopup(false);
            }}
          ></i>
        </div>
        <div className='bd-popup__body'>
          <div className='bd-profile__tbl'>
            <div className='bd-profile__detail--tbl'>
              <div className='bd-profile__detail--lft'>
                <div className='bd-profile__detail'>
                  <div
                    className='bd-profile__img'
                    style={{
                      backgroundImage: profileData.photo
                        ? `url(${profileData.photo})`
                        : `url(${genderWiseProfilePic(profileData.gender)})`,
                    }}
                  ></div>
                  <div className='bd-profile__group'>
                    <span>{profileData.bloodGroup}</span>
                  </div>
                </div>
                <div className='bd-tbl bd-tbl__theme2'>
                  <div className='bd-tbl__body'>
                    {cells.map((cell) => {
                      return (
                        <div className='bd-tbl__row'>
                          <div className='bd-tbl__cell'>
                            <span>{cell.label}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{cell.value}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {showComp && (
              <SuperAdminBlockUnBlock
                ID={profileData.userId}
                type={profileData.block}
                setShowComp={setShowComp}
                setShowSuccessPopup={setShowSuccessPopup}
                setSuccessMsg={setSuccessMsg}
                setShowProfilePopup={setShowProfilePopup}
              />
            )}

            {!showComp && (
              <div className='bd-btn__wrap'>
                <div
                  className='bd-btn bd-btn--outline'
                  onClick={() => {
                    setShowProfilePopup(false);
                  }}
                >
                  <span>Cancel</span>
                </div>
                {permisssions?.userManagement?.update && (
                  <div>
                    {!profileData.block ? (
                      <div
                        className='bd-btn bd-btn--primary'
                        onClick={() => {
                          setShowComp(true);
                        }}
                      >
                        <span>Block</span>
                      </div>
                    ) : (
                      <div
                        className='bd-btn bd-btn--primary'
                        onClick={() => {
                          setShowComp(true);
                        }}
                      >
                        <span>Unblock</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
