import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Loader } from '../../components';
import { sorting } from '../../utils';
import CampDonorsView from '../../views/CampView/CampDonorsView';
import {
  getOnProceedClick,
  // getCampsDetail,
} from '../../redux/actions/campsAction';

//Props is Comeing From CampContainer(INDEX.JS)
function CampDonorsContainer({
  campDetails,
  campDonorsDetailes,
  setDonorPage,
  APICallAgain,
}) {
  const dispatch = useDispatch();
  const { onProceedclick } = useSelector((state) => state.campsReducer);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const [campDonorsList, setCampDonorsList] = useState([]);
  const [donorSearch, setDonorSearch] = useState('');

  // const [showSuccess, setShowSuccess] = useState(false);

  const [donatFrom, setDonateForm] = useState(false);
  const [proceedForm, setProceedForm] = useState(false);

  const [campDonorsListObj, setCampDonorsListObj] = useState('');

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...campDonorsList];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setCampDonorsList(data);
    setCount(count + 1);
  }

  function setDonorsList(donorsData) {
    let Data = [];
    donorsData &&
      donorsData.forEach((record) => {
        let obj = {
          name: record?.firstName + ' ' + record?.lastName,
          bloodGroup: record?.bloodGroup || '--',
          donorId: record?.donorId || '--',
          mobileNo: record?.mobileNumber || '--',
          email: record?.email ? record?.email : '--',
          status:
            record?.status === 'Active' ? 'Fit To Donate' : 'Assess Donor',
          Button:
            record?.donationStatus === 0 ? (
              <ButtonDonate id={record.donorId} onDonateClick={onDonateClick} />
            ) : record?.donationStatus === 7 ? (
              <ButtonProcced
                id={record.donorId}
                onProccedClick={onProccedClick}
                APICallAgain={APICallAgain}
              />
            ) : (
              <div className='bd-clr-green text-center'>Donated</div>
            ),
        };
        Data.push(obj);
      });
    return Data;
  }

  function ButtonDonate(props) {
    return (
      <div class='bd-request-otp' id={props.id}>
        <div
          className='bd-request-accept-blur bd-request-otp-stars brder_Radius'
          onClick={props.onDonateClick}
          id={props.id}
        >
          <span onClick={props.onDonateClick} id={props.id}>
            <i id={props._id} className='bludcamps'></i>
          </span>
        </div>
        <div
          onClick={props.onDonateClick}
          id={props.id}
          className=' bd-request-accept bd-black active'
        >
          <span id={props.id}>Donate</span>
        </div>
      </div>
    );
  }
  function ButtonProcced(props) {
    return (
      <div class='bd-request-otp' id={props.id}>
        <div
          className='bd-request-proceed-blur bd-request-otp-stars brder_Radius'
          onClick={props.onProccedClick}
          id={props.id}
        >
          <span onClick={props.onProccedClick} id={props.id}>
            <i id={props._id} className='bludlab-results'></i>
          </span>
        </div>
        <div
          onClick={props.onProccedClick}
          id={props.id}
          className=' bd-request-proceed bd-black active'
        >
          <span id={props.id}>Proceed</span>
        </div>
      </div>
    );
  }

  function onDonateClick(e) {
    setCampDonorsListObj(
      campDonorsDetailes?.data?.donors.filter(
        (ele) => ele.donorId == e.target.id
      )[0]
    );
    setDonateForm(true);
  }

  function onProccedClick(e) {
    let obj = {
      donorId: '',
      inventoryId: '',
      campId: '',
    };
    setCampDonorsListObj(
      campDonorsDetailes?.data?.donors.filter((ele) => {
        if (ele.donorId == e.target.id) {
          obj['donorId'] = ele.donorId;
          obj['inventoryId'] = ele.inventoryId;
          obj['campId'] = ele.campId;
          return ele;
        }
      })[0]
    );
    setProceedForm(true);
    dispatch(getOnProceedClick(obj));
  }

  const [ProceedDonorData, setProceedDonorData] = useState({});
  useEffect(() => {
    if (onProceedclick) setProceedDonorData(onProceedclick?.data);
  }, [onProceedclick]);

  function onCloseClick() {
    setDonateForm(false);
    setProceedForm(false);
  }

  function finalortedCampList() {
    return donorSearch === ''
      ? campDonorsList
      : DonorsSearchData(campDonorsList);
  }

  function DonorsSearchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.name.toLowerCase().includes(donorSearch.toLowerCase()) ||
          String(items.bloodGroup)
            .toLowerCase()
            .includes(donorSearch.toLowerCase()) ||
          String(items.mobileNo)
            .toLowerCase()
            .includes(donorSearch.toLowerCase()) ||
          String(items.email)
            .toLowerCase()
            .includes(donorSearch.toLowerCase()) ||
          String(items.status)
            .toLowerCase()
            .includes(donorSearch.toLowerCase()) ||
          String(items.donorId)
            .toLowerCase()
            .includes(donorSearch.toLowerCase())
      )
    );
  }

  useEffect(() => {
    if (campDonorsDetailes) {
      setCampDonorsList(setDonorsList(campDonorsDetailes?.data?.donors));
    }
  }, [campDonorsDetailes?.data?.donors]);

  return (
    <>
      <CampDonorsView
        campDetails={campDetails}
        finalDonorsList={finalortedCampList()}
        setDonorPage={setDonorPage}
        onHeaderClick={onHeaderClick}
        donorSearch={donorSearch}
        setDonorSearch={setDonorSearch}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        //DONATE btn forms
        donatFrom={donatFrom}
        // PROCEED form
        proceedForm={proceedForm}
        onCloseClick={onCloseClick}
        ProceedDonorData={ProceedDonorData}
        // onBtnClick pass Donor Object
        campDonorsListObj={campDonorsListObj}
        APICallAgain={APICallAgain}
        // showSuccess={showSuccess}
        // setShowSuccess={setShowSuccess}
      />
    </>
  );
}

export default CampDonorsContainer;
