import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { triggerAddDonor, triggerFetchAddress } from '../../redux/actions';
import AddDonorView from '../../views/AddDonorView';
import {
  BLOOD_GROUP_LIST,
  GENDER_LIST,
  questions,
} from '../../utils/constants';

export default function AddDonorContainer() {
  const { loginObj } = useSelector((state) => state.loginReducer);
  let institutionType = loginObj?.data?.content?.data?.institutionType ?? '';
  const { donorObject } = useSelector((state) => state.addDonorReducer);
  const [sucessPopup, setShowSuccessPopup] = useState(false);

  let addDonorObj = {
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    mobileNumber: '',
    dateOfBirth: '',
    aadharNumber: '',
    confirmAadhar: '',
    bloodGroup: '',
    height: '',
    weight: '',
    age: '',
    address: '',
    // address: {
    //   description: '',
    // },

    refferedBy: '',
    notes: '',
    isVerified: false,
    isSubmit: false,
    lastDonationDate: '',
    component: '',
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const [addDonor, setAddDonorObj] = useState(addDonorObj);
  const [failedPopup, setfailedPopup] = useState(false);
  const [addressFocused, setaddressFocused] = useState(false);

  const [bloodGroup, setBloodGroupSelected] = useState('A+');

  const [component, setComponentSelected] = useState('');

  const [gender, setGenderSelected] = useState('');

  // useEffect(() => {
  //   if (donorObject.data.code == 200) {
  //     setAddDonorObj(addDonorObj);
  //   }
  // }, [donorObject]);

  function onChangeText(e, name) {
    let updateAddDonorObj = { ...addDonor };
    if (name) {
      updateAddDonorObj[name] = new Date(e);
    } else {
      updateAddDonorObj[e.target.name] = e.target.value;
    }
    setAddDonorObj(updateAddDonorObj);
  }

  function checkboxClick(e, value) {
    if (e.target.id) {
      let data = [...questions];
      data.map((a, i) => {
        if (i == e.target.id) {
          a['isSelected'] = e.target.checked;
        }
      });
    } else {
      addDonor.isVerified = e.target.checked;
    }

    let updateAddDonorObj = { ...addDonor };
    setAddDonorObj(updateAddDonorObj);
  }

  /* following code for addresss api fetching */

  // function onAddressSearch(e) {
  //   if (e.target.value !== '') {
  //     onChangeText(e);
  //     return dispatch(triggerFetchAddress(e.target.value, (resp) => {}));
  //   }
  // }

  // function selectAddress(data) {
  //   setaddressFocused(false);

  //   let updateAddDonorObj = { ...addDonor };
  //   updateAddDonorObj['address'] = {
  //     coordinates: data.location.coordinates,
  //     description: data.location.description,
  //     type: data.location.type,
  //   };
  //   setAddDonorObj(updateAddDonorObj);
  // }

  function headerDropDownChange(e, name) {
    let updateAddDonorObj = { ...addDonor };
    if (e.value) {
      updateAddDonorObj[name] = e.value;
    }
    setAddDonorObj(updateAddDonorObj);
  }

  function onSubmit(e) {
    e.preventDefault();
    const {
      firstName,
      lastName,
      mobileNumber,
      email,
      dateOfBirth,
      lastDonationDate,
      address,
      aadharNumber,
      confirmAadhar,
      height,
      weight,
    } = addDonor;

    if (gender) {
      addDonor.gender = gender;
    }
    if (bloodGroup) {
      addDonor.bloodGroup = bloodGroup;
    }
    if (component) {
      addDonor.component = component;
    }

    if (
      firstName === '' ||
      lastName === '' ||
      lastDonationDate === '' ||
      dateOfBirth === ''
    ) {
      setfailedPopup(true);
      return 0;
    }
    function getBMI(height, weight) {
      return (weight / ((height * height) / 10000)).toFixed(2);
    }

    let params = {
      firstName,
      lastName,
      gender,
      email,
      dateOfBirth,
      gender,
      bloodGroup,
      bmi: getBMI(height, weight),
      aadharNumber,
      confirmAadhar,
      height,
      weight,
      component,
      lastDonationDate,
      address,
      donorType: 'walkIn',
    };

    if (mobileNumber) {
      params.mobileNumber = mobileNumber;
    }

    if (email === '') {
      delete params.email;
    }
    if (aadharNumber === '') {
      delete params.aadharNumber;
    }

    let donorArray = [];
    donorArray.push(params);
    let donorObj = {
      donors: donorArray,
    };

    return dispatch(
      triggerAddDonor(donorObj, () => {
        setAddDonorObj(addDonorObj);
        setShowSuccessPopup(true);
      })
    );
  }

  function reset() {
    setAddDonorObj(addDonorObj);
    history.push('/');
  }

  function onContinueClick() {
    setShowSuccessPopup(false);
    history.push({
      pathname: institutionType === 'NGO' ? '/' : '/donation-form',
      // pathname: '/donation-form',
      donorType: 'walkIn',
    });
  }

  function onContinueFailedClick() {
    setfailedPopup(false);
  }

  return (
    <AddDonorView
      addDonor={donorObject}
      donorObj={addDonor}
      genderList={GENDER_LIST}
      failedPopup={failedPopup}
      bloodGroupList={BLOOD_GROUP_LIST}
      sucessPopup={sucessPopup}
      addressFocused={addressFocused}
      onChangeText={onChangeText}
      onContinueFailedClick={onContinueFailedClick}
      onSubmit={onSubmit}
      checkboxclick={checkboxClick}
      onContinueClick={onContinueClick}
      onCancelClick={reset}
      // onAddressSearch={onAddressSearch}
      // selectAddress={selectAddress}
      setaddressFocused={setaddressFocused}
      headerDropDownChange={headerDropDownChange}
      setBloodGroupSelected={setBloodGroupSelected}
      setComponentSelected={setComponentSelected}
      setGenderSelected={setGenderSelected}
    />
  );
}
