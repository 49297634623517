import {
  POST_PATIENT_DATA,
  POST_PATIENT_DATA_SUCCESS,
  POST_PATIENT_DATA_FAILED,
  GET_PATIENT_LIST,
  GET_PATIENT_LIST_SUCCESS,
  GET_PATIENT_LIST_FAILED,
} from "../actions/actionTypes";

const initialState = {
  patientObject: {
    loading: false,
    data: {},
    error: null,
  },

  patientList:{
    loading: false,
    data: {},
    error: null,
  }
};
const addPatientReducer = function (state = initialState, action) {
  switch (action.type) {
    case POST_PATIENT_DATA:
      return {
        ...state,
        patientObject: {
          loading: true,
          data: {},
          error: null,
        },
        patientList: {
          loading: true,
          data: {},
          error: null,
        },
      };

    case POST_PATIENT_DATA_SUCCESS:
      return {
        ...state,
        patientObject: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case POST_PATIENT_DATA_FAILED:
      return {
        ...state,
        patientObject: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

      case GET_PATIENT_LIST:
        return {
          ...state,
          patientList: {
            loading: true,
            data: {},
            error: null,
          },
        };
  
      case GET_PATIENT_LIST_SUCCESS:
        return {
          ...state,
          patientList: {
            loading: false,
            data: action.payload.content.data,
            error: null,
          },
        };
  
      case GET_PATIENT_LIST_FAILED:
        return {
          ...state,
          patientList: {
            loading: false,
            data: null,
            error: action.error,
          },
        };

    default:
      return state;
  }
};

export default addPatientReducer;
