import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  triggerGetInstitutionData,
  triggerGetInstitutionDetail,
  triggerApproveDecline,
} from '../../redux/actions/superAdminCommonActions';
import { sorting } from '../../utils';
import moment from 'moment';
import SuperAdminInstituionsView from '../../views/SuperAdminInstituionsView.js/SuperAdminInstituionsView';
import { Loader, SuccessPopup, FailPopup } from '../../components';
import { PendingForVerifProfilePopup } from '../../components/Popups/PendingForVerifProfilePopup';
import { triggeReCallLogin } from '../../redux/actions/superAdminCommonActions';

// hisory-- - '/Super-Admin/Instituions-Detail-Page';

function SuperAdminInstituionsContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getInstituitonList, getSingleDetails } = useSelector(
    (state) => state.superAdminCommonReducers
  );
  // console.log('getSingleDetails', getSingleDetails);

  const [activeTab, setActiveTab] = useState('Register');

  const [registerSearch, setRegisterSearch] = useState('');
  const [pendingSearch, setPendingSearch] = useState('');
  const [count, setCount] = useState(0);

  const [RegisterList, setRegisterList] = useState([]);
  const [PendingList, setPendingList] = useState([]);

  const [showProfilePopup, setShowProfilePopup] = useState(false);

  const [sucessPopup, setShowSuccessPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [failPopup, setShowFailPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [pendingProfileData, setPendingProfileData] = useState({});

  function onTabChange(e) {
    setActiveTab(e.target.id);
    setRegisterSearch('');
    setPendingSearch('');
  }

  const handleClick = (e) => {
    e.stopPropagation();
    // console.log('get PopUp Deatail', e.target.getAttribute('id'));
    if (activeTab === 'Pending') {
      dispatch(
        triggerGetInstitutionDetail(
          {
            id: e.target.getAttribute('id'),
          },
          'Pending'
        )
      );
      setShowProfilePopup(true);
    }
    if (activeTab === 'Register') {
      localStorage.setItem('ID', e.target.getAttribute('id'));

      history.push({
        pathname: '/Super-Admin/Instituions-Detail-Page',
        InstitutionID: e.target.getAttribute('id'),
      });
    }
  };

  function onPendingHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...PendingList];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setPendingList(data);
    setCount(count + 1);
  }
  function onRegisterHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...RegisterList];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setRegisterList(data);
    setCount(count + 1);
  }

  function setRegisterData(sortedData) {
    let data = [];
    sortedData &&
      sortedData?.forEach((record, i) => {
        let obj = {
          _id: record._id ? record._id : '--',
          institutionName: record?.institutionName
            ? record?.institutionName
            : '--',
          registrationNumber: record?.registrationNumber
            ? record?.registrationNumber
            : '--',
          institutionType: record?.institutionType
            ? record?.institutionType
            : '--',
          contactEmail: record?.contactEmail ? record?.contactEmail : '--',
          contactNumber: record?.contactNumber ? record?.contactNumber : '--',
          status: record?.status ? record?.status : '--',
          address: record?.address?.description
            ? record?.address?.description
            : '--',
          status:
            record?.status === 'Pending' ? (
              <div style={{ color: '#ebbf24', fontWeight: '700' }}>Pending</div>
            ) : (
              <div style={{ color: 'green', fontWeight: '700' }}>Confirmed</div>
            ),
          state:
            record?.state && record?.state === 'Blocked' ? (
              <div style={{ color: '#af0c0c', fontWeight: '700' }}>Block</div>
            ) : (
              <div style={{ color: 'green', fontWeight: '700' }}>Active</div>
            ),
          // date: moment(record?.addedAt).format('DD MMM YYYY'),
        };
        data.push(obj);
      });
    return data;
  }
  function setPendingData(sortedData) {
    let data = [];
    sortedData &&
      sortedData?.forEach((record, i) => {
        let obj = {
          _id: record._id ? record._id : '--',
          institutionName: record?.institutionName
            ? record?.institutionName
            : '--',
          registrationNumber: record?.registrationNumber
            ? record?.registrationNumber
            : '--',
          institutionType: record?.institutionType
            ? record?.institutionType
            : '--',
          contactEmail: record?.contactEmail ? record?.contactEmail : '--',
          contactNumber: record?.contactNumber ? record?.contactNumber : '--',
          status: record?.status ? (
            <div style={{ color: '#ebbf24', fontWeight: '700' }}>Pending</div>
          ) : (
            '--'
          ),
          address: record?.address?.description
            ? record?.address?.description
            : '--',

          date: moment(record?.addedAt).format('DD MMM YYYY'),
        };
        data.push(obj);
      });
    return data;
  }
  function searchPendingData(data, type) {
    console.log(data);
    return (
      data &&
      data.filter(
        (items) =>
          String(items.id).toLowerCase().includes(type.toLowerCase()) ||
          String(items.institutionName)
            .toLowerCase()
            .includes(type.toLowerCase()) ||
          String(items.registrationNumber)
            .toLowerCase()
            .includes(type.toLowerCase()) ||
          String(items.institutionType)
            .toLowerCase()
            .includes(type.toLowerCase()) ||
          String(items.contactEmail)
            .toLowerCase()
            .includes(type.toLowerCase()) ||
          String(items.address).toLowerCase().includes(type.toLowerCase()) ||
          String(items.status).toLowerCase().includes(type.toLowerCase()) ||
          String(items.contactNumber).toLowerCase().includes(type.toLowerCase())
      )
    );
  }

  function pendingDataList() {
    return pendingSearch === ''
      ? PendingList
      : searchPendingData(PendingList, pendingSearch);
  }
  function registerDataList() {
    return registerSearch === ''
      ? RegisterList
      : searchPendingData(RegisterList, registerSearch);
  }
  useEffect(() => {
    if (getSingleDetails?.data?.content?.data) {
      let detail = getSingleDetails?.data?.content?.data;
      setPendingProfileData({
        id: detail?._id ?? '--',
        name: detail?.institutionName || '--',
        email: detail?.contactEmail || '--',
        number: detail?.contactNumber || '--',
        type: detail?.institutionType || '--',
        address: detail?.address?.description || '--',
        regNo: detail?.registrationNumber || '--',
        date: moment(detail?.addedAt).format('DD MMM YYYY') || '--',
      });
    }
  }, [getSingleDetails?.data?.content?.data]);

  useEffect(() => {
    if (getInstituitonList?.data?.content?.data) {
      if (activeTab === 'Register') {
        setRegisterList(
          setRegisterData(getInstituitonList?.data?.content?.data)
        );
      }
      if (activeTab === 'Pending') {
        setPendingList(setPendingData(getInstituitonList?.data?.content?.data));
      }
    }
  }, [getInstituitonList?.data?.content?.data]);

  useEffect(() => {
    if (activeTab === 'Register') {
      dispatch(triggerGetInstitutionData('Register'));
    }
    if (activeTab === 'Pending') {
      dispatch(triggerGetInstitutionData('Pending'));
    }
    localStorage.removeItem('ID');
  }, [activeTab]);

  const [CommentValue, setCommentValue] = useState('');

  function callBack(status) {
    setShowProfilePopup(false);
    setShowSuccessPopup(true);
    setSuccessMsg(
      status === 'declined'
        ? 'Request Decline Successfully!'
        : 'Request Approve Successfully!'
    );
  }
  function escFunction(e) {
    if (e.keyCode === 27) {
      // hidePopup();

      setShowProfilePopup(false);
      setShowSuccessPopup(false);
      setShowFailPopup(false);
      dispatch(triggerGetInstitutionData());
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  }, []);

  function hidePopup() {
    setShowSuccessPopup(false);
    setShowFailPopup(false);
    // dispatch(triggerGetInstitutionData('Pending'));
    // dispatch(triggerGetInstitutionData('Register'));

    if (activeTab === 'Pending') {
      dispatch(triggerGetInstitutionData('Pending'));
    }
    if (activeTab === 'Register') {
      dispatch(triggerGetInstitutionData('Register'));
    }
    dispatch(triggerGetInstitutionData(activeTab));
  }

  function ApproveDeclineAction(status, id) {
    let param = {
      id,
      status,
    };
    if (status === 'declined') param.reason = CommentValue;
    dispatch(triggerApproveDecline(param, () => callBack(status)));
  }

  return (
    <>
      {getInstituitonList.loading ? (
        <Loader />
      ) : (
        <SuperAdminInstituionsView
          pendingList={pendingDataList()}
          registeredList={registerDataList()}
          onRegisterHeaderClick={onRegisterHeaderClick}
          onPendingHeaderClick={onPendingHeaderClick}
          registerSearch={registerSearch}
          pendingSearch={pendingSearch}
          setRegisterSearch={setRegisterSearch}
          setPendingSearch={setPendingSearch}
          activeTab={activeTab}
          onTabChange={onTabChange}
          handleClick={handleClick}
        />
      )}
      {showProfilePopup && (
        <PendingForVerifProfilePopup
          profileData={pendingProfileData}
          setShowProfilePopup={setShowProfilePopup}
          setCommentValue={setCommentValue}
          ApproveDeclineAction={ApproveDeclineAction}
        />
      )}
      {sucessPopup && (
        <SuccessPopup title={successMsg} onContinueClick={() => hidePopup()} />
      )}
      {failPopup && (
        <FailPopup title={errorMsg} onContinueClick={() => hidePopup()} />
      )}
    </>
  );
}

export default SuperAdminInstituionsContainer;
