import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UpdateStockView from '../../views/InventoryView/UpdateStockView';
import AddUpdateStock from '../../views/InventoryView/AddUpdateStock';
import { sorting } from '../../utils';
import { FailPopup, SuccessPopup } from '../../components';
import moment from 'moment';

function UpdateStockContainer() {
  const history = useHistory();

  const [updateStockPopUp, setUpdateStockPopUp] = useState(false);
  // following State for Peginations
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);

  const [updaetStockList, setUpdaetStockList] = useState([]);
  const [sortedDataList, setSortedDataList] = useState([]);
  const [search, setSearch] = useState('');

  const [failedPopup, setfailedPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...sortedDataList];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setSortedDataList(data);
    setCount(count + 1);
  }

  function headerDropDownChange(e) {
    let data = setData(updaetStockList);
    if (e.value === 'All/Any') {
      data = [...setData(updaetStockList)];
    } else {
      data = data.filter((a) => a.bloodGroup === e.value);
    }
    setSortedDataList(data);
  }

  function setData(sortedData) {
    let data = [];
    sortedData &&
      sortedData.forEach((record, i) => {
        let obj = {
          bagId: record?.bagId,
          bloodGroup: record?.bloodGroup,
          component: record?.component,
          storedIn: record?.storedIn,
          volume: record?.volume,
          expiryDate: moment(record?.expiryDate).format('DD MMM YYYY'),
          processOn: moment(record?.processedDate).format('DD MMM YYYY'),
        };
        data.push(obj);
      });
    return data;
  }

  function searchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.bagId.toLowerCase().includes(search.toLowerCase()) ||
          items.component.toLowerCase().includes(search.toLowerCase()) ||
          String(items.bloodGroup)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.expiryDate)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.processOn)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.storedIn).toLowerCase().includes(search.toLowerCase()) ||
          String(items.volume).toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  function DataList() {
    return search === '' ? sortedDataList : searchData(sortedDataList);
  }

  function closePopUp() {
    setSuccessPopup(false);
    history.push('/inventory-in-process');
  }

  useEffect(() => {
    if (updaetStockList) {
      setSortedDataList(setData(updaetStockList));
    }
  }, [updaetStockList]);

  return (
    <>
      <UpdateStockView
        sortedDataList={DataList()}
        updaetStockList={updaetStockList}
        page={page}
        search={search}
        setSearch={setSearch}
        onHeaderClick={onHeaderClick}
        headerDropDownChange={headerDropDownChange}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        setUpdateStockPopUp={setUpdateStockPopUp}
        setSuccessPopup={setSuccessPopup}
        setfailedPopup={setfailedPopup}
      />
      {updateStockPopUp && (
        <AddUpdateStock
          setUpdateStockPopUp={setUpdateStockPopUp}
          updaetStockList={updaetStockList}
          setUpdaetStockList={setUpdaetStockList}
        />
      )}
      {successPopup && (
        <SuccessPopup
          onContinueClick={() => closePopUp()}
          title='Stock Updated Successfully'
        />
      )}
      {failedPopup && (
        <FailPopup
          onContinueClick={() => setfailedPopup(false)}
          title='Please Update Stock!'
        />
      )}
    </>
  );
}

export default UpdateStockContainer;
