import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  UPDATE_SETTINGS,
  GET_LAB_RESULT_SETTINGS,
  GET_LAB_RESULT_SETTINGS_SUCCESS,
  GET_LAB_RESULT_SETTINGS_FAILED,
  POST_LAB_RESULT_SETTINGS,
  POST_LAB_RESULT_SETTINGS_SUCCESS,
  POST_LAB_RESULT_SETTINGS_FAILED,
  ADD_HOLIDAYS,
  ADD_MONTHLY_INVENTORY,
  POST_SETTINGS,
  POST_SETTINGS_SUCCESS,
  POST_SETTINGS_FAILED,
  UPLOAD_SETTINGS_INVENTORY,
  UPLOAD_SETTINGS_INVENTORY_SUCCESS,
  UPLOAD_SETTINGS_INVENTORY_FAILED,
  UPLOAD_SETTINGS_DONOR,
  UPLOAD_SETTINGS_DONOR_SUCCESS,
  UPLOAD_SETTINGS_DONOR_FAILED,
  REMOVE_LAB_FROM_SETTINGS,
  REMOVE_LAB_FROM_SETTINGS_FAILED,
  REMOVE_LAB_FROM_SETTINGS_SUCCESS,
  POST_LAB_CRITERIA,
  POST_LAB_CRITERIA_SUCCESS,
  POST_LAB_CRITERIA_FAILED,
} from './../actions/actionTypes';
// import SettingJSON from '../../utils/jsons/setting.json';
const initialState = {
  settingsObj: {
    loading: false,
    data: null,
    error: null,
  },
  getLabsSettingsObj: {
    loading: false,
    data: null,
    error: null,
  },
  postLabsSettingsObj: {
    loading: false,
    data: null,
    error: null,
  },
  postSettingObj: {
    loading: false,
    data: null,
    error: null,
  },

  postInventorySettingsObj: {
    loading: false,
    data: null,
    error: null,
  },

  postDonorSettingsObj: {
    loading: false,
    data: null,
    error: null,
  },
  postLabCriteria: {
    loading: false,
    data: null,
    error: null,
  },
};

const peopleReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settingsObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settingsObj: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };
    case GET_SETTINGS_FAILED:
      return {
        ...state,
        settingsObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case POST_SETTINGS:
      return {
        ...state,
        postSettingObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case POST_SETTINGS_SUCCESS:
      return {
        ...state,
        postSettingObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case POST_SETTINGS_FAILED:
      return {
        ...state,
        postSettingObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case GET_LAB_RESULT_SETTINGS:
      return {
        ...state,
        getLabsSettingsObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case GET_LAB_RESULT_SETTINGS_SUCCESS:
      return {
        ...state,
        getLabsSettingsObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_LAB_RESULT_SETTINGS_FAILED:
      return {
        ...state,
        getLabsSettingsObj: {
          loading: false,
          data: {},
          error: action.error,
        },
      };

    case POST_LAB_RESULT_SETTINGS:
      return {
        ...state,
        postLabsSettingsObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case POST_LAB_RESULT_SETTINGS_SUCCESS:
      return {
        ...state,
        postLabsSettingsObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case POST_LAB_RESULT_SETTINGS_FAILED:
      return {
        ...state,
        postLabsSettingsObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case REMOVE_LAB_FROM_SETTINGS:
      return {
        ...state,
        postLabsSettingsObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case REMOVE_LAB_FROM_SETTINGS_SUCCESS:
      return {
        ...state,
        postLabsSettingsObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case REMOVE_LAB_FROM_SETTINGS_FAILED:
      return {
        ...state,
        postLabsSettingsObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case UPDATE_SETTINGS:
      let { currSelected, data, type } = action.payload;
      if (type === 'radio') {
        if (data.type == 'subQuestions') {
          state.settingsObj.data[
            Object.keys(state.settingsObj.data)[currSelected]
          ][data.index]['subQuestions'][data.subDataIndex]['radios'].map(
            (d, index) => {
              if (data.radioSubDataIndex === index) {
                d.checked = true;
              } else {
                d.checked = false;
              }
              return d;
            }
          );
        } else {
          state.settingsObj.data[
            Object.keys(state.settingsObj.data)[currSelected]
          ][data.dataIndex]['radios'].map((d, index) => {
            if (data.radioDataIndex === index) {
              d.checked = true;
            } else {
              d.checked = false;
            }
            return d;
          });
        }
      } else if (type === 'input') {
        if (data.from === 'Regulated') {
          state.settingsObj.data[
            Object.keys(state.settingsObj.data)[currSelected]
          ][data.dataIndex]['radios'][1][data.option][data.subOption] =
            data.text;
        } else if (data.from === '24 x 7') {
          state.settingsObj.data[
            Object.keys(state.settingsObj.data)[currSelected]
          ][data.dataIndex]['radios'][0][data.option][data.subOption] =
            data.text;
        } else if (data.type == 'subQuestions') {
          state.settingsObj.data[
            Object.keys(state.settingsObj.data)[currSelected]
          ][data.index]['subQuestions'][data.subDataIndex]['capacity'].map(
            (d, index) => {
              if (data.capacitySubDataIndex === index) {
                d.text = data.text;
              }
              return d;
            }
          );
        } else {
          // state.settingsObj.data[Object.keys(state.settingsObj.data)[currSelected]][data.dataIndex]['text']=data.text;
          // state.settingsObj.data[Object.keys(state.settingsObj.data)[['currSelected']]][data.dataIndex].ans=""
          state.settingsObj.data[
            Object.keys(state.settingsObj.data)[currSelected]
          ][data.dataIndex]['capacity'].map((d, index) => {
            if (data.capacityDataIndex === index) {
              d.text = data.text;
            }
            return d;
          });
        }
      } else if (type === 'institueTiming') {
        state.settingsObj.data[
          Object.keys(state.settingsObj.data)[currSelected]
        ][data.dataIndex]['radios'][1]['weekDays'].map((d, index) => {
          if (data.weekDataIndex === index) {
            d.active = !d.active;
          }
          return d;
        });
      } else if (type === 'options') {
        state.settingsObj.data[
          Object.keys(state.settingsObj.data)[currSelected]
        ][data.dataIndex]['options'].map((d, index) => {
          if (data.compDataIndex === index) {
            d.checked = !d.checked;
          }
          return d;
        });
      }
      return {
        ...state,
      };

    case ADD_HOLIDAYS:
      state?.settingsObj?.data['Infrastructure'][
        state?.settingsObj?.data['Infrastructure'].length - 2
      ]['listOfHolidays'].push(action.payload);
      return {
        ...state,
      };

    case ADD_MONTHLY_INVENTORY:
      //   "infra",
      //   state?.settingsObj?.data["Infrastructure"][
      //     state?.settingsObj?.data["Infrastructure"].length - 1
      //   ]
      // );
      state.settingsObj.data['Infrastructure'][
        state?.settingsObj?.data['Infrastructure'].length - 1
      ]['monthlyInventory'][action.payload.index][action.payload.data] =
        action.payload.value;
      return {
        ...state,
      };

    case UPLOAD_SETTINGS_INVENTORY:
      return {
        ...state,
        postInventorySettingsObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case UPLOAD_SETTINGS_INVENTORY_SUCCESS:
      return {
        ...state,
        postInventorySettingsObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case UPLOAD_SETTINGS_INVENTORY_FAILED:
      return {
        ...state,
        postInventorySettingsObj: {
          loading: false,
          data: {},
          error: action.error,
        },
      };

    case UPLOAD_SETTINGS_DONOR:
      return {
        ...state,
        postDonorSettingsObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case UPLOAD_SETTINGS_DONOR_SUCCESS:
      return {
        ...state,
        postDonorSettingsObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case UPLOAD_SETTINGS_DONOR_FAILED:
      return {
        ...state,
        postDonorSettingsObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case POST_LAB_CRITERIA:
      return {
        ...state,
        postLabCriteria: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case POST_LAB_CRITERIA_SUCCESS:
      return {
        ...state,
        postLabCriteria: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case POST_LAB_CRITERIA_FAILED:
      return {
        ...state,
        postLabCriteria: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default peopleReducer;
