import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { API_BASE_PATH, UPLOAD_FILE_URL } from '../../utils/config';
import { triggerFetchInstituteList } from '../../redux/actions/dashboardAction';
import {
  addPatientFromInventory,
  getPatientRequestData,
} from '../../redux/actions/inventoryAction';
import AddPatientView from '../../views/InventoryView/AddPatientView';

export default function AddPatientContainer({
  currBloodGroup,
  bloodComponent,
  data,
  bloodComponents,
  setShowAddPatientPopup,
}) {
  const { instituteListObj } = useSelector((state) => state.dashboardReducer);
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({
    address: {
      type: '',
      coordinates: [],
      description: '',
    },
  });
  const [instituteNameFocused, setInstituteNameFocused] = useState(false);

  function onInputChange(e) {
    if (e.target.name === 'hospitalName') {
      dispatch(triggerFetchInstituteList(e.target.value));
    }
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  }
  function requestClick(obj) {
    dispatch(
      addPatientFromInventory(obj, () => {
        dispatch(getPatientRequestData({ bloodGroup: currBloodGroup }));
      })
    );
    setShowAddPatientPopup(false);
  }

  function onInstituteSelect(data) {
    setInstituteNameFocused(false);
    let updatedRegObj = { ...userDetails.address };
    updatedRegObj['type'] = data?.location?.type;
    updatedRegObj['coordinates'] = data?.location?.coordinates;
    updatedRegObj['description'] = data?.location?.description;
    setUserDetails({
      ...userDetails,
      hospitalName: data?.institutionName,
      address: updatedRegObj,
    });
  }

  const addFile = async (e) => {
    let selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    let response = await axios.post(API_BASE_PATH + UPLOAD_FILE_URL, formData, {
      headers: {
        token: `${localStorage.getItem('TOKEN_KEY')}`,
      },
    });
    let file = response.data.content.savedFiles[0];
    let arr = [...userDetails.attachments];
    arr.push(file);
    setUserDetails({
      ...userDetails,
      attachments: arr,
    });
  };

  function getData() {
    let obj = {
      patientName: '',
      patientId: '',
      mobileNo: '',
      hospitalName: '',
      address: {
        type: '',
        coordinates: null,
        description: '',
      },
      units: '',
      component: '',
      comment: '',
      requiredBy: '',
      attachments: [],
      bloodGroup: '',
    };

    setUserDetails(obj);
  }

  useEffect(() => {
    getData();
  }, [data]);

  return (
    <AddPatientView
      userDetails={userDetails}
      instituteListObj={instituteListObj}
      instituteNameFocused={instituteNameFocused}
      bloodComponents={bloodComponents}
      setShowAddPatientPopup={setShowAddPatientPopup}
      currBloodGroup={currBloodGroup}
      bloodComponent={bloodComponent}
      setInstituteNameFocused={setInstituteNameFocused}
      onInstituteSelect={onInstituteSelect}
      requestClick={requestClick}
      onInputChange={onInputChange}
      addFile={addFile}
    />
  );
}
