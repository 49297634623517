import React, { useState } from 'react';
import { func, array } from 'prop-types';
import { useDispatch } from 'react-redux';
import { addInventory } from '../../redux/actions';

import DatePicker from '../../components/DatePicker';
import DropDown from '../../components/RDropDown';
import { BLOOD_GROUP_OPTIONS_1 } from '../../utils/constants';

function AddInventory({ showHideInventoryPopup, bloodComponents }) {
  const [bloodGroup, setBloodGroupSelected] = useState('A+');
  const [bloodComponent, setBloodCompSelected] = useState('Whole Blood');
  const [expiryDate, setExpiryDate] = useState(undefined);

  const dispatch = useDispatch();

  function getSelectedDate(date) {
    setExpiryDate(date);
  }

  function onAddClick(e) {
    e.preventDefault();

    const { donor_id, bag_id, unit_volume, unit_weight, stored_in } = e.target;
    const payload = {
      inventories: [
        {
          status: 0,
          expiryDate,
          bloodGroup: bloodGroup,
          component: bloodComponent,
          donorId: donor_id.value,
          bagId: bag_id.value,
          mass: unit_weight.value,
          volume: unit_volume.value,
          storedIn: stored_in.value,
        },
      ],
    };

    // triggers an action to add inventory, on success trigger's callback to hide the popup
    dispatch(addInventory(payload, showHideInventoryPopup));
  }

  function fetchBloodGroup({ value }) {
    setBloodGroupSelected(value);
  }

  function fetchBloodComponent({ value }) {
    setBloodCompSelected(value);
  }

  return (
    <div className='bd-modal-wrap bd-modal-right bd-addinvent2'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Add to inventory</span>
          <i onClick={showHideInventoryPopup} className='bludclose' />
        </div>
        <div className='bd-popup__body'>
          <form onSubmit={onAddClick}>
            <div className='bd-form'>
              <div className='bd-form__row'>
                <label>Donor ID</label>
                <input type='text' name='donor_id' />
              </div>
              <div className='bd-form__row'>
                <label>Blood group</label>
                <div className='bd-dorpdown'>
                  <div className='bd-dorpdown__selected'>
                    <DropDown
                      options={BLOOD_GROUP_OPTIONS_1}
                      fetchDropDownValue={fetchBloodGroup}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
              </div>
              <div className='bd-form__row'>
                <label>Bag no.</label>
                <input type='text' name='bag_id' />
              </div>
              <div className='bd-form__row bd-twocol'>
                <div>
                  <label>Unit Volume (ml)</label>
                  <input type='text' name='unit_volume' />
                </div>
                <div>
                  <label>Unit Weight (gm)</label>
                  <input type='text' name='unit_weight' />
                </div>
              </div>
              <div className='bd-form__row'>
                <label>Components</label>
                <div className='bd-dorpdown'>
                  <div className='bd-dorpdown__selected'>
                    <DropDown
                      options={bloodComponents.map((comp) => ({
                        label: comp,
                        value: comp,
                      }))}
                      fetchDropDownValue={fetchBloodComponent}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
              </div>
              <div className='bd-form__row'>
                <div className='bd-calender'>
                  <label>Expiry date</label>
                  <DatePicker getSelectedDate={getSelectedDate} />
                </div>
              </div>
              <div className='bd-form__row'>
                <label>Stored in</label>
                <input type='text' name='stored_in' />
              </div>
            </div>
            <div className='bd-btn__wrap'>
              <button type='submit' className='bd-btn bd-btn--primary'>
                <span>Add</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className='bd-popup__lightbox' />
    </div>
  );
}

AddInventory.propTypes = {
  showHideInventoryPopup: func,
  bloodComponents: array,
};

export default AddInventory;
