import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import { SuccessPopup, Tables } from '../../components';

import { CAMP_PENDING_TABLE } from '../../utils/constants';
import CampProfilePop from '../../components/Popups/CampProfilePop';
import {
  triggerAccept_DeclineRequest,
  getCampsRequestData,
  getNgoCampsListData,
} from '../../redux/actions/campsAction';
import ReSchedulCampView from './ReSchedulCampView';

function PendingCampView({
  institutionType,
  pendingCampList,
  campProfilePopup,
  setCampProfilePopup,
  campListObject,
  onHeaderClick,
}) {
  const dispatch = useDispatch();

  // const { accept_decline_camp } = useSelector((state) => state.campsReducer);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [CommentValue, setCommentValue] = useState();
  const [error, setError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState('');
  const [showRechedulCamp, setShowRechedulCamp] = useState(false);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  function onDeclinedClick() {
    const obj = {
      id: campListObject?._id,
      action: 'Declined',
      comments: CommentValue,
    };

    if (obj.comments) {
      dispatch(
        triggerAccept_DeclineRequest(obj, () => {
          setCampProfilePopup(false);
          dispatch(getCampsRequestData({ type: 'Pending' }));
          setShowSuccess(true);
        })
      );
    } else {
      setError(true);
    }
  }

  useEffect(() => {}, [error]);

  const { accept_decline } = useSelector((state) => state.campsReducer);
  useEffect(() => {
    if (accept_decline?.data?.code === 200) {
      setPopUpMsg(accept_decline.data.content.message);
    }
  }, [accept_decline]);

  useEffect(() => {
    institutionType !== 'NGO'
      ? dispatch(getCampsRequestData({ type: 'Pending' }))
      : dispatch(getNgoCampsListData());
  }, [institutionType]);

  return (
    <>
      <div className='bd-tbl__inventory table-scroll'>
        <Tables
          tab={'AcceptedCamp'}
          tableType={'table-type-1'}
          columns={CAMP_PENDING_TABLE}
          tableData={pendingCampList.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          headerClick={onHeaderClick}
        />
      </div>
      <TablePagination
        component='div'
        count={pendingCampList.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {campProfilePopup && (
        <CampProfilePop
          institutionType={institutionType}
          setCampProfilePopup={setCampProfilePopup}
          campListObject={campListObject}
          error={error}
          onDeclinedClick={onDeclinedClick}
          setCommentValue={setCommentValue}
          setShowRechedulCamp={setShowRechedulCamp}
        />
      )}
      {showRechedulCamp && (
        // Reschedual Camp FORM---
        <ReSchedulCampView
          campListObject={campListObject}
          setShowRechedulCamp={setShowRechedulCamp}
          setShowSuccess={setShowSuccess}
        />
      )}
      {showSuccess && (
        <SuccessPopup
          title={popUpMsg}
          onContinueClick={() => setShowSuccess(false)}
        />
      )}
    </>
  );
}

export default PendingCampView;
