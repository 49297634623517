import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequestVerificationData,
  getRequestVerifiedData,
} from '../../redux/actions/dashboardAction';
import VerifyTabViewAll from '../../views/DashboardView/VerifyTabViewAll';
import { changeUserStatus } from '../../redux/actions/dashboardAction';
import VerifyPopup from '../../views/DashboardView/VerifyPopup';
import EditPopupContainer from './EditPopupContainer';
import { FailPopup, SuccessPopup, Loader } from '../../components';
import { VERIFY_USER } from '../../redux/actions/actionTypes';

export default function VerifyViewAllContainer({}) {
  const dispatch = useDispatch();
  const { requestVerifyObj, requestVerifiedObj, userStatus } = useSelector(
    (state) => state.dashboardReducer
  );
  const [activeTab, setActiveTab] = useState('Pending');
  const [showVerify, setVerify] = useState(false);
  const [showEditVerify, setEditVerify] = useState(false);
  const [userData, setUserData] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);
  const [loading, setLoading] = useState(false);

  function onTabChanges(e) {
    setActiveTab(e.target.id);
  }
  function verifyClick(e) {
    dispatch(
      changeUserStatus(
        {
          id: e.target.getAttribute('userid'),
          status: 2,
        },
        () => {
          setShowSuccess(true);
        }
      )
    );
  }
  function declineClick(e) {
    dispatch(
      changeUserStatus(
        {
          id: e.target.getAttribute('userid'),
          status: 4,
        },
        () => {
          setFail(true);
        }
      )
    );
  }
  function editClick() {
    setVerify(false);
    setEditVerify(true);
  }
  function onViewClick(e, id) {
    setVerify(true);
    if (requestVerifyObj?.data) {
      setUserData(requestVerifyObj?.data?.filter((a) => a._id === id)[0]);
    }
  }

  function escFunction(e) {
    if (e.keyCode === 27) {
      setEditVerify(false);
      setVerify(false);
    }
  }

  useEffect(() => {
    dispatch(getRequestVerificationData({ requestType: 'verify' }));
    dispatch(getRequestVerifiedData({ requestType: 'verified' }));
    document.addEventListener('keydown', escFunction, false);
  }, []);

  useEffect(() => {
    // if (userStatus.error !== null) {
    //   setFail(true);
    // } else {
    //   setFail(false);
    // }
    if (userStatus.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userStatus]);
  return (
    <>
      <VerifyTabViewAll
        activeTab={activeTab}
        onTabChange={onTabChanges}
        onViewClick={onViewClick}
      />

      {showVerify && (
        <VerifyPopup
          data={userData}
          verifyClick={verifyClick}
          declineClick={declineClick}
          editClick={editClick}
          setVerify={setVerify}
        />
      )}
      {showEditVerify && (
        <EditPopupContainer
          data={userData}
          setEditVerify={setEditVerify}
          setVerify={setVerify}
          setUserData={setUserData}
        />
      )}
      {showSuccess && (
        <SuccessPopup
          title={`Verified scucessfully!`}
          onContinueClick={() => {
            setVerify(false);
            setShowSuccess(false);
            dispatch({ type: VERIFY_USER, loading: false });
            dispatch(getRequestVerificationData({ requestType: 'verify' }));
          }}
        />
      )}
      {showFail && (
        <FailPopup
          // title={userStatus.error}
          title={`Declined successfully!`}
          onContinueClick={() => {
            setFail(false);
            setVerify(false);
            dispatch({ type: VERIFY_USER, loading: false });
            dispatch(getRequestVerificationData({ requestType: 'verify' }));
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}
