import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import ChatView from '../../views/ChatView';
import { API_BASE_PATH, UPLOAD_FILE_URL, ENDPOINT } from '../../utils/config';
import { getChat } from '../../redux/actions/chatAction';
import { Loader } from '../../components';
const authToken = localStorage.getItem('TOKEN_KEY');

function ChatContainer() {
  const { recentChatList } = useSelector((state) => state.chatReducer);
  const { loginObj } = useSelector((state) => state.loginReducer);
  let previousMsgDay = '';
  const myUserId = loginObj?.data?.content?.data?._id;
  const messagesEndRef = useRef(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  // const [chatDetail, setChatDetail] = useState([]);
  const dispatch = useDispatch();
  const [chatList, setChatList] = useState(recentChatList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChatList(recentChatList);
  }, [recentChatList]);

  useEffect(() => {
    dispatch(getChat());

    const socketObj = io(ENDPOINT, {
      path: '/api/socket.io/',
      transports: ['polling'],
      query: {
        token: authToken,
      },
    });
    setSocket(socketObj);
  }, []);

  useEffect(() => {
    if (socket) {
      userInfo && setLoading(true);
      socket.on('load_messages', (response) => {
        setLoading(false);
        setChatMessages(response);
        scrollToBottom();
      });

      socket.on('receive_message', (message) => {
        setLoading(false);
        if (message.sender._id === userInfo?._id) {
          setChatMessages([...chatMessages, message]);
          scrollToBottom();
        }
      });
    }
  }, [socket]);

  function socketConnection(contact) {
    const socketObj = io(ENDPOINT, {
      path: '/api/socket.io/',
      query: {
        token: authToken,
      },
    });
    socketObj.on('connect', (socket) => {});
    socketObj.emit('join_room', { chatId: contact.roomId });
    setSocket(socketObj);
  }

  function formatDate(someDateTimeStamp, forGrouping = false) {
    someDateTimeStamp = new Date(someDateTimeStamp).getTime();
    var dt = new Date(someDateTimeStamp),
      date = dt.getDate(),
      month = dt.getMonth() + 1,
      year = dt.getFullYear(),
      // timeDiff = someDateTimeStamp - Date.now(),
      diffDays = new Date().getDate() - date,
      diffMonths = new Date().getMonth() - dt.getMonth(),
      diffYears = new Date().getFullYear() - dt.getFullYear(),
      hours = dt.getHours(),
      minutes = dt.getMinutes(),
      ampm = hours >= 12 ? 'pm' : 'am';
    minutes = minutes < 10 ? '0' + minutes : minutes;

    // Minutes part from the timestamp

    if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
      return forGrouping ? 'Today' : hours + ':' + minutes + ' ' + ampm;
    } else if (diffYears === 0 && diffDays === 1) {
      return forGrouping ? 'Yesterday' : hours + ':' + minutes + ' ' + ampm;
    } else {
      return forGrouping
        ? date + '/' + month + '/' + year
        : hours + ':' + minutes + ' ' + ampm;
    }
  }

  function keyDown(event) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      const messageObj = {
        addedAt: new Date().toISOString(),
        attachment: [],
        chatType: 'Message',
        message: message.trim(),
        sender: { _id: myUserId },
      };
      sendMessage(messageObj);
    }
  }

  function scrollToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function sendMessage(messageObj, isResend = false) {
    const network = navigator.onLine ? true : false;
    if (network) {
      socket.emit('send_message', { message: messageObj.message });
      setChatMessages([...chatMessages, messageObj]);
    } else {
      messageObj.notSent = true;
      if (!isResend) {
        setChatMessages([...chatMessages, messageObj]);
      }
    }
    setMessage('');
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  }

  function resendMsg(messageObj) {
    sendMessage(messageObj, true);
  }

  function getChatDetail(e, contact) {
    const container = e.target.closest('.bd-side__container');

    [...container.children].forEach((sib) => sib.classList.remove('bd-active'));

    e.target.closest('.bd-side__row').classList.add('bd-active');
    socketConnection(contact);
    setUserInfo(contact);
  }

  function imageSelected(e) {
    const file = e.target.files[0] || null;
    let formData = new FormData(); //formdata object

    if (file) {
      formData.append('file', file);
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        token: `${localStorage.getItem('TOKEN_KEY')}`,
      },
      body: formData,
    };
    fetch(API_BASE_PATH + UPLOAD_FILE_URL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.code === 200) {
            const messageObj = {
              addedAt: new Date().toISOString(),
              attachment: [result.content.savedFiles[0]],
              chatType: 'Attachment',
              message: [result.content.savedFiles[0]],
              sender: { _id: myUserId },
            };
            sendMessage(messageObj);
          }
        },
        (error) => {}
      );
  }

  return (
    <>
      <ChatView
        myUserId={myUserId}
        chatList={chatList}
        userInfo={userInfo}
        chatMessages={chatMessages}
        messagesEndRef={messagesEndRef}
        message={message}
        previousMsgDay={previousMsgDay}
        resendMsg={resendMsg}
        setMessage={setMessage}
        keyDown={keyDown}
        getChatDetail={getChatDetail}
        formatDate={formatDate}
        imageSelected={imageSelected}
      />
      {loading && <Loader />}
    </>
  );
}

function mapStateToProps(state) {
  return {
    state,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer);
