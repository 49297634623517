import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import close from '../../assets/images/close.png';
import ReactDatePicker from 'react-datepicker';
import BedInfo from '../../components/Popups/BedInfo';
import {
  triggerAccept_DeclineRequest,
  getCampsRequestData,
} from '../../redux/actions/campsAction';

export default function ReSchedulCampView({
  campListObject,
  setShowRechedulCamp,
  setShowSuccess,
}) {
  const dispatch = useDispatch();

  const FIELDS = [
    'noOfBeds',
    'targetUnits',
    'bloodBankCoordinator',
    'bloodBankedMobileNo',
    'bloodBankedEmailId',
  ];
  const [formValues, setFormValues] = useState(null);

  const [donorInfo, setDonorInfo] = useState({
    campName: '',
    organiser: '',
    SponsorCoordinator: '',
    SponsoredMobileNo: '',
    SponsoredEmailId: '',
    venue: '',
    date: '',
    startTime: '',
    toTime: '',
  });

  function onInputChange(e) {
    let updateDonorInfo = { ...donorInfo };
    let value = e.target.value;
    if (FIELDS.includes(e.target.getAttribute('name'))) {
      updateDonorInfo[e.target.getAttribute('name')] = value;
    }
    setDonorInfo(updateDonorInfo);
  }
  function onFormChange(params) {
    setFormValues(params);
  }

  function onReschedualCampclick() {
    var id = campListObject?._id;
    let param = {
      id: id,
      action: 'Accepted',
      ...formValues.donorInfo,
      bloodBankCoordinator: {
        name: formValues.donorInfo.bloodBankCoordinator,
        mobileNumber: formValues.donorInfo.bloodBankedMobileNo,
        email: formValues.donorInfo.bloodBankedEmailId,
      },
    };
    // unWanted Keys we Delete using delete method
    delete param.bloodBankedEmailId;
    delete param.bloodBankedMobileNo;
    delete param.address_1;
    if (campListObject?.addedBy) param.addedBy = campListObject?.addedBy;
    if (campListObject?.address) param.address = campListObject?.address;

    console.log(param);
    // dispatch(
    //   triggerAccept_DeclineRequest(param, () => {
    //     setShowRechedulCamp(false);
    //     dispatch(getCampsRequestData({ type: 'Pending' }));
    //     setShowSuccess(true);
    //   })
    // );
  }

  useEffect(() => {
    onFormChange({
      donorInfo: donorInfo,
    });
  }, [donorInfo]);

  useEffect(() => {
    if (campListObject) {
      setDonorInfo({
        campName: campListObject?.campName,
        organiser: campListObject?.organiser,
        venue: campListObject?.venue,
        campCoordinator: {
          name: campListObject?.campCoordinator?.name,
          mobileNumber: campListObject?.campCoordinator?.mobileNumber,
          email: campListObject?.campCoordinator?.email,
        },
        campDuration: campListObject?.campDuration,
        address_1: campListObject?.address?.description,
        date: moment(campListObject?.campDuration?.startTime).format(
          'DD MMM YYYY'
        ),
        startTime: moment(campListObject?.campDuration?.startTime).format(
          'hh:mm'
        ),
        endTime: moment(campListObject?.campDuration?.endTime).format('hh:mm'),
      });
    }
  }, [campListObject]);
  //  value:
  const date = new Date();
  return (
    <div className='bd-modal-wrap bd-modal-right bd-createcamp'>
      <div className='bd-popup si-fade-in'>
        <div className='camp-popup-inside'>
          <BedInfo />
          <div style={{ paddingLeft: '10px' }}>
            <div className='bd-popup__head'>
              <span>Re-schedual Blood Donation Camp</span>
              <img
                src={close}
                height='25px'
                className='cursor-pointer'
                onClick={() => setShowRechedulCamp(false)}
                alt='close Btn'
              />
            </div>
            <div className='bd-popup__body'>
              <div
                className='bd-form'
                style={{ height: '60vh', overflow: 'auto' }}
              >
                <div className='bd-form__row'>
                  <label>Name of the Camp</label>
                  <input
                    type='text'
                    name='campName'
                    disabled
                    value={donorInfo?.campName}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className='bd-form__row'>
                  <label>Sponsored by</label>
                  <input
                    type='text'
                    name='SponsoredBy'
                    disabled
                    value={donorInfo?.organiser}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className='bd-form__row'>
                  <label>Organiser</label>
                  <input
                    type='text'
                    name='organiser'
                    disabled
                    value={donorInfo?.organiser}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className='bd-form__row bd-twocol'>
                  <div>
                    <label>No. of beds</label>
                    <input
                      type='text'
                      name='noOfBeds'
                      value={donorInfo?.noOfBeds}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                  <div>
                    <label>Target Units</label>
                    <input
                      type='text'
                      name='targetUnits'
                      value={donorInfo?.targetUnits}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>

                <div className='bd-form__row bd-twocol'>
                  <div>
                    <label>Date : </label>
                    <div>
                      <ReactDatePicker
                        disabled
                        name='date'
                        selected={date}
                        value={donorInfo?.date}
                        onChange={(e) => onInputChange(e)}
                        dateFormat='dd/MM/yyyy'
                      />
                    </div>
                  </div>
                  <div>
                    <label>Time : </label>
                    <input
                      disabled
                      type='text'
                      name='targetUnits'
                      value={donorInfo?.startTime + '-' + donorInfo?.endTime}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>

                <div className='bd-form__row'>
                  <label>Sponsor’s Coordinator</label>
                  <input
                    type='text'
                    name='SponsorCoordinator'
                    disabled
                    value={donorInfo?.campCoordinator?.name}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className='bd-form__row bd-twocol'>
                  <div>
                    <label>Sponsor’s Mobile Number</label>
                    <input
                      type='number'
                      name='SponsoredMobileNo'
                      disabled
                      value={donorInfo?.campCoordinator?.mobileNumber}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                  <div>
                    <label>Sponsor’s Email ID</label>
                    <input
                      type='text'
                      name='SponsoredEmailId'
                      disabled
                      value={donorInfo?.campCoordinator?.email}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
                <div className='bd-form__row'>
                  <label>Venue</label>
                  <input
                    type='text'
                    name='venue'
                    disabled
                    value={donorInfo?.venue}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className='bd-form__row'>
                  <label>Address</label>
                  <input
                    type='text'
                    name='address_1'
                    disabled
                    value={donorInfo?.address_1}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className='bd-form__row'>
                  <label>Internal Coordinator</label>
                  <input
                    type='text'
                    name='bloodBankCoordinator'
                    value={donorInfo?.bloodBankCoordinator}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className='bd-form__row bd-twocol'>
                  <div>
                    <label> Mobile Number</label>
                    <input
                      type='number'
                      name='bloodBankedMobileNo'
                      value={donorInfo?.bloodBankedMobileNo}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                  <div>
                    <label>Email ID</label>
                    <input
                      type='text'
                      name='bloodBankedEmailId'
                      value={donorInfo?.bloodBankedEmailId}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className='bd-btn__wrap'>
                <div
                  className='bd-btn bd-btn--primary'
                  onClick={() => onReschedualCampclick()}
                >
                  Reschedual Camp
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='bd-popup__lightbox'></div> */}
    </div>
  );
}
