import React from "react";
import { addMonthlyInventory } from "../../redux/actions";
import { useDispatch } from "react-redux";

function MonthlyInventoryList(props) {
  let { monthlyInventoryList } = props;

  const dispatch = useDispatch();

  const onChangeText = (e, obj, i) => {
    dispatch(
      addMonthlyInventory({ value: e.target.value, data: obj, index: i })
    );
  };

  return (
    <div className="bd-step__body--bot monthly-infra">
      <div className="bd-inventory__tbl">
        <div className="bd-step__head">
          <span>Average monthly consumption of inventory</span>
        </div>
        <div className="bd-tbl">
          <div className="bd-tbl__head">
            <div className="bd-tbl__row">
              <div className="bd-tbl__cell">
                <span>Blood Group</span>
              </div>
              <div className="bd-tbl__cell">
                <span>Whole Blood</span>
              </div>
              <div className="bd-tbl__cell">
                <span>Plasma</span>
              </div>
              <div className="bd-tbl__cell">
                <span>Platelets</span>
              </div>
              <div className="bd-tbl__cell">
                <span>Red cells</span>
              </div>
            </div>
          </div>
          <div className="bd-tbl__body">
            {monthlyInventoryList[0].monthlyInventory.map((invObj, index) => {
              return (
                <div className="bd-tbl__row">
                  <div className="bd-tbl__cell">
                    <span>{invObj["Blood Group"]}</span>
                  </div>
                  <div className="bd-tbl__cell">
                    <span>
                      <input
                        value={invObj["Whole Blood"]}
                        type="number"
                        placeholder="Enter"
                        onChange={(e) => onChangeText(e, "Whole Blood", index)}
                      />
                    </span>
                  </div>
                  <div className="bd-tbl__cell">
                    <span>
                      <input
                        value={invObj["Plasma"]}
                        type="number"
                        placeholder="Enter"
                        onChange={(e) => onChangeText(e, "Plasma", index)}
                      />
                    </span>
                  </div>
                  <div className="bd-tbl__cell">
                    <span>
                      <input
                        value={invObj["Platelets"]}
                        type="number"
                        placeholder="Enter"
                        onChange={(e) => onChangeText(e, "Platelets", index)}
                      />
                    </span>
                  </div>
                  <div className="bd-tbl__cell">
                    <span>
                      <input
                        value={invObj["Red cells"]}
                        type="number"
                        placeholder="Enter"
                        onChange={(e) => onChangeText(e, "Red cells", index)}
                      />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthlyInventoryList;
