import React, { useState } from 'react';
import { arrayOf, string, object, func } from 'prop-types';
import Select from 'react-select';

function RDropDown({
  options,
  placeHolderText,
  fetchDropDownValue,
  defaultValue,
}) {
  // const initialValue = defaultValue ? defaultValue : options[0];
  // const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [selectedValue, setSelectedValue] = useState(
    defaultValue ?? placeHolderText
  );

  function handleOnChange(value) {
    setSelectedValue(value);
    // fetch the value at the parent component
    fetchDropDownValue(value);
  }
  return (
    <>
      <Select
        options={options}
        onChange={handleOnChange}
        value={selectedValue || defaultValue}
        placeholder={placeHolderText}
        styles={customDDStyles}
      />
    </>
  );
}

// we can customize the color through props if needed
const customDDStyles = {
  option: (prevStyle, state) => ({
    ...prevStyle,
    backgroundColor: state.isSelected ? '#AF0C0C' : '',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#AF0C0C',
      opacity: 0.5,
      color: '#fff',
    },
  }),
  menu: (prevStyle) => ({
    ...prevStyle,
    color: '#AF0C0C',
  }),
  placeholder: (prevStyle) => ({
    ...prevStyle,
    fontSize: 15,
    color: '#000',
  }),
  control: (prevStyle) => ({
    ...prevStyle,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
  }),
  indicatorsContainer: (prevStyle) => ({
    ...prevStyle,
    display: 'none',
  }),
  valueContainer: (prevStyle) => ({
    ...prevStyle,
    padding: '0px',
  }),
  singleValue: (prevStyle) => ({
    ...prevStyle,
    fontWeight: 600,
  }),
};

RDropDown.defaultProps = {
  placeHolderText: 'Please select the option',
  setDefaultValue: false,
};

RDropDown.propTypes = {
  options: arrayOf(object),
  placeHolderText: string,
  fetchDropDownValue: func,
};

export default RDropDown;
