import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { FailPopup, Spinner } from '../../components';
import { SuccessPopup } from '../../components/Popups/SuccessPopup';
import { emailPattern } from '../../utils';
import {
  questions,
  questionsSet2,
  GENDER_LIST,
  BLOOD_GROUP_OPTIONS_1,
  BLOOD_STATIC_COMPONET_LIST,
} from '../../utils/constants';
import DropDown from '../../components/RDropDown';

export default function AddDonorView({
  onChangeText,
  checkboxclick,
  sucessPopup,
  donorObj,
  onSubmit,
  addDonor,
  onContinueClick,
  failedPopup,
  onContinueFailedClick,
  // onAddressSearch,
  // selectAddress,
  // addressFocused,
  // setaddressFocused,
  // headerDropDownChange,
  onCancelClick,

  setBloodGroupSelected, //for BloodGroup
  setComponentSelected, //for Blood Component
  setGenderSelected, // for gender
}) {
  const {
    firstName,
    lastName,
    mobileNumber,
    email,
    address,
    refferedBy,
    age,
    dateOfBirth,
    height,
    weight,
    aadharNumber,
    confirmAadhar,
    notes,
    isVerified,
    // lastDonationComponent,
    lastDonationDate,
  } = donorObj;
  // const { addressList } = useSelector((state) => state.campsReducer);

  function fetchBloodGroup({ value }) {
    setBloodGroupSelected(value);
  }
  function fetchComponent({ value }) {
    setComponentSelected(value);
  }
  function fetchGender({ value }) {
    setGenderSelected(value);
  }

  return (
    <article className='bd-article '>
      <section className='bd-dashboard bd-bg-color'>
        {failedPopup ? (
          <FailPopup
            onContinueClick={onContinueFailedClick}
            title={'Please fill required details!'}
          />
        ) : (
          ''
        )}

        <div className='bd-dashboard__container'>
          <div className='bd-dashboard__lft bd-equalcolumn'>
            <div className='bd-dashboard__header'>
              <span className='bd-dashboard__main'>Add a Donor</span>
            </div>

            <div className='bd-dbd__form'>
              <section className='bd-adddonor'>
                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div d-flex align-items-center'>
                    <span className='bd_error bd_star'>*</span>
                    <input
                      type='text'
                      placeholder='First Name'
                      value={firstName}
                      name='firstName'
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                  <div className='bd-adddonor-div  d-flex align-items-center'>
                    <span className='bd_error bd_star'>*</span>
                    <input
                      type='text'
                      placeholder='Last Name'
                      value={lastName}
                      name='lastName'
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>

                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div '>
                    <div className='bd-dorpdown width-100 ml-10'>
                      <div className='bd-dorpdown__selected'>
                        <DropDown
                          options={GENDER_LIST}
                          fetchDropDownValue={fetchGender}
                          placeHolderText={'Gender'}
                        />
                        <i className='bludbig-arrow' />
                      </div>
                    </div>
                  </div>
                  <div className='bd-dorpdown width-50 ml-20'>
                    <div className='bd-dorpdown__selected'>
                      <DropDown
                        options={BLOOD_GROUP_OPTIONS_1}
                        fetchDropDownValue={fetchBloodGroup}
                        placeHolderText={'Blood Group'}
                      />
                      <i className='bludbig-arrow' />
                    </div>
                  </div>
                </div>

                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div  d-flex align-items-center ml-10'>
                    <input
                      type='text'
                      placeholder='Height'
                      value={height}
                      name='height'
                      onChange={(e) => onChangeText(e)}
                    />
                    <span className='bd_error bd_star'>cm</span>
                  </div>
                  <div className='bd-adddonor-div d-flex align-items-center ml-10'>
                    <input
                      type='text'
                      placeholder='Weight'
                      value={weight}
                      name='weight'
                      onChange={(e) => onChangeText(e)}
                    />
                    <span className='bd_error bd_star'>kg</span>
                  </div>
                </div>
                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div  d-flex align-items-center'>
                    <span className='bd_error bd_star'>*</span>
                    <div className='width-100'>
                      <ReactDatePicker
                        selected={dateOfBirth}
                        onChange={(date) => onChangeText(date, 'dateOfBirth')}
                        dateFormat='dd/MM/yyyy'
                        showMonthDropdown=' true'
                        placeholderText='Date of Birth'
                      />
                    </div>
                  </div>
                  <div className='bd-adddonor-div d-flex align-items-center ml-20'>
                    <input
                      type='text'
                      placeholder='age'
                      value={age}
                      name='age'
                      onChange={(e) => onChangeText(e)}
                    />
                    <span className='bd_error bd_star'>years</span>
                  </div>
                </div>

                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div d-flex align-items-center'>
                    <span className='bd_error bd_star'>*</span>
                    <ReactDatePicker
                      selected={lastDonationDate}
                      onChange={(date) =>
                        onChangeText(date, 'lastDonationDate')
                      }
                      dateFormat='dd/MM/yyyy'
                      showMonthDropdown=' true'
                      placeholderText='Last Donation date'
                    />
                    {/* <div className='bd-form__row'>
                      <div className='bd-calender'>
                        <label>Processed On </label>
                        <DatePicker />
                      </div>
                    </div> */}
                  </div>
                  <div className='bd-adddonor-div  d-flex align-items-center'>
                    <span className='bd_error bd_star'>*</span>
                    <div className='bd-dorpdown width-100'>
                      <div className='bd-dorpdown__selected'>
                        <DropDown
                          options={BLOOD_STATIC_COMPONET_LIST}
                          fetchDropDownValue={fetchComponent}
                          placeHolderText={'Last Donated Component'}
                        />
                        <i className='bludbig-arrow' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div ml-10'>
                    <input
                      type='text'
                      placeholder='Email'
                      value={email}
                      name='email'
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>

                  <div className='bd-adddonor-div ml-10'>
                    <input
                      type='number'
                      placeholder='mobile No.'
                      value={mobileNumber}
                      name='mobileNumber'
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                </div>
                {email && !emailPattern.test(email) ? (
                  <p className='bd_error ml-10'>Invalid email!</p>
                ) : (
                  ''
                )}
                <div className='bd-adddonor-row bd-adddonor__1'>
                  <div className='bd-adddonor-div ml-10'>
                    <input
                      type='number'
                      placeholder='Adhaar No.'
                      value={aadharNumber}
                      name='aadharNumber'
                      onChange={(e) => onChangeText(e)}
                    />
                  </div>
                  <div className='bd-adddonor-div ml-10'>
                    <input
                      type='number'
                      placeholder='Confirm Adhaar No..'
                      value={confirmAadhar}
                      name='confirmAadhar'
                      onChange={(e) => onChangeText(e)}
                    />
                    {aadharNumber &&
                    confirmAadhar &&
                    aadharNumber !== confirmAadhar ? (
                      <p className='bd_error ml-10'>
                        Aadhar & confirm aadhar number must match!
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='bd-adddonor-row bd-adddonor__9'>
                  <input
                    className='ml-10'
                    type='text'
                    placeholder='Address '
                    value={address}
                    name='address'
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
                <div className='bd-adddonor-row bd-adddonor__9'>
                  <input
                    className='ml-10'
                    type='text'
                    placeholder='Referred by'
                    value={refferedBy}
                    name='refferedBy'
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
              </section>
            </div>
          </div>

          <div className='bd-dashboard__rgt bd-equalcolumn'>
            <div className='bd-dbd__form'>
              <section className='bd-sec__10'>
                <ul>
                  {questions.map((question, i) => {
                    return (
                      <li className='bd-adddonor-questions-set1' key={i}>
                        <span className='bd-checkbox'>
                          <input type='checkbox' />
                          <b></b>
                        </span>
                        {question.label}
                      </li>
                    );
                  })}
                </ul>

                {questionsSet2.map((question, i) => {
                  return (
                    <div
                      className='bd-adddonor-questions space-between'
                      key={i}
                    >
                      <div>{question.label}</div>

                      <div className='d-flex space-between radio-btns align-items-center'>
                        <div className='bd-adddonor-questions-set1'>
                          <span className='bd-radio  mr-5'>
                            <input type='radio' />
                            <b></b>
                          </span>
                          Yes
                        </div>
                        <div className='bd-adddonor-questions-set1'>
                          <span className='bd-radio mr-5'>
                            <input type='radio' />
                            <b></b>
                          </span>
                          No
                        </div>
                      </div>
                    </div>
                  );
                })}
                <ul>
                  <li>
                    <span className='bd-checkbox'>
                      <input type='checkbox' />
                      <b></b>
                    </span>
                    Pre-donation health check questionnaire filled
                  </li>
                </ul>
              </section>
              <section className='bd-sec__11'>
                <div className='bd-sec__11--1'>
                  <textarea
                    placeholder='Notes'
                    name='notes'
                    value={notes}
                    rows='9'
                    cols='50'
                    onChange={(e) => onChangeText(e)}
                  ></textarea>
                </div>
              </section>
              <section className='bd-sec__12'>
                <div className='bd-sec__12--1'>
                  <span className='bd-checkbox'>
                    <input
                      type='checkbox'
                      onChange={(e) => checkboxclick(e)}
                      checked={isVerified}
                    />
                    <b></b>
                  </span>
                  Have explained about the protocols & precautions for donation
                </div>
              </section>
            </div>
          </div>
          <div className='bd-adddonor__save'>
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline' onClick={onCancelClick}>
                <span>Cancel</span>
              </div>
              <div
                className='bd-btn bd-btn--primary db-bg__green'
                disabled={addDonor.loading}
                onClick={onSubmit}
              >
                <div className='d-flex align-items-center justify-content-center'>
                  {addDonor.loading ? (
                    <div>
                      <Spinner />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='ml-5'> Save</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {sucessPopup ? (
          <SuccessPopup
            title={`Donor added successfully!`}
            onContinueClick={onContinueClick}
          />
        ) : (
          ''
        )}
      </section>
    </article>
  );
}
