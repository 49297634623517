import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  triggerBloodComponentList,
  getInventoryData,
} from '../../redux/actions';
import { changeInventoryStatus } from '../../redux/actions/dashboardAction';
import { transferProcessInventory } from '../../redux/actions/inventoryAction';
import InventoryView from '../../views/InventoryView';
import { Loader } from '../../components';

class InventoryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventoryProps: {
        title: '',
        button: '',
        showInventoryPopUp: false,
      },
    };

    this.onCellClick = this.onCellClick.bind(this);
    this.onBtnClick = this.onBtnClick.bind(this);
    this.getInventoryList = this.getInventoryList.bind(this);
    this.resetInventoryProps = this.resetInventoryProps.bind(this);
  }

  componentDidMount() {
    // lifecyle for API calls

    // trigger inventory list api
    this.getInventoryList();

    // trigger blood component list api
    this.props.triggerBloodComponentList();
  }
  getInventoryList() {
    this.props.getInventoryData();
  }
  fetchInventoryData() {
    const { inventoryDataObj } = this.props;
    const { inventoryList, loading } = inventoryDataObj;

    const filterKeys = ['inventory', '_id'];

    let bloodComponents = [];
    if (inventoryList?.length > 0) {
      bloodComponents = Object.keys(inventoryList[0])
        .filter((key) => !filterKeys.includes(key))
        .sort();
    }

    return {
      loading,
      bloodComponents,
      inventoryList,
    };
  }
  onBtnClick(obj) {
    if (obj.type === 'registered' || obj.type === 'unregistered') {
      let { id, unit, inventoryList, deliveryType } = obj;
      let issue = {
        type: 'issue',
        issueType: 'patient',
        id: id,
        status: 3,
        issuedUnits: unit,
        inventoryIds: inventoryList,
        deliveryType: deliveryType,
      };
      this.props.changeInventoryStatus(issue, () => {
        this.getInventoryList();
      });
    } else if (obj.type === 'transfer') {
      let { selectedUser, inventoryList, comment, deliveryType } = obj;
      let issue = {
        type: 'transfer',
        institutionId: selectedUser.id,
        inventoryIds: inventoryList,
        comments: comment,
        details: selectedUser.name,
        deliveryType: deliveryType,
      };
      this.props.transferProcessInventory(issue, () => {
        this.getInventoryList();
      });
    } else if (obj.type === 'process') {
      let { inventoryList } = obj;
      // issue tu process render component here and pass issue obj aa props
      let issue = {
        type: 'issue',
        issueType: 'process',
        inventoryIds: inventoryList,
        // comments: comment,
        // inventories: inventories,
      };
      this.props.transferProcessInventory(issue, () => {
        this.getInventoryList();
      });
    }
  }

  onCellClick(e) {
    // to prevent the parent click we have add this
    e.stopPropagation();

    const { id } = e.currentTarget.dataset;

    let inventoryProps = {};

    switch (id) {
      case 'transfer':
        inventoryProps = {
          title: 'Transfer',
          button: 'Transfer',
          showInventoryPopUp: true,
          type: 'transfer',
        };
        break;
      case 'dispose':
        this.props.history.push('/inventory-transfer');
        break;
      case 'registered':
        inventoryProps = {
          title: 'Issue to Patient',
          button: 'Issue',
          showInventoryPopUp: true,
          type: 'registered',
        };
        break;
      case 'unregistered':
        inventoryProps = {
          title: 'Issue to Patient',
          button: 'Add',
          showInventoryPopUp: true,
          type: 'unregistered',
        };
        break;
      case 'process':
        inventoryProps = {
          title: 'Issue to Process',
          button: 'Process',
          showInventoryPopUp: true,
          // showDetail: false,
          // showIssueToProcess: true,
          // bloodComponentList: this.props.bloodComponentList,
          type: 'process',
        };
        break;
      default:
        break;
    }

    this.setState({
      inventoryProps,
    });
  }
  resetInventoryProps() {
    this.setState({
      inventoryProps: {
        title: '',
        button: '',
        showInventoryPopUp: false,
      },
    });
  }
  render() {
    const { inventoryList, bloodComponents, loading } =
      this.fetchInventoryData();
    const { inventoryProps } = this.state;
    const { changeStatus } = this.props;
    return (
      <React.Fragment>
        {loading ? (
          <Loader />
        ) : (
          <InventoryView
            inventoryList={inventoryList}
            bloodComponents={bloodComponents}
            inventoryProps={inventoryProps}
            inventoryStatus={changeStatus}
            onCellClick={this.onCellClick}
            onBtnClick={this.onBtnClick}
            getInventoryList={this.getInventoryList}
            resetInventoryProps={this.resetInventoryProps}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { inventoryDataObj, transferProcessData } = state.inventoryReducer;
  const { bloodComponentDataObj } = state.commonReducer;
  const { inventoryChangeStatus } = state.dashboardReducer;
  const { bloodComponentList } = bloodComponentDataObj;
  return {
    inventoryDataObj,
    bloodComponentList,
    changeStatus: inventoryChangeStatus || transferProcessData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getInventoryData,
      triggerBloodComponentList,
      changeInventoryStatus,
      transferProcessInventory,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryContainer);
