import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AddCamp from '../../views/CampsView/AddCamp';
import {
  triggerEditCamps,
  triggerAddCamps,
  triggerFetchAddress,
} from '../../redux/actions/campsAction';
import axios from 'axios';

export default function AddCampContainer({
  toggleForm,
  addCamps,
  defaultObject,
}) {
  const dispatch = useDispatch();
  const [failedPopup, setfailedPopup] = useState(false);
  const [addCampsObj, setAddCampsObj] = useState(addCamps);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [addressFocused, setaddressFocused] = useState(false);
  const [didMount, setDidMount] = useState(false);

  const inputFile = useRef(null);
  const {
    campName,
    sponser,
    date,
    address,
    fromTime,
    toTime,
    place,
    id,
    attachments,
    targetUnits,
    coordinator,
    noOfBeds,
  } = addCampsObj;

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  function handleClick() {
    inputFile.current.click();
  }

  async function onChangeFile(event) {
    let selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    let updateAddCampsObj = { ...addCampsObj };
    updateAddCampsObj.attachments.push(event.target.files[0]);
    setAddCampsObj(updateAddCampsObj);
    setUploadingFile(true);
    let response = await axios.post(
      'https://dev.blud.app/api/applications/' + 'generic/file',
      formData,
      {
        headers: {
          token: `${localStorage.getItem('TOKEN_KEY')}`,
        },
      }
    );
    if (response) {
      let attachment = response.data.content.savedFiles[0];
      let lastIndex = updateAddCampsObj.attachments.length - 1;
      updateAddCampsObj.attachments[lastIndex] = attachment;
      setAddCampsObj(updateAddCampsObj);
      setUploadingFile(false);
    }
  }

  function onChangeText(e, name) {
    let updateAddCampsObj = { ...addCampsObj };

    if (name == 'date') {
      updateAddCampsObj[name] = new Date(e);
    } else {
      updateAddCampsObj[e.target.name] = e.target.value;
    }
    if (
      updateAddCampsObj['toTime'].split(':')[0] >
      updateAddCampsObj['fromTime'].split(':')[0]
    ) {
      let hourDiff =
        updateAddCampsObj['toTime'].split(':')[0] -
        updateAddCampsObj['fromTime'].split(':')[0];
      // let minDiff=updateAddCampsObj['toTime'].split(':')[1]-updateAddCampsObj['fromTime'].split(':')[1];
      let bedNo = updateAddCampsObj['targetUnits'] / hourDiff;
      updateAddCampsObj['noOfBeds'] = Math.round(bedNo);
    }
    setAddCampsObj(updateAddCampsObj);
  }

  function onAddressSearch(e) {
    if (e.target.value !== '') {
      onChangeText(e);
      return dispatch(triggerFetchAddress(e.target.value, (resp) => {}));
    }
  }

  function selectAddress(data) {
    setaddressFocused(false);
    let updateAddCampsObj = { ...addCampsObj };
    updateAddCampsObj['address'] = {
      coordinates: data.location.coordinates,
      description: data.location.description,
      type: data.location.type,
    };

    setAddCampsObj(updateAddCampsObj);
  }

  function CloseAddCamp() {
    toggleForm();
    // setAddCampsObj(defaultObject);
    // if (
    //   (campName ||
    //     address.description ||
    //     fromTime ||
    //     toTime ||
    //     date ||
    //     sponser ||
    //     place) &&
    //   !id
    // ) {
    //   onSubmit("saveAsDraft");
    // }
  }

  function onSubmit(type) {
    if (
      (campName === '' ||
        address.description === '' ||
        fromTime === '' ||
        toTime === '' ||
        date === '' ||
        place === '' ||
        coordinator === '' ||
        targetUnits === '' ||
        targetUnits < 100 ||
        sponser === '' ||
        noOfBeds === '') &&
      type !== 'saveAsDraft'
    ) {
      setfailedPopup(true);
      return 0;
    }

    let params = {
      campName,
      address,
      sponser,
      place,
      date: moment(date).toISOString(),
      place,
      attachments,
      id,
      noOfBeds,
      attachments,
      targetUnits,
      coordinator,
      sponser,
    };

    if (type === 'saveAsDraft' && !id) {
      params.status = 'Draft';
    }
    if (fromTime && toTime) {
      params.time = fromTime + '-' + toTime;
    }

    if (id) {
      return dispatch(
        triggerEditCamps(params, (resp) => {
          toggleForm();
          setAddCampsObj(defaultObject);
        })
      );
    } else {
      return dispatch(
        triggerAddCamps(params, (resp) => {
          toggleForm();
          setAddCampsObj(defaultObject);
        })
      );
    }
  }

  function onContinueFailedClick() {
    setfailedPopup(false);
  }

  return (
    <AddCamp
      addCampsObj={addCampsObj}
      uploadingFile={uploadingFile}
      inputFile={inputFile}
      addressFocused={addressFocused}
      failedPopup={failedPopup}
      onContinueFailedClick={onContinueFailedClick}
      onCancelClick={CloseAddCamp}
      handleClick={handleClick}
      onChangeFile={onChangeFile}
      onChangeText={onChangeText}
      onSubmit={onSubmit}
      onAddressSearch={onAddressSearch}
      selectAddress={selectAddress}
      setaddressFocused={setaddressFocused}
    />
  );
}
