import React, { useState, useEffect } from 'react';
import { Tables } from '../../components';
import TablePagination from '@material-ui/core/TablePagination';
import { formatDate, sorting } from '../../utils';
import { PEOPLE_RECEPIENT_COLUMNS } from '../../utils/constants';

function RequestList({
  PeopleRequestedForList,
  RequestedSearch,
  headerDropDown,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [requestedData, setRequestedData] = useState([]);
  const [count, setCount] = useState(0);
  const [table, setTable] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onHeaderClick = (e) => {
    let attribute = e.target.getAttribute('dataindex');
    let data = requestedData;
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setTable(data);
    setCount(count + 1);
  };

  function headerDropDownChange(e) {
    let data = [...PeopleRequestedForList?.data];
    if (headerDropDown === 'All/Any') {
      data = [...PeopleRequestedForList?.data];
    } else {
      data = data.filter((a) => a.bloodGroup === headerDropDown);
    }
    setRequestedData(setData(data));
  }

  function setData(list) {
    let data = [];
    list.forEach((record, id) => {
      let obj = {
        bloodGroup: record?.bloodGroup,
        components: record?.component,
        purpose: record?.reciever[0].reasons
          ? record?.reciever[0].reasons
          : '--',
        hospitalName: record?.reciever[0].hospitalName
          ? record?.reciever[0].hospitalName
          : record?.reciever[0].bloodBankName,
        bagId: record?.bagId,
        patientId: record?.reciever[0].patientId
          ? record?.reciever[0].patientId
          : '--',
        date: formatDate(record?.issuedDate),
        expiryDate: formatDate(record?.expiryDate),
        // dateOfBirth:
        //   record?.details?.dateOfBirth === undefined || null
        //     ? '-'
        //     : formatDate(record?.details?.dateOfBirth),
        name: record?.reciever[0]?.details
          ? record?.reciever[0]?.details?.firstName +
            ' ' +
            record?.reciever[0]?.details?.lastName
          : '--',
      };
      data.push(obj);
    });
    return data;
  }

  useEffect(() => {
    headerDropDownChange();
  }, [PeopleRequestedForList, headerDropDown]);

  useEffect(() => {
    let data = setData(PeopleRequestedForList?.data);
    setRequestedData(data);

    if (RequestedSearch) {
      var list = [...data];
      var searchData = list.filter(
        (values) =>
          values.name.toLowerCase().includes(RequestedSearch.toLowerCase()) ||
          values.components
            .toLowerCase()
            .includes(RequestedSearch.toLowerCase()) ||
          values.purpose
            .toLowerCase()
            .includes(RequestedSearch.toLowerCase()) ||
          values.patientId
            .toLowerCase()
            .includes(RequestedSearch.toLowerCase()) ||
          String(values.bloodGroup)
            .toLowerCase()
            .includes(RequestedSearch.toLowerCase()) ||
          String(values.bagId)
            .toLowerCase()
            .includes(RequestedSearch.toLowerCase()) ||
          String(values.bagId).includes(RequestedSearch) ||
          String(values.hospitalName)
            .toLowerCase()
            .includes(RequestedSearch.toLowerCase()) ||
          String(values.expiryDate)
            .toLocaleLowerCase()
            .includes(RequestedSearch.toLowerCase()) ||
          String(values.date)
            .toLocaleLowerCase()
            .includes(RequestedSearch.toLowerCase())
      );
      return setRequestedData(searchData);
    }
  }, [PeopleRequestedForList, RequestedSearch]);

  return (
    <>
      <div className='bd-tbl__inventory table-scroll'>
        <Tables
          tab={'Requested-for'}
          tableType={'table-type-1'}
          columns={PEOPLE_RECEPIENT_COLUMNS}
          tableData={requestedData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          headerClick={onHeaderClick}
          rowClick={false}
        />
      </div>
      <TablePagination
        component='div'
        count={requestedData.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

export default RequestList;
