import React from 'react';

function BedInfo() {
  return (
    <div className='box-1'>
      <div class='camp-header mb-20'>Attention</div>
      <div class='camp-header-child text-left mb-20'>
        The thumbrule for determining the number of beds or maximum donations or
        duration is derived as follows:
      </div>

      <div className='child-grid mb-20'>
        <div>
          <p className='mb-10 clr-blud camp-header-child text-left'>Range</p>
          <p className='text-left mb-10 camp-header-child'>Minimum</p>
          <p className='text-left mb-10 camp-header-child'>Maximum</p>
          <p className='text-left mb-10 camp-header-child'>Average</p>
        </div>
        <div>
          <p className='text-left mb-10 clr-blud camp-header-child text-left'>
            Duration
          </p>
          <p className='text-left mb-10 camp-header-child'>10 minutes</p>
          <p className='text-left mb-10 camp-header-child'>15 minutes</p>
          <p className='text-left mb-10 camp-header-child'>12 minutes</p>
        </div>
        <div>
          <p className='text-left mb-10 clr-blud camp-header-child text-left'>
            Duration
          </p>
          <p className='text-left mb-10 camp-header-child'>
            06 units of Blood / Bed / Hour
          </p>
          <p className='text-left mb-10 camp-header-child'>
            04 units of Blood / Bed / Hour
          </p>
          <p className='text-left mb-10 camp-header-child'>
            05 units of Blood / Bed / Hour
          </p>
        </div>
      </div>

      <div class='camp-header clr-blud mb-10 '> Donation Capacity </div>
      <div class='camp-header-child clr-blud mb-20'>
        (No. of Units / Bed / Hour) x (No. of Beds) x Camp Duration
      </div>

      <div class='camp-header-child text-left'>
        <p className='mb-10'>
          Eg. For 6 Hours camp with 5 beds, a camp can deliver{' '}
        </p>
        <p className='mb-10'>Maximum 06x05x06 = 180 units</p>
        <p className='mb-10'>Minimum 04x05x06 = 120 units</p>
        <p className='mb-10'>
          However, consider only 65% of Maximum capacity as achievable.
        </p>
      </div>
    </div>
  );
}

export default BedInfo;
