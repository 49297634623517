// Regsitationn AND Loging AND CREATE NEW FLOW
export const GET_STATE = 'GET_STATE';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_FAILED = 'GET_STATE_FAILED';

export const GET_CITY = 'GET_CITY';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAILED = 'GET_CITY_FAILED';

export const TRIGGER_LOGIN = 'TRIGGER_LOGIN';
export const TRIGGER_LOGIN_SUCCESS = 'TRIGGER_LOGIN_SUCCESS';
export const TRIGGER_LOGIN_FAILED = 'TRIGGER_LOGIN_FAILED';

export const POST_NEW_INSTITUTION = 'POST_NEW_INSTITUTION';
export const POST_NEW_INSTITUTION_SUCCESS = 'POST_NEW_INSTITUTION_SUCCESS';
export const POST_NEW_INSTITUTION_FAILED = 'POST_NEW_INSTITUTION_FAILED';

export const TRIGGER_REGISTER = 'TRIGGER_REGISTER';
export const TRIGGER_REGISTER_SUCCESS = 'TRIGGER_REGISTER_SUCCESS';
export const TRIGGER_REGISTER_FAILED = 'TRIGGER_REGISTER_FAILED';

export const TRIGGER_GET_INSTITUTE_LIST = 'TRIGGER_GET_INSTITUTE_LIST';
export const TRIGGER_GET_INSTITUTE_LIST_SUCCESS =
  'TRIGGER_GET_INSTITUTE_LIST_SUCCESS';
export const TRIGGER_GET_INSTITUTE_LIST_FAILED =
  'TRIGGER_GET_INSTITUTE_LIST_FAILED';

export const TRIGGER_SET_PASSWORD = 'TRIGGER_SET_PASSWORD';
export const TRIGGER_SET_PASSWORD_SUCCESS = 'TRIGGER_SET_PASSWORD_SUCCESS';
export const TRIGGER_SET_PASSWORD_FAILED = 'TRIGGER_SET_PASSWORD_FAILED';
export const RESET_SET_PASSWORD = 'RESET_SET_PASSWORD';

export const TRIGGER_CHANGE_PASSWORD = 'TRIGGER_CHANGE_PASSWORD';
export const TRIGGER_CHANGE_PASSWORD_SUCCESS =
  'TRIGGER_CHANGE_PASSWORD_SUCCESS';
export const TRIGGER_CHANGE_PASSWORD_FAILED = 'TRIGGER_CHANGE_PASSWORD_FAILED';

export const TRIGGER_FORGET_PASSWORD = 'TRIGGER_FORGET_PASSWORD';
export const TRIGGER_FORGET_PASSWORD_SUCCESS =
  'TRIGGER_FORGET_PASSWORD_SUCCESS';
export const TRIGGER_FORGET_PASSWORD_FAILED = 'TRIGGER_FORGET_PASSWORD_FAILED';

export const TRIGGER_FORGET_SET_PASSWORD = 'TRIGGER_FORGET_SET_PASSWORD';
export const TRIGGER_FORGET_SET_PASSWORD_SUCCESS =
  'TRIGGER_FORGET_SET_PASSWORD_SUCCESS';
export const TRIGGER_FORGET_SET_PASSWORD_FAILED =
  'TRIGGER_FORGET_SET_PASSWORD_FAILED';

export const TRIGGER_OTP = 'TRIGGER_OTP';
export const TRIGGER_OTP_SUCCESS = 'TRIGGER_OTP_SUCCESS';
export const TRIGGER_OTP_FAILED = 'TRIGGER_OTP_FAILED';

export const TRIGGER_LOGOUT = 'TRIGGER_LOGOUT';

export const SET_EMAIL_FOR_OTP = 'SET_EMAIL_FOR_OTP';

export const SET_OTP_SET_PASSWORD = 'SET_OTP_SET_PASSWORD';

export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';

export const POST_SETTINGS = 'POST_SETTINGS';
export const POST_SETTINGS_SUCCESS = 'POST_SETTINGS_SUCCESS';
export const POST_SETTINGS_FAILED = 'POST_SETTINGS_FAILED';

export const REMOVE_LAB_FROM_SETTINGS = 'REMOVE_LAB_FROM_SETTINGS';
export const REMOVE_LAB_FROM_SETTINGS_SUCCESS =
  'REMOVE_LAB_FROM_SETTINGS_SUCCESS';
export const REMOVE_LAB_FROM_SETTINGS_FAILED =
  'REMOVE_LAB_FROM_SETTINGS_FAILED';

export const UPLOAD_SETTINGS_INVENTORY = 'UPLOAD_SETTINGS_INVENTORY';
export const UPLOAD_SETTINGS_INVENTORY_SUCCESS =
  'UPLOAD_SETTINGS_INVENTORY_SUCCESS';
export const UPLOAD_SETTINGS_INVENTORY_FAILED =
  'UPLOAD_SETTINGS_INVENTORY_FAILED';

export const UPLOAD_SETTINGS_DONOR = 'UPLOAD_SETTINGS_DONOR';
export const UPLOAD_SETTINGS_DONOR_SUCCESS = 'UPLOAD_SETTINGS_DONOR_SUCCESS';
export const UPLOAD_SETTINGS_DONOR_FAILED = 'UPLOAD_SETTINGS_DONOR_FAILED';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

/**************** Dashboard actions starts ********************/

export const GET_REQUEST_DATA = 'GET_REQUEST_DATA';
export const GET_REQUEST_DATA_SUCCESS = 'GET_REQUEST_DATA_SUCCESS';
export const GET_REQUEST_DATA_FAILED = 'GET_REQUEST_DATA_FAILED';

export const GET_DONATION_DATA = 'GET_DONATION_DATA';
export const GET_DONATION_DATA_SUCCESS = 'GET_DONATION_DATA_SUCCESS';
export const GET_DONATION_DATA_FAILED = 'GET_DONATION_DATA_FAILED';

export const GET_CHART_DATA = 'GET_CHART_DATA';
export const GET_CHART_DATA_SUCCESS = 'GET_CHART_DATA_SUCCESS';
export const GET_CHART_DATA_FAILED = 'GET_CHART_DATA_FAILED';

export const INVENTORY_CHANGE_STATUS = 'INVENTORY_CHANGE_STATUS';
export const INVENTORY_CHANGE_STATUS_SUCCESS =
  'INVENTORY_CHANGE_STATUS_SUCCESS';
export const INVENTORY_CHANGE_STATUS_FAILED = 'INVENTORY_CHANGE_STATUS_FAILED';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const GET_DONOR_OTP = 'GET_DONOR_OTP';
export const GET_DONOR_OTP_SUCCESS = 'GET_DONOR_OTP_SUCCESS';
export const GET_DONOR_OTP_FAILED = 'GET_DONOR_OTP_FAILED';

export const VALIDATE_DONOR_OTP = 'VALIDATE_DONOR_OTP';
export const VALIDATE_DONOR_OTP_SUCCESS = 'VALIDATE_DONOR_OTP_SUCCESS';
export const VALIDATE_DONOR_OTP_FAILED = 'VALIDATE_DONOR_OTP_FAILED';

export const REQUEST_DONOR_CHANGE_STATUS = 'REQUEST_DONOR_CHANGE_STATUS';
export const REQUEST_DONOR_CHANGE_STATUS_SUCCESS =
  'REQUEST_DONOR_CHANGE_STATUS_SUCCESS';
export const REQUEST_DONOR_CHANGE_STATUS_FAILED =
  'REQUEST_DONOR_CHANGE_STATUS_FAILED';

export const BLOCK_DONOR = 'BLOCK_DONOR';
export const BLOCK_DONOR_SUCCESS = 'BLOCK_DONOR_SUCCESS';
export const BLOCK_DONOR_FAILED = 'BLOCK_DONOR_FAILED';

export const REQUEST_VERIFICATION = 'REQUEST_VERIFICATION';
export const REQUEST_VERIFICATION_SUCCESS = 'REQUEST_VERIFICATION_SUCCESS';
export const REQUEST_VERIFICATION_FAILED = 'REQUEST_VERIFICATION_FAILED';

export const REQUEST_VERIFIED = 'REQUEST_VERIFIED';
export const REQUEST_VERIFIED_SUCCESS = 'REQUEST_VERIFIED_SUCCESS';
export const REQUEST_VERIFIED_FAILED = 'REQUEST_VERIFIED_FAILED';

export const DONOR_LAB_RESULT = 'DONOR_LAB_RESULT';
export const DONOR_LAB_RESULT_SUCCESS = 'DONOR_LAB_RESULT_SUCCESS';
export const DONOR_LAB_RESULT_FAILED = 'DONOR_LAB_RESULT_FAILED';

export const DONATION_HISTORY = 'DONATION_HISTORY';
export const DONATION_HISTORY_SUCCESS = 'DONATION_HISTORY_SUCCESS';
export const DONATION_HISTORY_FAILED = 'DONATION_HISTORY_FAILED';

export const MEDICAL_HISTORY = 'MEDICAL_HISTORY';
export const MEDICAL_HISTORY_SUCCESS = 'MEDICAL_HISTORY_SUCCESS';
export const MEDICAL_HISTORY_FAILED = 'MEDICAL_HISTORY_FAILED';

export const DONATION_GRAPH = 'DONATION_GRAPH';
export const DONATION_GRAPH_SUCCESS = 'DONATION_GRAPH_SUCCESS';
export const DONATION_GRAPH_FAILED = 'DONATION_GRAPH_FAILED';

export const DONATION_BY_DONORID = 'DONATION_BY_DONORID';
export const DONATION_BY_DONORID_SUCCESS = 'DONATION_BY_DONORID_SUCCESS';
export const DONATION_BY_DONORID_FAILED = 'DONATION_BY_DONORID_FAILED';

export const REQUEST_ADD_BLOOD = 'REQUEST_ADD_BLOOD';
export const REQUEST_ADD_BLOOD_SUCCESS = 'REQUEST_ADD_BLOOD_SUCCESS';
export const REQUEST_ADD_BLOOD_FAILED = 'REQUEST_ADD_BLOOD_FAILED';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED';

export const DONATION_REMARK = 'DONATION_REMARK';
export const DONATION_REMARK_SUCCESS = 'DONATION_REMARK_SUCCESS';
export const DONATION_REMARK_FAILED = 'DONATION_REMARK_FAILED';

export const VERIFY_USER = 'VERIFY_USER';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILED = 'VERIFY_USER_FAILED';

export const POST_EDIT_VERIFY_USER = 'POST_EDIT_VERIFY_USER';
export const POST_EDIT_VERIFY_USER_SUCCESS = 'POST_EDIT_VERIFY_USER_SUCCESS';
export const POST_EDIT_VERIFY_USER_FAILED = 'POST_EDIT_VERIFY_USER_FAILED';

export const GET_DASHBOARD_TILEDATA = 'GET_DASHBOARD_TILEDATA';
export const GET_DASHBOARD_TILEDATA_SUCCESS = 'GET_DASHBOARD_TILEDATA_SUCCESS';
export const GET_DASHBOARD_TILEDATA_FAILED = 'GET_DASHBOARD_TILEDATA_FAILED';

export const GET_NGO_DASHBOARD_DATA = 'GET_NGO_DASHBOARD_DATA';
export const GET_NGO_DASHBOARD_DATA_SUCCESS = 'GET_NGO_DASHBOARD_DATA_SUCCESS';
export const GET_NGO_DASHBOARD_DATA_FAILED = 'GET_NGO_DASHBOARD_DATA_FAILED';

export const GET_NGO_REQUEST_TAB_DATA = 'GET_NGO_REQUEST_TAB_DATA';
export const GET_NGO_REQUEST_TAB_DATA_SUCCESS =
  'GET_NGO_REQUEST_TAB_DATA_SUCCESS';
export const GET_NGO_REQUEST_TAB_DATA_FAILED =
  'GET_NGO_REQUEST_TAB_DATA_FAILED';

export const POST_NGO_REQUEST_TAB_DATA = 'POST_NGO_REQUEST_TAB_DATA';
export const POST_NGO_REQUEST_TAB_DATA_SUCCESS =
  'POST_NGO_REQUEST_TAB_DATA_SUCCESS';
export const POST_NGO_REQUEST_TAB_DATA_FAILED =
  'POST_NGO_REQUEST_TAB_DATA_FAILED';

/******************************** Dashboard actions ends *******************************/

/******************************** Camps actions starts*********************************/

export const GET_CAMPS_LIST_DATA = 'GET_CAMPS_LIST_DATA';
export const GET_CAMPS_LIST_DATA_SUCCESS = 'GET_CAMPS_LIST_DATA_SUCCESS';
export const GET_CAMPS_LIST_DATA_FAILED = 'GET_CAMPS_LIST_DATA_FAILED';

export const GET_CAMPS_REQUEST_DATA = 'GET_CAMPS_REQUEST_DATA';
export const GET_CAMPS_REQUEST_DATA_SUCCESS = 'GET_CAMPS_REQUEST_DATA_SUCCESS';
export const GET_CAMPS_REQUEST_DATA_FAILED = 'GET_CAMPS_REQUEST_DATA_FAILED';

export const GET_CAMPS_DETAILS = 'GET_CAMPS_DETAILS';
export const GET_CAMPS_DETAILS_SUCCESS = 'GET_CAMPS_DETAILS_SUCCESS';
export const GET_CAMPS_DETAILS_FAILED = 'GET_CAMPS_DETAILS_FAILED';

// POST DONATE FORM DATA --  SUDARSHAN
export const POST_DONATE_FORM = 'POST_DONATE_FORM';
export const POST_DONATE_FORM_SUCCESS = 'POST_DONATE_FORM_SUCCESS';
export const POST_DONATE_FORM_FAILED = 'POST_DONATE_FORM_FAILED';

// GET DATA ON PROCEED CLICK FOT FORM
export const GET_ON_PROCEED_CLICK = 'GET_ON_PROCEED_CLICK';
export const GET_ON_PROCEED_CLICK_SUCCESS = 'GET_ON_PROCEED_CLICK_SUCCESS';
export const GET_ON_PROCEED_CLICK_FAILED = 'GET_ON_PROCEED_CLICK_FAILED';

export const POST_CAMPS_DATA = 'POST_CAMPS_DATA';
export const POST_CAMPS_DATA_SUCCESS = 'POST_CAMPS_DATA_SUCCESS';
export const POST_CAMPS_DATA_FAILED = 'POST_CAMPS_DATA_FAILED';

export const UPDATE_CAMPS_DATA = 'UPDATE_CAMPS_DATA';
export const UPDATE_CAMPS_DATA_SUCCESS = 'UPDATE_CAMPS_DATA_SUCCESS';
export const UPDATE_CAMPS_DATA_FAILED = 'UPDATE_CAMPS_DATA_FAILED';

// POST ACCEPT/DECLINE PENDING CAMPS CLICK -- SUDARSHAN
export const TRIGGER_ACCEPT_DECLINE_CAMP = 'TRIGGER_ACCEPT_DECLINE_CAMP';
export const TRIGGER_ACCEPT_DECLINE_CAMP_SUCCESS =
  'TRIGGER_ACCEPT_DECLINE_CAMP_SUCCESS';
export const TRIGGER_ACCEPT_DECLINE_CAMP_FAILED =
  'TRIGGER_ACCEPT_DECLINE_CAMP_FAILED';

export const TRIGGER_DECLINE_CAMP = 'TRIGGER_DECLINE_CAMP';
export const TRIGGER_DECLINE_CAMP_SUCCESS = 'TRIGGER_DECLINE_CAMP_SUCCESS';
export const TRIGGER_DECLINE_CAMP_FAILED = 'TRIGGER_DECLINE_CAMP_FAILED';

export const TRIGGER_DELETE_CAMP = 'TRIGGER_DELETE_CAMP';
export const TRIGGER_DELETE_CAMP_SUCCESS = 'TRIGGER_DELETE_CAMP_SUCCESS';
export const TRIGGER_DELETE_CAMP_FAILED = 'TRIGGER_DELETE_CAMP_FAILED';

export const TRIGGER_DEACTIVATE_CAMP = 'TRIGGER_DEACTIVATE_CAMP';
export const TRIGGER_DEACTIVATE_CAMP_SUCCESS =
  'TRIGGER_DEACTIVATE_CAMP_SUCCESS';
export const TRIGGER_DEACTIVATE_CAMP_FAILED = 'TRIGGER_DEACTIVATE_CAMP_FAILED';

export const TRIGGER_GET_ADDRESS_LIST = 'TRIGGER_GET_ADDRESS_LIST';
export const TRIGGER_GET_ADDRESS_LIST_SUCCESS =
  'TRIGGER_GET_ADDRESS_LIST_SUCCESS';
export const TRIGGER_GET_ADDRESS_LIST_FAILED =
  'TRIGGER_GET_ADDRESS_LIST_FAILED';

// GET NGO CRATED CAMP LIST
export const GET_NGO_CREATED_CAMP = 'GET_NGO_CREATED_CAMP';
export const GET_NGO_CREATED_CAMP_SUCCESS = 'GET_NGO_CREATED_CAMP_SUCCESS';
export const GET_NGO_CREATED_CAMP_FAILED = 'GET_NGO_CREATED_CAMP_FAILED';

// GET SERCHING BLOOD BANK IN CREAT CAMP --  NGO
export const GET_PARTICIPATING_BB = 'GET_PARTICIPATING_BB';
export const GET_PARTICIPATING_BB_SUCCESS = 'GET_PARTICIPATING_BB_SUCCESS';
export const GET_PARTICIPATING_BB_FAILED = 'GET_PARTICIPATING_BB_FAILED';

/*********************************  Camps actions ends *********************************/

// Add Donor Actions starts

export const POST_DONOR_DATA = 'POST_DONOR_DATA';
export const POST_DONOR_DATA_SUCCESS = 'POST_DONOR_DATA_SUCCESS';
export const POST_DONOR_DATA_FAILED = 'POST_DONOR_DATA_FAILED';
// Add Donor Actions ends

//Add Patient Action starts
export const POST_PATIENT_DATA = 'POST_PATIENT_DATA';
export const POST_PATIENT_DATA_SUCCESS = 'POST_PATIENT_DATA_SUCCESS';
export const POST_PATIENT_DATA_FAILED = 'POST_PATIENT_DATA_FAILED';

export const GET_PATIENT_LIST = 'GET_PATIENT_LIST';
export const GET_PATIENT_LIST_SUCCESS = 'GET_PATIENT_LIST_SUCCESS';
export const GET_PATIENT_LIST_FAILED = 'GET_PATIENT_LIST_FAILED';
//Add Patient Action ends

// export const POST_DONOR_DATA_SUCCESS = "POST_DONOR_DATA_SUCCESS";
// export const POST_DONOR_DATA_FAILED = "POST_DONOR_DATA_FAILED";

export const GET_DASHBOARD_INVENTORYLIST = 'GET_DASHBOARD_INVENTORYLIST';
export const GET_DASHBOARD_INVENTORYLIST_SUCCESS =
  'GET_DASHBOARD_INVENTORYLIST_SUCCESS';
export const GET_DASHBOARD_INVENTORYLIST_FAILED =
  'GET_DASHBOARD_INVENTORYLIST_FAILED';

export const ACCEPT_REQUEST = 'ACCEPT_REQUEST';
export const ACCEPT_REQUEST_SUCCESS = 'ACCEPT_REQUEST_SUCCESS';
export const ACCEPT_REQUEST_FAILED = 'ACCEPT_REQUEST_FAILED';

/*********************************  Dashboard actions ends*********************************/

/*********************************  People actions start *********************************/
export const GET_DONOR_LIST = 'GET_DONOR_LIST';
export const GET_DONOR_LIST_SUCCESS = 'GET_DONOR_LIST_SUCCESS';
export const GET_DONOR_LIST_FAILED = 'GET_DONOR_LIST_FAILED';
export const GET_RECEPIENT_LIST = 'GET_RECEPIENT_LIST';
export const GET_RECEPIENT_LIST_SUCCESS = 'GET_RECEPIENT_LIST_SUCCESS';
export const GET_RECEPIENT_LIST_FAILED = 'GET_RECEPIENT_LIST_FAILED';
export const BLOCK_AND_UNBLOCK = 'BLOCK_AND_UNBLOCK';
export const BLOCK_AND_UNBLOCK_SUCCESS = 'BLOCK_AND_UNBLOCK_SUCCESS';
export const BLOCK_AND_UNBLOCK_FAILED = 'BLOCK_AND_UNBLOCK_FAILED';
export const REQUEST_FOR_BLOOD = 'REQUEST_FOR_BLOOD';
export const REQUEST_FOR_BLOOD_SUCCESS = 'REQUEST_FOR_BLOOD_SUCCESS';
export const REQUEST_FOR_BLOOD_FAILED = 'REQUEST_FOR_BLOOD_FAILED';
/*********************************  People actions ends*********************************/

/*********************************  Inventory actions starts  sudarshan ******************************** */

// inventory transfer and ageing list
export const GET_INVENTORY_DATA = 'GET_INVENTORY_DATA';
export const GET_INVENTORY_DATA_SUCCESS = 'GET_INVENTORY_DATA_SUCCESS';
export const GET_INVENTORY_DATA_FAILED = 'GET_INVENTORY_DATA_FAILED';

export const GET_INVENTORY_RECEIPT_DATA = 'GET_INVENTORY_RECEIPT_DATA';
export const GET_INVENTORY_RECEIPT_DATA_SUCCESS =
  'GET_INVENTORY_RECEIPT_DATA_SUCCESS';
export const GET_INVENTORY_RECEIPT_DATA_FAILED =
  'GET_INVENTORY_RECEIPT_DATA_FAILED';

export const POST_INVENTORY_RECEIPT_DATA = 'POST_INVENTORY_RECEIPT_DATA';
export const POST_INVENTORY_RECEIPT_DATA_SUCCESS =
  'POST_INVENTORY_RECEIPT_DATA_SUCCESS';
export const POST_INVENTORY_RECEIPT_DATA_FAILED =
  'POST_INVENTORY_RECEIPT_DATA_FAILED';

export const DISPOSE_INVENTORY_DATA = 'DISPOSE_INVENTORY_DATA';
export const DISPOSE_INVENTORY_DATA_SUCCESS = 'DISPOSE_INVENTORY_DATA_SUCCESS';
export const DISPOSE_INVENTORY_DATA_FAILED = 'DISPOSE_INVENTORY_DATA_FAILED';

export const GET_AGEING_REPORT_DATA = 'GET_AGEING_REPORT_DATA';
export const GET_AGEING_REPORT_DATA_SUCCESS = 'GET_AGEING_REPORT_DATA_SUCCESS';
export const GET_AGEING_REPORT_DATA_FAILED = 'GET_AGEING_REPORT_DATA_FAILED';

export const ADD_INVENTORY = 'ADD_INVENTORY';
export const ADD_INVENTORY_SUCCESS = 'ADD_INVENTORY_SUCCESS';
export const ADD_INVENTORY_FAILED = 'ADD_INVENTORY_FAILED';

export const ISSUE_TO_PATIENT = 'ISSUE_TO_PATIENT';
export const ISSUE_TO_PATIENT_SUCCESS = 'ISSUE_TO_PATIENT_SUCCESS';
export const ISSUE_TO_PATIENT_FAILED = 'ISSUE_TO_PATIENT_FAILED';

export const ISSUE_TO_PROCESS = 'ISSUE_TO_PROCESS';
export const ISSUE_TO_PROCESS_SUCCESS = 'ISSUE_TO_PROCESS_SUCCESS';
export const ISSUE_TO_PROCESS_FAILED = 'ISSUE_TO_PROCESS_FAILED';

export const TRANSFER_INVENTORY = 'TRANSFER_INVENTORY';
export const TRANSFER_INVENTORY_SUCCESS = 'TRANSFER_INVENTORY_SUCCESS';
export const TRANSFER_INVENTORY_FAILED = 'TRANSFER_INVENTORY_FAILED';

export const DISPOSE_INVENTORY = 'DISPOSE_INVENTORY';
export const DISPOSE_INVENTORY_SUCCESS = 'DISPOSE_INVENTORY_SUCCESS';
export const DISPOSE_INVENTORY_FAILED = 'DISPOSE_INVENTORY_FAILED';

export const REQUEST_USER_DATA = 'REQUEST_USER_DATA';
export const REQUEST_USER_DATA_SUCCESS = 'REQUEST_USER_DATA_SUCCESS';
export const REQUEST_USER_DATA_FAILED = 'REQUEST_USER_DATA_FAILED';

export const PATIENT_SPECIFIC_INVENTORY = 'PATIENT_SPECIFIC_INVENTORY';
export const PATIENT_SPECIFIC_INVENTORY_SUCCESS =
  'PATIENT_SPECIFIC_INVENTORY_SUCCESS';
export const PATIENT_SPECIFIC_INVENTORY_FAILED =
  'PATIENT_SPECIFIC_INVENTORY_FAILED';

export const INVENTORY_ADD_PATIENT = 'INVENTORY_ADD_PATIENT';
export const INVENTORY_ADD_PATIENT_SUCCESS = 'INVENTORY_ADD_PATIENT_SUCCESS';
export const INVENTORY_ADD_PATIENT_FAILED = 'INVENTORY_ADD_PATIENT_FAILED';

export const TRANSFER_INSTITUTE_LIST = 'TRANSFER_INSTITUTE_LIST';
export const TRANSFER_INSTITUTE_LIST_SUCCESS =
  'TRANSFER_INSTITUTE_LIST_SUCCESS';
export const TRANSFER_INSTITUTE_LIST_FAILED = 'TRANSFER_INSTITUTE_LIST_FAILED';

export const INVENTORY_TRANSFER_PROCESS = 'INVENTORY_TRANSFER_PROCESS';
export const INVENTORY_TRANSFER_PROCESS_SUCCESS =
  'INVENTORY_TRANSFER_PROCESS_SUCCESS';
export const INVENTORY_TRANSFER_PROCESS_FAILED =
  'INVENTORY_TRANSFER_PROCESS_FAILED';

export const GET_IN_PROCESS_DATA = 'GET_IN_PROCESS_DATA';
export const GET_IN_PROCESS_DATA_SUCCESS = 'GET_IN_PROCESS_DATA_SUCCESS';
export const GET_IN_PROCESS_DATA_FAILED = 'GET_IN_PROCESS_DATA_FAILED';

export const POST_IN_PROCESS_DATA = 'POST_IN_PROCESS_DATA';
export const POST_IN_PROCESS_DATA_SUCCESS = 'POST_IN_PROCESS_DATA_SUCCESS';
export const POST_IN_PROCESS_DATA_FAILED = 'POST_IN_PROCESS_DATA_FAILED';

/*********************************  Inventory actions end  sudarshan *********************************/

// LAB RESULTS
export const GET_LAB_RESULTS = 'GET_LAB_RESULTS';
export const GET_LAB_RESULTS_SUCCESS = 'GET_LAB_RESULTS_SUCCESS';
export const GET_LAB_RESULTS_FAILED = 'GET_LAB_RESULTS_FAILED';

export const POST_LAB_RESULTS = 'POST_LAB_RESULTS';
export const POST_LAB_RESULTS_SUCCESS = 'POST_LAB_RESULTS_SUCCESS';
export const POST_LAB_RESULTS_FAILED = 'POST_LAB_RESULTS_FAILED';

export const GET_LAB_RESULTS_DETAILS = 'GET_LAB_RESULTS_DETAILS';
export const GET_LAB_RESULTS_DETAILS_SUCCESS =
  'GET_LAB_RESULTS_DETAILS_SUCCESS';
export const GET_LAB_RESULTS_DETAILS_FAILED = 'GET_LAB_RESULTS_DETAILS_FAILED';

export const CURR_SELECTED_LABS = 'CURR_SELECTED_LABS';

export const GET_LAB_RESULT_SAMPLES = 'GET_LAB_RESULT_SAMPLES';
export const GET_LAB_RESULT_SAMPLES_SUCCESS = 'GET_LAB_RESULT_SAMPLES_SUCCESS';
export const GET_LAB_RESULT_SAMPLES_FAILED = 'GET_LAB_RESULT_SAMPLES_FAILED';

export const ACCEPT_LABS = 'ACCEPT_LABS';
export const ACCEPT_LABS_SUCCESS = 'ACCEPT_LABS_SUCCESS';
export const ACCEPT_LABS_FAILED = 'ACCEPT_LABS_FAILED';

// GET LAB CREITERIA IN LAB MODULE --- SUDARSHAN
export const GET_LABS_CRITERIA = 'GET_LABS_CRITERIA';
export const GET_LABS_CRITERIA_SUCCESS = 'GET_LABS_CRITERIA_SUCCESS';
export const GET_LABS_CRITERIA_FAILED = 'GET_LABS_CRITERIA_FAILED';

// SETTINGS COMPONENT ACTION TYPE
export const GET_LAB_RESULT_SETTINGS = 'GET_LAB_RESULT_SETTINGS';
export const GET_LAB_RESULT_SETTINGS_SUCCESS =
  'GET_LAB_RESULT_SETTINGS_SUCCESS';
export const GET_LAB_RESULT_SETTINGS_FAILED = 'GET_LAB_RESULT_SETTINGS_FAILED';

export const POST_LAB_RESULT_SETTINGS = 'POST_LAB_RESULT_SETTINGS';
export const POST_LAB_RESULT_SETTINGS_SUCCESS =
  'POST_LAB_RESULT_SETTINGS_SUCCESS';
export const POST_LAB_RESULT_SETTINGS_FAILED =
  'POST_LAB_RESULT_SETTINGS_FAILED';

export const ADD_HOLIDAYS = 'ADD_HOLIDAYS';
export const ADD_MONTHLY_INVENTORY = 'ADD_MONTHLY_INVENTORY';

// POST_LAB_CRITERIA FROM SETTINGS -- SUDARSHAN
export const POST_LAB_CRITERIA = 'POST_LAB_CRITERIA';
export const POST_LAB_CRITERIA_SUCCESS = 'POST_LAB_CRITERIA_SUCCESS';
export const POST_LAB_CRITERIA_FAILED = 'POST_LAB_CRITERIA_FAILED';

//profile
export const GET_INSTITUTE_PROFILE = 'GET_INSTITUTE_PROFILE';
export const GET_INSTITUTE_PROFILE_SUCCESS = 'GET_INSTITUTE_PROFILE_SUCCESS';
export const GET_INSTITUTE_PROFILE_FAILED = 'GET_INSTITUTE_PROFILE_FAILED';

export const UPDATE_INSTITUTE_PROFILE = 'UPDATE_INSTITUTE_PROFILE';
export const UPDATE_INSTITUTE_PROFILE_SUCCESS =
  'UPDATE_INSTITUTE_PROFILE_SUCCESS';
export const UPDATE_INSTITUTE_PROFILE_FAILED =
  'UPDATE_INSTITUTE_PROFILE_FAILED';

// INVENTORY COMPONET LIST --- SUDARSHAN
export const INVENTORY_COMPONENT_LIST = 'INVENTORY_COMPONENT_LIST';
export const INVENTORY_COMPONENT_LIST_SUCCESS =
  'INVENTORY_COMPONENT_LIST_SUCCESS';
export const INVENTORY_COMPONENT_LIST_FAILED =
  'INVENTORY_COMPONENT_LIST_FAILED';

// INVENTORY COMPONET LIST --- SUDARSHAN
export const INVENTORY_DATA_LIST = 'INVENTORY_DATA_LIST';
export const INVENTORY_DATA_LIST_SUCCESS = 'INVENTORY_DATA_LIST_SUCCESS';
export const INVENTORY_DATA_LIST_FAILED = 'INVENTORY_DATA_LIST_FAILED';

// BLOOD COMPONENT LIST
export const BLOOD_COMPONENT_LIST = 'BLOOD_COMPONENT_LIST';
export const BLOOD_COMPONENT_LIST_SUCCESS = 'BLOOD_COMPONENT_LIST_SUCCESS';
export const BLOOD_COMPONENT_LIST_FAILED = 'BLOOD_COMPONENT_LIST_FAILED';

// CHAT

export const RECENT_CHATS = 'RECENT_CHATS';
export const CHAT_DETAIL = 'CHAT_DETAIL';

export const CHAT_DATA = 'CHAT_DATA';
export const CHAT_DATA_SUCCESS = 'CHAT_DATA_SUCCESS';
export const CHAT_DATA_FAILED = 'CHAT_DATA_FAILED';

/**************** super admin ADMINITRATOR actions starts ********************/
export const GET_ADMIN_LIST = 'GET_ADMIN_LIST';
export const GET_ADMIN_LIST_SUCCESS = 'GET_ADMIN_LIST_SUCCESS';
export const GET_ADMIN_LIST_FAILED = 'GET_ADMIN_LIST_FAILED';

export const GET_ADMIN_DATA = 'GET_ADMIN_DATA';
export const GET_ADMIN_DATA_SUCCESS = 'GET_ADMIN_DATA_SUCCESS';
export const GET_ADMIN_DATA_FAILED = 'GET_ADMIN_DATA_FAILED';

export const POST_ADD_ADMIN = 'POST_ADD_ADMIN';
export const POST_ADD_ADMIN_SUCCESS = 'POST_ADD_ADMIN_SUCCESS';
export const POST_ADD_ADMIN_FAILED = 'POST_ADD_ADMIN_FAILED';

export const POST_EDIT_ADMIN = 'POST_EDIT_ADMIN';
export const POST_EDIT_ADMIN_SUCCESS = 'POST_EDIT_ADMIN_SUCCESS';
export const POST_EDIT_ADMIN_FAILED = 'POST_EDIT_ADMIN_FAILED';

export const ACTIVE_INACTIVE_DELETE_ADMIN = 'ACTIVE_INACTIVE_DELETE_ADMIN';
export const ACTIVE_INACTIVE_DELETE_ADMIN_SUCCESS =
  'ACTIVE_INACTIVE_DELETE_ADMIN_SUCCESS';
export const ACTIVE_INACTIVE_DELETE_ADMIN_FAILED =
  'ACTIVE_INACTIVE_DELETE_ADMIN_FAILED';

/**************** super admin ADMINITRATOR  actions starts ********************/

/**************** super admin users actions starts ********************/
export const GET_USERS_DATA = 'GET_USERS_DATA';
export const GET_USERS_DATA_SUCCESS = 'GET_USERS_DATA_SUCCESS';
export const GET_USERS_DATA_FAILED = 'GET_USERS_DATA_FAILED';

export const BLOCK_UNBLOCK_USER = 'BLOCK_UNBLOCK_USER';
export const BLOCK_UNBLOCK_USER_SUCCESS = 'BLOCK_UNBLOCK_USER_SUCCESS';
export const BLOCK_UNBLOCK_USER_FAILED = 'BLOCK_UNBLOCK_USER_FAILED';

/**************** super admin users  actions starts ********************/

/**************** super admin COMMON ACTIONS  starts ********************/

export const RECALL_LOGIN = 'RECALL_LOGIN';
export const RECALL_LOGIN_SUCCESS = 'RECALL_LOGIN_SUCCESS';
export const RECALL_LOGIN_FAILED = 'RECALL_LOGIN_FAILED';

//  SUPER ADMIN DASHBOARD START ****************
export const DASHBOARD_TILES_DATA = 'DASHBOARD_TILES_DATA';
export const DASHBOARD_TILES_DATA_SUCCESS = 'DASHBOARD_TILES_DATA_SUCCESS';
export const DASHBOARD_TILES_DATA_FAILED = 'DASHBOARD_TILES_DATA_FAILED';
//
export const DASHBOARD_STATE_CITY_DATA = 'DASHBOARD_STATE_CITY_DATA';
export const DASHBOARD_STATE_CITY_DATA_SUCCESS =
  'DASHBOARD_STATE_CITY_DATA_SUCCESS';
export const DASHBOARD_STATE_CITY_DATA_FAILED =
  'DASHBOARD_STATE_CITY_DATA_FAILED';
//  SUPER ADMIN DASHBOARD END ****************

//TRANSACTION MODULE ACTIONS
export const GET_SUBCRIPTION_AND_BLUDUNIT_LIST =
  'GET_SUBCRIPTION_AND_BLUDUNIT_LIST';
export const GET_SUBCRIPTION_AND_BLUDUNIT_LIST_SUCCESS =
  'GET_SUBCRIPTION_AND_BLUDUNIT_LIST_SUCCESS';
export const GET_SUBCRIPTION_AND_BLUDUNIT_LIST_FAILED =
  'GET_SUBCRIPTION_AND_BLUDUNIT_LIST_FAILED';

// Subcription Plans
export const GET_SUBCRIPTION_PLANS = 'GET_SUBCRIPTION_PLANS';
export const GET_SUBCRIPTION_PLANS_SUCCESS = 'GET_SUBCRIPTION_PLANS_SUCCESS';
export const GET_SUBCRIPTION_PLANS_FAILED = 'GET_SUBCRIPTION_PLANS_FAILED';

export const POST_ADD_SUBACRIPTION_PLANS = 'POST_ADD_SUBACRIPTION_PLANS';
export const POST_ADD_SUBACRIPTION_PLANS_SUCCESS =
  'POST_ADD_SUBACRIPTION_PLANS_SUCCESS';
export const POST_ADD_SUBACRIPTION_PLANS_FAILED =
  'POST_ADD_SUBACRIPTION_PLANS_FAILED';

// Institution Module
export const GET_REGISTERED_AND_PENDING_DATA =
  'GET_REGISTERED_AND_PENDING_DATA';
export const GET_REGISTERED_AND_PENDING_DATA_SUCCESS =
  'GET_REGISTERED_AND_PENDING_DATA_SUCCESS';
export const GET_REGISTERED_AND_PENDING_DATA_FAILED =
  'GET_REGISTERED_AND_PENDING_DATA_FAILED';

export const GET_SINGLE_INSTITUTION_DETAIL = 'GET_SINGLE_INSTITUTION_DETAIL';
export const GET_SINGLE_INSTITUTION_DETAIL_SUCCESS =
  'GET_SINGLE_INSTITUTION_DETAIL_SUCCESS';
export const GET_SINGLE_INSTITUTION_DETAIL_FAILED =
  'GET_SINGLE_INSTITUTION_DETAIL_FAILED';

export const APPROVE_DECLINE_REQUEST = 'APPROVE_DECLINE_REQUEST';
export const APPROVE_DECLINE_REQUEST_SUCCESS =
  'APPROVE_DECLINE_REQUEST_SUCCESS';
export const APPROVE_DECLINE_REQUEST_FAILED = 'APPROVE_DECLINE_REQUEST_FAILED';

export const BLOCK_UNBLOCK_INSTITUTION = 'BLOCK_UNBLOCK_INSTITUTION';
export const BLOCK_UNBLOCK_INSTITUTION_SUCCESS =
  'BLOCK_UNBLOCK_INSTITUTION_SUCCESS';
export const BLOCK_UNBLOCK_INSTITUTION_FAILED =
  'BLOCK_UNBLOCK_INSTITUTION_FAILED';

// pricing informations
export const GET_PRICING_LIST = 'GET_PRICING_LIST';
export const GET_PRICING_LIST_SUCCESS = 'GET_PRICING_LIST_SUCCESS';
export const GET_PRICING_LIST_FAILED = 'GET_PRICING_LIST_FAILED';

/**************** super admin COMMON ACTIONS starts ********************/
