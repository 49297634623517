import React from "react";

function PageNotFound() {
  return (
    <div className="wrapper-404">
        <div className="page-not-found"/>
    </div>
  );
}
export default PageNotFound;
