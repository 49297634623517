import {
  BLOOD_COMPONENT_LIST,
  BLOOD_COMPONENT_LIST_SUCCESS,
  BLOOD_COMPONENT_LIST_FAILED
} from "../actions/actionTypes";

const initialState = {
  bloodComponentDataObj: {
    loading: false,
    bloodComponentList: [],
    error: null,
  }
}

const commonReducer = function (state = initialState, action) {
  switch(action.type) {
    case BLOOD_COMPONENT_LIST:
      return {
        ...state,
        bloodComponentDataObj: {
          loading: true,
          bloodComponentList: [],
          error: null,
        },
      };
    case BLOOD_COMPONENT_LIST_SUCCESS:
      let bloodComponentList = action?.payload?.content?.data ?? [];
      return {
        ...state,
        bloodComponentDataObj: {
          loading: false,
          bloodComponentList,
          error: null,
        },
      };

    case BLOOD_COMPONENT_LIST_FAILED:
      return {
        ...state,
        bloodComponentDataObj: {
          loading: false,
          bloodComponentList: [],
          error: action.error,
        },
      };

    default:
      return state;
  }
}

export default commonReducer;
