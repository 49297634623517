import React from 'react';
import { Tile } from '../../components/index';
import IndiaMapView from './IndiaMapView';
import DropDown from '../../components/RDropDown';

function SuperAdminDashboardView({
  Data,
  TileData,
  CoreData,
  fetchState,
  stateList,
  selectedStateList,
}) {
  // console.log(Data?.mapData);
  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className=''>
            <Tile dataArray={TileData} />
            <div className='verifyWrapper mt-20'>
              <div>
                <div className=' bb'>
                  <div className='d-flex justify-content-between mb-10'>
                    <div className='bd-count-value'>
                      Core Statistics Of{' '}
                      {selectedStateList === 'All'
                        ? 'India'
                        : selectedStateList}
                    </div>
                    {/* <div>DropDown</div> */}
                    <div style={{ width: '20%' }}>
                      <div className='bd-dorpdown width-100 '>
                        <div className='bd-dorpdown__selected pd-unset'>
                          <DropDown
                            options={stateList}
                            fetchDropDownValue={fetchState}
                            placeHolderText={'India'}
                          />
                          <i className='bludbig-arrow' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-20 bd_grid_2'>
                <div>
                  {CoreData.map((items) => {
                    return (
                      <div className=' bb mt-10'>
                        <div className='d-flex justify-content-between mb-10'>
                          <div>{items?.lable}</div>
                          <div className='fw-600 mr-20'>{items?.value}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {selectedStateList !== 'All' ? (
                    <div>
                      {selectedStateList !== 'All' &&
                      Data?.citiwisedonors &&
                      Object.keys(Data?.citiwisedonors).length ? (
                        <div className='bd-clr-red mb-10 text-center'>
                          List Of Cities With Number Of Donations
                        </div>
                      ) : (
                        <div className='bd-clr-red mb-10 text-center'>
                          No City List
                        </div>
                      )}
                      {Data?.citiwisedonors &&
                        Object.keys(Data?.citiwisedonors).map((item) => {
                          return (
                            <div className=' bb mt-10'>
                              <div className='d-flex justify-content-between mb-10'>
                                <div>{item}</div>
                                <div className='fw-600 mr-20'>
                                  {Data?.citiwisedonors[item]}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <IndiaMapView stateArr={Data?.mapData} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </>
  );
}

export default SuperAdminDashboardView;
