import React, { useEffect, useState } from 'react';
import DropDown from '../../components/RDropDown';
import { RadioButton, CheckBox } from '../index';
import { formatDate } from '../../utils';
import { BLOOD_COMPONENTS } from '../../utils/constants';

export const InventoryModal = ({
  title,
  buttonName,
  data,
  userList,
  showDetail,
  defaultUnits,
  defaultUser,
  onCancelClick,
  onButtonlick,
  dropDownChange,
}) => {
  const [currentRadioSelect, setCurrentRadioSelect] = useState('deliver');
  const [dropdownList, setDropdownList] = useState([]);
  const [unitValue, setUnitValue] = useState(defaultUnits || 0);
  const [modalData, setModalData] = useState(null);
  const onRadioChange = (e) => {
    setCurrentRadioSelect(e.target.value);
  };
  const checkboxClick = (e) => {
    let data = [...modalData.inventory];
    data &&
      data.map((a) => {
        if (a._id === e.target.id) {
          a['isSelected'] = e.target.checked;
        }
      });
    setUnitValue(modalData.inventory.filter((a) => a.isSelected).length);
    setModalData({
      ...modalData,
      inventory: data,
    });
  };
  useEffect(() => {
    if (data !== null || data !== undefined) {
      let obj = { ...data };
      if (obj?.inventory) {
        let list = [];
        obj.inventory.map((a, i) => {
          let o = {
            label: i + 1,
            value: i + 1,
          };
          list.push(o);
        });
        setDropdownList(list);
      }
      setModalData(obj);
      selectRecord(unitValue);
    }
  }, [data]);
  const selectRecord = (value) => {
    if (data?.inventory && data.inventory.length !== 0) {
      let _data = [...data.inventory];
      let list = [];
      _data.forEach((a, i) => {
        a['isSelected'] = i < value * 1;
        let o = {
          label: i + 1,
          value: i + 1,
        };
        list.push(o);
      });
      setDropdownList(list);
      setModalData({
        ...modalData,
        inventory: _data,
      });
    }
  };
  const handleOnChange = (e) => {
    setUnitValue(e.value);
    selectRecord(e.value);
  };
  const cells = [
    '',
    'Bag / Unit ID',
    'Donor ID',
    'Receipt',
    'Expiry',
    'Location',
  ];
  let disabledBtn = true;
  if (modalData?.inventory && modalData.inventory.length) {
    disabledBtn = false;
  }
  return (
    <div className='bd-modal-wrap bd-accept-issue'>
      <div className='bd-popup si-fade-in dashboard-request-popup'>
        <div className='bd-popup__head'>
          <span>{title}</span>
          <div className='bd-popup__head--rgt'>
            <div className='bd-blood-type'>
              <span>
                <b>{data?.bloodGroup}</b>
              </span>
              <span>
                {BLOOD_COMPONENTS[data?.component] || data?.component}
              </span>
            </div>
          </div>
        </div>
        <div
          className='bd-form__row'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <label>Quantity</label>
          <div className='bd-dorpdown inventory-dd'>
            <div className='bd-dorpdown__selected'>
              <DropDown
                width={'200px'}
                options={dropdownList}
                defaultValue={{ label: unitValue, value: unitValue }}
                fetchDropDownValue={handleOnChange}
              />
              <i className='bludbig-arrow' />
            </div>
          </div>
        </div>
        <div className='bd-popup__body'>
          <div className='bd-accept-issue__tbl'>
            <div className='bd-tbl bd-tbl__theme1'>
              <div className='bd-tbl__body--scroll'>
                <div className='bd-tbl__body'>
                  {modalData?.inventory && modalData.inventory.length > 0 && (
                    <div className='bd-tbl__row'>
                      {cells.map((cell) => {
                        return (
                          <div className='bd-tbl__cell'>
                            <span>{cell}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {modalData?.inventory && modalData.inventory.length > 0 ? (
                    modalData.inventory.map((record, i) => {
                      return (
                        <div key={'row' + i} className='bd-tbl__row'>
                          <div className='bd-tbl__cell'>
                            <CheckBox
                              keyindex={`checkbox_${record._id}`}
                              id={record._id}
                              checked={record.isSelected}
                              onChange={checkboxClick}
                            />
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{record?.bagId ?? '--'}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{record?.donorId ?? '--'}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{formatDate(record.collectionDate)}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{formatDate(record.expiryDate)}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{record?.storedIn ?? '--'}</span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className='noData_center'>
                      <div className='bd-tbl__body'>
                        <div className='bd-nodata'>
                          <span>No Data Available</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='bd-accept-issue__details'>
            {showDetail && (
              <div className='bd-accept-issue__bot'>
                <label>Details</label>
                <div className='bd-dorpdown'>
                  <div className='bd-dorpdown__selected dd-zindex'>
                    <DropDown
                      width={'200px'}
                      options={userList.map((a) => {
                        return {
                          label: a.name,
                          value: a.id,
                        };
                      })}
                      defaultValue={{
                        label: defaultUser?.name,
                        value: defaultUser?.id,
                      }}
                      fetchDropDownValue={dropDownChange}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
              </div>
            )}
            <div className='bd-accept-issue__bot'>
              <label>Comment</label>
              <div className='bd-accept-issue__comment'>
                <span>{data?.request?.comments}</span>
              </div>
            </div>
            <div className='bd-accept-issue__radio'>
              <RadioButton
                label='Will be delivered'
                name='radio1'
                value='deliver'
                onRadioChange={onRadioChange}
              />
              <RadioButton
                label='To be collected'
                name='radio1'
                value='collected'
                onRadioChange={onRadioChange}
              />
            </div>
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline' onClick={onCancelClick}>
                <span>Cancel</span>
              </div>
              <div
                className='bd-btn bd-btn--primary bd-btn--issue'
                disabled={disabledBtn}
                onClick={() =>
                  disabledBtn
                    ? null
                    : onButtonlick({
                        inventoryList: modalData.inventory,
                        unit: unitValue,
                        id: data.request._id,
                        deliveryType:
                          currentRadioSelect === 'deliver'
                            ? 'toBeDeliver'
                            : 'toBeCollected',
                      })
                }
              >
                <span>{buttonName}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
};
