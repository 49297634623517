import React, { useEffect, useState } from 'react';
import { INVENTORY_ISSUED_COLUMNS } from '../../utils/constants';
import InventoryTableList from '../../components/InventoryTableList';
import { Loader } from '../../components';

function IssuedView({ data, bloodComponentList, loading }) {
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    if (data.length) setRawData(data);
  }, [data]);

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          {loading ? (
            <Loader />
          ) : (
            bloodComponentList.length && (
              <InventoryTableList
                title={'Issued'}
                data={rawData}
                columns={INVENTORY_ISSUED_COLUMNS}
                bloodComponentList={bloodComponentList}
              />
            )
          )}
        </section>
      </article>
    </>
  );
}

export default IssuedView;
