import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { FailPopup, Spinner } from '../../components';
import Dropdown from '../../components/RDropDown';
import { SuccessPopup } from '../../components/Popups/SuccessPopup';
import UserHistory from '../../components/Popups/UserHistory';
import { emailPattern } from '../../utils';
import {
  BLOOD_GROUP_OPTIONS,
  GENDER_LIST,
  addPatientQuestions,
} from '../../utils/constants';

export default function AddPatientView({
  checkboxclick,
  sucessPopup,
  patientObj,
  saveHistory,
  addNewHistory,
  closePopup,
  customCheckListClick,
  onChangeCustomCheck,
  onSubmit,
  onChangeTextPatientForm,
  customCheckList,
  showUserHistoryPopUp,
  customHistoryRowMap,
  addNewCheckList,
  failedPopup,
  onContinueFailedClick,
  patientObject,
  onContinueClick,
  onCancelClick,
  errorMsg,
  onAddressSearch,
  selectAddress,
  addressFocused,
  setaddressFocused,
  headerDropDownChange,
}) {
  const userHistoryColumns = [
    '#',
    'Date',
    'Case',
    'Diagnosis',
    'Condition',
    'Treatment',
  ];

  const {
    firstName,
    lastName,
    mobileNumber,
    // gender,
    email,
    address,
    refferedBy,
    age,
    dateOfBirth,
    height,
    weight,
    // bloodGroup,
    aadharNumber,
    confirmAadhar,
    notes,
    patientId,
  } = patientObj;
  const { addressList } = useSelector((state) => state.campsReducer);

  return (
    <div style={{ backgroundColor: '#fff' }}>
      {showUserHistoryPopUp ? (
        <UserHistory
          columns={userHistoryColumns}
          customUserHistoryRow={customHistoryRowMap}
          onCancelClick={closePopup}
          onClick={addNewHistory}
          saveHistory={saveHistory}
        />
      ) : (
        ''
      )}

      <article className='bd-article '>
        <section className='bd-dashboard bd-bg-color'>
          {failedPopup ? (
            <FailPopup
              onContinueClick={onContinueFailedClick}
              title={errorMsg}
            />
          ) : (
            ''
          )}
          {sucessPopup ? (
            <SuccessPopup
              title={`Patient added successfully!`}
              onContinueClick={onContinueClick}
            />
          ) : (
            ''
          )}
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft bd-equalcolumn'>
              <div className='bd-dashboard__header'>
                <span className='bd-dashboard__main'>Add a Patient</span>
              </div>
              <div className='bd-dbd__form'>
                <section className='bd-adddonor'>
                  <div className='bd-adddonor-row bd-adddonor__6  align-items-center'>
                    <span className='bd_error bd_star'>*</span>
                    <input
                      type='text'
                      placeholder='First Name'
                      value={firstName}
                      name='firstName'
                      onChange={(e) => onChangeTextPatientForm(e)}
                    />
                  </div>
                  <div className='bd-adddonor-row bd-adddonor__6  align-items-center'>
                    <span className='bd_error bd_star'>*</span>
                    <input
                      type='text'
                      placeholder='Last Name'
                      value={lastName}
                      name='lastName'
                      onChange={(e) => onChangeTextPatientForm(e)}
                    />
                  </div>

                  <div className='bd-adddonor-row '>
                    <div className='d-flex align-items-center '>
                      <span className='bd_error bd_star'>*</span>
                      <input
                        type='text'
                        placeholder='Patient Id'
                        value={patientId}
                        name='patientId'
                        onChange={(e) => onChangeTextPatientForm(e)}
                      />
                    </div>
                    <div className='d-flex align-items-center ml-10'>
                      <span className='bd_error bd_star'>*</span>
                      <Dropdown
                        placeHolderText={'Gender'}
                        options={GENDER_LIST}
                        fetchDropDownValue={(e) =>
                          headerDropDownChange(e, 'gender')
                        }
                      />
                    </div>
                  </div>

                  <div className='bd-adddonor-row bd-patient_3'>
                    <ReactDatePicker
                      className='ml-10'
                      selected={dateOfBirth}
                      onChange={(date) =>
                        onChangeTextPatientForm(date, 'dateOfBirth')
                      }
                      dateFormat='dd/MM/yyyy'
                      placeholderText='Date of Birth'
                    />

                    <input
                      type='text'
                      placeholder='Age'
                      name='age'
                      value={age}
                      onChange={(e) => onChangeTextPatientForm(e)}
                    />

                    <Dropdown
                      placeHolderText={'Blood Group'}
                      options={BLOOD_GROUP_OPTIONS}
                      fetchDropDownValue={(e) =>
                        headerDropDownChange(e, 'bloodGroup')
                      }
                    />
                  </div>

                  <div className='bd-adddonor-row bd-adddonor__5'>
                    <div className='d-flex align-items-center w-100'>
                      <div className='ml-10'>
                        <input
                          type='number'
                          placeholder='Height'
                          name='height'
                          value={height}
                          onChange={(e) => onChangeTextPatientForm(e)}
                        />
                      </div>

                      <input
                        type='number'
                        placeholder='Weight'
                        name='weight'
                        value={weight}
                        onChange={(e) => onChangeTextPatientForm(e)}
                      />
                    </div>
                  </div>

                  <div
                    className=' bd-adddonor__6 '
                    style={{ marginBottom: '20px' }}
                  >
                    <div className='d-flex w-100'>
                      <span className='bd_error bd_star'>*</span>

                      <div
                        className='w-100 bd-adddonor-row  align-items-center'
                        style={{ margin: 'unset' }}
                      >
                        <input
                          className={` ${mobileNumber ? 'ml-10' : ''}`}
                          type='text'
                          placeholder='Email'
                          value={email}
                          name='email'
                          onChange={(e) => onChangeTextPatientForm(e)}
                        />
                      </div>
                    </div>
                    {email && !emailPattern.test(email) ? (
                      <p className='bd_error ml-10'>Invalid email!</p>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className='bd-adddonor-row bd-adddonor__6  align-items-center'>
                    <input
                      className='ml-10'
                      type='number'
                      placeholder='Mobile'
                      value={mobileNumber}
                      name='mobileNumber'
                      onChange={(e) => onChangeTextPatientForm(e)}
                    />
                  </div>

                  <div className='bd-adddonor-row bd-adddonor__6'>
                    <input
                      className='ml-10'
                      type='number'
                      placeholder='Confirm Aadhar'
                      value={confirmAadhar}
                      name='confirmAadhar'
                      onChange={(e) => onChangeTextPatientForm(e)}
                    />
                  </div>

                  <div className='bd-adddonor-row bd-adddonor__6'>
                    <input
                      className='ml-10'
                      type='number'
                      placeholder='Aadhar'
                      value={aadharNumber}
                      name='aadharNumber'
                      onChange={(e) => onChangeTextPatientForm(e)}
                    />
                    {aadharNumber &&
                    confirmAadhar &&
                    aadharNumber !== confirmAadhar ? (
                      <p className='bd_error'>
                        Aadhar & confirm aadhar number must match!
                      </p>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className='bd-adddonor-row bd-adddonor__6 d-block'>
                    <input
                      className='ml-10'
                      type='text'
                      placeholder='Address'
                      name='address'
                      value={address.description}
                      onChange={(e) => onAddressSearch(e)}
                      onFocus={(e) => setaddressFocused(true)}
                    />
                    {addressFocused &&
                    addressList?.data &&
                    addressList?.data?.length ? (
                      <div className='bd_address'>
                        {addressList?.data.map((data, i) => {
                          return (
                            <div
                              className='cursor-pointer'
                              key={i}
                              onClick={() => {
                                selectAddress(data);
                              }}
                            >
                              <div>{data.location.description},</div>
                              <div
                                onClick={() => {
                                  selectAddress(data);
                                }}
                              ></div>
                              <hr />
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className='bd-adddonor-row bd-adddonor__6'>
                    <input
                      className='ml-10'
                      type='text'
                      placeholder='Referred by'
                      value={refferedBy}
                      name='refferedBy'
                      onChange={(e) => onChangeTextPatientForm(e)}
                    />
                  </div>
                </section>
              </div>
            </div>

            <div className='bd-dashboard__rgt bd-equalcolumn'>
              <div className='bd-dbd__form'>
                <section className='bd-sec__10'>
                  <ul>
                    {addPatientQuestions.map((question, i) => {
                      return (
                        <li className='bd-adddonor-questions-set1' key={i}>
                          <span className='bd-checkbox'>
                            <input
                              type='checkbox'
                              onChange={checkboxclick}
                              checked={question.isSelected}
                            />
                            <b></b>
                          </span>
                          <div> {question.label}</div>
                        </li>
                      );
                    })}
                  </ul>

                  <ul>
                    {customCheckList.map((item, i) => {
                      return (
                        <li key={i} className='bd-adddonor-questions-set1'>
                          <span className='bd-checkbox'>
                            <input
                              onChange={(e) => customCheckListClick(e, i)}
                              type='checkbox'
                              checked={item.checked}
                            />
                            <b></b>
                          </span>
                          <div>
                            <span onClick={() => addNewCheckList(i)}>
                              {item.text}
                            </span>
                            {item.showInput ? (
                              <>
                                <input
                                  type='text'
                                  name='customCheck'
                                  onChange={(e) => onChangeCustomCheck(e, i)}
                                  value={item.value}
                                />
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </section>
                <section className='bd-sec__11'>
                  <div className='bd-sec__11--1'>
                    <textarea
                      placeholder='Notes'
                      name='notes'
                      value={notes}
                      rows='9'
                      cols='50'
                      onChange={(e) => onChangeCustomCheck(e)}
                    ></textarea>
                  </div>
                </section>
                {/* <p
                  className="bd_error fw-700"
                  onClick={() => setShowUserHistoryPopUp(true)}
                >
                  Add History
                </p> */}
              </div>
            </div>
          </div>
          <div className='bd-adddonor__save'>
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline' onClick={onCancelClick}>
                <span>Cancel</span>
              </div>
              <div
                className='bd-btn bd-btn--primary   db-bg__red'
                disabled={patientObject.loading}
                onClick={onSubmit}
              >
                <div className='d-flex align-items-center justify-content-center'>
                  {patientObject.loading ? (
                    <div>
                      {' '}
                      <Spinner />{' '}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='ml-5'> Save</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
  );
}
