import React from 'react';
import close from '../../assets/images/close.png';
import { RadioButton } from '../../components';

function CampDonorsForm_one_View({
  donorInfo,
  onCloseClick,
  errorFields,
  ErrorMsg,
  onInputChange,
  onRadioChange,
  onDonateClick,
  // campDonorsListObj,
}) {
  return (
    <div className='bd-modal-wrap bd-modal-right bd-CampDonorForm'>
      <div className='bd-popup si-fade-in'>
        <div style={{ paddingLeft: '10px' }}>
          <form onSubmit={onDonateClick}>
            <div className='bd-popup__head'>
              <span>Donor Data</span>
              <img
                src={close}
                height='25px'
                className='cursor-pointer'
                onClick={onCloseClick}
                alt='close'
              />
            </div>
            <div className='bd-popup__body'>
              <div className='bd-form'>
                <div className='bd-dbd__form'>
                  <section className='bd-sec__1'>
                    {/* <div className='bd-sec__1--1'>
                     */}
                    <div
                      className='bd-sec__1--1'
                      style={{ color: '#222326', fontSize: '16px' }}
                    >
                      <span>
                        {donorInfo?.firstName} {donorInfo?.lastName}
                      </span>
                      <span>{donorInfo?.id} </span>
                      <span>{donorInfo?.gender} </span>

                      <span>
                        <span></span>
                        {donorInfo?.age} <b>Yrs</b>
                      </span>
                    </div>
                  </section>
                  <div className='bd-sec__2--2 mTB'>
                    <div className='d-flex-center'>
                      <span>Height</span>
                      <input
                        className={errorFields['height'] ? 'form-error' : ''}
                        type='number'
                        placolder=''
                        value={donorInfo?.height}
                        name='height'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>cm</span>
                    </div>
                    <div className='d-flex-center'>
                      <span>Weight</span>
                      <input
                        className={errorFields['weight_1'] ? 'form-error' : ''}
                        type='number'
                        placolder=''
                        value={donorInfo?.weight_1}
                        name='weight_1'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>kg</span>
                    </div>
                  </div>
                  <div className='bd-sec__2--2 mTB'>
                    <div className='d-flex-center'>
                      <span>Hb</span>
                      <input
                        className={errorFields['hb'] ? 'form-error' : ''}
                        type='number'
                        placolder=''
                        value={donorInfo.hb}
                        name='hb'
                        onChange={(e) => onInputChange(e)}
                      />
                      <span>g/dL</span>
                    </div>
                    <div className='d-flex-center'>
                      <span>Blood Pressure</span>
                      <input
                        className={
                          errorFields['bp'] ? 'form-error fs-15' : 'fs-15'
                        }
                        // className='fs-15'
                        type='text'
                        placolder=''
                        value={donorInfo?.bp}
                        name='bp'
                        onChange={(e) => onInputChange(e)}
                      />

                      <span>mmHg</span>
                    </div>
                  </div>
                  <div className='bd-sec__2--2 mTB'>
                    <div className='d-flex-center'>
                      <span>Kit Number</span>
                      <input
                        className={
                          errorFields['kitNumber']
                            ? 'form-error fs-15'
                            : 'fs-15'
                        }
                        // className='fs-15'
                        type='text'
                        placolder=''
                        value={donorInfo?.kitNumber}
                        name='kitNumber'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                    <div className='d-flex-center'>
                      <span>Bag / Unit ID</span>
                      <input
                        className={
                          errorFields['bagId'] ? 'form-error fs-15' : 'fs-15'
                        }
                        // className='fs-15'
                        type='text'
                        placolder=''
                        value={donorInfo?.bagId}
                        name='bagId'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>

                  <section className='bd-sec__3'>
                    <div className='bd-sec__3--1'>
                      <span>Bag Type :</span>
                    </div>
                    <div className='bd-sec__3--2'>
                      <div>
                        <RadioButton
                          label='Single'
                          name='radio9'
                          value='single'
                          onRadioChange={onRadioChange}
                        />
                      </div>
                      <div>
                        <RadioButton
                          label='Double'
                          name='radio9'
                          value='double'
                          onRadioChange={onRadioChange}
                        />
                      </div>
                      <div>
                        <RadioButton
                          label='SAGM'
                          name='radio9'
                          value='SAGM'
                          onRadioChange={onRadioChange}
                        />
                      </div>
                      <div>
                        <RadioButton
                          label='Quardruple'
                          name='radio9'
                          value='quardruple'
                          onRadioChange={onRadioChange}
                        />
                      </div>
                    </div>
                  </section>
                  <section className='bd-sec__4 border-bottom'>
                    <div className='bd-sec__4--2'>
                      <div className='bd-sec__4--head'>
                        <span>Samples</span>
                      </div>
                      <div className='d-flex-center'>
                        <span># 1 ID</span>
                        <input
                          className={
                            errorFields['sampleId_1']
                              ? 'form-error fs-15'
                              : 'fs-15'
                          }
                          // className='fs-15'
                          type='text'
                          placeholder=''
                          value={donorInfo?.sampleId_1}
                          name='sampleId_1'
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className='d-flex-center'>
                        <span># 2 ID</span>
                        <input
                          className={
                            errorFields['sampleId_2']
                              ? 'form-error fs-15'
                              : 'fs-15'
                          }
                          // className='fs-15'
                          type='text'
                          placeholder=''
                          value={donorInfo?.sampleId_2}
                          name='sampleId_2'
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className='d-flex-center'>
                        <span># 3 ID</span>
                        <input
                          className={
                            errorFields['sampleId_3']
                              ? 'form-error fs-15'
                              : 'fs-15'
                          }
                          type='text'
                          placeholder=''
                          value={donorInfo?.sampleId_3}
                          name='sampleId_3'
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                  </section>
                  <section className='bd-sec__12'>
                    <div className='text-center clr-blud fs-15'>{ErrorMsg}</div>
                    <div className='bd-sec__12--2 padding-25'>
                      <div className='bd-btn__wrap'>
                        <div
                          className='bd-btn bd-btn--outline'
                          onClick={onCloseClick}
                        >
                          <span>Cancel</span>
                        </div>
                        <button
                          type='submit'
                          className='bd-btn bd-btn--primary'
                          // onClick={() => onDonateClick(campDonorsListObj)}
                        >
                          Donate
                        </button>
                      </div>
                    </div>
                  </section>
                  {/* below form tag end */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}

export default CampDonorsForm_one_View;
