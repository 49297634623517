import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddNewInstituteView from '../../views/AddNewInstituteView';
// import { postNewInstitution } from '../../redux/actions';
import { SuccessPopup, FailPopup } from '../../components';
import {
  postNewInstitution,
  getStateList,
  getCityList,
} from '../../redux/actions/authAction';
const createObj = {
  institutionName: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  latitude: '',
  longitude: '',
};
export default function AddNewInstituteContainer({
  setCreateNewInstitute,
  setInstituteNameFocused,
  regObj,
  setRegisterObj,
  setDisable,
  enableBtn,
}) {
  const dispatch = useDispatch();
  const tabs = ['BloodBank', 'Hospital'];
  useEffect(() => {
    dispatch(getStateList({ type: 'state' }));
    dispatch(getStateList({ type: 'city' }));
  }, []);

  const { newInstitution, getState, getCity } = useSelector(
    (state) => state.authReducer
  );
  const [activeTab, setActiveTab] = useState('BloodBank');
  const [createNewObj, setCreateNewObj] = useState(createObj);

  const [apIStateList, setApIStateList] = useState([]);
  const [apICityList, setApICityList] = useState([]);

  const [StateFocused, setStateFocused] = useState(false);
  const [CityFocused, setCityFocused] = useState(false);

  const [fieldsEmpty, setFieldsEmpty] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);

  function onTabChange(tab) {
    setActiveTab(tab);
    setCreateNewObj(createObj);
  }

  const {
    institutionName,
    address,
    city,
    state,
    zipcode,
    latitude,
    longitude,
  } = createNewObj;

  // Text Tiled Data
  function onChangeText(e) {
    let updateCreateNEwObj = { ...createNewObj };
    updateCreateNEwObj[e.target.name] = e.target.value;
    setCreateNewObj(updateCreateNEwObj);
  }

  function onStateSelect(data) {
    setStateFocused(false);
    let updateFIELDS = createNewObj;
    updateFIELDS['state'] = data;
    setCreateNewObj(updateFIELDS);

    return dispatch(getCityList({ state: data, type: 'city' }));
  }

  const onStateSearch = (e) => {
    onChangeText(e);
    if (e.target.value) {
      setApIStateList(sortingStateList(apIStateList));
    } else {
      setApIStateList(getState?.data?.content?.stateOrCity);
    }
  };

  function sortingStateList(data) {
    return (
      data &&
      data.filter((ele) => ele.toLowerCase().includes(state.toLowerCase()))
    );
  }

  function onCitySelect(data) {
    setCityFocused(false);
    let updateFIELDS = createNewObj;
    updateFIELDS['city'] = data;
    setCreateNewObj(updateFIELDS);
  }

  const onCitySearch = (e) => {
    onChangeText(e);
    if (e.target.value) {
      setApICityList(sortingCityList(apICityList));
    } else {
      setApICityList(getCity?.data?.content?.stateOrCity);
    }
  };

  function sortingCityList(data) {
    return (
      data &&
      data.filter((ele) => ele.toLowerCase().includes(city.toLowerCase()))
    );
  }

  // THis function is for  Patch the value to/pass the value to registration from
  function setInstituteNameData(response) {
    setRegisterObj({
      ...regObj,
      address: response.content.data.location,
      institutionName: response.content.data.institutionName,
      referenceId: response.content.data._id,
    });
    setInstituteNameFocused(false);
    setDisable(!enableBtn());
  }

  function closeCreatNewPopup() {
    setCreateNewInstitute(false);
    setCreateNewObj(createObj);
  }

  function onContinue(e) {
    e.preventDefault();
    let obj = {
      institutionType: activeTab,
      institutionName,
      address,
      city,
      state,
      zipcode,
      latitude,
      longitude,
    };
    if (
      !institutionName ||
      !address ||
      !city ||
      !state ||
      !zipcode ||
      !latitude ||
      !longitude
    ) {
      setFail(true);
    } else {
      dispatch(
        postNewInstitution(obj, (response) => {
          setInstituteNameData(response);
          setShowSuccess(true);
        })
      );
    }
  }

  useEffect(() => {
    if (getState?.data?.content?.stateOrCity) {
      setApIStateList(getState?.data?.content?.stateOrCity);
    }
    if (getCity?.data?.content?.stateOrCity) {
      setApICityList(getCity?.data?.content?.stateOrCity);
    }
  }, [getState, getCity]);

  useEffect(() => {
    if (newInstitution.error != null) {
      setFail(true);
    }
  }, [newInstitution]);

  return (
    <>
      <AddNewInstituteView
        createNewObj={createNewObj}
        apIStateList={apIStateList}
        getCity={getCity} // api call city list
        apICityList={apICityList}
        activeTab={activeTab}
        tabs={tabs}
        fieldsEmpty={fieldsEmpty}
        CityFocused={CityFocused}
        StateFocused={StateFocused}
        setCreateNewInstitute={setCreateNewInstitute}
        onTabChange={onTabChange}
        setStateFocused={setStateFocused}
        setCityFocused={setCityFocused}
        onChangeText={onChangeText}
        onContinue={onContinue}
        closeCreatNewPopup={closeCreatNewPopup}
        onStateSelect={onStateSelect}
        onStateSearch={onStateSearch}
        onCitySelect={onCitySelect}
        onCitySearch={onCitySearch}
      />
      {showSuccess && (
        <SuccessPopup
          title={'New Institution added successfully'}
          onContinueClick={() => {
            setCreateNewInstitute(false);
            setShowSuccess(false);
          }}
        />
      )}
      {showFail && (
        <FailPopup
          title={'All Fileds Is Required'}
          onContinueClick={() => {
            setFail(false);
          }}
        />
      )}
    </>
  );
}
