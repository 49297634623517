import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { triggerFetchAddress } from '../../redux/actions';
import { triggerAddPatient } from '../../redux/actions/addPatientAction';
import { BLOOD_GROUP_LIST, GENDER_LIST } from '../../utils/constants';
import AddPatientView from '../../views/AddPatientView';
export default function AddPatientContainer() {
  const { patientObject } = useSelector((state) => state.addPatientReducer);
  const [sucessPopup, setShowSuccessPopup] = useState(false);
  const [customCheckList, setCustomCheckList] = useState([
    {
      showCheckBox: true,
      text: 'Add more',
      showInput: false,
      value: '',
      checked: false,
    },
  ]);
  const [userHistoryObj, setUserHistoryObj] = useState([]);
  const [showUserHistoryPopUp, setShowUserHistoryPopUp] = useState(false);
  const [customUserHistory, setCustomUserHistory] = useState([{}]);
  const [failedPopup, setfailedPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const [addressFocused, setaddressFocused] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  let addPatientObj = {
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    patientId: '',
    mobileNumber: '',
    dateOfBirth: '',
    aadharNumber: '',
    confirmAadhar: '',
    bloodGroup: '',
    height: '',
    weight: '',
    age: '',
    address: {
      description: '',
    },
    refferedBy: '',
    notes: '',
    isSubmit: false,
  };
  const [addPatient, setAddPatientObj] = useState(addPatientObj);

  useEffect(() => {
    if (!patientObject?.loading && patientObject?.error) {
      setfailedPopup(true);
      setErrorMsg(patientObject?.error);
    }
  }, [patientObject]);

  function onChangeTextPatientForm(e, name) {
    let updateAddPatientObj = { ...addPatient };
    if (name) {
      updateAddPatientObj[name] = new Date(e);
    } else {
      updateAddPatientObj[e.target.name] = e.target.value;
    }
    setAddPatientObj(updateAddPatientObj);
  }

  function onAddressSearch(e) {
    if (e.target.value !== '') {
      onChangeTextPatientForm(e);
      return dispatch(triggerFetchAddress(e.target.value, (resp) => {}));
    }
  }

  function selectAddress(data) {
    setaddressFocused(false);
    let updateAddPatientObj = { ...addPatient };
    updateAddPatientObj['address'] = {
      coordinates: data.location.coordinates,
      description: data.location.description,
      type: data.location.type,
    };

    setAddPatientObj(updateAddPatientObj);
  }

  function headerDropDownChange(e, name) {
    let updateAddPatientObj = { ...addPatient };
    if (e.value) {
      updateAddPatientObj[name] = e.value;
    }
    setAddPatientObj(updateAddPatientObj);
  }

  function onSubmit(e) {
    e.preventDefault();
    const {
      firstName,
      lastName,
      gender,
      mobileNumber,
      email,
      aadharNumber,
      address,
      refferedBy,
      height,
      weight,
      dateOfBirth,
      age,
      patientId,
    } = addPatient;
    if (firstName === '' || lastName === '' || gender === '' || email === '') {
      setfailedPopup(true);
      setErrorMsg('Please enter required details!');
      return 0;
    }
    let obj = { firstName, lastName, email, gender, patientId };

    if (height) {
      obj.height = height;
    }
    if (aadharNumber) {
      obj.aadharNumber = aadharNumber;
    }
    if (address) {
      obj.address = address;
    }
    if (refferedBy) {
      obj.refferedBy = refferedBy;
    }
    if (weight) {
      obj.weight = weight;
    }
    if (dateOfBirth) {
      obj.dateOfBirth = dateOfBirth;
    }

    if (mobileNumber) {
      obj.mobileNumber = mobileNumber;
    }

    return dispatch(
      triggerAddPatient(obj, () => {
        setAddPatientObj(addPatientObj);
        setShowSuccessPopup(true);
      })
    );
  }

  function reset() {
    setAddPatientObj(addPatientObj);
    setUserHistoryObj([]);
    history.push('/');
  }

  function onChangeText(e, i) {
    let updateUserHistoryObj = [...userHistoryObj];
    let list = [...customUserHistory];
    if (updateUserHistoryObj.length && updateUserHistoryObj[i] !== undefined) {
      updateUserHistoryObj[i][e.target.name] = e.target.value;
    } else {
      updateUserHistoryObj.push({ [e.target.name]: e.target.value });
    }

    setUserHistoryObj(updateUserHistoryObj);
  }

  const customHistoryRowMap = customUserHistory.map((item, i) => {
    return (
      <div key={i} className='bd-tbl__row bd_add_history'>
        <div className='bd-tbl__cell'>{i + 1}</div>

        <div className='bd-tbl__cell'>
          <input onChange={(e) => onChangeText(e, i)} type='date' name='date' />
        </div>

        <div className='bd-tbl__cell'>
          <input onChange={(e) => onChangeText(e, i)} type='text' name='case' />
        </div>

        <div className='bd-tbl__cell'>
          <input
            onChange={(e) => onChangeText(e, i)}
            type='text'
            name='diagnosis'
          />
        </div>

        <div className='bd-tbl__cell'>
          <input
            onChange={(e) => onChangeText(e, i)}
            type='text'
            name='condition'
          />
        </div>

        <div className='bd-tbl__cell'>
          <input
            onChange={(e) => onChangeText(e, i)}
            type='text'
            name='treatment'
          />
        </div>
      </div>
    );
  });

  function addNewHistory() {
    let list = [...customUserHistory];
    list.push({});
    setCustomUserHistory(list);
    let updateUserHistoryObj = [...userHistoryObj];
    updateUserHistoryObj.push({});
    setUserHistoryObj(updateUserHistoryObj);
  }

  function saveHistory() {
    setShowUserHistoryPopUp(false);
  }

  function closePopup() {
    setShowUserHistoryPopUp(false);
  }

  function customCheckListClick(e, i) {
    let list = [...customCheckList];
    list[i].checked = e.target.checked;
    setCustomCheckList(list);
  }

  function onChangeCustomCheck(e, i) {
    let list = [...customCheckList];
    list[i].value = e.target.value;
    setCustomCheckList(list);
  }

  function addNewCheckList(i) {
    let list = [...customCheckList];
    let selectedItem = list[i];
    selectedItem.showInput
      ? (selectedItem.showInput = false)
      : (selectedItem.showInput = true);
    selectedItem.text
      ? (selectedItem.text = '')
      : (selectedItem.text = 'Add more');
    list[i] = selectedItem;
    list.push({
      showInput: false,
      text: 'Add more',
      value: '',
      checked: false,
    });
    setCustomCheckList(list);
  }
  function onContinueFailedClick() {
    setfailedPopup(false);
  }
  function onContinueClick() {
    setShowSuccessPopup(false);
  }
  return (
    <AddPatientView
      failedPopup={failedPopup}
      patientObj={addPatient}
      patientObject={patientObject}
      genderList={GENDER_LIST}
      bloodGroupList={BLOOD_GROUP_LIST}
      customHistoryRowMap={customHistoryRowMap}
      showUserHistoryPopUp={showUserHistoryPopUp}
      customUserHistory={customUserHistory}
      sucessPopup={sucessPopup}
      customCheckList={customCheckList}
      userHistoryObj={userHistoryObj}
      errorMsg={errorMsg}
      addressFocused={addressFocused}
      addNewCheckList={addNewCheckList}
      onContinueClick={onContinueClick}
      customCheckListClick={customCheckListClick}
      onChangeCustomCheck={onChangeCustomCheck}
      onContinueFailedClick={onContinueFailedClick}
      addNewHistory={addNewHistory}
      saveHistory={saveHistory}
      closePopup={closePopup}
      onChangeTextPatientForm={onChangeTextPatientForm}
      onSubmit={onSubmit}
      onCancelClick={reset}
      setCustomCheckList={setCustomCheckList}
      setCustomUserHistory={setCustomUserHistory}
      setUserHistoryObj={setUserHistoryObj}
      setShowUserHistoryPopUp={setShowUserHistoryPopUp}
      onAddressSearch={onAddressSearch}
      selectAddress={selectAddress}
      setaddressFocused={setaddressFocused}
      headerDropDownChange={headerDropDownChange}
    />
  );
}
