import { makeAPICall } from '../../utils';
import {
  API_BASE_PATH,
  DONATION_DATA_URL,
  REQUEST_USER_DATA_URL,
  CHART_DATA_URL,
  ISSUE_TO_PATIENT_URL,
  VERIFY_ISSUE_TO_PATIENT_URL,
  GET_PROFILE_URL,
  SUPER_ADMIN_USERS_URL,
  GET_DONOR_OTP_URL,
  VALIDATE_DONOR_OTP_URL,
  REQUEST_DONOR_CHANGE_STATUS_URL,
  DONOR_LAB_RESULT_URL,
  DONATION_HISTORY_URL,
  MEDICAL_HISTORY_URL,
  REQUEST_ADD_BLOOD_URL,
  UPLOAD_FILE_URL,
  DONATION_REMARK_URL,
  GET_HOSPITAL_LIST_URL,
  ISSUE_TO_REQUEST_URL,
  EDIT_URL,
  GET_DASHBOARD_TILES_DATA,
  GET_NGO_DASHBOARD_DATA_API,
  GET_NGO_REQUEST_TAB_DATA_API,
  REQ_ASSIGN_BTN_CLICK_API,
} from '../../utils/config';
import {
  GET_REQUEST_DATA,
  GET_REQUEST_DATA_SUCCESS,
  GET_REQUEST_DATA_FAILED,
  GET_DONATION_DATA,
  GET_DONATION_DATA_SUCCESS,
  GET_DONATION_DATA_FAILED,
  GET_CHART_DATA,
  GET_CHART_DATA_SUCCESS,
  GET_CHART_DATA_FAILED,
  GET_DASHBOARD_INVENTORYLIST,
  GET_DASHBOARD_INVENTORYLIST_SUCCESS,
  GET_DASHBOARD_INVENTORYLIST_FAILED,
  ACCEPT_REQUEST,
  ACCEPT_REQUEST_SUCCESS,
  ACCEPT_REQUEST_FAILED,
  INVENTORY_CHANGE_STATUS,
  INVENTORY_CHANGE_STATUS_SUCCESS,
  INVENTORY_CHANGE_STATUS_FAILED,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  GET_DONOR_OTP,
  GET_DONOR_OTP_SUCCESS,
  GET_DONOR_OTP_FAILED,
  VALIDATE_DONOR_OTP,
  VALIDATE_DONOR_OTP_SUCCESS,
  VALIDATE_DONOR_OTP_FAILED,
  REQUEST_DONOR_CHANGE_STATUS,
  REQUEST_DONOR_CHANGE_STATUS_SUCCESS,
  REQUEST_DONOR_CHANGE_STATUS_FAILED,
  BLOCK_DONOR,
  BLOCK_DONOR_SUCCESS,
  BLOCK_DONOR_FAILED,
  REQUEST_VERIFICATION,
  REQUEST_VERIFICATION_SUCCESS,
  REQUEST_VERIFICATION_FAILED,
  REQUEST_VERIFIED,
  REQUEST_VERIFIED_SUCCESS,
  REQUEST_VERIFIED_FAILED,
  DONOR_LAB_RESULT,
  DONOR_LAB_RESULT_SUCCESS,
  DONOR_LAB_RESULT_FAILED,
  DONATION_HISTORY,
  DONATION_HISTORY_SUCCESS,
  DONATION_HISTORY_FAILED,
  MEDICAL_HISTORY,
  MEDICAL_HISTORY_SUCCESS,
  MEDICAL_HISTORY_FAILED,
  DONATION_GRAPH,
  DONATION_GRAPH_SUCCESS,
  DONATION_GRAPH_FAILED,
  DONATION_BY_DONORID,
  DONATION_BY_DONORID_SUCCESS,
  DONATION_BY_DONORID_FAILED,
  REQUEST_ADD_BLOOD,
  REQUEST_ADD_BLOOD_SUCCESS,
  REQUEST_ADD_BLOOD_FAILED,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILED,
  DONATION_REMARK,
  DONATION_REMARK_SUCCESS,
  DONATION_REMARK_FAILED,
  TRIGGER_GET_INSTITUTE_LIST,
  TRIGGER_GET_INSTITUTE_LIST_SUCCESS,
  TRIGGER_GET_INSTITUTE_LIST_FAILED,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILED,
  POST_EDIT_VERIFY_USER,
  POST_EDIT_VERIFY_USER_SUCCESS,
  POST_EDIT_VERIFY_USER_FAILED,
  GET_DASHBOARD_TILEDATA,
  GET_DASHBOARD_TILEDATA_SUCCESS,
  GET_DASHBOARD_TILEDATA_FAILED,
  // Following For NGO DashBoard Data
  GET_NGO_DASHBOARD_DATA,
  GET_NGO_DASHBOARD_DATA_SUCCESS,
  GET_NGO_DASHBOARD_DATA_FAILED,
  // GET ngo req tab Data
  GET_NGO_REQUEST_TAB_DATA,
  GET_NGO_REQUEST_TAB_DATA_SUCCESS,
  GET_NGO_REQUEST_TAB_DATA_FAILED,
  // POT ngo req tab Data
  POST_NGO_REQUEST_TAB_DATA,
  POST_NGO_REQUEST_TAB_DATA_SUCCESS,
  POST_NGO_REQUEST_TAB_DATA_FAILED,
} from './actionTypes';
import { urlSearchParams } from '../../utils';

export function getRequestData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQUEST_USER_DATA_URL}`,
      dispatch,
      defaultAction: GET_REQUEST_DATA,
      successAction: GET_REQUEST_DATA_SUCCESS,
      failedAction: GET_REQUEST_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getDonationData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DONATION_DATA_URL}`,
      dispatch,
      defaultAction: GET_DONATION_DATA,
      successAction: GET_DONATION_DATA_SUCCESS,
      failedAction: GET_DONATION_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getChartsData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${CHART_DATA_URL}`,
      dispatch,
      defaultAction: GET_CHART_DATA,
      successAction: GET_CHART_DATA_SUCCESS,
      failedAction: GET_CHART_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getDashboardInventoryList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${CHART_DATA_URL}`,
      dispatch,
      defaultAction: GET_DASHBOARD_INVENTORYLIST,
      successAction: GET_DASHBOARD_INVENTORYLIST_SUCCESS,
      failedAction: GET_DASHBOARD_INVENTORYLIST_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getAcceptRequest(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${CHART_DATA_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: ACCEPT_REQUEST,
      successAction: ACCEPT_REQUEST_SUCCESS,
      failedAction: ACCEPT_REQUEST_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function changeInventoryStatus(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ISSUE_TO_PATIENT_URL}`,
      dispatch,
      defaultAction: INVENTORY_CHANGE_STATUS,
      successAction: INVENTORY_CHANGE_STATUS_SUCCESS,
      failedAction: INVENTORY_CHANGE_STATUS_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function issueRequest(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ISSUE_TO_REQUEST_URL}`,
      dispatch,
      defaultAction: INVENTORY_CHANGE_STATUS,
      successAction: INVENTORY_CHANGE_STATUS_SUCCESS,
      failedAction: INVENTORY_CHANGE_STATUS_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getDonarProfile(obj, callback, type) {
  console.log(type);
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${
        type === undefined ? GET_PROFILE_URL : SUPER_ADMIN_USERS_URL
      }?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_PROFILE,
      successAction: GET_PROFILE_SUCCESS,
      failedAction: GET_PROFILE_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function getDonarOTP(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_DONOR_OTP_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_DONOR_OTP,
      successAction: GET_DONOR_OTP_SUCCESS,
      failedAction: GET_DONOR_OTP_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function validateDonorOTP(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${VALIDATE_DONOR_OTP_URL}`,
      dispatch,
      defaultAction: VALIDATE_DONOR_OTP,
      successAction: VALIDATE_DONOR_OTP_SUCCESS,
      failedAction: VALIDATE_DONOR_OTP_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function donorStatusChange(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQUEST_DONOR_CHANGE_STATUS_URL}`,
      dispatch,
      defaultAction: REQUEST_DONOR_CHANGE_STATUS,
      successAction: REQUEST_DONOR_CHANGE_STATUS_SUCCESS,
      failedAction: REQUEST_DONOR_CHANGE_STATUS_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function donorBlockStatus(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQUEST_DONOR_CHANGE_STATUS_URL}`,
      dispatch,
      defaultAction: BLOCK_DONOR,
      successAction: BLOCK_DONOR_SUCCESS,
      failedAction: BLOCK_DONOR_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getRequestVerificationData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQUEST_USER_DATA_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: REQUEST_VERIFICATION,
      successAction: REQUEST_VERIFICATION_SUCCESS,
      failedAction: REQUEST_VERIFICATION_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function getRequestVerifiedData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQUEST_USER_DATA_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: REQUEST_VERIFIED,
      successAction: REQUEST_VERIFIED_SUCCESS,
      failedAction: REQUEST_VERIFIED_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function getDonorLabResultData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DONOR_LAB_RESULT_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: DONOR_LAB_RESULT,
      successAction: DONOR_LAB_RESULT_SUCCESS,
      failedAction: DONOR_LAB_RESULT_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}
export function getDonationHistoryData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DONATION_HISTORY_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: DONATION_HISTORY,
      successAction: DONATION_HISTORY_SUCCESS,
      failedAction: DONATION_HISTORY_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}
export function getMedicalHistoryData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${MEDICAL_HISTORY_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: MEDICAL_HISTORY,
      successAction: MEDICAL_HISTORY_SUCCESS,
      failedAction: MEDICAL_HISTORY_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}
export function getDonationGraphData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DONATION_DATA_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: DONATION_GRAPH,
      successAction: DONATION_GRAPH_SUCCESS,
      failedAction: DONATION_GRAPH_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function donationByDonorId(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQUEST_DONOR_CHANGE_STATUS_URL}`,
      dispatch,
      defaultAction: DONATION_BY_DONORID,
      successAction: DONATION_BY_DONORID_SUCCESS,
      failedAction: DONATION_BY_DONORID_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function addBloodRequest(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQUEST_ADD_BLOOD_URL}`,
      dispatch,
      defaultAction: REQUEST_ADD_BLOOD,
      successAction: REQUEST_ADD_BLOOD_SUCCESS,
      failedAction: REQUEST_ADD_BLOOD_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function uploadFile(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${UPLOAD_FILE_URL}`,
      dispatch,
      defaultAction: UPLOAD_FILE,
      successAction: UPLOAD_FILE_SUCCESS,
      failedAction: UPLOAD_FILE_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getRemarkyData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DONATION_REMARK_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: DONATION_REMARK,
      successAction: DONATION_REMARK_SUCCESS,
      failedAction: DONATION_REMARK_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}
export function triggerFetchInstituteList(param) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_HOSPITAL_LIST_URL}${param}`,
      dispatch,
      defaultAction: TRIGGER_GET_INSTITUTE_LIST,
      successAction: TRIGGER_GET_INSTITUTE_LIST_SUCCESS,
      failedAction: TRIGGER_GET_INSTITUTE_LIST_FAILED,
      type: 'GET',
      noToken: true,
    };
    makeAPICall(params);
  };
}
export function changeUserStatus(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${VERIFY_ISSUE_TO_PATIENT_URL}`,
      dispatch,
      defaultAction: VERIFY_USER,
      successAction: VERIFY_USER_SUCCESS,
      failedAction: VERIFY_USER_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function EditVerifyUsers(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${EDIT_URL}`,
      dispatch,
      defaultAction: POST_EDIT_VERIFY_USER,
      successAction: POST_EDIT_VERIFY_USER_SUCCESS,
      failedAction: POST_EDIT_VERIFY_USER_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getDashboardTilesData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_DASHBOARD_TILES_DATA}`,
      dispatch,
      defaultAction: GET_DASHBOARD_TILEDATA,
      successAction: GET_DASHBOARD_TILEDATA_SUCCESS,
      failedAction: GET_DASHBOARD_TILEDATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getNGODashboardData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_NGO_DASHBOARD_DATA_API}`,
      dispatch,
      defaultAction: GET_NGO_DASHBOARD_DATA,
      successAction: GET_NGO_DASHBOARD_DATA_SUCCESS,
      failedAction: GET_NGO_DASHBOARD_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getNGODashboardReqTabData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_NGO_REQUEST_TAB_DATA_API}?${urlSearchParams(
        obj
      )}`,
      dispatch,
      defaultAction: GET_NGO_REQUEST_TAB_DATA,
      successAction: GET_NGO_REQUEST_TAB_DATA_SUCCESS,
      failedAction: GET_NGO_REQUEST_TAB_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function assignBtnClick(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQ_ASSIGN_BTN_CLICK_API}`,
      dispatch,
      defaultAction: POST_NGO_REQUEST_TAB_DATA,
      successAction: POST_NGO_REQUEST_TAB_DATA_SUCCESS,
      failedAction: POST_NGO_REQUEST_TAB_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
