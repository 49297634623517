import { CHAT_DATA, CHAT_DATA_SUCCESS, CHAT_DATA_FAILED } from './actionTypes';
import { makeAPICall } from '../../utils';
import { API_BASE_PATH, GET_CHAT_URL } from '../../utils/config';

export function getChat(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_CHAT_URL}`,
      dispatch,
      defaultAction: CHAT_DATA,
      successAction: CHAT_DATA_SUCCESS,
      failedAction: CHAT_DATA_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}
