import {
  TRIGGER_LOGIN,
  TRIGGER_LOGIN_SUCCESS,
  TRIGGER_LOGIN_FAILED,
} from '../actions/actionTypes';
import { login } from '../../utils';

const initialState = {
  loginObj: {
    loading: false,
    data: [],
    error: null,
  },
};

const authReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_LOGIN:
      return {
        ...state,
        loginObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_LOGIN_SUCCESS:
      login(action?.payload?.content?.data);
      return {
        ...state,
        loginObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_LOGIN_FAILED:
      return {
        ...state,
        loginObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default authReducer;
