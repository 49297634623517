import {
  TRIGGER_REGISTER,
  TRIGGER_REGISTER_SUCCESS,
  TRIGGER_REGISTER_FAILED,
  TRIGGER_SET_PASSWORD,
  TRIGGER_SET_PASSWORD_SUCCESS,
  TRIGGER_SET_PASSWORD_FAILED,
  TRIGGER_GET_INSTITUTE_LIST,
  TRIGGER_GET_INSTITUTE_LIST_SUCCESS,
  TRIGGER_GET_INSTITUTE_LIST_FAILED,
  TRIGGER_FORGET_PASSWORD,
  TRIGGER_FORGET_PASSWORD_FAILED,
  TRIGGER_FORGET_PASSWORD_SUCCESS,
  SET_EMAIL_FOR_OTP,
  TRIGGER_FORGET_SET_PASSWORD,
  TRIGGER_FORGET_SET_PASSWORD_SUCCESS,
  TRIGGER_FORGET_SET_PASSWORD_FAILED,
  SET_OTP_SET_PASSWORD,
  RESET_SET_PASSWORD,
  TRIGGER_OTP,
  TRIGGER_OTP_SUCCESS,
  TRIGGER_OTP_FAILED,
  POST_NEW_INSTITUTION,
  POST_NEW_INSTITUTION_SUCCESS,
  POST_NEW_INSTITUTION_FAILED,
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_FAILED,
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_FAILED,
} from '../actions/actionTypes';
// import { login } from '../../utils';

const initialState = {
  registerObj: {
    loading: false,
    data: [],
    error: null,
  },
  setPassObj: {
    loading: false,
    data: [],
    error: null,
  },
  instituteListObj: {
    loading: false,
    data: null,
    error: null,
  },

  forgetPassword: {
    loading: false,
    data: null,
    error: null,
  },

  forgetSetPassword: {
    loading: false,
    data: null,
    error: null,
  },

  confirmOTP: {
    loading: false,
    data: null,
    error: null,
  },

  newInstitution: {
    loading: false,
    data: null,
    error: null,
  },

  getState: {
    loading: false,
    data: [],
    error: null,
  },
  getCity: {
    loading: false,
    data: [],
    error: null,
  },

  emailForOTP: null,
  otpForSetPass: null,
};

const authReducer = function (state = initialState, action) {
  switch (action.type) {
    case TRIGGER_REGISTER:
      return {
        ...state,
        registerObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_REGISTER_SUCCESS:
      return {
        ...state,
        registerObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_REGISTER_FAILED:
      return {
        ...state,
        registerObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_GET_INSTITUTE_LIST:
      return {
        ...state,
        instituteListObj: {
          loading: true,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_GET_INSTITUTE_LIST_SUCCESS:
      return {
        ...state,
        instituteListObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_GET_INSTITUTE_LIST_FAILED:
      return {
        ...state,
        instituteListObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_SET_PASSWORD:
      return {
        ...state,
        setPassObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPassObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_SET_PASSWORD_FAILED:
      return {
        ...state,
        setPassObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case RESET_SET_PASSWORD:
      return {
        ...state,
        setPassObj: {
          loading: false,
          data: null,
          error: null,
        },
      };

    case TRIGGER_FORGET_PASSWORD:
      return {
        ...state,
        forgetPassword: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPassword: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_FORGET_PASSWORD_FAILED:
      return {
        ...state,
        forgetPassword: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_FORGET_SET_PASSWORD:
      return {
        ...state,
        forgetSetPassword: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_FORGET_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetSetPassword: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_FORGET_SET_PASSWORD_FAILED:
      return {
        ...state,
        forgetSetPassword: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_OTP:
      return {
        ...state,
        confirmOTP: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case TRIGGER_OTP_SUCCESS:
      return {
        ...state,
        confirmOTP: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_OTP_FAILED:
      return {
        ...state,
        confirmOTP: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case POST_NEW_INSTITUTION:
      return {
        ...state,
        newInstitution: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case POST_NEW_INSTITUTION_SUCCESS:
      return {
        ...state,
        newInstitution: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case POST_NEW_INSTITUTION_FAILED:
      return {
        ...state,
        newInstitution: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_STATE:
      return {
        ...state,
        getState: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_STATE_SUCCESS:
      return {
        ...state,
        getState: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case GET_STATE_FAILED:
      return {
        ...state,
        getState: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_CITY:
      return {
        ...state,
        getCity: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_CITY_SUCCESS:
      return {
        ...state,
        getCity: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case GET_CITY_FAILED:
      return {
        ...state,
        getCity: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case SET_EMAIL_FOR_OTP:
      return {
        ...state,
        emailForOTP: action.payload,
      };

    case SET_OTP_SET_PASSWORD:
      return {
        ...state,
        otpForSetPass: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
