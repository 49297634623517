import React, { useEffect } from 'react';

export default function UserHistory(props) {
  useEffect(() => {}, [props.customUserHistoryRow]);

  return (
    <div className='bd-modal-wrap bd-modal-right bd-profile'>
      <div className='bd-popup si-fade-in'>
        <p onClick={props.onClick}>Add New</p>
        <div className='bd-popup__body'>
          <div className='bd-tbl'>
            <div className='bd-tbl__head'>
              <div className='bd-tbl__row'>
                {props.columns.map((col) => {
                  return (
                    <div className='bd-tbl__cell'>
                      <span>{col}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='bd-tbl__body'>
              {props.customUserHistoryRow.map((row) => {
                return row;
              })}
            </div>
          </div>
          <div className='bd-btn__wrap'>
            <div
              className='bd-btn bd-btn--outline'
              onClick={props.onCancelClick}
            >
              <span>Cancel</span>
            </div>

            <div className='bd-btn bd-btn--primary' onClick={props.saveHistory}>
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox' />
    </div>
  );
}
