import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { triggerAuthLogin } from '../../redux/actions';
import SuperAdminLoginView from '../../views/LoginView/SuperAdminLoginView';
import { AuthHeader } from '../../components/AuthHeader';
import { LandscapeImage } from '../../components/Auth';
import Google from '../../assets/images/Google.png';
import Apple from '../../assets/images/Apple.png';

const SuperAdminLoginContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loginObj } = useSelector((state) => state.loginReducer);
  const [showPassword, setShowPassword] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [errorMsg, setError] = useState('');

  const loginCredObj = {
    loginId: '',
    password: '',
  };
  const [loginCred, setLoginCred] = useState(loginCredObj);

  useEffect(() => {
    setIsMobile(detectMob());
  });

  const onChangeText = (e) => {
    let updatedLoginObj = loginCred;
    updatedLoginObj[e.target.name] = e.target.value;
    setLoginCred(updatedLoginObj);
  };

  let type = 'SuperAdmin';
  const onSubmit = () => {
    if (loginCred.loginId !== '' && loginCred.password !== '') {
      setError('');
      return dispatch(
        triggerAuthLogin(
          loginCred,
          () => {
            history.push('/Super-Admin/Dashboard');
          },
          type
        )
      );
    } else {
      setError('Enter valid email and password');
    }
  };

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const detectMob = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  function escFunction(e) {
    if (e.keyCode === 13) {
      onSubmit();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  });

  return (
    <>
      <div className={isMobile && 'login-overlay-bg'}>
        {isMobile ? (
          <div className='login-overlay'>
            <div className='center text-center w-100 '>
              <p>Have a better view of the Web App on your desktop / laptop.</p>
              {/* <p>   You can also view it on landscape mode.</p> */}
              <p>You can download the Mobile App from your favourite stores,</p>
              <Link to=''>
                <img src={Google} className='playstore-img' />
              </Link>
              <Link to=''>
                <img src={Apple} className='playstore-img' />
              </Link>
            </div>
          </div>
        ) : (
          ''
        )}

        <div className={'bd_reg_bg ' + (isMobile ? 'pointer-none' : '')}>
          <AuthHeader />
          <div className='bd_login'>
            <LandscapeImage />
            <SuperAdminLoginView
              errorMsg={errorMsg}
              loginObj={loginObj}
              onChangeText={onChangeText}
              onSubmit={onSubmit}
              showPasswordToggle={showPasswordToggle}
              showPassword={showPassword}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminLoginContainer;
