import React, { useState, useEffect } from 'react';
import {
  ageCalculation,
  genderWiseProfilePic,
  getpermission,
} from '../../utils';
import { useHistory } from 'react-router';

export function SuperAdminAdministratorProfilepopup({
  profileData,
  rawData,
  setShowProfilePopup,
  ActionBtn,
  permissionOfAddForm,
}) {
  const history = useHistory();
  const cells = [
    { label: 'Name:', value: profileData.name ? profileData.name : '--' },
    { label: 'Gender:', value: profileData.gender ? profileData.gender : '--' },
    { label: 'Email:', value: profileData.email ?? '--' },
    { label: 'Phone:', value: profileData.contactNo ?? '--' },
    { label: 'Address:', value: profileData.address ?? '--' },
    {
      label: 'Age:',
      value:
        profileData && profileData.dob ? ageCalculation(profileData.dob) : '',
    },
    {
      label: 'status:',
      value:
        profileData.status?.status === 1
          ? 'Active'
          : profileData.status?.status === 2
          ? 'Inactive'
          : 'Pending',
    },
  ];
  const infoCells = [
    {
      label: 'User Account Management',
      value: getpermission(profileData?.permissions?.userManagement),
    },
    {
      label: 'Institution Account Management',
      value: getpermission(profileData?.permissions?.instituteManagement),
    },
    {
      label: 'Admins Account Management',
      value: getpermission(profileData?.permissions?.admins),
    },
    {
      label: 'Pricing ',
      value: getpermission(profileData?.permissions?.billing),
    },
    {
      label: 'Configration ',
      value: getpermission(profileData?.permissions?.configurations),
    },
    {
      label: 'Subscription ',
      value: getpermission(profileData?.permissions?.subscription),
    },
  ];

  return (
    <div className='bd-modal-wrap bd-profile'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head mp_remove '>
          <span>Profile of Admin</span>
          <i
            className='bludclose '
            onClick={() => {
              setShowProfilePopup(false);
            }}
          ></i>
        </div>

        <div className='bd-popup__body'>
          {permissionOfAddForm?.update && (
            <div
              className='text-right'
              onClick={() =>
                history.push({
                  pathname: '/Super-Admin/Add_Admin',
                  state: {
                    editProfile: true,
                    rawDataList: rawData,
                  },
                })
              }
            >
              <span>Edit</span>
              <i className='bludedit ml-6'></i>
            </div>
          )}
          <div className='bd-profile__tbl'>
            <div className='bd-profile__detail--tbl'>
              <div className='bd-profile__detail--lft'>
                <div className='bd-profile__detail'>
                  <div
                    className='bd-profile__img'
                    style={{
                      backgroundImage: profileData.photo
                        ? `url(${profileData.photo})`
                        : `url(${genderWiseProfilePic(profileData.gender)})`,
                    }}
                  ></div>
                </div>
                <div className='bd-tbl bd-tbl__theme2'>
                  <div className='bd-tbl__body'>
                    {cells.map((cell) => {
                      return (
                        <div className='bd-tbl__row'>
                          <div className='bd-tbl__cell'>
                            <span>{cell.label}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{cell.value}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className='bd-profile__otp'>
              <div className='bd-popup__head'>
                <span>Permissions</span>
              </div>
              <div className='bd-tbl bd-tbl__theme2'>
                <div className='bd-tbl__body'>
                  {infoCells.map((cell) => {
                    return (
                      <div className='bd-tbl__row'>
                        <div className='bd-tbl__cell'>
                          <span>{cell.label}</span>
                        </div>
                        <div className='bd-tbl__cell'>
                          <span>{cell.value}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className='bd-btn__wrap mt-20'>
                {permissionOfAddForm?.update && (
                  <div>
                    {profileData.status === 1 && (
                      <div
                        className='bd-btn db-bg__blue '
                        onClick={() => ActionBtn('deactivate')}
                      >
                        <span>Inactive</span>
                      </div>
                    )}
                    {profileData.status === 2 && (
                      <div
                        className='bd-btn bd-request-accept '
                        onClick={() => ActionBtn('activate')}
                      >
                        <span>Active</span>
                      </div>
                    )}
                  </div>
                )}

                {permissionOfAddForm?.delete && (
                  <div
                    className='bd-btn bd-btn--primary'
                    onClick={() => ActionBtn('delete')}
                  >
                    <span>Delete</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
