import {
  //RECALL_LOGIN
  RECALL_LOGIN,
  RECALL_LOGIN_FAILED,
  RECALL_LOGIN_SUCCESS,

  //DASHBOARD MOADULE
  DASHBOARD_TILES_DATA,
  DASHBOARD_TILES_DATA_FAILED,
  DASHBOARD_TILES_DATA_SUCCESS,
  DASHBOARD_STATE_CITY_DATA,
  DASHBOARD_STATE_CITY_DATA_FAILED,
  DASHBOARD_STATE_CITY_DATA_SUCCESS,
  // SUBCRIPTION MODULE
  GET_SUBCRIPTION_AND_BLUDUNIT_LIST,
  GET_SUBCRIPTION_AND_BLUDUNIT_LIST_FAILED,
  GET_SUBCRIPTION_AND_BLUDUNIT_LIST_SUCCESS,
  GET_SUBCRIPTION_PLANS,
  GET_SUBCRIPTION_PLANS_FAILED,
  GET_SUBCRIPTION_PLANS_SUCCESS,
  POST_ADD_SUBACRIPTION_PLANS,
  POST_ADD_SUBACRIPTION_PLANS_SUCCESS,
  POST_ADD_SUBACRIPTION_PLANS_FAILED,
  // INSTITUTION MODULE
  GET_REGISTERED_AND_PENDING_DATA,
  GET_REGISTERED_AND_PENDING_DATA_SUCCESS,
  GET_REGISTERED_AND_PENDING_DATA_FAILED,
  GET_SINGLE_INSTITUTION_DETAIL,
  GET_SINGLE_INSTITUTION_DETAIL_SUCCESS,
  GET_SINGLE_INSTITUTION_DETAIL_FAILED,
  APPROVE_DECLINE_REQUEST,
  APPROVE_DECLINE_REQUEST_SUCCESS,
  APPROVE_DECLINE_REQUEST_FAILED,
  BLOCK_UNBLOCK_INSTITUTION,
  BLOCK_UNBLOCK_INSTITUTION_SUCCESS,
  BLOCK_UNBLOCK_INSTITUTION_FAILED,
  // PRICING
  GET_PRICING_LIST,
  GET_PRICING_LIST_SUCCESS,
  GET_PRICING_LIST_FAILED,
} from './actionTypes';
import { makeAPICall, urlSearchParams } from '../../utils';

import {
  API_BASE_PATH,
  RECALL_LOGIN_URL,
  GET_PRICING_LIST_URL,
  DASHBOARD_TILES_DATA_URL,
  DASHBOARD_STATE_CITY_DATA_URL,
  GET_SUBCRIPTION_LIST_URL,
  GET_BLUDUNIT_LIST_URL,
  GET_SUBCRIPTION_PLANS_LIST_URL,
  POST_ADD_SUBACRIPTION_PLANS_URL,
  GET_REGISTER_LIST_URL,
  GET_PENDING_LIST_URL,
  GET_REGISTER_DETAIL_URL,
  GET_PENDING_DETAIL_URL,
  APPROVE_DECLINE_REQUEST_URL,
  BLOCK_UNBLOCK_INSTITUTION_URL,
} from '../../utils/config';

export function triggeReCallLogin(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${RECALL_LOGIN_URL}`,
      dispatch,
      defaultAction: RECALL_LOGIN,
      successAction: RECALL_LOGIN_SUCCESS,
      failedAction: RECALL_LOGIN_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function triggerGetPricingList(callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_PRICING_LIST_URL}`,
      dispatch,
      defaultAction: GET_PRICING_LIST,
      successAction: GET_PRICING_LIST_SUCCESS,
      failedAction: GET_PRICING_LIST_FAILED,
      type: 'GET',
      headers: {},
      // params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function triggerGetTileData(callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DASHBOARD_TILES_DATA_URL}`,
      dispatch,
      defaultAction: DASHBOARD_TILES_DATA,
      successAction: DASHBOARD_TILES_DATA_SUCCESS,
      failedAction: DASHBOARD_TILES_DATA_FAILED,
      type: 'GET',
      headers: {},
      // params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerGetDashboardData(obj, callback) {
  return (dispatch) => {
    const params = {
      // url: `${API_BASE_PATH}${DASHBOARD_STATE_CITY_DATA_URL}?${urlSearchParams(
      //   obj
      // )}`,
      url: `${API_BASE_PATH}${DASHBOARD_STATE_CITY_DATA_URL}`,
      dispatch,
      defaultAction: DASHBOARD_STATE_CITY_DATA,
      successAction: DASHBOARD_STATE_CITY_DATA_SUCCESS,
      failedAction: DASHBOARD_STATE_CITY_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function triggerGetsubBludList(type, callback) {
  return (dispatch) => {
    // const params = {
    //   url: `${API_BASE_PATH}${
    //     GET_SUBCRIPTION_LIST_URL || GET_BLUDUNIT_LIST_URL
    //   }`,
    const params = {
      url: `${API_BASE_PATH}${GET_BLUDUNIT_LIST_URL}`,
      dispatch,
      defaultAction: GET_SUBCRIPTION_AND_BLUDUNIT_LIST,
      successAction: GET_SUBCRIPTION_AND_BLUDUNIT_LIST_SUCCESS,
      failedAction: GET_SUBCRIPTION_AND_BLUDUNIT_LIST_FAILED,
      type: 'GET',
      headers: {},
      // params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerGetSubcriptionPlansList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_SUBCRIPTION_PLANS_LIST_URL}`,
      dispatch,
      defaultAction: GET_SUBCRIPTION_PLANS,
      successAction: GET_SUBCRIPTION_PLANS_SUCCESS,
      failedAction: GET_SUBCRIPTION_PLANS_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerPostAddPlan(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_ADD_SUBACRIPTION_PLANS_URL}`,
      dispatch,
      defaultAction: POST_ADD_SUBACRIPTION_PLANS,
      successAction: POST_ADD_SUBACRIPTION_PLANS_SUCCESS,
      failedAction: POST_ADD_SUBACRIPTION_PLANS_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerApproveDecline(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${APPROVE_DECLINE_REQUEST_URL}`,
      dispatch,
      defaultAction: APPROVE_DECLINE_REQUEST,
      successAction: APPROVE_DECLINE_REQUEST_SUCCESS,
      failedAction: APPROVE_DECLINE_REQUEST_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerBlockUnblock(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${BLOCK_UNBLOCK_INSTITUTION_URL}`,
      dispatch,
      defaultAction: BLOCK_UNBLOCK_INSTITUTION,
      successAction: BLOCK_UNBLOCK_INSTITUTION_SUCCESS,
      failedAction: BLOCK_UNBLOCK_INSTITUTION_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerGetInstitutionData(type, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${
        type === 'Register' ? GET_REGISTER_LIST_URL : GET_PENDING_LIST_URL
      }`,
      dispatch,
      defaultAction: GET_REGISTERED_AND_PENDING_DATA,
      successAction: GET_REGISTERED_AND_PENDING_DATA_SUCCESS,
      failedAction: GET_REGISTERED_AND_PENDING_DATA_FAILED,
      type: 'GET',
      headers: {},
      // params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerGetInstitutionDetail(obj, type) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${
        type === 'Register' ? GET_REGISTER_DETAIL_URL : GET_PENDING_DETAIL_URL
      }?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_SINGLE_INSTITUTION_DETAIL,
      successAction: GET_SINGLE_INSTITUTION_DETAIL_SUCCESS,
      failedAction: GET_SINGLE_INSTITUTION_DETAIL_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      // callback,
    };
    makeAPICall(params);
  };
}
