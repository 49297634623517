import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from './Modal';
import InventoryTableList from '../../components/InventoryTableList';
import { INVENTORY_TRANSFER_COLUMNS } from '../../utils/constants';
import {
  disposeInventory,
  getPatientRequestData,
  transferProcessInventory,
} from '../../redux/actions/inventoryAction';
import { changeInventoryStatus } from '../../redux/actions/dashboardAction';
import { BLOOD_COMPONENTS, BLOOD_GROUP_LIST } from '../../utils/constants';
import {
  DISPOSE_INVENTORY_DATA,
  INVENTORY_CHANGE_STATUS,
  INVENTORY_TRANSFER_PROCESS,
} from '../../redux/actions/actionTypes';

function TransferView({ data, bloodComponentList }) {
  const dispatch = useDispatch();
  const { inventoryDisposeObj } = useSelector(
    (state) => state.inventoryReducer
  );
  const { inventoryChangeStatus } = useSelector(
    (state) => state.dashboardReducer
  );
  const { transferProcessData } = useSelector(
    (state) => state.inventoryReducer
  );
  const [selectedListIds, setSelectedIds] = useState([]);
  const [inventoryProps, setInventoryProps] = useState({
    bloodComponent: bloodComponentList[0],
    currBloodGroup: BLOOD_GROUP_LIST[0],
  });
  const [showInventory, setShowInventory] = useState(false);
  const [rawData, setRawData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setShowFail] = useState(false);
  const [error, setError] = useState('');
  // const [error, setError] = useState(null);

  function onActionClick(type) {
    switch (type) {
      case 'Dispose':
        dispatch(
          disposeInventory(
            {
              type: 'dispose',
              // inventoryIds: [],
              inventoryIds: selectedListIds,
            },
            setShowSuccess(true)
          )
        );
        break;
      case 'Transfer':
        setInventoryProps({
          ...inventoryProps,
          title: 'Transfer',
          button: 'Transfer',
          type: 'transfer',
        });
        setShowInventory(true);

        break;
      case 'Issue':
        setInventoryProps({
          ...inventoryProps,
          title: 'Issue to Patient',
          button: 'Issue',
          type: 'registered',
        });
        setShowInventory(true);

        break;
      default:
      // code block
    }
  }

  function onBtnClick(obj) {
    if (obj.type === 'registered') {
      let { id, unit, inventoryList, deliveryType } = obj;
      let issue = {
        type: 'issue',
        issueType: 'patient',
        id: id,
        status: 3,
        issuedUnits: unit,
        inventoryIds: inventoryList,
        deliveryType: deliveryType,
      };
      dispatch(
        changeInventoryStatus(issue, () => {
          setShowInventory(false);
          setShowSuccess(true);
        })
      );
    } else if (obj.type === 'transfer') {
      let { selectedUser, inventoryList, comment, deliveryType } = obj;
      let issue = {
        type: 'transfer',
        institutionId: selectedUser.id,
        inventoryIds: inventoryList,
        comments: comment,
        details: selectedUser.name,
        deliveryType: deliveryType,
      };
      dispatch(
        transferProcessInventory(issue, () => {
          setShowInventory(false);
          setShowSuccess(true);
        })
      );
    }
  }

  function modalInventoryList() {
    return data.filter((a) => {
      let flag = false;
      selectedListIds.forEach((selectedID) => {
        if (a._id === selectedID) {
          flag = true;
        }
      });
      return flag;
    });
  }

  function getComponent(activeBloodGroup, activeBloodComponent) {
    dispatch(getPatientRequestData({ bloodGroup: activeBloodGroup }));

    setInventoryProps({
      ...inventoryProps,
      currBloodGroup: activeBloodGroup || '',
      bloodComponent: activeBloodComponent || '',
    });
  }

  function onCancelClick() {
    let cloneData = [...data];
    cloneData.forEach((item) => {
      item.isSelected = false;
    });
    setSelectedIds([]);
    setRawData(cloneData);
  }
  const [btnType, setBtnType] = useState('');

  function onDisposeClick() {
    onActionClick('Dispose');
    setBtnType('btnDispose');
  }
  function onTransferClick() {
    onActionClick('Transfer');
    setBtnType('btnTransfer');
  }
  function onIssueClick() {
    onActionClick('Issue');
    setBtnType('btnIssue');
  }

  function showErrorPopUp() {
    inventoryChangeStatus.loading ||
    transferProcessData.loading ||
    inventoryDisposeObj.loading
      ? setLoading(true)
      : setLoading(false);

    if (
      inventoryChangeStatus.error !== null ||
      transferProcessData.error !== null ||
      inventoryDisposeObj.error
    ) {
      setError(
        inventoryChangeStatus.error ||
          transferProcessData.error ||
          inventoryDisposeObj.error
      );
      setShowFail(true);
    }
  }
  function hideErrorPopUp() {
    setShowFail(false);
    // dispatch({ type: DISPOSE_INVENTORY_DATA, loading: false });
    dispatch({ type: INVENTORY_TRANSFER_PROCESS, loading: false });
    dispatch({ type: INVENTORY_CHANGE_STATUS, loading: false });
  }
  function escFunction(e) {
    if (e.keyCode === 27) {
      setShowInventory(false);
    }
  }

  useEffect(() => {
    dispatch(getPatientRequestData({ bloodGroup: BLOOD_GROUP_LIST[0] }));
    document.addEventListener('keydown', escFunction, false);
  }, []);

  useEffect(() => {
    showErrorPopUp();
  }, [inventoryChangeStatus, transferProcessData]);

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          {bloodComponentList.length ? (
            <InventoryTableList
              data={rawData}
              title={'Transfer'}
              columns={INVENTORY_TRANSFER_COLUMNS}
              selectedListIds={selectedListIds}
              setSelectedIds={setSelectedIds}
              showSuccess={showSuccess}
              setShowSuccess={setShowSuccess}
              hideErrorPopUp={hideErrorPopUp}
              showFail={showFail}
              // setShowFail={setShowFail}
              error={error}
              // setError={setError}
              loading={loading}
              getComponent={getComponent}
              btnType={btnType}
              bloodComponentList={bloodComponentList}
              setError={setError}
            />
          ) : (
            ''
          )}
          {selectedListIds.length ? (
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline ' onClick={onCancelClick}>
                <span>Cancel</span>
              </div>
              <div className='bd-btn bd-btn--primary' onClick={onDisposeClick}>
                <span>Dispose</span>
              </div>
              <div
                className='bd-btn bd-btn--primary db-bg__blue'
                onClick={onTransferClick}
              >
                <span>Transfer</span>
              </div>
              <div
                className='bd-btn bd-btn--primary db-bg__green'
                onClick={onIssueClick}
              >
                <span>Issue</span>
              </div>
            </div>
          ) : (
            ''
          )}

          {showInventory && (
            <Modal
              {...inventoryProps}
              onBtnClick={onBtnClick}
              data={{
                inventory: modalInventoryList(),
              }}
              setShowInventory={setShowInventory}
              // setShowSuccess={setShowSuccess}
            />
          )}
        </section>
      </article>
    </>
  );
}

export default TransferView;
