import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../components';
import { setIssuedDataList } from '../../utils';

import IssuedView from '../../views/InventoryView/IssuedView';
import { getReceiptData } from '../../redux/actions/inventoryAction';
import { triggerBloodComponentList } from '../../redux/actions/commonAction';
import { getDashboardTilesData } from '../../redux/actions/dashboardAction';

function IssuedContainer() {
  const { dashboarTilesCount } = useSelector((state) => state.dashboardReducer);

  const [bloodComponentList, setBloodComponentsList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReceiptData());
    dispatch(getDashboardTilesData()); // Dashboard Tiles Issued Data List
    dispatch(triggerBloodComponentList(setBloodComponents)); //boolComponent
  }, []);

  const { loading } = dashboarTilesCount;
  const issuedList =
    dashboarTilesCount?.data?.content?.dashboardData?.issuedBags;

  function setBloodComponents(response) {
    //boolComponent
    if (response.content.data) setBloodComponentsList(response.content.data);
  }

  function fetchList() {
    return setIssuedDataList(issuedList);
  }

  useEffect(() => {}, [bloodComponentList, issuedList]);

  return (
    <>
      {loading &&
      issuedList?.length <= 0 &&
      bloodComponentList?.length <= 0 &&
      bloodComponentList ? (
        <Loader />
      ) : (
        <IssuedView
          data={fetchList()}
          bloodComponentList={bloodComponentList}
          loading={loading}
        />
      )}
    </>
  );
}

export default IssuedContainer;
