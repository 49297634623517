import React, { useState } from 'react';
import Chart from 'react-google-charts';

const geoData = [
  ['State Code', 'State', 'Temperature'],
  ['IN-UP', 'Uttar Pradesh', null],
  ['IN-MH', 'Maharashtra', 32],
  ['IN-BR', 'Bihar', 31],
  ['IN-WB', 'West Bengal', null],
  ['IN-MP', 'Madhya Pradesh', 30],
  ['IN-TN', 'Tamil Nadu', 33],
  ['IN-RJ', 'Rajasthan', null],
  ['IN-KA', 'Karnataka', null],
  ['IN-GJ', 'Gujarat', 34],
  ['IN-AP', 'Andhra Pradesh', 32],
  ['IN-OR', 'Orissa', null],
  ['IN-TG', 'Telangana', 33],
  ['IN-KL', 'Kerala', null],
  ['IN-JH', 'Jharkhand', 29],
  ['IN-AS', 'Assam', 28],
  ['IN-PB', 'Punjab', 30],
  ['IN-CT', 'Chhattisgarh', 33],
  ['IN-HR', 'Haryana', null],
  ['IN-JK', 'Jammu and Kashmir', 20],
  ['IN-UT', 'Uttarakhand', 28],
  ['IN-HP', 'Himachal Pradesh', 17],
  ['IN-TR', 'Tripura', null],
  ['IN-ML', 'Meghalaya', 21],
  ['IN-MN', 'Manipur', null],
  ['IN-NL', 'Nagaland', 22],
  ['IN-GA', 'Goa', 32],
  ['IN-AR', 'Arunachal Pradesh', 33],
  ['IN-MZ', 'Mizoram', 23],
  ['IN-SK', 'Sikkim', 24],
  ['IN-DL', 'Delhi', null],
  ['IN-PY', 'Puducherry', 33],
  ['IN-CH', 'Chandigarh', 30],
  ['IN-AN', 'Andaman and Nicobar Islands', 30],
  ['IN-DN', 'Dadra and Nagar Haveli', null],
  ['IN-DD', 'Daman and Diu', 29],
  ['IN-LD', 'Lakshadweep', null],
];

export const options = {
  width: '750px',
  height: '600px',
  region: 'IN',
  domain: 'IN',
  displayMode: 'regions',
  resolution: 'provinces',
  colorAxis: {
    minValue: 0,
    colors: ['#7CCFEA', '#EAE87C', '#af0c0c'],
  },
  // backgroundColor: '#81d4fa',
  datalessRegionColor: '#81d4fa',
  defaultColor: '#EBF4FA',
};

function IndiaMapView({ stateArr }) {
  var showMap = false;
  let mapStateData = stateArr ? stateArr : [];
  for (let ele of mapStateData) {
    if (ele[2] !== 'Donations') {
      if (ele[2] !== null) {
        showMap = true;
      }
    }
  }

  return (
    <>
      {!showMap ? (
        <div className='bd-clr-red mb-10 text-center'>
          Donation Data Not Available
        </div>
      ) : (
        <div className='container mt-5'>
          {/* <h2>React Gauge Chart Example</h2> */}
          <Chart
            chartType='GeoChart'
            data={mapStateData}
            // data={stateArr}
            options={options}
            // Note: you will need to get a mapsApiKey for your project.
            // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
            mapsApiKey='YOUR_MAP_API_KEY_GOES_HERE'
            rootProps={{ 'data-testid': '1' }}
          />
        </div>
      )}
    </>
  );
}

export default IndiaMapView;
