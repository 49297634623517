import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCampsDetail } from '../../redux/actions';
import CampsDonorListView from '../../views/CampsView/CampsDonorList';

export default function CampsDonorList(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const [showAddCampForm, setShowAddCampForm] = useState(false);
  const { campDetails } = useSelector((state) => state.campsReducer);
  const addCampObj = {
    campName: '',
    sponser: '',
    date: '',
    to: '',
    time: '',
    place: '',
    address: {
      description: '',
    },
    noOfBeds: '',
    location: '',
    coordinator: '',
    attachments: [],
    targetUnits: '',
    id: '',
    fromTime: '',
    toTime: '',
  };

  useEffect(() => {
    dispatch(getCampsDetail({ campId: params.id }));
  }, [params.id]);

  function CloseAddCampPopup() {
    setShowAddCampForm();
  }

  function onEditClick(obj) {
    setShowAddCampForm(true);
  }

  function CloseAddCampPopup() {
    setShowAddCampForm();
  }
  return (
    <div>
      <CampsDonorListView
        onClick={onEditClick}
        toggleForm={CloseAddCampPopup}
        showAddCampForm={showAddCampForm}
        addCamps={addCampObj}
        campsDetails={campDetails}
      />
    </div>
  );
}
