import React from 'react';
import close from '../../assets/images/close.png';
import { RadioButton, CheckBox } from '../../components';

function CampDonorsForm_two_View({
  donorInfo,
  onCloseClick,
  errorFields,
  ErrorMsg,
  selectedCheckbox,
  onCheckboxChange,
  onRadioChange,
  onInputChange,
  onApproveClick,
  // campDonorsListObj,
}) {
  return (
    <div className='bd-modal-wrap bd-modal-right bd-CampDonorForm'>
      <div className='bd-popup si-fade-in'>
        <div style={{ paddingLeft: '10px' }}>
          <form onSubmit={onApproveClick}>
            <div className='bd-popup__head'>
              <span>Donor Data</span>
              <img
                src={close}
                height='25px'
                className='cursor-pointer'
                onClick={onCloseClick}
                alt='close'
              />
            </div>
            <div className='bd-popup__body'>
              <div className='bd-form'>
                <div className='bd-dbd__form'>
                  <section className='bd-sec__1'>
                    <div
                      className='bd-sec__1--1'
                      style={{ color: '#222326', fontSize: '18px' }}
                    >
                      <span>
                        {donorInfo?.firstName} {donorInfo?.lastName}
                      </span>
                      <span>{donorInfo?.id} </span>
                      <span>{donorInfo?.gender} </span>

                      <span>
                        <span></span>
                        {donorInfo?.age} <b>Yrs</b>
                      </span>
                    </div>
                  </section>
                  <div className='bd-sec__2--2 mTB'>
                    <div className='d-flex-center'>
                      <span>Height</span>
                      <input
                        type='number'
                        placeholder=''
                        value={donorInfo?.height}
                        name='height'
                        disabled
                      />
                      <span>cm</span>
                    </div>
                    <div className='d-flex-center'>
                      <span>Weight</span>
                      <input
                        type='number'
                        placeholder=''
                        value={donorInfo?.weight}
                        name='weight_1'
                        disabled
                      />
                      <span>kg</span>
                    </div>
                  </div>
                  <div className='bd-sec__2--2 mTB'>
                    <div className='d-flex-center'>
                      <span>Hb</span>
                      <input
                        type='number'
                        placeholder=''
                        value={donorInfo?.hb}
                        name='hb'
                        disabled
                      />
                      <span>g/dL</span>
                    </div>
                    <div className='d-flex-center'>
                      <span>Blood Pressure</span>
                      <input
                        className='fs-15'
                        type='text'
                        placeholder=''
                        value={donorInfo?.bp}
                        name='bp'
                        disabled
                      />
                      <span>mmHg</span>
                    </div>
                  </div>
                  <div className='bd-sec__2--2 mTB'>
                    <div className='d-flex-center'>
                      <span>Kit Number</span>
                      <input
                        className='fs-15'
                        type='text'
                        placolder=''
                        value={donorInfo?.kitNumber}
                        name='kitNumber'
                        disabled
                      />
                    </div>
                    <div className='d-flex-center'>
                      <span>Bag / Unit ID</span>
                      <input
                        className='fs-15'
                        type='text'
                        placolder=''
                        value={donorInfo?.bagId}
                        name='bagId'
                        disabled
                      />
                    </div>
                  </div>
                  <div className='bd-sec__2--2 mTB'>
                    <div className='d-flex-center'>
                      <span>Bag Type</span>
                      <input
                        className='fs-15'
                        type='text'
                        placolder=''
                        value={donorInfo?.bagType}
                        name='bagType'
                        disabled
                      />
                    </div>
                  </div>

                  <section className='bd-sec__4'>
                    <div className='bd-sec__4--2'>
                      <div className='bd-sec__4--head'>
                        <span>Samples</span>
                      </div>
                      <div className='d-flex-center'>
                        <span># 1 ID</span>
                        <input
                          className='fs-15'
                          type='text'
                          placeholder=''
                          value={donorInfo?.sampleId_1}
                          name='sampleId_1'
                          disabled
                        />
                      </div>
                      <div className='d-flex-center'>
                        <span># 2 ID</span>
                        <input
                          className='fs-15'
                          type='text'
                          placeholder=''
                          value={donorInfo?.sampleId_2}
                          name='sampleId_2'
                          disabled
                        />
                      </div>
                      <div className='d-flex-center'>
                        <span># 3 ID</span>
                        <input
                          className='fs-15'
                          type='text'
                          placeholder=''
                          value={donorInfo?.sampleId_3}
                          name='sampleId_3'
                          disabled
                        />
                      </div>
                    </div>
                  </section>

                  <section className='bd-sec__6'>
                    <div className='bd-sec__6--head'>
                      <span>Blood Donation</span>
                    </div>
                    <div className='bd-sec__6--2'>
                      <div className='d-flex-center'>
                        <span>Start Time</span>
                        <input
                          className={
                            errorFields['startTime'] ? 'form-error' : ''
                          }
                          type='number'
                          placeholder=''
                          // value={donorInfo.startTime}
                          name='startTime'
                          onChange={(e) => onInputChange(e)}
                        />
                        <span>Hrs</span>
                      </div>
                      <div className='d-flex-center'>
                        <span>End Time</span>
                        <input
                          className={errorFields['endTime'] ? 'form-error' : ''}
                          type='number'
                          placeholder=''
                          // value={donorInfo.endTime}
                          name='endTime'
                          onChange={(e) => onInputChange(e)}
                        />
                        <span>Hrs</span>
                      </div>
                    </div>
                    <div className='bd-sec__6--2'>
                      <div className='d-flex-center'>
                        <span>Volume</span>
                        <input
                          className={errorFields['volume'] ? 'form-error' : ''}
                          type='number'
                          placeholder=''
                          // value={donorInfo.volume}
                          name='volume'
                          onChange={(e) => onInputChange(e)}
                        />
                        <span>ml</span>
                      </div>
                      <div className='d-flex-center'>
                        <span>Weight</span>
                        <input
                          className={
                            errorFields['weight_2'] ? 'form-error' : ''
                          }
                          type='number'
                          placeholder=''
                          // value={donorInfo.weight_2}
                          name='weight_2'
                          onChange={(e) => onInputChange(e)}
                        />
                        <span>g</span>
                      </div>
                    </div>
                  </section>
                  <section className='bd-sec__7'>
                    <div className='bd-sec__7--2'>
                      {/* <div className=' d-flex-center'> */}
                      <div className='bd-sec__7--2-1'>
                        <div className='d-flex-center'>
                          <span>Blood Bag Stored at</span>
                          <input
                            className={
                              errorFields['storedIn']
                                ? 'form-error fs-15'
                                : 'fs-15'
                            }
                            type='text'
                            placeholder=''
                            // value={donorInfo?.storedIn}
                            name='storedIn'
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        <div className='bd-at'>
                          <span>at</span>
                        </div>
                      </div>
                      <div className=' d-flex-center'>
                        <div className='bd-sec__7--2-2'>
                          <input
                            className={
                              errorFields['temperature']
                                ? 'form-error fs-15'
                                : 'fs-15'
                            }
                            type='number'
                            placeholder=''
                            // value={donorInfo?.temperature}
                            name='temperature'
                            onChange={(e) => onInputChange(e)}
                          />
                          <span>&#8451;</span>
                        </div>
                      </div>
                    </div>
                    <div className='bd-sec__7--3  d-flex-center '>
                      <span>Data posted by</span>
                      <input
                        className={
                          errorFields['dataPostBy']
                            ? 'form-error fs-15'
                            : 'fs-15'
                        }
                        type='text'
                        placeholder='Your Name'
                        // value={donorInfo.dataPostBy}
                        name='dataPostBy'
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </section>

                  <section className='bd-sec__10'>
                    <ul>
                      <li>
                        <CheckBox
                          keyindex={'checkbox_1'}
                          id={'checkbox_1'}
                          checked={selectedCheckbox['checkbox_1']}
                          onChange={onCheckboxChange}
                        />
                        Have explained about the protocols & precautions for
                        donation
                      </li>
                      <li>
                        <CheckBox
                          keyindex={'checkbox_2'}
                          id={'checkbox_2'}
                          checked={selectedCheckbox['checkbox_2']}
                          onChange={onCheckboxChange}
                        />
                        Pre-donation health check questionnaire filled and found
                        ok
                      </li>
                    </ul>
                  </section>

                  <section className='bd-sec__11'>
                    <ul>
                      <li>
                        <label>
                          Was the donor comfortable during the process?
                        </label>
                        <div className='bd-radio__wrap'>
                          <div>
                            <RadioButton
                              label='Yes'
                              name='radio1'
                              value='yes'
                              onRadioChange={onRadioChange}
                            />
                          </div>
                          <div>
                            <RadioButton
                              label='No'
                              name='radio1'
                              value='no'
                              onRadioChange={onRadioChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <label>
                          Was there any clotting issues during / after the
                          process?
                        </label>
                        <div className='bd-radio__wrap'>
                          <div>
                            <RadioButton
                              label='Yes'
                              name='radio2'
                              value='yes'
                              onRadioChange={onRadioChange}
                            />
                          </div>
                          <div>
                            <RadioButton
                              label='No'
                              name='radio2'
                              value='no'
                              onRadioChange={onRadioChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <label>
                          Did the donor face any issue post donation - recovery?
                        </label>
                        <div className='bd-radio__wrap'>
                          <div>
                            <RadioButton
                              label='Yes'
                              name='radio3'
                              value='yes'
                              onRadioChange={onRadioChange}
                            />
                          </div>
                          <div>
                            <RadioButton
                              label='No'
                              name='radio3'
                              value='no'
                              onRadioChange={onRadioChange}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </section>

                  <section className='bd-sec__12'>
                    <div className='text-center clr-blud fs-15 mb-10'>
                      {ErrorMsg}
                    </div>
                    <div className='bd-sec__12--2 '>
                      <div className='bd-btn__wrap'>
                        <div
                          className='bd-btn bd-btn--outline'
                          onClick={onCloseClick}
                        >
                          <span>Cancel</span>
                        </div>
                        <button
                          type='submit'
                          className='bd-btn bd-btn--primary'
                          // disabled={false}
                          // onClick={onApproveClick}
                        >
                          Approve
                        </button>
                      </div>
                    </div>
                  </section>

                  {/* below form tag end */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}

export default CampDonorsForm_two_View;
