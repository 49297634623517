import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DonorView from './DonorView';
import RequestList from './RequestList';
import RecepientList from './RecepientList';
import { formatDate } from '../../utils';
import { BLOOD_GROUP_OPTIONS } from '../../utils/constants';
import SettingInventoryView from '../SettingsInventoryView/index';
import DropDown from '../../components/RDropDown';

function PeopleView({ peopleDonorObj, peopleRecepientObj }) {
  const { loginObj } = useSelector((state) => state.loginReducer);

  let institutionType = loginObj?.data?.content?.data?.institutionType;

  const [activeTab, setActiveTab] = useState('Donors');

  const [search, setSearch] = useState('');

  const [Recepientsearch, setRecepientSearch] = useState('');

  const [requestedSearch, setRequestedSearch] = useState('');

  const [bloodGroupDropDown, setBloodGroupDropDown] = useState('');

  const [finalSortedData, setFinalSortedData] = useState([]);

  const [headerDropDown, setHeaderDropDown] = useState('');

  const [dataList, setDataList] = useState(); // bloodGroup DropDown

  const [showXlUplodings, setShowXlUplodings] = useState(false);

  function onSelectChange(e) {
    setBloodGroupDropDown(e.target.value);
  }

  function onTabChange(e) {
    setActiveTab(e.target.id);
    setHeaderDropDown('');
    setSearch('');
    setRecepientSearch('');
    setRequestedSearch('');
  }

  function headerDropDownChange(e) {
    setHeaderDropDown(e.value);
  }

  function serachData(list, filter) {
    return list.filter(
      (values) =>
        values.name.toLowerCase().includes(filter.toLowerCase()) ||
        values.donorId.toLowerCase().includes(filter.toLowerCase()) ||
        values.email.toLowerCase().includes(filter.toLowerCase()) ||
        values.status.toLowerCase().includes(filter.toLowerCase()) ||
        String(values.bloodGroup)
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        String(values.lastDonationComponent)
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        String(values.lastDonationDate)
          .toLocaleLowerCase()
          .includes(filter.toLowerCase()) ||
        String(values.mobileNo).includes(filter)
    );
  }

  // function sortData(filterList) {
  //   return bloodGroupDropDown
  //     ? filterList.filter((a) => a.bloodGroup === bloodGroupDropDown)
  //     : filterList;
  // }

  function formatDonorObj(peopleDonorObj) {
    let data = [];
    peopleDonorObj.data?.forEach((record, i) => {
      let obj = {
        userType: record?.userType || 'user',
        donorId: record?._id ? record?._id : '--',
        name: record?.firstName + ' ' + record?.lastName,
        bloodGroup: record?.bloodGroup ? record?.bloodGroup : '--',
        mobileNo: record?.mobileNumber ? record?.mobileNumber : '--',
        email: record?.email ? record?.email : '--',
        lastDonationDate: record?.lastDonationDate
          ? formatDate(record?.lastDonationDate)
          : '--',
        lastDonationComponent: record?.lastDonationComponent
          ? record?.lastDonationComponent
          : '--',
        status: record?.isBlocked == false ? 'Active' : 'Blocked',
      };
      data.push(obj);
    });
    return data;
  }

  useEffect(() => {
    let list = [...formatDonorObj(peopleDonorObj)];
    // let filterList = search === "" ? list : serachData(list, search);
    setFinalSortedData(list);
  }, [bloodGroupDropDown, peopleDonorObj]);

  var donorBtn = true;

  return (
    <>
      {showXlUplodings ? (
        <SettingInventoryView
          donorBtn={donorBtn}
          setShowXlUplodings={setShowXlUplodings}
        />
      ) : (
        <article className='bd-article'>
          <section className='bd-dashboard'>
            <div className='bd-dashboard__container'>
              <div className='bd-dashboard__lft'>
                <div className='bd-dashboard__header bd-header__search'>
                  <span className='bd-dashboard__main'>People</span>
                  {/* <div className="bd-tab width"> */}
                  <div className='bd-tab bd-tab-new width'>
                    {institutionType === 'Both' ? (
                      <>
                        <div
                          id='Donors'
                          className={activeTab === 'Donors' && 'active'}
                          onClick={onTabChange}
                          activetab={activeTab}
                        >
                          <span id='Donors' style={{ padding: '0 10px' }}>
                            Donors
                          </span>
                        </div>
                        <div
                          id='Recepient'
                          className={activeTab === 'Recepient' && 'active'}
                          onClick={onTabChange}
                          activetab={activeTab}
                        >
                          <span id='Recepient' style={{ padding: '0 10px' }}>
                            Recepient
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          id='Donors'
                          className={activeTab === 'Donors' && 'active'}
                          onClick={onTabChange}
                          activetab={activeTab}
                        >
                          <span
                            id='Donors'
                            style={{ padding: '0 8px !important' }}
                          >
                            Donors
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className='bd-hader'>
                    {/* DropDown */}
                    <div className='bd-dorpdown mr-10'>
                      <div className='bd-dorpdown__selected detail-dd bg-dd'>
                        <DropDown
                          placeHolderText={'Blood Group'}
                          options={BLOOD_GROUP_OPTIONS}
                          fetchDropDownValue={headerDropDownChange}
                        />
                        <i className='bludbig-arrow' />
                      </div>
                    </div>

                    {activeTab === 'Donors' && (
                      <input
                        type='text'
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        placeholder='Search'
                        style={{ padding: 5, marginRight: 10 }}
                      />
                    )}
                    {activeTab === 'Recepient' && (
                      <input
                        type='text'
                        value={Recepientsearch}
                        onChange={(e) => {
                          setRecepientSearch(e.target.value);
                        }}
                        placeholder='Search'
                        style={{ padding: 5, marginRight: 10 }}
                      />
                    )}
                    {activeTab === 'Requested_for' && (
                      <input
                        type='text'
                        value={requestedSearch}
                        onChange={(e) => {
                          setRequestedSearch(e.target.value);
                        }}
                        placeholder='Search'
                        style={{ padding: 5, marginRight: 10 }}
                      />
                    )}
                    <div
                      className='bd-file-upload'
                      onClick={() => setShowXlUplodings(true)}
                    >
                      <button className='si-btn bd-btn bd-btn--primary'>
                        Upload Donors
                      </button>
                    </div>
                  </div>
                </div>
                {institutionType === 'Both' ? (
                  activeTab === 'Donors' ? (
                    <DonorView
                      headerDropDown={headerDropDown}
                      peopleDonorObj={finalSortedData}
                      bloodGroupDropDown={bloodGroupDropDown}
                      search={search}
                      onSelectChange={onSelectChange}
                      setDataList={setDataList}
                      serachData={serachData}
                    />
                  ) : activeTab === 'Recepient' ? (
                    <RecepientList
                      headerDropDown={headerDropDown}
                      peopleRecepientList={peopleRecepientObj}
                      Recepientsearch={Recepientsearch}
                      setDataList={setDataList}
                    />
                  ) : activeTab === 'Requested_for' ? (
                    <RequestList
                      headerDropDown={headerDropDown}
                      PeopleRequestedForList={peopleRecepientObj}
                      RequestedSearch={requestedSearch}
                      setDataList={setDataList}
                    />
                  ) : null
                ) : activeTab === 'Donors' ? (
                  <DonorView
                    headerDropDown={headerDropDown}
                    peopleDonorObj={finalSortedData}
                    bloodGroupDropDown={bloodGroupDropDown}
                    search={search}
                    onSelectChange={onSelectChange}
                    setDataList={setDataList}
                    serachData={serachData}
                  />
                ) : activeTab === 'Recepient' ? (
                  <RecepientList
                    headerDropDown={headerDropDown}
                    peopleRecepientList={peopleRecepientObj}
                    Recepientsearch={Recepientsearch}
                    setDataList={setDataList}
                  />
                ) : null}
              </div>
            </div>
          </section>
        </article>
      )}
    </>
  );
}

export default PeopleView;
