import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Loader, SuccessPopup, FailPopup } from '../../components';
import BlockUnblockInstituttionPopup from '../../components/Popups/BlockUnblockInstituttionPopup';
import {
  triggerGetInstitutionDetail,
  triggerBlockUnblock,
} from '../../redux/actions/superAdminCommonActions';
import InstitutionDetailPageView from '../../views/SuperAdminInstituionsView.js/InstitutionDetailPageView';
import { API_BASE_PATH, UPLOAD_FILE_URL } from '../../utils/config';
import axios from 'axios';
// import { Loader, SuccessPopup, FailPopup } from '../../components';

function InstitutionDetailPageContainer() {
  const dispatch = useDispatch();
  let location = useLocation();

  const { getSingleDetails } = useSelector(
    (state) => state.superAdminCommonReducers
  );

  const [activeTab, setActiveTab] = useState('Institution');
  const [sucessPopup, setShowSuccessPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [failPopup, setShowFailPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);
  const [CommentValue, setCommentValue] = useState('');
  const [Comment, setComment] = useState('');
  const [attachments, setAttachments] = useState([]);

  const addFile = async (e) => {
    let selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    let response = await axios.post(API_BASE_PATH + UPLOAD_FILE_URL, formData, {
      headers: {
        token: `${localStorage.getItem('TOKEN_KEY')}`,
      },
    });
    let file = response.data.content.savedFiles[0];
    let arr = [...attachments];
    arr.push(file);
    setAttachments(arr);
  };

  function onTabChange(e) {
    setActiveTab(e.target.id);
  }

  function escFunction(e) {
    if (e.keyCode === 27) {
      hidePopup();
      setShowPopUp(false);
    }
  }

  useEffect(() => {
    dispatch(
      triggerGetInstitutionDetail(
        {
          id: localStorage.getItem('ID'),
        },
        'Register'
      )
    );
    document.addEventListener('keydown', escFunction, false);
  }, [location.InstitutionID]);

  function blockUnbockAction(btnType) {
    let param = {
      institutionId: getSingleDetails?.data?.content?.data?._id,
      institutionName: getSingleDetails?.data?.content?.data?.institutionName,
      comment: CommentValue,
      attachment: attachments,
      state: btnType,
    };
    if (CommentValue === '') {
      setComment('comment is required');
    } else {
      // API call sholud be here
      console.log(param);
      dispatch(
        triggerBlockUnblock(param, () => {
          callBack(param.state);
        })
      );
    }
  }

  function callBack(type) {
    setShowPopUp(false);
    setShowSuccessPopup(true);
    setSuccessMsg(
      type === 'Blocked' ? 'Institution Blocked' : 'Institution Unlocked'
    );
  }

  function hidePopup() {
    // Window.reload();
    dispatch(
      triggerGetInstitutionDetail(
        {
          id: localStorage.getItem('ID'),
        },
        'Register'
      )
    );
    setShowSuccessPopup(false);
    setShowFailPopup(false);
  }

  return (
    <>
      {getSingleDetails.loading ? (
        <Loader />
      ) : (
        <InstitutionDetailPageView
          institutionInfo={getSingleDetails?.data?.content?.data}
          activeTab={activeTab}
          onTabChange={onTabChange}
          setShowPopUp={setShowPopUp}
        />
      )}
      {showPopUp && (
        <BlockUnblockInstituttionPopup
          addFile={addFile}
          setShowPopUp={setShowPopUp}
          info={getSingleDetails?.data?.content?.data}
          attachments={attachments}
          blockUnbockAction={blockUnbockAction}
          setCommentValue={setCommentValue}
          Comment={Comment}
        />
      )}
      {sucessPopup && (
        <SuccessPopup title={successMsg} onContinueClick={() => hidePopup()} />
      )}
      {failPopup && (
        <FailPopup title={errorMsg} onContinueClick={() => hidePopup()} />
      )}
    </>
  );
}

export default InstitutionDetailPageContainer;
