import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BLOOD_COMPONENTS } from "../../utils/constants";
import InventoryTab from "../../views/DashboardView/InventoryTab";

export default function InventoryTabContainer() {
  const { inventoryList } = useSelector((state) => state.dashboardReducer);
  const [inventoryTableData, setInventoryTableData] = useState([]);

  useEffect(() => {
    let data = [];
    let components = [];
    if (inventoryList.data) {
      inventoryList.data.forEach((element) => {
        for (let i in element) {
          if (i !== "_id" && i !== "inventory") {
            if (!components.includes(i)) {
              components.push(i);
              data.push({ components: BLOOD_COMPONENTS[i] || i, componentKey: i });
            }
          }
        }
      });
      inventoryList.data.forEach((element) => {
        for (let i in element) {
          if (i !== "inventory") {
            data.map((obj) => {
              if (obj.componentKey === i) {
                obj[element._id.toLowerCase()] = element[i];
              }
            });
          }
        }
      });
    }
    setInventoryTableData(data);
  }, [inventoryList]);

  return <InventoryTab inventoryTableData={inventoryTableData} />;
};
