import React from "react";

function ChatView({
  myUserId,
  userInfo,
  chatMessages,
  messagesEndRef,
  chatList,
  previousMsgDay,
  message,
  resendMsg,
  setMessage,
  keyDown,
  getChatDetail,
  formatDate,
  imageSelected
}) {
  return (
    <article className="bd-article">
      <section className="bd-chat">
        <div className="bd-chat__container">
          <div className="bd-chat__side">
            <div className="bd-chat__head">
              <span>Chat</span>
            </div>
            <div className="bd-side__container">
              {chatList?.data?.chats.map((contact, index) => {
                if (contact._id !== myUserId) {
                  return (
                    <div
                      className={`bd-side__row`}
                      key={index}
                      onClick={(e) => getChatDetail(e, contact)}
                    >
                      <div className="bd-side__row--cont">
                        <span
                          className="bd-user__img"
                          style={{
                            backgroundImage: `url(${contact.photo})`,
                          }}
                        ></span>
                        <div>
                          <span className="bd-user__name">{`${contact.firstName} ${contact.lastName}`}</span>
                          <span className="bd-user__short">
                            {contact.lastMsg}
                          </span>
                        </div>
                      </div>
                      <div className="bd-side__row--rgt">
                        <span className="bd-user__tm">
                          {contact?.lastMsgTime ? formatDate(contact.lastMsgTime) : ''}
                        </span>
                        {contact.unreadCount && (
                          <span className="bd-user__total">
                            {contact.unreadCount}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                } else {
                  <div>asdfghjkl;poiuytrewzxcvbnm</div>

                  return;
                }
              })}
            </div>
          </div>
          {userInfo ? userInfo && (
            <div className="bd-chat__main">
              <div className="bd-user__head">
                <div className="bd-user__head--lft">
                  <span
                    className="bd-user__img"
                    style={{ backgroundImage: `url(${userInfo.photo})` }}
                  ></span>
                  <span className="bd-user__name">{userInfo.firstName}</span>
                </div>
                <div className="bd-user__head--rgt">
                  <i className="bludpin"></i>
                </div>
              </div>
              <div className="bd-user__chat">
                <div className="bd-user__chat--container">
                  {/* <div className="bd-user__status"><span>Unread</span></div> */}
                  {chatMessages.map((message, index) => {
                    let day = formatDate(message.addedAt, true),
                      isShowDay = false;
                    if (previousMsgDay === "" || previousMsgDay !== day) {
                      isShowDay = true;
                      previousMsgDay = day;
                    }
                    return (
                      <>
                        {isShowDay && (
                          <div className="bd-user__day">
                            <span>{day}</span>
                          </div>
                        )}
                        <div
                          className={`bd-user__conversation ${
                            message.sender._id === myUserId
                              ? `bd-user__me`
                              : `bd-user__inverse`
                          } ${message.notSent ? `bd-disabled` : ``}`}
                          key={index}
                        >
                          {message.chatType === "Message" && (
                            <span className="bd__conversation">
                              {message.message}
                            </span>
                          )}
                          {message.chatType === "Attachment" &&
                            message.attachment.map((attachment, key) => {
                              return (
                                <a href={attachment.location} target="_blank">
                                  <span
                                    className="bd__conversation attachment"
                                    key={key}
                                  >
                                    {attachment.name}
                                  </span>
                                </a>
                              );
                            })}
                          <span className="bd-user__time">
                            {formatDate(message.addedAt)}
                          </span>
                          {message?.notSent && (
                            <i
                              className="bludrefresh"
                              onClick={() => resendMsg(message)}
                            ></i>
                          )}
                        </div>
                      </>
                    );
                  })}
                  {/* <div className="bd-user__conversation bd-user__me bd-disabled">
                          <span className="bd__conversation">Thank You</span>
                          <span className="bd-user__time">19:00</span>
                          <i className="bludrefresh"></i>
                      </div> */}
                  <div ref={messagesEndRef} />
                </div>
                <div className="bd-user__chat--type">
                  <input
                    type="text"
                    name=""
                    placeholder="Type a message"
                    value={message}
                    onChange={({ target }) => setMessage(target.value)}
                    onKeyDown={keyDown}
                  />
                  <label>
                    <i className="bludpin" htmlFor="attachment"></i>
                    <input
                      type="file"
                      className="file-select"
                      id="attachment"
                      name="attachment"
                      accept="image/*,.pdf,.txt"
                      onChange={imageSelected}
                      style={{ visibility: "none" }}
                    />
                  </label>
                </div>
              </div>
            </div>
          ) : <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "60vw" }}>
           {/* <div className="align-center-no-data"> */}
                <div className="bd-tbl__body">
                <div className="bd-noChatData">
                  <span>Looks like you have made 
                    </span>
                    <span>things very clear!  </span>
                    
              {/* </div> */}
              </div>
          </div>
          </div>}
        </div>
      </section>
    </article>
  );
}

export default ChatView;
