import React, { useState, useEffect } from 'react';
// import moment from 'moment';
import {
  // XAxis,
  // YAxis,
  // CartesianGrid,
  // Tooltip,
  // LineChart,
  // Line,
  // ResponsiveContainer,
  // Legend,
  Pie,
  PieChart,
  Cell,
  Sector,
} from 'recharts';
// import { MONTHS } from '../../utils';

export const BloodGroupChartComponent = ({ inventoryChartData }) => {
  var BloodGroupChartCounts = [];
  if (inventoryChartData && inventoryChartData?.length) {
    inventoryChartData.map((ele) =>
      BloodGroupChartCounts.push({
        name: ele._id,
        value: ele.inventory?.length,
      })
    );
  }
  if (
    inventoryChartData &&
    Object.keys(inventoryChartData).length &&
    !inventoryChartData.length
  ) {
    for (let ele in inventoryChartData) {
      BloodGroupChartCounts.push({
        name: ele,
        value: inventoryChartData[`${ele}`],
      });
    }
  }
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (inventoryChartData) setData(BloodGroupChartCounts);
  }, [inventoryChartData]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor='middle'
        dominantBaseline='central'
      >
        {percent === 0 ? '' : `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderActiveShape = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  }) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);

    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />

        <text
          x={ex + (cos >= 0 ? -4 : 4) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill='#495057'
        >{`${payload.name} : ${value}`}</text>
      </g>
    );
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const COLORS = [
    '#9E2C41',
    '#FFC130',
    '#D3870A',
    '#66A3FF',
    '#1C53C7',
    '#73AF00',
    '#1E7D2D',
    '#DF5047',
  ];
  var colorGrp1 = [];
  var colorGrp2 = [];
  return (
    <>
      <PieChart width={450} height={400} className='bloodgroup-chart'>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx='270'
          cy='170'
          labelLine={false}
          outerRadius={130}
          fill='#8884d8'
          dataKey='value'
          label={renderCustomizedLabel}
          onMouseEnter={onPieEnter}
          startAngle={90}
          endAngle={-270}
        >
          {data.map((entry, index) => {
            entry['colorCode'] = COLORS[index % COLORS.length];
            if (index < 4) {
              colorGrp1.push(entry);
            } else {
              colorGrp2.push(entry);
            }
            return (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            );
          })}
        </Pie>
      </PieChart>

      <div className='legends-invetory'>
        <div className='d-flex'>
          {colorGrp1
            .sort((ele1, ele2) => {
              if (ele1['name'] > ele2['name']) return 1;
              if (ele1['name'] < ele2['name']) return -1;
              return 0;
            })
            .map((ele) => {
              return (
                <div className='divRow'>
                  <div className='divCell'>
                    <div
                      className='bullet'
                      style={{ borderColor: ele.colorCode }}
                    />
                  </div>
                  <div className='divCell'>{ele.name}</div>
                </div>
              );
            })}
        </div>
        <div className='d-flex'>
          {colorGrp2
            .sort((ele1, ele2) => {
              if (ele1['name'] > ele2['name']) return 1;
              if (ele1['name'] < ele2['name']) return -1;
              return 0;
            })
            .map((ele) => {
              return (
                <div className='divRow'>
                  <div className='divCell'>
                    <div
                      className='bullet'
                      style={{ borderColor: ele.colorCode }}
                    />
                  </div>
                  <div className='divCell'>{ele.name}</div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
