import React from "react";

function Buttons(props) {
  let {
    currSelected,
    setCurrSelected,
    SettingsJSON,
    onFinalSubmitSettings,
  } = props;
  const onNextClick = () => {
    if (currSelected < Object.keys(SettingsJSON).length - 1) {
      setCurrSelected(currSelected + 1);
    } else {
      onFinalSubmitSettings();
    }
  };
  return (
    <div className="bd-step__btn">
      {currSelected > 0 && (
        <span
          className="bd-btn bd-btn--outline"
          onClick={() => {
            setCurrSelected(currSelected - 1);
          }}
        >
          Cancel
        </span>
      )}
      <span className="bd-btn bd-btn--primary" onClick={onNextClick}>
        Save & Next
      </span>
    </div>
  );
}

export default Buttons;
