import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OTPView from '../../views/OTPView';
import {
  triggerForgotPassword,
  triggerSetOTP,
  triggerValidateOTP,
} from '../../redux/actions/authAction';

function OTPContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { forgetPassword, confirmOTP, emailForOTP } = useSelector(
    (state) => state.authReducer
  );
  const [errorMsg, setError] = useState('');
  const [errorResetMessage, setErrorResetMessage] = useState('');
  const [otp, setOtp] = useState('');
  const onChangeText = (e) => {
    // let forgSetPassObj = otp;
    // forgSetPassObj[e.target.name] = e.target.value;
    setOtp(e.target.value);
  };

  const onResendOTP = () => {
    setErrorResetMessage('OTP sent to email');
    return dispatch(triggerForgotPassword(emailForOTP));
  };

  const onSubmit = () => {
    if (otp === '') {
      setError('Invalid OTP');
    } else {
      let otpObj = {
        id: forgetPassword?.data?.content?.data?.id,
        otp: otp,
        type: 'ForgotOTP',
      };
      dispatch(
        triggerValidateOTP(otpObj, () => {
          dispatch(triggerSetOTP(otp));
          history.push('/setPassword');
        })
      );

      //
    }
  };
  return (
    <OTPView
      onResendOTP={onResendOTP}
      onSubmit={onSubmit}
      onChangeText={onChangeText}
      errorMsg={errorMsg}
      confirmOTP={confirmOTP}
      otp={otp}
      errorResetMessage={errorResetMessage}
    />
  );
}

export default OTPContainer;
