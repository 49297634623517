import React, { useState, useEffect } from "react";
import { DD_VALUES } from "../../utils/constants";
import BloodComponentView from "../../views/InventoryView/BloodComponentView";

export default function BloodComponentContainer ({
  currBloodGroup,
  bloodComponent,
  bloodComponentList,
  setShowInventory,
  setShowIssueToProcess,
  getSelectedComponent,
  getUnitValue
}) {
 
  const [dropdownList, setDropdownList] = useState(DD_VALUES);
  const [unitValue, setUnitValue] = useState(0);
  const [list, setList] = useState([]);

  function handleOnChange (e) {
    setUnitValue(e.value);
  };

  function onProcessClick(enteredVolume, totalVolume, externalAttributes) {
    setShowInventory(true);
    setShowIssueToProcess(false);
    getUnitValue({
      enteredVolume: enteredVolume, 
      totalVolume: totalVolume,
      unitValue: unitValue,
      ...externalAttributes
    });
  }

  function checkboxClick(e) {
    let arr = [];

    list.forEach((a) => {
      if (a.id === (e.target.id * 1)) {
        let obj = {
          ...a,
          isSelected: e.target.checked
        }
        a = {...obj}
      }
      arr.push(a);
    })

    setList(arr);
  }
  
  useEffect(() => {
    getSelectedComponent(list.filter(a => a.isSelected));
  },[list]);

  useEffect(() => {
    let arr = [];
    bloodComponentList.forEach((element, i) => {
      let obj = {
        component: element,
        id: i
      }
      arr.push(obj);
    });
    setList(arr);
  }, [bloodComponentList]);

  return (
   <BloodComponentView 
    currBloodGroup={currBloodGroup}
    bloodComponentList={list}
    setShowIssueToProcess={setShowIssueToProcess}
    dropdownList={dropdownList}
    unitValue={unitValue}
    bloodComponent={bloodComponent.split("_")[0]}
    checkboxClick={checkboxClick}
    handleOnChange={handleOnChange}
    onProcessClick={onProcessClick}
   />
  )
}