import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SuperAdminAdministratorView from '../../views/SuperAdminAdministratorView/SuperAdminAdministratorView.js';
import { SuperAdminAdministratorProfilepopup } from '../../components/Popups/SuperAdminAdministratorProfilepopup';

import moment from 'moment';
import { sorting } from '../../utils';
import {
  triggerGetAdminsList,
  triggerGetAdminData,
  triggerChangeStaus,
} from '../../redux/actions/superAdminAdministratorAction';
import { SuccessPopup } from '../../components/Popups/SuccessPopup';
import { FailPopup } from '../../components';

import { Loader } from '../../components';

import { triggeReCallLogin } from '../../redux/actions/superAdminCommonActions';
function SuperAdminAdministratorContainer() {
  const dispatch = useDispatch();

  const { getAdminList, getAdminData } = useSelector(
    (state) => state.superAdminAdministratoReducer
  );
  const { reCallLogin } = useSelector(
    (state) => state.superAdminCommonReducers
  );
  useEffect(() => {
    dispatch(triggeReCallLogin());
  }, []);

  const [sucessPopup, setShowSuccessPopup] = useState(false);
  const [failPopup, setShowFailPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [adminsRawList, setAdminsRawList] = useState([]);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [adminProfileData, setAdminProfileData] = useState([]);
  const [search, setSearch] = useState('');

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...adminsRawList];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setAdminsRawList(data);
    setCount(count + 1);
  }

  const handleClick = (e) => {
    e.stopPropagation();
    setShowProfilePopup(true);
    dispatch(
      triggerGetAdminData({
        id: e.target.getAttribute('id'),
      })
    );
  };

  function setData(sortedData) {
    let data = [];
    sortedData &&
      sortedData?.forEach((record, i) => {
        let obj = {
          userId: record?._id ? record._id : '--',
          name:
            record?.personalInfo?.firstName +
            ' ' +
            record?.personalInfo?.lastName,
          email: record?.personalInfo?.email
            ? record?.personalInfo?.email
            : '--',
          contactNo: record?.personalInfo?.mobileNumber
            ? record?.personalInfo?.mobileNumber
            : '--',
          address: record?.personalInfo?.addressDetails
            ? record?.personalInfo?.addressDetails?.address
            : '--',
          date: moment(record?.personalInfo?.updatedAt).format('DD MMM YYYY'),

          status:
            record?.status == 0 ? (
              <div style={{ color: '#f1ee17 ', fontWeight: '700' }}>
                Pending
              </div>
            ) : record?.status == 1 ? (
              <div style={{ color: 'green', fontWeight: '700' }}>Active</div>
            ) : (
              <div style={{ color: '#AF0C0C', fontWeight: '700' }}>
                Inactive
              </div>
            ),
        };
        data.push(obj);
      });
    return data;
  }

  function searchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.name.toLowerCase().includes(search.toLowerCase()) ||
          String(items.email).toLowerCase().includes(search.toLowerCase()) ||
          String(items.date).toLowerCase().includes(search.toLowerCase()) ||
          String(items.address).toLowerCase().includes(search.toLowerCase()) ||
          String(items.contactNo)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.status).toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  function adminsTableData() {
    return search === '' ? adminsRawList : searchData(adminsRawList);
  }

  useEffect(() => {
    if (getAdminData.data) {
      setAdminProfileData({
        id: getAdminData.data?._id,
        status: getAdminData.data?.status,
        name:
          getAdminData.data?.personalInfo?.firstName +
          ' ' +
          getAdminData.data?.personalInfo?.lastName,
        email: getAdminData.data?.personalInfo?.email
          ? getAdminData.data?.personalInfo?.email
          : '--',
        gender: getAdminData.data?.personalInfo?.gender
          ? getAdminData.data?.personalInfo?.gender
          : '--',
        dob: getAdminData.data?.personalInfo?.dateOfBirth
          ? getAdminData.data?.personalInfo?.dateOfBirth
          : '--',
        contactNo: getAdminData.data?.personalInfo?.mobileNumber
          ? getAdminData.data?.personalInfo?.mobileNumber
          : '--',
        address: getAdminData.data?.personalInfo?.addressDetails
          ? getAdminData.data?.personalInfo?.addressDetails?.state +
            ',' +
            getAdminData.data?.personalInfo?.addressDetails?.city +
            ',' +
            getAdminData.data?.personalInfo?.addressDetails?.zipCode +
            ',' +
            getAdminData.data?.personalInfo?.addressDetails?.address
          : '--',
        permissions: getAdminData.data?.permissions,
      });
    }
  }, [getAdminData]);

  function escFunction(e) {
    if (e.keyCode === 27) {
      setShowProfilePopup(false);
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  });

  useEffect(() => {
    if (getAdminList?.data) {
      setAdminsRawList(setData(getAdminList?.data));
    }
  }, [getAdminList?.data]);

  useEffect(() => {
    dispatch(triggerGetAdminsList());
  }, []);

  function ActionBtn(type) {
    let obj = {
      id: adminProfileData?.id,
      action: type,
    };
    dispatch(
      triggerChangeStaus(obj, () => {
        setShowProfilePopup(false);
        setShowSuccessPopup(true);
        setSuccessMsg(
          type === 'delete'
            ? 'Delete Admin'
            : type === 'activate'
            ? 'Active Admin'
            : 'Deactive Admin'
        );
        dispatch(triggerGetAdminsList());
      })
    );
  }

  function onContinueClick() {
    setShowFailPopup(false);
    setShowSuccessPopup(false);
  }

  return (
    <>
      {getAdminList?.loading ? (
        <Loader />
      ) : (
        <SuperAdminAdministratorView
          page={page}
          search={search}
          setSearch={setSearch}
          adminsList={adminsTableData()}
          rowsPerPage={rowsPerPage}
          onHeaderClick={onHeaderClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleClick={handleClick}
          permissionOfAddForm={reCallLogin?.data?.content?.data?.admins?.update}
        />
      )}
      {showProfilePopup && (
        <SuperAdminAdministratorProfilepopup
          profileData={adminProfileData}
          rawData={getAdminData.data}
          setShowProfilePopup={setShowProfilePopup}
          ActionBtn={ActionBtn}
          permissionOfAddForm={reCallLogin?.data?.content?.data?.admins}
        />
      )}
      {sucessPopup ? (
        <SuccessPopup title={successMsg} onContinueClick={onContinueClick} />
      ) : (
        ''
      )}
      {failPopup && (
        <FailPopup
          title={errorMsg}
          onContinueClick={() => setShowFailPopup(false)}
        />
      )}
    </>
  );
}

export default SuperAdminAdministratorContainer;
