import axios from 'axios';
import moment from 'moment';
import Female from '../assets/images/Female.png';
// import Female from './';
import Male from '../assets/images/Male.png';
import Other from '../assets/images/Other.png';

export const emailPattern = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const makeAPICall = (apiData) => {
  // let history = useHistory();
  let {
    url,
    dispatch,
    defaultAction,
    successAction,
    failedAction,
    type,
    callback,
    errCallback,
    callbackData,
    extraData,
    headers,
    noToken,
    params = {},
  } = apiData;

  // this.props.history.push("/login")

  if (!url || url.indexOf('http://') === 1) {
    dispatch({
      type: null,
      payload: null,
    });
    return Promise.reject();
  }

  let apiObj = {
    method: type || 'GET',
    headers: {
      // Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };

  if (defaultAction) {
    dispatch({
      type: defaultAction,
      payload: null,
    });
  }

  if (apiObj.method !== 'GET') {
    apiObj.data = JSON.stringify(params);
  }
  if (noToken) {
  } else {
    apiObj.headers = {
      ...apiObj.headers,
      token: window.localStorage.TOKEN_KEY,
    };
  }
  apiObj.url = url;
  return axios(apiObj)
    .then((response) => {
      try {
        response = response.data;
        // if (response.status == 200) { str.includes("world")
        if (response.code === 200) {
          if (apiObj.url.includes('login')) {
            global.sessionTime = new Date();
          }
          if (successAction) {
            dispatch({
              type: successAction,
              payload: response,
              data: params,
              params,
              callbackData,
              extraData,
            });
          }
          if (callback) {
            callback(response, params);
          }
        } else {
          if (failedAction) {
            dispatch({
              type: failedAction,
              payload: null,
              data: params,
              error: response,
            });
          }
          if (errCallback) {
            errCallback(response, params);
          }
        }
      } catch (e) {
        if (failedAction) {
          dispatch({
            type: failedAction,
            payload: null,
            data: params,
            error: e,
          });
        }
      }
    })
    .catch((error) => {
      const { response } = error;
      if (failedAction) {
        dispatch({
          type: failedAction,
          payload: null,
          data: params,
          error: response?.data?.content?.message ?? 'Something went wrong',
        });
      }
    });
};

export const login = (data) => {
  console.log('🚀 ~ file: index.js ~ line 126 ~ login ~ data', data);
  localStorage.setItem('TOKEN_KEY', data?.token);
  localStorage.setItem(
    'identifier',
    data.institutionType ? 'institution' : 'admin'
  );
};

export const logout = () => {
  localStorage.removeItem('TOKEN_KEY');
};

export const isLogin = () => {
  if (localStorage.getItem('TOKEN_KEY') && localStorage.getItem('identifier')) {
    return true;
  }
  if (localStorage.getItem('TOKEN_KEY') || localStorage.getItem('identifier')) {
    localStorage.removeItem('TOKEN_KEY');
    localStorage.removeItem('identifier');
  }

  return false;
};

export const validateRegister = (obj, setError) => {
  const mailformat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (obj.contactEmail.match(mailformat)) {
    if (
      obj.contactNumber !== '' &&
      (obj.contactNumber.length == 10 || obj.contactNumber.length == 11)
    ) {
      if (obj.institutionName !== '') {
        if (obj.registrationNumber !== '') {
          return;
          // if (obj.keyMembers[0].firstName !== '') {
          //   if (obj.keyMembers[0].designation !== '') {
          //     if (obj.keyMembers[0].email.match(mailformat)) {
          //       if (obj.address !== '') {
          //         return obj;
          //       } else {
          //         setError('Enter address');
          //         return null;
          //       }
          //     } else {
          //       setError('Enter key members email');
          //       return null;
          //     }
          //   } else {
          //     setError('Enter key members designation');
          //     return null;
          //   }
          // } else {
          //   setError('Enter key members full name');
          //   return null;
          // }
        } else {
          setError('Enter registration number');
          return null;
        }
      } else {
        setError('Enter institution name');
        return null;
      }
    } else {
      setError('Enter valid contact number');
      return null;
    }
  } else {
    setError('Enter valid contact email');
    return null;
  }
};

export const validateLabResult = (obj, setError) => {
  if (obj.bloodGroup && obj.bloodGroup !== '') {
    // if (obj.labResults && obj.labResults.Hb && obj.labResults.Hb !== "") {
    if (
      obj.labResults &&
      obj.labResults.reportNo &&
      obj.labResults.reportNo !== ''
    ) {
      // if (
      //   obj.labResults &&
      //   obj.labResults.weight &&
      //   obj.labResults.weight !== ""
      // ) {
      // if (
      //   obj.labResults &&
      //   obj.labResults.bloodPressure &&
      //   obj.labResults.bloodPressure !== ""
      // ) {
      if (
        obj.labResults &&
        obj.labResults.reportNo &&
        obj.labResults.reportNo !== ''
      ) {
        // if (
        //   obj.labResults &&
        //   obj.labResults.weight &&
        //   obj.labResults.weight !== ""
        // ) {
        // if (
        //   obj.labResults &&
        //   obj.labResults.bloodPressure &&
        //   obj.labResults.bloodPressure !== ""
        // ) {
        if (
          obj.labResults &&
          obj.labResults.testedBy &&
          obj.labResults.testedBy !== ''
        ) {
          if (
            obj.labResults &&
            obj.labResults.addedAt &&
            obj.labResults.addedAt !== ''
          ) {
            return true;
          } else {
            setError('Enter Added At');
          }
        } else {
          setError('Enter Tested By');
        }
        // }
        // else {
        //   setError("Enter Blood Pressure");
        // }
        // } else {
        //   setError("Enter Weight");
        // }
      } else {
        setError('Enter Tested By');
      }
      // }
      // else {
      //   setError("Enter Blood Pressure");
      // }
      // } else {
      //   setError("Enter Weight");
      // }
    } else {
      setError('Enter Report No');
    }
    // }
    // else {
    //   setError("Enter Hb");
    // }
  } else {
    setError('Enter Blood Group');
  }
};

export const validateLabResultArr = (arr, setError) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].bloodGroup && arr[i].bloodGroup !== '') {
      if (
        arr[i].labResults &&
        arr[i].labResults.Hb &&
        arr[i].labResults.Hb !== ''
      ) {
        if (
          arr[i].labResults &&
          arr[i].labResults.reportNo &&
          arr[i].labResults.reportNo !== ''
        ) {
          if (
            arr[i].labResults &&
            arr[i].labResults.weight &&
            arr[i].labResults.weight !== ''
          ) {
            if (
              arr[i].labResults &&
              arr[i].labResults.bloodPressure &&
              arr[i].labResults.bloodPressure !== ''
            ) {
              if (
                arr[i].labResults &&
                arr[i].labResults.testedBy &&
                arr[i].labResults.testedBy !== ''
              ) {
                if (
                  arr[i].labResults &&
                  arr[i].labResults.addedAt &&
                  arr[i].labResults.addedAt !== ''
                ) {
                  return true;
                } else {
                  setError('Enter Added At');
                  return false;
                }
              } else {
                setError('Enter Tested By');
                return false;
              }
            } else {
              setError('Enter Blood Pressure');
              return false;
            }
          } else {
            setError('Enter Weight');
            return false;
          }
        } else {
          setError('Enter Report No');
          return false;
        }
      } else {
        setError('Enter Hb');
        return false;
      }
    } else {
      setError('Enter Blood Group');
      return false;
    }
  }
};

export const validateSettingInvDonArray = (obj) => {
  if (obj.type === 'inventory') {
    // for (let i = 0; i < obj.arr.length; i++) {
    //   if(obj.arr[i].)
    // }
  }
};
export const validatePassword = (txt) => {
  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  if (txt.match(passwordRegex)) {
    return true;
  }
  return false;
};

export const getOTP = (refObj) => {
  let value = '';
  for (const property in refObj) {
    value += refObj[property].value;
  }
  return value;
};

export const urlSearchParams = (params) => {
  let param = new URLSearchParams();
  for (let i in params) {
    param.append(`${i}`, params[i]);
  }
  return param;
};

export function formatDate(date) {
  let formatedDate = date ? new Date(date).toDateString().split(' ') : '';
  return formatedDate[2] + ' ' + formatedDate[1] + ' ' + formatedDate[3];
}

export const clean = (obj) => {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export function ageCalculation(date) {
  let a = moment();
  let b = moment(new Date(date), 'DD-MM-YYYY');
  let age = moment.duration(a.diff(b));
  let years = age.years();
  let months = age.months();
  let days = age.days();
  return (
    years +
    ' years ' +
    months +
    ' months ' +
    days +
    (days === 1 ? ' day' : ' days')
  );
}

export function ageInYears(date) {
  let a = moment();
  let b = moment(new Date(date), 'DD-MM-YYYY');
  let age = moment.duration(a.diff(b));
  let years = age.years();
  return years;
}
export function sorting(value1, value2, attribute) {
  let str1 = value1[attribute] || '';
  let str2 = value2[attribute] || '';
  if (typeof str1 === 'string') {
    let flag = str1.toLowerCase().localeCompare(str2.toLowerCase());
    return flag;
  } else if (typeof str2 === 'number') {
    let flag = str1 - str2;
    return flag;
  }
}

export function setDataList(list, _updatedList = []) {
  if (list?.length > 0) {
    _updatedList = list.reduce((acc, currVal) => {
      currVal.inventory.map((item) => {
        item.bloodGroup = currVal._id;
        item.donorId = item.donorId ? item.donorId : '--';
        item.collectionDate &&
          (item.collectionDate = formatDate(item.collectionDate));
        item.expiryDate && (item.expiryDate = formatDate(item.expiryDate));
        item.type && (item.type = item.type);
        item.receivedOn = item?.receivedOn
          ? formatDate(item?.receivedOn)
          : formatDate(item?.collectionDate);
        item.receivedFrom = item?.receivedFrom?.institutionName
          ? item?.receivedFrom?.institutionName
          : item?.DonationType;
        item.result = (
          <div style={{ color: '#0D9823', fontWeight: 'bold' }}>Pass</div>
        );
        item.transfer =
          item.isTransferInProcess == true ? (
            <div style={{ color: '#2A6BF4', fontWeight: 'bold' }}>
              Transfer In Process
            </div>
          ) : item.inBloodProcessing == true ? (
            <div style={{ color: '#0D9823', fontWeight: 'bold' }}>
              Blood in Process
            </div>
          ) : (
            <div style={{ color: '#2A6BF4', fontWeight: 'bold' }}>
              In Inventory
            </div>
          );
      });

      acc.push(...currVal.inventory);
      return acc;
    }, []);
  }

  return _updatedList;
}

export function setIssuedDataList(list) {
  let data = [];
  list &&
    list.forEach((item) => {
      let obj = {
        bloodGroup: item?.bloodGroup,
        component: item?.component,
        // storage: item?.storedIn,
        // unit: item?.units,
        // ID: item?._id,
        bagId: item?.bagId ? item?.bagId : '--',
        collectionDate: formatDate(item.collectionDate),
        expiryDate: formatDate(item.expiryDate),

        issuedDate: formatDate(item.issuedDate),
        hospitalName: item?.reciever[0]?.hospitalName
          ? item?.reciever[0]?.hospitalName
          : item?.reciever[0]?.bloodBankName,
        patientId: item?.reciever[0]?.patientId
          ? item?.reciever[0]?.patientId
          : '--',
        Result: (
          <div style={{ color: '#0D9823', fontWeight: 'bold' }}>Pass</div>
        ),
      };
      data.push(obj);
    });
  return data;
}

export function setReceivedDataList(list) {
  let data = [];
  list &&
    list.forEach((item) => {
      let obj = {
        bloodGroup: item?.bloodGroup,
        component: item?.component,
        // storage: item?.storedIn,
        // unit: item?.units,
        // ID: item?._id,
        status:
          item?.status == 0 ? (
            <div style={{ color: '#2a6bf4', fontWeight: 'bold' }}>
              Inventory
            </div>
          ) : item?.status == 1 ? (
            <div style={{ color: '#af0c0c', fontWeight: 'bold' }}>Disposed</div>
          ) : (
            <div style={{ color: '#0d9823', fontWeight: 'bold' }}>Issued</div>
          ),
        bagId: item?.bagId ? item?.bagId : '--',
        collectionDate: item.collectionDate
          ? formatDate(item.collectionDate)
          : '--',
        expiryDate: item.expiryDate ? formatDate(item.expiryDate) : '--',
        issuedDate: item.issuedDate ? formatDate(item.issuedDate) : '--',
        hospitalName: '--',
        // patientId: item?.reciever ? item?.reciever[0]?.patientId : '--',
        donorId: item?.donorId ? item.donorId : '--',
        location:
          (item?.storedIn && item?.status == 2) || item?.status == 1
            ? '--'
            : item?.storedIn,
        Result: (
          <div style={{ color: '#0D9823', fontWeight: 'bold' }}>Pass</div>
        ),
      };
      item?.reciever?.length &&
        (obj['hospitalName'] = item.reciever[0].hospitalName
          ? item.reciever[0].hospitalName
          : item.reciever[0].bloodBankName);
      data.push(obj);
    });
  return data;
}

export function genderWiseProfilePic(gender) {
  let abc = { Male: Male, Female: Female, Other: Other };
  return abc[`${gender}`];
}

export const BLOCK_REASON = [
  'Medically unfit to donate',
  'Asked money for donation',
  'Under Antibiotics/Prescribed Medication',
  'Unruly behaviour',
  'Drug/Alcohol abuse',
  'False Declaration',
  'Others (please specify)',
];

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export function getpermission(type) {
  let abc = {
    'Full Permission': {
      read: true,
      write: true,
      update: true,
      delete: true,
    },
    'View Only': {
      read: true,
      write: false,
      update: false,
      delete: false,
    },
    'Create , Update but Not Delete': {
      read: true,
      write: true,
      update: true,
      delete: false,
    },
    None: {
      read: false,
      write: false,
      update: false,
      delete: false,
    },
  };

  if (typeof type === 'object') {
    var output = '';
    for (let item in abc) {
      // console.log(
      //   '🚀 ~ file: index.js ~ line 602 ~ getpermission ~ item',
      //   item
      // );
      // console.log(
      //   '🚀 ~ ',
      //   JSON.stringify(abc[`${item}`]) === JSON.stringify(type)
      // );
      if (JSON.stringify(abc[`${item}`]) === JSON.stringify(type)) {
        output = `${item}`;
      }
      // console.log(output);
    }
    // console.log(output);
    return output;
  } else {
    return abc[type];
  }
}
