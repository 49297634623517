import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { Charts, RadioButton } from '../../components';
import UserDetail from '../../components/Popups/UserDetail';
import { blockDonor } from '../../redux/actions/peopleAction';
import { getPeopleDonorList } from '../../redux/actions/peopleAction';
import { API_BASE_PATH, UPLOAD_FILE_URL } from '../../utils/config';
import download from '../../assets/icon/download.svg';

const ProfileDetailPopup = ({ profileData, hidePopup, type, tabs }) => {
  const { donationObj, donarProfile } = useSelector(
    (state) => state.dashboardReducer
  );
  const dispatch = useDispatch();
  const [detail, setDetail] = useState({});
  const [donorRequestId, setDonorRequestId] = useState(null);
  const [currentRadioSelect, setCurrentRadioSelect] = useState('');
  const [reasonValue, setReasonValue] = useState('');
  // const [files, setFiles] = useState({});
  const hiddenFileInput = React.useRef(null);
  const [attachments, setAttachments] = useState([]);

  const blockReason = [
    'Medically unfit donate',
    'Asked money for donation',
    'Under Antibiotics/Prescribed Medication',
    'Unruly behaviour',
    'Drug/Alcohol abuse',
    'False Declaration',
    'Others (please specify)',
  ];
  function onInputChange(e) {
    let obj = { ...detail };
    let value = e.target.value;
    if (e.target.name === 'days') {
      let regex = /^[0-9]+$/;
      if (value.match(regex) !== null) {
        if (value * 1 < 182.5) {
          obj[e.target.name] = value;
        }
      }
    } else {
      obj[e.target.name] = value;
    }
    setDetail(obj);
  }
  const addFile = async (e) => {
    let selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    let response = await axios.post(API_BASE_PATH + UPLOAD_FILE_URL, formData, {
      headers: {
        token: `${localStorage.getItem('TOKEN_KEY')}`,
      },
    });
    let file = response.data.content.savedFiles[0];
    let arr = [...attachments];
    if (file) arr.push(file);
    setAttachments(arr);
  };

  function onRadioChange(e) {
    setCurrentRadioSelect(e.target.value);
  }
  function handleOnChange(e) {
    setReasonValue(e.target.value);
  }
  function onBlockClick() {
    let obj = {
      type,
      donorId: donarProfile.data.donorId,
      blockType: currentRadioSelect,
      blockPeriod: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 3
      ).toISOString(),
      comment: detail.comment,
      reason: reasonValue,
      file: attachments,
      // status: "Block",
      // file: "",
    };

    type == 'UnBlock' &&
      (obj = {
        type,
        donorId: donarProfile.data.donorId,
        reason: reasonValue,
        blockedForDays: 25,
        comment: detail.comment,
        file: attachments,
      });

    dispatch(
      blockDonor(obj, () => {
        dispatch(getPeopleDonorList());
        hidePopup();
      })
    );
  }

  function createDropdownList() {
    return blockReason.map((item, key) => {
      return (
        <option key={key} value={item === 'default' ? '' : item}>
          {blockReason[key]}
        </option>
      );
    });
  }

  function deleteAttachment(e, index) {
    e.preventDefault();
    let files = [...attachments];
    files.splice(index, 1);
    setAttachments(files);
  }

  useEffect(() => {
    if (donarProfile.data) {
      let request = donationObj.data.filter(
        (a) => a.donorId === donarProfile.data.donorId
      )[0];
      setDonorRequestId(request);
    }
  }, [donarProfile, donationObj]);

  return (
    <>
      <div className='bd-modal-wrap bd-modal-right bd-profile'>
        <div className='bd-popup si-fade-in'>
          <div className='bd-popup__head'>
            <span>Profile of Donor ID - {profileData.donorId}</span>
            <i className='bludclose' onClick={hidePopup}></i>
          </div>
          <div className='bd-popup__body'>
            <div className='bd-profile__tbl'>
              <div className='bd-profile__detail--tbl'>
                <UserDetail profileData={profileData} />
                <div class='bd-graph__wrap'>
                  <Charts chartType='line-chart' chartData={tabs && tabs[0]} />
                </div>
              </div>

              <>
                <div class='bd-donation__form'>
                  {type !== 'UnBlock' ? (
                    <div class='bd-popup__head'>
                      <span>Block donor</span>
                      <div className='blockPopupRadioWrapper'>
                        <RadioButton
                          label='Block Permanently'
                          name='radio1'
                          value='permanently'
                          onRadioChange={onRadioChange}
                        />
                        <RadioButton
                          label='Block Temporarily'
                          name='radio1'
                          value='temporarily'
                          onRadioChange={onRadioChange}
                        />
                        <RadioButton
                          label='Block till recovery'
                          name='radio1'
                          value='recovery'
                          onRadioChange={onRadioChange}
                        />
                      </div>
                    </div>
                  ) : (
                    <div class='bd-popup__head'>
                      <span>UnBlock donor</span>{' '}
                    </div>
                  )}
                  <div class='bd-form'>
                    <div class='bd-twocol bd-form__row'>
                      <div class=''>
                        <div class='bd-dorpdown__selected'>
                          <span>Reason</span>
                          {type !== 'UnBlock' ? (
                            <select
                              className='block-reason-dropdown'
                              value={
                                reasonValue
                                  ? reasonValue
                                  : 'Medically unfit donate'
                              }
                              onChange={handleOnChange}
                            >
                              {createDropdownList()}
                            </select>
                          ) : (
                            <input
                              type='text'
                              placeholder='reason'
                              name='reason'
                              value={detail.reasonValue}
                              onChange={(e) => {
                                setReasonValue(e.target.value);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {currentRadioSelect === 'temporarily' && (
                        <div class='bd-form__row'>
                          <input
                            type='text'
                            placeholder='Block Period (in days) not more than 6 month'
                            name='days'
                            value={detail.days}
                            onChange={onInputChange}
                          />
                        </div>
                      )}
                    </div>
                    <div class='bd-form__row'>
                      <input
                        type='text'
                        placeholder='Comment'
                        name='comment'
                        value={detail.comment}
                        onChange={onInputChange}
                      />
                    </div>
                    <input
                      type='file'
                      name='attachments'
                      id='upload'
                      hidden
                      ref={hiddenFileInput}
                      onChange={addFile}
                    />
                    <div
                      className='bd-btn bd-btn--outline'
                      for='upload'
                      onClick={() => hiddenFileInput.current.click()}
                    >
                      <span for='upload'>
                        <i class='icon-clip-icon mr-10'></i>
                        Attach Files
                      </span>
                    </div>
                    <div className='bd-attachment__file'>
                      {attachments &&
                        attachments.map((attachment, i) => {
                          return (
                            <a
                              className='download-a'
                              download
                              href={attachment.location}
                            >
                              <img
                                className='download-img'
                                src={download}
                                alt='download'
                              />
                              <div className='attachment-name'>
                                {attachment.name}
                              </div>
                              <i
                                className='bluddelete paddingTop_3'
                                onClick={(e) => deleteAttachment(e, i)}
                              />
                            </a>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div class='bd-btn__wrap'>
                  <div class='bd-btn bd-btn--outline' onClick={hidePopup}>
                    <span>Cancel</span>
                  </div>
                  {type == 'UnBlock' ? (
                    <div class='bd-btn bd-btn--share' onClick={onBlockClick}>
                      <span>UnBlock</span>
                    </div>
                  ) : (
                    <div class='bd-btn bd-btn--primary' onClick={onBlockClick}>
                      <span>Block</span>
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
        <div className='bd-popup__lightbox' />
      </div>
    </>
  );
};
export default ProfileDetailPopup;
