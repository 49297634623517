import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  triggerGetSubcriptionPlansList,
  triggerPostAddPlan,
} from '../../redux/actions/superAdminCommonActions';
import SuperAdminSubscriptionView from '../../views/SuperAdminSubscriptionView/SuperAdminSubscriptionView';
import { triggeReCallLogin } from '../../redux/actions/superAdminCommonActions';
import { sorting } from '../../utils';
import moment from 'moment';
import AddPlansForm from '../../views/SuperAdminSubscriptionView/AddPlansForm';
import { SuccessPopup } from '../../components/Popups/SuccessPopup';
import { FailPopup } from '../../components';
// import AddPlansForm from '../../views/';

function SuperAdminSubscriptionContainer() {
  const dispatch = useDispatch();
  const { getSubcriptionPlasList, reCallLogin } = useSelector(
    (state) => state.superAdminCommonReducers
  );

  useEffect(() => {
    dispatch(triggeReCallLogin());
  }, []);

  const [sucessPopup, setShowSuccessPopup] = useState(false);
  const [failPopup, setShowFailPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [SubscriptionData, setSubscriptionData] = useState([]);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [addPlans, setAddPlans] = useState(false);

  const [addPlansObj, setAddPlansObj] = useState({
    planName: '',
    description: '',
    quantity: '',
    price: '',
    fromDate: '',
    toDate: '',
    bonusQuantity: '',
    bonusPeriod: '',
    condition: '',
    // attachments: [],
  });
  function onChangeText(e, name) {
    let updateAddPlanObj = { ...addPlansObj };
    if (name) {
      updateAddPlanObj[name] = new Date(e);
    } else {
      updateAddPlanObj[e.target.name] = e.target.value;
    }
    setAddPlansObj(updateAddPlanObj);
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...SubscriptionData];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setSubscriptionData(data);
    setCount(count + 1);
  }

  function setData(sortedData) {
    let data = [];
    sortedData &&
      sortedData?.forEach((record, i) => {
        let obj = {
          planName: record?.planName ? record?.planName : '--',
          description: record?.description ? record?.description : '--',
          price: record?.price ? `₹ ${record?.price}` : '--',
          quantity: record?.quantity ? record?.quantity : '--',
          bonusQuantity: record?.bonusQuantity ? record?.bonusQuantity : '--',
          condition: record?.condition ? record?.condition : '--',
          bonusPeriod: moment(record?.bonusPeriod).format('DD MMM YYYY'),
          fromDate: moment(record?.from).format('DD MMM YYYY'),
          toDate: moment(record?.to).format('DD MMM YYYY'),
        };
        data.push(obj);
      });
    return data;
  }

  function searchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.planName.toLowerCase().includes(search.toLowerCase()) ||
          items.description.toLowerCase().includes(search.toLowerCase()) ||
          String(items.price).toLowerCase().includes(search.toLowerCase()) ||
          String(items.quantity).toLowerCase().includes(search.toLowerCase()) ||
          String(items.bonusQuantity)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.bonusPeriod)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.fromDate).toLowerCase().includes(search.toLowerCase()) ||
          String(items.toDate).toLowerCase().includes(search.toLowerCase()) ||
          String(items.condition).toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  function BludUnitsDataList() {
    return search === '' ? SubscriptionData : searchData(SubscriptionData);
  }

  function onSubmit(e) {
    e.preventDefault();
    // console.log('ADD FOrm');
    const {
      planName,
      description,
      quantity,
      price,
      fromDate,
      toDate,
      bonusQuantity,
      bonusPeriod,
      condition,
      // attachments,
    } = addPlansObj;

    if (
      !planName ||
      !description ||
      !quantity ||
      !price ||
      !fromDate ||
      !toDate ||
      !bonusQuantity ||
      !bonusPeriod ||
      !condition
    ) {
      setErrorMsg('All Fields required !');
      setShowFailPopup(true);
    } else {
      dispatch(triggerPostAddPlan(addPlansObj, callBack));
    }
  }
  function callBack() {
    setAddPlans(false);
    setShowSuccessPopup(true);
    dispatch(triggerGetSubcriptionPlansList());
    setAddPlansObj({});
  }

  useEffect(() => {
    if (getSubcriptionPlasList?.data?.content?.data) {
      setSubscriptionData(setData(getSubcriptionPlasList?.data?.content?.data));
    }
  }, [getSubcriptionPlasList?.data?.content?.data]);

  function escFunction(e) {
    if (e.keyCode === 27) {
      setAddPlans(false);
      setShowSuccessPopup(false);
      setShowFailPopup(false);
    }
  }
  useEffect(() => {
    dispatch(triggerGetSubcriptionPlansList());
    document.addEventListener('keydown', escFunction, false);
  }, []);

  return (
    <>
      <SuperAdminSubscriptionView
        SubcriptionDataList={BludUnitsDataList()}
        onHeaderClick={onHeaderClick}
        setSearch={setSearch}
        search={search}
        setAddPlans={setAddPlans}
        addPlans={addPlans}
        permissions={reCallLogin?.data?.content?.data?.subscription}
      />

      {addPlans && (
        <AddPlansForm
          addPlansObj={addPlansObj}
          onChangeText={onChangeText}
          setAddPlans={setAddPlans}
          addPlans={addPlans}
          onSubmit={onSubmit}
        />
      )}
      {sucessPopup ? (
        <SuccessPopup
          title={`Plan Add Successfully!`}
          onContinueClick={() => setShowSuccessPopup(false)}
        />
      ) : (
        ''
      )}
      {failPopup && (
        <FailPopup
          title={errorMsg}
          onContinueClick={() => setShowFailPopup(false)}
        />
      )}
    </>
  );
}

export default SuperAdminSubscriptionContainer;
