import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import DropDown from '../../components/RDropDown';

import { USERS_COLUMNS, BLOOD_GROUP_OPTIONS } from '../../utils/constants';

function SuperAdminUsersView({
  page,
  search,
  setSearch,
  UsersList,
  rowsPerPage,
  onHeaderClick,
  handleClick,
  headerDropDownChange,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search'>
                <span className='bd-dashboard__main'>Users</span>
                <div className='dashboard_tiles_header'>
                  <div className='bd_error fw-600'>
                    <div className='bd-dorpdown'>
                      <div className='bd-dorpdown__selected detail-dd bg-dd'>
                        <DropDown
                          placeHolderText={'Blood Group'}
                          options={BLOOD_GROUP_OPTIONS}
                          fetchDropDownValue={headerDropDownChange}
                        />
                        <i className='bludbig-arrow' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bd-hader'>
                  <input
                    type='text'
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder='Search'
                    style={{ padding: 5, marginRight: 10 }}
                  />
                </div>
              </div>
              <div className='table-scroll'>
                <Tables
                  tab={'Users'}
                  tableType={'table-type-1'}
                  columns={USERS_COLUMNS}
                  tableData={UsersList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  headerClick={onHeaderClick}
                  onClick={handleClick}
                  rowClick={true}
                />
              </div>
              <TablePagination
                component='div'
                count={UsersList.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </section>
      </article>
    </>
  );
}

export default SuperAdminUsersView;
