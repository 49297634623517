import {
  GET_DONOR_LIST,
  GET_DONOR_LIST_SUCCESS,
  GET_DONOR_LIST_FAILED,
  GET_RECEPIENT_LIST,
  GET_RECEPIENT_LIST_SUCCESS,
  GET_RECEPIENT_LIST_FAILED,
  BLOCK_AND_UNBLOCK,
  BLOCK_AND_UNBLOCK_SUCCESS,
  BLOCK_AND_UNBLOCK_FAILED,
  REQUEST_FOR_BLOOD,
  REQUEST_FOR_BLOOD_SUCCESS,
  REQUEST_FOR_BLOOD_FAILED,
  // superAdmin Users Data
  GET_USERS_DATA,
  GET_USERS_DATA_SUCCESS,
  GET_USERS_DATA_FAILED,
  // superAdmin Users Data
  BLOCK_UNBLOCK_USER,
  BLOCK_UNBLOCK_USER_SUCCESS,
  BLOCK_UNBLOCK_USER_FAILED,
} from './../actions/actionTypes';

const initialState = {
  peopleDonorObj: {
    loading: false,
    data: [],
    error: null,
  },
  peopleRecepientObj: {
    loading: false,
    data: [],
    error: null,
  },
  blockDonorObj: {
    loading: false,
    data: null,
    error: null,
  },
  addBloodRequestObj: {
    loading: false,
    data: null,
    error: null,
  },
  superAdminUsersList: {
    loading: false,
    data: [],
    error: null,
  },
  changeAdimintatus: {
    loading: false,
    data: [],
    error: null,
  },
};

const peopleReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_DATA:
      return {
        ...state,
        superAdminUsersList: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GET_USERS_DATA_SUCCESS:
      return {
        ...state,
        superAdminUsersList: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_USERS_DATA_FAILED:
      return {
        ...state,
        superAdminUsersList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case BLOCK_UNBLOCK_USER:
      return {
        ...state,
        changeAdimintatus: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case BLOCK_UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        changeAdimintatus: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case BLOCK_UNBLOCK_USER_FAILED:
      return {
        ...state,
        changeAdimintatus: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case GET_DONOR_LIST:
      return {
        ...state,
        peopleDonorObj: {
          loading: true,
          data: [],
          // data: payload, //dummy data
          error: null,
        },
      };
    case GET_DONOR_LIST_SUCCESS:
      return {
        ...state,
        peopleDonorObj: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };
    case GET_DONOR_LIST_FAILED:
      return {
        ...state,
        peopleDonorObj: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    case GET_RECEPIENT_LIST:
      return {
        ...state,
        peopleRecepientObj: {
          loading: true,
          data: [],
          error: null,
        },
      };
    case GET_RECEPIENT_LIST_SUCCESS:
      return {
        ...state,
        peopleRecepientObj: {
          loading: false,
          data: action.payload.content.data,
          // data: action.reyload, //dummy data
          error: null,
        },
      };

    case GET_RECEPIENT_LIST_FAILED:
      return {
        ...state,
        peopleRecepientObj: {
          loading: false,
          data: [],
          // data: reyload, //dummy data
          error: action.error,
        },
      };
    case BLOCK_AND_UNBLOCK:
      return {
        ...state,
        blockDonorObj: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };

    case BLOCK_AND_UNBLOCK_SUCCESS:
      return {
        ...state,
        blockDonorObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case BLOCK_AND_UNBLOCK_FAILED:
      return {
        ...state,
        blockDonorObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case REQUEST_FOR_BLOOD:
      return {
        ...state,
        addBloodRequestObj: {
          loading: action.loading === false ? action.loading : true,
          data: [],
          error: null,
        },
      };

    case REQUEST_FOR_BLOOD_SUCCESS:
      return {
        ...state,
        addBloodRequestObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case REQUEST_FOR_BLOOD_FAILED:
      return {
        ...state,
        addBloodRequestObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default peopleReducer;
