import React from 'react';
import { BloodGroupChartComponent } from './BloodGroupChart';
import { DonationChart } from './DonationChart';
import { InventoryChart } from './InventoryChart';
import { LineChartComponent } from './LineChart';

export const Charts = (props) => {
  const renderChart = (curProps) => {
    switch (curProps.chartType) {
      case 'donation-chart':
        return <DonationChart {...curProps} />;
      case 'inventory-chart':
        return <InventoryChart {...curProps} />;
      case 'line-chart':
        return <LineChartComponent {...curProps} />;
      case 'blood-group-chart':
        return <BloodGroupChartComponent {...curProps} />;
    }
  };

  return renderChart(props);
};
