import React, { useEffect, useState } from 'react';
import LabSamplesView from '../../views/LabResultView/LabSamplesView';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLabSamples,
  acceptLabSamples,
  triggerPostLabResult,
  getLabCriteriaResult,
} from '../../redux/actions/labResultAction';
import { validateLabResult } from '../../utils';
import { FailPopup, SuccessPopup } from '../../components';
import { POST_LAB_RESULTS } from '../../redux/actions/actionTypes';
function LabsSamples() {
  const headerArray = [
    '',
    'Sample ID',
    'Donor ID',
    'Collected on',
    'Bag ID',
    'Received on',
    'Received From',
    '',
  ];

  // const resultsArr = [
  //   {
  //     parameter: 'RBC',
  //     observation: '',
  //     remarks: '',
  //     standard: '4.5-5.5*10^12',
  //   },
  //   {
  //     parameter: 'WBC',
  //     observation: '',
  //     remarks: '',
  //     standard: '4-5*10^9',
  //   },
  //   {
  //     parameter: 'Platelet',
  //     observation: '',
  //     remarks: '',
  //     standard: '150-400*10^9',
  //   },
  //   {
  //     parameter: 'ESR',
  //     observation: '',
  //     remarks: '',
  //     standard: '0-20 mm/Hr',
  //   },
  //   {
  //     parameter: 'TLC',
  //     observation: '',
  //     remarks: '',
  //     standard: '4000-11000/mm^3',
  //   },
  // ];
  const dispatch = useDispatch();

  const { getLabResultSamples, postLabResultObj, labCriteriaChange } =
    useSelector((state) => state.labResultReducer);

  // let resultsArr = [];

  const [chheckboxIds, setchheckboxIds] = useState([]);
  const [showResultOption, setShowResultOption] = useState(false);
  const [result, SetResult] = useState('Fail');
  const [showLabOption, setShowLabOption] = useState(false);
  const [laboratory, setLaboratory] = useState('Laboratory');
  // const [resultsArray, setResultsArray] = useState(resultsArr);
  const [resultsArray, setResultsArray] = useState([]);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [postError, setPostError] = useState('All Fileds Is Required');
  const [labPostObj, setLabPostObj] = useState({});
  const [showMultipleAddPopup, setShowMultipleAddPopup] = useState(false);
  const [acceptedData, setAcceptedData] = useState(null);
  const [singleObj, setSingleObj] = useState({});
  const [selctedDate, setSelectedDate] = useState('');
  const [successPopup, setSuccessPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [failedPopup, setFailedPopup] = useState(false);

  // const [labCriteriaChangeObject, setlabCriteriaChangeObject] = useState([]);

  useEffect(() => {
    dispatch(getLabSamples());
    dispatch(getLabCriteriaResult());
  }, []);

  useEffect(() => {
    if (labCriteriaChange?.data?.content?.data) {
      setResultsArray(labCriteriaChange?.data?.content?.data);
    }
  }, [labCriteriaChange]);

  useEffect(() => {
    if (postLabResultObj?.data?.code == 200) {
      setSuccessPopup(true);
      setSuccessMsg(postLabResultObj.data.content.message);
    }
  }, [postLabResultObj]);
  let abc = [];
  resultsArray.map((item) => {
    abc.push({
      parameter: item.name ? item.name : '',
      observation: '',
      remarks: '',
      standard:
        item.min && item.max && item.unit
          ? `${item.min}-${item.max}/ ${item.unit}`
          : '',
    });
  });

  const onSingleAccept = (data) => {
    if (data && data.status === 3) {
      dispatch(
        acceptLabSamples({ ids: [data._id] }, () => {
          dispatch(getLabSamples());
        })
      );
    } else {
      setSingleObj(data);
      setShowAddPopup(!showAddPopup);
    }
  };

  function getSelectedDate(date) {
    setSelectedDate(date);
  }

  const onContinueClick = () => {
    setSuccessPopup(false);
    dispatch({ type: POST_LAB_RESULTS, loading: false });
  };

  const onAcceptAll = () => {
    let myArrayFiltered = getLabResultSamples?.data?.content?.data?.filter(
      (el) => {
        return chheckboxIds.some((f) => {
          return f === el._id;
        });
      }
    );

    let arrayHasAcceptedStatus =
      myArrayFiltered && myArrayFiltered.some((f) => f.status === 4);

    if (arrayHasAcceptedStatus) {
      //   "ids ....",
      //   myArrayFiltered.filter((data) => {
      //     return data?.status === 4;
      //   })
      // );
      setAcceptedData(
        myArrayFiltered.filter((data) => {
          return data?.status === 4;
        })
      );
      setShowMultipleAddPopup(true);
    } else {
      dispatch(
        acceptLabSamples({ ids: chheckboxIds }, () => {
          setchheckboxIds([]);
          dispatch(getLabSamples());
        })
      );
    }
  };

  const onCancelPopup = () => {
    setShowMultipleAddPopup(false);
  };
  const onCheckClick = (data) => {
    let checkboxIds = chheckboxIds;
    let isAvailable = checkboxIds.filter((d, i) => {
      return d == data;
    });

    if (isAvailable && isAvailable.length) {
      for (var i = 0; i < checkboxIds.length; i++) {
        if (checkboxIds[i] === data) {
          checkboxIds.splice(i, 1);
        }
      }
    } else {
      checkboxIds.push(data);
    }

    setchheckboxIds((prevIds) => [...checkboxIds]);
  };

  const showAddResultPopup = () => {
    setPostError('');
    setShowAddPopup(!showAddPopup);
  };

  const onChangeText = (e) => {
    let updatedLabObj = labPostObj;
    updatedLabObj[e.target.name] = e.target.value;
    setLabPostObj(updatedLabObj);
  };

  const onResultSelect = (data) => {
    SetResult(data);
    setShowResultOption(false);
  };

  const onSubmit = () => {
    let modifyResultArray = [];
    resultsArray.map((ele) => {
      modifyResultArray.push({
        parameter: ele.name,
        observation: ele.observation,
        remarks: ele.remarks,
        standard: `${ele.min}-${ele.max}`,
        id: ele.id,
      });
    });
    let obj = {
      inventoryId: singleObj.inventoryId,
      donorId: singleObj.donorId,
      bloodGroup: labPostObj.bloodGroup,
      collectedDate: singleObj.collectionDate,
      labResults: {
        reportNo: labPostObj.reportNo,
        // weight: labPostObj.weight,
        // bloodPressure: labPostObj.bloodPressure,
        // Hb: labPostObj.Hb,
        // laboratory: laboratory,
        testedBy: labPostObj.testedBy,
        addedAt: selctedDate,
        // results: resultsArray, // for modification existing arrya
        results: modifyResultArray,
        finalResult: result,
        comments: labPostObj.comments,
      },
    };

    if (validateLabResult(obj, setPostError)) {
      setPostError('');
      return dispatch(
        triggerPostLabResult({ labs: [obj] }, () => {
          setShowAddPopup(!showAddPopup);
          dispatch(getLabSamples());
          // dispatch(getLabResults());
        })
      );
    }
  };

  const onLaboratorySelect = (data) => {
    setLaboratory(data);
    setShowLabOption(false);
  };

  const onResultArrEdit = (e, i) => {
    let resultArray = resultsArray;
    resultArray[i][e.target.name] = e.target.value;
    setResultsArray(resultArray);
  };

  return (
    <>
      {successPopup && (
        <SuccessPopup title={successMsg} onContinueClick={onContinueClick} />
      )}
      {failedPopup && (
        <FailPopup onContinueClick={() => setFailedPopup(false)} />
      )}
      <LabSamplesView
        // labCriteriaChangeObj={labCriteriaChange?.data?.content?.data} // set lab  criteria
        getLabResultSamples={getLabResultSamples}
        headerArray={headerArray}
        onSingleAccept={onSingleAccept}
        onCheckClick={onCheckClick}
        chheckboxIds={chheckboxIds}
        onAcceptAll={onAcceptAll}
        showMultipleAddPopup={showMultipleAddPopup}
        acceptedData={acceptedData}
        onCancelPopup={onCancelPopup}
        singleObj={singleObj}
        showAddPopup={showAddPopup}
        showAddResultPopup={showAddResultPopup}
        onChangeText={onChangeText}
        onSubmit={onSubmit}
        showResultOption={showResultOption}
        onResultSelect={onResultSelect}
        setShowResultOption={setShowResultOption}
        result={result}
        onLaboratorySelect={onLaboratorySelect}
        setShowLabOption={setShowLabOption}
        laboratory={laboratory}
        showLabOption={showLabOption}
        resultsArray={abc}
        onResultArrEdit={onResultArrEdit}
        postError={postError}
        postLabResultObj={postLabResultObj}
        getSelectedDate={getSelectedDate}
      />
    </>
  );
}

export default LabsSamples;
