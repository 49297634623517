import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  postLabResultsSettings,
  getLabResultsSettings,
  deleteLabFromSetting,
} from '../../redux/actions';
import { REMOVE_LAB_FROM_SETTINGS } from '../../redux/actions/actionTypes';
import { DeletePopup } from '../Popups/DeletePopup';
import { FailPopup } from '../Popups/FailPopup';
import { SuccessPopup } from '../Popups/SuccessPopup';

function AssociateLabs(props) {
  let {} = props;
  const dispatch = useDispatch();
  const { getLabsSettingsObj, postLabsSettingsObj } = useSelector(
    (state) => state.settingReducer
  );
  const [showLabsInput, setShowLabsInput] = useState(false);
  const [deletePopup, setShowDeletePopup] = useState(false);

  const labObj = {
    firstName: '',
    lastName: '',
    email: '',
    designation: 'labMember',
    labName: '',
  };

  const [labCred, setLabCred] = useState(labObj);
  const [errorMsg, setError] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [sucessPopup, setShowSuccessPopup] = useState(false);
  const [failedPopup, setShowFailedPopup] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState('');
  const [failPopUpMsg, setFailPopUpMsg] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (postLabsSettingsObj?.data?.code === 200) {
      setPopUpMsg(postLabsSettingsObj.data.content.message);
      setShowDeletePopup(false);
      setShowSuccessPopup(true);
      setLoading(false);
    } else if (postLabsSettingsObj?.error) {
      setShowFailedPopup(true);
      setLoading(false);
      setShowDeletePopup(false);
      setFailPopUpMsg(
        'You are trying to use an mail id which is already in use'
      );
    }
  }, [postLabsSettingsObj]);
  const onChangeText = (e) => {
    let updatedObj = labCred;
    updatedObj[e.target.name] = e.target.value;
    setLabCred(updatedObj);
  };
  const removeLab = () => {
    let obj = {
      id: selectedId,
    };

    let index = getLabsSettingsObj?.data?.content?.members.findIndex(
      (data) => data._id === selectedId
    );
    let list = [...getLabsSettingsObj?.data?.content?.members];
    setLoading(true);
    dispatch(
      deleteLabFromSetting(obj, () => {
        getLabsSettingsObj?.data?.content?.members.splice(index, 1);
      })
    );
  };
  const onSubmit = () => {
    if (
      labCred.firstName !== '' &&
      labCred.email !== '' &&
      labCred.labName !== ''
    ) {
      setError('');
      return dispatch(
        postLabResultsSettings(labCred, () => {
          setShowLabsInput(false);
          return dispatch(getLabResultsSettings());
        })
      );
    } else {
      setError('Enter valid data');
    }
  };

  const showDeletePopup = (data) => {
    setShowDeletePopup(true);
    setSelectedId(data._id);
  };

  function onContinueClick() {
    setShowSuccessPopup(false);
    dispatch({
      type: REMOVE_LAB_FROM_SETTINGS,
      loading: false,
    });
  }

  return (
    <>
      {deletePopup && (
        <DeletePopup
          onCancelClick={() => setShowDeletePopup(false)}
          onDeleteClick={removeLab}
          loading={loading}
        />
      )}
      {sucessPopup && (
        <SuccessPopup title={popUpMsg} onContinueClick={onContinueClick} />
      )}

      {failedPopup && (
        <FailPopup
          title={failPopUpMsg}
          onContinueClick={() => setShowFailedPopup(false)}
        />
      )}
      <div className='bd-rgt__lab-equipments'>
        <div className='bd-associated__labs'>
          <div className='bd-step__head'>
            <span>Associated Labs</span>
          </div>
          <div className='bd-tbl'>
            <div className='bd-tbl__head'>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>#</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>Lab Name</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>Username</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>Email</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span></span>
                </div>
              </div>
            </div>
            <div className='bd-tbl__body'>
              {getLabsSettingsObj?.data?.content?.members?.map(
                (data, index) => {
                  return (
                    <div className='bd-tbl__row'>
                      <div className='bd-tbl__cell'>
                        <span>{index + 1}.</span>
                      </div>
                      <div className='bd-tbl__cell'>
                        <span>{data.labName}</span>
                      </div>
                      <div className='bd-tbl__cell'>
                        <span>{data.firstName}</span>
                      </div>
                      <div className='bd-tbl__cell'>
                        <span>{data.email}</span>
                      </div>
                      <div className='bd-tbl__cell'>
                        <span onClick={() => showDeletePopup(data)}>
                          <i className='bluddelete'></i>
                        </span>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className='bd-add__labs--btn'>
            <span
              onClick={() => {
                setShowLabsInput(true);
              }}
            >
              <i className='bludplus'></i>Add more labs
            </span>
          </div>
        </div>
        {showLabsInput && (
          <div className='bd-add__labs'>
            <div className='bd-step__head'>
              <span>Add Labs</span>
            </div>
            <div className='bd-form'>
              <div className='bd-form__row'>
                <input
                  type='text'
                  placeholder='Lab Name'
                  name='labName'
                  onChange={(e) => onChangeText(e)}
                />
              </div>
              <div className='bd-form__row bd-add__labs--userpass'>
                <div className='bd-form__row'>
                  <input
                    type='text'
                    placeholder='Name of representative'
                    name='firstName'
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
                <div className='bd-form__row'>
                  <input
                    type='text'
                    placeholder='Email'
                    name='email'
                    onChange={(e) => onChangeText(e)}
                  />
                </div>
              </div>
              <div className='bd-add-cancel__labs--btn'>
                <span
                  className='bd-btn bd-btn--outline'
                  onClick={() => {
                    setShowLabsInput(false);
                  }}
                >
                  Cancel
                </span>
                <span onClick={onSubmit} class='bd-btn bd-btn--primary'>
                  Add
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AssociateLabs;
