import { makeAPICall } from '../../utils';
import {
  API_BASE_PATH,
  GET_SETTINGS_URL,
  GET_LAB_RESULTS_SETTINGS_URL,
  ADD_LAB_RESULTS_SETTINGS_URL,
  POST_SETTINGS_URL,
  UPLOAD_SETTINGS_INVENTORY_URL,
  UPLOAD_SETTINGS_DONOR_URL,
  REMOVE_LAB_SETTINGS_URL,
  POST_LAB_CRITERIA_API,
  // UPLOAD_SETTINGS_INVENTORY_URL,
  // UPLOAD_SETTINGS_DONOR_URL,
} from '../../utils/config';
import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  POST_SETTINGS,
  POST_SETTINGS_SUCCESS,
  POST_SETTINGS_FAILED,
  GET_LAB_RESULT_SETTINGS,
  GET_LAB_RESULT_SETTINGS_SUCCESS,
  GET_LAB_RESULT_SETTINGS_FAILED,
  POST_LAB_RESULT_SETTINGS,
  POST_LAB_RESULT_SETTINGS_SUCCESS,
  POST_LAB_RESULT_SETTINGS_FAILED,
  UPDATE_SETTINGS,
  ADD_HOLIDAYS,
  ADD_MONTHLY_INVENTORY,
  UPLOAD_SETTINGS_INVENTORY,
  UPLOAD_SETTINGS_INVENTORY_SUCCESS,
  UPLOAD_SETTINGS_INVENTORY_FAILED,
  UPLOAD_SETTINGS_DONOR,
  UPLOAD_SETTINGS_DONOR_SUCCESS,
  UPLOAD_SETTINGS_DONOR_FAILED,
  POST_LAB_CRITERIA,
  POST_LAB_CRITERIA_SUCCESS,
  POST_LAB_CRITERIA_FAILED,
} from './../actions/actionTypes';

export function getSettings(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_SETTINGS_URL}`,
      dispatch,
      defaultAction: GET_SETTINGS,
      successAction: GET_SETTINGS_SUCCESS,
      failedAction: GET_SETTINGS_FAILED,
      headers: {},
      params: obj,
      callback,
      type: 'GET',
    };
    makeAPICall(params);
  };
}
export function submitSettings(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_SETTINGS_URL}`,
      dispatch,
      defaultAction: POST_SETTINGS,
      successAction: POST_SETTINGS_SUCCESS,
      failedAction: POST_SETTINGS_FAILED,
      headers: {},
      params: obj,
      callback,
      type: 'POST',
    };
    makeAPICall(params);
  };
}

export function getLabResultsSettings(obj) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_LAB_RESULTS_SETTINGS_URL}?designation=labMember`,
      dispatch,
      defaultAction: GET_LAB_RESULT_SETTINGS,
      successAction: GET_LAB_RESULT_SETTINGS_SUCCESS,
      failedAction: GET_LAB_RESULT_SETTINGS_FAILED,
      headers: {},
      type: 'GET',
    };
    makeAPICall(params);
  };
}

export function postLabResultsSettings(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ADD_LAB_RESULTS_SETTINGS_URL}`,
      dispatch,
      defaultAction: POST_LAB_RESULT_SETTINGS,
      successAction: POST_LAB_RESULT_SETTINGS_SUCCESS,
      failedAction: POST_LAB_RESULT_SETTINGS_FAILED,
      headers: {},
      params: obj,
      callback: callback,
      type: 'POST',
    };
    makeAPICall(params);
  };
}

export function updatedSettings(data) {
  return {
    type: UPDATE_SETTINGS,
    payload: data,
  };
}

export function addHolidays(data, currSelected) {
  return {
    type: ADD_HOLIDAYS,
    payload: data,
    currSelected: currSelected,
  };
}
export function addMonthlyInventory(data) {
  return {
    type: ADD_MONTHLY_INVENTORY,
    payload: data,
  };
}

export function uploadSettingsInventory(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${UPLOAD_SETTINGS_INVENTORY_URL}`,
      dispatch,
      defaultAction: UPLOAD_SETTINGS_INVENTORY,
      successAction: UPLOAD_SETTINGS_INVENTORY_SUCCESS,
      failedAction: UPLOAD_SETTINGS_INVENTORY_FAILED,
      headers: {},
      params: obj,
      callback,
      type: 'POST',
    };
    makeAPICall(params);
  };
}

export function uploadSettingsDonors(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${UPLOAD_SETTINGS_DONOR_URL}`,
      dispatch,
      defaultAction: UPLOAD_SETTINGS_DONOR,
      successAction: UPLOAD_SETTINGS_DONOR_SUCCESS,
      failedAction: UPLOAD_SETTINGS_DONOR_FAILED,
      headers: {},
      params: obj,
      callback,
      type: 'POST',
    };
    makeAPICall(params);
  };
}

// POST LAB CRITERIA FROM  SETTING -- SUDARSHAN
export function postLabCriterias(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_LAB_CRITERIA_API}`,
      dispatch,
      defaultAction: POST_LAB_CRITERIA,
      successAction: POST_LAB_CRITERIA_SUCCESS,
      failedAction: POST_LAB_CRITERIA_FAILED,
      headers: {},
      params: obj,
      callback,
      type: 'POST',
    };
    makeAPICall(params);
  };
}

export function deleteLabFromSetting(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REMOVE_LAB_SETTINGS_URL}?id=${obj.id}`,
      dispatch,
      defaultAction: POST_LAB_RESULT_SETTINGS,
      successAction: POST_LAB_RESULT_SETTINGS_SUCCESS,
      failedAction: POST_LAB_RESULT_SETTINGS_FAILED,
      headers: {},
      params: obj,
      callback: callback,
      type: 'GET',
    };
    makeAPICall(params);
  };
}
