import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { CheckBox } from '..';

function Set_Lab_Creiterai({
  setOpenCriteriaPopUp,
  labCriteriaComponents,
  setSelectedColumns,
  // name2,
}) {
  const Data = labCriteriaComponents;
  const [LabList, setLabList] = useState([]);

  let tempLabList = [];
  function onCheckboxChange(index, e) {
    const objUpdate = LabList.findIndex((obj) => obj.id === index);
    LabList[objUpdate].checked = e.target.checked;
    setLabList(tempLabList);
  }

  function onApproveCriteria() {
    let columnsToHide = [];
    LabList.filter((ele) => {
      if (!ele.checked) {
        columnsToHide.push({
          id: ele.id,
          checked: ele.checked,
        });
      }
    });

    setSelectedColumns(columnsToHide);
    setOpenCriteriaPopUp(false);
  }

  useEffect(() => {
    if (Data) setLabList(Data);
  }, [Data, tempLabList, labCriteriaComponents]);

  return (
    <div className='bd-modal-wrap .bd-modal-right'>
      <div className='bd-popup .si-fade-in bd-popup_Lab_Criteria'>
        <div className='bd-popup__head style-case'>
          <span>Select Lab Criteria Which you want to show/hide</span>
        </div>
        <div>
          <div className='Lab-criteria-inside style-case'>
            <div></div>
            <div>Parameter</div>
            {/* <div>Min</div>
            <div>Max</div>
            <div>Units</div> */}
          </div>
          <div
            style={{ borderBottom: '1px solid hsl(0deg 64% 44% / 20%)' }}
          ></div>
        </div>
        {LabList.map((cell, index) => {
          if (!cell.disable) {
            return (
              <div>
                <div className='Lab-criteria-inside'>
                  <div>
                    <CheckBox
                      checked={cell.checked}
                      onChange={onCheckboxChange.bind(this, index)}
                    />
                  </div>
                  <div>{cell.name}</div>
                  <div>{cell.id}</div>
                  {/* <div>{cell.min}</div>
                  <div>{cell.max}</div>
                  <div>{cell.unit}</div> */}
                </div>
                <div
                  style={{ borderBottom: '1px solid hsl(0deg 64% 44% / 20%)' }}
                ></div>
              </div>
            );
          }
        })}
        <div className='bd-form__row'>
          <label></label>
          {/* <input placeholder='comment' type='text' name='organiser' /> */}
        </div>

        <div className='bd-btn__wrap'>
          <div
            class='bd-btn bd-btn--outline'
            onClick={() => setOpenCriteriaPopUp(false)}
          >
            <span>Cancel </span>
          </div>

          <div className='bd-btn  bd-btn--primary' onClick={onApproveCriteria}>
            <span>Aprrove</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Set_Lab_Creiterai;
