import React from "react";

export function FailPopup ({ onContinueClick, title }) {
  return (
    <div className="bd-modal-wrap">
      <div className="bd-popup si-fade-in bd-addinventory">
        <div className="bd-popup__body">
          <div className="bd-addinvent_wrap">
            <div className="bd-addinvent__top db-bg__red">
              <i className="bludclose"></i>
              <span>FAILED</span>
            </div>
            <div className="bd-addinvent__cont">
              <p className="fail-title">{title ? title : ""}</p>
              <div className="bd-btn__wrap" onClick={onContinueClick}>
                <span className="bd-btn bd-btn--primary db-bg__red">Continue</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bd-popup__lightbox"></div>
    </div>
  );
};
