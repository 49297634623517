import {
  GET_INSTITUTE_PROFILE,
  GET_INSTITUTE_PROFILE_FAILED,
  GET_INSTITUTE_PROFILE_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  profileObj: {
    loading: false,
    data: null,
    error: null,
  },
};
const profileReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_INSTITUTE_PROFILE:
      return {
        ...state,
        profileObj: {
          loading: true,
          data: {},
          error: null,
        },
      };

    case GET_INSTITUTE_PROFILE_SUCCESS:
      return {
        ...state,
        profileObj: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case GET_INSTITUTE_PROFILE_FAILED:
      return {
        ...state,
        profileObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default profileReducer;
