import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import { CAMP_ACCEPTED_TABLE, NGO_ONGOING_TABLE } from '../../utils/constants';
import { getCampsListData } from '../../redux/actions/campsAction';

function AcceptedCampView({
  acceptedCampList,
  onHeaderClick,
  institutionType,
}) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  useEffect(() => {
    dispatch(getCampsListData());
  }, []);

  return (
    <>
      <div className='bd-tbl__inventory table-scroll'>
        <Tables
          tab={'AcceptedCamp'}
          tableType={'table-type-1'}
          // columns={NGO_ONGOING_TABLE}
          columns={
            institutionType !== 'NGO' ? CAMP_ACCEPTED_TABLE : NGO_ONGOING_TABLE
          }
          // tableData={acceptedCampList}
          tableData={acceptedCampList.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          headerClick={onHeaderClick}
        />
      </div>
      <TablePagination
        component='div'
        count={acceptedCampList.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

export default AcceptedCampView;
