import React from 'react';
import moment from 'moment';
import { ageCalculation } from '../../utils';
import download from '../../assets/icon/download.svg';

export default function VerifyPopup({
  data,
  verifyClick,
  declineClick,
  editClick,
  setVerify,
}) {
  let {
    details,
    bloodGroup,
    component,
    patientId,
    reasons,
    condition,
    doctorName,
    units,
    requiredBy,
    comments,
    attachment,
  } = data;

  return (
    <div className='bd-modal-wrap bd-modal-right bd-addinvent2 verifyPoup-addinvent2'>
      <div className='bd-popup si-fade-in verifyPoup'>
        <div className='bd-popup__head'>
          <span>For Verification</span>
          <i className='bludclose' onClick={() => setVerify(false)}></i>
        </div>
        <div className='bd-tbl bd-tbl__theme2'>
          <div className='bd-popup__body'>
            <div className='bd-edit' onClick={editClick}>
              Edit
            </div>
            <div className='bd-form'>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Name:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>
                    {details.firstName ? details.firstName : ''}{' '}
                    {details.lastName ? details.lastName : ''}
                  </span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Mobile No:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>
                    {details.countryCode ? details.countryCode : ''}{' '}
                    {details.mobileNumber ? details.mobileNumber : ''}
                  </span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Blood Group</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>{bloodGroup}</span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Component:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>{component}</span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Age:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>
                    {details.dateOfBirth
                      ? ageCalculation(details.dateOfBirth)
                      : ''}
                  </span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Patient ID:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>{patientId}</span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Purpose:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>{reasons}</span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Condition:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>{condition}</span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Doctor:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>{doctorName}</span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Units:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>{units}</span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Required By:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>
                    {moment(new Date(requiredBy)).format('DD MMM YYYY')}
                  </span>
                </div>
              </div>
              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Comments:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <span>{comments}</span>
                </div>
              </div>

              <div className='bd-tbl__row'>
                <div className='bd-tbl__cell'>
                  <span>Attachment:</span>
                </div>
                <div className='bd-tbl__cell'>
                  <div className='bd-attachment__file m-unset'>
                    {attachment.length > 0
                      ? attachment.map((attachment) => {
                          return (
                            <a
                              className='download-a'
                              download
                              href={attachment.location}
                            >
                              <img
                                className='download-img'
                                src={download}
                                alt='download'
                              />
                              <div className='attachment-name'>
                                {attachment.name}
                              </div>
                            </a>
                          );
                        })
                      : 'No Attachments'}
                  </div>
                </div>
              </div>
            </div>
            <div className='bd-btn__wrap mt-10 '>
              <div
                className='bd-btn bd-btn--outline bd-btn--accept active'
                userId={data._id}
                onClick={verifyClick}
              >
                <span userId={data._id}>
                  <i userId={data._id} className='bludAccept' />
                  Verify
                </span>
              </div>
              <div
                userId={data._id}
                className='bd-btn bd-btn--primary'
                onClick={declineClick}
              >
                <span userId={data._id}>Decline</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
