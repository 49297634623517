import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {triggerPatientList} from "../../redux/actions/addPatientAction";
import PatientView from "../../views/PatientView";
export default function PatientContainer({ }) {
  const dispatch = useDispatch();
  const {patientList} = useSelector((state) => state.addPatientReducer);

  const [patientsList, setPatientsList] = useState([patientList.data]);

  useEffect(() => {
    setPatientsList(patientList.data);
  }, [patientList.data]);

  useEffect(() => {
    dispatch(triggerPatientList());
  }, []);

  function headerDropDownChange(e) {
    let data = [...patientList.data];
    let arr=[];
    if (e.value === "") {
      arr = [...patientList.data];
    } else {
      arr = data.filter((a) => a.bloodGroup === e.value);
    }
    setPatientsList(arr);
  }


  return (
    <>
      <PatientView patientsList={patientsList} headerDropDownChange={headerDropDownChange} />
    </>
  );
}
