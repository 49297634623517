import React from "react";
import loader from "../../assets/icon/loader.gif";
function Loader() {
  return (
    <div className="bd-modal-wrap bd-modal-right bd-request-blood">
      <div className="spinner">
        <img src={loader} className="loader-img" />
      </div>
      <div className="bd-popup__lightbox"></div>
    </div>
  );
}

export { Loader };
