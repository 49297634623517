import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPeopleDonorList,
  getPeopleRecepientList,
} from '../../redux/actions/peopleAction';
import PeopleView from '../../views/PeopleView';

const PeopleContainer = () => {
  const dispatch = useDispatch();
  // const [activeTab, setActiveTab] = useState('Donors');
  useEffect(() => {
    dispatch(getPeopleDonorList());
    dispatch(getPeopleRecepientList({ type: 'recepients' }));
  }, []);

  const { peopleDonorObj, peopleRecepientObj } = useSelector(
    (state) => state.peopleReducer
  );

  return (
    <>
      <PeopleView
        peopleRecepientObj={peopleRecepientObj}
        peopleDonorObj={peopleDonorObj}
      />
    </>
  );
};

export default PeopleContainer;
