import React from 'react';

export function Confirmation({
  onCancleClick,
  onDisposeClick,
  title,
  btnName,
}) {
  return (
    <>
      <div class='bd-modal-wrap'>
        <div class='bd-popup si-fade-in bd-addinventory'>
          <div class='bd-popup__body'>
            <div class='bd-addinvent_wrap'>
              <div class='bd-addinvent__top db-bg__red'>
                <i class='bludquestionmark'></i>
              </div>
              <div class='bd-addinvent__cont'>
                <p>{title ? title : ''}</p>
                <div class='bd-btn__wrap mt-10'>
                  <span class='bd-btn bd-btn--outline' onClick={onCancleClick}>
                    Cancel
                  </span>
                  <span
                    class=' bd-btn bd-btn--primary'
                    onClick={onDisposeClick}
                  >
                    {btnName ? btnName : 'Done'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='bd-popup__lightbox'></div>
      </div>
    </>
  );
}
