import { makeAPICall, urlSearchParams } from '../../utils';
// import { urlSearchParams } from '../../utils';

import {
  API_BASE_PATH,
  LOGIN_URL,
  SUPER_ADMIN_URL,
  REGISTER_URL,
  GET_HOSPITAL_LIST_URL,
  SET_PASSWORD_URL,
  FORGOT_PASSWORD_URL,
  VALIDATE_OTP_URL,
  RESET_PASSWORD_URL,
  POST_NEW_INSTITUTION_URL,
  SET_PASSWORD_PATIENT_URL,
  STATE_AND_STATE_URL,
  SUPER_ADMIN_SET_PASSWORD_URL,
} from '../../utils/config';
import {
  TRIGGER_LOGIN,
  TRIGGER_LOGIN_SUCCESS,
  TRIGGER_LOGIN_FAILED,
  TRIGGER_LOGOUT,
  TRIGGER_REGISTER,
  TRIGGER_REGISTER_SUCCESS,
  TRIGGER_REGISTER_FAILED,
  TRIGGER_SET_PASSWORD,
  TRIGGER_SET_PASSWORD_SUCCESS,
  TRIGGER_SET_PASSWORD_FAILED,
  TRIGGER_GET_INSTITUTE_LIST,
  TRIGGER_GET_INSTITUTE_LIST_SUCCESS,
  TRIGGER_GET_INSTITUTE_LIST_FAILED,
  TRIGGER_FORGET_PASSWORD,
  TRIGGER_FORGET_PASSWORD_SUCCESS,
  TRIGGER_FORGET_PASSWORD_FAILED,
  SET_EMAIL_FOR_OTP,
  TRIGGER_FORGET_SET_PASSWORD,
  TRIGGER_FORGET_SET_PASSWORD_SUCCESS,
  TRIGGER_FORGET_SET_PASSWORD_FAILED,
  SET_OTP_SET_PASSWORD,
  TRIGGER_OTP,
  TRIGGER_OTP_SUCCESS,
  TRIGGER_OTP_FAILED,
  POST_NEW_INSTITUTION,
  POST_NEW_INSTITUTION_SUCCESS,
  POST_NEW_INSTITUTION_FAILED,
  RESET_SET_PASSWORD,
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_FAILED,
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_FAILED,
} from './actionTypes';

// SAME ACTION FOR LOGIN AND SUPER ADMIN LOGIN
export function triggerAuthLogin(obj, callback, type) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${type === 'Login' ? LOGIN_URL : SUPER_ADMIN_URL}`,
      dispatch,
      defaultAction: TRIGGER_LOGIN,
      successAction: TRIGGER_LOGIN_SUCCESS,
      failedAction: TRIGGER_LOGIN_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
      noToken: true,
    };
    makeAPICall(params);
  };
}

export function triggerAuthRegister(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REGISTER_URL}`,
      dispatch,
      defaultAction: TRIGGER_REGISTER,
      successAction: TRIGGER_REGISTER_SUCCESS,
      failedAction: TRIGGER_REGISTER_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
      noToken: true,
    };
    makeAPICall(params);
  };
}

export function triggerFetchInstituteList(param, type) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_HOSPITAL_LIST_URL}${param}&type=${type}`,
      dispatch,
      defaultAction: TRIGGER_GET_INSTITUTE_LIST,
      successAction: TRIGGER_GET_INSTITUTE_LIST_SUCCESS,
      failedAction: TRIGGER_GET_INSTITUTE_LIST_FAILED,
      type: 'GET',
      noToken: true,
    };
    makeAPICall(params);
  };
}

export function triggerAuthSetPassword(obj, callback, type) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${
        type === 'keymember'
          ? SET_PASSWORD_URL
          : type === 'admin'
          ? SUPER_ADMIN_SET_PASSWORD_URL
          : SET_PASSWORD_PATIENT_URL
      }`,
      dispatch,
      defaultAction: TRIGGER_SET_PASSWORD,
      successAction: TRIGGER_SET_PASSWORD_SUCCESS,
      failedAction: TRIGGER_SET_PASSWORD_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
      noToken: true,
    };
    makeAPICall(params);
  };
}

export function triggerForgotPassword(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${FORGOT_PASSWORD_URL}`,
      dispatch,
      defaultAction: TRIGGER_FORGET_PASSWORD,
      successAction: TRIGGER_FORGET_PASSWORD_SUCCESS,
      failedAction: TRIGGER_FORGET_PASSWORD_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
      noToken: true,
    };
    makeAPICall(params);
  };
}

export function triggerValidateOTP(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${VALIDATE_OTP_URL}`,
      dispatch,
      defaultAction: TRIGGER_OTP,
      successAction: TRIGGER_OTP_SUCCESS,
      failedAction: TRIGGER_OTP_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback: callback,
      noToken: true,
    };
    makeAPICall(params);
  };
}

export function triggerForgotSETPassword(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${RESET_PASSWORD_URL}`,
      dispatch,
      defaultAction: TRIGGER_FORGET_SET_PASSWORD,
      successAction: TRIGGER_FORGET_SET_PASSWORD_SUCCESS,
      failedAction: TRIGGER_FORGET_SET_PASSWORD_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
      noToken: true,
    };
    makeAPICall(params);
  };
}
export function postNewInstitution(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_NEW_INSTITUTION_URL}`,
      dispatch,
      defaultAction: POST_NEW_INSTITUTION,
      successAction: POST_NEW_INSTITUTION_SUCCESS,
      failedAction: POST_NEW_INSTITUTION_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
      noToken: true,
    };
    makeAPICall(params);
  };
}

export function triggerLogout() {
  return {
    type: TRIGGER_LOGOUT,
  };
}

export function triggerSetEmailForOTP(payload) {
  return {
    type: SET_EMAIL_FOR_OTP,
    payload: payload,
  };
}

export function triggerSetOTP(payload) {
  return {
    type: SET_OTP_SET_PASSWORD,
    payload: payload,
  };
}

export function resetSetPassword() {
  return {
    type: RESET_SET_PASSWORD,
  };
}

export function getStateList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${STATE_AND_STATE_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_STATE,
      successAction: GET_STATE_SUCCESS,
      failedAction: GET_STATE_FAILED,
      type: 'GET',
      headers: {},
      callback,
      noToken: true,
    };
    makeAPICall(params);
  };
}
export function getCityList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${STATE_AND_STATE_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_CITY,
      successAction: GET_CITY_SUCCESS,
      failedAction: GET_CITY_FAILED,
      type: 'GET',
      headers: {},
      callback,
      noToken: true,
    };
    makeAPICall(params);
  };
}

// export const triggerAuthLogin = (function (obj, callback) {
//   return (dispatch) => {
//     const params = {
//       url: `${API_BASE_PATH}${LOGIN_URL}`,
//       dispatch,
//       defaultAction: TRIGGER_LOGIN,
//       successAction: TRIGGER_LOGIN_SUCCESS,
//       failedAction: TRIGGER_LOGIN_FAILED,
//       type: "POST",
//       headers: {},
//       params: obj,
//       callback,
//     };
//     makeAPICall(params);
//   };
// })(obj, callback);
