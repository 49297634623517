import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNgoCampsListData,
  getCampsListData,
  triggerAddCamps,
  triggerFetchAddress,
  triggerFetchParticipatingBBlist,
} from '../../redux/actions/campsAction';
import { API_BASE_PATH, UPLOAD_FILE_URL } from '../../utils/config';
import axios from 'axios';
import NgoCreateCampView from '../../views/CampView/NgoCreateCampView';
const addNgoCreateCampsObj = {
  campName: '',
  // participateBB: '',
  institutionName: '',
  institutionId: '',
  bbName: '',
  organiser: '',
  date: '',
  fromTime: '',
  toTime: '',
  CoordinatorName: '',
  MobileNo: '',
  EmailId: '',
  venue: '',
  address: '',
  comments: '',
  attachments: [],
};

export default function NgoCreateCampContainer({
  // addCamps,
  toggleForm,
  setPopUpMsg,
  setShowSuccess,
  setShowFail,
}) {
  const dispatch = useDispatch();
  const [addCampsObj, setAddCampsObj] = useState(addNgoCreateCampsObj);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [addressFocused, setaddressFocused] = useState(false);
  const [bloodBankFocused, setBloodBankFocused] = useState(false);
  // const [didMount, setDidMount] = useState(false);

  const inputFile = useRef(null);
  const hiddenFileInput = React.useRef(null);
  const [attachments, setAttachments] = useState([]);

  const {
    campName,
    institutionName,
    institutionId,
    bbName,
    organiser,
    date,
    fromTime,
    toTime,
    CoordinatorName,
    MobileNo,
    EmailId,
    venue,
    address,
    comments,
  } = addCampsObj;

  useEffect(() => {
    dispatch(getCampsListData());
  }, []);

  function handleClick() {
    inputFile.current.click();
  }

  function deleteAttachment(e, index) {
    e.preventDefault();
    let files = [...addCampsObj?.attachments];
    files.splice(index, 1);
    setAttachments(files);
    setAddCampsObj({ ...addCampsObj, attachments: files });
  }

  const addFile = async (e) => {
    let selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    let response = await axios.post(API_BASE_PATH + UPLOAD_FILE_URL, formData, {
      headers: {
        token: `${localStorage.getItem('TOKEN_KEY')}`,
      },
    });
    let file = response.data.content.savedFiles[0];
    let arr = [...addCampsObj?.attachments];

    if (file) arr.push(file);
    setAddCampsObj({ ...addCampsObj, attachments: arr });
    setAttachments(arr);
  };

  function onChangeText(e, name) {
    let updateAddCampsObj = { ...addCampsObj };

    if (name == 'date') {
      updateAddCampsObj[name] = new Date(e);
    } else {
      updateAddCampsObj[e.target.name] = e.target.value;
    }
    setAddCampsObj(updateAddCampsObj);
  }

  const bloodBankList = 'bloodBank';
  function onBloodBankSearch(e) {
    if (e.target.value !== '') {
      onChangeText(e);
      return dispatch(
        triggerFetchParticipatingBBlist(e.target.value, bloodBankList)
      );
    }
  }
  function selectBloodBank(data) {
    setBloodBankFocused(false);
    let updateAddCampsObj;
    updateAddCampsObj = { ...addCampsObj };
    updateAddCampsObj['institutionName'] = data?.institutionName;
    updateAddCampsObj['institutionId'] = data?._id;
    setAddCampsObj(updateAddCampsObj);
  }

  ///// address Filed start -------
  const locationMap = 'location';

  function onAddressSearch(e) {
    if (e.target.value !== '') {
      onChangeText(e);
      return dispatch(triggerFetchAddress(e.target.value, locationMap));
    }
  }
  function selectAddress(data) {
    setaddressFocused(false);
    let updateAddCampsObj;
    updateAddCampsObj = { ...addCampsObj };
    updateAddCampsObj['address'] = data?.address;
    setAddCampsObj(updateAddCampsObj);
  }
  ///// address Filed end -------

  function CloseAddCamp() {
    toggleForm();
  }

  function onSubmit() {
    if (
      campName === '' ||
      institutionName === '' ||
      address === '' ||
      fromTime === '' ||
      toTime === '' ||
      date === '' ||
      venue === '' ||
      CoordinatorName === '' ||
      MobileNo === '' ||
      EmailId === '' ||
      bbName === ''
    ) {
      setShowFail(true);
      return 0;
    }

    let params = {
      campName,
      institutionName,
      institutionId: addCampsObj?.institutionId,
      bloodBankReference: bbName,
      organiser,

      campCoordinator: {
        name: CoordinatorName,
        mobileNumber: MobileNo,
        email: EmailId,
      },

      venue,
      address,
      comments,
      attachments,
    };

    if (fromTime && toTime && date) {
      params.campDuration = {
        startTime: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          fromTime.split(':')[0],
          fromTime.split(':')[1]
        ).toISOString(),
        endTime: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          toTime.split(':')[0],
          toTime.split(':')[1]
        ).toISOString(),
      };
    }

    return dispatch(
      triggerAddCamps(params, () => {
        toggleForm();
        dispatch(getNgoCampsListData());
        setShowSuccess(true);
        setPopUpMsg('Donation Camp Create successfully');
      })
    );
  }

  return (
    <NgoCreateCampView
      addCampsObj={addCampsObj}
      uploadingFile={uploadingFile}
      inputFile={inputFile}
      addressFocused={addressFocused}
      // participant blood bank
      bloodBankFocused={bloodBankFocused}
      setBloodBankFocused={setBloodBankFocused}
      onBloodBankSearch={onBloodBankSearch}
      selectBloodBank={selectBloodBank}
      //
      onCancelClick={CloseAddCamp}
      handleClick={handleClick}
      onChangeText={onChangeText}
      onSubmit={onSubmit}
      onAddressSearch={onAddressSearch}
      selectAddress={selectAddress}
      setaddressFocused={setaddressFocused}
      setAttachments={setAttachments}
      hiddenFileInput={hiddenFileInput}
      addFile={addFile}
      deleteAttachment={deleteAttachment}
    />
  );
}
