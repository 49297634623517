import {
  //RECALL_LOGIN
  RECALL_LOGIN,
  RECALL_LOGIN_FAILED,
  RECALL_LOGIN_SUCCESS,

  //DASHBOARD MOADULE
  DASHBOARD_TILES_DATA,
  DASHBOARD_TILES_DATA_FAILED,
  DASHBOARD_TILES_DATA_SUCCESS,
  DASHBOARD_STATE_CITY_DATA,
  DASHBOARD_STATE_CITY_DATA_FAILED,
  DASHBOARD_STATE_CITY_DATA_SUCCESS,
  // SUBCRIPRTION PLANS
  GET_SUBCRIPTION_AND_BLUDUNIT_LIST,
  GET_SUBCRIPTION_AND_BLUDUNIT_LIST_SUCCESS,
  GET_SUBCRIPTION_AND_BLUDUNIT_LIST_FAILED,
  GET_SUBCRIPTION_PLANS,
  GET_SUBCRIPTION_PLANS_SUCCESS,
  GET_SUBCRIPTION_PLANS_FAILED,
  POST_ADD_SUBACRIPTION_PLANS,
  POST_ADD_SUBACRIPTION_PLANS_SUCCESS,
  POST_ADD_SUBACRIPTION_PLANS_FAILED,
  // INSTITUTION
  GET_REGISTERED_AND_PENDING_DATA,
  GET_REGISTERED_AND_PENDING_DATA_SUCCESS,
  GET_REGISTERED_AND_PENDING_DATA_FAILED,
  GET_SINGLE_INSTITUTION_DETAIL,
  GET_SINGLE_INSTITUTION_DETAIL_SUCCESS,
  GET_SINGLE_INSTITUTION_DETAIL_FAILED,
  APPROVE_DECLINE_REQUEST,
  APPROVE_DECLINE_REQUEST_SUCCESS,
  APPROVE_DECLINE_REQUEST_FAILED,
  BLOCK_UNBLOCK_INSTITUTION,
  BLOCK_UNBLOCK_INSTITUTION_SUCCESS,
  BLOCK_UNBLOCK_INSTITUTION_FAILED,
  //GET_PRICING_LIST
  GET_PRICING_LIST,
  GET_PRICING_LIST_SUCCESS,
  GET_PRICING_LIST_FAILED,
} from '../actions/actionTypes';
// import { login } from '../../utils';

const initialState = {
  reCallLogin: {
    loading: false,
    data: [],
    error: null,
  },
  getPricingList: {
    loading: false,
    data: [],
    error: null,
  },
  getTileData: {
    loading: false,
    data: [],
    error: null,
  },
  getStateCityData: {
    loading: false,
    data: [],
    error: null,
  },
  getsubAndBludList: {
    loading: false,
    data: [],
    error: null,
  },
  getSubcriptionPlasList: {
    loading: false,
    data: [],
    error: null,
  },
  postAddPlan: {
    loading: false,
    data: [],
    error: null,
  },
  getInstituitonList: {
    loading: false,
    data: [],
    error: null,
  },
  getSingleDetails: {
    loading: false,
    data: [],
    error: null,
  },
  postApproveDecline: {
    loading: false,
    data: [],
    error: null,
  },
  postblockUnblockInstitution: {
    loading: false,
    data: [],
    error: null,
  },
};

// import { triggeReCallLogin } from '../../redux/actions/superAdminCommonActions';

// let getToken = localStorage.getItem('TOKEN');
// useEffect(() => {
//   dispatch(triggeReCallLogin(getToken));
// }, []);

const superAdminCommonReducers = function (state = initialState, action) {
  switch (action.type) {
    case RECALL_LOGIN:
      return {
        ...state,
        reCallLogin: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case RECALL_LOGIN_SUCCESS:
      return {
        ...state,
        reCallLogin: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case RECALL_LOGIN_FAILED:
      return {
        ...state,
        reCallLogin: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_PRICING_LIST:
      return {
        ...state,
        getPricingList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_PRICING_LIST_SUCCESS:
      return {
        ...state,
        getPricingList: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case GET_PRICING_LIST_FAILED:
      return {
        ...state,
        getPricingList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case DASHBOARD_STATE_CITY_DATA:
      return {
        ...state,
        getStateCityData: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case DASHBOARD_STATE_CITY_DATA_SUCCESS:
      return {
        ...state,
        getStateCityData: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case DASHBOARD_STATE_CITY_DATA_FAILED:
      return {
        ...state,
        getStateCityData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case DASHBOARD_TILES_DATA:
      return {
        ...state,
        getTileData: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case DASHBOARD_TILES_DATA_SUCCESS:
      return {
        ...state,
        getTileData: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case DASHBOARD_TILES_DATA_FAILED:
      return {
        ...state,
        getTileData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_SUBCRIPTION_AND_BLUDUNIT_LIST:
      return {
        ...state,
        getsubAndBludList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_SUBCRIPTION_AND_BLUDUNIT_LIST_SUCCESS:
      return {
        ...state,
        getsubAndBludList: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case GET_SUBCRIPTION_AND_BLUDUNIT_LIST_FAILED:
      return {
        ...state,
        getsubAndBludList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_SUBCRIPTION_PLANS:
      return {
        ...state,
        getSubcriptionPlasList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_SUBCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        getSubcriptionPlasList: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case GET_SUBCRIPTION_PLANS_FAILED:
      return {
        ...state,
        getSubcriptionPlasList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case POST_ADD_SUBACRIPTION_PLANS:
      return {
        ...state,
        postAddPlan: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case POST_ADD_SUBACRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        postAddPlan: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case POST_ADD_SUBACRIPTION_PLANS_FAILED:
      return {
        ...state,
        postAddPlan: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_REGISTERED_AND_PENDING_DATA:
      return {
        ...state,
        getInstituitonList: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_REGISTERED_AND_PENDING_DATA_SUCCESS:
      return {
        ...state,
        getInstituitonList: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case GET_REGISTERED_AND_PENDING_DATA_FAILED:
      return {
        ...state,
        getInstituitonList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_SINGLE_INSTITUTION_DETAIL:
      return {
        ...state,
        getSingleDetails: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_SINGLE_INSTITUTION_DETAIL_SUCCESS:
      return {
        ...state,
        getSingleDetails: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case GET_SINGLE_INSTITUTION_DETAIL_FAILED:
      return {
        ...state,
        getSingleDetails: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case APPROVE_DECLINE_REQUEST:
      return {
        ...state,
        postApproveDecline: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case APPROVE_DECLINE_REQUEST_SUCCESS:
      return {
        ...state,
        postApproveDecline: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case APPROVE_DECLINE_REQUEST_FAILED:
      return {
        ...state,
        postApproveDecline: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case BLOCK_UNBLOCK_INSTITUTION:
      return {
        ...state,
        postblockUnblockInstitution: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case BLOCK_UNBLOCK_INSTITUTION_SUCCESS:
      return {
        ...state,
        postblockUnblockInstitution: {
          loading: false,
          data: action?.payload,
          error: null,
        },
      };

    case BLOCK_UNBLOCK_INSTITUTION_FAILED:
      return {
        ...state,
        postblockUnblockInstitution: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default superAdminCommonReducers;
