import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessPopup, FailPopup, Loader } from '../../components';
import RequestBloodView from '../../views/DashboardView/RequestBloodView';
import { addBloodRequest } from '../../redux/actions/dashboardAction';
import { REQUEST_ADD_BLOOD } from '../../redux/actions/actionTypes';
import { REQUEST_FOR_BLOOD } from '../../redux/actions/actionTypes';
import { requestForBlood } from '../../redux/actions/peopleAction';

export default function RequestBloodContainer({ donorIds, hideRequest }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addBloodRequestObj } = useSelector((state) =>
    donorIds?.length ? state.peopleReducer : state.dashboardReducer
  );

  const [request, setRequest] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [close, setClose] = useState(false);
  function formValues(obj) {
    let a = { ...obj, component: 'Whole Blood' };
    setRequest(a);
  }

  function requestClick() {
    // setShowSuccess(true) && addBloodRequestObj;
    dispatch(
      donorIds?.length
        ? requestForBlood({ ...request, donorIds })
        : addBloodRequest(request, () => {
            setShowSuccess(true);
          })
    );
  }
  useEffect(() => {
    donorIds?.length && setClose(true); //optimized
    if (addBloodRequestObj.error !== null) {
      setFail(true);
      setLoading(false);
    } else if (addBloodRequestObj.data?.code === 200) {
      setShowSuccess(true);
      setLoading(false);
    } else if (addBloodRequestObj.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addBloodRequestObj]);
  return (
    <>
      <RequestBloodView
        formValues={formValues}
        requestClick={requestClick}
        hideRequest={hideRequest}
        close={close}
      />
      {showSuccess && (
        <SuccessPopup
          title={'Blood requested Successfully!'}
          onContinueClick={() => {
            history.push({
              pathname: close ? '/people' : '/',
              requestBlood: false,
            });
            {
              close && hideRequest();
            }
            setShowSuccess(false);
            dispatch({
              type: close ? REQUEST_FOR_BLOOD : REQUEST_ADD_BLOOD,
              loading: false,
            });
          }}
        />
      )}
      {showFail && (
        <FailPopup
          title={addBloodRequestObj.error}
          onContinueClick={() => {
            dispatch({
              type: close ? REQUEST_FOR_BLOOD : REQUEST_ADD_BLOOD,
              loading: false,
            });
            setFail(false);
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}
