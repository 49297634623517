import React from 'react';
import { Link } from 'react-router-dom';
import { HospitalCounts, Steps } from '../../components/Auth';
import Google from '../../assets/images/Google.png';
import Apple from '../../assets/images/Apple.png';
import qr from '../../assets/images/qr.png';
import { LatLongURL } from '../../utils/config';
import longLatIcon from '../../assets/icon/longLatIcon.svg';
import DropDown from '../../components/RDropDown';
import { INSTI_TYPE } from '../../utils/constants';

function RegisterView(props) {
  let {
    onInstituteSelect,
    regObj,
    disable,
    instituteNameFocused,
    addNewInstition,
    successMsg,
    addFile,
    hiddenFileInput,
    deleteAttachment,
    // NGO dATA
    ngoRegObj,
    // typeOfNgo,
    setTypeOfNgo,
  } = props;

  function fetchInstiType({ value }) {
    setTypeOfNgo(value);
  }
  return (
    <div className='bd-main'>
      <div className='bd_registration_grid'>
        <div className='bd-container-fluid' style={{ display: 'block' }}>
          <HospitalCounts />
          <div className='bd_registration_sub_grid borderRight'>
            <div className='borderRight'>
              <Steps />
            </div>
            <div className='bd-registration '>
              <div className='bd-registration__body'>
                <div className='bd-reg-insititution'>
                  <div className='bd-tab__content'>
                    <div className='d-flex space-between'>
                      <img src={qr} height='100' alt='Icon' />
                      <div className='bd_box'>
                        <div
                          className='bd_text_content'
                          style={{ marginTop: '10px' }}
                        >
                          Patients & Donors, please use the mobile app
                        </div>
                        <div
                          className='logo_sepration'
                          style={{ marginTop: '10px' }}
                        >
                          <a href='https://play.google.com/store/apps/details?id=com.serapis.blud'>
                            <img
                              src={Google}
                              height='40'
                              width='135'
                              alt='Google Icon'
                            />
                          </a>
                          <a href='https://play.google.com/store/apps/details?id=com.serapis.blud'>
                            <img
                              src={Apple}
                              height='40'
                              width='135'
                              alt='Apple Icon'
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='bd-registration__content'>
                      <i>
                        <p>
                          Everyday, we lose lots of lives for the lack of timely
                          availability of Blood and its components. This App is
                          a step closer to automate this request to donation
                          cycle efficiently.
                        </p>
                        <p>
                          This App endeavours to reduce the lead-time from
                          request to blood availability and also reduce the
                          excess inventory carriage and willful wastage
                          (Disposal).
                        </p>
                        <p>
                          This App can only connect to willing and eligible
                          donors who <b>Volunteer to Donate Blood for Free</b>{' '}
                          without any monetary expectations.
                        </p>
                        <p>
                          Blood Banks and respective recipient medical or
                          research institutions are responsible for examining
                          and ascertaining the medical fitness of donors and
                          thus absolves this App being held responsible to
                          guarantee medical fitness of donors.
                        </p>
                      </i>
                    </div>
                    {/* {props.currSelected !== 'NGO' && ( */}
                    <div className='bd-form bd-red-box-shadow'>
                      <div className='bd-form__row bd-form__row--register'>
                        <ul>
                          <div
                            className='bd-registration__header'
                            style={{ textAlign: 'left' }}
                          >
                            Declaration & Undertaking
                          </div>
                          {props.checkBoxUndertaking.map((data, index) => {
                            return (
                              <li className='bd-register-questions-set d-flex'>
                                <div className='bd-checkbox'>
                                  <input
                                    type='checkbox'
                                    checked={data.checked}
                                    onChange={() =>
                                      props.onCheckBoxClick(
                                        index,
                                        'undertaking'
                                      )
                                    }
                                  />
                                  <b></b>
                                </div>
                                <div className='bd-checkbox-label'>
                                  {data.name}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                        {/* {
                         props.checkBoxUndertaking.filter(item =>{
                           return  !item.checked})[0] ? <p className="ml-10 error-msg-font">Please mark the undertaking!</p> : ''

                        } */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='bd-registration bd-red-box-shadow'>
            <div className='bd-registration__body'>
              <div className='bd-reg-insititution'>
                <div
                  className='bd-registration__header'
                  style={{ textAlign: 'left' }}
                >
                  <span>Register Insititution</span>
                </div>
                <div className='bd-tab' style={{ marginBottom: '20px' }}>
                  <div
                    onClick={() => props.tabChange('blood_bank')}
                    className={
                      props.currSelected === 'blood_bank' ? 'active' : ''
                    }
                  >
                    <span>Blood Bank</span>
                  </div>
                  <div
                    onClick={() => props.tabChange('hospital')}
                    className={
                      props.currSelected === 'hospital' ? 'active' : ''
                    }
                  >
                    <span>Hospital</span>
                  </div>
                  <div
                    onClick={() => props.tabChange('NGO')}
                    className={props.currSelected === 'NGO' ? 'active' : ''}
                  >
                    {/* <span>NGO</span> */}
                    <span>Facilitator</span>
                  </div>
                </div>

                {(props.currSelected === 'blood_bank' ||
                  props.currSelected === 'hospital') && (
                  <div className='bd-form'>
                    <div
                      className='bd-form__row'
                      style={{ marginBottom: '15px' }}
                    >
                      <input
                        name='institutionName'
                        onChange={(e) => props.onInstituteSearch(e)}
                        onFocus={(e) => props.setInstituteNameFocused(true)}
                        // onBlur={(e) => props.setInstituteNameFocused(false)}
                        type='text'
                        value={regObj?.institutionName}
                        placeholder='Insititution Name'
                      />
                    </div>
                    {props.instituteNameFocused &&
                    props?.instituteListObj?.data?.content?.institutionLocation
                      .length ? (
                      <div
                        style={{
                          width: '100%',
                          height: 200,
                          // backgroundColor: 'transparent',
                          border: '1px solid #eee',
                          zIndex: 100,
                          overflow: 'auto',
                        }}
                      >
                        {props.instituteListObj.data.content.institutionLocation.map(
                          (data, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => onInstituteSelect(data)}
                                style={{
                                  borderRadius: 1,
                                  borderColor: 'grey',
                                  borderWidth: 1,
                                  padding: 4,
                                }}
                              >
                                <div>{data.institutionName},</div>
                                <div>
                                  {data.location &&
                                    data.location &&
                                    data.location.description}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      instituteNameFocused &&
                      !props?.instituteListObj?.data?.content
                        ?.institutionLocation && (
                        <div
                          className='create-new-btn'
                          onClick={addNewInstition}
                        >
                          + Create New
                        </div>
                      )
                    )}

                    <div
                      className='bd-form__row'
                      style={{ marginBottom: '7px' }}
                    >
                      <input
                        name='registrationNumber'
                        onChange={(e) => props.onChangeText(e)}
                        value={regObj?.registrationNumber}
                        type='text'
                        placeholder='Registration Number'
                      />
                    </div>
                    <input
                      type='file'
                      name='attachments'
                      id='upload'
                      hidden
                      ref={hiddenFileInput}
                      onChange={addFile}
                    />
                    <div
                      // className="bd-btn bd-btn--outline"
                      className='create-new-btn'
                      for='upload'
                      onClick={() => hiddenFileInput.current.click()}
                    >
                      <span for='upload'>
                        {/* <i className="icon-clip-icon mr-10" /> */}+ Attach
                        Certificate
                      </span>
                    </div>
                    <div
                      className='bd-attachment__file'
                      style={{ marginTop: '5px' }}
                    >
                      {regObj.attachments &&
                        regObj.attachments.map((attachment, i) => {
                          return (
                            <a
                              className='download-a'
                              download
                              href={attachment.location}
                            >
                              {/* <img
                              className="download-img"
                              src={download}
                              alt="download"
                            /> */}
                              <div className='attachment-name'>
                                {attachment.name}
                              </div>
                              <i
                                className='bluddelete paddingTop_3'
                                onClick={(e) => deleteAttachment(e, i)}
                              />
                            </a>
                          );
                        })}
                    </div>

                    <div className='bd-form__row' style={{ marginTop: '20px' }}>
                      <input
                        type='text'
                        name='address'
                        disabled
                        onChange={(e) => props.onChangeText(e)}
                        value={regObj?.address?.description}
                        placeholder='Address'
                      />
                    </div>

                    <div
                      className='d-flex'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div
                        className='bd-form__row'
                        style={{ marginRight: '5px' }}
                      >
                        <input
                          type='text'
                          style={{ textTransform: 'unset' }}
                          name='contactEmail'
                          onChange={(e) => props.onChangeText(e)}
                          value={regObj?.contactEmail}
                          placeholder='Contact Email'
                        />
                      </div>
                      <div className='bd-form__row'>
                        <input
                          type='number'
                          name='contactNumber'
                          onChange={(e) => props.onChangeText(e)}
                          value={regObj?.contactNumber}
                          placeholder='Contact Number'
                        />
                      </div>
                    </div>

                    <div className='bd-form'>
                      <div className='bd-form__row'>
                        <p>Does the institution has the infrastructure for</p>
                        <ul>
                          {props.checkBoxArr.map((data, index) => {
                            return (
                              <li className='bd-register-questions-set bd_hospital_checkbox d-flex'>
                                <div className='bd-checkbox'>
                                  <input
                                    type='checkbox'
                                    // defaultChecked={data.checked}
                                    checked={data.checked}
                                    onChange={() =>
                                      props.onCheckBoxClick(index)
                                    }
                                  />
                                  <b></b>
                                </div>
                                <div> {data.name}</div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      <div className='bd-form'>
                        <div
                          className='bd-registration__header'
                          style={{ textAlign: 'left' }}
                        >
                          <span>Key Admin Person</span>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            className='bd-form__row'
                            style={{ marginRight: '5px' }}
                          >
                            <input
                              type='text'
                              name='firstName'
                              onChange={(e) =>
                                props.onChangeText(e, 'keyMember')
                              }
                              value={regObj?.keyMembers?.firstName}
                              placeholder='First Name'
                            />
                          </div>

                          <div className='bd-form__row'>
                            <input
                              type='text'
                              name='lastName'
                              onChange={(e) =>
                                props.onChangeText(e, 'keyMember')
                              }
                              value={regObj?.keyMembers?.lastName}
                              placeholder='Last Name'
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            className='bd-form__row'
                            style={{ marginRight: '5px' }}
                          >
                            <input
                              type='text'
                              name='designation'
                              onChange={(e) =>
                                props.onChangeText(e, 'keyMember')
                              }
                              value={regObj?.keyMembers?.designation}
                              placeholder='Designation'
                            />
                          </div>
                          <div className='bd-form__row'>
                            <input
                              type='text'
                              style={{ textTransform: 'unset' }}
                              name='email'
                              onChange={(e) =>
                                props.onChangeText(e, 'keyMember')
                              }
                              value={regObj?.keyMembers?.email}
                              placeholder='Email ID'
                            />
                          </div>
                        </div>
                        <div className='bd-form__row bd-form__row--register'>
                          <button
                            className='bd-btn bd-btn--primary'
                            onClick={props.onSubmit}
                            disabled={disable}
                            type='submit'
                          >
                            <span>Register</span>
                          </button>
                        </div>

                        <div className='success-msg-font'>
                          {successMsg ? successMsg : ''}
                        </div>
                        <div className='error-msg-font'>
                          {props.errorMsg
                            ? props.errorMsg
                            : props.registerObj && props.registerObj.error
                            ? props.registerObj.error
                            : ''}
                        </div>
                        <div
                          className='bd-form__row bd-form__row--signup'
                          style={{ marginTop: '10px' }}
                        >
                          <span>
                            Already have an Account?{' '}
                            <Link to='/login'>Login</Link>
                          </span>
                        </div>
                        <div
                          className='bd-form__row bd-form__row--signup'
                          style={{ marginTop: '10px' }}
                        >
                          <span>
                            For any discrepancy, write to us at
                            <a
                              style={{ marginLeft: '10px' }}
                              href='mailto:support@blud.app'
                            >
                              support@blud.app
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {props.currSelected === 'NGO' && (
                  <div>
                    <div className='bd-form'>
                      <div className='bd-form__row mb-20'>
                        <div className='bd-dorpdown'>
                          <div className='bd-dorpdown__selected'>
                            <DropDown
                              options={INSTI_TYPE}
                              fetchDropDownValue={fetchInstiType}
                              placeHolderText={'Select Institution Type'}
                            />
                            <i className='bludbig-arrow' />
                          </div>
                        </div>
                      </div>
                      <div className='bd-form__row mb-20'>
                        <input
                          type='text'
                          name='InstitutionName'
                          onChange={(e) => props.onNgoChangeText(e)}
                          value={ngoRegObj?.InstitutionName}
                          placeholder='InstitutionName'
                        />
                      </div>
                      <div className='bd-form__row mb-20'>
                        <input
                          name='registrationNumber'
                          onChange={(e) => props.onNgoChangeText(e)}
                          value={ngoRegObj?.registrationNumber}
                          type='text'
                          placeholder='Registration No. / Unique Identity No.'
                        />
                      </div>
                      <div className='bd-form__row mb-20'>
                        <input
                          name='address'
                          onChange={(e) => props.onNgoChangeText(e)}
                          value={ngoRegObj?.address}
                          type='text'
                          placeholder='Address'
                        />
                      </div>
                      <div
                        className='d-flex'
                        style={{ justifyContent: 'space-between' }}
                      >
                        <div className='bd-form__row'>
                          <input
                            type='text'
                            name='City'
                            onChange={(e) => props.onNgoChangeText(e)}
                            value={ngoRegObj?.City}
                            placeholder='City'
                          />
                        </div>
                        <div
                          className='bd-form__row'
                          style={{ marginRight: '5px' }}
                        >
                          <input
                            type='text'
                            style={{ textTransform: 'unset' }}
                            name='state'
                            onChange={(e) => props.onNgoChangeText(e)}
                            value={ngoRegObj?.state}
                            placeholder='State'
                          />
                        </div>
                      </div>
                      <div
                        className='bd-form__row'
                        style={{ marginBottom: '7px' }}
                      >
                        <input
                          name='postalCode'
                          onChange={(e) => props.onNgoChangeText(e)}
                          value={ngoRegObj?.postalCode}
                          type='number'
                          placeholder='Postal Code'
                        />
                      </div>
                      <div
                        className='d-flex-center'
                        style={{ justifyContent: 'space-between' }}
                      >
                        <div
                          className='bd-form__row'
                          style={{ marginRight: '5px' }}
                        >
                          <input
                            type='number'
                            style={{ textTransform: 'unset' }}
                            name='Latitude'
                            onChange={(e) => props.onNgoChangeText(e)}
                            value={ngoRegObj?.Latitude}
                            placeholder='Latitude'
                          />
                        </div>
                        <div className='bd-form__row'>
                          <input
                            type='number'
                            name='Langitude'
                            onChange={(e) => props.onNgoChangeText(e)}
                            value={ngoRegObj?.Langitude}
                            placeholder='Langitude'
                          />
                        </div>
                        <div className='bd-form__row'>
                          <a target='_blank' href={LatLongURL}>
                            <img className='lat-long' src={longLatIcon} />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className='bd-form'>
                      <div
                        className='bd-registration__header'
                        style={{ textAlign: 'left' }}
                      >
                        <span>Key Admin Person</span>
                      </div>
                      <div
                        className='d-flex'
                        style={{ justifyContent: 'space-between' }}
                      >
                        <div className='bd-form__row'>
                          <input
                            type='text'
                            name='firstname'
                            onChange={(e) => props.onNgoChangeText(e)}
                            value={ngoRegObj?.firstname}
                            placeholder='First Name'
                          />
                        </div>
                        <div className='bd-form__row'>
                          <input
                            type='text'
                            name='lastName'
                            onChange={(e) => props.onNgoChangeText(e)}
                            value={ngoRegObj?.lastName}
                            placeholder='Last Name'
                          />
                        </div>
                      </div>
                      <div className='bd-form__row mb-20'>
                        <input
                          name='designation'
                          onChange={(e) => props.onNgoChangeText(e)}
                          value={ngoRegObj?.designation}
                          type='text'
                          placeholder='Designation'
                        />
                      </div>
                      <div
                        className='d-flex'
                        style={{ justifyContent: 'space-between' }}
                      >
                        <div
                          className='bd-form__row'
                          style={{ marginRight: '5px' }}
                        >
                          <input
                            type='text'
                            style={{ textTransform: 'unset' }}
                            name='emailId'
                            onChange={(e) => props.onNgoChangeText(e)}
                            value={ngoRegObj?.emailId}
                            placeholder='Email ID'
                          />
                        </div>
                        <div className='bd-form__row'>
                          <input
                            type='number'
                            name='mobileNumber'
                            onChange={(e) => props.onNgoChangeText(e)}
                            value={ngoRegObj?.mobileNumber}
                            placeholder='Mobile No.'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='bd-form__row bd-form__row--register'>
                      <button
                        className='bd-btn bd-btn--primary'
                        onClick={props.onSubmitNGO}
                        disabled={disable}
                        type='submit'
                      >
                        <span>Register</span>
                      </button>
                    </div>
                    <div className='success-msg-font'>
                      {successMsg ? successMsg : ''}
                    </div>
                    <div
                      className='bd-form__row bd-form__row--signup'
                      style={{ marginTop: '10px' }}
                    >
                      <span>
                        Already have an Account? <Link to='/login'>Login</Link>
                      </span>
                    </div>
                    <div
                      className='bd-form__row bd-form__row--signup'
                      style={{ marginTop: '10px' }}
                    >
                      <span>
                        For any discrepancy, write to us at
                        <a style={{ marginLeft: '10px' }} href='#'>
                          support@blud.app
                        </a>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterView;
