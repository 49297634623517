import {
  POST_DONOR_DATA,
  POST_DONOR_DATA_FAILED,
  POST_DONOR_DATA_SUCCESS,
} from './actionTypes';
import { makeAPICall } from '../../utils';
import { API_BASE_PATH, ADD_DONOR_URL } from '../../utils/config';

export function triggerAddDonor(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ADD_DONOR_URL}`,
      dispatch,
      defaultAction: POST_DONOR_DATA,
      successAction: POST_DONOR_DATA_SUCCESS,
      failedAction: POST_DONOR_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
