import React, { useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../index';

export default function UserDetailTable({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <>
      <Tables
        classes={data.alignCellCenter || ['']}
        tableType={data.tableType}
        columns={data.column}
        tableData={data.data.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )}
      />
      <TablePagination
        component='div'
        count={data.data.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
