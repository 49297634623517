import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  triggerGetTileData,
  triggerGetDashboardData,
} from '../../redux/actions/superAdminCommonActions';

import SuperAdminDashboardView from '../../views/SuperAdminDashboardView/SuperAdminDashboardView';
import donors from '../../assets/images/donors.png';
import consum from '../../assets/images/consum.png';
import issuedImg from '../../assets/images/issued.png';
import inventoryImg from '../../assets/images/inventory.png';
import dmand from '../../assets/images/dmand.png';
import { getStateList } from '../../redux/actions/authAction';

function SuperAdminDashboardContainer() {
  const dispatch = useDispatch();

  const { getTileData, getStateCityData } = useSelector(
    (state) => state.superAdminCommonReducers
  );

  const [TileData, setTileData] = useState([
    {
      count: 0,
      title: 'Donation',
      img: donors,
    },
    {
      count: 0,
      title: 'Consumption',
      img: consum,
    },
    {
      count: 0,
      title: 'Inventory',
      img: inventoryImg,
    },
    {
      count: 0,
      title: 'Demand per days',
      img: dmand,
    },
    {
      count: 0,
      title: 'Issued per day',
      img: issuedImg,
    },
  ]);

  const [CoreData, setCoreData] = useState([
    {
      lable: '# Units of Blood Donated',
      value: '--',
    },
    {
      lable: '# Units of  Blood Consumed',
      value: '--',
    },
    {
      lable: '# Units of  Blood Disposed',
      value: '--',
    },
    {
      lable: '# Units of  Inter Blood Bank Transfer',
      value: '--',
    },
    {
      lable: '# Donors',
      value: '--',
    },
    {
      lable: '# New Donors Added',
      value: '--',
    },
    {
      lable: '# Blood Donation Camps conducted',
      value: '--',
    },
    {
      lable: '# Emergency Response Mobilisation for Blood',
      value: '--',
    },
    {
      lable: 'Most Active State in Blood Donation Drive',
      value: '--',
    },
    {
      lable: 'Least Active State in Blood Donation Drive',
      value: '--',
    },
    {
      lable: 'Highest Consumption State',
      value: '--',
    },
    {
      lable: 'Lowest Consumption State',
      value: '--',
    },
    {
      lable: 'Average Time to find a Donor',
      value: '--',
    },
    {
      lable: 'Average Time to fulfill a Request for Blood',
      value: '--',
    },
    {
      lable: 'Top Reasons for Donor Deferral',
      value: '--',
    },
    {
      lable: '# Donors Deferred',
      value: '--',
    },
    {
      lable: 'Average Consumption of Blood ml/’000 people',
      value: '--',
    },
  ]);

  // Object.keys(Data?.citiwisedonors).length;

  useEffect(() => {
    if (getStateCityData?.data?.content?.data) {
      let Info = getStateCityData?.data?.content?.data;
      setCoreData([
        {
          lable: '# Units of Blood Donated',
          value: Info?.donation,
        },
        {
          lable: '# Units of  Blood Consumed',
          value: Info?.consumed,
        },
        {
          lable: '# Units of  Blood Disposed',
          value: Info?.disposed,
        },
        {
          lable: '# Units of  Inter Blood Bank Transfer',
          value: Info?.transfered,
        },
        {
          lable: '# Donors',
          value: Info?.users,
        },
        {
          lable: '# New Donors Added',
          value: Info?.newUsers,
        },
        {
          lable: '# Blood Donation Camps conducted',
          value: Info?.camps,
        },
        {
          lable: '# Emergency Response Mobilisation for Blood',
          value: Info?.emergencyResponseMobilisatioOfBlood,
        },
        {
          lable: `Most Active ${
            Info?.citiwisedonors && Object.keys(Info?.citiwisedonors).length
              ? 'City'
              : 'State'
          } in Blood Donation Drive`,
          value: Info?.MostActiveStateInBloodDonationDrive,
        },
        {
          lable: `Least Active ${
            Info?.citiwisedonors && Object.keys(Info?.citiwisedonors).length
              ? 'City'
              : 'State'
          } in Blood Donation Drive`,
          value: Info?.LeastActiveStateInBloodDonationDrive,
        },
        {
          lable: `Higehst Consumption  ${
            Info?.citiwisedonors && Object.keys(Info?.citiwisedonors).length
              ? 'City'
              : 'State'
          }`,
          value: Info?.HighestConsumputionState,
        },
        {
          lable: `Lowest Consumption  ${
            Info?.citiwisedonors && Object.keys(Info?.citiwisedonors).length
              ? 'City'
              : 'State'
          }`,
          value: Info?.LowestConsumptionState,
        },
        {
          lable: 'Average Time to find a Donor',
          value: Info?.AvarageTimeToFindDonor ?? '--',
        },
        {
          lable: 'Average Time to fulfill a Request for Blood',
          value: Info?.AvarageTimeToFulfilRequest ?? '--',
        },
        {
          lable: 'Top Reasons for Donor Deferral',
          value: Info?.topReasonToDOnorDefferal,
        },
        {
          lable: '# Donors Deferred',
          value: Info?.DonorDeffered,
        },
        {
          lable: 'Average Consumption of Blood ml/’000 people',
          value: Info?.AvarageConsuptionOfBlood,
        },
      ]);
    }
  }, [getStateCityData?.data?.content?.data]);

  useEffect(() => {
    if (getTileData?.data?.content?.data) {
      let { donations, consumed, demandPerDay, issued, inventory } =
        getTileData?.data?.content?.data;
      setTileData([
        {
          count: donations,
          title: 'Donation',
          img: donors,
        },
        {
          count: consumed,
          title: 'Consumption',
          img: consum,
        },
        {
          count: inventory,
          title: 'Inventory',
          img: inventoryImg,
        },
        {
          count: demandPerDay,
          title: 'Demand per days',
          img: dmand,
        },
        {
          count: issued,
          title: 'Issued per day',
          img: issuedImg,
        },
      ]);
    }
  }, [getTileData?.data?.content?.data]);

  const { getState } = useSelector((state) => state.authReducer);

  const [apIStateList, setApIStateList] = useState([]);

  const [selectedStateList, setSelectedStateList] = useState('All');
  function fetchState({ value }) {
    setSelectedStateList(value);
  }

  const stateList = [{ label: 'All', value: 'All' }];

  if (apIStateList.length) {
    apIStateList.map((item) => {
      stateList.push({ label: item, value: item });
    });
  }

  useEffect(() => {
    if (getState?.data?.content?.stateOrCity) {
      setApIStateList(getState?.data?.content?.stateOrCity);
    }
  }, [getState?.data?.content?.stateOrCity]);

  useEffect(() => {
    // this is for state list
    dispatch(getStateList({ type: 'state' }));
  }, []);

  useEffect(() => {
    dispatch(triggerGetTileData());
    dispatch(triggerGetDashboardData({ state: selectedStateList }));
  }, [selectedStateList]);

  return (
    <SuperAdminDashboardView
      Data={getStateCityData?.data?.content?.data}
      TileData={TileData}
      CoreData={CoreData}
      fetchState={fetchState}
      stateList={stateList}
      selectedStateList={selectedStateList}
    />
  );
}

export default SuperAdminDashboardContainer;
