import {
  GET_REQUEST_DATA,
  GET_REQUEST_DATA_SUCCESS,
  GET_REQUEST_DATA_FAILED,
  GET_DONATION_DATA,
  GET_DONATION_DATA_SUCCESS,
  GET_DONATION_DATA_FAILED,
  GET_CHART_DATA,
  GET_CHART_DATA_SUCCESS,
  GET_CHART_DATA_FAILED,
  GET_DASHBOARD_INVENTORYLIST,
  GET_DASHBOARD_INVENTORYLIST_SUCCESS,
  GET_DASHBOARD_INVENTORYLIST_FAILED,
  ACCEPT_REQUEST,
  ACCEPT_REQUEST_SUCCESS,
  ACCEPT_REQUEST_FAILED,
  INVENTORY_CHANGE_STATUS,
  INVENTORY_CHANGE_STATUS_SUCCESS,
  INVENTORY_CHANGE_STATUS_FAILED,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  GET_DONOR_OTP,
  GET_DONOR_OTP_SUCCESS,
  GET_DONOR_OTP_FAILED,
  VALIDATE_DONOR_OTP,
  VALIDATE_DONOR_OTP_SUCCESS,
  VALIDATE_DONOR_OTP_FAILED,
  REQUEST_DONOR_CHANGE_STATUS,
  REQUEST_DONOR_CHANGE_STATUS_SUCCESS,
  REQUEST_DONOR_CHANGE_STATUS_FAILED,
  BLOCK_DONOR,
  BLOCK_DONOR_SUCCESS,
  BLOCK_DONOR_FAILED,
  REQUEST_VERIFICATION,
  REQUEST_VERIFICATION_SUCCESS,
  REQUEST_VERIFICATION_FAILED,
  REQUEST_VERIFIED,
  REQUEST_VERIFIED_SUCCESS,
  REQUEST_VERIFIED_FAILED,
  DONOR_LAB_RESULT,
  DONOR_LAB_RESULT_SUCCESS,
  DONOR_LAB_RESULT_FAILED,
  DONATION_HISTORY,
  DONATION_HISTORY_SUCCESS,
  DONATION_HISTORY_FAILED,
  MEDICAL_HISTORY,
  MEDICAL_HISTORY_SUCCESS,
  MEDICAL_HISTORY_FAILED,
  DONATION_GRAPH,
  DONATION_GRAPH_SUCCESS,
  DONATION_GRAPH_FAILED,
  DONATION_BY_DONORID,
  DONATION_BY_DONORID_SUCCESS,
  DONATION_BY_DONORID_FAILED,
  REQUEST_ADD_BLOOD,
  REQUEST_ADD_BLOOD_SUCCESS,
  REQUEST_ADD_BLOOD_FAILED,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILED,
  DONATION_REMARK,
  DONATION_REMARK_SUCCESS,
  DONATION_REMARK_FAILED,
  TRIGGER_GET_INSTITUTE_LIST,
  TRIGGER_GET_INSTITUTE_LIST_SUCCESS,
  TRIGGER_GET_INSTITUTE_LIST_FAILED,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILED,
  POST_EDIT_VERIFY_USER,
  POST_EDIT_VERIFY_USER_SUCCESS,
  POST_EDIT_VERIFY_USER_FAILED,
  GET_DASHBOARD_TILEDATA,
  GET_DASHBOARD_TILEDATA_SUCCESS,
  GET_DASHBOARD_TILEDATA_FAILED,
  GET_NGO_DASHBOARD_DATA,
  GET_NGO_DASHBOARD_DATA_SUCCESS,
  GET_NGO_DASHBOARD_DATA_FAILED,
  GET_NGO_REQUEST_TAB_DATA,
  GET_NGO_REQUEST_TAB_DATA_SUCCESS,
  GET_NGO_REQUEST_TAB_DATA_FAILED,
  POST_NGO_REQUEST_TAB_DATA,
  POST_NGO_REQUEST_TAB_DATA_SUCCESS,
  POST_NGO_REQUEST_TAB_DATA_FAILED,
} from '../actions/actionTypes';
const initialState = {
  userRequestObj: {
    loading: false,
    data: [],
    error: null,
  },
  donationObj: {
    loading: false,
    data: [],
    error: null,
  },
  chartsData: {
    loading: false,
    data: null,
    error: null,
  },
  inventoryList: {
    loading: false,
    data: [],
    error: null,
  },
  acceptList: {
    loading: false,
    data: null,
    error: null,
  },
  inventoryChangeStatus: {
    loading: false,
    data: null,
    error: null,
    successFlag: false,
  },
  donarProfile: {
    loading: false,
    data: null,
    error: null,
  },
  donarOTPData: {
    loading: false,
    data: null,
    verifiedData: null,
    error: null,
    successFlag: false,
  },
  donorDecline: {
    loading: false,
    data: null,
    error: null,
  },
  donorBlock: {
    loading: false,
    data: null,
    error: null,
  },
  requestVerifyObj: {
    loading: false,
    data: [],
    error: null,
  },
  requestVerifiedObj: {
    loading: false,
    data: [],
    error: null,
  },
  historyList: {
    loading: false,
    data: [],
    error: null,
  },
  labResultList: {
    loading: false,
    data: [],
    error: null,
  },
  medicaList: {
    loading: false,
    data: [],
    error: null,
  },
  remarkList: {
    loading: false,
    data: [],
    error: null,
  },
  donationGraphData: {
    loading: false,
    data: [],
    error: null,
  },
  donationByDonor: {
    loading: false,
    data: null,
    error: null,
  },
  addBloodRequestObj: {
    loading: false,
    data: [],
    error: null,
  },
  fileUpload: {
    loading: false,
    data: [],
    error: null,
  },
  instituteListObj: {
    loading: false,
    data: null,
    error: null,
  },
  userStatus: {
    loading: false,
    data: null,
    error: null,
  },
  editVerifyUser: {
    // post pai for edit user
    loading: false,
    data: null,
    error: null,
  },
  dashboarTilesCount: {
    // GET APi for Dashboard Tiles Counts && issued Data
    loading: false,
    data: [],
    error: null,
  },
  ngoDashboardCount: {
    // GET APi for All Dashboard Data Counts , Graph
    loading: false,
    data: [],
    error: null,
  },
  ngoDashboardAcceptBtn: {
    // GET APi for All Dashboard Request Tab Accept Btn  Data
    loading: false,
    data: [],
    error: null,
  },
  ngoReqAssignBtn: {
    // GET APi for All Dashboard Request Tab Accept Btn  Data
    loading: false,
    data: [],
    error: null,
  },
};

const dashboardReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_REQUEST_DATA:
      return {
        ...state,
        userRequestObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        userRequestObj: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_REQUEST_DATA_FAILED:
      return {
        ...state,
        userRequestObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_DONATION_DATA:
      return {
        ...state,
        donationObj: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_DONATION_DATA_SUCCESS:
      return {
        ...state,
        donationObj: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_DONATION_DATA_FAILED:
      return {
        ...state,
        donationObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_CHART_DATA:
      return {
        ...state,
        chartsData: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartsData: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_CHART_DATA_FAILED:
      return {
        ...state,
        chartsData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_DASHBOARD_INVENTORYLIST:
      return {
        ...state,
        inventoryList: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };

    case GET_DASHBOARD_INVENTORYLIST_SUCCESS:
      return {
        ...state,
        inventoryList: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_DASHBOARD_INVENTORYLIST_FAILED:
      return {
        ...state,
        inventoryList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case ACCEPT_REQUEST:
      return {
        ...state,
        acceptList: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case ACCEPT_REQUEST_SUCCESS:
      return {
        ...state,
        acceptList: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case ACCEPT_REQUEST_FAILED:
      return {
        ...state,
        acceptList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case INVENTORY_CHANGE_STATUS:
      return {
        ...state,
        inventoryChangeStatus: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
          successFlag: false,
        },
      };
    case INVENTORY_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        inventoryChangeStatus: {
          loading: false,
          data: action.payload.content.data,
          error: null,
          successFlag: true,
        },
      };

    case INVENTORY_CHANGE_STATUS_FAILED:
      return {
        ...state,
        inventoryChangeStatus: {
          loading: false,
          data: null,
          error: action.error,
          successFlag: false,
        },
      };
    case GET_PROFILE:
      return {
        ...state,
        donarProfile: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        donarProfile: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_PROFILE_FAILED:
      return {
        ...state,
        donarProfile: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_DONOR_OTP:
      return {
        ...state,
        donarOTPData: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case GET_DONOR_OTP_SUCCESS:
      return {
        ...state,
        donarOTPData: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_DONOR_OTP_FAILED:
      return {
        ...state,
        donarOTPData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case VALIDATE_DONOR_OTP:
      return {
        ...state,
        donarOTPData: {
          loading: action.loading === false ? action.loading : true,
          verifiedData: null,
          error: null,
          successFlag: false,
        },
      };
    case VALIDATE_DONOR_OTP_SUCCESS:
      return {
        ...state,
        donarOTPData: {
          loading: false,
          verifiedData: action.payload.content.data,
          error: null,
          successFlag: true,
        },
      };

    case VALIDATE_DONOR_OTP_FAILED:
      return {
        ...state,
        donarOTPData: {
          loading: false,
          verifiedData: null,
          error: action.error,
          successFlag: false,
        },
      };
    case REQUEST_DONOR_CHANGE_STATUS:
      return {
        ...state,
        donorDecline: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case REQUEST_DONOR_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        donorDecline: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case REQUEST_DONOR_CHANGE_STATUS_FAILED:
      return {
        ...state,
        donorDecline: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case BLOCK_DONOR:
      return {
        ...state,
        donorBlock: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case BLOCK_DONOR_SUCCESS:
      return {
        ...state,
        donorBlock: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case BLOCK_DONOR_FAILED:
      return {
        ...state,
        donorBlock: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case REQUEST_VERIFIED:
      return {
        ...state,
        requestVerifiedObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case REQUEST_VERIFIED_SUCCESS:
      return {
        ...state,
        requestVerifiedObj: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case REQUEST_VERIFIED_FAILED:
      return {
        ...state,
        requestVerifiedObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case REQUEST_VERIFICATION:
      return {
        ...state,
        requestVerifyObj: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case REQUEST_VERIFICATION_SUCCESS:
      return {
        ...state,
        requestVerifyObj: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case REQUEST_VERIFICATION_FAILED:
      return {
        ...state,
        requestVerifyObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case DONOR_LAB_RESULT:
      return {
        ...state,
        labResultList: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case DONOR_LAB_RESULT_SUCCESS:
      return {
        ...state,
        labResultList: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case DONOR_LAB_RESULT_FAILED:
      return {
        ...state,
        labResultList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case DONATION_HISTORY:
      return {
        ...state,
        historyList: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case DONATION_HISTORY_SUCCESS:
      return {
        ...state,
        historyList: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case DONATION_HISTORY_FAILED:
      return {
        ...state,
        historyList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case MEDICAL_HISTORY:
      return {
        ...state,
        medicaList: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case MEDICAL_HISTORY_SUCCESS:
      return {
        ...state,
        medicaList: {
          loading: false,
          data: action.payload.content.reports,
          error: null,
        },
      };

    case MEDICAL_HISTORY_FAILED:
      return {
        ...state,
        medicaList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case DONATION_GRAPH:
      return {
        ...state,
        donationGraphData: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case DONATION_GRAPH_SUCCESS:
      return {
        ...state,
        donationGraphData: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case DONATION_GRAPH_FAILED:
      return {
        ...state,
        donationGraphData: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case DONATION_BY_DONORID:
      return {
        ...state,
        donationByDonor: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case DONATION_BY_DONORID_SUCCESS:
      return {
        ...state,
        donationByDonor: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case DONATION_BY_DONORID_FAILED:
      return {
        ...state,
        donationByDonor: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case REQUEST_ADD_BLOOD:
      return {
        ...state,
        addBloodRequestObj: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case REQUEST_ADD_BLOOD_SUCCESS:
      return {
        ...state,
        addBloodRequestObj: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case REQUEST_ADD_BLOOD_FAILED:
      return {
        ...state,
        addBloodRequestObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case UPLOAD_FILE:
      return {
        ...state,
        fileUpload: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        fileUpload: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case UPLOAD_FILE_FAILED:
      return {
        ...state,
        fileUpload: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case DONATION_REMARK:
      return {
        ...state,
        remarkList: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: null,
        },
      };
    case DONATION_REMARK_SUCCESS:
      return {
        ...state,
        remarkList: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case DONATION_REMARK_FAILED:
      return {
        ...state,
        remarkList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case TRIGGER_GET_INSTITUTE_LIST:
      return {
        ...state,
        instituteListObj: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_GET_INSTITUTE_LIST_SUCCESS:
      return {
        ...state,
        instituteListObj: {
          loading: false,
          data: action.payload.content,
          error: null,
        },
      };

    case TRIGGER_GET_INSTITUTE_LIST_FAILED:
      return {
        ...state,
        instituteListObj: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case VERIFY_USER:
      return {
        ...state,
        userStatus: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: action.error,
        },
      };

    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        userStatus: {
          loading: false,
          data: action.payload.content,
          error: null,
        },
      };

    case VERIFY_USER_FAILED:
      return {
        ...state,
        userStatus: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case POST_EDIT_VERIFY_USER:
      return {
        ...state,
        editVerifyUser: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case POST_EDIT_VERIFY_USER_SUCCESS:
      return {
        ...state,
        editVerifyUser: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case POST_EDIT_VERIFY_USER_FAILED:
      return {
        ...state,
        editVerifyUser: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case GET_DASHBOARD_TILEDATA:
      return {
        ...state,
        dashboarTilesCount: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GET_DASHBOARD_TILEDATA_SUCCESS:
      return {
        ...state,
        dashboarTilesCount: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case GET_DASHBOARD_TILEDATA_FAILED:
      return {
        ...state,
        dashboarTilesCount: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_NGO_DASHBOARD_DATA:
      return {
        ...state,
        ngoDashboardCount: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GET_NGO_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        ngoDashboardCount: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case GET_NGO_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        ngoDashboardCount: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_NGO_REQUEST_TAB_DATA:
      return {
        ...state,
        ngoDashboardAcceptBtn: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GET_NGO_REQUEST_TAB_DATA_SUCCESS:
      return {
        ...state,
        ngoDashboardAcceptBtn: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case GET_NGO_REQUEST_TAB_DATA_FAILED:
      return {
        ...state,
        ngoDashboardAcceptBtn: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case POST_NGO_REQUEST_TAB_DATA:
      return {
        ...state,
        ngoReqAssignBtn: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case POST_NGO_REQUEST_TAB_DATA_SUCCESS:
      return {
        ...state,
        ngoReqAssignBtn: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case POST_NGO_REQUEST_TAB_DATA_FAILED:
      return {
        ...state,
        ngoReqAssignBtn: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default dashboardReducer;
