import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { triggeReCallLogin } from '../../redux/actions/superAdminCommonActions';

export function PendingForVerifProfilePopup({
  profileData,
  setShowProfilePopup,
  setCommentValue,
  ApproveDeclineAction,
}) {
  const dispatch = useDispatch();
  const { reCallLogin } = useSelector(
    (state) => state.superAdminCommonReducers
  );
  // useEffect(() => {
  //   setBtns(false);
  // }, [reCallLogin]);

  useEffect(() => {
    dispatch(triggeReCallLogin());
  }, []);

  const [Comment, setComment] = useState(false);
  const [Btns, setBtns] = useState(true);

  const cells = [
    { label: 'Institution Name:', value: profileData.name },
    { label: 'Institution Register No.:', value: profileData.regNo ?? '--' },
    { label: 'Institution Email ID:', value: profileData.email },
    { label: 'Institution Type:', value: profileData.type ?? '--' },
    { label: 'Contact No:', value: profileData.number ?? '--' },
    { label: 'Institution Address:', value: profileData.address ?? '--' },
    { label: 'Applid On:', value: profileData?.date ?? '--' },
  ];

  return (
    <div className='bd-modal-wrap bd-profile'>
      <div className='bd-popup si-fade-in'>
        <div className='bd-popup__head'>
          <span>Profile of - {profileData.type} </span>
          {/* <span>Profile of - {profileData.id} </span> */}
          <i
            className='bludclose'
            onClick={() => {
              setShowProfilePopup(false);
            }}
          ></i>
        </div>
        <div className='bd-popup__body'>
          <div className='bd-profile__tbl'>
            <div className='bd-profile__detail--tbl'>
              <div className='bd-profile__detail--lft'>
                <div className='bd-tbl bd-tbl__theme2'>
                  <div className='bd-tbl__body'>
                    {cells.map((cell) => {
                      return (
                        <div className='bd-tbl__row'>
                          <div className='bd-tbl__cell'>
                            <span>{cell.label}</span>
                          </div>
                          <div className='bd-tbl__cell'>
                            <span>{cell.value}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {reCallLogin?.data?.content?.data?.instituteManagement.update ? (
              <div>
                {Btns && (
                  <div className='bd-btn__wrap'>
                    <div
                      className='bd-btn bd-btn--primary'
                      onClick={() => {
                        setComment(true);
                        setBtns(false);
                      }}
                    >
                      <span>Decline</span>
                    </div>
                    <div
                      className='bd-btn db-bg__green'
                      onClick={() => {
                        ApproveDeclineAction('approved', profileData.id);
                      }}
                    >
                      <span>Approve</span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className='bd-btn__wrap'>
                <div
                  className='bd-btn bd-btn--primary'
                  onClick={() => {
                    setShowProfilePopup(false);
                  }}
                >
                  <span>Cancle</span>
                </div>
              </div>
            )}
            {Comment && (
              <div>
                <div className='bd-profile__otp'>
                  <div className='bd-testArea mb-10'>
                    <textarea
                      className='bd-textArea-style'
                      placeholder='Reason'
                      name='Comments'
                      onChange={(e) => setCommentValue(e.target.value)}
                    ></textarea>
                    {/* {error && ( */}
                    <span style={{ color: '#af0c0c' }}>
                      *Comment Is Required For Decline
                    </span>
                    {/* )} */}
                  </div>
                  <div className='bd-btn__wrap'>
                    <div
                      class='bd-btn bd-btn--outline'
                      onClick={() => {
                        setComment(false);
                        setBtns(true);
                      }}
                    >
                      <span>Cancel </span>
                    </div>
                    <div
                      className='bd-btn  bd-btn--primary'
                      onClick={() => {
                        ApproveDeclineAction('declined', profileData.id);
                      }}
                    >
                      <span>Decline</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
