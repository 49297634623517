import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
  ProfilePopup,
  ProfileDetailPopup,
  SuccessPopup,
  FailPopup,
  Loader,
} from '../../components';
import SelfQuestionnarie from './SelfQuestionnaire';
import { titleCase } from '../../utils/constants';
import stars from '../../assets/images/stars.png';
import requestBlood from '../../assets/images/blooddrop-white.png';

export default function DonationTab({
  donationData,
  tabs,
  showVerifyOTP,
  showProfilePopup,
  showProfileDetailPopup,
  handleCilck,
  onRequestClick,
  otpInput,
  setShowProfilePopup,
  onVerifyClick,
  setShowVerifyOTP,
  onTabChange,
  activeTab,
  hidePopup,
  setShowSuccess,
  showSuccess,
  donorProfile,
  setFail,
  showFail,
  loading,
  errors,
}) {
  const history = useHistory();
  const [viewSelfQuestionnarie, setViewSelfQuestionnarie] = useState(false);
  const [acceptTermsQuestionnarie, setAcceptTermsQuestionnarie] =
    useState(false);

  function onQuestionnarieClick() {
    setViewSelfQuestionnarie(true);
  }
  function declineClick() {
    setViewSelfQuestionnarie(false);
  }
  function approveClick() {
    setViewSelfQuestionnarie(false);
    history.push('/donation-form');
  }
  function acceptTermsClick(val) {
    setAcceptTermsQuestionnarie(!val);
  }

  function ReqOTPBtn(obj) {
    return (
      <div className='bd-tbl__cell' donorId={obj?.obj?.donorId}>
        <div
          class='bd-request-otp '
          onClick={handleCilck.bind(this, obj?.obj?.knownDonor)}
          donorId={obj?.obj?.donorId}
        >
          <div
            className={[
              !obj.obj.knownDonor
                ? ' bd-request-otp-stars brder_Radius'
                : 'bd-green bd-request-otp-stars brder_Radius',
            ]}
            // className={[
            //   !obj.obj.knownDonor
            //     ? ' bd-request-otp-stars brder_Radius'
            //     : 'bd-green bd-request-otp-stars brder_Radius',
            // ]}
            donorId={obj?.obj?.donorId}
          >
            {!obj.obj.knownDonor ? (
              <img
                src={stars}
                height='15'
                donorId={obj?.obj?.donorId}
                alt='stars'
              />
            ) : (
              <img
                src={requestBlood}
                width={18}
                donorId={obj?.obj?.donorId}
                alt='stars'
              />
            )}
          </div>
          <div
            className={[
              !obj.obj.knownDonor
                ? ' bd-black active'
                : 'bd-green bd-black active',
            ]}
            donorId={obj?.obj?.donorId}
          >
            <span donorId={obj?.obj?.donorId}>
              {!obj.obj.knownDonor ? 'Request OTP' : 'Donation'}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {/* <div className="bd-tab__header">
      <span className="bd-tab__header--txt">For Donations</span>
      <span className="bd-viewall">
        <Link to={`/dashboard/donation-viewall`}>View all</Link>
      </span>
    </div> */}
      <div className='bd-tbl bd-tbl__theme1 bd-tbl__theme1-new'>
        {donationData && donationData.length > 0 && (
          <div className='bd-tbl__head'>
            <div className='bd-tbl__row'>
              <div className='bd-tbl__cell'>Name</div>
              <div className='bd-tbl__cell'>Blood Group</div>
              <div className='bd-tbl__cell'>Donor ID</div>
              <div className='bd-tbl__cell'>Date</div>
              <div className='bd-tbl__cell'>Time</div>
              <div className='bd-tbl__cell'>Status</div>
              <div className='bd-tbl__cell'>Mobile No.</div>
            </div>
          </div>
        )}
        {donationData && donationData.length > 0 ? (
          donationData
            .map((obj, i) => {
              let date = new Date((i = obj.appointment))
                .toDateString()
                .split(' ');
              let timeArr = new Date((i = obj.appointment))
                .toTimeString()
                .split(' ');
              let time = timeArr[0].split(':');
              return (
                <div className='bd-tbl__row'>
                  <div className='bd-tbl__cell name-cell' donorId={obj.donorId}>
                    {titleCase(obj.firstName) + ' ' + titleCase(obj.lastName)}
                  </div>
                  <div
                    className='bd-tbl__cell text-center'
                    donorId={obj.donorId}
                  >
                    {obj.bloodGroup}
                  </div>

                  <div className='bd-tbl__cell' donorId={obj.donorId}>
                    {obj.donorId}
                  </div>

                  <div className='bd-tbl__cell' donorId={obj.donorId}>
                    {obj.appointment && date[2]} {obj.appointment && date[1]}{' '}
                    {obj.appointment && date[3]}
                  </div>

                  <div className='bd-tbl__cell' donorId={obj.donorId}>
                    {obj.appointment && time[0] + ':' + time[1]}
                  </div>

                  <div className='bd-tbl__cell' donorId={obj.donorId}>
                    {obj.status}
                  </div>

                  <div className='bd-tbl__cell' donorId={obj.donorId}>
                    {(obj.countryCode ? obj.countryCode : ' - ') +
                      ' ' +
                      (obj.mobileNumber ? obj.mobileNumber : ' - ')}
                  </div>
                  <ReqOTPBtn obj={obj} />
                </div>
              );
            })
            .filter((a, i) => i < 3)
        ) : (
          <div className='bd-tbl__body--scroll noData_center'>
            <div className='bd-tbl__body'>
              <div className='bd-noDonationData'>
                <span>No Donors scheduled </span>
                <span>for donation </span>
              </div>
            </div>
          </div>
        )}
        {showProfilePopup.show && (
          <ProfilePopup
            profileData={donorProfile}
            onRequestClick={onRequestClick}
            showVerifyOTP={showVerifyOTP}
            otpInput={otpInput}
            setShowProfilePopup={setShowProfilePopup}
            onVerifyClick={onVerifyClick}
            setShowVerifyOTP={setShowVerifyOTP}
          />
        )}
        {showProfileDetailPopup.show && (
          <ProfileDetailPopup
            profileData={donorProfile}
            tabs={tabs}
            onTabChange={onTabChange}
            hidePopup={hidePopup}
            activeTab={activeTab}
            displayApproveDeclineButton={true}
            displaySelfQuestionnarie={false}
            onQuestionnarieClick={onQuestionnarieClick}
          />
        )}
        {viewSelfQuestionnarie && (
          <SelfQuestionnarie
            profileData={donorProfile}
            declineClick={declineClick}
            approveClick={approveClick}
            acceptTermsClick={acceptTermsClick}
            acceptTermsQuestionnarie={acceptTermsQuestionnarie}
          />
        )}
        {showSuccess && (
          <SuccessPopup
            title={`The Donor has been Blocked successfully!`}
            onContinueClick={() => {
              hidePopup();
              setShowSuccess(false);
            }}
          />
        )}
        {showFail && (
          <FailPopup
            title={errors}
            onContinueClick={() => {
              setFail(false);
              hidePopup();
            }}
          />
        )}
        {loading && <Loader />}
      </div>
    </React.Fragment>
  );
}
