import {
  GET_INSTITUTE_PROFILE,
  GET_INSTITUTE_PROFILE_FAILED,
  GET_INSTITUTE_PROFILE_SUCCESS,
  UPDATE_INSTITUTE_PROFILE,
  UPDATE_INSTITUTE_PROFILE_FAILED,
  UPDATE_INSTITUTE_PROFILE_SUCCESS,
} from './actionTypes';
import { makeAPICall } from '../../utils';
import {
  API_BASE_PATH,
  GET_INSTITUTION_PROFILE,
  POST_INSTITUTION_PROFILE,
} from '../../utils/config';

export function triggerGetProfile(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_INSTITUTION_PROFILE}`,
      dispatch,
      defaultAction: GET_INSTITUTE_PROFILE,
      successAction: GET_INSTITUTE_PROFILE_SUCCESS,
      failedAction: GET_INSTITUTE_PROFILE_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerUpdateProfile(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_INSTITUTION_PROFILE}`,
      dispatch,
      defaultAction: UPDATE_INSTITUTE_PROFILE,
      successAction: UPDATE_INSTITUTE_PROFILE_SUCCESS,
      failedAction: UPDATE_INSTITUTE_PROFILE_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
