import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import DropDown from '../../components/RDropDown';

import { PRICING_COLUMNS } from '../../utils/constants';

function SuperAdminPricingView() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  let staticPricingArr = [
    {
      Component: 'WholeBlood',
      BestPrice: '₹1200',
      NorthEast: '₹1200',
      UT: '₹1200',
      Platelets: '₹500',
      WholeBlood: '₹1200',
      TLC: '₹1200',
    },
    {
      Component: 'Plasma - Apheresis single donor',
      BestPrice: '₹1200',
      NorthEast: '₹1200',
      UT: '₹1200',
      Platelets: '₹530',
      WholeBlood: '₹1230',
      TLC: '₹1400',
    },
    {
      Component: 'Plasma - Fresh Frozen',
      BestPrice: '₹1200',
      NorthEast: '₹1200',
      UT: '₹1200',
      Platelets: '₹500',
      WholeBlood: '₹1200',
      TLC: '₹1200',
    },
    {
      Component: 'Plasma - Cryo Poor',
      BestPrice: '₹1200',
      NorthEast: '₹1220',
      UT: '₹1200',
      Platelets: '₹400',
      WholeBlood: '₹1200',
      TLC: '₹1600',
    },
    {
      Component: 'Platelets - Leukoreduced',
      BestPrice: '₹1000',
      NorthEast: '₹3200',
      UT: '₹1200',
      Platelets: '₹550',
      WholeBlood: '₹1250',
      TLC: '₹1200',
    },
  ];

  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              <div className='bd-dashboard__header bd-header__search'>
                <span className='bd-dashboard__main'>Pricing</span>
                <div className='dashboard_tiles_header'>
                  <div className='bd_error fw-600'>
                    {/* <div className='bd-dorpdown'>
                      <div className='bd-dorpdown__selected detail-dd bg-dd'>
                        <DropDown
                          placeHolderText={'Blood Group'}
                          options={BLOOD_GROUP_OPTIONS}
                          fetchDropDownValue={headerDropDownChange}
                        />
                        <i className='bludbig-arrow' />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className='bd-hader'>
                  <input
                    type='text'
                    // value={search}
                    // onChange={(e) => {
                    //   setSearch(e.target.value);
                    // }}
                    placeholder='Search'
                    style={{ padding: 5, marginRight: 10 }}
                  />
                </div>
              </div>
              <div className='table-scroll'>
                <Tables
                  tab={'Users'}
                  tableType={'table-type-1'}
                  columns={PRICING_COLUMNS}
                  tableData={staticPricingArr.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  //   headerClick={onHeaderClick}
                  //   onClick={handleClick}
                  //   rowClick={true}
                />
              </div>
              <TablePagination
                component='div'
                count={staticPricingArr.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </section>
      </article>
    </>
  );
}

export default SuperAdminPricingView;
