import React from 'react';
import { Tables } from '../../components/Tables';
import { INVENTORY_TAB_COLUMNS } from '../../utils/constants';

export default function InventoryTab({ inventoryTableData }) {
  return (
    <React.Fragment>
      <div className='bd-tab__content inventory-tab'>
        <div className='bd-tbl__donation '>
          {inventoryTableData.length > 0 ? (
            <Tables
              tableType={'table-type-2'}
              columns={INVENTORY_TAB_COLUMNS}
              tableData={inventoryTableData}
            />
          ) : (
            <div className='bd-tbl__body--scroll noData_center'>
              <div className='bd-tbl__body'>
                <div className='bd-noInventoryData'>
                  <span>Looks like you don’t </span>
                  <span>have any ageing unit</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
