import React, { useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { Tables } from '../../components';
import { VERIFY_VERIFIED_VIEW_ALL_TAB_COLUMNS } from '../../utils/constants';

export default function VerifyTabListView({ VerifiedViewData, onHeaderClick }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <>
      {/* <article className='bd-article'>
        <section className='bd-dashboard'> */}
      <div className='bd-dashboard__container pt-20'>
        <div className='bd-dashboard__lft'>
          <div className='table-scroll'>
            <Tables
              tab={'VerifyAllTab'}
              tableType={'table-type-1'}
              columns={VERIFY_VERIFIED_VIEW_ALL_TAB_COLUMNS}
              tableData={VerifiedViewData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              headerClick={onHeaderClick}
            />
          </div>
          <TablePagination
            component='div'
            count={VerifiedViewData.length}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {/* </section>
      </article> */}
    </>
  );
}
