import React, { useState, useEffect } from 'react';
import download from '../../assets/icon/download.svg';
import DropDown from '../../components/RDropDown';
import {
  BLOOD_GROUP_OPTIONS_1,
  BLOOD_STATIC_COMPONET_LIST,
} from '../../utils/constants';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import DatePicker from '../../components/DatePicker';
// import dashboardReducer from './../../redux/reducers/dashboardReducer';
// import { EditVerifyUsers } from '../../redux/actions/dashboardAction';

export default function EditPopup({
  setEditVerify,
  userDetails,
  onInputChange,
  setInstituteNameFocused,
  instituteNameFocused,
  instituteListObj,
  onInstituteSelect,
  requestClick,
  addFile,
  deleteAttachment,
  // dropdownValue,
  // onDateChange,
  // handleOnChange,
  // createDropdownList,
}) {
  const [bloodGroup, setBloodGroupSelected] = useState(userDetails?.bloodGroup);
  const [selectedComponent, setSelectedComponent] = useState(
    userDetails?.component
  );

  function fetchBloodGroup({ value }) {
    setBloodGroupSelected(value);
  }
  function fetchComponent({ value }) {
    setSelectedComponent(value);
  }
  useEffect(() => {
    setBloodGroupSelected(userDetails?.bloodGroup);
    setSelectedComponent(userDetails?.component);
  }, [userDetails]);

  let list = [];
  const hiddenFileInput = React.useRef(null);

  if (instituteListObj?.data?.institutionLocation) {
    list = [...instituteListObj.data.institutionLocation];
  }

  return (
    <div className='bd-modal-wrap bd-request-blood'>
      <div className='bd-popup si-fade-in' style={{ margin: 'auto' }}>
        <div className='bd-popup__head'>
          <span>Request Blood</span>
          <div className='bd-popup__head--rgt'>
            <i className='bludclose' onClick={() => setEditVerify(false)}></i>
          </div>
        </div>
        <div className='bd-popup__body pt-unset'>
          <div className='bd-form'>
            <div className='bd-form__row'>
              <label>Patient Name</label>
              <input
                type='text'
                placeholder='Patient Name'
                value={userDetails.patientName}
                name='patientName'
                onChange={onInputChange}
                disabled
              />
            </div>
            <div className='bd-form__row bd-twocol mb-unset'>
              <div className='bd-form__row'>
                <label>Patient ID</label>
                <input
                  type='text'
                  placeholder='Patient ID'
                  value={userDetails.patientId}
                  name='patientId'
                  onChange={onInputChange}
                  disabled
                />
              </div>
              <div className='bd-form__row'>
                <label>Mobile No.</label>
                <input
                  type='text'
                  placeholder='Mobile No'
                  value={userDetails.mobileNo}
                  name='mobileNo'
                  onChange={onInputChange}
                  disabled
                />
              </div>
            </div>
            <div className='bd-form__row'>
              <label>Hospital Name</label>
              <input
                type='text'
                placeholder='Hospital'
                value={userDetails.hospitalName}
                name='hospitalName'
                onChange={onInputChange}
                // onChange={(e) => onInputChange(e)}
                onFocus={(e) => setInstituteNameFocused(true)}
                disabled
              />
            </div>
            {instituteNameFocused && list.length && (
              <div className='autoNameList'>
                {list.map((data, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => onInstituteSelect(data)}
                      style={{
                        borderRadius: 1,
                        borderColor: 'grey',
                        borderWidth: 1,
                        padding: 4,
                      }}
                    >
                      <div>{data.institutionName},</div>
                      <div>
                        {data.location &&
                          data.location &&
                          data.location.description}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className='bd-form__row'>
              <label>Hospital Location</label>
              <input
                type='text'
                placeholder='Address'
                value={userDetails.address.description}
                name='address'
                disabled
              />
            </div>
            <div className='bd-form__row'>
              <label>Blood Components</label>
              <div className='bd-dorpdown'>
                <div className='bd-dorpdown__selected'>
                  <DropDown
                    options={BLOOD_STATIC_COMPONET_LIST}
                    fetchDropDownValue={fetchComponent}
                    placeHolderText={userDetails?.component}
                  />
                  <i className='bludbig-arrow' />
                </div>
              </div>
            </div>
            <div className='bd-form__row'>
              <label>Blood group</label>
              <div className='bd-dorpdown'>
                <div className='bd-dorpdown__selected'>
                  <DropDown
                    options={BLOOD_GROUP_OPTIONS_1}
                    fetchDropDownValue={fetchBloodGroup}
                    placeHolderText={userDetails?.bloodGroup}
                  />
                  <i className='bludbig-arrow' />
                </div>
              </div>
            </div>

            <div className='bd-form__row bd-twocol  mb-unset'>
              <div className='bd-form__row'>
                <label>Required units</label>
                <input
                  type='text'
                  placeholder='Units'
                  value={userDetails.units}
                  name='units'
                  onChange={onInputChange}
                />
              </div>
              {/* </div> */}
              {/* <div className='bd-form__row'>
              <div className='bd-dorpdown'>
                <div className='bd-dorpdown__selected bd-calender'>
                  <span style={{ paddingRight: '6px' }}>Required before</span>
                  <DatePicker getSelectedDate={onDateChange} disabled />
                </div>
              </div>
            </div> */}
              <div className='bd-form__row'>
                <label>Required before</label>
                <input
                  type='text'
                  placeholder='Required before'
                  value={userDetails?.requiredBy}
                  name={'locationToDonate'}
                  onChange={onInputChange}
                  disabled
                />
              </div>
            </div>
            <div className='bd-form__row'>
              <label>Donation Location</label>
              <input
                type='text'
                placeholder='Location to Donate'
                value={userDetails?.donationLocation?.description}
                name={'locationToDonate'}
                onChange={onInputChange}
                disabled
              />
            </div>
            <div className='bd-form__row'>
              <label>Comment</label>
              <input
                type='text'
                placeholder='Comments'
                value={userDetails.comment}
                name={'comment'}
                onChange={onInputChange}
                disabled
              />
            </div>
            <input
              type='file'
              name='attachments'
              id='upload'
              hidden
              ref={hiddenFileInput}
              onChange={addFile}
            />
            <div
              className='bd-btn bd-btn--outline ml-10'
              for='upload'
              onClick={() => hiddenFileInput.current.click()}
            >
              <span for='upload'>Attach Files</span>
            </div>
            <div className='bd-attachment__file'>
              {userDetails.attachments &&
                userDetails.attachments.map((attachment, i) => {
                  return (
                    <a
                      className='download-a'
                      download
                      href={attachment.location}
                    >
                      <img
                        className='download-img'
                        src={download}
                        alt='download'
                      />
                      <div className='attachment-name'>{attachment.name}</div>
                      <i
                        className='bluddelete paddingTop_3'
                        onClick={(e) => deleteAttachment(e, i)}
                      />
                    </a>
                  );
                })}
            </div>
            <div className='bd-request__share'>
              <div
                className='bd-btn bd-btn--outline'
                onClick={() => setEditVerify(false)}
              >
                <span>Cancel</span>
              </div>
              <div
                className='bd-btn bd-btn--outline bd-btn--accept active'
                onClick={() =>
                  requestClick(userDetails, bloodGroup, selectedComponent)
                }
              >
                <span>Request</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bd-popup__lightbox'></div>
    </div>
  );
}
