import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader } from '../../components';
import { formatDate } from '../../utils';

export default function CampsTab({ campsObject }) {
  const { loginObj } = useSelector((state) => state.loginReducer);
  let institutionType = loginObj?.data?.content?.data?.institutionType ?? '';
  return (
    <div>
      <div className='bd-tab__header'>
        <span className='bd-tab__header--txt'></span>
        <span className='bd-viewall'>
          <Link to={`/camp`}>View all</Link>
        </span>
      </div>
      <div className='bd-tbl bd-tbl__theme1 bd-tbl__theme1-new'>
        {campsObject && campsObject.length > 0 && (
          <div className='bd-tbl__head'>
            <div className='bd-tbl__row'>
              <div className='bd-tbl__cell'>Date</div>
              <div className='bd-tbl__cell'>Camp Name</div>
              <div className='bd-tbl__cell'>Venue</div>
              <div className='bd-tbl__cell'>Organiser</div>
              <div className='bd-tbl__cell'>Mobile no.</div>
              {institutionType !== 'NGO' && (
                <div className='bd-tbl__cell'>Donors</div>
              )}
            </div>
          </div>
        )}
        <div class='bd-tbl__body'>
          {campsObject && campsObject.length > 0 ? (
            campsObject
              .map((obj, i) => {
                return (
                  <div className='bd-tbl__row' key={i}>
                    <div className='bd-tbl__cell name-cell'>
                      {obj.campDuration?.startTime
                        ? formatDate(obj.campDuration?.startTime)
                        : '-'}
                    </div>
                    <div className='bd-tbl__cell '>{obj.campName}</div>
                    <div className='bd-tbl__cell'>{obj?.venue}</div>
                    <div className='bd-tbl__cell'>
                      {obj?.organiser ? obj?.organiser : '-'}
                    </div>
                    <div className='bd-tbl__cell'>
                      {obj?.bloodBankCoordinator?.mobileNumber
                        ? obj?.bloodBankCoordinator?.mobileNumber
                        : '-'}
                    </div>

                    {institutionType !== 'NGO' && (
                      <div className='bd-tbl__cell'>
                        {obj?.noDonors ? obj?.noDonors : '-'}
                      </div>
                    )}
                  </div>
                );
              })
              .filter((a, i) => i < 3)
          ) : (
            <div className='bd-tbl__body--scroll noData_center'>
              <div className='bd-tbl__body'>
                <div className='bd-noCampData'>
                  <span>Looks like no donation</span>
                  <span>drives planned</span>
                </div>
              </div>
            </div>
          )}
        </div>
        {campsObject.loading && <Loader />}
      </div>
    </div>
  );
}
