import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Loader, Confirmation } from '../../components';
import { CheckBox } from '../../components';
import { sorting } from '../../utils';
import InProcessView from '../../views/InventoryView/InProcessView';
import {
  getInProcess,
  postInProcess,
} from '../../redux/actions/inventoryAction';

function InProcessContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getInProcessData } = useSelector((state) => state.inventoryReducer);

  const [InProcessData, setInProcessData] = useState([]);
  const [search, setSearch] = useState('');
  const [SelectedIds, setSelectedIds] = useState([]);
  // following STATES for popup controle
  const [Confirmationpopup, setConfirmationpopup] = useState(false);
  const [Title, setTitle] = useState('');
  const [btnName, setbtnName] = useState('');
  const [PopupBtnType, setPopupBtnType] = useState('');
  // following State for Peginations
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function onHeaderClick(e) {
    let attribute = e.target.getAttribute('dataindex');
    let data = [...InProcessData];
    data.sort((a, b) => {
      if (count % 2 === 0) {
        return sorting(a, b, attribute);
      } else {
        return sorting(b, a, attribute);
      }
    });
    setInProcessData(data);
    setCount(count + 1);
  }

  function headerDropDownChange(e) {
    let data = setData(getInProcessData?.data?.data);
    if (e.value === 'All/Any') {
      data = [...setData(getInProcessData?.data?.data)];
    } else {
      data = data.filter((a) => a.bloodGroup === e.value);
    }
    setInProcessData(data);
  }

  let abc = [];
  function onCheckboxChange(e) {
    let donorId = e.target.id.split('_')[1];
    //1
    if (e.target.checked) {
      abc.push(donorId);
    } else {
      abc = abc.filter((ele) => ele !== donorId);
    }
    // setSelectedIds([]);
    setSelectedIds(abc);
    //2
    // if (abc.findIndex((ele) => ele == donorId) !== -1) {
    //   abc = abc.filter((ele) => ele !== donorId);
    // } else {
    //   abc.push(donorId);
    // }
  }

  function setData(DataList) {
    let data = [];
    DataList &&
      DataList.forEach((ele, i) => {
        let obj = {
          checkbox: (
            <CheckBox
              keyindex={ele?.donorId}
              // id={`checkbox_${ele?.donorId}`}
              id={`checkbox_${ele?._id}`}
              checked={ele.isSelected}
              onChange={onCheckboxChange}
            />
          ),
          id: ele?._id ? ele?._id : '--',
          bagid: ele?.bagId ? ele?.bagId : '--',
          bloodGroup: ele?.bloodGroup ? ele?.bloodGroup : '--',
          donorId: ele?.donorId ? ele?.donorId : '--',
          volume: ele?.volume ? ele?.volume : '--',
          collectionDate: moment(ele?.collectionDate).format('DD MMM YYYY'),
          expirtDate: moment(ele?.expiryDate).format('DD MMM YYYY'),
        };
        data.push(obj);
      });
    return data;
  }

  function searchData(data) {
    return (
      data &&
      data.filter(
        (items) =>
          items.bagid.toLowerCase().includes(search.toLowerCase()) ||
          items.donorId.toLowerCase().includes(search.toLowerCase()) ||
          String(items.bloodGroup)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.collectionDate)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.expirtDate)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          String(items.volume).toLowerCase().includes(search.toLowerCase())
      )
    );
  }

  function searchInProcessList() {
    return search === '' ? InProcessData : searchData(InProcessData);
  }

  useEffect(() => {
    if (getInProcessData?.data?.data)
      setInProcessData(setData(getInProcessData?.data?.data));
  }, [getInProcessData]);

  useEffect(() => {
    dispatch(getInProcess());
  }, []);

  function onButtonClick(type) {
    switch (type) {
      case 'Cancle':
        setSelectedIds([]);
        abc = [];
        break;
      case 'Dispose':
        setConfirmationpopup(true);
        setTitle('Are you sure to Disposed selected units from inventory?');
        setbtnName('Dispose');
        setPopupBtnType('dispose');
        break;
      case 'Renturn':
        setConfirmationpopup(true);
        setTitle('Are you sure to Return selected units to inventory?');
        setbtnName('Return');
        setPopupBtnType('return');
        break;
      case 'Update Stock':
        history.push({
          pathname: '/inventory-in-process/updatestock',
          state: { ids: SelectedIds, volume: '1001' },
        });
        break;
    }
  }

  function onPopupBtnClick(type) {
    switch (type) {
      case 'dispose':
        dispatch(
          postInProcess({ inventoryIds: SelectedIds, type: 'dispose' }, () => {
            dispatch(getInProcess());
            setConfirmationpopup(false);
          })
        );
        break;
      case 'return':
        dispatch(
          postInProcess(
            { inventoryIds: SelectedIds, type: 'returnToInventory' },
            () => {
              dispatch(getInProcess());
              setConfirmationpopup(false);
            }
          )
        );
        break;
    }
  }

  return (
    <>
      {InProcessData && InProcessData.length < 0 ? (
        <Loader />
      ) : (
        <InProcessView
          InProcessData={searchInProcessList()}
          SelectedIds={SelectedIds}
          page={page}
          search={search}
          setSearch={setSearch}
          rowsPerPage={rowsPerPage}
          onHeaderClick={onHeaderClick}
          headerDropDownChange={headerDropDownChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          onButtonClick={onButtonClick}
        />
      )}
      {Confirmationpopup && (
        <Confirmation
          title={Title}
          btnName={btnName}
          onCancleClick={() => setConfirmationpopup(false)}
          onDisposeClick={() => onPopupBtnClick(PopupBtnType)}
        />
      )}
    </>
  );
}

export default InProcessContainer;
