import { makeAPICall, urlSearchParams } from '../../utils';
import {
  API_BASE_PATH,
  ADD_INVENTORY_URL,
  ISSUE_TO_PATIENT_URL,
  ISSUE_TO_PROCESS_URL,
  GET_INVENTORY_DATA_URL,
  GET_INVENTORY_RECEIPT_DATA_URL,
  POST_INVENTORY_RECEIPT_DATA_URL,
  DISPOSE_INVENTORYS_URL,
  GET_AGEING_REPORT_DATA_URL,
  REQUEST_USER_DATA_URL,
  INVENTORY_ADD_PATIENT_URL,
  TRANSFER_LIST_URL,
  BLOOD_COMPONENT_LIST_URL,
  INVENTORY_CHANGE_STATUS_URL,
  GET_IN_PROCESS_DATA_URL,
  POST_IN_PROCESS_DATA_URL,
} from '../../utils/config';
import {
  GET_INVENTORY_DATA,
  GET_INVENTORY_DATA_SUCCESS,
  GET_INVENTORY_DATA_FAILED,
  GET_INVENTORY_RECEIPT_DATA,
  GET_INVENTORY_RECEIPT_DATA_SUCCESS,
  GET_INVENTORY_RECEIPT_DATA_FAILED,
  POST_INVENTORY_RECEIPT_DATA,
  POST_INVENTORY_RECEIPT_DATA_SUCCESS,
  POST_INVENTORY_RECEIPT_DATA_FAILED,
  DISPOSE_INVENTORY_DATA,
  DISPOSE_INVENTORY_DATA_SUCCESS,
  DISPOSE_INVENTORY_DATA_FAILED,
  GET_AGEING_REPORT_DATA,
  GET_AGEING_REPORT_DATA_SUCCESS,
  GET_AGEING_REPORT_DATA_FAILED,
  ADD_INVENTORY,
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_FAILED,
  ISSUE_TO_PATIENT,
  ISSUE_TO_PATIENT_SUCCESS,
  ISSUE_TO_PATIENT_FAILED,
  ISSUE_TO_PROCESS,
  ISSUE_TO_PROCESS_SUCCESS,
  ISSUE_TO_PROCESS_FAILED,
  // DISPOSE_INVENTORY,
  // DISPOSE_INVENTORY_SUCCESS,
  // DISPOSE_INVENTORY_FAILED,
  REQUEST_USER_DATA,
  REQUEST_USER_DATA_SUCCESS,
  REQUEST_USER_DATA_FAILED,
  PATIENT_SPECIFIC_INVENTORY,
  PATIENT_SPECIFIC_INVENTORY_SUCCESS,
  PATIENT_SPECIFIC_INVENTORY_FAILED,
  INVENTORY_ADD_PATIENT,
  INVENTORY_ADD_PATIENT_SUCCESS,
  INVENTORY_ADD_PATIENT_FAILED,
  TRANSFER_INSTITUTE_LIST,
  TRANSFER_INSTITUTE_LIST_SUCCESS,
  TRANSFER_INSTITUTE_LIST_FAILED,
  BLOOD_COMPONENT_LIST,
  BLOOD_COMPONENT_LIST_SUCCESS,
  BLOOD_COMPONENT_LIST_FAILED,
  INVENTORY_TRANSFER_PROCESS,
  INVENTORY_TRANSFER_PROCESS_SUCCESS,
  INVENTORY_TRANSFER_PROCESS_FAILED,
  GET_IN_PROCESS_DATA,
  GET_IN_PROCESS_DATA_SUCCESS,
  GET_IN_PROCESS_DATA_FAILED,
  POST_IN_PROCESS_DATA,
  POST_IN_PROCESS_DATA_SUCCESS,
  POST_IN_PROCESS_DATA_FAILED,
} from './actionTypes';

// inventory Transfer & Ageing_Report GET action
export function getInventoryData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_INVENTORY_DATA_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_INVENTORY_DATA,
      successAction: GET_INVENTORY_DATA_SUCCESS,
      failedAction: GET_INVENTORY_DATA_FAILED,
      callback,
    };
    makeAPICall(params);
  };
}
// inventory Receipt GET action
export function getReceiptData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_INVENTORY_RECEIPT_DATA_URL}`,
      dispatch,
      defaultAction: GET_INVENTORY_RECEIPT_DATA,
      successAction: GET_INVENTORY_RECEIPT_DATA_SUCCESS,
      failedAction: GET_INVENTORY_RECEIPT_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

// GET IN PROCESS DATA
export function getInProcess(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_IN_PROCESS_DATA_URL}`,
      dispatch,
      defaultAction: GET_IN_PROCESS_DATA,
      successAction: GET_IN_PROCESS_DATA_SUCCESS,
      failedAction: GET_IN_PROCESS_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

// POST IN PROCESS DATA  (DISAPOSE , RETURN ) btns
export function postInProcess(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_IN_PROCESS_DATA_URL}`,
      dispatch,
      defaultAction: POST_IN_PROCESS_DATA,
      successAction: POST_IN_PROCESS_DATA_SUCCESS,
      failedAction: POST_IN_PROCESS_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

// inventory Receipt POST action
export function postReceiptData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_INVENTORY_RECEIPT_DATA_URL}`,
      dispatch,
      defaultAction: POST_INVENTORY_RECEIPT_DATA,
      successAction: POST_INVENTORY_RECEIPT_DATA_SUCCESS,
      failedAction: POST_INVENTORY_RECEIPT_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
// inventory Transfer & Ageing_Report POST action
export function disposeInventory(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DISPOSE_INVENTORYS_URL}`,
      dispatch,
      defaultAction: DISPOSE_INVENTORY_DATA,
      successAction: DISPOSE_INVENTORY_DATA_SUCCESS,
      failedAction: DISPOSE_INVENTORY_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function addInventory(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ADD_INVENTORY_URL}`,
      dispatch,
      defaultAction: ADD_INVENTORY,
      successAction: ADD_INVENTORY_SUCCESS,
      failedAction: ADD_INVENTORY_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function issueToPatient(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ISSUE_TO_PATIENT_URL}`,
      dispatch,
      defaultAction: ISSUE_TO_PATIENT,
      successAction: ISSUE_TO_PATIENT_SUCCESS,
      failedAction: ISSUE_TO_PATIENT_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function issueToProcess(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ISSUE_TO_PROCESS_URL}`,
      dispatch,
      defaultAction: ISSUE_TO_PROCESS,
      successAction: ISSUE_TO_PROCESS_SUCCESS,
      failedAction: ISSUE_TO_PROCESS_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getPatientRequestData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${REQUEST_USER_DATA_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: REQUEST_USER_DATA,
      successAction: REQUEST_USER_DATA_SUCCESS,
      failedAction: REQUEST_USER_DATA_FAILED,
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function getPatientRequest(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_INVENTORY_DATA_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: PATIENT_SPECIFIC_INVENTORY,
      successAction: PATIENT_SPECIFIC_INVENTORY_SUCCESS,
      failedAction: PATIENT_SPECIFIC_INVENTORY_FAILED,
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function addPatientFromInventory(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${INVENTORY_ADD_PATIENT_URL}`,
      dispatch,
      defaultAction: INVENTORY_ADD_PATIENT,
      successAction: INVENTORY_ADD_PATIENT_SUCCESS,
      failedAction: INVENTORY_ADD_PATIENT_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getTransferInstituteList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${TRANSFER_LIST_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: TRANSFER_INSTITUTE_LIST,
      successAction: TRANSFER_INSTITUTE_LIST_SUCCESS,
      failedAction: TRANSFER_INSTITUTE_LIST_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function getBloodComponentList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${BLOOD_COMPONENT_LIST_URL}`,
      dispatch,
      defaultAction: BLOOD_COMPONENT_LIST,
      successAction: BLOOD_COMPONENT_LIST_SUCCESS,
      failedAction: BLOOD_COMPONENT_LIST_FAILED,
      type: 'GET',
      headers: {},
      callback,
    };
    makeAPICall(params);
  };
}

export function transferProcessInventory(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${INVENTORY_CHANGE_STATUS_URL}`,
      dispatch,
      defaultAction: INVENTORY_TRANSFER_PROCESS,
      successAction: INVENTORY_TRANSFER_PROCESS_SUCCESS,
      failedAction: INVENTORY_TRANSFER_PROCESS_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function getAgeingReportData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_AGEING_REPORT_DATA_URL}`,
      dispatch,
      defaultAction: GET_AGEING_REPORT_DATA,
      successAction: GET_AGEING_REPORT_DATA_SUCCESS,
      failedAction: GET_AGEING_REPORT_DATA_FAILED,
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
