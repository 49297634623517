import {
  GET_CAMPS_LIST_DATA,
  GET_CAMPS_LIST_DATA_SUCCESS,
  GET_CAMPS_LIST_DATA_FAILED,
  // POST DONATE FORM DATA --  SUDARSHAN
  POST_DONATE_FORM,
  POST_DONATE_FORM_SUCCESS,
  POST_DONATE_FORM_FAILED,
  // GET ON PROCEED CLICK --  SUDARSHAN
  GET_ON_PROCEED_CLICK,
  GET_ON_PROCEED_CLICK_SUCCESS,
  GET_ON_PROCEED_CLICK_FAILED,
  POST_CAMPS_DATA,
  POST_CAMPS_DATA_SUCCESS,
  POST_CAMPS_DATA_FAILED,
  GET_CAMPS_REQUEST_DATA,
  GET_CAMPS_REQUEST_DATA_FAILED,
  GET_CAMPS_REQUEST_DATA_SUCCESS,
  // POST ACCEPT/DECLINE PENDING CAMPS CLICK -- SUDARSHAN
  TRIGGER_ACCEPT_DECLINE_CAMP,
  TRIGGER_ACCEPT_DECLINE_CAMP_SUCCESS,
  TRIGGER_ACCEPT_DECLINE_CAMP_FAILED,
  UPDATE_CAMPS_DATA,
  UPDATE_CAMPS_DATA_FAILED,
  UPDATE_CAMPS_DATA_SUCCESS,
  TRIGGER_GET_ADDRESS_LIST,
  TRIGGER_GET_ADDRESS_LIST_FAILED,
  TRIGGER_GET_ADDRESS_LIST_SUCCESS,
  TRIGGER_DELETE_CAMP,
  TRIGGER_DELETE_CAMP_SUCCESS,
  TRIGGER_DELETE_CAMP_FAILED,
  TRIGGER_DEACTIVATE_CAMP,
  TRIGGER_DEACTIVATE_CAMP_SUCCESS,
  TRIGGER_DEACTIVATE_CAMP_FAILED,
  TRIGGER_DECLINE_CAMP,
  TRIGGER_DECLINE_CAMP_SUCCESS,
  TRIGGER_DECLINE_CAMP_FAILED,
  GET_CAMPS_DETAILS,
  GET_CAMPS_DETAILS_FAILED,
  GET_CAMPS_DETAILS_SUCCESS,
  // NGO CREATED CAMP LIST
  GET_NGO_CREATED_CAMP,
  GET_NGO_CREATED_CAMP_FAILED,
  GET_NGO_CREATED_CAMP_SUCCESS,
  // GET PARTICIPATING BB
  GET_PARTICIPATING_BB,
  GET_PARTICIPATING_BB_SUCCESS,
  GET_PARTICIPATING_BB_FAILED,
} from '../actions/actionTypes';

const payload = [];

const requestPayload = [];

const initialState = {
  campsObject: {
    loading: false,
    data: payload,
    error: null,
  },

  campsRequestObject: {
    loading: false,
    data: requestPayload,
    error: null,
  },
  camp: {
    loading: false,
    data: {},
    error: null,
  },
  accept_decline: {
    loading: false,
    data: {},
    error: null,
  },
  donorDonateForm: {
    loading: false,
    data: {},
    error: null,
  },
  onProceedclick: {
    loading: false,
    data: {},
    error: null,
  },
  campDetails: {
    loading: false,
    data: {},
    error: null,
  },
  deleteCamp: {
    loading: false,
    data: {},
    error: null,
  },
  getNgoCamp: {
    loading: false,
    data: {},
    error: null,
  },
  participatingBBlist: {
    loading: false,
    data: {},
    error: null,
  },
};
const campsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_CAMPS_LIST_DATA:
      return {
        ...state,
        campsObject: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_CAMPS_LIST_DATA_SUCCESS:
      return {
        ...state,
        campsObject: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_CAMPS_LIST_DATA_FAILED:
      return {
        ...state,
        campsObject: {
          loading: false,
          data: [],
          error: action.error,
        },
      };
    case GET_ON_PROCEED_CLICK:
      return {
        ...state,
        onProceedclick: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case GET_ON_PROCEED_CLICK_SUCCESS:
      return {
        ...state,
        onProceedclick: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_ON_PROCEED_CLICK_FAILED:
      return {
        ...state,
        onProceedclick: {
          loading: false,
          data: [],
          error: action.error,
        },
      };

    case GET_CAMPS_REQUEST_DATA:
      return {
        ...state,
        campsRequestObject: {
          loading: false,
          data: [],
          error: action.error,
        },
      };

    case GET_CAMPS_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        campsRequestObject: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_CAMPS_REQUEST_DATA_FAILED:
      return {
        ...state,
        campsRequestObject: {
          loading: false,
          data: requestPayload,
          error: action.error,
        },
      };

    case UPDATE_CAMPS_DATA:
      return {
        ...state,
        camp: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case UPDATE_CAMPS_DATA_SUCCESS:
      return {
        ...state,
        camp: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case UPDATE_CAMPS_DATA_FAILED:
      return {
        ...state,
        camp: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case POST_CAMPS_DATA:
      return {
        ...state,
        camp: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case POST_CAMPS_DATA_SUCCESS:
      return {
        ...state,
        camp: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case POST_CAMPS_DATA_FAILED:
      return {
        ...state,
        camp: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case POST_DONATE_FORM:
      return {
        ...state,
        donorDonateForm: {
          loading: true,
          data: [],
          error: null,
        },
      };

    case POST_DONATE_FORM_SUCCESS:
      return {
        ...state,
        donorDonateForm: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case POST_DONATE_FORM_FAILED:
      return {
        ...state,
        donorDonateForm: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_ACCEPT_DECLINE_CAMP:
      return {
        ...state,
        accept_decline: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_ACCEPT_DECLINE_CAMP_SUCCESS:
      return {
        ...state,
        accept_decline: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_ACCEPT_DECLINE_CAMP_FAILED:
      return {
        ...state,
        accept_decline: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_DECLINE_CAMP:
      return {
        ...state,
        camp: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_DECLINE_CAMP_SUCCESS:
      return {
        ...state,
        camp: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_DECLINE_CAMP_FAILED:
      return {
        ...state,
        camp: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_GET_ADDRESS_LIST:
      return {
        ...state,
        addressList: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_GET_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        addressList: {
          loading: false,
          data: action.payload.content.institutionLocation,
          error: null,
        },
      };

    case TRIGGER_GET_ADDRESS_LIST_FAILED:
      return {
        ...state,
        addressList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_DELETE_CAMP:
      return {
        ...state,
        camp: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_DELETE_CAMP_SUCCESS:
      return {
        ...state,
        camp: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_DELETE_CAMP_FAILED:
      return {
        ...state,
        camp: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case TRIGGER_DEACTIVATE_CAMP:
      return {
        ...state,
        camp: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case TRIGGER_DEACTIVATE_CAMP_SUCCESS:
      return {
        ...state,
        camp: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case TRIGGER_DEACTIVATE_CAMP_FAILED:
      return {
        ...state,
        camp: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case GET_CAMPS_DETAILS:
      return {
        ...state,
        campDetails: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case GET_CAMPS_DETAILS_SUCCESS:
      return {
        ...state,
        campDetails: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_CAMPS_DETAILS_FAILED:
      return {
        ...state,
        campDetails: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_NGO_CREATED_CAMP:
      return {
        ...state,
        getNgoCamp: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case GET_NGO_CREATED_CAMP_SUCCESS:
      return {
        ...state,
        getNgoCamp: {
          loading: false,
          data: action.payload.content.data,
          error: null,
        },
      };

    case GET_NGO_CREATED_CAMP_FAILED:
      return {
        ...state,
        getNgoCamp: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    case GET_PARTICIPATING_BB:
      return {
        ...state,
        participatingBBlist: {
          loading: false,
          data: [],
          error: null,
        },
      };

    case GET_PARTICIPATING_BB_SUCCESS:
      return {
        ...state,
        participatingBBlist: {
          loading: false,
          data: action.payload.content.institutionLocation,
          error: null,
        },
      };

    case GET_PARTICIPATING_BB_FAILED:
      return {
        ...state,
        participatingBBlist: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default campsReducer;
