import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./utils/config/routes/privateRoutes";
import PublicRoute from "./utils/config/routes/publicRoutes";
import { publicRoutesList, privateRoutesList} from "./utils/config/routes/routeConfig";
import PageNotFound from "./components/PageNotFound";
import "./assets/css/style.css";
import "./assets/mycss.css";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {publicRoutesList.map((routes, index) => {
            return (
              <PublicRoute
                key={index}
                restricted={routes.restricted}
                component={routes.component}
                path={routes.path}
                exact
              />
            );
          })}

          {privateRoutesList.map((routes, index) => {
            return (
              <PrivateRoute
                key={index}
                restricted={routes.restricted}
                component={routes.component}
                path={routes.path}
                exact
                subRoutes={routes.userRoutes}
              />
            );
          })}
          <Route path="" component={PageNotFound}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
