import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import InventoryTableList from '../../components/InventoryTableList';
import { INVENTORY_AGEING_COLUMNS } from '../../utils/constants';
import { disposeInventory } from '../../redux/actions/inventoryAction';

function AgeingView({ data, bloodComponentList }) {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedListIds, setSelectedIds] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rawData, setRawData] = useState(data);
  const [showFail, setShowFail] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { inventoryDisposeObj } = useSelector(
    (state) => state.inventoryReducer
  );
  function disposeAgeing() {
    dispatch(
      disposeInventory(
        {
          type: 'dispose',
          inventoryIds: selectedListIds,
        },
        () => {
          setShowConfirmation(false);
          setShowSuccess(true);
        }
      )
    );
  }

  function onCancelClick() {
    let cloneData = [...data];
    cloneData.forEach((item) => {
      item.isSelected = false;
    });
    setSelectedIds([]);
    setRawData(cloneData);
  }

  function showErrorPopUp() {
    inventoryDisposeObj.loading ? setLoading(true) : setLoading(false);

    if (inventoryDisposeObj.error) {
      setError(inventoryDisposeObj.error);
      setShowFail(true);
    }
  }
  function hideErrorPopUp() {
    setShowFail(false);
    // dispatch({ type: DISPOSE_INVENTORY_DATA, loading: false });
  }

  useEffect(() => {
    showErrorPopUp();
  }, [inventoryDisposeObj]);

  return (
    <React.Fragment>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <InventoryTableList
            title={'Ageing-Report'}
            data={rawData}
            columns={INVENTORY_AGEING_COLUMNS}
            disposeAgeing={disposeAgeing}
            selectedListIds={selectedListIds}
            setSelectedIds={setSelectedIds}
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
            showConfirmation={showConfirmation}
            setShowConfirmation={setShowConfirmation}
            hideErrorPopUp={hideErrorPopUp}
            showFail={showFail}
            // setShowFail={setShowFail}
            error={error}
            // setError={setError}
            loading={loading}
            bloodComponentList={bloodComponentList} //boolComponent
          />
          {selectedListIds.length ? (
            <div className='bd-btn__wrap'>
              <div className='bd-btn bd-btn--outline' onClick={onCancelClick}>
                <span>Cancel</span>
              </div>
              <div
                className='bd-btn bd-btn--primary'
                onClick={() => setShowConfirmation(true)}
              >
                <span>Dispose</span>
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
      </article>
    </React.Fragment>
  );
}

export default AgeingView;
