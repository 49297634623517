import {
  POST_PATIENT_DATA,
  POST_PATIENT_DATA_FAILED,
  POST_PATIENT_DATA_SUCCESS,
  GET_PATIENT_LIST,
  GET_PATIENT_LIST_FAILED,
  GET_PATIENT_LIST_SUCCESS,
} from './actionTypes';
import { makeAPICall } from '../../utils';
import {
  API_BASE_PATH,
  GET_PATIENT_LIST_URL,
  POST_PATIENT_DATA_URL,
} from '../../utils/config';

export function triggerAddPatient(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_PATIENT_DATA_URL}`,
      dispatch,
      defaultAction: POST_PATIENT_DATA,
      successAction: POST_PATIENT_DATA_SUCCESS,
      failedAction: POST_PATIENT_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerPatientList(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_PATIENT_LIST_URL}`,
      dispatch,
      defaultAction: GET_PATIENT_LIST,
      successAction: GET_PATIENT_LIST_SUCCESS,
      failedAction: GET_PATIENT_LIST_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
