import {
  // RECENT_CHATS,
  CHAT_DETAIL,
  CHAT_DATA,
  CHAT_DATA_SUCCESS,
  CHAT_DATA_FAILED,
} from '../actions/actionTypes';

const initialState = {
  recentChatList: {
    loading: false,
    data: null,
    error: null,
  },
  chatDetail: [],
};
const chatReducer = function (state = initialState, action) {
  switch (action.type) {
    case CHAT_DATA: {
      return {
        ...state,
        recentChatList: {
          loading: action.loading === false ? action.loading : true,
          data: null,
          error: action.error,
        },
      };
    }

    case CHAT_DATA_SUCCESS:
      return {
        ...state,
        recentChatList: {
          loading: false,
          data: action.payload.content,
          error: null,
        },
      };

    case CHAT_DATA_FAILED:
      return {
        ...state,
        recentChatList: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case CHAT_DETAIL: {
      return {
        ...state,
        chatDetail: {
          ...action.payload.content,
        },
      };
    }
    default:
      return state;
  }
};

export default chatReducer;
