import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCampsListData,
  triggerAddCamps,
  triggerFetchAddress,
} from '../../redux/actions/campsAction';
import { API_BASE_PATH, UPLOAD_FILE_URL } from '../../utils/config';
import axios from 'axios';
import CreateCampView from '../../views/CampView/CreateCampView';

export default function CreateCampConatiner({
  toggleForm,
  addCamps,
  setShowSuccess,
  setShowFail,
}) {
  const dispatch = useDispatch();
  const [addCampsObj, setAddCampsObj] = useState(addCamps);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [addressFocused, setaddressFocused] = useState(false);
  // const [didMount, setDidMount] = useState(false);

  const inputFile = useRef(null);
  const hiddenFileInput = React.useRef(null);
  const [attachments, setAttachments] = useState([]);

  const {
    campName,

    SponsoredBy,
    organiser,
    noOfBeds,
    targetUnits,
    date,
    fromTime,
    toTime,
    SponsorCoordinator,
    SponsoredMobileNo,
    SponsoredEmailId,
    bloodBankCoordinator,
    bloodBankedMobileNo,
    bloodBankedEmailId,
    venue,
    address,
    id,
  } = addCampsObj;

  useEffect(() => {
    dispatch(getCampsListData());
  }, []);

  function handleClick() {
    inputFile.current.click();
  }

  function deleteAttachment(e, index) {
    e.preventDefault();
    let files = [...addCampsObj?.attachments];
    files.splice(index, 1);
    setAttachments(files);
    setAddCampsObj({ ...addCampsObj, attachments: files });
  }

  const addFile = async (e) => {
    let selectedFile = e.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    let response = await axios.post(API_BASE_PATH + UPLOAD_FILE_URL, formData, {
      headers: {
        token: `${localStorage.getItem('TOKEN_KEY')}`,
      },
    });
    let file = response.data.content.savedFiles[0];
    let arr = [...addCampsObj?.attachments];

    if (file) arr.push(file);
    setAddCampsObj({ ...addCampsObj, attachments: arr });
    setAttachments(arr);
  };

  function onChangeText(e, name) {
    let updateAddCampsObj = { ...addCampsObj };

    if (name == 'date') {
      updateAddCampsObj[name] = new Date(e);
    } else {
      updateAddCampsObj[e.target.name] = e.target.value;
    }
    setAddCampsObj(updateAddCampsObj);
  }

  const locationMap = 'location';

  function onAddressSearch(e) {
    if (e.target.value !== '') {
      onChangeText(e);
      return dispatch(triggerFetchAddress(e.target.value, locationMap));
    }
  }

  function selectAddress(data) {
    setaddressFocused(false);
    let updateAddCampsObj;
    updateAddCampsObj = { ...addCampsObj };
    updateAddCampsObj['address'] = data?.address;

    setAddCampsObj(updateAddCampsObj);
  }

  function CloseAddCamp() {
    toggleForm();
  }

  function onSubmit(type) {
    if (
      (campName === '' ||
        address === '' ||
        fromTime === '' ||
        toTime === '' ||
        date === '' ||
        venue === '' ||
        SponsorCoordinator === '' ||
        SponsoredMobileNo === '' ||
        SponsoredEmailId === '' ||
        bloodBankCoordinator === '' ||
        bloodBankedMobileNo === '' ||
        bloodBankedEmailId === '' ||
        targetUnits === '' ||
        targetUnits < 100 ||
        SponsoredBy === '' ||
        noOfBeds === '') &&
      type !== 'saveAsDraft'
    ) {
      setShowFail(true);
      return 0;
    }

    let params = {
      campName,
      SponsoredBy,
      organiser,
      noOfBeds,
      targetUnits,
      campCoordinator: {
        name: SponsorCoordinator,
        mobileNumber: SponsoredMobileNo,
        email: SponsoredEmailId,
      },
      bloodBankCoordinator: {
        name: bloodBankCoordinator,
        mobileNumber: bloodBankedMobileNo,
        email: bloodBankedEmailId,
      },
      venue,
      address,
      attachments,
    };

    if (type === 'saveAsDraft' && !id) {
      params.status = 'Draft';
    }
    if (fromTime && toTime && date) {
      params.campDuration = {
        startTime: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          fromTime.split(':')[0],
          fromTime.split(':')[1]
        ).toISOString(),
        endTime: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          toTime.split(':')[0],
          toTime.split(':')[1]
        ).toISOString(),
      };
    }

    return dispatch(
      triggerAddCamps(params, () => {
        toggleForm();
        dispatch(getCampsListData());
        setShowSuccess(true);
      })
    );
  }

  return (
    <CreateCampView
      addCampsObj={addCampsObj}
      uploadingFile={uploadingFile}
      inputFile={inputFile}
      addressFocused={addressFocused}
      onCancelClick={CloseAddCamp}
      handleClick={handleClick}
      onChangeText={onChangeText}
      onSubmit={onSubmit}
      onAddressSearch={onAddressSearch}
      selectAddress={selectAddress}
      setaddressFocused={setaddressFocused}
      setAttachments={setAttachments}
      hiddenFileInput={hiddenFileInput}
      addFile={addFile}
      deleteAttachment={deleteAttachment}
    />
  );
}
