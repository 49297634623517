import React from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from '../../components';
import Google from '../../assets/images/Google.png';
import Apple from '../../assets/images/Apple.png';

const SuperAdminLoginView = (props) => {
  return (
    <div className='bd-main'>
      <div className='bd-container-fluid center'>
        <div className='bd-registration'>
          <div className='bd-login'>
            {/* <div className="bd-logo"></div> */}
            <div className='bd-registration__body'>
              <div className='bd-signin'>
                <div className='bd-registration__header'>
                  <span>Super Admin Login</span>
                </div>
                <div className='bd-form'>
                  <div className='bd-form__row'>
                    <label>Email ID </label>
                    <input
                      type='text'
                      style={{ textTransform: 'unset' }}
                      name='loginId'
                      placeholder='Enter your Email ID'
                      onChange={props.onChangeText}
                      autoFocus
                    />
                  </div>
                  <div className='bd-form__row bd-form__row--pass'>
                    <label>Password</label>
                    <input
                      type={props.showPassword ? 'text' : 'Password'}
                      placeholder='Enter your password'
                      name='password'
                      onChange={props.onChangeText}
                    />
                    <i
                      onClick={props.showPasswordToggle}
                      className={
                        props.showPassword ? 'bd-eyeIcon bd-show' : 'bd-eyeIcon'
                      }
                    ></i>
                  </div>
                  <div className='bd-form__row bd-form__row--forgotpass'>
                    <span className='bd-incorrect__msg'>
                      {props?.errorMsg ?? ''}
                      {/* {(props?.errorMsg || props?.loginObj?.error) ?? ''} */}
                    </span>
                    <div className='bd-forgot-pass'>
                      <Link to='/forgetPassword'>Forgot Password?</Link>
                    </div>
                  </div>
                  <div className='bd-form__row bd-form__row--signin'>
                    <div
                      className='bd-btn bd-btn--primary'
                      onClick={props.onSubmit}
                      disabled={props.loginObj.loading}
                    >
                      {/* <span>Sign in</span> */}
                      {props.loginObj.loading ? (
                        <Spinner />
                      ) : (
                        <span>SuperAdmin Sign in</span>
                      )}
                    </div>
                  </div>
                  {/* 
                  <div className='bd-form__row bd-form__row--signup'>
                    <span>
                      Don’t have an Account? <Link to='/signup'>Register</Link>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='bd_box text-center' style={{ marginTop: '80px' }}>
            <div className='bd_text_content'>
              Patients & Donors, please use the mobile app
            </div>
            <div
              className='logo_sepration '
              style={{ 'justify-content': 'center' }}
            >
              <a href='https://play.google.com/store/apps/details?id=com.serapis.blud'>
                <img
                  src={Google}
                  height='40'
                  width='135'
                  style={{ 'margin-right': '10px' }}
                  alt='img'
                />
              </a>
              <a href='#'>
                <img src={Apple} height='40' width='135' alt='img' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminLoginView;
