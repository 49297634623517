import React from "react";

function TableThree(props) {
  return (
    <>
      Table three {props.headers} and {props.data}
    </>
  );
}

export default TableThree;
