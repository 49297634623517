import React from "react";
import {LandscapeImage} from "../../components/Auth";
import {AuthHeader} from "../../components/AuthHeader";


function ChangePasswordContainer() {
  return (

<>
    <div className="bd-main ">
    <AuthHeader />
    <div className="bd-container-fluid">

      <div className="bd-registration_main_grid">

        <div>
          <LandscapeImage />
        </div>

        <div>
        <div className="bd-main bg-white">
      <div className="bd-container-fluid ">
        <div className="bd-registration bd-setpassword"  style={{marginTop:"65px"}}>
          {/* <div
            className="bd-logo"
            // style="background-image: url(../images/default-logo.png);"
          ></div> */}
          <div className="bd-registration__body">
            <div className="">
              <div className="">
                <div className="bd-registration__header">
                  <span>Change Password</span>
                </div>
                <div className="bd-form">
                  <div className="bd-form__row bd-form__row--pass">
                    <input type="Password" placeholder="Enter Password" />
                    <i className="bd-eyeIcon bd-show"></i>
                  </div>
                  <div className="bd-form__row bd-form__row--pass">
                    <input type="Password" placeholder="Re-enter Password" />
                    <i className="bd-eyeIcon"></i>
                  </div>

                  <div className="bd-form__row bd-form__row--setpass">
                    <div className="bd-btn bd-btn--primary">
                      <span>Set Password</span>
                    </div>
                  </div>
                </div>
              </div>

              
      
            </div>
            <hr style={{borderColor: 'rgba(151, 151, 151, 0.2)'}}/>
 
            <div className="bd-setpass__rgt">
              <div className="bd-registration__header bd-changepass-header"> 
                  <span>Password must have 8 characters</span>
                </div>
                <ul>
                  <li>You can have a combination of:</li>
                  <li>- Upper-case characters (A-Z)</li>
                  <li>- Lower-case characters (a-z)</li>
                  <li>- Numbers (0-9) </li>
                  <li>- Special characters (!,@,#,$,%,^,&,*,etc)  </li>
                </ul>
              </div>

          </div>
        </div>
      </div>
    </div>
        </div>


        </div>

        </div>
        </div>

    </>
  );
}

export default ChangePasswordContainer;
