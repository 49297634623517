import React from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
// import download from '../../assets/icon/download.svg';
import { BLOOD_COMPONENTS, titleCase } from '../../utils/constants';
export default function RequestTab({ userRequestData, onAcceptClick }) {
  const { loginObj } = useSelector((state) => state.loginReducer);
  let institutionType = loginObj?.data?.content?.data?.institutionType ?? '';
  return (
    <React.Fragment>
      <div className='bd-tbl bd-tbl__theme1 bd-tbl__theme1-new bd-req-tab'>
        {userRequestData?.data?.length > 0 && (
          <div className='bd-tbl__head'>
            <div className='bd-tbl__row'>
              <div className='bd-tbl__cell fw-600'>Name</div>
              <div className='bd-tbl__cell fw-600'>Blood Group</div>
              <div className='bd-tbl__cell fw-600'>Units</div>
              <div className='bd-tbl__cell bd-component fw-600'>Component</div>
              <div className='bd-tbl__cell fw-600'>Condition</div>
              <div className='bd-tbl__cell fw-600'>Hospital</div>
              <div className='bd-tbl__cell fw-600'>Required by</div>
            </div>
          </div>
        )}
        {userRequestData?.data?.length > 0 ? (
          userRequestData?.data
            ?.map((request, i) => {
              request.calUnits = 0;
              if (request.bulkRequest) {
                for (let bulk in request.bulkRequest) {
                  request.bulkRequest[bulk].map((ele) => {
                    request.calUnits += ele.units - ele.recieved;
                  });
                }
              } else {
                request.calUnits += request.units - request.unitsReceived;
              }
              // if (request.details === undefined) return null;
              let date = new Date(request.requiredBy).toDateString().split(' ');
              // let requestType = request.requestType;
              return (
                <div className='bd-tbl__row'>
                  <div className='bd-tbl__cell name-cell'>
                    {request?.details
                      ? titleCase(request?.details?.firstName) +
                        ' ' +
                        titleCase(request?.details?.lastName)
                      : request?.bloodBankName || ' ' + request?.hospitalName}
                  </div>
                  <div className='bd-tbl__cell text-center fw-600'>
                    {request.bloodGroup || 'Bulk'}
                  </div>
                  <div className='bd-tbl__cell text-center'>
                    {request.calUnits}
                  </div>
                  <div className='bd-tbl__cell'>
                    {BLOOD_COMPONENTS[request.component] ||
                      request.component ||
                      'Bulk requirment'}
                  </div>
                  <div className='bd-tbl__cell'>
                    {request?.condition ? request.condition : '--'}
                  </div>
                  <div className='bd-tbl__cell'>
                    {request?.hospitalName ? request?.hospitalName : '--'}
                  </div>
                  <div className='bd-tbl__cell'>
                    {date[2]} {date[1]} {date[3]}
                  </div>
                  {request?.subType === 'Bulk' && institutionType === 'NGO' ? (
                    <div className='bd-tbl__cell '>
                      <div class='bd-request-otp cursor_notAllow'>
                        <div className='bd-request-dul brder_Radius bd-request-otp-stars'>
                          <span>
                            <i className='bludaccept'></i>
                          </span>
                        </div>
                        <div className=' bd-request-dul bd-black active'>
                          <span>Accept </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='bd-tbl__cell '>
                      <div class='bd-request-otp' id={request._id}>
                        <div
                          className='bd-request-accept-blur brder_Radius bd-request-otp-stars'
                          onClick={onAcceptClick}
                          id={request._id}
                        >
                          <span id={request._id} onClick={onAcceptClick}>
                            <i id={request._id} className='bludaccept'></i>
                          </span>
                        </div>
                        <div
                          onClick={onAcceptClick}
                          id={request._id}
                          className=' bd-request-accept bd-black active'
                        >
                          <span id={request._id}>Accept </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
            .filter((a, i) => i < 3)
        ) : (
          <div className='bd-tbl__body--scroll noData_center'>
            <div className='bd-tbl__body'>
              <div className='bd-noRequestData'>
                <span> No New Request </span>
                <span> for blood </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
