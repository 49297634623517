import React from "react";
import {Spinner} from "../../components";
import {LandscapeImage} from "../../components/Auth";
import {AuthHeader} from "../../components/AuthHeader";


function OtpView(props) {
  return (
    <div className="bd-main">
      <AuthHeader />
      <div className="bd-container-fluid">

        <div className="bd-registration_main_grid">

          <LandscapeImage />
          <div>
            
          <div className="bd-registration" style={{marginTop: "65px"}}>
            {/* <div className="bd-logo"></div> */}
            <div className="bd-registration__body">
              <div className="bd-signin bd-otp">
                <div className="bd-registration__header" style={{textAlign: 'unset'}}>
                  <span>Enter OTP</span>
                </div>
                <div className="bd-form">
                  <p>A 6 digit OTP PIN has been sent to your Email ID</p>
                  <div className="bd-form__row">
                    <label>OTP</label>
                    <input
                      type="text"
                      maxLength="6"
                      // placeholder=""
                      name="otp"
                      onChange={(e) => props.onChangeText(e)}
                    />
                    {/* <div className="bd-otp__box">
                    <input type="text" maxlength="1" />
                    <input type="text" maxlength="1" />
                    <input type="text" maxlength="1" />
                    <input type="text" maxlength="1" />
                    <input type="text" maxlength="1" />
                    <input type="text" maxlength="1" className="active" />
                  </div> */}
                  </div>
                  <div className="bd-form__row bd-form__row--forgotpass">
                    <span className="bd-incorrect__msg">
                      {props.errorMsg
                        ? props.errorMsg
                        : props.confirmOTP && props.confirmOTP.error
                          ? props.confirmOTP.error
                          : ""}
                    </span>
                  </div>
                  <div className="bd-form__row bd-form__row--resendotp">
                    <span>
                      Didn’t get the Email?{" "}
                      <a className="cursor-pointer" onClick={props.onResendOTP}>Resend OTP</a>
                    </span>
                  </div>
                  {props.errorResetMessage && (
                    <div>{props.errorResetMessage}</div>
                  )}
                  <div className="bd-form__row bd-form__row--signin">
                    <div
                      className="bd-btn bd-btn--primary"
                      onClick={props.onSubmit}
                      disabled={
                        props && props.confirmOTP && props.confirmOTP.loading
                      }
                    >
                      <span>
                        {props.confirmOTP && props.confirmOTP.loading ? (
                          <Spinner />
                        ) : (
                          <span>Continue</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>


        </div>




      </div>
    </div>
  );
}

export default OtpView;
