import React from "react";

function TableTwo({ onClick, columns, headerClick, tableData, rowClick, classes }) {
  const handleClick = (e) => {
    onClick(e);
  };
  return (
    <div className="bd-tbl ">
      <div className="bd-tbl__head">
        <div className="bd-tbl__row">
          {columns.map((col) => {
            return (
              <div
                className="bd-tbl__cell"
                dataIndex={col.dataIndex}
                onClick={headerClick}
              >
                <span dataIndex={col.dataIndex}>{col.title}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bd-tbl__body">
        {tableData.length === 0 ? <span>No data available</span> :tableData.map((data) => {
          return (
            <div
              onClick={rowClick ? handleClick : null}
              className="bd-tbl__row"
            >
              {columns.map((col) => {
                return (
                  <div className={"bd-tbl__cell "+ classes?.join("")}>
                    <span>{data[col.dataIndex]}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TableTwo;
