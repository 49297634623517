import React from 'react';
import moment from 'moment';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { MONTHS } from '../../utils';

const COLORS = ['#FB49C0', '#53B9EA', '#F5A623'];

const CustomTooltip = ({ active, payload }) => {
  if (payload && payload.length) {
    return (
      <div className='line-chart-tooltip'>
        <div>{payload[0].payload.name}</div>
        <div className='divTable'>
          <div className='divRow'>
            {payload.map((a) => {
              return (
                <div className='line-chart-data' style={{ color: a.color }}>
                  <span className='data-key'>{a.dataKey.toUpperCase()}</span>
                  <span className='data-value'>: {a.value}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const LineChartComponent = ({ chartData }) => {
  let _data = chartData?.tableData?.data
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map((a) => {
      return {
        date: `${MONTHS[new Date(a.date).getMonth()]} ${new Date(
          a.date
        ).getDate()}`,
        weight: a.weight,
        bp: a.bp,
        hb: a.hb,
      };
    });

  function renderColorfulLegendText(value, entry) {
    const { color } = entry;

    return <span style={{ color }}>{value.toUpperCase()}</span>;
  }

  return (
    <ResponsiveContainer
      style={{ width: '75%', height: '100%', float: 'right' }}
    >
      <LineChart
        width={400}
        height={300}
        data={_data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <Legend formatter={renderColorfulLegendText} />
        <CartesianGrid strokeDasharray='7' vertical={false} />
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type='monotone'
          dataKey='bp'
          stroke={COLORS[0]}
          activeDot={{ r: 3 }}
        />
        <Line type='monotone' dataKey='weight' stroke={COLORS[1]} />
        <Line type='monotone' dataKey='hb' stroke={COLORS[2]} />
      </LineChart>
    </ResponsiveContainer>
  );
};
