import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLabResultsSettings,
  getSettings,
} from '../../redux/actions/settings';
import TablePagination from '@material-ui/core/TablePagination';
import ResultHeader from '../../components/LabResults/ResultHeader';
import ResultBody from '../../components/LabResults/ResultBody';
import PopupLabs from '../../components/LabResults/Popup';
import PopupDetailsLabs from '../../components/LabResults/PopupDetails';
import { BLOOD_GROUP_OPTIONS } from '../../utils/constants';
import DropDown from '../../components/RDropDown';
import SelectOptions from '../../components/Popups/SelectOptions';
import $ from 'jquery';

function LabResultView(props) {
  let {
    showGroupFilter,
    setShowGroupFilter,
    getLabResultObj,
    showAddResultPopup,
    showAddPopup,
    onChangeText,
    onSubmit,
    showResultOption,
    onResultSelect,
    setShowResultOption,
    result,
    onLaboratorySelect,
    setShowLabOption,
    laboratory,
    showLabOption,
    resultsArray,
    onResultArrEdit,
    postError,
    postLabResultObj,

    onRowClick,
    showDetailsPopup,
    setShowDetailsPopup,
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLabResultsSettings());
    dispatch(getSettings());
  }, []);

  const { settingsObj } = useSelector((state) => state.settingReducer);

  const [labCriteriaComponents, setDynamicHeaders] = useState([]);
  useEffect(() => {
    if (settingsObj?.data?.labCriteria) {
      setDynamicHeaders(settingsObj?.data?.labCriteria);
    }
  }, [settingsObj?.data?.labCriteria]);

  const [arrayToPrint, setArrayToPrint] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // followin 2 for labCriteria popup
  const [openCriteriaPopUp, setOpenCriteriaPopUp] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  function name() {
    // var a = [];
    // $(`.td${ele.id}`).removeClass('');
    $('.hide').removeClass('hide');
    selectedColumns.map((ele) => {
      // a.push(document.getElementsByClassName(`td${ele.id}`));
      // a.push(document.getElementsByName(`td${ele.id}`));
      $(`.td${ele.id}`).addClass('hide');
    });

    // if (a.length > 0) {
    //   for (let i = 0; i < a.length; i++) {
    //     // a[i].classList.add('hide');
    //     $(`${a[i]}`).addClass('hide');
    //   }
    // } else {
    // }
  }

  useEffect(() => {
    setArrayToPrint(getLabResultObj?.data?.content?.data);
  }, [getLabResultObj]);

  useEffect(() => {
    name();
  }, [name]);

  // const [set, setSet] = useState(arrayToPrint);
  //   useEffect(() => {
  //     let sum = arrayToPrint.filter((ele) => {
  //       if (selectedColumns.map((item) => item.id).indexOf(ele.id) !== -1) {
  //         return ele;
  //       }
  //     });
  //     setArrayToPrint();
  //   }, [selectedColumns]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function headerDropDownChange(e) {
    let arr = getLabResultObj?.data?.content?.data ?? [];
    let curArr = [];
    if (e.value === 'All/Any') {
      curArr = [...arr];
    } else {
      curArr = arr.filter((a) => a.bloodGroup === e.value);
    }
    setArrayToPrint(curArr);
  }
  return (
    <>
      <article className='bd-article'>
        <section className='bd-dashboard'>
          <div className='bd-dashboard__container'>
            <div className='bd-dashboard__lft'>
              {/* <div className='bd-dashboard__header'>
                <span className='bd-dashboard__main'>Lab Results</span>
                <div
                  className='bd-addlab__result'
                  onClick={() => setOpenCriteriaPopUp(true)}
                >
                  Show/Hide Columns
                </div>
                <div className='bd-dorpdown'>
                  <div className='bd-dorpdown__selected detail-dd bg-dd'>
                    <DropDown
                      placeHolderText={'Blood Group'}
                      options={BLOOD_GROUP_OPTIONS}
                      fetchDropDownValue={headerDropDownChange}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
              </div> */}
              <div className='bd-dashboard__header bd-header__search'>
                <span className='bd-dashboard__main'>Lab Results</span>
                <div className='bd-dorpdown mr-10'>
                  <div className='bd-dorpdown__selected detail-dd bg-dd'>
                    <DropDown
                      placeHolderText={'Blood Group'}
                      options={BLOOD_GROUP_OPTIONS}
                      fetchDropDownValue={headerDropDownChange}
                    />
                    <i className='bludbig-arrow' />
                  </div>
                </div>
                <div className='bd-hader'>
                  <div
                    className='bd-file-upload'
                    onClick={() => setOpenCriteriaPopUp(true)}
                  >
                    <button className='si-btn bd-btn bd-btn--primary'>
                      Show/Hide Columns
                    </button>
                  </div>
                </div>
              </div>

              <div className='table-scroll'>
                <div className='bd-tbl bd-tbl__theme1'>
                  <ResultHeader
                    labCriteriaComponents={labCriteriaComponents}
                    showGroupFilter={showGroupFilter}
                    setShowGroupFilter={setShowGroupFilter}
                  />
                  <div className='bd-tbl__body'>
                    {arrayToPrint && arrayToPrint?.length === 0 ? (
                      <div className='align-center-no-data'>
                        <div className='bd-tbl__body'>
                          <div className='bd-noLabdata'>
                            <span>No Lab results added yet</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      arrayToPrint?.map((item, index) => {
                        return (
                          <ResultBody
                            id={`td${item.id}`}
                            item={item}
                            onRowClick={onRowClick}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <TablePagination
                component='div'
                count={arrayToPrint?.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </section>
      </article>
      {showAddPopup && (
        <PopupLabs
          showAddResultPopup={showAddResultPopup}
          onChangeText={onChangeText}
          onSubmit={onSubmit}
          setShowResultOption={setShowResultOption}
          showResultOption={showResultOption}
          onResultSelect={onResultSelect}
          result={result}
          onLaboratorySelect={onLaboratorySelect}
          setShowLabOption={setShowLabOption}
          laboratory={laboratory}
          showLabOption={showLabOption}
          resultsArray={resultsArray}
          onResultArrEdit={onResultArrEdit}
          postError={postError}
          postLabResultObj={postLabResultObj}
        />
      )}
      {openCriteriaPopUp && (
        <SelectOptions
          labCriteriaComponents={labCriteriaComponents}
          setOpenCriteriaPopUp={setOpenCriteriaPopUp}
          setSelectedColumns={setSelectedColumns}
          // name2={name2}
        />
      )}

      {showDetailsPopup && (
        <PopupDetailsLabs setShowDetailsPopup={setShowDetailsPopup} />
      )}
    </>
  );
}

export default LabResultView;
