import React from 'react';
import { Loader } from '../../components';
import plus from '../../assets/images/plus.png';
import printer from '../../assets/images/printer.png';
import AddCampContainer from '../../containers/CampsContainer/AddCamp';
import { CAMP_DONOR_DETAILS_COLUMNS } from '../../utils/config';

export default function CampsDonorList({
  campsDetails,
  addCamps,
  showAddCampForm,
  onClick,
  toggleForm,
}) {
  const donors = [
    {
      _id: 'IN-0000AD',
      countryCode: '+91',
      isBlocked: false,
      email: 'Susindran.buddha@live.com',
      firstName: 'SusindranBuddha',
      lastName: 'Buddhakrishnamurti',
      mobileNumber: '9833488426',
      lastDonationDate: '2021-06-26T18:30:00.000Z',
      lastDonationComponent: 'whole blood',
    },
  ];

  return (
    <>
      <section className='bd-dashboard bd-bg-color'>
        {campsDetails.loading ? <Loader /> : ''}

        <div className='bd-dashboard__header space-between bd-camps_header'>
          <div className='d-flex align-items-center'>
            <div className=' fw-700'>{campsDetails?.data?.campName}-</div>
            {/* <div className=' fw-700'>blood camp</div> */}
            <div>{campsDetails?.data?.address?.description}</div>
          </div>

          <div className='d-flex align-items-center fw-600 cursor-pointer'>
            <span className='bd-dashboard__main  bd_error' onClick={onClick}>
              Organise a Camp
            </span>
            <img
              onClick={onClick}
              src={plus}
              height='25px'
              className='cursor-pointer'
              style={{ marginLeft: '5px' }}
            />
          </div>
        </div>

        <article className='bd-article'>
          <section className=''>
            <div className='bd-dashboard__container'>
              <div className='bd-dashboard__lft'>
                {/* <div className="bd-tbl__inventory bd-scroll__horz"> */}
                <div className='table-scroll'>
                  {/* <div className="bd-people"> */}
                  <div className='bd-tbl bd-tbl__theme1'>
                    <div className='bd-tbl__head'>
                      <div className='bd-tbl__row'>
                        {/* <div className="bd-tbl__cell">
                        {table.length > 0 ? (
                          <CheckBox
                            keyindex={"checkbox_1"}
                            id={"checkbox"}
                            checked={selectAllCheckBox}
                            onChange={selectAllCheck}
                          />
                        ) : (
                          ""
                        )}
                      </div> */}

                        {CAMP_DONOR_DETAILS_COLUMNS.map((data, i) => {
                          return (
                            <div className='bd-tbl__cell bd-pointer' key={i}>
                              <span
                                //   onClick={onHeaderClick}
                                dataindex={data.dataIndex}
                              >
                                {data.title}
                              </span>
                              {/* {data.dataIndex === "bloodGroup" ? (
                                  <HeaderDropDown
                                    data={{
                                      list: BLOOD_GROUP_LIST,
                                    }}
                                  />
                                ) : null} */}
                            </div>
                          );
                        })}

                        <div className='bd-tbl__cell bd-pointer '>
                          <div className='d-flex align-items-center'>
                            <div className='mr-5 bd_error'>
                              {donors.length} Donors
                            </div>
                            <img src={printer} width='20px' />
                          </div>
                        </div>
                      </div>
                    </div>
                    {!donors.length ? (
                      <div className='align-center-no-data'>
                        <div className='bd-tbl__body'>
                          <div className='bd-nodata'>
                            <span>No Data Available</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      donors.length > 0 &&
                      donors.map((data, i) => {
                        return (
                          <div className='bd-tbl__body' key={i}>
                            <div
                              className='bd-tbl__row'
                              //   onClick={handleCilck}
                              donorId={data._id}
                              key={i}
                            >
                              <div donorId={data._id} className='bd-tbl__cell'>
                                <span donorId={data._id}>
                                  {data?.firstName + ' ' + data?.lastName}
                                </span>
                              </div>
                              <div
                                className='bd-tbl__cell bd-tbl__cell--center'
                                donorId={data._id}
                              >
                                <span
                                  donorId={data._id}
                                  // style={{ textAlign: "center" }}
                                >
                                  {data?.bloodGroup}
                                </span>
                              </div>
                              <div donorId={data._id} className='bd-tbl__cell'>
                                <span donorId={data._id}>{data._id}</span>
                              </div>
                              <div donorId={data._id} className='bd-tbl__cell'>
                                <span donorId={data._id}>{data.email}</span>
                              </div>
                              <div donorId={data._id} className='bd-tbl__cell'>
                                <span donorId={data._id}>
                                  {data.mobileNumber}
                                </span>
                              </div>
                              <div donorId={data._id} className='bd-tbl__cell'>
                                <span donorId={data._id}>
                                  {data.checkList ? data.checkList : '-'}
                                </span>
                              </div>
                              <div donorId={data._id} className='bd-tbl__cell'>
                                <span donorId={data._id}>
                                  {data.status ? data.status : '-'}
                                </span>
                              </div>

                              <div className='bd-tbl__cell bd-pointer'>
                                <div
                                  id={data._id}
                                  className={[
                                    'bd-btn bd-btn--primary',
                                    data?.status === 'Active'
                                      ? ''
                                      : 'bd-btn--deactive',
                                  ].join(' ')}
                                  onClick={
                                    data?.status === 'Active' ? onClick : null
                                  }
                                >
                                  <span id={data._id}>Request OTP</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      //   .slice(
                      //     page * rowsPerPage,
                      //     page * rowsPerPage + rowsPerPage
                      //   )
                    )}
                  </div>
                </div>
                {/* <TablePagination
                component="div"
                count={donors.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              /> */}
              </div>
            </div>
            {/* {showButtons ? (
            <div className="bd-btn__wrap">
              <div className="bd-btn bd-btn--outline" onClick={hideBtn}>
                <span>Cancel</span>
              </div>
              {blockBtns &&
                donors.filter((t) => t.isSelected && t.status === "Active")
                  .length === 1 && (
                  <div
                    className="bd-btn bd-btn--primary"
                    onClick={() => {
                      handleBlockUnblock("Block");
                    }}
                  >
                    <span>Block</span>
                  </div>
                )}
              {blockBtns &&
                donors.filter((t) => t.isSelected && t.status === "Blocked")
                  .length === 1 && (
                  <div
                    className="bd-btn bd-btn--share"
                    // onClick={() => {
                    //   handleBlockUnblock("UnBlock");
                    // }}
                  >
                    <span>UnBlock</span>
                  </div>
                )}

              <div
                className="bd-btn bd-btn--primary db-bg__green"
                // onClick={() => setRequetBlood({show: true})}
              >
                <span>Request</span>
              </div>
            </div>
          ) : (
            ""
          )} */}
          </section>
        </article>
      </section>

      {showAddCampForm && (
        <AddCampContainer addCamps={addCamps} toggleForm={toggleForm} />
      )}
    </>
  );
}
