import { makeAPICall } from '../../utils';
import {
  API_BASE_PATH,
  GET_CAMPS_LIST_URL,
  ACCEPT_CAMPS_REQUEST_URL,
  POST_CAMPS_DATA_URL,
  DELETE_CAMPS_URL,
  DEACTIVATE_CAMPS_URL,
  UPLOAD_FILE_URL,
  UPDATE_CAMPS_DATA_URL,
  // GET_HOSPITAL_LIST_URL,
  GET_CAMP_DETAILS_URL,
  GET_CAMP_ADDRESS_LIST_URL,
  POST_DONATION_FORM_URL,
  GET_PROCEED_BTN_CLICK,
  GET_NGO_CAMPS_LIST_URL,
  GET_BB_LIST_URL,
} from '../../utils/config';
import {
  // GET ACCEPTED/PENDING CAMPS LIST --  SUDARSHAN
  GET_CAMPS_LIST_DATA,
  GET_CAMPS_LIST_DATA_SUCCESS,
  GET_CAMPS_LIST_DATA_FAILED,
  // POST DONATE FORM DATA --  SUDARSHAN
  POST_DONATE_FORM,
  POST_DONATE_FORM_SUCCESS,
  POST_DONATE_FORM_FAILED,
  // GET ON PROCEED CLICK -- SUDARSHAN
  GET_ON_PROCEED_CLICK,
  GET_ON_PROCEED_CLICK_SUCCESS,
  GET_ON_PROCEED_CLICK_FAILED,
  //
  POST_CAMPS_DATA,
  POST_CAMPS_DATA_SUCCESS,
  POST_CAMPS_DATA_FAILED,
  //
  GET_CAMPS_REQUEST_DATA,
  GET_CAMPS_REQUEST_DATA_SUCCESS,
  GET_CAMPS_REQUEST_DATA_FAILED,
  // POST ACCEPT/DECLINE PENDING CAMPS CLICK -- SUDARSHAN
  TRIGGER_ACCEPT_DECLINE_CAMP,
  TRIGGER_ACCEPT_DECLINE_CAMP_FAILED,
  TRIGGER_ACCEPT_DECLINE_CAMP_SUCCESS,
  //
  TRIGGER_DELETE_CAMP,
  TRIGGER_DELETE_CAMP_FAILED,
  TRIGGER_DELETE_CAMP_SUCCESS,
  //
  TRIGGER_DECLINE_CAMP_FAILED,
  TRIGGER_DECLINE_CAMP_SUCCESS,
  TRIGGER_DECLINE_CAMP,
  //
  TRIGGER_DEACTIVATE_CAMP,
  TRIGGER_DEACTIVATE_CAMP_SUCCESS,
  TRIGGER_DEACTIVATE_CAMP_FAILED,
  //
  UPDATE_CAMPS_DATA,
  UPDATE_CAMPS_DATA_SUCCESS,
  UPDATE_CAMPS_DATA_FAILED,
  //
  TRIGGER_GET_ADDRESS_LIST,
  TRIGGER_GET_ADDRESS_LIST_FAILED,
  TRIGGER_GET_ADDRESS_LIST_SUCCESS,
  //GET CAMP LIST ACCEPTED/PENDING DATA
  GET_CAMPS_DETAILS,
  GET_CAMPS_DETAILS_SUCCESS,
  GET_CAMPS_DETAILS_FAILED,
  //GET NGO CAMP DATA
  GET_NGO_CREATED_CAMP,
  GET_NGO_CREATED_CAMP_SUCCESS,
  GET_NGO_CREATED_CAMP_FAILED,
  // GET PARTICIPATING BB
  GET_PARTICIPATING_BB,
  GET_PARTICIPATING_BB_SUCCESS,
  GET_PARTICIPATING_BB_FAILED,
} from './actionTypes';
import { urlSearchParams } from '../../utils';

// const payload = [];
// get Accepetd Camp List
export function getNgoCampsListData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_NGO_CAMPS_LIST_URL}`,
      dispatch,
      defaultAction: GET_NGO_CREATED_CAMP,
      successAction: GET_NGO_CREATED_CAMP_SUCCESS,
      failedAction: GET_NGO_CREATED_CAMP_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function getCampsListData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_CAMPS_LIST_URL}`,
      dispatch,
      defaultAction: GET_CAMPS_LIST_DATA,
      successAction: GET_CAMPS_LIST_DATA_SUCCESS,
      failedAction: GET_CAMPS_LIST_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
// Get PRequested Camp List
export function getCampsRequestData(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_CAMPS_LIST_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_CAMPS_REQUEST_DATA,
      successAction: GET_CAMPS_REQUEST_DATA_SUCCESS,
      failedAction: GET_CAMPS_REQUEST_DATA_FAILED,
      type: 'GET',
      headers: {},
      params: obj,
      callback,
    };

    makeAPICall(params);
  };
}

export function triggerAddCamps(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_CAMPS_DATA_URL}`,
      dispatch,
      defaultAction: POST_CAMPS_DATA,
      successAction: POST_CAMPS_DATA_SUCCESS,
      failedAction: POST_CAMPS_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

// POST DONATE FORM DATA --  SUDARSHAN
export function postDonateForm(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${POST_DONATION_FORM_URL}`,
      dispatch,
      defaultAction: POST_DONATE_FORM,
      successAction: POST_DONATE_FORM_SUCCESS,
      failedAction: POST_DONATE_FORM_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerEditCamps(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${UPDATE_CAMPS_DATA_URL}`,
      dispatch,
      defaultAction: UPDATE_CAMPS_DATA,
      successAction: UPDATE_CAMPS_DATA_SUCCESS,
      failedAction: UPDATE_CAMPS_DATA_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
// POST ACCEPT/DECLINE PENDING CAMPS CLICK -- SUDARSHAN
export function triggerAccept_DeclineRequest(obj, callback) {
  return (dispatch) => {
    const params = {
      // url: `${API_BASE_PATH}${ACCEPT_CAMPS_REQUEST_URL}?${urlSearchParams(
      //   obj
      // )}`,
      url: `${API_BASE_PATH}${ACCEPT_CAMPS_REQUEST_URL}`,
      dispatch,
      defaultAction: TRIGGER_ACCEPT_DECLINE_CAMP,
      successAction: TRIGGER_ACCEPT_DECLINE_CAMP_SUCCESS,
      failedAction: TRIGGER_ACCEPT_DECLINE_CAMP_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerDeclineRequest(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${ACCEPT_CAMPS_REQUEST_URL}?${urlSearchParams(
        obj
      )}`,
      dispatch,
      defaultAction: TRIGGER_DECLINE_CAMP,
      successAction: TRIGGER_DECLINE_CAMP_SUCCESS,
      failedAction: TRIGGER_DECLINE_CAMP_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerFetchAddress(param, type) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_CAMP_ADDRESS_LIST_URL}${param}&type=${type}`,
      dispatch,
      defaultAction: TRIGGER_GET_ADDRESS_LIST,
      successAction: TRIGGER_GET_ADDRESS_LIST_SUCCESS,
      failedAction: TRIGGER_GET_ADDRESS_LIST_FAILED,
      type: 'GET',
      headers: {},
    };
    makeAPICall(params);
  };
}

export function triggerDeleteCamp(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DELETE_CAMPS_URL}`,
      dispatch,
      defaultAction: TRIGGER_DELETE_CAMP,
      successAction: TRIGGER_DELETE_CAMP_SUCCESS,
      failedAction: TRIGGER_DELETE_CAMP_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}
export function triggerDeactivateCamp(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${DEACTIVATE_CAMPS_URL}`,
      dispatch,
      defaultAction: TRIGGER_DEACTIVATE_CAMP,
      successAction: TRIGGER_DEACTIVATE_CAMP_SUCCESS,
      failedAction: TRIGGER_DEACTIVATE_CAMP_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

export function triggerUploadFile(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${UPLOAD_FILE_URL}`,
      dispatch,
      defaultAction: TRIGGER_DEACTIVATE_CAMP,
      successAction: TRIGGER_DEACTIVATE_CAMP_SUCCESS,
      failedAction: TRIGGER_DEACTIVATE_CAMP_FAILED,
      type: 'POST',
      headers: {},
      params: obj,
      callback,
    };
    makeAPICall(params);
  };
}

// Get Camp Donors List sudarshan
export function getCampsDetail(obj, callback) {
  return (dispatch) => {
    const params = {
      // url: `${API_BASE_PATH}${GET_CAMP_DETAILS_URL}?campId=${obj.campId}`,
      url: `${API_BASE_PATH}${GET_CAMP_DETAILS_URL}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_CAMPS_DETAILS,
      successAction: GET_CAMPS_DETAILS_SUCCESS,
      failedAction: GET_CAMPS_DETAILS_FAILED,
      type: 'GET',
      headers: {},
    };

    makeAPICall(params);
  };
}
// Get onProceedClick donor Data sudarshan
export function getOnProceedClick(obj, callback) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_PROCEED_BTN_CLICK}?${urlSearchParams(obj)}`,
      dispatch,
      defaultAction: GET_ON_PROCEED_CLICK,
      successAction: GET_ON_PROCEED_CLICK_SUCCESS,
      failedAction: GET_ON_PROCEED_CLICK_FAILED,
      type: 'GET',
      headers: {},
    };

    makeAPICall(params);
  };
}

export function triggerFetchParticipatingBBlist(param, type) {
  return (dispatch) => {
    const params = {
      url: `${API_BASE_PATH}${GET_BB_LIST_URL}${param}&type=${type}`,
      dispatch,
      defaultAction: GET_PARTICIPATING_BB,
      successAction: GET_PARTICIPATING_BB_SUCCESS,
      failedAction: GET_PARTICIPATING_BB_FAILED,
      type: 'GET',
      headers: {},
    };
    makeAPICall(params);
  };
}
