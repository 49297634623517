import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SetPasswordView from '../../views/SetPasswordView';

import {
  resetSetPassword,
  triggerAuthSetPassword,
  triggerForgotSETPassword,
  triggerSetOTP,
} from '../../redux/actions';
import { validatePassword } from '../../utils';
import { useHistory } from 'react-router-dom';

function SetPasswordContainer(props) {
  const passCredObj = {
    password: '',
    confirmPassword: '',
  };
  const dispatch = useDispatch();
  const history = useHistory();

  const { setPassObj, otpForSetPass, forgetPassword } = useSelector(
    (state) => state.authReducer
  );
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passCred, setPassCred] = useState(passCredObj);
  const [errorMsg, setError] = useState('');

  useEffect(() => {
    // resets the error
    setError('');

    dispatch(resetSetPassword());
  }, []);

  const onChangeText = (e) => {
    let updatedPassObj = passCred;
    updatedPassObj[e.target.name] = e.target.value;
    setPassCred(updatedPassObj);
  };

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const showConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const type = urlParams.get('type');

  const onSubmit = () => {
    if (id) {
      if (passCred.password !== '' && passCred.confirmPassword !== '') {
        if (passCred.password === passCred.confirmPassword) {
          if (validatePassword(passCred.password)) {
            setError('');
            let obj = {
              password: passCred.password,
              id,
            };
            console.log(type);
            return dispatch(
              triggerAuthSetPassword(
                obj,
                () => {
                  //redirecting to login
                  console.log('type is admin', type);
                  type === 'admin'
                    ? history.push('/Super-Admin/login')
                    : history.push('/');
                  // history.push('/');
                },
                type
              )
            );
          } else {
            setError('Password doesn’t match the requirment');
          }
        } else {
          setError('Password doesn’t match');
        }
      } else {
        setError('Password is empty');
      }
    } else if (otpForSetPass) {
      let obj = {
        password: passCred.password,
        otp: otpForSetPass,
        id: forgetPassword?.data?.content?.data?.id,
      };

      dispatch(triggerSetOTP());

      return dispatch(
        triggerForgotSETPassword(obj, () => {
          //redirecting to login
          history.push('/');
        })
      );
    } else {
      setError('Invalid link');
    }
  };

  return (
    <SetPasswordView
      setPassObj={setPassObj}
      onChangeText={onChangeText}
      showPasswordToggle={showPasswordToggle}
      showPassword={showPassword}
      showConfirmPasswordToggle={showConfirmPasswordToggle}
      showConfirmPassword={showConfirmPassword}
      errorMsg={errorMsg}
      onSubmit={onSubmit}
    />
  );
}

export default SetPasswordContainer;
