import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import XLSX from 'xlsx';
import { Loader, SuccessPopup, FailPopup } from '../../components';
import {
  uploadSettingsInventory,
  uploadSettingsDonors,
} from '../../redux/actions/settings';
import {
  UPLOAD_SETTINGS_INVENTORY,
  UPLOAD_SETTINGS_DONOR,
} from '../../redux/actions/actionTypes';
import { useHistory } from 'react-router-dom';

function SettingInventoryView({ inventoryBtn, donorBtn }) {
  // donorBtn ----- is comes from people module

  const dispatch = useDispatch();
  const history = useHistory();

  const [showXlData, setShowXlData] = useState(false);

  const [xlInventory, setXlInventory] = useState([]);

  const [activeTab, setActiveTab] = useState(
    donorBtn === true ? 'Donors' : 'inventory'
  );
  // const [activeTab, setActiveTab] = useState('inventory');
  // const [errMsg, setErrMsg] = useState('');

  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setShowFail] = useState(false);

  const [errors, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const { postInventorySettingsObj, postDonorSettingsObj } = useSelector(
    (state) => state.settingReducer
  );
  useEffect(() => {
    // postInventorySettingsObj.loading || postDonorSettingsObj.loading
    //   ? setLoading(true)
    //   : setLoading(false);
    // if (
    //   postInventorySettingsObj.data !== null ||
    //   postDonorSettingsObj.data !== null
    // ) {
    //   if (postInventorySettingsObj.data) {
    //     setSuccessMsg(postInventorySettingsObj.data.message);
    //   } else if (postDonorSettingsObj.data) {
    //     setSuccessMsg(postDonorSettingsObj.data.message);
    //   }
    //   setShowSuccess(true);
    // } else

    if (
      postInventorySettingsObj.error !== null ||
      postDonorSettingsObj.error !== null
    ) {
      setShowFail(true);
      setError(postInventorySettingsObj.error || postDonorSettingsObj.error);
    } else if (
      postInventorySettingsObj.data !== null ||
      postDonorSettingsObj.data !== null
    ) {
      setSuccessMsg(
        postInventorySettingsObj.data?.content?.message ||
          postDonorSettingsObj.data?.content?.message
      );
    }
  }, [postInventorySettingsObj, postDonorSettingsObj]);

  let inventoryHeadersArr = [
    'Bag / Unit no.',
    'Donor ID',
    'Blood group',
    'Component',
    'Collected on',
    'Expiry',
    'Stored in',
    'Lab Result',
  ];

  let heading = 'Excel Upload for Inventory';
  if (activeTab !== 'inventory') {
    heading = 'Excel Upload for Donors';
    inventoryHeadersArr = [
      'Name',
      'Blood group',
      'Donor ID',
      'Email ID',
      'Mobile no.',
      'Address',
      'Last Donated',
    ];
  }

  useEffect(() => {
    const input = document.getElementById('fileUpload');
    input &&
      input.addEventListener('change', () => {
        // readXlsxFile(input.files[0]).then((rows) => {
        //   // `rows` is an array of rows
        //   // each row being an array of cells.
        // });

        Upload();
      });
  }, []);

  const Upload = () => {
    const fileUpload = document.getElementById('fileUpload');
    // const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    // if (regex.test(fileUpload.value.toLowerCase())) {
    // let fileName = fileUpload.files[0].name;
    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          processExcel(reader.result);
        };
        reader.readAsBinaryString(fileUpload.files[0]);
      }
    } else {
    }
  };

  function redirectInventory() {
    window.location.reload();
    history.push('/inventory');
  }

  const onDataUpload = () => {
    let arrToPush = [];
    for (let i = 0; i < xlInventory.length; i++) {
      let arrObj = null;
      if (i !== 0) {
        if (activeTab === 'inventory') {
          if (
            xlInventory[i].__EMPTY &&
            xlInventory[i].__EMPTY_1 &&
            xlInventory[i].__EMPTY_2 &&
            xlInventory[i].__EMPTY_3 &&
            xlInventory[i].__EMPTY_4 &&
            xlInventory[i].__EMPTY_5 &&
            xlInventory[i].__EMPTY_7
            // xlInventory[i].__EMPTY_8
          ) {
            let rawCollectionDate = xlInventory[i].__EMPTY_4
              .toString()
              .split(/\//);
            let formatedCollectionDate = new Date(
              [
                rawCollectionDate[1],
                rawCollectionDate[0],
                rawCollectionDate[2],
              ].join('/')
            );
            let rawExpiryDate = xlInventory[i].__EMPTY_5.toString().split(/\//);
            let formatedExpiryDate = new Date(
              [rawExpiryDate[1], rawExpiryDate[0], rawExpiryDate[2]].join('/')
            );

            arrObj = {
              bagId: xlInventory[i].__EMPTY_1,
              bloodGroup: xlInventory[i].__EMPTY,
              donorId: xlInventory[i].__EMPTY_8?.toString(),
              component: xlInventory[i].__EMPTY_2,
              status: 0,
              volume: xlInventory[i].__EMPTY_3?.toString(),
              storedIn: xlInventory[i].__EMPTY_7,
              collectionDate: formatedCollectionDate,
              expiryDate: formatedExpiryDate,
            };
          }
        } else {
          if (
            xlInventory[i].__EMPTY_1 &&
            xlInventory[i].__EMPTY_2 &&
            // xlInventory[i]['Mandatory Fields'] &&
            // xlInventory[i]["__EMPTY"] &&
            xlInventory[i].__EMPTY_3 &&
            xlInventory[i].__EMPTY_4 &&
            xlInventory[i].__EMPTY_5 &&
            xlInventory[i].__EMPTY_6
          ) {
            let rawDob = xlInventory[i]?.__EMPTY_5?.toString().split(/\//);
            let formatedDob = new Date(
              [rawDob[1], rawDob[0], rawDob[2]].join('/')
            );

            arrObj = {
              firstName: xlInventory[i].__EMPTY_1,
              lastName: xlInventory[i].__EMPTY_2,
              gender: xlInventory[i].__EMPTY_3,
              mobileNumber: xlInventory[i].__EMPTY_4.toString(),
              email: xlInventory[i]['Mandatory Fields'],
              // countryCode: xlInventory[i]["__EMPTY" ],
              countryCode: '+91',
              // lastDonationDate: formatedLastDonationDate,
              bloodGroup: xlInventory[i].__EMPTY_6,
              lastDonationComponent: xlInventory[i].__EMPTY_8,
              dateOfBirth: formatedDob,
              height: xlInventory[i]['Optional Fields'],
              weight: xlInventory[i].__EMPTY_9,
              bmi: xlInventory[i].__EMPTY_10,
            };
            if (xlInventory[i].__EMPTY_7) {
              let rawLastDonationDate = xlInventory[i]?.__EMPTY_7
                ?.toString()
                .split(/\//);
              let formatedLastDonationDate = new Date(
                [
                  rawLastDonationDate[1],
                  rawLastDonationDate[0],
                  rawLastDonationDate[2],
                ].join('/')
              );
              arrObj['lastDonationDate'] = formatedLastDonationDate;
            }
          }
        }
        arrObj && arrToPush.push(arrObj);
      }
    }

    if (activeTab === 'inventory') {
      // if (
      //   validateSettingInvDonArray({
      //     arr: arrToPush,
      //     type: "inventory",
      //     err: setErrMsg,
      //   })
      // ) {
      // }
      let obj = {
        inventories: arrToPush,
      };

      if (obj.inventories.length > 0) {
        dispatch(
          uploadSettingsInventory(obj, () => {
            setXlInventory([]);
            setShowXlData(false);
            setActiveTab('Donors');
            setShowSuccess(true);
            // inventoryBtn === true ? redirectInventory() : history.push("/");
          })
        );
      }
    } else {
      let obj = {
        donors: arrToPush,
      };
      dispatch(
        uploadSettingsDonors(obj, () => {
          setShowSuccess(true);
          // history.push("/");
        })
      );
    }
  };

  const processExcel = (data) => {
    const workbook = XLSX.read(data, {
      type: 'binary',
    });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet],
      { raw: false }
      // { raw: false, dateFormat: "dd/mm/yyyy" }
    );

    setShowXlData(true);
    setXlInventory(excelRows);
  };

  // this is for check mail format
  //  const mailformat =
  //    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // function validExcel() {
  //     xlInventory.filter((item) => !mailformat.test(item['Mandatory Fields']))
  //   );
  //   return xlInventory.filter(
  //     (item, i) => !mailformat.test(item['Mandatory Fields']) && i !== 0
  //   );
  // }

  const onSkipClick = () => {
    // inventoryBtn === true
    //   ? setShowXlUplodings(false)
    //   : donorBtn === true
    //   ? setShowXlUplodings(false)
    //   : activeTab === 'inventory'
    //   ? setActiveTab('blood')
    //   : history.push('/');
    // setShowXlData(false);
    // setXlInventory([]);
    // const input = document.getElementById('fileUpload');
    // input.value = '';
    history.push('/');
  };
  function onTabChange(e) {
    setActiveTab(e.target.id);
  }
  return (
    <>
      <article className='bd-article'>
        <div className='bd-step__container '>
          <section className='bd-step__content bd-inventory__container'>
            <div className='bd-inventory'>
              <div className='bd-header-grid mb-20'>
                <div className='bd-inventory__header d-flex-center'>
                  <span>{heading}</span>
                </div>
                <div className='bd-tab bd-tab-new width'>
                  <div
                    id='inventory'
                    className={activeTab === 'inventory' && 'active'}
                    onClick={onTabChange}
                    activetab={activeTab}
                  >
                    <span id='inventory' style={{ padding: '0 10px' }}>
                      Inventory
                    </span>
                  </div>
                  <div
                    id='Donors'
                    className={activeTab === 'Donors' && 'active'}
                    onClick={onTabChange}
                    activetab={activeTab}
                  >
                    <span id='Donors' style={{ padding: '0 10px' }}>
                      Donors
                    </span>
                  </div>
                </div>
              </div>
              <div className='bd-inventory__step'>
                <div className='bd-inventory__step--row'>
                  <div className='bd-inventory__step--primary'>
                    <span>Download the Excel Template</span>
                  </div>
                  <div className='bd-inventory__step--secondary'>
                    <a
                      className='bd-download-temp'
                      href={
                        activeTab === 'inventory'
                          ? 'https://bucket.penzyl.com/blud/1629803734940-Sangiune-Blud_ inventory_ Data (1).xlsx'
                          : 'https://bucket.penzyl.com/blud/1634031583828-Blud_Donor_Upload.xlsx'
                      }
                    >
                      <i className='bluddownload'></i>
                      Download Template
                    </a>
                  </div>
                </div>
                <div className='bd-inventory__step--row'>
                  <div className='bd-inventory__step--primary'>
                    <span>Populate the data in the Excel Template</span>
                  </div>
                  <div className='bd-inventory__step--secondary'>
                    <span>
                      Please popluate the data in the template downloaded.
                    </span>
                  </div>
                </div>
                <div className='bd-inventory__step--row'>
                  <div className='bd-inventory__step--primary'>
                    <span>Upload data in the.xlxs file</span>
                    <div className='bd-file-upload'>
                      <button className='si-btn bd-btn bd-btn--primary'>
                        Choose file
                      </button>
                      <input
                        type='file'
                        id='fileUpload'
                        // onClick={() => Upload()}
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      />
                    </div>
                  </div>
                </div>
              </div>
              {showXlData ? (
                <div className='bd-excel__tbl'>
                  <div className='bd-excel__tbl--container'>
                    <div className='bd-tbl'>
                      <div className='bd-tbl__head'>
                        <div className='bd-tbl__row'>
                          {inventoryHeadersArr.map((data, i) => {
                            return (
                              <div
                                key={i}
                                className={
                                  data !== 'Lab Result'
                                    ? 'bd-tbl__cell'
                                    : 'bd-tbl__cell bd-lab-result'
                                }
                              >
                                <span>{data}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className='bd-tbl__body'>
                        {xlInventory.map((singleInventory, index) => {
                          if (index !== 0)
                            return (
                              <>
                                {activeTab === 'inventory' ? (
                                  <div className='bd-tbl__row'>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY_1}</span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY_8}</span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY}</span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY_2}</span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY_4}</span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY_5}</span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY_7}</span>
                                    </div>
                                    <div className='bd-tbl__cell bd-lab-result'>
                                      <span>{singleInventory.__EMPTY_6}</span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='bd-tbl__row'>
                                    <div
                                      className='bd-tbl__cell '
                                      style={{ textAlign: 'left' }}
                                    >
                                      <span>
                                        {singleInventory.__EMPTY_1 +
                                          ' ' +
                                          singleInventory.__EMPTY_2}
                                      </span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY_6}</span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>
                                        {singleInventory['BLUD Donor Data']}
                                      </span>
                                    </div>
                                    <div
                                      className='bd-tbl__cell'
                                      style={{ textAlign: 'left' }}
                                    >
                                      <span>
                                        {singleInventory['Mandatory Fields']}
                                        {/* {!mailformat.test(
                                          singleInventory['Mandatory Fields']
                                        ) && (
                                          <p
                                            style={{
                                              textAlign: 'center',
                                              color: '#af0c0c',
                                            }}
                                          >
                                            invalid Email
                                          </p>
                                        )} */}
                                      </span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY_4}</span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY}</span>
                                    </div>
                                    <div className='bd-tbl__cell'>
                                      <span>{singleInventory.__EMPTY_7}</span>
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='bd-inventory__step--guide'>
                  <ul className='bd-list__lower-disc'>
                    <li>
                      <span>
                        Download the Excel Template and save it on your Desktop
                      </span>
                    </li>
                    <li>
                      <span>
                        Copy your current inventory data to this Template with
                        all details of Donor, Date & Time of Collection,
                        Identification, Test Results and Storage Location. This
                        is help you maintain optimum inventory (FIFO).
                      </span>
                    </li>
                    <li>
                      <span>
                        Add additional information to the Comments against each
                        item.{' '}
                      </span>
                    </li>
                    <li>
                      <span>
                        Save this File and Upload the file by clicking{' '}
                      </span>
                      <div className='bd-file-upload'>
                        <button className='si-btn bd-btn bd-btn--primary'>
                          Choose file
                        </button>
                        <input
                        //   type="file"
                        //   id="fileUpload"
                        //   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        //   onchange={() => Upload()}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </section>
          {/* <div className="bd-form__row bd_align_center">
            <span className="bd-incorrect__msg">
              {postInventorySettingsObj && postInventorySettingsObj.error
                ? postInventorySettingsObj.error
                : postDonorSettingsObj && postDonorSettingsObj.error
                ? postDonorSettingsObj.error
                : ""}
            </span>
          </div> */}
          <div className='bd-step__btn '>
            <span className='bd-btn bd-btn--outline' onClick={onSkipClick}>
              {inventoryBtn === true
                ? 'Cancel'
                : donorBtn === true
                ? 'cancel'
                : 'Skip'}
              {/* Skip */}
            </span>
            <span
              onClick={onDataUpload}
              className={
                activeTab === 'inventory'
                  ? showXlData
                    ? 'bd-btn bd-btn--primary'
                    : 'bd-btn bd-btn--primary bd-disabled'
                  : // : showXlData && !validExcel().length
                  showXlData
                  ? 'bd-btn bd-btn--primary'
                  : 'bd-btn bd-btn--primary bd-disabled'
              }
            >
              Upload
            </span>
          </div>
        </div>
      </article>
      {showSuccess && (
        <SuccessPopup
          // title={"Inventory Uploaded Successfully"}
          title={successMsg}
          onContinueClick={() => {
            setShowSuccess(false);

            inventoryBtn === true ? redirectInventory() : history.push('/');
            // history.push("/");
            dispatch({
              type: UPLOAD_SETTINGS_INVENTORY || UPLOAD_SETTINGS_DONOR,
              loading: false,
            });
          }}
        />
      )}
      {showFail && (
        <FailPopup
          title={errors}
          onContinueClick={() => {
            setShowFail(false);
            setShowXlData(false);
            setXlInventory([]);
            const input = document.getElementById('fileUpload');
            input.value = '';
            dispatch({
              type: UPLOAD_SETTINGS_DONOR || UPLOAD_SETTINGS_INVENTORY,
              loading: false,
            });
          }}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default SettingInventoryView;
