import React from 'react';
import { useSelector } from 'react-redux';
import DatePicker from '../../components/DatePicker';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

function PopupDetailsLabs(props) {
  const printElement = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => printElement.current,
  });
  let { setShowDetailsPopup } = props;

  const { selectedLabResult } = useSelector((state) => state.labResultReducer);

  let classNameResult = '';
  if (selectedLabResult?.labResults?.finalResult === 'Fail') {
    classNameResult = 'bd-red__color';
  } else if (selectedLabResult?.labResults?.finalResult === 'Pass') {
    classNameResult = 'bd-green__color';
  }
  return (
    <div class='bd-modal-wrap bd-modal-right bd-lbresult' ref={printElement}>
      <div class='bd-popup si-fade-in'>
        <div class='bd-popup__head'>
          <span>Lab Results fffff</span>
          <i class='bludclose' onClick={() => setShowDetailsPopup(false)}></i>
        </div>
        <div class='bd-popup__body'>
          <div class='bd-form'>
            <div class='bd-form__row bd-form__row--4'>
              {/* <div>
                <span>Donor ID</span>
                <input
                  disabled
                  type="text"
                  value={selectedLabResult?.donorId ?? "-"}
                />
              </div> */}
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}> Donor ID</span>
                <input
                  disabled
                  type='text'
                  value={selectedLabResult?.donorId ?? '-'}
                />
              </div>
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}>Sample ID</span>
                <input
                  disabled
                  type='text'
                  value={selectedLabResult?.labResults?.reportNo ?? '-'}
                />
              </div>

              {/* <div class="bd-dorpdown">
                <span>Laboratory</span>
                <div class="bd-dorpdown__selected">
                  <span>
                    {selectedLabResult?.labResults?.laboratory ?? "-"}
                  </span>
                </div>
              </div> */}
              {/* <div class="bd-calender">
                <input
                  disabled
                  type="text"
                  placeholder="Date"
                  value={selectedLabResult?.collectedDate ?? "-"}
                />
              </div> */}
            </div>
            {/* <div class="bd-form__row bd-form__row--2">
              <div>
                <span>Sample ID</span>
                <input
                  disabled
                  type="text"
                  value={selectedLabResult?.labResults?.sampleId ?? "-"}
                />
              </div>
              
            </div> */}
            <div class='bd-form__row bd-form__row--4'>
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}> Blood Group</span>
                <input
                  disabled
                  type='text'
                  value={selectedLabResult?.bloodGroup ?? '-'}
                />
              </div>
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}>Laboratory</span>
                <input
                  disabled
                  type='text'
                  value={selectedLabResult?.labResults?.laboratory ?? '-'}
                />
              </div>
            </div>

            <div class='bd-form__row bd-form__row--4'>
              <div className='d-flex-center'>
                <span style={{ marginRight: '10px' }}>Tested by</span>
                <input
                  disabled
                  type='text'
                  value={selectedLabResult?.labResults?.testedBy ?? '-'}
                />
              </div>
              <div className='bd-dorpdown d-flex-center'>
                <div>Report Date</div>
                <DatePicker
                  disabled={true}
                  selected={
                    selectedLabResult?.labResults?.addedAt
                      ? new Date(selectedLabResult.labResults.addedAt)
                      : null
                  }
                />
              </div>
              {/* <div class="bd-calender">
                <input
                  disabled
                  type="text"
                  placeholder="Report Date"
                  value={selectedLabResult?.labResults?.addedAt ?? "-"}
                />
              </div> */}
            </div>
          </div>
          <div class='bd-tbl  bd-tbl__theme1'>
            <div class='bd-tbl__head'>
              <div class='bd-tbl__row'>
                <div class='bd-tbl__cell'>
                  <span>Parameters</span>
                </div>
                <div class='bd-tbl__cell'>
                  <span>Standard</span>
                </div>
                <div class='bd-tbl__cell'>
                  <span>Observation</span>
                </div>
                <div class='bd-tbl__cell'>
                  <span>Remarks</span>
                </div>
              </div>
            </div>
            <div class='bd-tbl__body'>
              {selectedLabResult?.labResults?.results?.map((data, i) => {
                return (
                  <div key={i} class='bd-tbl__row'>
                    <div class='bd-tbl__cell'>
                      <span>{data.parameter}</span>
                    </div>
                    <div class='bd-tbl__cell'>
                      <span>4.5-5.5*1012</span>
                    </div>
                    <div class='bd-tbl__cell'>
                      <span>{data.observation}</span>
                    </div>
                    <div class='bd-tbl__cell'>
                      <span>{data.remarks}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div class='bd-print__result'>
            <div class='bd-print__result--btn'>
              <span>Result</span>
              <i class='bludprint' onClick={handlePrint}></i>
            </div>
            <div class='bd-dorpdown active'>
              <div class='bd-dorpdown__selected'>
                <span class={classNameResult}>
                  {selectedLabResult?.labResults?.finalResult}
                </span>
              </div>
            </div>
          </div>
          <div class='bd-comment'>
            <input
              disabled
              type='text'
              placeholder='Comment'
              value={selectedLabResult?.labResults?.comments ?? ''}
            />
          </div>
          <div class='bd-btn__wrap'>
            {/* <div class="bd-btn bd-btn--outline" on>
              <span>Cancel</span>
            </div>
            <div class="bd-btn bd-btn--primary db-bg__green">
              <span>Submit</span>
            </div> */}
          </div>
        </div>
      </div>

      <div class='bd-popup__lightbox'></div>
    </div>
  );
}

export default PopupDetailsLabs;
